import { ApiClientError } from '@raydiant/api-client-js';
import Link from 'raydiant-elements/core/Link';
import Heading from 'raydiant-elements/core/Heading';
import TextField from 'raydiant-elements/core/TextField';
import Button from 'raydiant-elements/core/Button';
import Form from 'raydiant-elements/core/Form';
import Text from 'raydiant-elements/typography/Text';
import Column from 'raydiant-elements/layout/Column';
import Center from 'raydiant-elements/layout/Center';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import { FC, useState, useCallback } from 'react';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import * as deviceActions from '../../actions/devices';
import activationScreenPng from '../../assets/activation-screen.png';
import TimeZoneSelect from '../TimeZoneSelect';
import moment from 'moment';

interface RegisterDeviceProps {
  title?: string;
  defaultCode?: string | null;
  onRegister?: () => void;
}
const defaulTimeZone = moment.tz.guess();

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    activationScreen: {
      maxWidth: 185,
      textAlign: 'center',

      '& > img': {
        maxWidth: '100%',
        boxShadow: theme.shadows[8],
      },
    },
  });
});

const RegisterDevice: FC<RegisterDeviceProps> = ({
  title = 'Activate a new screen',
  defaultCode = null,
  onRegister,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // State

  const [activationCode, setActivationCode] = useState<string | null>(
    defaultCode,
  );
  const [name, setName] = useState<string | null>(null);
  const [registrationStatus, setRegistrationStatus] = useState<
    '' | 'pending' | 'success' | 'failed'
  >('');
  const [errorCode, setErrorCode] = useState<number | null>(null);
  const [timezone, setTimezone] = useState<string | null>(defaulTimeZone);
  // Callbacks

  const registerDevice = useCallback(() => {
    if (!activationCode) return;

    setRegistrationStatus('pending');

    dispatch(
      deviceActions.registerDevice(
        {
          activationCode,
          name: name ?? '',
          timezone: timezone ?? '',
          isAudioOnly: false,
          locationId: '',
        },
        {
          onRegister: () => {
            setRegistrationStatus('success');
            if (onRegister) {
              onRegister();
            }
          },
          onError: (error) => {
            setRegistrationStatus('failed');
            if (error instanceof ApiClientError) {
              setErrorCode(error.status ?? null);
            }
          },
        },
      ),
    );
  }, [
    dispatch,
    activationCode,
    name,
    timezone,
    setRegistrationStatus,
    onRegister,
  ]);

  // Render

  const isValidActivationCode =
    !!activationCode && !!activationCode.match(/^[A-Z0-9]+-[A-Z0-9]+$/);

  let helperText: React.ReactNode = '';
  if (registrationStatus === 'failed') {
    if (errorCode === 403) {
      helperText = (
        <>
          Sorry, you have reached your limit on ScreenRay licenses. Please
          contact{' '}
          <Link color="inherit" href="mailto:support@raydiant.com">
            support@raydiant.com
          </Link>{' '}
          for further assistance.
        </>
      );
    } else {
      helperText = `Sorry, we couldn't activate your ScreenRay. Please double check your Activation Code and try again.`;
    }
  }

  return (
    <Column doubleMargin>
      <Heading size={2} weight={500} color="primary" center>
        {title}
      </Heading>

      <Form onSubmit={registerDevice}>
        <Column>
          <Center>
            <Column className={classes.activationScreen}>
              <img src={activationScreenPng} alt="" />

              <Text muted small>
                Enter the two word Activation Code on your TV screen
              </Text>
            </Column>
          </Center>

          <TextField
            label="Activation Code"
            autoFocus={!defaultCode}
            value={activationCode ?? ''}
            onChange={setActivationCode}
            error={registrationStatus === 'failed'}
            helperText={helperText}
            mask={(value = '') => value.split('').map(() => /[a-zA-Z0-9\-\s]/g)}
            pipe={(value = '') => {
              const newValue = value.toUpperCase().replace(' ', '-');
              return {
                value: newValue,
                indexesOfPipedChars: [newValue.indexOf('-') - 1],
              };
            }}
          />

          <TextField
            autoFocus={!!defaultCode}
            label="Name"
            value={name ?? ''}
            onChange={setName}
          />

          <TimeZoneSelect
            label="Screen Timezone"
            value={timezone ?? ''}
            onChange={setTimezone}
          />

          <Button
            type="submit"
            label="Activate"
            color="progress"
            disabled={
              !isValidActivationCode ||
              registrationStatus === 'pending' ||
              !timezone
            }
          />
        </Column>
      </Form>
    </Column>
  );
};

export default RegisterDevice;
