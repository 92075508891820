import { useState, useEffect } from 'react';
import ContentEngagementIllust from './assets/content-engagement-1.png';
import ContentEngagementIllust2 from './assets/content-engagement-2.png';
import ContentEngagementIllust3 from './assets/content-engagement-3.png';
import cn from 'classnames';

const carouselItems = [
  {
    label: 'Interpret your metrics',
    href: 'https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics',
    backgroundImage: ContentEngagementIllust,
  },
  {
    label: 'Identify your best content',
    href: 'https://www.raydiant.com/blog/raydiants-in-store-analytics-can-identify-your-best-performing-content',
    backgroundImage: ContentEngagementIllust2,
  },
  {
    label: 'Add Visitor InIsights',
    href: 'https://www.raydiant.com/solutions/customer-analytics',
    backgroundImage: ContentEngagementIllust3,
  },
];

const AnalyticsCarousel = () => {
  const [active, setActive] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setActive((active + 1) % carouselItems.length);
    }, 5000);
    return () => clearTimeout(timer);
  }, [active]);

  return (
    <div
      className={cn('rounded-3xl carousel-card')}
      style={{
        backgroundImage: `url(${carouselItems[active].backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <div className="carousel-header">Learn How To:</div>
      <ol>
        {carouselItems.map((item, index) => {
          return (
            <li
              key={item.label}
              className={cn('carousel-item', index === active ? 'active' : '')}
            >
              <a href={item.href} target="_blank" rel="noreferrer">{`${
                index + 1
              }. ${item.label}`}</a>
            </li>
          );
        })}
      </ol>
      <div className="carousel-buttongroup">
        {carouselItems.map((_, index) => {
          return (
            <div
              className={cn(
                'carousel-button',
                index === active ? 'active' : '',
              )}
              onClick={() => setActive(index)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AnalyticsCarousel;
