import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type TimeIntervalProps = {
  className?: string;
};

const TimeInterval = ({ className }: TimeIntervalProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        'time-interval text-left rounded-2xl border-gray-100 border-solid w-[224px] h-[54px] p-0 px-6 flex items-center justify-start hover:none',
        className,
      )}
    >
      <div>
        <p className="font-semibold">{t('analytics.timeInterval')}</p>
        <p className="text-primary">{t('analytics.daily')}</p>
      </div>
    </div>
  );
};

export default TimeInterval;
