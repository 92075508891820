import {
  ApiClientError,
  Connection,
  GetConnectionsQuery,
  Paginated,
} from '@raydiant/api-client-js';
import raydiant from '../clients/miraClient';
import useProtectedQuery from './useProtectedQuery';
import { keys } from '../queryClient';

export default function useConnections( // Pagination support has not been implemented. In the interim, setting page size to a large number
  opts: GetConnectionsQuery = { pageSize: 1000 },
) {
  const query = useProtectedQuery<Paginated<Connection>, ApiClientError>(
    keys.connections(),
    () => raydiant.getConnections(opts),
  );

  return query;
}
