import { SVGProps } from 'react';

interface CircleArrowRightProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const CircleArrowRight = ({ size = 18, ...props }: CircleArrowRightProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.9062 9.625C15.9062 7.16797 14.5781 4.91016 12.4531 3.64844C10.2949 2.41992 7.67188 2.41992 5.54688 3.64844C3.38867 4.91016 2.09375 7.16797 2.09375 9.625C2.09375 12.1152 3.38867 14.373 5.54688 15.6348C7.67188 16.8633 10.2949 16.8633 12.4531 15.6348C14.5781 14.373 15.9062 12.1152 15.9062 9.625ZM0.5 9.625C0.5 6.60352 2.09375 3.81445 4.75 2.28711C7.37305 0.759766 10.5938 0.759766 13.25 2.28711C15.873 3.81445 17.5 6.60352 17.5 9.625C17.5 12.6797 15.873 15.4688 13.25 16.9961C10.5938 18.5234 7.37305 18.5234 4.75 16.9961C2.09375 15.4688 0.5 12.6797 0.5 9.625ZM10.0957 13.1113C9.76367 13.4434 9.26562 13.4434 8.9668 13.1113C8.63477 12.8125 8.63477 12.3145 8.9668 11.9824L10.5273 10.4219H5.54688C5.08203 10.4219 4.75 10.0898 4.75 9.625C4.75 9.19336 5.08203 8.82812 5.54688 8.82812H10.5273L8.9668 7.26758C8.63477 6.96875 8.63477 6.4707 8.9668 6.17188C9.26562 5.83984 9.76367 5.83984 10.0957 6.17188L13.0176 9.09375C13.3164 9.39258 13.3164 9.89062 13.0176 10.1895L10.0957 13.1113Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default CircleArrowRight;
