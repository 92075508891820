import {
  AnalyticsQueryParams,
  ApiClientError,
  GetAnalytics,
} from '@raydiant/api-client-js';
import { keys } from '../queryClient';
import raydiant from '../clients/miraClient';
import useProtectedQuery from './useProtectedQuery';

export default function useAnalytics(params: AnalyticsQueryParams) {
  const query = useProtectedQuery<GetAnalytics, ApiClientError>(
    keys.analytics(params),
    () => raydiant.getAnalytics(params),
  );

  return query;
}
