import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import Button from 'raydiant-elements/core/Button';
import Heading from 'raydiant-elements/core/Heading';
import Spacer from 'raydiant-elements/layout/Spacer';
import Text from 'raydiant-elements/typography/Text';
import useStyles from './DeviceIntegrationDialog.styles';

interface DeleteConfirmationModalProps {
  open: boolean;
  handleClose: () => void;
  handleClick: () => void;
}

const DeleteConfirmationModal = ({
  open,
  handleClose,
  handleClick,
}: DeleteConfirmationModalProps) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Heading className={classes.heading}>Confirm Delete</Heading>
        <DialogContentText id="alert-dialog-description">
          <Text>
            Are you sure you want to delete this location GUID for this screen?
          </Text>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          onClick={handleClose}
          color="default"
        >
          Cancel
        </Button>
        <Spacer />
        <Button
          className={classes.button}
          onClick={handleClick}
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationModal;
