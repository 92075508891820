import {
  ApiClientError,
  CreateConnectionServer,
  CreateConnectionClient,
} from '@raydiant/api-client-js';
import { useQueryClient } from 'react-query';
import raydiant from '../clients/raydiant';
import { keys } from '../queryClient';
import useProtectedMutation from './useProtectedMutation';

export default function useCreateConnection() {
  const queryClient = useQueryClient();

  const mutation = useProtectedMutation<
    CreateConnectionServer,
    ApiClientError,
    CreateConnectionClient
  >((data) => raydiant.createConnection(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(keys.connections());
    },
  });

  return mutation;
}
