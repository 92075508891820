import classNames from 'classnames';
import Hidden from 'raydiant-elements/layout/Hidden';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';

export type PaginationProps = {
  className?: string;
  currentPage: number;
  pageCount: number;
  onPageChange: (pageNumber: number) => void;
};

export const PAGE_SIZE = 10;

const Pagination = ({
  className,
  currentPage,
  onPageChange,
  pageCount,
}: PaginationProps) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('pagination', className)}>
      <ReactPaginate
        forcePage={currentPage}
        activeClassName="active"
        breakLabel="..."
        containerClassName="pagination flex-wrap"
        nextLabel={
          <>
            <Hidden smDown>
              <button className="btn next pointer-events-none">
                <Hidden smDown>
                  <span className="text-base font-regular">
                    {t('pagination.next')}{' '}
                  </span>
                </Hidden>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  className="w-3 h-3 ml-3 text-inherit"
                  fill="currentColor"
                >
                  <path d="M305 239c9.4 9.4 9.4 24.6 0 33.9L113 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L79 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L305 239z" />
                </svg>
              </button>
            </Hidden>
            <Hidden smUp>
              <button className="btn next pointer-events-none p-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  className="w-3 h-3 ml-3 text-inherit"
                  fill="currentColor"
                >
                  <path d="M305 239c9.4 9.4 9.4 24.6 0 33.9L113 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L79 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L305 239z" />
                </svg>
              </button>
            </Hidden>
          </>
        }
        nextLinkClassName="no-underline hover:no-underline"
        onPageChange={(item) => onPageChange && onPageChange(item.selected)}
        pageLabelBuilder={(page) => (
          <button className="btn text-base font-regular pointer-events-none">
            {page}
          </button>
        )}
        pageLinkClassName="no-underline hover:no-underline"
        pageRangeDisplayed={2}
        pageCount={pageCount}
        previousLinkClassName="no-underline hover:no-underline"
        previousLabel={
          <>
            <Hidden smDown>
              <button className="btn prev pointer-events-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  className="w-3 h-3 mr-3 text-inherit"
                  fill="currentColor"
                >
                  <path d="M15 239c-9.4 9.4-9.4 24.6 0 33.9L207 465c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L65.9 256 241 81c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L15 239z" />
                </svg>
                <span className="!text-base font-regular">
                  {t('pagination.previous')}{' '}
                </span>
              </button>
            </Hidden>
            <Hidden smUp>
              <button className="btn prev pointer-events-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  className="w-3 h-3 mr-3 text-inherit"
                  fill="currentColor"
                >
                  <path d="M15 239c-9.4 9.4-9.4 24.6 0 33.9L207 465c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L65.9 256 241 81c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L15 239z" />
                </svg>
              </button>
            </Hidden>
          </>
        }
        renderOnZeroPageCount={null}
      />
    </div>
  );
};

export default Pagination;
