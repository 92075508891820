import { SVGProps } from 'react';

interface RectangleHistoryProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const RectangleHistory = ({ size = 18, ...props }: RectangleHistoryProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.9062 7.5625C15.9062 7.29688 15.6406 7.03125 15.375 7.03125L2.625 7.03125C2.32617 7.03125 2.09375 7.29688 2.09375 7.5625L2.09375 15C2.09375 15.2988 2.32617 15.5312 2.625 15.5312L15.375 15.5313C15.6406 15.5313 15.9062 15.2988 15.9062 15V7.5625ZM15.375 5.4375C16.5371 5.4375 17.5 6.40039 17.5 7.5625L17.5 15C17.5 16.1953 16.5371 17.125 15.375 17.125L2.625 17.125C1.42969 17.125 0.5 16.1953 0.5 15L0.5 7.5625C0.5 6.40039 1.42969 5.4375 2.625 5.4375L15.375 5.4375ZM15.1094 2.78125C15.541 2.78125 15.9062 3.14648 15.9062 3.57813C15.9062 4.04297 15.541 4.375 15.1094 4.375L2.89062 4.375C2.42578 4.375 2.09375 4.04297 2.09375 3.57812C2.09375 3.14648 2.42578 2.78125 2.89062 2.78125L15.1094 2.78125ZM13.5156 0.125C13.9473 0.125 14.3125 0.490234 14.3125 0.921875C14.3125 1.38672 13.9473 1.71875 13.5156 1.71875L4.48438 1.71875C4.01953 1.71875 3.6875 1.38672 3.6875 0.921875C3.6875 0.490234 4.01953 0.125 4.48438 0.125L13.5156 0.125Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default RectangleHistory;
