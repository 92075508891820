import { useSelector } from 'react-redux';
import { Application, ApplicationVersion } from '@raydiant/api-client-js';
import ActionBar from 'raydiant-elements/core/ActionBar';
import Heading from 'raydiant-elements/core/Heading';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import Grid from '../../../components/Grid';
import GridItem from '../../../components/Grid/GridItem';
import MarketplaceV2Card from '../../../components/MarketplaceV2Card';
import { userHasPremiumApp } from '../MarketplacePageUtils';
import { capitalize } from '../../../utilities/stringUtils';
import { selectApplications } from '../../../selectors/applications';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appsGrid: {
      '&>:nth-child(2)': {
        '&>:nth-child(1)': {
          alignItems: 'flex-end',
          justifyContent: 'flex-start',
          gap: theme.spacing(4),
        },
      },
    },
  }),
);

const isStagingOrLocal = () => {
  if (typeof window === 'undefined') return true;

  return [
    window.location.hostname.includes('staging'),
    window.location.hostname.includes('localhost'),
  ].includes(true);
};

export interface ApplicationGroupProps {
  data: Application[] | undefined;
  groupName: 'content' | 'menu' | 'communication';
  onClick: (application: Application) => void;
}

const ApplicationGroup = ({
  data,
  groupName,
  onClick,
}: ApplicationGroupProps) => {
  const classes = useStyles();

  const applications = useSelector(selectApplications);

  const renderApplications = () => {
    const groupedApplications = data?.filter(
      (premiumApp) => premiumApp.applicationCategory?.name === groupName,
    );

    const firstApplication = groupedApplications && groupedApplications[0];

    const fakeArtPlayer =
      firstApplication &&
      ({
        ...firstApplication,
        currentAppVersion: {
          ...firstApplication.currentAppVersion,
          name: 'BlueJeans',
        } as ApplicationVersion,
        name: 'BlueJeans',
      } as Application);

    return (
      <>
        <ActionBar>
          <Heading size={2} weight={600}>
            Premium {capitalize(groupName)} Apps
          </Heading>
        </ActionBar>
        <Grid paddingTop={false} className={classes.appsGrid}>
          {isStagingOrLocal() && fakeArtPlayer && (
            <GridItem key="fake_artplayer">
              <MarketplaceV2Card
                application={{
                  ...fakeArtPlayer,
                  checked: userHasPremiumApp(fakeArtPlayer, applications),
                }}
                onClick={onClick}
              />
            </GridItem>
          )}
          {groupedApplications?.map((app) => {
            return (
              <GridItem key={app.id}>
                <MarketplaceV2Card
                  application={{
                    ...app,
                    checked: userHasPremiumApp(app, applications),
                  }}
                  onClick={onClick}
                />
              </GridItem>
            );
          })}
        </Grid>
      </>
    );
  };

  return <>{renderApplications()}</>;
};

export default ApplicationGroup;
