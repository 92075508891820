import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { RuleConstantInputPropsType } from './RuleConstantInput';

type GenderRuleConstantValueType = 'male' | 'female' | 'unknown';
export type GenderRuleConstantInputProps = RuleConstantInputPropsType<{
  type: 'gender';
  value: GenderRuleConstantValueType;
  onChange: (value: GenderRuleConstantValueType) => void;
}>;

const GenderRuleConstantInput = ({
  onChange,
  value,
}: GenderRuleConstantInputProps) => {
  return (
    <ToggleButtonGroup
      exclusive={true}
      onChange={(_event, value) => {
        if (value !== null) onChange(value);
      }}
      value={value}
    >
      <ToggleButton value={'m'}>Male</ToggleButton>
      <ToggleButton value={'f'}>Female</ToggleButton>
      <ToggleButton value={'u'}>Undetermined</ToggleButton>
    </ToggleButtonGroup>
  );
};

export default GenderRuleConstantInput;
