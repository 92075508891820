import { FC } from 'react';
import { Grid } from '@material-ui/core';
import useMarketPlaceTiles from '../../hooks/useMarketPlaceTiles';

import MarketplaceTile from '../MarketplaceTile';
import TileModalLayout from '../../pages/MarketplacePage/TileModalLayout';

import { useSelector } from 'react-redux';
import { selectApplications } from '../../selectors/applications';
import {
  selectUserProfile,
  selectIsDeveloper,
  selectIsUserAIEnabled,
} from '../../selectors/user';
import useStyles from './MarketplaceLayout.styles';

export interface MarketplaceLayoutProps {
  open: boolean;
  selected: string | null;
  onTileClick: (app: string) => void;
  onClick: () => void;
  onClose: () => void;
}

const MarketplaceLayout: FC<MarketplaceLayoutProps> = ({
  open,
  selected,
  onTileClick,
  onClick,
  onClose,
}) => {
  const { data, isLoading } = useMarketPlaceTiles();
  const classes = useStyles();
  const selectedTile = data?.filter((app) => app.type === selected) || [];
  const currentUser = useSelector(selectUserProfile);
  const applications = useSelector(selectApplications);

  // Permissions to Disable Tile Button
  const cxAccessEnabled = !!currentUser?.domainId;
  const exAccessEnabled = applications.some(
    (application) => application.name === 'hoopla',
  );
  const dxAccessEnabled = useSelector(selectIsDeveloper);
  const aiAccessEnabled = useSelector(selectIsUserAIEnabled);

  if (!data) return null;

  const isDisabled = (type: string) => {
    switch (type) {
      case 'dx':
        return dxAccessEnabled;
      case 'ex':
        return exAccessEnabled;
      case 'cx':
        return cxAccessEnabled;
      case 'ai':
        return aiAccessEnabled;
      default:
        return false;
    }
  };

  return (
    <>
      <Grid spacing={2} container className={classes.root}>
        <Grid item xs={12} md={8}>
          <MarketplaceTile
            isLoading={isLoading}
            data={data[0]}
            onClick={onTileClick}
            isDisabled={isDisabled(data[0].type)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MarketplaceTile
                isLoading={isLoading}
                data={data[1]}
                onClick={onTileClick}
                isDisabled={isDisabled(data[1].type)}
              />
            </Grid>
            <Grid item xs={12}>
              <MarketplaceTile
                isLoading={isLoading}
                data={data[2]}
                onClick={onTileClick}
                isDisabled={isDisabled(data[2].type)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {selectedTile.length > 0 && (
        <TileModalLayout
          data={selectedTile[0]}
          open={open}
          onClick={onClick}
          onClose={onClose}
          isDisabled={isDisabled(selectedTile[0].type)}
        />
      )}
    </>
  );
};

export default MarketplaceLayout;
