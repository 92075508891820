import { BatchActionStatus } from '@raydiant/api-client-js';
import Text from '@raydiant/raydial/components/Text';
import Button from '@raydiant/raydial/components/Button';
import Popover from '@raydiant/raydial/components/Popover';
import Dialog, { DialogTrigger } from '@raydiant/raydial/components/Dialog';
import { clientFacingBatchActions } from '../deviceBatchSettingsUtils';
import cn from 'classnames';

interface DeviceBatchStatusProps {
  batchActions: BatchActionStatus[];
}

const DeviceBatchStatus = ({ batchActions = [] }: DeviceBatchStatusProps) => {
  const appliedBatchOperation = batchActions.filter(
    (action) => action.status === 'completed',
  );
  const errorBatchOperation = batchActions.filter(
    (action) => action.status === 'error',
  );

  const appliedText = appliedBatchOperation.length > 1 ? 'changes' : 'change';
  const errorText = errorBatchOperation.length > 1 ? 'errors' : 'error';

  const renderErrorTooltip = () => (
    <DialogTrigger>
      <Button aria-label="Errors" variant="unstyled" className="text-red-700">
        <div className="flex items-center">
          <Text>
            {errorBatchOperation.length} {errorText}
          </Text>
        </div>
      </Button>
      <Popover className="max-w-[180px]" placement="bottom end">
        <Dialog>
          <Text className="font-medium" variant="body">
            Errors
          </Text>
          <div>
            {errorBatchOperation.map((batchAction) => (
              <div>
                <Text variant="caption">
                  - {clientFacingBatchActions(batchAction.action)}
                </Text>
              </div>
            ))}
          </div>
        </Dialog>
      </Popover>
    </DialogTrigger>
  );

  return (
    <div className={cn('flex items-end md:items-center', 'md:flex-row')}>
      <div>
        <Text variant="caption" className="text-blue-700">
          {appliedBatchOperation.length} {appliedText} applied
        </Text>
        <Text variant="caption" className="text-gray-600 mx-2">
          |
        </Text>
      </div>
      {renderErrorTooltip()}
    </div>
  );
};

export default DeviceBatchStatus;
