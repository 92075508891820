import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import Button from 'raydiant-elements/core/Button';
import Heading from 'raydiant-elements/core/Heading';
import Spacer from 'raydiant-elements/layout/Spacer';
import Text from 'raydiant-elements/typography/Text';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      color: theme.palette.primary.main,
      fontSize: theme.spacing(3),
      fontWeight: 500,
    },
    button: {
      minWidth: '8rem',
    },
  }),
);

interface FreetrialModalProps {
  open: boolean;
  onClick: () => void;
  onClose: () => void;
}

const FreetrialModal = ({ open, onClick, onClose }: FreetrialModalProps) => {
  const [showSecondModal, setShowSecondModal] = useState(false);
  const classes = useStyles();

  const handleFirstModalClick = () => {
    setShowSecondModal(true);
    onClick();
  };

  useEffect(() => {
    if (!open) {
      setShowSecondModal(false);
    }
  }, [open]);

  const renderFirstModal = () => (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Heading className={classes.heading}>
          We're here to help you get started
        </Heading>
        <DialogContentText id="alert-dialog-description">
          <Text>
            Interested in our apps? Apps and Raydiant products are only
            available on our paid plans. Reach out to Sales to upgrade your
            subscription.
          </Text>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          onClick={() => onClose()}
          color="default"
        >
          Cancel
        </Button>
        <Spacer />
        <Button
          className={classes.button}
          onClick={() => handleFirstModalClick()}
          color="primary"
        >
          Reach out to Sales
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderSecondModal = () => (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Heading className={classes.heading}>All done!</Heading>
        <DialogContentText id="alert-dialog-description">
          <Text>
            A Raydiant Sales representative will reach out to you soon!
          </Text>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Spacer />
        <Button
          className={classes.button}
          onClick={() => onClose()}
          color="primary"
        >
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      {!showSecondModal && renderFirstModal()}
      {showSecondModal && renderSecondModal()}
    </>
  );
};

export default FreetrialModal;
