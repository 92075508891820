import { Collapse } from '@material-ui/core';
import Heading from 'raydiant-elements/core/Heading';
import { useState } from 'react';
import useStyles from './DeviceAISettings.styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ButtonBase from '@material-ui/core/ButtonBase';
import Box from '@material-ui/core/Box';

interface DeviceAiSettingsGroupProps {
  children: React.ReactNode;
  collapsable?: boolean;
  title?: string;
  helperText?: React.ReactNode;
}

const DeviceAiSettingsGroup = ({
  collapsable = true,
  children,
  title,
  helperText,
}: DeviceAiSettingsGroupProps) => {
  const classes = useStyles();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const showHelperText = isCollapsed && helperText;

  return (
    <ul className={classes.aiSettingList}>
      {title && (
        <li className={classes.aiSetting}>
          <Heading
            size={5}
            overline
            gutterBottom
            className={
              showHelperText ? classes.headingWithHelperText : undefined
            }
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <>{title}</>
              <ButtonBase onClick={() => setIsCollapsed(!isCollapsed)}>
                {isCollapsed ? <ExpandLess /> : <ExpandMore />}
              </ButtonBase>
            </Box>
          </Heading>
          {showHelperText && helperText}
        </li>
      )}
      {collapsable && isCollapsed && (
        <Collapse in={isCollapsed}>{children}</Collapse>
      )}
      {!collapsable && <>{children}</>}
    </ul>
  );
};

export default DeviceAiSettingsGroup;
