import Text from '@raydiant/raydial/components/Text';
import Button from '@raydiant/raydial/components/Button';
import Card from '@raydiant/raydial/components/Card';
import CircleNodes from '@raydiant/raydial/components/Icons/CircleNodes';
import Page from '../../components/Page';
import PageLayout from '../../components/PageLayout/PageLayout';
import PageTitle from '../../components/PageLayout/PageTitle';
import history from '../../history';
import * as paths from '../../routes/paths';
import useConnections from '../../hooks/useConnections';
import LoadingModal from '@raydiant/raydial/components/LoadingModal';
import { IntegrationItem } from './components';

const ProductHubIntegrationsPage = () => {
  const connectionsQuery = useConnections();

  if (!connectionsQuery || connectionsQuery.isLoading) {
    return (
      <Page>
        <LoadingModal isOpen={true} />
      </Page>
    );
  }

  if (!connectionsQuery.data) {
    return null;
  }

  const { data: connections } = connectionsQuery.data;

  const handleOnAddIntegration = () => {
    history.push(paths.productHubCreateIntegrations());
  };

  const handleOnEditClick = (integrationId: string) => {
    history.push(paths.productHubEditIntegrations(integrationId));
  };

  return (
    <Page>
      <PageLayout
        title={
          <PageTitle
            title="Integrations"
            subTitle="View and manage integrations"
          />
        }
      >
        <Card className="text-black max-w-[628px]">
          <header className="flex items-center px-4">
            <div className="grid gap-1 py-4 mr-auto">
              <Text as="h2" variant="subheader">
                Connected Integrations
              </Text>
              <Text as="h3" className="text-gray-400 font-regular text-lg">
                {connections.length === 0 &&
                  'You have no connected integrations'}
                {connections.length === 1 && 'You have 1 connected integration'}
                {connections.length > 1 &&
                  `You have ${connections.length} connected integrations`}
              </Text>
            </div>

            {connections.length > 0 && (
              <div>
                <Button onPress={handleOnAddIntegration}>
                  Add Integration
                </Button>
              </div>
            )}
          </header>
          {connections.length === 0 && (
            <IntegrationItem
              heading="Add an integration"
              subheading="Connect with a Raydiant partner"
              icon={
                <div className="size-16 bg-gray-200 rounded-lg flex items-center justify-center">
                  <CircleNodes className="text-gray-500 size-[30px]" />
                </div>
              }
              action={<Button onPress={handleOnAddIntegration}>Add</Button>}
            />
          )}
          {connections.length > 0 &&
            connections.map((integration) => {
              return (
                <IntegrationItem
                  key={integration.id}
                  heading={integration.name}
                  subheading={integration.connector.name}
                  icon={
                    <img
                      src={integration.connector.thumbnailUrl}
                      alt="connector"
                    />
                  }
                  action={
                    <Button
                      onPress={() => {
                        handleOnEditClick(integration.id);
                      }}
                    >
                      Edit
                    </Button>
                  }
                />
              );
            })}
        </Card>
      </PageLayout>
    </Page>
  );
};

export default ProductHubIntegrationsPage;
