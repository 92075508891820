import { useTranslation } from 'react-i18next';
import TotalCard from './TotalCard';
import { GetTrafficAwareness } from '@raydiant/api-client-js';
import AveragePerDayCard from './AveragePerDayCard';
import AveragePerWeekCard from './AveragePerWeekCard';
import { Popover } from '@headlessui/react';
import QuestionMarkIcon from '../../../../components/QuestionMarkIcon';
import PopoverButton from '../../../../components/Popover/PopoverButton';
import { getPercentChange } from './utils';
import TotalTrafficImage from './assets/traffic-total.svg';
import DailyAverageTrafficImage from './assets/traffic-average-day.svg';
import WeeklyAverageTrafficImage from './assets/traffic-average-week.svg';
import TotalDwellTimeImage from './assets/dwellTime-total.svg';
import DailyAverageDwellTimeImage from './assets/dwellTime-average-day.svg';
import TotalImpressionsImage from './assets/impressions-total.svg';
import DailyAverageImpressionsImage from './assets/impressions-average-day.svg';
import WeeklyAverageImpressionsImage from './assets/impressions-average-week.svg';

type ShopperEngagementInsightsProps = {
  data: GetTrafficAwareness;
  showEmpty?: boolean;
  showImpressions?: boolean;
  isAllTime: boolean;
};

const TrafficAwarenessOverview = ({
  data,
  showEmpty = false,
  showImpressions = false,
  isAllTime = false,
}: ShopperEngagementInsightsProps) => {
  const { t } = useTranslation();

  const [currentSummary] = data?.trafficSummary;
  const [previousSummary] = data?.trafficSummaryPreviousPeriod;

  const currentTotalTraffic = currentSummary?.ots || 0;
  const previousTotalTraffic = previousSummary?.ots || 0;
  const currentDailyAverageTraffic = currentSummary?.avgOtsPerDay || 0;
  const previousDailyAverageTraffic = previousSummary?.avgOtsPerDay || 0;
  const currentWeeklyAverageTraffic = currentSummary?.avgOtsPerWeek || 0;
  const previousWeeklyAverageTraffic = previousSummary?.avgOtsPerWeek || 0;

  const currentTotalDwellTime = currentSummary?.dwellTime || 0;
  const previousTotalDwellTime = previousSummary?.dwellTime || 0;
  const currentAverageDwellTimePerVisitor = currentSummary?.avgDwellTime || 0;
  const previousAverageDwellTimePerVisitor = previousSummary?.avgDwellTime || 0;

  const currentTotalImpressions = currentSummary?.impressions || 0;
  const previousTotalImpressions = previousSummary?.impressions || 0;
  const currentDailyAverageImpressions =
    currentSummary?.avgImpressionsPerDay || 0;
  const previousDailyAverageImpressions =
    previousSummary?.avgImpressionsPerDay || 0;
  const currentWeeklyAverageImpressions =
    currentSummary?.avgImpressionsPerWeek || 0;
  const previousWeeklyAverageImpressions =
    previousSummary?.avgImpressionsPerWeek || 0;

  return (
    <div className="mb-8">
      <div className="flex gap-3 items-center mb-6 mx-6">
        <h2 className="text-black text-xl font-bold">
          {t('analytics.trafficAwarenessPage.overview.title')}
        </h2>
        <Popover className="relative">
          <PopoverButton icon={<QuestionMarkIcon />} />
          <Popover.Panel className="absolute z-10 card popover">
            <div className="overflow-hidden">
              <p className="mb-3">
                {showImpressions
                  ? t('analytics.trafficAwarenessPage.overview.tooltip.default')
                  : t('analytics.trafficAwarenessPage.overview.tooltip.viOnly')}
              </p>
              <a
                href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                rel="noreferrer"
                target="_blank"
              >
                {t('analytics.learnmore')}
              </a>
            </div>
          </Popover.Panel>
        </Popover>
      </div>
      <div className="insights touch-pan-x">
        <TotalCard
          change={getPercentChange(currentTotalTraffic, previousTotalTraffic)}
          key="totalTraffic"
          type="ots"
          label={t('analytics.trafficAwarenessPage.overview.ots.total')}
          src={TotalTrafficImage}
          showEmpty={showEmpty}
          value={currentTotalTraffic}
          noPreviousData={isAllTime || previousTotalTraffic === 0}
        />
        <AveragePerWeekCard
          change={getPercentChange(
            currentWeeklyAverageTraffic,
            previousWeeklyAverageTraffic,
          )}
          key="averageTrafficPerWeek"
          type="ots"
          src={WeeklyAverageTrafficImage}
          label={t('analytics.trafficAwarenessPage.overview.ots.weeklyAverage')}
          showEmpty={showEmpty}
          value={currentWeeklyAverageTraffic}
          noPreviousData={isAllTime || previousWeeklyAverageTraffic === 0}
        />
        <AveragePerDayCard
          change={getPercentChange(
            currentDailyAverageTraffic,
            previousDailyAverageTraffic,
          )}
          key="averageTrafficPerDay"
          type="ots"
          src={DailyAverageTrafficImage}
          label={t('analytics.trafficAwarenessPage.overview.ots.dailyAverage')}
          showEmpty={showEmpty}
          value={currentDailyAverageTraffic}
          noPreviousData={isAllTime || previousDailyAverageTraffic === 0}
        />

        <TotalCard
          change={getPercentChange(
            currentTotalDwellTime,
            previousTotalDwellTime,
          )}
          key="totalDwellTime"
          type="dwellTime"
          label={t('analytics.trafficAwarenessPage.overview.dwellTime.total')}
          src={TotalDwellTimeImage}
          showEmpty={showEmpty}
          value={currentTotalDwellTime}
          unit=" sec"
          noPreviousData={isAllTime || previousTotalDwellTime === 0}
        />
        <AveragePerDayCard
          change={getPercentChange(
            currentAverageDwellTimePerVisitor,
            previousAverageDwellTimePerVisitor,
          )}
          key="averageDwellTimePerDay"
          type="dwellTime"
          src={DailyAverageDwellTimeImage}
          label={t(
            'analytics.trafficAwarenessPage.overview.dwellTime.avgPerVisitor',
          )}
          showEmpty={showEmpty}
          value={currentAverageDwellTimePerVisitor}
          unit=" sec"
          noPreviousData={isAllTime || previousAverageDwellTimePerVisitor === 0}
        />
        {showImpressions && (
          <>
            <TotalCard
              change={getPercentChange(
                currentTotalImpressions,
                previousTotalImpressions,
              )}
              key="totalImpressions"
              type="impressions"
              label={t(
                'analytics.trafficAwarenessPage.overview.impressions.total',
              )}
              src={TotalImpressionsImage}
              showEmpty={showEmpty}
              value={currentTotalImpressions}
              noPreviousData={isAllTime || previousTotalImpressions === 0}
            />
            <AveragePerWeekCard
              change={getPercentChange(
                currentWeeklyAverageImpressions,
                previousWeeklyAverageImpressions,
              )}
              key="averageImpressionsPerWeek"
              type="impressions"
              src={WeeklyAverageImpressionsImage}
              label={t(
                'analytics.trafficAwarenessPage.overview.impressions.weeklyAverage',
              )}
              showEmpty={showEmpty}
              value={currentWeeklyAverageImpressions}
              noPreviousData={
                isAllTime || previousWeeklyAverageImpressions === 0
              }
            />
            <AveragePerDayCard
              change={getPercentChange(
                currentDailyAverageImpressions,
                previousDailyAverageImpressions,
              )}
              key="averageImpressionsPerDay"
              type="impressions"
              src={DailyAverageImpressionsImage}
              label={t(
                'analytics.trafficAwarenessPage.overview.impressions.dailyAverage',
              )}
              showEmpty={showEmpty}
              value={currentDailyAverageImpressions}
              noPreviousData={
                isAllTime || previousDailyAverageImpressions === 0
              }
            />
          </>
        )}
      </div>
    </div>
  );
};

export default TrafficAwarenessOverview;
