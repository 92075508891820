import Card from '@raydiant/raydial/components/Card';
import Page from '../../components/Page';
import PageLayout from '../../components/PageLayout/PageLayout';
import PageTitle from '../../components/PageLayout/PageTitle';
import Text from '@raydiant/raydial/components/Text';
import TextField from '@raydiant/raydial/components/TextField';
import Button from '@raydiant/raydial/components/Button';
import { useEffect, useState } from 'react';
import history from '../../history';
import cn from 'classnames';
import * as paths from '../../routes/paths';
import { Footer, Header } from './components';
import ToastSetupWizard from './ToastSetupWizard';
import { useToastState } from '@raydiant/raydial/components/Toast/ToastProvider';
import LoadingModal from '@raydiant/raydial/components/LoadingModal';
import useConnectors from '../../hooks/useConnectors';
import { Connector } from '@raydiant/api-client-js';
import useCreateConnection from '../../hooks/useCreateConnection';
import SquareSetupWizard from './SquareSetupWizard';
import { useLocation } from 'react-router-dom';

const IntegrationDetailsStep = ({
  onNext,
  name,
  onChange,
}: {
  onNext: () => void;
  name: string;
  onChange: (integrationName: string) => void;
}) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onNext();
  };

  const isValid = name.length > 0;

  const handleCancel = () => {
    history.push(paths.productHubIntegrations());
  };

  return (
    <>
      <Header heading="Add an Integration" subheading="Let's get started" />
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <TextField
            label="Integration Name"
            placeholder="Enter integration name"
            description="This could be your restaurant or brand, eg: The Burger Barn"
            onChange={onChange}
            value={name}
            autoFocus
          />
        </div>

        <Footer>
          <Button
            size="large"
            variant="secondary"
            className="flex-1"
            onPress={handleCancel}
          >
            Cancel
          </Button>
          <Button
            size="large"
            variant="primary"
            className="flex-1"
            type="submit"
            isDisabled={!isValid}
          >
            Next
          </Button>
        </Footer>
      </form>
    </>
  );
};

const SelectIntegrationStep = ({
  onNext,
  onPrevious,
  connectors,
  onChange,
  value,
}: {
  onNext: () => void;
  onPrevious: () => void;
  value: string;
  onChange: (connectorId: string) => void;
  connectors: Connector[];
}) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onNext();
  };

  const handlePrevious = () => {
    onPrevious();
  };

  const setConnectorId = (connectorId: string) => {
    onChange(connectorId);
  };

  const isValid = value.length > 0;

  return (
    <>
      <Header
        heading="Add an Integration"
        subheading="Choose an integration to add"
      />
      <form onSubmit={handleSubmit}>
        <div className="px-4">
          {connectors.map((connector) => (
            <Button
              variant="unstyled"
              key={connector.name}
              className={cn(
                'flex items-center gap-4 mb-4 rounded-xl  border p-4 w-full text-start hover:bg-gray-100 border-gray-200',
                connector.id === value && 'bg-gray-100',
              )}
              onPress={() => {
                setConnectorId(connector.id);
              }}
            >
              <div className="size-16 rounded-lg flex items-center justify-center">
                <img src={connector.thumbnailUrl} alt="connector" />
              </div>
              <div>
                <Text as="h3" variant="smallSubheader">
                  {connector.displayName}
                </Text>
                <Text className="text-lg text-gray-400 capitalize">
                  Connect with {connector.name}
                </Text>
              </div>
            </Button>
          ))}
        </div>

        <Footer>
          <Button
            size="large"
            variant="secondary"
            className="flex-1"
            onPress={handlePrevious}
          >
            Back
          </Button>
          <Button
            size="large"
            variant="primary"
            className="flex-1"
            type="submit"
            isDisabled={!isValid}
          >
            Next
          </Button>
        </Footer>
      </form>
    </>
  );
};

const ProductHubCreateIntegrationPage = () => {
  const connectorsQuery = useConnectors();
  const [preliminarySteps, setPreliminaryStep] = useState<
    'integrationDetails' | 'selectIntegration' | 'complete'
  >('integrationDetails');
  const state = useToastState();
  const [formData, setFormData] = useState({
    name: '',
    connectorId: '',
    locationGuids: [''],
    authId: '',
  });
  const createConnectionQuery = useCreateConnection();
  const { mutateAsync: createConnection } = createConnectionQuery;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const connector = searchParams.get('connector');
  const connectorId = searchParams.get('connectorId');
  const name = searchParams.get('name');
  const authId = searchParams.get('authId');

  const resetQueryParams = () => {
    const currentLocation = history.location;
    const pathname = currentLocation.pathname;

    history.replace({
      pathname,
      search: '',
    });
  };

  // Handle Square OAuth redirect
  useEffect(() => {
    if (!connector || !connectorId || !name || !authId) {
      return;
    }

    if (
      connector === 'square' &&
      formData.connectorId !== connectorId &&
      formData.authId !== authId &&
      formData.name !== name
    ) {
      setPreliminaryStep('complete');
      setFormData({ ...formData, connectorId, name, authId });

      // Ensure query params are cleared to prevent the Toast/Square integration wizard from reappearing
      resetQueryParams();
    }
  }, [authId, connector, connectorId, formData, name]);

  if (!connectorsQuery || connectorsQuery.isLoading) {
    return (
      <Page>
        <LoadingModal isOpen={true} />
      </Page>
    );
  }

  if (!connectorsQuery.data) {
    return null;
  }

  const { data: connectors } = connectorsQuery.data;

  if (!connectors) {
    return null;
  }

  const selectedIntegrationName = connectors.find(
    (c) => c.id === formData.connectorId,
  )?.name;

  if (createConnectionQuery.isLoading) {
    return (
      <Page>
        <LoadingModal isOpen={true}>
          <Text className="capitalize">
            Connecting with {selectedIntegrationName}
          </Text>
        </LoadingModal>
      </Page>
    );
  }

  const handleToastSubmit = async () => {
    await createConnection({
      name: formData.name,
      connectorId: formData.connectorId,
      settings: {
        restaurantGuids: formData.locationGuids,
      },
    });

    state.add({
      title: 'Integration Added',
      description: 'Toast integration added successfully',
    });
    history.push(paths.productHubIntegrations());
  };

  const handleSquareSubmit = async () => {
    await createConnection({
      name: formData.name,
      connectorId: formData.connectorId,
      settings: {
        authId: formData.authId,
      },
    });

    state.add({
      title: 'Integration Added',
      description: 'Square integration added successfully',
    });
    history.push(paths.productHubIntegrations());
  };

  const handleOnExitConnectorIntegrationWizard = () => {
    setPreliminaryStep('selectIntegration');
    setFormData({
      ...formData,
      connectorId: '',
      locationGuids: [''],
      authId: '',
    });
  };

  return (
    <Page>
      <PageLayout title={<PageTitle title="Integrations" />}>
        <Card className="text-black max-w-[628px]">
          {preliminarySteps !== 'complete' ? (
            <div>
              {preliminarySteps === 'integrationDetails' && (
                <IntegrationDetailsStep
                  onNext={() => setPreliminaryStep('selectIntegration')}
                  name={formData.name}
                  onChange={(name) => setFormData({ ...formData, name })}
                />
              )}
              {preliminarySteps === 'selectIntegration' && (
                <SelectIntegrationStep
                  onNext={() => setPreliminaryStep('complete')}
                  onPrevious={() => setPreliminaryStep('integrationDetails')}
                  value={formData.connectorId}
                  onChange={(connectorId: string) =>
                    setFormData({ ...formData, connectorId })
                  }
                  connectors={connectors}
                />
              )}
            </div>
          ) : (
            <>
              {selectedIntegrationName === 'toast' && (
                <ToastSetupWizard
                  onSubmit={handleToastSubmit}
                  locationGuids={formData.locationGuids}
                  onLocationGuidsChange={(locationGuids) =>
                    setFormData({ ...formData, locationGuids })
                  }
                  onExit={handleOnExitConnectorIntegrationWizard}
                />
              )}

              {selectedIntegrationName === 'square' && (
                <SquareSetupWizard
                  onSubmit={handleSquareSubmit}
                  onExit={handleOnExitConnectorIntegrationWizard}
                  formData={{
                    connectorId: formData.connectorId,
                    name: formData.name,
                    authId: formData.authId,
                  }}
                />
              )}
            </>
          )}
        </Card>
      </PageLayout>
    </Page>
  );
};

export default ProductHubCreateIntegrationPage;
