import { SVGProps } from 'react';

interface TrashProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const Trash = ({ size = 18, ...props }: TrashProps) => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.37695 2.21875C6.27734 2.21875 6.21094 2.28516 6.14453 2.35156L5.51367 3.28125H10.3281L9.69727 2.35156C9.66406 2.28516 9.56445 2.21875 9.46484 2.21875H6.37695ZM12.2539 3.28125H12.6855H14.3125H14.5781C15.0098 3.28125 15.375 3.64648 15.375 4.07812C15.375 4.54297 15.0098 4.875 14.5781 4.875H14.1797L13.3828 15.666C13.2832 16.7949 12.3867 17.625 11.2578 17.625H4.58398C3.45508 17.625 2.55859 16.7949 2.45898 15.666L1.66211 4.875H1.29688C0.832031 4.875 0.5 4.54297 0.5 4.07812C0.5 3.64648 0.832031 3.28125 1.29688 3.28125H1.5625H3.15625H3.58789L4.81641 1.45508C5.14844 0.957031 5.74609 0.625 6.37695 0.625H9.46484C10.0957 0.625 10.6934 0.957031 11.0254 1.45508L12.2539 3.28125ZM12.5859 4.875H3.25586L4.05273 15.5664C4.08594 15.832 4.31836 16.0312 4.58398 16.0312H11.2578C11.5234 16.0312 11.7559 15.832 11.7891 15.5664L12.5859 4.875Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Trash;
