import { Theme } from 'raydiant-elements/theme';
import { makeStyles, createStyles } from 'raydiant-elements/styles';

export default makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      marginBottom: theme.spacing(5),
    },
  });
});
