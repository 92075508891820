import { SVGProps } from 'react';

interface WifiSlashProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const WifiSlash = ({ size = 18, ...props }: WifiSlashProps) => {
  return (
    <svg
      width="23"
      height="18"
      viewBox="0 0 23 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.37723 0.419486L5.31105 2.68653C7.27805 1.78638 9.44507 1.2863 11.7788 1.2863C15.7461 1.2863 19.3801 2.78655 22.1472 5.22029C22.4806 5.52034 22.5139 6.02042 22.2472 6.35381C21.9472 6.6872 21.4471 6.72054 21.1137 6.42049C18.6133 4.22012 15.3461 2.88656 11.7788 2.88656C10.0118 2.88656 8.31155 3.21995 6.74462 3.82005L11.0453 7.18728C11.2787 7.18728 11.5121 7.15394 11.7788 7.15394C14.3459 7.15394 16.6796 8.15411 18.4466 9.75437C18.7466 10.0544 18.78 10.5545 18.4799 10.8879C18.1799 11.2213 17.6798 11.2546 17.3464 10.9546C16.2129 9.88773 14.8126 9.18761 13.2124 8.88756L22.1138 15.8887C22.4806 16.1554 22.5473 16.6555 22.2472 16.9889C21.9805 17.3556 21.4804 17.4223 21.147 17.1223L1.4104 1.65302C1.04367 1.38631 0.976996 0.88623 1.27705 0.552841C1.54376 0.186114 2.04384 0.119436 2.37723 0.419486ZM3.64411 5.45366C3.24404 5.75371 2.81063 6.0871 2.41057 6.42049C2.07718 6.72054 1.5771 6.6872 1.31038 6.35381C1.01033 6.02042 1.04367 5.52034 1.37706 5.22029C1.67711 4.95357 2.0105 4.68686 2.34389 4.42015L3.64411 5.45366ZM8.64494 9.38765C7.71145 9.75437 6.87798 10.2878 6.17786 10.9546C5.84447 11.2546 5.34439 11.2213 5.04434 10.8879C4.74429 10.5545 4.77763 10.0544 5.07768 9.75437C5.74446 9.18761 6.44457 8.68753 7.24471 8.25412L8.64494 9.38765ZM13.6458 14.3551C13.6458 15.0553 13.279 15.6554 12.7123 15.9887C12.1122 16.3221 11.4121 16.3221 10.8453 15.9887C10.2452 15.6554 9.91182 15.0553 9.91182 14.3551C9.91182 13.6884 10.2452 13.0883 10.8453 12.7549C11.4121 12.4215 12.1122 12.4215 12.7123 12.7549C13.279 13.0883 13.6458 13.6884 13.6458 14.3551Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default WifiSlash;
