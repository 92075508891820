import { Grid } from '@material-ui/core';
import cn from 'classnames';

import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import Heading from 'raydiant-elements/core/Heading';
import Text from 'raydiant-elements/core/Text';

import ImageContentCard from './components/ImageContentCard';
import ContentCard from './components/ContentCard';
import ColorCard from './components/ColorCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      paddingBottom: theme.spacing(2),
      color: '#000000',
    },
    box: {
      color: '#fff',
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      overflow: 'hidden',
    },
    whiteText: {
      color: '#ffffff',
    },
    stretchImage: {
      alignItems: 'stretch',
    },
    list: {
      padding: '1rem',
      marginBlockStart: 'unset',
      marginBlockEnd: 'unset',
    },
  }),
);

const DXModalContent = () => {
  const classes = useStyles();

  return (
    <Grid>
      <ImageContentCard
        className={classes.img}
        imageSrc="https://assets.raydiant.com/marketplace/dx-modal-1.png"
        imageAlt="dx-modal-1"
        imageDirectionOnDesktop="right"
        imageDirectionOnMobile="bottom"
      >
        <ContentCard>
          <Heading size={2} weight={600} className={classes.heading}>
            Create New Apps in Minutes
          </Heading>
          <Text>
            <b>
              Use Raydiant’s Developer Portal to take your in-location digital
              experiences to new levels.
              <br />
            </b>
          </Text>
          <Text>
            Developer Portal users can create custom apps to further extend the
            Raydiant app ecosystem in just a few minutes using a point-and-click
            interface. These Raydiant apps mirror the content of a web app into
            our player and can optionally pass user-supplied parameters to that
            web app. Examples include:
          </Text>
        </ContentCard>
      </ImageContentCard>

      <ColorCard type="dx">
        <ContentCard className={classes.whiteText}>
          <Grid item container spacing={2} justifyContent="space-between">
            <Grid item xs={12} md={3}>
              <Text>
                A roofing company creates a schedule of jobs and workers in its
                construction management system. It then displays this weekly
                schedule on digital signage in the corporate office to keep all
                workers informed and on track.
              </Text>
            </Grid>
            <Grid item xs={12} md={3}>
              <Text>
                A charitable organization builds engaging, informing content
                comprising images, video, scrolling text, etc. in a conference
                management platform, and then displays this content on digital
                signage at various events.
              </Text>
            </Grid>
            <Grid item xs={12} md={3}>
              <Text>
                A restaurant operator creates a static digital menu using design
                software and then displays that digital menu on menu boards in
                the restaurant.
              </Text>
            </Grid>
          </Grid>
        </ContentCard>
      </ColorCard>

      <ImageContentCard
        className={cn(classes.img, classes.stretchImage)}
        imageSrc="https://assets.raydiant.com/marketplace/dx-modal-2.png"
        imageAlt="dx-modal-2"
        imageDirectionOnDesktop="left"
        imageDirectionOnMobile="top"
      >
        <ContentCard>
          <Heading size={2} weight={600} className={classes.heading}>
            Super-charge Your Apps with Powerful APIs
          </Heading>
          <Text>
            <b>
              Use powerful APIs to create sophisticated, connected apps
              <br />
            </b>
          </Text>
          <Text>
            Raydiant’s On-Brand Menu API allows restaurants to create 100%
            on-brand digital menu boards powered by their point of sale (POS)
            data. Restaurant operators can manage and update digital menu
            boards, including items, descriptions, prices, calories, categories,
            and availability, directly from the POS and see changes reflected on
            their digital menus in just a few seconds or minutes. With a custom
            menu app based on HTML, CSS, and JavaScript, you retain complete
            control over the branding and layout of your digital menus.
          </Text>
        </ContentCard>
      </ImageContentCard>

      <ImageContentCard
        className={cn(classes.img, classes.stretchImage)}
        imageSrc="https://assets.raydiant.com/marketplace/dx-modal-3.jpeg"
        imageAlt="dx-modal-3"
        imageDirectionOnDesktop="right"
        imageDirectionOnMobile="top"
      >
        <ContentCard>
          <Heading size={2} weight={600} className={classes.heading}>
            Get Off To a Running Start
          </Heading>
          <Text>
            <b>
              Get up to speed quickly with developer documentation, sample apps,
              and best practices
              <br />
            </b>
          </Text>
          <Text>
            Our comprehensive developer documentation site was built with you in
            mind and features detailed documentation on topics you care most
            about, including:
          </Text>
          <Grid item xs={12} md={12}>
            <ul className={classes.list}>
              <li>How to build and deploy an app</li>
              <li>Best practices for building apps for digital signage</li>
              <li>
                Complete API documentation, including API exploration in the
                browser
              </li>
              <li>
                Code snippets and fully built sample apps using a variety of
                programming languages and technologies (NextJS, VanillaJS, etc.)
              </li>
              <li>How to generate and manage API keys</li>
              <li>POS-specific guidance</li>
            </ul>
          </Grid>
        </ContentCard>
      </ImageContentCard>

      <ColorCard type="dx">
        <ContentCard className={classes.whiteText}>
          <Heading size={2} weight={300} className={classes.box}>
            These tools are specifically designed to quickly build your
            confidence and
            <br /> efficiently build your app. Collaboration and community
            features
            <br />
            coming soon!
          </Heading>
        </ContentCard>
      </ColorCard>
    </Grid>
  );
};

export default DXModalContent;
