import { FC } from 'react';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import Link from 'raydiant-elements/core/Link';
import {
  RuleToken,
  RuleError,
  getRuleErrorMessage,
  isInitialRuleToken,
} from './ruleTokens';
import useStyles from './RuleTokenInput.styles';

export interface RuleTokenHelperTextProps {
  tokens: RuleToken[];
  errors: RuleError[];
  selectedIndex: number;
}

const RuleTokenHelperText: FC<RuleTokenHelperTextProps> = ({
  tokens,
  errors,
  selectedIndex,
}) => {
  const classes = useStyles();

  const errorAtSelectedIndex = errors.find((e) => e.index === selectedIndex);
  // The rule is valid if it has no errors and has non-empty tokens.
  const isValidRule = errors.length === 0 && tokens.length > 0;
  // The is empty if there are no errors and has no tokens OR if it has exactly
  // one token and that token is the initial rule token.
  const isEmptyRule =
    (errors.length === 0 && tokens.length === 0) ||
    (tokens.length === 1 && isInitialRuleToken(tokens[0]));

  const hasError = errors.length > 0 && !isEmptyRule;
  const hasErrorAtSelected = hasError && !!errorAtSelectedIndex;
  const hasErrorButNotSelected = hasError && !errorAtSelectedIndex;

  return (
    <InputHelperText indent success={errors.length === 0}>
      {isEmptyRule && (
        <>
          <span>Build rule(s) to trigger playback of content.</span>
          &nbsp;
          <Link
            href="https://support.raydiant.com/hc/en-us/articles/4407734122772"
            target="_blank"
          >
            Learn more
          </Link>
        </>
      )}
      {isValidRule && (
        <>
          <span>Valid!</span>
          &nbsp;
          <Link
            href="https://support.raydiant.com/hc/en-us/articles/4407734122772"
            target="_blank"
          >
            Learn more
          </Link>
        </>
      )}
      {hasErrorAtSelected && errorAtSelectedIndex && (
        <>
          <span className={classes.helperWarning}>
            {getRuleErrorMessage(errorAtSelectedIndex.error)}
          </span>
          &nbsp;
          <Link href="">Learn more</Link>
        </>
      )}
      {hasErrorButNotSelected && (
        <>
          <span className={classes.helperWarning}>
            Select invalid elements for help.
          </span>
          &nbsp;
          <Link
            href="https://support.raydiant.com/hc/en-us/signin?return_to=https%3A%2F%2Fsupport.raydiant.com%2Fhc%2Fen-us%2Farticles%2F4407734122772"
            target="_blank"
          >
            Learn more
          </Link>
        </>
      )}
    </InputHelperText>
  );
};

export default RuleTokenHelperText;
