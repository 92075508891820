import { ButtonBase } from '@material-ui/core';
import Heading from 'raydiant-elements/core/Heading';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import FullscreenOutlinedIcon from '@material-ui/icons/FullscreenOutlined';
import DeviceAiSettingsVideoControls from './DeviceAiSettingsVideoControls';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

interface DeviceAiSettingsVideoHeaderProps {
  fullScreen: boolean;
  hasError: boolean;
  isPlaying: boolean;
  isLoadingVideo: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  onPlay?: () => void;
  onStop?: () => void;
  showMaximize?: boolean;
  showMinimize?: boolean;
}

const DeviceAiSettingsVideoHeader = ({
  fullScreen = false,
  hasError = false,
  isPlaying,
  isLoadingVideo = false,
  onClose,
  onOpen,
  onPlay,
  onStop,
  showMaximize = false,
  showMinimize = false,
}: DeviceAiSettingsVideoHeaderProps) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.heading}>
        <div className="icon">
          <div className="playback">
            <VideocamOutlinedIcon />
            <Heading size={4}>Playback</Heading>
          </div>
          {fullScreen && (
            <DeviceAiSettingsVideoControls
              disabled={hasError || isLoadingVideo}
              isPlaying={isPlaying}
              onPlay={onPlay}
              onStop={onStop}
            />
          )}
        </div>

        {!fullScreen && (
          <DeviceAiSettingsVideoControls
            disabled={hasError || isLoadingVideo}
            isPlaying={isPlaying}
            onPlay={onPlay}
            onStop={onStop}
          />
        )}

        {showMinimize && (
          <ButtonBase className="icon" onClick={onClose}>
            <Heading size={4}>Minimize</Heading>
            <FullscreenExitIcon />
          </ButtonBase>
        )}
        {showMaximize && (
          <ButtonBase className="icon" onClick={onOpen}>
            <Heading size={4}>Maximize</Heading>
            <FullscreenOutlinedIcon />
          </ButtonBase>
        )}
      </div>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    heading: {
      display: 'flex',
      justifyContent: 'space-between',
      height: 10,
      marginBottom: 13,
      '& .icon': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .playback': {
          marginRight: 12,
        },
        '& h5': {
          fontFamily: 'Gilroy',
          fontSize: 9,
          fontWeight: 'bold',
          lineHeight: 1.11,
          letterSpacing: 1.42,
          textTransform: 'uppercase',
          color: theme.palette.grey[600],
        },
      },
    },
  });
});

export default DeviceAiSettingsVideoHeader;
