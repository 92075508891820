import { useTranslation } from 'react-i18next';
import averageInteractionDistribution from '../../../assets/average-interaction-distribution.svg';
import DataCard, { DataCardDisplayProps } from './DataCard';

type AverageDistributionProps = Omit<
  DataCardDisplayProps,
  'change' | 'value'
> & {
  value: number[];
};

const AverageDistributionCard = ({
  value,
  type,
  showEmpty = false,
}: AverageDistributionProps) => {
  const { t } = useTranslation();
  const [first, second] = value;

  return (
    <DataCard type={type}>
      <img
        className="image"
        src={averageInteractionDistribution}
        alt={t('analytics.distributionPickupsToClicks')!}
      />
      <div className="w-full flex text">
        <div className="ml-6">
          <p className="display-value">
            {showEmpty ? '— —' : `${first} | ${second}`}
          </p>
          <p className="text-sm font-semibold align-top">
            {t('analytics.distributionPickupsToClicks')}
          </p>
          <p className="text-[0.7rem]">{t('analytics.percentageOfTotal')}</p>
        </div>
      </div>
    </DataCard>
  );
};

export default AverageDistributionCard;
