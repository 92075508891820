import Card from '@raydiant/raydial/components/Card';
import Page from '../../components/Page';
import PageLayout from '../../components/PageLayout/PageLayout';
import PageTitle from '../../components/PageLayout/PageTitle';
import TextField from '@raydiant/raydial/components/TextField';
import Button from '@raydiant/raydial/components/Button';
import history from '../../history';
import * as paths from '../../routes/paths';
import { useState } from 'react';
import { Footer, Header } from './components';
import { useToastState } from '@raydiant/raydial/components/Toast/ToastProvider';
import LoadingModal from '@raydiant/raydial/components/LoadingModal';
import useUpdateConnection from '../../hooks/useUpdateConnection';
import { Connection } from '@raydiant/api-client-js';

type ProductHubEditSquareIntegrationProps = {
  connection: Connection;
};

const ProductHubEditSquareIntegrationPage = (
  props: ProductHubEditSquareIntegrationProps,
) => {
  const state = useToastState();
  const { connection } = props;
  const settings = connection.settings as { authId: string[] };
  const [formData, setFormData] = useState({
    name: connection.name || '',
    authId: settings,
  });
  const updateConnectionQuery = useUpdateConnection();
  const { mutateAsync: updateConnection } = updateConnectionQuery;
  const isValid = formData.name.length > 0;

  if (updateConnectionQuery.isLoading) {
    return (
      <Page>
        <LoadingModal isOpen={true} />
      </Page>
    );
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await updateConnection({
      id: connection.id,
      params: {
        name: formData.name,
        settings: {
          authId: formData.authId,
        },
      },
    });

    state.add({
      title: 'Integration Updated',
      description: 'Integration settings have been updated successfully',
    });
    history.push(paths.productHubIntegrations());
  };

  const handleOnNameChange = (name: string) => {
    setFormData({ ...formData, name });
  };

  return (
    <Page>
      <PageLayout title={<PageTitle title="Integrations" />}>
        <Card className="text-black max-w-[628px]">
          <Header
            heading="Edit Integration Settings"
            subheading="Manage Square integration"
          />
          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <TextField
                label="Integration Name"
                placeholder="Enter integration name"
                description="This could be your restaurant or brand, eg: The Burger Barn"
                onChange={handleOnNameChange}
                value={formData.name}
                aria-label="integration name"
                autoFocus
              />
            </div>

            <Footer>
              <Button
                size="large"
                variant="primary"
                className="flex-1"
                type="submit"
                isDisabled={!isValid}
              >
                Save
              </Button>
            </Footer>
          </form>
        </Card>
      </PageLayout>
    </Page>
  );
};

export default ProductHubEditSquareIntegrationPage;
