import { useLocation } from 'react-router';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Center from 'raydiant-elements/layout/Center';
import { GetTrafficAwareness, LocationTraffic } from '@raydiant/api-client-js';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import { Popover } from '@headlessui/react';
import Hidden from 'raydiant-elements/layout/Hidden';

import Page from '../../../components/Page';
import PageLayout from '../../../components/PageLayout/PageLayout';
import PageTitle from '../../../components/PageLayout/PageTitle';
import AnalyticsFilter from '../AnalyticsFilter/AnalyticsFilter';
import AnalyticsPageSkeleton from '../AnalyticsPageSkeleton';
import { getApiQueryParams } from '../AnalyticsFilter/utils';
import TopLocationsTable from './TopLocationsTable';
import { TableSort } from '../../../components/DataTable/DataTableHeader';
import { paginate, sort } from '../utils';
import Empty from '../../../components/Empty/Empty';
import Error from '../../../components/Error/Error';
import QuestionMarkIcon from '../../../components/QuestionMarkIcon';
import TrafficFunnelChart from './TrafficFunnelChart';
import TrafficByHourChart from './TrafficByHourChart';
import DemographicBreakdownChart from './DemographicBreakdownChart';
import AgeDistributionChart from './AgeDistributionChart';
import AverageGenderDistributionChart from './AverageGenderDistributionChart';
import TopLocationsChart from './TopLocationsChart';
import DwellTimesByHourChart from './DwellTimesByHourChart';
import ShopperEngagementChart from './ShopperEngagementChart';
import useTrafficAwareness from '../../../hooks/useTrafficAwareness';
import TrafficAwarenessOverview from './TrafficAwarenessOverview';
import NonVisitorInsightLayout from './NonVisitorInsightLayout';
import {
  selectIsVIOnly,
  selectIsVIEnabled,
  selectIsRXEnabled,
  selectUserProfile,
} from '../../../selectors/user';
import VisitorInsight from './VisitorInsightCarousel/VisitorInsightCard';
import VIOnlyLayout from './VIOnlyLayout';
import DwellHistogramChart from './DwellHistogramChart';

export interface TopLocationsData {
  data: LocationTraffic[];
  pageCount: number;
}

const defaultLocationsSort: TableSort<LocationTraffic> = {
  direction: 'desc',
  key: 'ots',
};

const TrafficAwarenessPage = () => {
  const { t } = useTranslation();

  const { search } = useLocation();
  const query = getApiQueryParams(search);
  const { data, isError, isLoading } = useTrafficAwareness(query);
  const isRXEnabled = useSelector(selectIsRXEnabled);
  const hasQueryParams = Object.values(query).length > 0;

  const [topLocationsTableSort, setTopLocationsTableSort] =
    useState<TableSort<LocationTraffic>>(defaultLocationsSort);
  const [topLocationsCurrentPage, setTopLocationsCurrentPage] = useState(0);

  // Selectors
  const isVIOnly = useSelector(selectIsVIOnly);
  const isVIEnabled = useSelector(selectIsVIEnabled);
  const isVIDisabled = !isVIOnly && !isVIEnabled;
  const isAllTime = search.includes('dateRangeKey=allTime');
  const currentUser = useSelector(selectUserProfile);

  const topTenLocations = sort<LocationTraffic>(
    data?.trafficByLocation?.filter(
      (item) => !(item.ots === null && item.impressions === null),
    )!,
    defaultLocationsSort.key,
    defaultLocationsSort.direction,
  )?.slice(0, 10);

  const topLocationsData = useMemo(() => {
    const sortedItems = sort<LocationTraffic>(
      data?.trafficByLocation!,
      topLocationsTableSort.key,
      topLocationsTableSort.direction,
    );
    const paginatedData = paginate<LocationTraffic>(sortedItems);

    return {
      data: paginatedData[topLocationsCurrentPage]?.map((item, index) => ({
        rank: index + 1,
        ...item,
      })),
      pageCount: paginatedData?.length,
    };
  }, [data?.trafficByLocation, topLocationsTableSort, topLocationsCurrentPage]);

  useEffect(() => {
    setTopLocationsCurrentPage(0);
  }, [isLoading]);

  const hasData =
    data &&
    Object.keys(data)
      .filter(
        (key) => !['trafficByDay', 'trafficByPreviousPeriod'].includes(key),
      )
      ?.some((key) => data[key as keyof GetTrafficAwareness].length > 0) &&
    data?.trafficByDay
      ?.map((value) => value.ots + value.avgDwellTime + value.impressions)
      .reduce((current, next) => {
        return current + next;
      }, 0) > 0;
  const showEmptyState = !isLoading && !hasData;
  const showErrorState = !isLoading && isError;
  const showCharts = !isLoading && !showEmptyState && !showErrorState;
  const showSkeleton = isLoading && !hasQueryParams;
  const showFilter = hasQueryParams;
  const showLoader = isLoading && hasQueryParams && !isError;
  const showShopperInsights = !isLoading && !showErrorState;

  const handleTopLocationsCurrentPage = (page: number) => {
    setTopLocationsCurrentPage(page);
  };

  const handleTopLocationsTableSort = (sort: TableSort<LocationTraffic>) => {
    setTopLocationsTableSort(sort);
  };

  if (!currentUser) {
    return (
      <Page>
        <PageLayout
          sectionClassName={!isVIDisabled && showErrorState ? 'h-full' : ''}
          title={
            <PageTitle
              title={t('analytics.trafficAwarenessPage.title')}
              subTitle={t('analytics.trafficAwarenessPage.subTitle')!}
            />
          }
        >
          <AnalyticsFilter className="mb-8" />
          <Center className="h-[500px]">
            <CircularProgress size={30}></CircularProgress>
          </Center>
        </PageLayout>
      </Page>
    );
  }

  return (
    <Page>
      <PageLayout
        sectionClassName={!isVIDisabled && showErrorState ? 'h-full' : ''}
        title={
          <PageTitle
            title={t('analytics.trafficAwarenessPage.title')}
            subTitle={t('analytics.trafficAwarenessPage.subTitle')!}
          />
        }
        isExportNeeded
      >
        {isVIDisabled && <NonVisitorInsightLayout />}
        {(isVIEnabled || isVIOnly) && (
          <>
            {showSkeleton && <AnalyticsPageSkeleton />}
            {showFilter && <AnalyticsFilter className="mb-8" />}
            {showLoader && (
              <Center className="h-[500px]">
                <CircularProgress size={30}></CircularProgress>
              </Center>
            )}
            {showShopperInsights && (
              <TrafficAwarenessOverview
                data={data!}
                showEmpty={showEmptyState || showErrorState}
                showImpressions={isVIEnabled}
                isAllTime={isAllTime}
              />
            )}
            {showEmptyState && (
              <div className="card bg-[#fafafa] w-full h-full items-center justify-center">
                <Empty>
                  <a
                    href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                    rel="noreferrer"
                    target="_blank"
                  >
                    {t('analytics.learnmore')}
                  </a>
                </Empty>
              </div>
            )}
            {showErrorState && (
              <div className="card bg-[#fafafa] w-full h-full items-center justify-center">
                <Error>
                  <a
                    href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                    rel="noreferrer"
                    target="_blank"
                  >
                    {t('analytics.learnmore')}
                  </a>
                </Error>
              </div>
            )}

            {showCharts && (
              <>
                {isVIOnly && (
                  <VIOnlyLayout
                    data={data}
                    isError={isError}
                    topTenLocationsData={topTenLocations}
                    topLocationsData={topLocationsData}
                    topLocationsCurrentPage={topLocationsCurrentPage}
                    topLocationsTableSort={topLocationsTableSort}
                    onTopLocationsCurrentPage={handleTopLocationsCurrentPage}
                    onTopLocationsTableSort={handleTopLocationsTableSort}
                  />
                )}
                {!isVIOnly && (
                  <>
                    <div className="flex gap-3 items-center mb-6 ml-6">
                      <h2 className="text-black text-xl font-bold">
                        {t('analytics.trafficAwarenessPage.trends.title')}
                      </h2>
                      <Popover className="relative">
                        <Popover.Button>
                          <QuestionMarkIcon />
                        </Popover.Button>
                        <Popover.Panel className="absolute z-10 card popover">
                          <div className="overflow-hidden">
                            <p className="mb-3">
                              {t(
                                'analytics.trafficAwarenessPage.trends.tooltip',
                              )}
                            </p>
                          </div>
                          <a
                            href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                            rel="noreferrer"
                            target="_blank"
                          >
                            {t('analytics.learnmore')}
                          </a>
                        </Popover.Panel>
                      </Popover>
                    </div>
                    <div className="card">
                      <div className="flex flex-col w-full flex-wrap md:flex-row">
                        <div className="flex-1" style={{ minWidth: '330px' }}>
                          {data?.trafficSummary &&
                            data.trafficSummary.length > 0 && (
                              <TrafficFunnelChart
                                data={data.trafficSummary[0]}
                                showSessions={isRXEnabled}
                                sessions={data.interactions[0]?.sessions || 0}
                              />
                            )}
                        </div>
                        <Hidden smDown>
                          <div
                            className="flex flex-1 items-center md:p-9"
                            style={{}}
                          >
                            <VisitorInsight />
                          </div>
                        </Hidden>
                        <Hidden smUp>
                          <div className="flex justify-end items-center mt-3 mb-3">
                            <VisitorInsight />
                          </div>
                        </Hidden>
                      </div>

                      {data?.trafficByDay && !isError && (
                        <ShopperEngagementChart
                          data={data?.trafficByDay}
                          showAll
                        />
                      )}

                      {data?.trafficSummary &&
                        data.trafficSummary.length > 0 && (
                          <DwellHistogramChart data={data.trafficSummary[0]} />
                        )}

                      {data?.trafficByLocation && !isError && (
                        <div className="mb-6">
                          <TopLocationsChart data={topTenLocations} showAll />
                          <TopLocationsTable
                            currentPage={topLocationsCurrentPage}
                            data={topLocationsData?.data}
                            onPageChange={(page) => {
                              setTopLocationsCurrentPage(page);
                            }}
                            onSort={(sort) => {
                              setTopLocationsTableSort({
                                ...topLocationsTableSort,
                                ...sort,
                              });
                              setTopLocationsCurrentPage(0);
                            }}
                            pageCount={topLocationsData?.pageCount}
                            sort={topLocationsTableSort}
                            showAll
                            showPagination={data?.trafficByLocation.length > 10}
                          />
                        </div>
                      )}

                      {data?.trafficByWeekdayHour && (
                        <div className="flex flex-col w-full mt-6 mb-6 md:flex-row">
                          <div style={{ width: '100%' }}>
                            <TrafficByHourChart
                              data={data.trafficByWeekdayHour}
                            />
                          </div>
                          <div style={{ width: '100%' }}>
                            <DwellTimesByHourChart
                              data={data.trafficByWeekdayHour}
                            />
                          </div>
                        </div>
                      )}

                      {data?.trafficSummary &&
                        data.trafficSummary.length > 0 && (
                          <DemographicBreakdownChart
                            data={data.trafficSummary[0]}
                          />
                        )}

                      {data?.trafficByWeekdayHour && (
                        <>
                          <AgeDistributionChart
                            data={data.trafficByWeekdayHour}
                          />
                          <AverageGenderDistributionChart
                            data={data.trafficByWeekdayHour}
                          />
                        </>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </PageLayout>
    </Page>
  );
};

export default TrafficAwarenessPage;
