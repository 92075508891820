import { createSelector } from 'reselect';
import { localizeAppStrings } from '../utilities';
import { RootState } from '../reducers';

export const selectApplications = (state: RootState) => {
  return state.applications.items;
};

export const selectIsApplicationsLoading = (state: RootState) => {
  return state.applicationsPage.isLoading;
};

export const selectPremiumApplications = (state: RootState) => {
  return state.applications.premiumApplicationItems;
};

export const selectApplicationsWithLocalizedStrings = createSelector(
  selectApplications,
  (applications) =>
    applications.map((app) => ({
      ...app,
      currentAppVersion: {
        ...app.currentAppVersion,
        strings: localizeAppStrings(app.currentAppVersion.strings),
      },
    })),
);
