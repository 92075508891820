import { useTranslation } from 'react-i18next';
import ScreensFilterControl from '../../ScreensFilterControl';
import { useEffect, useState } from 'react';
import TimezoneSelect from './TimezoneSelect';
import ScreensFilterListItem from '../../ScreensFilterListItem';
import ScreensFilterFooterAction from '../../ScreensFilterFooterAction';

type DeviceTimezonesProps = {
  className?: string;
  onChange: (value: DeviceTimezonesQuery) => void;
  value?: DeviceTimezonesQuery;
};

export type DeviceTimezonesQuery = string[];

const DeviceTimezones = ({ onChange, value }: DeviceTimezonesProps) => {
  const { t } = useTranslation(['devices']);
  const [query, setQuery] = useState<DeviceTimezonesQuery>([]);

  useEffect(() => {
    if (value) setQuery(value);
  }, [value]);

  const handleChange = (value: DeviceTimezonesQuery) => {
    setQuery(value);
    onChange(value);
  };

  return (
    <ScreensFilterControl
      title={<p className="font-[500] text-[16px]">{t('screens.timezone')}</p>}
      footer={
        <ul className="flex items-center divide-x divide-black text-sm text-primary">
          <li key="clear" className="pr-2">
            {query?.length > 0 && (
              <ScreensFilterFooterAction
                label={t('screens.clearTimezones')}
                onClick={() => handleChange([])}
              />
            )}
          </li>
        </ul>
      }
    >
      <form>
        <TimezoneSelect
          onChange={(value) => handleChange([...query, value])}
          label={''}
          value={query}
        />
        <ul>
          {query.map((timezone) => (
            <ScreensFilterListItem
              key={timezone}
              label={<p>{timezone}</p>}
              onClear={() =>
                handleChange(
                  query.filter(
                    (selectedTimezone) => timezone !== selectedTimezone,
                  ),
                )
              }
            />
          ))}
        </ul>
      </form>
    </ScreensFilterControl>
  );
};

export default DeviceTimezones;
