import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export default function useQueryParams() {
  const location = useLocation();
  return useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return {
      applicationId: searchParams.get('applicationId') ?? '',
      version: searchParams.get('version') ?? '',
      folderId: searchParams.get('folderId') ?? '',
      selectionId: searchParams.get('selectionId') ?? '',
      saveTo: searchParams.get('saveTo') ?? '',
      backTo: searchParams.get('backTo') ?? '',
      backToLabel: searchParams.get('backToLabel') ?? '',
      previewMode: getPreviewMode(searchParams),
      previewResolution: getPreviewResolution(searchParams),
      didSave: searchParams.get('didSave') === 'true',
      sessionId: searchParams.get('sessionId') ?? undefined,
    };
  }, [location.search]);
}

const getPreviewMode = (searchParams: URLSearchParams): string => {
  const previewMode = searchParams.get('previewMode');
  if (!previewMode) {
    return searchParams.get('previewOrientation') === 'vertical'
      ? 'vertical'
      : 'horizontal';
  }
  return previewMode;
};

const getPreviewResolution = (searchParams: URLSearchParams): string => {
  const previewResolution = searchParams.get('previewResolution');
  if (!previewResolution || previewResolution === '1080p') {
    return 'FullHD';
  }
  return previewResolution;
};
