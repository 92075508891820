import Switch from 'raydiant-elements/core/Switch';
import TextField from 'raydiant-elements/core/TextField/TextField';
import useStyles from '../DeviceAISettings.styles';
import DeviceAiSettingsGroup from '../DeviceAiSettingsGroup';

interface DeviceAiSettingsRawData {
  csvEnabled?: boolean;
  csvPath?: string;
  pullEnabled?: boolean;
  pushEnabled?: boolean;
  pushUrl?: string;
}

interface DeviceAiSettingsRawProps {
  data: DeviceAiSettingsRawData;
  onChangeCsvEnabled?: (value: boolean) => void;
  onChangeCsvPath?: (value: string) => void;
  onChangePullEnabled?: (value: boolean) => void;
  onChangePushEnabled?: (value: boolean) => void;
  onChangePushUrl?: (value: string) => void;
  showCsvEnabled?: boolean;
  showCsvPath?: boolean;
  showPullEnabled?: boolean;
  showPushEnabled?: boolean;
  showPushUrl?: boolean;
}

const DeviceAiSettingsRaw = ({
  data,
  onChangeCsvEnabled,
  onChangeCsvPath,
  onChangePullEnabled,
  onChangePushEnabled,
  onChangePushUrl,
  showCsvEnabled = false,
  showCsvPath = false,
  showPullEnabled = false,
  showPushEnabled = false,
  showPushUrl = false,
}: DeviceAiSettingsRawProps) => {
  const classes = useStyles();

  return (
    <DeviceAiSettingsGroup title="Raw">
      {showCsvEnabled && (
        <li className={classes.aiSetting} key={'csv'}>
          <Switch
            checked={data.csvEnabled || false}
            onChange={onChangeCsvEnabled}
            label={'CSV Enabled'}
          />
        </li>
      )}
      {showCsvPath && (
        <li className={classes.aiSetting} key={'csvPath'}>
          <TextField
            label={'CSV path'}
            value={data.csvPath}
            onChange={onChangeCsvPath}
          />
        </li>
      )}
      {showPullEnabled && (
        <li className={classes.aiSetting} key={'pullEnabled'}>
          <Switch
            checked={data.pullEnabled || false}
            onChange={onChangePullEnabled}
            label={'Pull enabled'}
          />
        </li>
      )}
      {showPushEnabled && (
        <li className={classes.aiSetting} key={'pushEnabled'}>
          <Switch
            checked={data.pushEnabled || false}
            onChange={onChangePushEnabled}
            label={'Push enabled'}
          />
        </li>
      )}
      {showPushUrl && (
        <li className={classes.aiSetting} key={'pushUrl'}>
          {showCsvPath && (
            <TextField
              label={'Push url'}
              value={data.pushUrl}
              onChange={onChangePushUrl}
            />
          )}
        </li>
      )}
    </DeviceAiSettingsGroup>
  );
};

export default DeviceAiSettingsRaw;
