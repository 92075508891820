import { useState, useMemo, useEffect } from 'react';
import { Location } from '@raydiant/api-client-js/build/types/Location';
import useLocations from '../../../../hooks/useLocations';
import DataTable from '../../../../components/DataTable/DataTable';
import DataTableHeader, {
  TableSort,
} from '../../../../components/DataTable/DataTableHeader';
import DataTableCell from '../../../../components/DataTable/DataTableCell';
import { paginate, sort } from '../../../AnalyticsPage/utils';
import Text from '@raydiant/raydial/components/Text';
import cn from 'classnames';
import Spinner from '@raydiant/raydial/components/Spinner';
import SidebarMenuTooltip from '../../../../components/Sidebar/SidebarMenuTooltip';

interface LocationsTableProps {
  search: string;
  selectedLocation: Location | null;
  onRowClick: (location: Location) => void;
}

const defaultLocationsSort: TableSort<Location> = {
  direction: 'asc',
  key: 'name',
};

const LocationsTable = ({
  search,
  selectedLocation,
  onRowClick,
}: LocationsTableProps) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [tableSort, setTableSort] =
    useState<TableSort<Location>>(defaultLocationsSort);

  const { data, isLoading } = useLocations();
  const filteredData = (data || []).filter(
    (location) =>
      location.name?.toLowerCase().includes(search.toLowerCase()) ||
      location.description?.toLowerCase().includes(search.toLowerCase()),
  );

  useEffect(() => {
    setCurrentPage(0);
  }, [search]);

  const locationsData = useMemo(() => {
    const sortedItems = sort<Location>(
      filteredData!,
      tableSort.key,
      tableSort.direction,
    );
    const paginatedData = paginate<Location>(sortedItems, 5);

    return {
      data: paginatedData[currentPage]?.map((item, index) => ({
        rank: index + 1,
        ...item,
      })),
      pageCount: paginatedData?.length,
    };
  }, [filteredData, currentPage, tableSort]);

  // Render
  if (isLoading) return <Spinner />;
  return (
    <div>
      <DataTable<Location>
        className="mt-4"
        currentPage={currentPage}
        data={locationsData?.data}
        showPagination={true}
        headers={
          <tr>
            <DataTableHeader<Location>
              className="!text-left sticky left-0 !bg-gray-100 !text-gray-500 !fill-gray-500"
              wrapperClassName="!justify-start"
              onSort={(sort) => {
                setTableSort({
                  ...tableSort,
                  ...sort,
                });
              }}
              showSort={tableSort.key === 'name'}
              sortDirection={
                tableSort.key === 'name' ? tableSort.direction : undefined
              }
              sortKey={'name'}
            >
              <Text className="text-base font-semibold">location name</Text>
            </DataTableHeader>
            <DataTableHeader<Location>
              className="!bg-gray-100 !text-gray-500 !fill-gray-500"
              wrapperClassName="!justify-start"
              onSort={() => {}}
              showSort={false}
              sortDirection={undefined}
            >
              <Text className="text-base font-semibold">details</Text>
            </DataTableHeader>
          </tr>
        }
        onPageChange={(page) => setCurrentPage(page)}
        pageCount={locationsData.pageCount}
        renderRow={(item) => (
          <tr
            key={item.id}
            onClick={() => onRowClick(item)}
            className={cn(
              'cursor-pointer !h-9',
              selectedLocation?.id === item.id ? 'bg-blue-50' : '',
            )}
          >
            <DataTableCell
              hasSort
              className="!rounded-2xl"
              wrapperClassName="w-[195px] text-left"
            >
              <SidebarMenuTooltip placement="bottom" title={item.name} arrow>
                <div className="truncate">
                  <Text>{item.name}</Text>
                </div>
              </SidebarMenuTooltip>
            </DataTableCell>
            <DataTableCell wrapperClassName="max-w-[347px] text-left">
              <SidebarMenuTooltip
                placement="bottom"
                title={
                  item.description ||
                  'No details have been added for this location'
                }
                arrow
              >
                <div className="truncate">
                  <Text>
                    {item.description ||
                      'No details have been added for this location'}
                  </Text>
                </div>
              </SidebarMenuTooltip>
            </DataTableCell>
          </tr>
        )}
      />
    </div>
  );
};

export default LocationsTable;
