import { ApiClientError } from '@raydiant/api-client-js';
import { useQueryClient } from 'react-query';
import raydiant from '../clients/raydiant';
import { keys } from '../queryClient';
import useProtectedMutation from './useProtectedMutation';

export default function useUpdateOnboardingStatus() {
  const queryClient = useQueryClient();

  const mutation = useProtectedMutation<void, ApiClientError, { name: string }>(
    ({ name }) => raydiant.updateOnboardingStatus(name),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(keys.onboarding());
      },
    },
  );

  return mutation;
}
