import useStyles from '../DeviceAISettings.styles';
import DeviceAiSettingsGroup from '../DeviceAiSettingsGroup';
import TextField from 'raydiant-elements/core/TextField/TextField';

interface DeviceInfoZoom {
  zoomFactor?: string;
  zoomCenterOffsetX?: string | undefined;
  zoomCenterOffsetY?: string | undefined;
}

interface DeviceAiSettingsZoomProps {
  data: DeviceInfoZoom;
  onChangeZoomFactor?: (value: string) => void;
  onChangeZoomCenterOffsetX?: (value: string) => void;
  onChangeZoomCenterOffsetY?: (value: string) => void;
  showZoomFactor?: boolean;
  showZoomOffsetX?: boolean;
  showZoomOffsetY?: boolean;
}

const DeviceAiSettingsZoom = ({
  data,
  onChangeZoomFactor,
  onChangeZoomCenterOffsetX,
  onChangeZoomCenterOffsetY,
  showZoomFactor = false,
  showZoomOffsetX = false,
  showZoomOffsetY = false,
}: DeviceAiSettingsZoomProps) => {
  const classes = useStyles();

  return (
    <DeviceAiSettingsGroup title={'Zoom Settings'}>
      <li className={classes.aiSetting} key={'zoomFactor'}>
        {showZoomFactor && (
          <TextField
            label={'Zoom factor'}
            value={data.zoomFactor}
            onChange={onChangeZoomFactor}
          />
        )}
      </li>
      <li className={classes.aiSetting} key={'zoomCenterOffsetX'}>
        {showZoomOffsetX && (
          <TextField
            label={'Zoom center offset x'}
            value={data.zoomCenterOffsetX}
            onChange={onChangeZoomCenterOffsetX}
          />
        )}
      </li>
      <li className={classes.aiSetting} key={'zoomCenterOffsetY'}>
        {showZoomOffsetY && (
          <TextField
            label={'Zoom center offset y'}
            value={data.zoomCenterOffsetY}
            onChange={onChangeZoomCenterOffsetY}
          />
        )}
      </li>
    </DeviceAiSettingsGroup>
  );
};

export default DeviceAiSettingsZoom;
