import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import Paper from 'raydiant-elements/core/Paper';
import PaperModal from 'raydiant-elements/core/PaperModal';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import Heading from 'raydiant-elements/core/Heading';
import Center from 'raydiant-elements/layout/Center';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import Column from 'raydiant-elements/layout/Column';
import PlaylistCard from 'raydiant-elements/playlist/PlaylistCard';
import { FC, useEffect } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from '../../clients/mira/types/Resource';
import * as domainActions from '../../actions/domains';
import * as applicationActions from '../../actions/applications';
import * as libraryPageActions from '../../pages/LibraryPage/actions';
import {
  isResourceDeleted,
  createDefaultPlaylist,
  isNotNullOrUndefined,
} from '../../utilities';
import {
  selectSearchedLibraryByOwner,
  selectLibraryFolder,
} from '../../selectors/v2/folders';
import { selectDomainForCurrentUser } from '../../selectors/v2/domains';
import {
  selectIsEnterpriseUser,
  selectIsRestrictedUser,
  selectUserProfile,
} from '../../selectors/user';
import { selectApplications } from '../../selectors/applications';
import LibraryTree from '../../components/LibraryTree';
import DomainAccountSelector from '../../components/DomainAccountSelector';
import ApplicationCard from '../../components/ApplicationCard';
import config from '../../config';
import PlaylistSelectorActionBar from './PlaylistSelectorActionBar';
import { selectSortOptions, selectSearchQuery } from './selectors';
import { Playlist } from '@raydiant/api-client-js';
import { selectIsLoadingLibrary } from '../../pages/LibraryPage/selectors';
import classNames from 'classnames';

interface PlaylistSelectorProps {
  assignButtonLabel?: string;
  selectedProfileId: string;
  onSelectProfile: (profileId: string) => void;
  onSelectPlaylist?: (playlistId: string) => void;
  onSelectBatchPlaylist?: (playlist: Playlist) => void;
  onNewPlaylist?: () => void;
  onNewPresentation?: (applicationId: string) => void;
  sortOnly?: boolean;
  showOpenLibraryButton?: boolean;
  batch?: boolean;
  filter?: boolean;
}

const defaultPlaylist = createDefaultPlaylist({ name: 'Create a Playlist' });

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    headerContainer: {
      paddingTop: 0,
      paddingBottom: 0,
    },

    header: {
      margin: theme.spacing(1, 0),
      marginBottom: 0,
    },

    domainAccountSelector: {
      padding: theme.spacing(2),

      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
      },
    },

    actions: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },

    libraryContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 0,

      '&:last-child': {
        paddingBottom: 0,
      },
    },

    library: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1),
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },

    libraryEmpty: {
      flex: 1,
      alignItems: 'center',
      backgroundImage: `url(${
        require('../../assets/library-tree-skeleton.svg').default
      })`,
      backgroundPosition: 'top center',
      backgroundRepeat: 'repeat',
      backgroundSize: '100%',
      lineHeight: 1.09,
      letterSpacing: 0.25,
      textAlign: 'center',
      padding: theme.spacing(4, 2),
    },
  });
});

const PlaylistSelector: FC<PlaylistSelectorProps> = ({
  assignButtonLabel,
  batch,
  filter = false,
  selectedProfileId,
  showOpenLibraryButton = true,
  sortOnly,
  onSelectBatchPlaylist,
  onSelectProfile,
  onSelectPlaylist,
  onNewPlaylist,
  onNewPresentation,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Selectors

  const libraryByOwner = useSelector(selectSearchedLibraryByOwner);
  const libraryFolder = useSelector(selectLibraryFolder);
  const applications = useSelector(selectApplications);
  const isEnterpriseUser = useSelector(selectIsEnterpriseUser);
  const currentUser = useSelector(selectUserProfile);
  const domain = useSelector(selectDomainForCurrentUser);
  const sortOptions = useSelector(selectSortOptions);
  const searchQuery = useSelector(selectSearchQuery);
  const isLoadingLibrary = useSelector(selectIsLoadingLibrary);
  const isRestrictedUser = useSelector(selectIsRestrictedUser);

  // Side-effects

  // Fetch applications if we have not before.
  useEffect(() => {
    if (applications.length) return;
    dispatch(applicationActions.fetchApplications());
  }, [dispatch, applications]);

  // Fetch domain if enterprise user and we have not before.
  useEffect(() => {
    if (!isEnterpriseUser || domain) return;
    dispatch(domainActions.fetchDomain());
  }, [dispatch, domain, isEnterpriseUser]);

  // Fetch library on profile change
  useEffect(() => {
    dispatch(
      libraryPageActions.loadLibraryPage({ profileId: selectedProfileId }),
    );
  }, [dispatch, selectedProfileId]);

  // Render

  let selectedProfile: R.ResourceProfile | undefined;

  if (isEnterpriseUser && domain) {
    selectedProfile = domain.r.profiles.find((p) => p.id === selectedProfileId);
  } else if (!isEnterpriseUser && currentUser) {
    // Map full user profile to a resource profile.
    selectedProfile = {
      id: currentUser.id,
      email: currentUser.email,
      name: currentUser.name,
      thumbnailUrl: currentUser.thumbnailUrl || '',
      domainId: currentUser.domainId,
      domainRole: currentUser.domainRole,
    };
  }

  const renderLibrary = () => {
    if (isLoadingLibrary) {
      return (
        <Center>
          <CircularProgress size={30} />
        </Center>
      );
    }

    const hasFolders = !!libraryFolder?.folders.find(
      (f) => !isResourceDeleted(f),
    );
    const hasPlaylists = !!libraryFolder?.playlists.find(
      (pl) => !isResourceDeleted(pl),
    );
    if (!hasFolders && !hasPlaylists && !searchQuery) {
      const hasPresentations = !!libraryFolder?.presentations.find(
        (p) => !isResourceDeleted(p),
      );

      let emptyStateCta: React.ReactNode;
      if (!hasPresentations) {
        const flyersApp = applications.find(
          (app) => app.id === config.flyersAppId,
        );
        const picturesApp = applications.find(
          (app) => app.id === config.picturesAppId,
        );
        const youtubeApp = applications.find(
          (app) => app.id === config.youtubeAppId,
        );
        const ctaApps = [flyersApp, picturesApp, youtubeApp].filter(
          isNotNullOrUndefined,
        );

        emptyStateCta = (
          <>
            <Heading size={2} weight={500} color="primary">
              Hm, there’s nothing to add-
              <br />
              lets make a presentation, then add it to a playlist
            </Heading>
            {onNewPresentation &&
              ctaApps.map((app) => (
                <ApplicationCard
                  key={app.id}
                  application={app}
                  onClick={() => onNewPresentation && onNewPresentation(app.id)}
                />
              ))}
          </>
        );
      } else {
        emptyStateCta = (
          <>
            <Heading size={2} weight={500} color="primary">
              Hm, no playlists-
              <br />
              lets make one and add some of your presentations!
            </Heading>
            <PlaylistCard
              playlist={defaultPlaylist}
              onClick={() => onNewPlaylist && onNewPlaylist()}
            />
          </>
        );
      }

      return (
        <Column doubleMargin className={classes.libraryEmpty}>
          {emptyStateCta}
        </Column>
      );
    }

    if (selectedProfile) {
      return (
        <LibraryTree
          assignButtonLabel={assignButtonLabel}
          showAssignButtonIcon={!assignButtonLabel}
          batch={batch}
          selectMode="playlist"
          searchQuery={searchQuery}
          selectedProfile={selectedProfile}
          libraryByOwner={libraryByOwner}
          onSelectBatchPlaylist={batch ? onSelectBatchPlaylist : () => {}}
          onSelectPlaylist={batch ? () => {} : onSelectPlaylist}
          sortOptions={sortOptions}
        />
      );
    }

    return null;
  };

  return (
    <>
      <PaperModal.Body
        className={classNames(classes.headerContainer, filter ? '!p-0' : '')}
      >
        <div className={classes.header}>
          {!isEnterpriseUser && <Heading gutterBottom>Your Library</Heading>}

          {isEnterpriseUser && !isRestrictedUser && (
            <Paper color="light">
              <DomainAccountSelector
                className={classes.domainAccountSelector}
                selectedProfileId={selectedProfileId}
                showOpenLibraryButton={showOpenLibraryButton}
                onSelect={onSelectProfile}
              />
            </Paper>
          )}

          {selectedProfile && (
            <Paper color="light" className={classes.actions}>
              <PlaylistSelectorActionBar
                selectedProfile={selectedProfile}
                onNewPlaylist={onNewPlaylist}
                sortOnly={sortOnly}
              />
            </Paper>
          )}
        </div>
      </PaperModal.Body>

      <Scrollable
        className={classNames(filter ? 'min-h-[200] max-h-[250px]' : '')}
      >
        <PaperModal.Body
          className={classNames(classes.libraryContainer, filter ? '!p-0' : '')}
        >
          <Paper color="light" className={classes.library}>
            {renderLibrary()}
          </Paper>
        </PaperModal.Body>
      </Scrollable>
    </>
  );
};

export default PlaylistSelector;
