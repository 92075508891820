import { useTranslation } from 'react-i18next';
import { getDisplayValue } from './ShopperEngagementInsights/utils';

export type EChartsToolTipParams = {
  color: string;
  borderColor: string;
  dataIndex: number;
  name: string;
  seriesName: string;
  value: number | string;
  axisValueLabel?: string;
  data: any[];
};

type ChartToolTipProps = {
  header?: React.ReactNode;
  totalInteractions?: string;
  values: EChartsToolTipParams[];
};

//Note that this component is static and will only be used with the Echarts formatter method that uses pure html https://echarts.apache.org/en/option.html#tooltip.formatter
const ChartToolTip = ({
  header,
  totalInteractions,
  values,
}: ChartToolTipProps) => {
  const { t } = useTranslation();

  return (
    <div className="divide-y">
      {header && header}
      <hr />
      <div className="p-2">
        <table className="w-[177px] table-auto">
          {totalInteractions && (
            <thead>
              <tr>
                <th className="font-bold text-sm text-left text-black">
                  {totalInteractions}
                </th>
                <th className="font-bold text-sm text-left text-black">
                  {t('analytics.totalInteractions')}
                </th>
                <th>
                  <p className="invisible"></p>
                </th>
              </tr>
            </thead>
          )}
          <tbody>
            {values.map((data) => (
              <tr key={data.name} className="mb-1">
                <td className="flex items-center" style={{ height: '1.4rem' }}>
                  <div
                    className={`rounded-full h-2 w-2 text-black`}
                    style={{
                      backgroundColor:
                        data.color === '#ffffff'
                          ? data.borderColor
                          : data.color,
                    }}
                  ></div>
                </td>
                <td className="text-sm text-black">{data.seriesName}</td>
                <td className="text-sm text-black font-bold flex justify-end">
                  {getDisplayValue(data.value)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ChartToolTip;
