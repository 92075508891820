import {
  ApiClientError,
  Catalog,
  Paginated,
  GetCatalogsQuery,
} from '@raydiant/api-client-js';
import raydiant from '../clients/miraClient';
import useProtectedQuery from './useProtectedQuery';
import { keys } from '../queryClient';

export type UseCatalogsOptions = GetCatalogsQuery & {
  enabled?: boolean;
};

export default function useCatalogs(
  // Pagination support has not been implemented. In the interim, setting page size to a large number
  opts: UseCatalogsOptions = { pageSize: 1000 },
) {
  const query = useProtectedQuery<Paginated<Catalog>, ApiClientError>(
    keys.catalogs(),
    () => raydiant.getCatalogs(opts),
    { enabled: opts.enabled },
  );

  return query;
}
