import { DeviceAIStatus } from '@raydiant/api-client-js';

import { selectUserHasRole } from '../../../selectors/user';
import { useSelector } from 'react-redux';

import { useSnackbar } from 'notistack';

import Heading from 'raydiant-elements/core/Heading';
import Column from 'raydiant-elements/layout/Column';

import useDeviceAISettings from '../../../hooks/useDeviceAISettings';

import useStyles from './DeviceAISettings.styles';

import React, { useEffect, useMemo, useRef, useState } from 'react';

import apiClient from '../../../clients/miraClient';
import * as D from '../../../clients/mira/types/Device';
import { FlatDeviceAIOption, OptionVal } from '../deviceAiSettings.interface';
import {
  unflattenSettings,
  flattenSchema,
  flattenSettings,
} from '../deviceAiSettingsUtils';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import PaperModal from 'raydiant-elements/core/PaperModal';
import Center from 'raydiant-elements/layout/Center';
import { getDeviceType } from '../../../utilities';
import Text from 'raydiant-elements/core/Text';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import LoadingButton from '../../../components/LoadingButton';
import { LoadingStatus } from '../../../components/LoadingButton/LoadingButton';
import deepEqual from 'fast-deep-equal';
import Paper from 'raydiant-elements/core/Paper';
import DeviceAiSettingsVideoModal from './DeviceAiSettingsVideoModal';
import DeviceAiSettingsVideo, {
  DeviceAiSettingsVideoPlayerState,
} from './DeviceAiSettingsVideo';
import DeviceAiSettingsVideoFullScreenPlaceholder from './DeviceAiSettingsVideoFullScreenPlaceholder';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DeviceAiSettingsRegionOfInterest from './DeviceAiSettingsGroups/DeviceAiSettingsRegionOfInterest';
import DeviceAiSettingsZoom from './DeviceAiSettingsGroups/DeviceAiSettingsZoom';
import DeviceAiSettingsPeopleCount from './DeviceAiSettingsGroups/DeviceAiSettingsPeopleCount';
import DeviceAiSettingsAggregation from './DeviceAiSettingsGroups/DeviceAiSettingsAggregation';
import DeviceAiSettingsHttpInterface from './DeviceAiSettingsGroups/DeviceAiSettingsHttpInterface';
import DeviceAiSettingsRaw from './DeviceAiSettingsGroups/DeviceAiSettingsRaw';
import DeviceAiSettingsStatus from './DeviceAiSettingsGroups/DeviceAiSettingsStatus';
import DeviceAiSettingsSdk from './DeviceAiSettingsGroups/DeviceAiSettingsSdk';
import DeviceAiSettingsVideoFeed from './DeviceAiSettingsGroups/DeviceAiSettingsVideoFeed';
import DeviceAiSettingsUcms from './DeviceAiSettingsGroups/DeviceAiSettingsUcms';
import DeviceAiSettingsCurrentStats from './DeviceAiSettingsGroups/DeviceAiSettingsCurrentStats';
interface DeviceAISettingsProps {
  device: D.Device;
  visibility?: string;
}

enum IVSPlayerPayloadStatus {
  NOT_FOUND = 404,
}

interface IVSPlayerPayload {
  code: IVSPlayerPayloadStatus;
  message: string;
  source: 'MasterPlaylist';
  type: 'ErrorNotAvailable';
}

const getSetting = (key: string, schema: Array<FlatDeviceAIOption>) =>
  schema?.find((setting) => setting.$id.includes(key));
const isHttpOption = (elem: FlatDeviceAIOption): boolean =>
  elem.$id.startsWith('#root/http');
const isStatusOption = (elem: FlatDeviceAIOption): boolean =>
  elem.$id.startsWith('#root/status');
const isPeopleCountOption = (elem: FlatDeviceAIOption): boolean =>
  elem.$id.startsWith('#root/peopleCount');
const isAggregationOption = (elem: FlatDeviceAIOption): boolean =>
  elem.$id.startsWith('#root/aggregated') ||
  elem.$id.startsWith('#root/aggregation');
const isRawOption = (elem: FlatDeviceAIOption): boolean =>
  elem.$id.startsWith('#root/raw');
const isSdkOption = (elem: FlatDeviceAIOption): boolean =>
  elem.$id.startsWith('#root/sdkSettings');
const isUcmsOption = (elem: FlatDeviceAIOption): boolean =>
  elem.$id.startsWith('#root/ucms');
const isCurrentStatsOption = (elem: FlatDeviceAIOption): boolean =>
  elem.$id.startsWith('#root/currentStats');
const isRegionOfInterestOption = (elem: FlatDeviceAIOption): boolean =>
  elem.$id.startsWith('#root/ROI');
const isVideoFeedOption = (elem: FlatDeviceAIOption): boolean =>
  [
    elem.$id.startsWith('#root/blurFaces'),
    elem.$id.startsWith('#root/drawResult'),
    elem.$id.startsWith('#root/flipHorizontally'),
    elem.$id.startsWith('#root/flipVertically'),
    elem.$id.startsWith('#root/instanceName'),
    elem.$id.startsWith('#root/loopVideo'),
    elem.$id.startsWith('#root/processAllFrames'),
    elem.$id.startsWith('#root/resolutionHeight'),
    elem.$id.startsWith('#root/resolutionWidth'),
    elem.$id.startsWith('#root/rotateMode'),
    elem.$id.startsWith('#root/sourceType'),
    elem.$id.startsWith('#root/video'),
    elem.$id.startsWith('#root/webcamIndex'),
  ].includes(true);
const isZoomOption = (elem: FlatDeviceAIOption): boolean =>
  elem.$id.startsWith('#root/zoom');

const VIDEO_PLAYBACK_DELAY = 600000; //10 minutes

const DeviceAISettings = ({ device }: DeviceAISettingsProps) => {
  const classes = useStyles();

  const userHasRole = useSelector(selectUserHasRole);

  const [aiSettingsSchema, setAiSettingsSchema] = useState<
    Array<FlatDeviceAIOption> | undefined
  >(undefined);
  const [aiSettings, setAiSettings] = useState<
    Record<string, OptionVal> | undefined
  >(undefined);
  const [aiStatus, setAiStatus] = useState<DeviceAIStatus | undefined>(
    undefined,
  );
  const [videoStreamURL, setVideoStreamURL] = useState<string | undefined>(
    undefined,
  );
  const [videoPlayerState, setVideoPlayerState] =
    useState<DeviceAiSettingsVideoPlayerState>('idle');
  const [submitStatus, setSubmitStatus] = useState<LoadingStatus>('idle');
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  //ai settings state
  const [roiX, setRoiX] = useState<number | undefined>();
  const [roiY, setRoiY] = useState<number | undefined>();
  const [roiW, setRoiW] = useState<number | undefined>();
  const [roiH, setRoiH] = useState<number | undefined>();
  const [zoomFactor, setZoomFactor] = useState<number | undefined>();
  const [zoomCenterOffsetX, setZoomCenterOffsetX] = useState<
    number | undefined
  >();
  const [zoomCenterOffsetY, setZoomCenterOffsetY] = useState<
    number | undefined
  >();

  const [peopleCountCsvEnabled, setPeopleCountCsvEnabled] =
    useState<boolean>(false);
  const [peopleCountCsvPath, setPeopleCountCsvPath] = useState<
    string | undefined
  >('');
  const [peopleCountEnabled, setPeopleCountEnabled] = useState<boolean>(false);
  const [peopleCountFrequency, setPeopleCountFrequency] = useState<
    number | undefined
  >();
  const [peopleCountPullEnabled, setPeopleCountPullEnabled] =
    useState<boolean>(false);
  const [peopleCountPushEnabled, setPeopleCountPushEnabled] =
    useState<boolean>(false);
  const [peopleCountPushUrl, setPeopleCountPushUrl] = useState<
    string | undefined
  >('');
  const [aggregatedCsvEnabled, setAggregatedCsvEnabled] =
    useState<boolean>(false);
  const [aggregatedCsvPath, setAggregatedCsvPath] = useState<
    string | undefined
  >('');
  const [aggregationFrequency, setAggregationFrequency] = useState<
    number | undefined
  >();
  const [aggregatedPullEnabled, setAggregatedPullEnabled] =
    useState<boolean>(false);
  const [aggregatedPushEnabled, setAggregatedPushEnabled] =
    useState<boolean>(false);
  const [aggregatedPushUrl, setAggregatedPushUrl] = useState<
    string | undefined
  >('');
  const [httpPort, setHttpPort] = useState<number | undefined>();
  const [httpSslEnabled, setHttpSslEnabled] = useState<boolean>();
  const [httpSslKey, setHttpSslKey] = useState<string | undefined>();
  const [httpSslCertificate, setHttpSslCertificate] = useState<
    string | undefined
  >();
  const [rawCsvEnabled, setRawCsvEnabled] = useState<boolean>(false);
  const [rawCsvPath, setRawCsvPath] = useState<string | undefined>('');
  const [rawPullEnabled, setRawPullEnabled] = useState<boolean>(false);
  const [rawPushEnabled, setRawPushEnabled] = useState<boolean>(false);
  const [rawPushUrl, setRawPushUrl] = useState<string | undefined>('');
  const [statusPullEnabled, setStatusPullEnabled] = useState<boolean>(false);
  const [statusPushEnabled, setStatusPushEnabled] = useState<boolean>(false);
  const [statusPushUrl, setStatusPushUrl] = useState<string | undefined>('');

  const [sdkUseAge, setSdkUseAge] = useState(false);
  const [sdkUseEmotions, setSdkUseEmotions] = useState(false);
  const [sdkUseFaceMask, setSdkUseFaceMask] = useState(false);
  const [sdkUseGender, setSdkUseGender] = useState(false);
  const [sdkUseHeadpose, setSdkUseHeadpose] = useState(false);
  const [sdkUseSmile, setSdkUseSmile] = useState(false);
  const [sdkDetectionRange, setSdkDetectionRange] = useState<
    string | undefined
  >();
  const [sdkMaxFaceSize, setSdkMaxFaceSize] = useState<number | undefined>();
  const [sdkMinFaceSize, setSdkMinFaceSize] = useState<number | undefined>();
  const [sdkMaxNumFaces, setSdkMaxNumFaces] = useState<string | undefined>();
  const [sdkOperationMode, setSdkOperationMode] = useState<
    string | undefined
  >();
  const [sdkFaceConfidenceThreshold, setSdkFaceConfidenceThreshold] = useState<
    string | undefined
  >();
  const [sdkNeuralNetworksPath, setSdkNeuralNetworksPath] = useState<
    string | undefined
  >();
  const [sdkBodyTrackFps, setSdkBodyTrackFps] = useState<string | undefined>();
  const [sdkDnnTarget, setSdkDnnTarget] = useState<string | undefined>();
  const [sdkLogLevel, setSdkLogLevel] = useState<string | undefined>();
  const [sdkNumThreads, setSdkNumThreads] = useState<string | undefined>();

  const [blurFaces, setBlurFaces] = useState(false);
  const [drawResult, setDrawResult] = useState(false);
  const [flipHorizontally, setFlipHorizontally] = useState(false);
  const [flipVertically, setFlipVertically] = useState(false);
  const [instanceName, setInstanceName] = useState<string | undefined>();
  const [loopVideo, setLoopVideo] = useState(false);
  const [processAllFrames, setProcessAllFrames] = useState(false);
  const [resolutionHeight, setResolutionHeight] = useState<
    string | undefined
  >();
  const [resolutionWidth, setResolutionWidth] = useState<string | undefined>();
  const [rotateMode, setRotateMode] = useState<string | undefined>();
  const [sourceType, setSourceType] = useState<string | undefined>();
  const [videoFilePath, setVideoFilePath] = useState<string | undefined>();
  const [videoFeedStreamUrl, setVideoFeedStreamUrl] = useState<
    string | undefined
  >();
  const [webcamIndex, setWebcamIndex] = useState<number | undefined>();

  const [ucmsMode, setUcmsMode] = useState<string | undefined>();
  const [ucmsCsvEnabled, setUcmsCsvEnabled] = useState(false);
  const [ucmsCsvPath, setUcmsCsvPath] = useState<string | undefined>();
  const [ucmsEnabled, setUcmsEnabled] = useState(false);
  const [ucmsPushEnabled, setUcmsPushEnabled] = useState(false);
  const [ucmsPushUrl, setUcmsPushUrl] = useState<string | undefined>();

  const [currentStatsDuration, setCurrentStatsDuration] = useState<
    number | undefined
  >();
  const [currentStatsEnabled, setCurrentStatsEnabled] = useState(false);

  const deviceId = device.resinUuid;
  const cameraDisconnectedError = aiStatus?.VideoSource_Status === 'OFF';

  const videoPlayer = useRef(null);
  const videoPlayerElement = useRef(document.createElement('video'));

  const videoPlayerModal = useRef(null);
  const videoPlayerElementModal = useRef(document.createElement('video'));
  const IVSPlayer = (window as any).IVSPlayer;
  let videoPlaybackTimeout: NodeJS.Timeout;

  useEffect(() => {
    if (cameraDisconnectedError) setVideoPlayerState('error');

    const { PLAYING, READY, IDLE } = IVSPlayer.PlayerState;
    const { ERROR, INITIALIZED } = IVSPlayer.PlayerEventType;

    const initializePlayer = (
      videoPlayer: React.MutableRefObject<null>,
      videoElement: React.MutableRefObject<HTMLVideoElement>,
    ) => {
      videoPlayer.current = IVSPlayer.create();
      const player = videoPlayer.current as any;
      player?.attachHTMLVideoElement(videoElement.current);

      player.addEventListener(READY, (payload: IVSPlayerPayload) => {
        onStateChange(READY, payload);
      });
      player.addEventListener(PLAYING, (payload: IVSPlayerPayload) => {
        onStateChange(PLAYING, payload);
      });
      player.addEventListener(ERROR, (payload: IVSPlayerPayload) => {
        onStateChange(ERROR, payload);
      });
      player.addEventListener(IDLE, (payload: IVSPlayerPayload) => {
        onStateChange(IDLE, payload);
      });
      player.addEventListener(INITIALIZED, (payload: IVSPlayerPayload) => {
        onStateChange(INITIALIZED, payload);
      });

      return player;
    };

    const onStateChange = (event: string, payload?: IVSPlayerPayload) => {
      const player = videoPlayer.current as any;
      const playerModal = videoPlayerModal.current as any;
      const playerState = player.getState();
      const playerModalState = playerModal.getState();

      console.log(`Player Event`, IDLE);
      console.log(`Player State - ${playerState}`, payload); //For debugging , should remove in the next version
      console.log(`Player Modal State - ${playerModalState}`, payload); //For debugging , should remove in the next version

      if (payload?.code === IVSPlayerPayloadStatus.NOT_FOUND) {
        setVideoPlayerState('idle');
      }
    };

    const player = initializePlayer(videoPlayer, videoPlayerElement);
    const playerModal = initializePlayer(
      videoPlayerModal,
      videoPlayerElementModal,
    );

    return () => {
      player.removeEventListener(READY, onStateChange);
      player.removeEventListener(PLAYING, onStateChange);
      player.removeEventListener(ERROR, onStateChange);

      playerModal.removeEventListener(READY, onStateChange);
      playerModal.removeEventListener(PLAYING, onStateChange);
      playerModal.removeEventListener(ERROR, onStateChange);
    };
  }, [IVSPlayer, videoStreamURL, cameraDisconnectedError]);

  const { data, isLoading, isError } = useDeviceAISettings(deviceId);

  const { enqueueSnackbar } = useSnackbar();

  const getVideoErrors = () => {
    const errors = [];

    if (cameraDisconnectedError) errors.push('Camera disconnected');

    return errors;
  };

  const isHidden = (option: FlatDeviceAIOption) => {
    if (option?.visibility === 'CS' && userHasRole('ai_device_Admin'))
      return false;

    if (option?.visibility !== 'Client')
      return !userHasRole('ai_device_' + option.visibility);

    return true;
  };

  const showStatusGroup = aiSettingsSchema
    ?.filter((setting) => isStatusOption(setting))
    .some((setting) => !isHidden(setting));
  const showHttpGroup = aiSettingsSchema
    ?.filter((setting) => isHttpOption(setting))
    .some((setting) => !isHidden(setting));
  const showPeopleCountGroup = aiSettingsSchema
    ?.filter((setting) => isPeopleCountOption(setting))
    .some((setting) => !isHidden(setting));
  const showAggregationGroup = aiSettingsSchema
    ?.filter((setting) => isAggregationOption(setting))
    .some((setting) => !isHidden(setting));
  const showRawGroup = aiSettingsSchema
    ?.filter((setting) => isRawOption(setting))
    .some((setting) => !isHidden(setting));
  const showSdkGroup = aiSettingsSchema
    ?.filter((setting) => isSdkOption(setting))
    .some((setting) => !isHidden(setting));
  const showUcmsGroup = aiSettingsSchema
    ?.filter((setting) => isUcmsOption(setting))
    .some((setting) => !isHidden(setting));
  const showCurrentStatsGroup = aiSettingsSchema
    ?.filter((setting) => isCurrentStatsOption(setting))
    .some((setting) => !isHidden(setting));
  const showRegionOfInterestGroup = aiSettingsSchema
    ?.filter((setting) => isRegionOfInterestOption(setting))
    .some((setting) => !isHidden(setting));
  const showVideoFeedSettingsGroup = aiSettingsSchema
    ?.filter((setting) => isVideoFeedOption(setting))
    .some((setting) => !isHidden(setting));
  const showZoomSettingsGroup = aiSettingsSchema
    ?.filter((setting) => isZoomOption(setting))
    .some((setting) => !isHidden(setting));

  const showApplyButton = [
    showStatusGroup,
    showHttpGroup,
    showPeopleCountGroup,
    showAggregationGroup,
    showRawGroup,
    showSdkGroup,
    showUcmsGroup,
    showCurrentStatsGroup,
    showRegionOfInterestGroup,
    showVideoFeedSettingsGroup,
    showZoomSettingsGroup,
  ].includes(true);

  const handleUpdateAISettings = async () => {
    try {
      setSubmitStatus('loading');
      const response = await apiClient.setDeviceAISettings(deviceId, {
        ...unflattenSettings(aiSettings!),
        ...{
          blurFaces: blurFaces!,
          drawResult: drawResult!,
          flipHorizontally: flipHorizontally!,
          flipVertically: flipVertically!,
          instanceName: instanceName!,
          loopVideo: loopVideo!,
          processAllFrames: processAllFrames!,
          resolutionHeight: resolutionHeight!,
          resolutionWidth: resolutionWidth!,
          rotateMode: rotateMode!,
          sourceType: sourceType!,
          videoFilePath: videoFilePath!,
          videoStreamUrl: videoFeedStreamUrl!,
          webcamIndex: webcamIndex!,
          aggregationFrequency: aggregationFrequency!,
          aggregatedCsvEnabled: aggregatedCsvEnabled!,
          aggregatedCsvPath: aggregatedCsvPath!,
          aggregatedPullEnabled: aggregatedPullEnabled!,
          aggregatedPushEnabled: aggregatedPushEnabled!,
          aggregatedPushUrl: aggregatedPushUrl!,
          httpPort: httpPort!,
          httpSslEnabled: httpSslEnabled!,
          httpSslKey: httpSslKey!,
          httpSslCertificate: httpSslCertificate!,
          peopleCountCsvEnabled: peopleCountCsvEnabled,
          peopleCountCsvPath: peopleCountCsvPath!,
          peopleCountEnabled: peopleCountEnabled!,
          peopleCountFrequency: peopleCountFrequency!,
          peopleCountPullEnabled: peopleCountPullEnabled!,
          peopleCountPushEnabled: peopleCountPushEnabled!,
          peopleCountPushUrl: peopleCountPushUrl!,
          rawCsvEnabled: rawCsvEnabled,
          rawCsvPath: rawCsvPath!,
          rawPullEnabled: rawPullEnabled!,
          rawPushEnabled: rawPushEnabled!,
          rawPushUrl: rawPushUrl!,
          sdkSettings: {
            useAge: sdkUseAge!,
            useEmotions: sdkUseEmotions!,
            useFaceMask: sdkUseFaceMask!,
            useGender: sdkUseGender!,
            useHeadpose: sdkUseHeadpose!,
            useSmile: sdkUseSmile!,
            detectionRange: sdkDetectionRange!,
            maxFaceSize: sdkMaxFaceSize!,
            minFaceSize: sdkMinFaceSize!,
            maxNumFaces: sdkMaxNumFaces!,
            operationMode: sdkOperationMode!,
            faceConfidenceThreshold: sdkFaceConfidenceThreshold!,
            neuralNetworksPath: sdkNeuralNetworksPath!,
            bodyTrackFps: sdkBodyTrackFps!,
            dnnTarget: sdkDnnTarget!,
            logLevel: sdkLogLevel!,
            numThreads: sdkNumThreads!,
          },
          ROI_X: roiX!,
          ROI_Y: roiY!,
          ROI_W: roiW!,
          ROI_H: roiH!,
          statusPullEnabled: statusPullEnabled!,
          statusPushEnabled: statusPushEnabled!,
          statusPushUrl: statusPushUrl!,
          zoomFactor: zoomFactor!,
          zoomCenterOffset_X: zoomCenterOffsetX!,
          zoomCenterOffset_Y: zoomCenterOffsetY!,
          ucmsMode: ucmsMode!,
          ucmsCsvEnabled: ucmsCsvEnabled!,
          ucmsCsvPath: ucmsCsvPath!,
          ucmsEnabled: ucmsEnabled!,
          ucmsPushEnabled: ucmsPushEnabled!,
          ucmsPushUrl: ucmsPushUrl!,
          currentStatsDuration: currentStatsDuration!,
          currentStatsEnabled: currentStatsEnabled!,
        },
      });
      setSubmitStatus('success');
      const flat = flattenSchema(response.schema.schema.properties);
      setAiSettingsSchema(flat);
      setAiSettings(flattenSettings(flat, response.settings));
    } catch (error) {
      console.log('ERROR UPDATING AI SETTINGS', error);

      enqueueSnackbar('There was an error updating settings', {
        variant: 'error',
      });
    }
  };

  const handleStartVideoFeed = async () => {
    try {
      setVideoPlayerState('loading');
      const resp = await apiClient.startDeviceAIVideo(deviceId);

      setVideoStreamURL(resp.playback_url);
      const player = videoPlayer.current as any;
      player.load(resp.playback_url);
      player.play();

      const playerModal = videoPlayerModal.current as any;
      playerModal.load(resp.playback_url);
      playerModal.play();

      setVideoPlayerState('playing');
      videoPlaybackTimeout = setTimeout(async () => {
        await handleStopVideoFeed();
      }, VIDEO_PLAYBACK_DELAY);
    } catch (error) {
      console.log('ERROR STARTING VIDEO FEED', error);
      enqueueSnackbar('There was an error starting the video feed', {
        variant: 'error',
      });
      setVideoPlayerState('idle');
    }
  };

  const handleStopVideoFeed = async () => {
    try {
      setVideoPlayerState('stopping');
      await apiClient.stopDeviceAIVideo(deviceId);
      setVideoPlayerState('idle');
      clearTimeout(videoPlaybackTimeout);
    } catch (error) {
      console.log('ERROR STARTING VIDEO FEED', error);
      enqueueSnackbar('There was an error stopping the video feed', {
        variant: 'error',
      });
      setVideoPlayerState('idle');
      clearTimeout(videoPlaybackTimeout);
    }
  };

  const renderVideoFeedSettings = () => {
    return (
      <div className={classes.section}>
        <Heading size={5} overline gutterBottom>
          Video feed
        </Heading>
        {
          <div style={{ display: isVideoModalOpen ? 'block' : 'none' }}>
            <DeviceAiSettingsVideoFullScreenPlaceholder />
          </div>
        }
        {
          <Paper
            className={classes.video}
            elevation={3}
            style={{ display: isVideoModalOpen ? 'none' : 'block' }}
          >
            <DeviceAiSettingsVideo
              errorMessages={getVideoErrors()}
              hasError={cameraDisconnectedError}
              onPlay={handleStartVideoFeed}
              onOpen={() => setIsVideoModalOpen(true)}
              onStop={handleStopVideoFeed}
              showMaximize={true}
              url={videoStreamURL}
              videoPlayerElement={videoPlayerElement}
              videoPlayerState={videoPlayerState}
            />
          </Paper>
        }
        {aiSettingsSchema && showRegionOfInterestGroup && (
          <DeviceAiSettingsRegionOfInterest
            data={{
              x: roiX,
              y: roiY,
              w: roiW,
              h: roiH,
            }}
            onChangeX={(value) => setRoiX(value ? parseInt(value) : undefined)}
            onChangeY={(value) => setRoiY(value ? parseInt(value) : undefined)}
            onChangeH={(value) => setRoiH(value ? parseInt(value) : undefined)}
            onChangeW={(value) => setRoiW(value ? parseInt(value) : undefined)}
            showX={!isHidden(getSetting('#root/ROI_X', aiSettingsSchema!)!)}
            showY={!isHidden(getSetting('#root/ROI_Y', aiSettingsSchema!)!)}
            showW={!isHidden(getSetting('#root/ROI_W', aiSettingsSchema!)!)}
            showH={!isHidden(getSetting('#root/ROI_H', aiSettingsSchema!)!)}
          />
        )}
      </div>
    );
  };

  const flattenedAiSettings = useMemo(() => {
    if (data) {
      const flattened = flattenSchema(data.schema.schema.properties);
      setAiSettingsSchema(flattened);
      setAiSettings(flattenSettings(flattened, data.settings));
      setAiStatus(data.status);

      return flattened;
    }
  }, [data]);

  useMemo(() => {
    if (aiSettings) {
      //ROI
      setRoiX(aiSettings['#root/ROI_X'] as number);
      setRoiY(aiSettings['#root/ROI_Y'] as number);
      setRoiW(aiSettings['#root/ROI_W'] as number);
      setRoiH(aiSettings['#root/ROI_H'] as number);

      // Aggregation
      setAggregatedCsvEnabled(
        aiSettings!['#root/aggregatedCsvEnabled'] as boolean,
      );
      setAggregatedCsvPath(aiSettings!['#root/aggregatedCsvPath'] as string);
      setAggregationFrequency(
        aiSettings!['#root/aggregationFrequency'] as number,
      );
      setAggregatedPullEnabled(
        aiSettings!['#root/aggregatedPullEnabled'] as boolean,
      );
      setAggregatedPushEnabled(
        aiSettings!['#root/aggregatedPushEnabled'] as boolean,
      );
      setAggregatedPushUrl(aiSettings!['#root/aggregatedPushUrl'] as string);

      //Http
      setHttpPort(aiSettings!['#root/httpInterfacePort'] as number);
      setHttpSslEnabled(aiSettings!['#root/httpInterfaceSsl'] as boolean);
      setHttpSslCertificate(aiSettings!['#root/httpInterfaceSslCrt'] as string);
      setHttpSslKey(aiSettings!['#root/httpInterfaceSslKey'] as string);

      //People Count
      setPeopleCountCsvEnabled(
        aiSettings!['#root/peopleCountCsvEnabled'] as boolean,
      );
      setPeopleCountCsvPath(aiSettings!['#root/peopleCountCsvPath'] as string);
      setPeopleCountEnabled(aiSettings!['#root/peopleCountEnabled'] as boolean);
      setPeopleCountFrequency(
        aiSettings!['#root/peopleCountFrequency'] as number,
      );
      setPeopleCountPullEnabled(
        aiSettings!['#root/peopleCountPullEnabled'] as boolean,
      );
      setPeopleCountPushEnabled(
        aiSettings!['#root/peopleCountPushEnabled'] as boolean,
      );
      setPeopleCountPushUrl(aiSettings!['#root/peopleCountPushUrl'] as string);

      // Zoom factor
      setZoomFactor(
        aiSettings
          ? (parseInt(aiSettings['#root/zoomFactor'] as string, 10) as number)
          : 0,
      );
      setZoomCenterOffsetX(
        aiSettings
          ? parseInt(aiSettings['#root/zoomCenterOffset_X']! as string, 10)
          : 0,
      );
      setZoomCenterOffsetY(
        aiSettings
          ? parseInt(aiSettings['#root/zoomCenterOffset_Y'] as string, 10)
          : 0,
      );

      // Raw
      setRawCsvEnabled(aiSettings!['#root/rawCsvEnabled'] as boolean);
      setRawCsvPath(aiSettings!['#root/rawCsvPath'] as string);
      setRawPullEnabled(aiSettings!['#root/rawPullEnabled'] as boolean);
      setRawPushEnabled(aiSettings!['#root/rawPushEnabled'] as boolean);
      setRawPushUrl(aiSettings!['#root/rawPushUrl'] as string);

      //Status
      setStatusPullEnabled(aiSettings!['#root/statusPullEnabled'] as boolean);
      setStatusPushEnabled(aiSettings!['#root/statusPushEnabled'] as boolean);
      setStatusPushUrl(aiSettings!['#root/statusPushUrl'] as string);

      //Sdk Settings
      setSdkUseAge(aiSettings!['#root/sdkSettings/useAge'] as boolean);
      setSdkUseEmotions(
        aiSettings!['#root/sdkSettings/useEmotions'] as boolean,
      );
      setSdkUseFaceMask(
        aiSettings!['#root/sdkSettings/useFaceMask'] as boolean,
      );
      setSdkUseGender(aiSettings!['#root/sdkSettings/useGender'] as boolean);
      setSdkUseHeadpose(
        aiSettings!['#root/sdkSettings/useHeadpose'] as boolean,
      );
      setSdkUseSmile(aiSettings!['#root/sdkSettings/useSmile'] as boolean);
      setSdkDetectionRange(
        aiSettings!['#root/sdkSettings/detectionRange'] as string,
      );
      setSdkMaxFaceSize(aiSettings!['#root/sdkSettings/maxFaceSize'] as number);
      setSdkMinFaceSize(aiSettings!['#root/sdkSettings/minFaceSize'] as number);
      setSdkMaxNumFaces(aiSettings!['#root/sdkSettings/maxNumFaces'] as string);
      setSdkOperationMode(
        aiSettings!['#root/sdkSettings/operationMode'] as string,
      );
      setSdkFaceConfidenceThreshold(
        aiSettings!['#root/sdkSettings/faceConfidenceThreshold'] as string,
      );
      setSdkNeuralNetworksPath(
        aiSettings!['#root/sdkSettings/neuralNetworksPath'] as string,
      );
      setSdkBodyTrackFps(
        aiSettings!['#root/sdkSettings/bodyTrackFps'] as string,
      );
      setSdkDnnTarget(aiSettings!['#root/sdkSettings/dnnTarget'] as string);
      setSdkLogLevel(aiSettings!['#root/sdkSettings/logLevel'] as string);
      setSdkNumThreads(aiSettings!['#root/sdkSettings/numThreads'] as string);

      // video feed settings
      setBlurFaces(aiSettings!['#root/blurFaces'] as boolean);
      setDrawResult(aiSettings!['#root/drawResult'] as boolean);
      setFlipHorizontally(aiSettings!['#root/flipHorizontally'] as boolean);
      setFlipVertically(aiSettings!['#root/flipVertically'] as boolean);
      setInstanceName(aiSettings!['#root/instanceName'] as string);
      setLoopVideo(aiSettings!['#root/loopVideo'] as boolean);
      setProcessAllFrames(aiSettings!['#root/processAllFrames'] as boolean);
      setResolutionHeight(aiSettings!['#root/resolutionHeight'] as string);
      setResolutionWidth(aiSettings!['#root/resolutionWidth'] as string);
      setRotateMode(aiSettings!['#root/rotateMode'] as string);
      setSourceType(aiSettings!['#root/sourceType'] as string);
      setVideoFilePath(aiSettings!['#root/videoFilePath'] as string);
      setVideoFeedStreamUrl(aiSettings!['#root/videoStreamUrl'] as string);
      setWebcamIndex(aiSettings!['#root/webcamIndex'] as number);

      //ucms
      setUcmsCsvEnabled(aiSettings!['#root/ucmsCsvEnabled'] as boolean);
      setUcmsCsvPath(aiSettings!['#root/ucmsCsvPath'] as string);
      setUcmsEnabled(aiSettings!['#root/ucmsEnabled'] as boolean);
      setUcmsMode(aiSettings!['#root/ucmsMode'] as string);
      setUcmsPushEnabled(aiSettings!['#root/ucmsPushEnabled'] as boolean);
      setUcmsPushUrl(aiSettings!['#root/ucmsPushUrl'] as string);

      //currentStats
      setCurrentStatsDuration(
        aiSettings!['#root/currentStatsDuration'] as number,
      );
      setCurrentStatsEnabled(
        aiSettings!['#root/currentStatsEnabled'] as boolean,
      );
    }
  }, [aiSettings]);

  const isDirty = useMemo(() => {
    if (data && flattenedAiSettings && aiSettings) {
      const isSame = deepEqual(
        flattenSettings(flattenedAiSettings, data.settings),
        {
          ...aiSettings,
          '#root/sdkSettings/useAge': sdkUseAge,
          '#root/sdkSettings/useEmotions': sdkUseEmotions,
          '#root/sdkSettings/useFaceMask': sdkUseFaceMask,
          '#root/sdkSettings/useGender': sdkUseGender,
          '#root/sdkSettings/useHeadpose': sdkUseHeadpose,
          '#root/sdkSettings/useSmile': sdkUseSmile,
          '#root/sdkSettings/detectionRange': sdkDetectionRange,
          '#root/sdkSettings/maxFaceSize': sdkMaxFaceSize,
          '#root/sdkSettings/minFaceSize': sdkMinFaceSize,
          '#root/sdkSettings/maxNumFaces': sdkMaxNumFaces,
          '#root/sdkSettings/operationMode': sdkOperationMode,
          '#root/sdkSettings/faceConfidenceThreshold':
            sdkFaceConfidenceThreshold,
          '#root/sdkSettings/neuralNetworksPath': sdkNeuralNetworksPath,
          '#root/sdkSettings/bodyTrackFps': sdkBodyTrackFps,
          '#root/sdkSettings/dnnTarget': sdkDnnTarget,
          '#root/sdkSettings/logLevel': sdkLogLevel,
          '#root/sdkSettings/numThreads': sdkNumThreads,
          '#root/zoomFactor': zoomFactor,
          '#root/zoomCenterOffset_X': zoomCenterOffsetX,
          '#root/zoomCenterOffset_Y': zoomCenterOffsetY,
          '#root/ROI_X': roiX,
          '#root/ROI_Y': roiY,
          '#root/ROI_W': roiW,
          '#root/ROI_H': roiH,
          '#root/httpInterfacePort': httpPort,
          '#root/httpInterfaceSsl': httpSslEnabled,
          '#root/httpInterfaceSslCrt': httpSslCertificate,
          '#root/httpInterfaceSslKey': httpSslKey,
          '#root/rawCsvEnabled': rawCsvEnabled,
          '#root/rawCsvPath': rawCsvPath,
          '#root/rawPullEnabled': rawPullEnabled,
          '#root/rawPushEnabled': rawPushEnabled,
          '#root/rawPushUrl': rawPushUrl,
          '#root/aggregationFrequency': aggregationFrequency,
          '#root/aggregatedCsvEnabled': aggregatedCsvEnabled,
          '#root/aggregatedCsvPath': aggregatedCsvPath,
          '#root/aggregatedPullEnabled': aggregatedPullEnabled,
          '#root/aggregatedPushEnabled': aggregatedPushEnabled,
          '#root/aggregatedPushUrl': aggregatedPushUrl,
          '#root/peopleCountCsvEnabled': peopleCountCsvEnabled,
          '#root/peopleCountCsvPath': peopleCountCsvPath,
          '#root/peopleCountEnabled': peopleCountEnabled,
          '#root/peopleCountFrequency': peopleCountFrequency,
          '#root/peopleCountPullEnabled': peopleCountPullEnabled,
          '#root/peopleCountPushEnabled': peopleCountPushEnabled,
          '#root/peopleCountPushUrl': peopleCountPushUrl,
          '#root/statusPullEnabled': statusPullEnabled,
          '#root/statusPushEnabled': statusPushEnabled,
          '#root/statusPushUrl': statusPushUrl,
          '#root/blurFaces': blurFaces,
          '#root/drawResult': drawResult,
          '#root/flipHorizontally': flipHorizontally,
          '#root/flipVertically': flipVertically,
          '#root/instanceName': instanceName,
          '#root/loopVideo': loopVideo,
          '#root/processAllFrames': processAllFrames,
          '#root/resolutionHeight': resolutionHeight,
          '#root/resolutionWidth': resolutionWidth,
          '#root/rotateMode': rotateMode,
          '#root/sourceType': sourceType,
          '#root/videoFilePath': videoFilePath,
          '#root/videoStreamUrl': videoFeedStreamUrl,
          '#root/webcamIndex': webcamIndex,
          '#root/ucmsCsvEnabled': ucmsCsvEnabled,
          '#root/ucmsCsvPath': ucmsCsvPath,
          '#root/ucmsMode': ucmsMode,
          '#root/ucmsEnabled': ucmsEnabled,
          '#root/ucmsPushEnabled': ucmsPushEnabled,
          '#root/ucmsPushUrl': ucmsPushUrl,
          '#root/currentStatsDuration': currentStatsDuration,
          '#root/currentStatsEnabled': currentStatsEnabled,
        },
      );

      return !isSame;
    }

    return false;
  }, [
    blurFaces,
    drawResult,
    flipHorizontally,
    flipVertically,
    instanceName,
    loopVideo,
    processAllFrames,
    resolutionHeight,
    resolutionWidth,
    rotateMode,
    sourceType,
    videoFilePath,
    videoFeedStreamUrl,
    webcamIndex,
    aiSettings,
    aggregatedCsvEnabled,
    aggregatedCsvPath,
    aggregationFrequency,
    aggregatedPullEnabled,
    aggregatedPushEnabled,
    aggregatedPushUrl,
    flattenedAiSettings,
    data,
    httpPort,
    httpSslCertificate,
    httpSslEnabled,
    httpSslKey,
    peopleCountCsvEnabled,
    peopleCountCsvPath,
    peopleCountEnabled,
    peopleCountFrequency,
    peopleCountPullEnabled,
    peopleCountPushEnabled,
    peopleCountPushUrl,
    rawCsvEnabled,
    rawCsvPath,
    rawPullEnabled,
    rawPushEnabled,
    rawPushUrl,
    roiX,
    roiY,
    roiW,
    roiH,
    statusPullEnabled,
    statusPushEnabled,
    statusPushUrl,
    sdkUseAge,
    sdkUseEmotions,
    sdkUseFaceMask,
    sdkUseGender,
    sdkUseHeadpose,
    sdkUseSmile,
    sdkDetectionRange,
    sdkMaxFaceSize,
    sdkMinFaceSize,
    sdkMaxNumFaces,
    sdkOperationMode,
    sdkFaceConfidenceThreshold,
    sdkNeuralNetworksPath,
    sdkBodyTrackFps,
    sdkDnnTarget,
    sdkLogLevel,
    sdkNumThreads,
    zoomFactor,
    zoomCenterOffsetX,
    zoomCenterOffsetY,
    ucmsCsvEnabled,
    ucmsCsvPath,
    ucmsEnabled,
    ucmsMode,
    ucmsPushEnabled,
    ucmsPushUrl,
    currentStatsDuration,
    currentStatsEnabled,
  ]);

  const renderSettings = () => {
    if (isLoading) {
      return (
        <Center>
          <CircularProgress size={30} />
        </Center>
      );
    }

    if (isError) {
      return (
        <Center>
          <>
            <ErrorOutlineIcon className="icon" color="error" />
            <Text small error>
              Error
            </Text>
          </>
        </Center>
      );
    }

    if (data && flattenedAiSettings) {
      return (
        <>
          {aiSettings && renderVideoFeedSettings()}
          {aiSettingsSchema && showVideoFeedSettingsGroup && (
            <>
              <DeviceAiSettingsVideoFeed
                data={{
                  blurFaces: blurFaces,
                  drawResult: drawResult,
                  flipHorizontally: flipHorizontally,
                  flipVertically: flipVertically,
                  instanceName: instanceName,
                  loopVideo: loopVideo,
                  processAllFrames: processAllFrames,
                  resolutionHeight: resolutionHeight,
                  resolutionWidth: resolutionWidth,
                  rotateMode: rotateMode,
                  sourceType: sourceType,
                  videoFilePath: videoFilePath,
                  videoStreamUrl: videoFeedStreamUrl,
                  webcamIndex: webcamIndex,
                }}
                onChangeBlurFaces={setBlurFaces}
                onChangeDrawResult={setDrawResult}
                onChangeFlipHorizontally={setFlipHorizontally}
                onChangeFlipVertically={setFlipVertically}
                onChangeInstanceName={setInstanceName}
                onChangeLoopVideo={setLoopVideo}
                onChangeProcessAllFrames={setProcessAllFrames}
                onChangeResolutionHeight={setResolutionHeight}
                onChangeResolutionWidth={setResolutionWidth}
                onChangeRotateMode={setRotateMode}
                onChangeSourceType={setSourceType}
                onChangeVideoFilePath={setVideoFilePath}
                onChangeVideoStreamUrl={setVideoFeedStreamUrl}
                onChangeWebcamIndex={(value) =>
                  value ? setWebcamIndex(parseInt(value, 10)) : undefined
                }
                rotateModeOptions={
                  getSetting('#root/rotateMode', aiSettingsSchema!)?.enum
                }
                sourceTypeOptions={
                  getSetting('#root/sourceType', aiSettingsSchema!)?.enum
                }
                showBlurFaces={
                  !isHidden(getSetting('#root/blurFaces', aiSettingsSchema!)!)
                }
                showDrawResult={
                  !isHidden(getSetting('#root/drawResult', aiSettingsSchema!)!)
                }
                showFlipHorizontally={
                  !isHidden(
                    getSetting('#root/flipHorizontally', aiSettingsSchema!)!,
                  )
                }
                showFlipVeritcally={
                  !isHidden(
                    getSetting('#root/flipVertically', aiSettingsSchema!)!,
                  )
                }
                showInstanceName={
                  !isHidden(
                    getSetting('#root/instanceName', aiSettingsSchema!)!,
                  )
                }
                showLoopVideo={
                  !isHidden(getSetting('#root/loopVideo', aiSettingsSchema!)!)
                }
                showProcessAllFrames={
                  !isHidden(
                    getSetting('#root/processAllFrames', aiSettingsSchema!)!,
                  )
                }
                showResolutionHeight={
                  !isHidden(
                    getSetting('#root/resolutionHeight', aiSettingsSchema!)!,
                  )
                }
                showResolutionWidth={
                  !isHidden(
                    getSetting('#root/resolutionWidth', aiSettingsSchema!)!,
                  )
                }
                showRotateMode={
                  !isHidden(getSetting('#root/rotateMode', aiSettingsSchema!)!)
                }
                showSourceType={
                  !isHidden(getSetting('#root/sourceType', aiSettingsSchema!)!)
                }
                showVideoFilePath={
                  !isHidden(
                    getSetting('#root/videoFilePath', aiSettingsSchema!)!,
                  )
                }
                showVideoStreamUrl={
                  !isHidden(
                    getSetting('#root/videoStreamUrl', aiSettingsSchema!)!,
                  )
                }
                showWebcamIndex={
                  !isHidden(getSetting('#root/webcamIndex', aiSettingsSchema!)!)
                }
              />
              {showAggregationGroup && (
                <DeviceAiSettingsAggregation
                  data={{
                    csvEnabled: aggregatedCsvEnabled,
                    csvPath: aggregatedCsvPath,
                    frequency: aggregationFrequency,
                    pullEnabled: aggregatedPullEnabled,
                    pushEnabled: aggregatedPushEnabled,
                    pushUrl: aggregatedPushUrl,
                  }}
                  onChangeCsvEnabled={setAggregatedCsvEnabled}
                  onChangeCsvPath={setAggregatedCsvPath}
                  onChangePullEnabled={setAggregatedPullEnabled}
                  onChangePushEnabled={setAggregatedPushEnabled}
                  onChangePushUrl={setAggregatedPushUrl}
                  showCsvEnabled={
                    !isHidden(
                      getSetting(
                        '#root/aggregatedCsvEnabled',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                  showCsvPath={
                    !isHidden(
                      getSetting('#root/aggregatedCsvPath', aiSettingsSchema!)!,
                    )
                  }
                  showFrequency={
                    !isHidden(
                      getSetting(
                        '#root/aggregationFrequency',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                  showPullEnabled={
                    !isHidden(
                      getSetting(
                        '#root/aggregatedPullEnabled',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                  showPushEnabled={
                    !isHidden(
                      getSetting(
                        '#root/aggregatedPushEnabled',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                  showPushUrl={
                    !isHidden(
                      getSetting('#root/aggregatedPushUrl', aiSettingsSchema!)!,
                    )
                  }
                />
              )}
              {showHttpGroup && (
                <DeviceAiSettingsHttpInterface
                  data={{
                    port: httpPort,
                    sslCertificate: httpSslCertificate,
                    sslKey: httpSslKey,
                    sslEnabled: httpSslEnabled,
                  }}
                  onChangePort={(value) =>
                    setHttpPort(value ? parseInt(value, 10) : undefined)
                  }
                  onChangeSslCertificate={setHttpSslCertificate}
                  onChangeSslKey={setHttpSslKey}
                  onChangeSslEnabled={setHttpSslEnabled}
                  showPort={
                    !isHidden(
                      getSetting('#root/httpInterfacePort', aiSettingsSchema!)!,
                    )
                  }
                  showSslEnabled={
                    !isHidden(
                      getSetting('#root/httpInterfaceSsl', aiSettingsSchema!)!,
                    )
                  }
                  showSslKey={
                    !isHidden(
                      getSetting(
                        '#root/httpInterfaceSslKey',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                  showSslCertificate={
                    !isHidden(
                      getSetting(
                        '#root/httpInterfaceSslCrt',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                />
              )}
              {showPeopleCountGroup && (
                <DeviceAiSettingsPeopleCount
                  data={{
                    csvEnabled: peopleCountCsvEnabled,
                    csvPath: peopleCountCsvPath,
                    enabled: peopleCountEnabled,
                    frequency: peopleCountFrequency,
                    pullEnabled: peopleCountPullEnabled,
                    pushEnabled: peopleCountPushEnabled,
                    pushUrl: peopleCountPushUrl,
                  }}
                  onChangeCsvEnabled={setPeopleCountCsvEnabled}
                  onChangeCsvPath={setPeopleCountCsvPath}
                  onChangeEnabled={setPeopleCountEnabled}
                  onChangeFrequency={(value) =>
                    setPeopleCountFrequency(
                      value ? parseInt(value, 10) : undefined,
                    )
                  }
                  onChangePullEnabled={setPeopleCountPullEnabled}
                  onChangePushEnabled={setPeopleCountPushEnabled}
                  onChangePushUrl={setPeopleCountPushUrl}
                  showCsvEnabled={
                    !isHidden(
                      getSetting(
                        '#root/peopleCountCsvEnabled',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                  showCsvPath={
                    !isHidden(
                      getSetting(
                        '#root/peopleCountCsvPath',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                  showEnabled={
                    !isHidden(
                      getSetting(
                        '#root/peopleCountEnabled',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                  showFrequency={
                    !isHidden(
                      getSetting(
                        '#root/peopleCountFrequency',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                  showPullEnabled={
                    !isHidden(
                      getSetting(
                        '#root/peopleCountPullEnabled',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                  showPushEnabled={
                    !isHidden(
                      getSetting(
                        '#root/peopleCountPushEnabled',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                  showPushUrl={
                    !isHidden(
                      getSetting(
                        '#root/peopleCountPushUrl',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                />
              )}
              {showRawGroup && (
                <DeviceAiSettingsRaw
                  data={{
                    csvEnabled: rawCsvEnabled,
                    csvPath: rawCsvPath,
                    pullEnabled: rawPullEnabled,
                    pushEnabled: rawPushEnabled,
                    pushUrl: rawPushUrl,
                  }}
                  onChangeCsvEnabled={setRawCsvEnabled}
                  onChangeCsvPath={setRawCsvPath}
                  onChangePullEnabled={setRawPullEnabled}
                  onChangePushEnabled={setRawPushEnabled}
                  onChangePushUrl={setRawPushUrl}
                  showCsvEnabled={
                    !isHidden(
                      getSetting('#root/rawCsvEnabled', aiSettingsSchema!)!,
                    )
                  }
                  showCsvPath={
                    !isHidden(
                      getSetting('#root/rawCsvPath', aiSettingsSchema!)!,
                    )
                  }
                  showPullEnabled={
                    !isHidden(
                      getSetting('#root/rawPullEnabled', aiSettingsSchema!)!,
                    )
                  }
                  showPushEnabled={
                    !isHidden(
                      getSetting('#root/rawPushEnabled', aiSettingsSchema!)!,
                    )
                  }
                  showPushUrl={
                    !isHidden(
                      getSetting('#root/rawPushUrl', aiSettingsSchema!)!,
                    )
                  }
                />
              )}
              {showStatusGroup && (
                <DeviceAiSettingsStatus
                  data={{
                    pullEnabled: statusPullEnabled,
                    pushEnabled: statusPushEnabled,
                    pushUrl: statusPushUrl,
                  }}
                  onChangePullEnabled={setStatusPullEnabled}
                  onChangePushEnabled={setStatusPushEnabled}
                  onChangePushUrl={setStatusPushUrl}
                  showPullEnabled={
                    !isHidden(
                      getSetting('#root/statusPullEnabled', aiSettingsSchema!)!,
                    )
                  }
                  showPushEnabled={
                    !isHidden(
                      getSetting('#root/statusPushEnabled', aiSettingsSchema!)!,
                    )
                  }
                  showPushUrl={
                    !isHidden(
                      getSetting('#root/statusPushUrl', aiSettingsSchema!)!,
                    )
                  }
                />
              )}
              {showZoomSettingsGroup && (
                <DeviceAiSettingsZoom
                  data={{
                    zoomFactor: zoomFactor?.toString(),
                    zoomCenterOffsetX: zoomCenterOffsetX?.toString(),
                    zoomCenterOffsetY: zoomCenterOffsetY?.toString(),
                  }}
                  onChangeZoomFactor={(value) =>
                    setZoomFactor(value ? parseInt(value, 10) : undefined)
                  }
                  onChangeZoomCenterOffsetX={(value) =>
                    setZoomCenterOffsetX(
                      value ? parseInt(value, 10) : undefined,
                    )
                  }
                  onChangeZoomCenterOffsetY={(value) =>
                    setZoomCenterOffsetY(
                      value ? parseInt(value, 10) : undefined,
                    )
                  }
                  showZoomFactor={
                    !isHidden(
                      getSetting('#root/zoomFactor', aiSettingsSchema!)!,
                    )
                  }
                  showZoomOffsetX={
                    !isHidden(
                      getSetting(
                        '#root/zoomCenterOffset_X',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                  showZoomOffsetY={
                    !isHidden(
                      getSetting(
                        '#root/zoomCenterOffset_Y',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                />
              )}
              {showSdkGroup && (
                <DeviceAiSettingsSdk
                  data={{
                    useAge: sdkUseAge,
                    useEmotions: sdkUseEmotions,
                    useFaceMask: sdkUseFaceMask,
                    useGender: sdkUseGender,
                    useHeadpose: sdkUseHeadpose,
                    useSmile: sdkUseSmile,
                    detectionRange: sdkDetectionRange,
                    maxFaceSize: sdkMaxFaceSize,
                    minFaceSize: sdkMinFaceSize,
                    maxNumFaces: sdkMaxNumFaces,
                    operationMode: sdkOperationMode,
                    faceConfidenceThreshold: sdkFaceConfidenceThreshold,
                    neuralNetworksPath: sdkNeuralNetworksPath,
                    bodyTrackFps: sdkBodyTrackFps,
                    dnnTarget: sdkDnnTarget,
                    logLevel: sdkLogLevel,
                    numThreads: sdkNumThreads,
                  }}
                  detectionRangeOptions={
                    getSetting(
                      '#root/sdkSettings/detectionRange',
                      aiSettingsSchema!,
                    )?.enum
                  }
                  logLevelOptions={
                    getSetting('#root/sdkSettings/logLevel', aiSettingsSchema!)
                      ?.enum
                  }
                  operationModeOptions={
                    getSetting(
                      '#root/sdkSettings/operationMode',
                      aiSettingsSchema!,
                    )?.enum
                  }
                  onChangeUseAge={setSdkUseAge}
                  onChangeUseEmotions={setSdkUseEmotions}
                  onChangeUseFaceMask={setSdkUseFaceMask}
                  onChangeUseHeadpose={setSdkUseHeadpose}
                  onChangeUseSmile={setSdkUseSmile}
                  onChangeDetectionRange={setSdkDetectionRange}
                  onChangeMaxFaceSize={(value) =>
                    setSdkMaxFaceSize(value ? parseInt(value, 10) : undefined)
                  }
                  onChangeMinFaceSize={(value) =>
                    setSdkMinFaceSize(value ? parseInt(value, 10) : undefined)
                  }
                  onChangeMaxNumFaces={setSdkMaxNumFaces}
                  onChangeOperationMode={setSdkOperationMode}
                  onChangeFaceConfidenceThreshold={
                    setSdkFaceConfidenceThreshold
                  }
                  onChangeNeuralNetworksPath={setSdkNeuralNetworksPath}
                  onChangeBodyTrackFps={setSdkBodyTrackFps}
                  onChangeDnnTarget={setSdkDnnTarget}
                  onChangeLogLevel={setSdkLogLevel}
                  onChangeNumThreads={setSdkNumThreads}
                  showUseAge={
                    !isHidden(
                      getSetting(
                        '#root/sdkSettings/useAge',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                  showUseEmotions={
                    !isHidden(
                      getSetting(
                        '#root/sdkSettings/useEmotions',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                  showUseFaceMask={
                    !isHidden(
                      getSetting(
                        '#root/sdkSettings/useFaceMask',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                  showUseGender={
                    !isHidden(
                      getSetting(
                        '#root/sdkSettings/useGender',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                  showUseHeadpose={
                    !isHidden(
                      getSetting(
                        '#root/sdkSettings/useHeadpose',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                  showUseSmile={
                    !isHidden(
                      getSetting(
                        '#root/sdkSettings/useSmile',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                  showDetectionRange={
                    !isHidden(
                      getSetting(
                        '#root/sdkSettings/detectionRange',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                  showMaxFaceSize={
                    !isHidden(
                      getSetting(
                        '#root/sdkSettings/maxFaceSize',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                  showMinFaceSize={
                    !isHidden(
                      getSetting(
                        '#root/sdkSettings/minFaceSize',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                  showMaxNumFaces={
                    !isHidden(
                      getSetting(
                        '#root/sdkSettings/maxNumFaces',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                  showOperationMode={
                    !isHidden(
                      getSetting(
                        '#root/sdkSettings/operationMode',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                  showFaceConfidenceThreshold={
                    !isHidden(
                      getSetting(
                        '#root/sdkSettings/faceConfidenceThreshold',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                  showNeuralNetworksPath={
                    !isHidden(
                      getSetting(
                        '#root/sdkSettings/neuralNetworksPath',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                  showBodyTrackFps={
                    !isHidden(
                      getSetting(
                        '#root/sdkSettings/bodyTrackFps',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                  showDnnTarget={
                    !isHidden(
                      getSetting(
                        '#root/sdkSettings/dnnTarget',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                  showLogLevel={
                    !isHidden(
                      getSetting(
                        '#root/sdkSettings/logLevel',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                  showNumThreads={
                    !isHidden(
                      getSetting(
                        '#root/sdkSettings/numThreads',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                />
              )}
              {showUcmsGroup && (
                <DeviceAiSettingsUcms
                  data={{
                    enabled: ucmsEnabled,
                    csvEnabled: ucmsCsvEnabled,
                    csvPath: ucmsCsvPath,
                    mode: ucmsMode,
                    pushEnabled: ucmsPushEnabled,
                    pushUrl: ucmsPushUrl,
                  }}
                  onChangeEnabled={setUcmsEnabled}
                  onChangeCsvEnabled={setUcmsCsvEnabled}
                  onChangeCsvPath={setUcmsCsvPath}
                  onChangePushEnabled={setUcmsPushEnabled}
                  onChangePushUrl={setUcmsPushUrl}
                  onChangeMode={setUcmsMode}
                  showEnabled={
                    !isHidden(
                      getSetting('#root/ucmsEnabled', aiSettingsSchema!)!,
                    )
                  }
                  showCsvEnabled={
                    !isHidden(
                      getSetting('#root/ucmsCsvEnabled', aiSettingsSchema!)!,
                    )
                  }
                  showCsvPath={
                    !isHidden(
                      getSetting('#root/ucmsCsvPath', aiSettingsSchema!)!,
                    )
                  }
                  showMode={
                    !isHidden(getSetting('#root/ucmsMode', aiSettingsSchema!)!)
                  }
                  showPushEnabled={
                    !isHidden(
                      getSetting('#root/ucmsPushEnabled', aiSettingsSchema!)!,
                    )
                  }
                  showPushUrl={
                    !isHidden(
                      getSetting('#root/ucmsPushUrl', aiSettingsSchema!)!,
                    )
                  }
                />
              )}
              {showCurrentStatsGroup && (
                <DeviceAiSettingsCurrentStats
                  data={{
                    duration: currentStatsDuration,
                    enabled: currentStatsEnabled,
                  }}
                  onChangeEnabled={setCurrentStatsEnabled}
                  onChangeDuration={(value) =>
                    setCurrentStatsDuration(
                      value ? parseInt(value, 10) : undefined,
                    )
                  }
                  showDuration={
                    !isHidden(
                      getSetting(
                        '#root/currentStatsDuration',
                        aiSettingsSchema!,
                      )!,
                    )
                  }
                />
              )}
            </>
          )}
          {showApplyButton && (
            <div className={classes.section}>
              <LoadingButton
                color={'primary'}
                label={'Apply Changes'}
                disabled={!isDirty}
                iconAlignment="start"
                fullWidth
                status={submitStatus}
                successLabel="Saved"
                loadingLabel="Saving..."
                errorLabel="Failed!"
                delay={1000}
                onClick={handleUpdateAISettings}
              />
            </div>
          )}
        </>
      );
    }
  };

  const deviceType = getDeviceType(device);

  return (
    <>
      <PaperModal.Body>
        <Column>
          <div>
            <Heading className={classes.title}>
              <Text editable value={device.name} />
            </Heading>
            <Heading size={5}>AI Settings</Heading>
            {deviceType && (
              <Heading size={5} className={classes.type}>
                {deviceType}
              </Heading>
            )}
          </div>
        </Column>
        <br />
      </PaperModal.Body>
      <Scrollable>
        <PaperModal.Body>
          <Column>{renderSettings()}</Column>
        </PaperModal.Body>
      </Scrollable>
      <DeviceAiSettingsVideoModal
        isOpen={isVideoModalOpen}
        onClose={() => setIsVideoModalOpen(false)}
        onPlay={handleStartVideoFeed}
        onStop={handleStopVideoFeed}
        onChangeRegionOfInterestX={(value) =>
          setRoiX(value ? parseInt(value, 10) : undefined)
        }
        onChangeRegionOfInterestY={(value) =>
          setRoiY(value ? parseInt(value, 10) : undefined)
        }
        onChangeRegionOfInterestW={(value) =>
          setRoiW(value ? parseInt(value, 10) : undefined)
        }
        onChangeRegionOfInterestH={(value) =>
          setRoiH(value ? parseInt(value, 10) : undefined)
        }
        regionOfInterestData={{
          x: roiX,
          y: roiY,
          w: roiW,
          h: roiH,
        }}
        url={videoStreamURL}
        videoHasError={cameraDisconnectedError}
        videoErrors={getVideoErrors()}
        videoPlayerElement={videoPlayerElementModal}
        videoPlayerState={videoPlayerState}
      />
    </>
  );
};

export default DeviceAISettings;
