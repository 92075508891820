import { Grid } from '@material-ui/core';
import TextField from 'raydiant-elements/core/TextField/TextField';
import DeviceAiSettingsGroup from './DeviceAiSettingsGroup';
import { regionOfInterest } from './DeviceAiSettingsVideoModal';

interface DeviceAiSettingsRegionOfInterestModalProps {
  onChangeX: (value: string) => void;
  onChangeY: (value: string) => void;
  onChangeW: (value: string) => void;
  onChangeH: (value: string) => void;
  data: regionOfInterest;
}

const DeviceAiSettingsRegionOfInterestModal = ({
  data,
  onChangeX,
  onChangeY,
  onChangeW,
  onChangeH,
}: DeviceAiSettingsRegionOfInterestModalProps) => {
  return (
    <DeviceAiSettingsGroup collapsable={false}>
      <Grid container spacing={1}>
        <Grid item xs={6} md={3}>
          <TextField
            label={'X (px)'}
            value={data.x?.toString()}
            onChange={onChangeX}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label={'Y (px)'}
            value={data.y?.toString()}
            onChange={onChangeY}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label={'W (px)'}
            value={data.w?.toString()}
            onChange={onChangeW}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label={'H (px)'}
            value={data.h?.toString()}
            onChange={onChangeH}
          />
        </Grid>
      </Grid>
    </DeviceAiSettingsGroup>
  );
};

export default DeviceAiSettingsRegionOfInterestModal;
