import Switch from 'raydiant-elements/core/Switch';
import TextField from 'raydiant-elements/core/TextField/TextField';
import useStyles from '../DeviceAISettings.styles';
import DeviceAiSettingsGroup from '../DeviceAiSettingsGroup';

interface DeviceAiSettingsHttpInterfaceData {
  port?: number;
  sslEnabled?: boolean;
  sslCertificate?: string;
  sslKey?: string;
}

interface DeviceAiSettingsHttpInterfaceProps {
  data: DeviceAiSettingsHttpInterfaceData;
  onChangeSslCertificate?: (value: string) => void;
  onChangeSslKey?: (value: string) => void;
  onChangePort?: (value: string) => void;
  onChangeSslEnabled?: (value: boolean) => void;
  showPort?: boolean;
  showSslEnabled?: boolean;
  showSslCertificate?: boolean;
  showSslKey?: boolean;
}

const DeviceAiSettingsHttpInterface = ({
  data,
  onChangeSslCertificate,
  onChangeSslKey,
  onChangePort,
  onChangeSslEnabled,
  showPort = false,
  showSslEnabled = false,
  showSslCertificate = false,
  showSslKey = false,
}: DeviceAiSettingsHttpInterfaceProps) => {
  const classes = useStyles();

  return (
    <DeviceAiSettingsGroup title="Http Interface">
      {showSslEnabled && (
        <li className={classes.aiSetting} key={'sslEnabled'}>
          <Switch
            checked={data.sslEnabled || false}
            onChange={onChangeSslEnabled}
            label={'Ssl Enabled'}
          />
        </li>
      )}
      {showPort && (
        <li className={classes.aiSetting} key={'port'}>
          <TextField
            label={'Port'}
            value={data.port?.toString()}
            onChange={onChangePort}
          />
        </li>
      )}
      {showSslKey && (
        <li className={classes.aiSetting} key={'sslKey'}>
          <TextField
            label={'Ssl Key'}
            value={data.sslKey}
            onChange={onChangeSslKey}
          />
        </li>
      )}
      {showSslCertificate && (
        <li className={classes.aiSetting} key={'sslCertificate'}>
          <TextField
            label={'Ssl Certificate'}
            value={data.sslCertificate}
            onChange={onChangeSslCertificate}
          />
        </li>
      )}
    </DeviceAiSettingsGroup>
  );
};

export default DeviceAiSettingsHttpInterface;
