import { SVGProps } from 'react';

interface CircleRotateLeftProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const CircleRotateLeft = ({ size = 18, ...props }: CircleRotateLeftProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.66814 3.79501C3.20173 1.66133 5.73548 0.227753 8.60263 0.227753C13.3034 0.227753 17.1374 4.06173 17.1374 8.76251C17.1374 13.4966 13.3034 17.2973 8.60263 17.2973C6.83566 17.2973 5.2354 16.7972 3.8685 15.897C3.50178 15.6637 3.40176 15.1636 3.66847 14.7968C3.90184 14.4301 4.40193 14.3301 4.76865 14.5635C5.86884 15.2969 7.16905 15.697 8.60263 15.697C12.4033 15.697 15.5371 12.5965 15.5371 8.76251C15.5371 4.96188 12.4033 1.82802 8.60263 1.82802C6.13555 1.82802 3.96852 3.12824 2.73498 5.02855H4.60196C5.03537 5.02855 5.40209 5.39528 5.40209 5.82869C5.40209 6.29543 5.03537 6.62882 4.60196 6.62882H0.868004C0.40126 6.62882 0.0678711 6.29543 0.0678711 5.82869V2.09473C0.0678711 1.66133 0.40126 1.2946 0.868004 1.2946C1.30141 1.2946 1.66814 1.66133 1.66814 2.09473V3.79501ZM8.60263 4.49513H8.56929C9.03603 4.49513 9.36942 4.86186 9.36942 5.29526V8.46246L11.5364 10.6295C11.8698 10.9295 11.8698 11.4296 11.5364 11.7297C11.2364 12.0631 10.7363 12.0631 10.4363 11.7297L8.03586 9.32927C7.86917 9.19591 7.80249 8.99588 7.80249 8.76251V5.29526C7.80249 4.86186 8.13588 4.49513 8.60263 4.49513Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default CircleRotateLeft;
