import { useSelector } from 'react-redux';
import { selectIsVIOnly, selectIsVIEnabled } from '../../../../selectors/user';
import VisitorInsightOnlyCard from './VisitorInsightOnly';
import VisitorInsightCard from './VisitorInsightCard';
import NonVisitorInsightCard from './NonVisitorInsightCard';

const VisitorInsightCarousel = () => {
  // Selectors
  const isVIOnly = useSelector(selectIsVIOnly);
  const isVIEnabled = useSelector(selectIsVIEnabled);

  const renderCarousel = () => {
    if (isVIOnly) {
      return <VisitorInsightOnlyCard />;
    } else if (isVIEnabled) {
      return <VisitorInsightCard />;
    } else {
      return <NonVisitorInsightCard />;
    }
  };

  return <>{renderCarousel()}</>;
};

export default VisitorInsightCarousel;
