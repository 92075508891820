import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AccountPage from '../pages/AccountPage';
import ApplicationPage from '../pages/ApplicationPage';
import ApplicationsPage from '../pages/ApplicationsPage';
import DevicesPage from '../pages/DevicesPage';
import LegacyChangePasswordBackstopPage from '../pages/LegacyChangePasswordBackstopPage';
import LibraryPage from '../pages/LibraryPage';
import LoginCallbackPage from '../pages/LoginCallbackPage';
import LoginPage from '../pages/LoginPage';
import PlaylistPage from '../pages/PlaylistPage';
import PresentationPage from '../pages/PresentationPage';
import AddContentPage from '../pages/AddContentPage';
import UnsubscribePage from '../pages/UnsubscribePage';
import HomePage from '../pages/HomePage';
import EnterpriseWelcomePage from '../pages/EnterpriseWelcomePage';
import ActivatePage from '../pages/ActivatePage';
import * as paths from './paths';
import useCurrentUserRoles from '../hooks/useCurrentUserRoles';
import { useSelector } from 'react-redux';
import {
  selectIsEnterpriseUser,
  selectUserIsFetching,
  selectUserProfile,
} from '../selectors/user';
import IntegrationPage from '../pages/IntegrationPage';
import MarketplaceV2Page from '../pages/MarketplacePage/MarketplaceV2Page';
import AnalyticsPageV2 from '../pages/AnalyticsPage/ProductEngagementPage';
import ContentEngagementPage from '../pages/AnalyticsPage/ContentEngagementPage';
import TrafficAwarenessPage from '../pages/AnalyticsPage/TrafficAwarenessPage';
import ContentDetailPage from '../pages/AnalyticsPage/ContentDetailPage';
import ProductHubCataloguePage from '../pages/ProductHubPage/ProductHubCataloguePage';
import ProductHubIntegrationsPage from '../pages/ProductHubPage/ProductHubIntegrationsPage';
import LocationsPage from '../pages/LocationsPage';
import ProductHubCreateIntegrationPage from '../pages/ProductHubPage/ProductHubCreateIntegrationPage';
import ProductHubEditIntegrationRouter from '../pages/ProductHubPage/ProductHubEditIntegrationRouter';
import ProductHubCatalogueDetailsPage from '../pages/ProductHubPage/ProductHubCatalogueDetailsPage';
import ProductHubCreateCataloguePage from '../pages/ProductHubPage/ProductHubCreateCataloguePage';
import LoadingModal from '@raydiant/raydial/components/LoadingModal';

const ProductHubRoutes = () => {
  const isUserFetching = useSelector(selectUserIsFetching);
  const roles = useCurrentUserRoles();

  if (isUserFetching) {
    return <LoadingModal isOpen={true} />;
  }

  if (!roles.isProductHubEnabled) {
    return null;
  }

  return (
    <Switch>
      <Route
        exact
        path={paths.productHubIntegrations.pattern}
        component={ProductHubIntegrationsPage}
      />
      <Route
        exact
        path={paths.productHubCreateCatalog.pattern}
        component={ProductHubCreateCataloguePage}
      />
      <Route
        exact
        path={paths.productHubCreateIntegrations.pattern}
        component={ProductHubCreateIntegrationPage}
      />
      <Route
        exact
        path={paths.productHubEditIntegrations.pattern}
        component={ProductHubEditIntegrationRouter}
      />
      <Route
        exact
        path={paths.productHubCatalogDetails.pattern}
        component={ProductHubCatalogueDetailsPage}
      />
      <Route
        exact
        path={paths.productHubCatalogs.pattern}
        component={ProductHubCataloguePage}
      />
    </Switch>
  );
};

const Routes: React.SFC = () => {
  const roles = useCurrentUserRoles();
  const isEnterpriseUser = useSelector(selectIsEnterpriseUser);
  const currentUser = useSelector(selectUserProfile);

  return (
    <Switch>
      <Route path={paths.loginCallback.pattern} component={LoginCallbackPage} />
      <Route path={paths.login.pattern} component={LoginPage} />
      <Route path={paths.activate.pattern} component={ActivatePage} />
      <Route
        path={paths.screens.pattern}
        component={currentUser?.isLocationEnabled ? LocationsPage : DevicesPage}
      />
      <Route path={paths.library.pattern} component={LibraryPage} />
      <Route path={paths.account.pattern} component={AccountPage} />

      <Route
        exact
        path={[
          paths.newPresentation.pattern,
          paths.newPresentationNewTheme.pattern,
          paths.newPresentationEditTheme.pattern,
          paths.newPresentationManageThemes.pattern,
          paths.editPresentation.pattern,
          paths.editPresentationNewTheme.pattern,
          paths.editPresentationEditTheme.pattern,
          paths.editPresentationManageThemes.pattern,
        ]}
        component={PresentationPage}
      />
      <Route path={paths.presentations.pattern} component={AddContentPage} />
      <Route path={paths.application.pattern} component={ApplicationPage} />
      <Route path={paths.applications.pattern} component={ApplicationsPage} />
      <Route path={paths.unsubscribe.pattern} component={UnsubscribePage} />
      <Route path={paths.newPlaylist.pattern} component={PlaylistPage} />
      <Route path={paths.editPlaylist.pattern} component={PlaylistPage} />
      <Route
        path={paths.enterpriseWelcome.pattern}
        component={EnterpriseWelcomePage}
      />
      <Route
        exact
        path={paths.marketplace.pattern}
        component={MarketplaceV2Page}
      />

      <Route
        path={paths.trafficAwareness.pattern}
        component={TrafficAwarenessPage}
      />
      <Route
        exact
        path={paths.contentEngagement.pattern}
        component={ContentEngagementPage}
      />
      <Route
        exact
        path={paths.contentDetail.pattern}
        component={ContentDetailPage}
      />
      <Route exact path={paths.analytics.pattern} component={AnalyticsPageV2} />

      <Route path={paths.productHub.pattern}>
        <ProductHubRoutes />
      </Route>

      {roles.isAIPOSEnabled && isEnterpriseUser && (
        <Route path={paths.integration.pattern} component={IntegrationPage} />
      )}
      <Route path={paths.home.pattern} component={HomePage} />
      {/* TODO: Legacy changePassword redirect, remove after enterprise migration is complete. */}
      <Route
        path={paths.legacyChangePassword.pattern}
        component={LegacyChangePasswordBackstopPage}
      />
      {/* Redirects */}
      <Route
        path={paths.indexHtml.pattern}
        render={() => <Redirect to={paths.home()} />}
      />
      {/* TODO: 404 page? */}
      <Route render={() => <Redirect to={paths.home()} />} />
    </Switch>
  );
};

export default Routes;
