import { FC, useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserProfile, selectIsVIOnly } from '../../selectors/user';
import {
  selectHasPublishableDevices,
  selectDeviceStatusById,
  selectPlaylistStatusByDeviceIdBulkUpdate,
} from '../../selectors/v2/devices';
import * as deviceActions from '../../actions/devices';
import {
  canEditResource,
  SortDeviceOptions,
  isDevicePublishable,
} from '../../utilities';
import {
  selectSortOptions,
  selectSearchQuery,
  selectDeviceResults,
  selectBatchMode,
  selectSelectedDeviceIds,
  selectMoveScreenMode,
} from '../DevicesPage/selectors';
import * as actions from '../DevicesPage/actions';
import { useHistory, useLocation } from 'react-router-dom';
import * as paths from '../../routes/paths';
import cn from 'classnames';
import ArrowDownArrowUp from './Icons/ArrowDownArrowUp';
import Filter from './Icons/Filter';
import XMark from './Icons/XMark';
import RectangleHistory from './Icons/RectangleHistory';
import Search from './Icons/Search';
import CircleArrowRight from './Icons/CircleArrowRight';
import ArrowUpToLine from './Icons/ArrowUpToLine';
import Button from '@raydiant/raydial/components/Button';
import Popover from '@raydiant/raydial/components/Popover';
import Dialog from '@raydiant/raydial/components/Dialog';
import Hidden from 'raydiant-elements/layout/Hidden';

interface DevicesActionBarProps {
  disabled?: boolean;
}

const DevicesActionBar: FC<DevicesActionBarProps> = ({ disabled }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();

  // Selectors

  const deviceResults = useSelector(selectDeviceResults);
  const currentUser = useSelector(selectUserProfile);
  const sortOptions = useSelector(selectSortOptions);
  const searchQuery = useSelector(selectSearchQuery);
  const hasPublishableDevices = useSelector(selectHasPublishableDevices);
  const deviceStatusById = useSelector(selectDeviceStatusById);
  const playlistStatusByDeviceId = useSelector(
    selectPlaylistStatusByDeviceIdBulkUpdate,
  );
  const batchMode = useSelector(selectBatchMode);
  const moveScreenMode = useSelector(selectMoveScreenMode);
  const selectedDeviceIds = useSelector(selectSelectedDeviceIds);
  const isVIOnly = useSelector(selectIsVIOnly);
  const isEnterpriseUser = currentUser?.domainId !== null;
  const isSuperAdmin = currentUser?.domainRole === 'superadmin';
  const showMoveScreen =
    !isEnterpriseUser || (isEnterpriseUser && isSuperAdmin);

  // State

  const [isSortMode, setIsSortMode] = useState(false);
  const [isSearchMode, setIsSearchMode] = useState(false);
  const triggerRef = useRef(null);

  // Callbacks

  const toggleSort = useCallback(
    (property: SortDeviceOptions['property']) => {
      let direction: SortDeviceOptions['direction'] =
        property === 'publishedAt' ? 'desc' : 'asc';

      if (property === sortOptions.property) {
        direction = sortOptions.direction === 'asc' ? 'desc' : 'asc';
      }

      dispatch(actions.setSortOptions({ property, direction }));

      setIsSortMode(false);
    },
    [dispatch, setIsSortMode, sortOptions],
  );

  const setSearchQuery = useCallback(
    (value: string) => {
      dispatch(actions.setSearchQuery(value));
    },
    [dispatch],
  );

  const publishAll = useCallback(() => {
    const deviceIds = Object.values(deviceResults).reduce((a, b) => {
      const selectedDevices = b.devices.filter((device) =>
        selectedDeviceIds.includes(device.id),
      );
      const devices = batchMode ? selectedDevices : b.devices;
      const publishableDevices = devices.filter((device) => {
        const deviceStatus = deviceStatusById[device.id];
        const playlistStatus = playlistStatusByDeviceId[device.id];
        return (
          currentUser &&
          canEditResource(currentUser, device.resource) &&
          isDevicePublishable(device, deviceStatus, playlistStatus)
        );
      });
      return [...a, ...publishableDevices.map((d) => d.id)];
    }, [] as string[]);

    dispatch(deviceActions.publishAll(deviceIds));
  }, [
    dispatch,
    deviceResults,
    currentUser,
    deviceStatusById,
    playlistStatusByDeviceId,
    batchMode,
    selectedDeviceIds,
  ]);

  const toggleBatchMode = useCallback(() => {
    if (!batchMode) {
      history.push({
        pathname: paths.screens(),
        search,
      });
    }
    dispatch(actions.toggleBatchMode());
  }, [dispatch, batchMode, history, search]);

  const toggleMoveScreenMode = useCallback(() => {
    if (!moveScreenMode) {
      history.push({
        pathname: paths.screens(),
        search,
      });
    }
    dispatch(actions.toggleMoveScreenMode());
  }, [dispatch, moveScreenMode, history, search]);

  // Side-effects

  // Reset search query when exiting search mode.
  useEffect(() => {
    if (isSearchMode) return;
    dispatch(actions.setSearchQuery(''));
  }, [isSearchMode, dispatch]);

  // Render

  let sortLabel = 'Sort';
  if (sortOptions.property === 'name') {
    sortLabel = `${sortLabel}: Name`;
  } else if (sortOptions.property === 'isOnline') {
    sortLabel = `${sortLabel}: Online`;
  } else if (sortOptions.property === 'publishedAt') {
    sortLabel = `${sortLabel}: Date`;
  }

  const isSortByName = sortOptions.property === 'name';
  const isSortByOnline = sortOptions.property === 'isOnline';
  const isSortByDate = sortOptions.property === 'publishedAt';
  const isReverseSort = sortOptions.direction === 'desc';

  const sortActionOptions = (
    <div className="flex flex-col text-base font-regular text-gray-700 gap-1">
      <Button
        variant="unstyled"
        fullWidth
        className={cn(
          'flex items-center px-4 py-2.5 hover:bg-gray-100',
          isSortByName && 'bg-gray-200 hover:bg-gray-200',
        )}
        onClick={() => toggleSort('name')}
      >
        {isSortByName && isReverseSort ? (
          <ArrowDownArrowUp />
        ) : (
          <ArrowDownArrowUp transform="scale(-1,1)" />
        )}
        <span className="pl-3">Name</span>
      </Button>
      <Button
        variant="unstyled"
        fullWidth
        className={cn(
          'flex items-center px-4 py-2.5 hover:bg-gray-100',
          isSortByOnline && 'bg-gray-200 hover:bg-gray-200',
        )}
        onClick={() => toggleSort('isOnline')}
      >
        {isSortByOnline && isReverseSort ? (
          <ArrowDownArrowUp />
        ) : (
          <ArrowDownArrowUp transform="scale(-1,1)" />
        )}
        <span className="pl-3">Online</span>
      </Button>
      <Button
        variant="unstyled"
        fullWidth
        className={cn(
          'flex items-center px-4 py-2.5 hover:bg-gray-100',
          isSortByDate && 'bg-gray-200 hover:bg-gray-200',
        )}
        onClick={() => toggleSort('publishedAt')}
      >
        {isSortByDate && isReverseSort ? (
          <ArrowDownArrowUp />
        ) : (
          <ArrowDownArrowUp transform="scale(-1,1)" />
        )}
        <span className="pl-3">Date</span>
      </Button>
    </div>
  );

  const shouldDisableSort = disabled;

  const sortAction = (
    <>
      <Button
        variant="unstyled"
        className={cn(
          'text-lg font-medium text-gray-400 hover:text-blue-600 outline-none md:w-[114px]',
          isSortMode && 'text-blue-600',
        )}
        onClick={() => setIsSortMode(true)}
        isDisabled={shouldDisableSort}
      >
        <div className="flex items-center" ref={triggerRef}>
          <Filter />
          <span className="hidden pl-2.5 md:block">{sortLabel}</span>
        </div>
      </Button>
      <Popover
        triggerRef={triggerRef}
        isOpen={isSortMode}
        onOpenChange={setIsSortMode}
        className="w-[116px]"
        placement="bottom start"
      >
        <Dialog className="px-0 py-2.5">
          <div className="overflow-hidden">{sortActionOptions}</div>
        </Dialog>
      </Popover>
    </>
  );

  const batchManagementAction = (
    <Button
      variant="unstyled"
      className="flex items-center text-lg font-medium text-gray-400 outline-none hover:text-blue-600 md:w-[162px]"
      onClick={toggleBatchMode}
      isDisabled={shouldDisableSort}
    >
      {batchMode ? <XMark /> : <RectangleHistory />}
      <span className="hidden pl-2.5 md:block">
        {batchMode ? 'Done' : 'Manage Multiple'}
      </span>
    </Button>
  );

  const shouldDisableSearch = disabled;

  const searchAction = (
    <>
      {isSearchMode ? (
        <div className="flex text-lg font-medium text-gray-400">
          <input
            autoFocus
            className="outline-none border-b-2 max-w-[140px] mr-1"
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Button
            variant="unstyled"
            className="text-gray-400 hover:text-blue-600 outline-none"
            onClick={() => setIsSearchMode(false)}
          >
            <XMark size={12} />
          </Button>
        </div>
      ) : (
        <Button
          variant="unstyled"
          className="flex items-center text-lg font-medium text-gray-400 enabled:hover:text-blue-600 outline-none"
          onClick={() => setIsSearchMode(true)}
          isDisabled={shouldDisableSearch}
        >
          <Search size={17} />
        </Button>
      )}
    </>
  );

  const moveScreenAction = (
    <>
      {showMoveScreen && (
        <Button
          variant="unstyled"
          className="flex items-center text-gray-400 text-lg font-medium outline-none hover:text-blue-600 md:w-[138px]"
          onClick={toggleMoveScreenMode}
          isDisabled={shouldDisableSort}
        >
          {moveScreenMode ? <XMark /> : <CircleArrowRight />}
          <span className="hidden pl-2.5 md:block">
            {moveScreenMode ? 'Done' : 'Move Screen'}
          </span>
        </Button>
      )}
    </>
  );

  const shouldDisablePublish = disabled || !hasPublishableDevices;

  const publishAllAction = (
    <>
      {!isVIOnly && (
        <Button
          variant="unstyled"
          className="flex items-center text-gray-400 text-lg font-medium outline-none enabled:hover:text-blue-600"
          onClick={publishAll}
          isDisabled={shouldDisablePublish}
        >
          <ArrowUpToLine size={17} />
          <span className="hidden pl-2.5 md:block">
            {batchMode ? 'Publish Selected' : 'Publish All'}
          </span>
        </Button>
      )}
    </>
  );

  const renderDesktopActionBar = () => (
    <>
      <div className="flex gap-6">
        {sortAction}
        {batchManagementAction}
        {moveScreenAction}
      </div>
      <div className="grow" />
      <div className="flex gap-6">
        {searchAction}
        {publishAllAction}
      </div>
    </>
  );

  const renderMobileActionBar = () => (
    <>
      <div className="flex flex-1 justify-between mr-2">
        {sortAction}
        {batchManagementAction}
        {moveScreenAction}
        {publishAllAction}
      </div>
      <div className="min-w-40 flex justify-end">{searchAction}</div>
    </>
  );

  return (
    <div className="flex bg-white px-4 rounded-3xl h-10 items-center">
      <Hidden smDown>{renderDesktopActionBar()}</Hidden>
      <Hidden mdUp>{renderMobileActionBar()}</Hidden>
    </div>
  );
};

export default DevicesActionBar;
