import Scrollable from 'raydiant-elements/layout/Scrollable';
import Text from '@raydiant/raydial/components/Text';
import { useState, useEffect } from 'react';
import { Location } from '@raydiant/api-client-js';
import Button from '@raydiant/raydial/components/Button';
import Select, { SelectItem } from '@raydiant/raydial/components/Select';
import miraClient from '../../../../clients/miraClient';
import { useToastState } from '@raydiant/raydial/components/Toast/ToastProvider';
import * as deviceActions from '../../../../actions/devices';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import Link from '@raydiant/raydial/components/Link';
import * as paths from '../../../../routes/paths';
import { useHistory } from 'react-router-dom';

interface MoveScreenModalProps {
  locations: Location[];
  selectedDeviceIds: string[];
  onClose: () => void;
  onSubmit: () => void;
}

const MoveScreenModal = ({
  locations,
  selectedDeviceIds,
  onClose,
  onSubmit,
}: MoveScreenModalProps) => {
  const dispatch = useDispatch();
  const state = useToastState();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [updatedDeviceIds, selectedUpdatedDeviceIds] = useState<string[]>([]);
  const [updatedLocation, setUpdatedLocation] = useState<Location | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null,
  );

  useEffect(() => {
    setUpdatedLocation(null);
    setSelectedLocation(null);
    selectedUpdatedDeviceIds([]);
  }, []);

  const handleSelectLocation = (locationId: string) => {
    const location = locations.find((location) => location.id === locationId);
    setSelectedLocation(location || null);
  };

  const handleMoveDevices = async () => {
    if (!selectedLocation) return;

    setIsLoading(true);
    try {
      await miraClient.moveDevicesToAnotherLocation(
        selectedLocation?.id || '',
        { deviceIds: selectedDeviceIds },
      );
      dispatch(
        deviceActions.moveDevicesToAnotherLocation({
          location: selectedLocation,
          deviceIds: selectedDeviceIds,
        }),
      );
      setUpdatedLocation(selectedLocation);
      selectedUpdatedDeviceIds(selectedDeviceIds);
      onSubmit();
      state.add({
        title: 'Screen Moved',
        description: (
          <div>
            Screen moved to{' '}
            <Link
              className="outline-none underline"
              onClick={() => {
                onClose();
                history.push({
                  pathname: paths.screens(),
                  search: `location_ids=${selectedLocation?.id}`,
                });
              }}
            >
              {selectedLocation?.name}
            </Link>
          </div>
        ),
      });
    } catch (error) {
      state.add({
        title: 'Could not move screens',
        description: 'Please try again',
        variant: 'caution',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const isLocationSame = _.isEqual(selectedLocation, updatedLocation);
  const isDeviceIdsSame = _.isEqual(selectedDeviceIds, updatedDeviceIds);
  const isAlreadyUpdated = isLocationSame && isDeviceIdsSame;
  const disableButton = isLoading || isAlreadyUpdated || !selectedLocation;

  return (
    <div className="px-8 pt-2 pb-8 flex flex-1 flex-col text-black">
      <div className="grid gap-1 mb-4">
        <Text as="h2" variant="subheader">
          Move Screen
        </Text>
        <Text as="h3" className="text-gray-600 font-regular text-lg">
          {selectedDeviceIds.length} Screens selected
        </Text>
      </div>
      <div className="py-3 mb-4">
        <Text variant="body">
          Select desired screens and then choose the destination location
        </Text>
      </div>
      <Scrollable>
        <div>
          <div>
            <div>
              <Select
                label="Location to move to"
                onSelectionChange={(key) => handleSelectLocation(key as string)}
                selectedKey={selectedLocation?.id || ''}
              >
                <SelectItem key="">Select a location</SelectItem>
                {locations.map((location) => (
                  <SelectItem key={location.id} id={location.id}>
                    {location.name}
                  </SelectItem>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </Scrollable>
      <Button
        fullWidth
        loading={isLoading}
        isDisabled={disableButton}
        onClick={() => handleMoveDevices()}
      >
        Move Screens
      </Button>
    </div>
  );
};

export default MoveScreenModal;
