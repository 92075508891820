import Paper from 'raydiant-elements/core/Paper';
import Center from 'raydiant-elements/layout/Center';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import Text from 'raydiant-elements/core/Text';

const DeviceAiSettingsVideoFullScreenPlaceholder = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Paper className="placeholder" color={'light'}>
        <Center>
          <VideocamOutlinedIcon />
        </Center>
      </Paper>
      <Text small className="footerText">
        Minimize fullscreen video feed to view here.
      </Text>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    wrapper: {
      '& .placeholder': {
        padding: theme.spacing(3),
        borderRadius: theme.borderRadius.lg,
        marginBottom: theme.spacing(2),
        '& svg': {
          fontSize: 24,
          color: theme.palette.grey[400],
        },
        '& .footerText': {},
      },
    },
  });
});

export default DeviceAiSettingsVideoFullScreenPlaceholder;
