import Page from '../../components/Page';
import LoadingModal from '@raydiant/raydial/components/LoadingModal';
import { RouteComponentProps } from 'react-router-dom';
import useConnection from '../../hooks/useConnection';
import ProductHubEditToastIntegrationPage from './ProductHubEditToastIntegrationPage';
import ProductHubEditSquareIntegrationPage from './ProductHubEditSquareIntegrationPage';

type RouteParams = {
  integrationId: string;
};

const ProductHubEditIntegrationRouter = (
  props: RouteComponentProps<RouteParams>,
) => {
  const { params } = props.match;
  const { integrationId } = params;
  const connectionQuery = useConnection(integrationId);
  const { data: connection } = connectionQuery;

  if (connectionQuery.isLoading) {
    return (
      <Page>
        <LoadingModal isOpen={true} />
      </Page>
    );
  }

  if (!connection) {
    return null;
  }

  const selectedConnector = connection.connector.name;

  return (
    <>
      {selectedConnector === 'toast' && (
        <ProductHubEditToastIntegrationPage connection={connection} />
      )}
      {selectedConnector === 'square' && (
        <ProductHubEditSquareIntegrationPage connection={connection} />
      )}
    </>
  );
};

export default ProductHubEditIntegrationRouter;
