import {
  TimezoneSelectProps,
  getFormattedTimezoneNames,
} from '../../../../../components/TimeZoneSelect';
import Select from '../../../../../components/Select/Select';
import SelectOption from '../../../../../components/Select/SelectOption';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import DropDownHeader from '../../../../../components/DropDown/DropDownHeader';
import DropDownEmpty from '../../../../../components/DropDown/DropDownEmpty';

type TimezoneProps = Omit<TimezoneSelectProps, 'value'> & {
  value: string[];
};

const TimezoneSelect = ({ className, onChange, value }: TimezoneProps) => {
  const { t } = useTranslation(['devices']);
  const data = getFormattedTimezoneNames();

  const [term, setTerm] = useState('');
  const [open, setOpen] = useState(false);

  const timezones = useMemo(() => {
    if (term === '') return data;

    return data.filter((item) =>
      item.label.toLocaleLowerCase().includes(term.toLocaleLowerCase()),
    );
  }, [data, term]);

  const showEmpty = timezones.length === 0 && term.length > 0;

  return (
    <Select
      className={className}
      disableMobile
      header={
        <DropDownHeader>
          <input
            type="text"
            className="input no-label"
            onChange={(e) => setTerm(e.target.value)}
          />
        </DropDownHeader>
      }
      menuClassname="max-h-[300px] overflow-y-auto"
      onChange={() => {}} //we dont want to trigger on change when user is typing
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      panelClassname="p-0 min-w-min w-full left-0 !overflow-visible"
      wrapperClassName={classNames('no-label')}
      renderAnchorElement={() => <>{t('screens.selectTimezone')}</>}
      popover
    >
      {timezones
        .filter((timezone) => !value.includes(timezone.value))
        .map((item, key) => (
          <SelectOption
            disableMobile
            key={key}
            onClick={() => {
              onChange(item.value);
              setTerm('');
              setOpen(false);
            }}
            value={item.value}
          >
            {item.label}
          </SelectOption>
        ))}
      {showEmpty && <DropDownEmpty />}
    </Select>
  );
};

export default TimezoneSelect;
