import { ContentEngagementByPresentation } from '@raydiant/api-client-js';
import ChartCard from '../ChartCard/ChartCard';
import { useTranslation } from 'react-i18next';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { BarChart, LineChart } from 'echarts/charts';
import {
  CHART_DEFAULT_AXIS_OPTIONS,
  CHART_DEFAULT_OPTIONS,
  CHART_DEFAULT_SERIES,
} from '../utils';
import { renderToStaticMarkup } from 'react-dom/server';
import ChartToolTip, { EChartsToolTipParams } from '../ChartToolTip';
import Hidden from 'raydiant-elements/layout/Hidden';
import { Popover } from '@headlessui/react';
import QuestionMarkIcon from '../../../components/QuestionMarkIcon';
import { transformToLabelText } from '../ShopperEngagementInsights/utils';

echarts.use([BarChart, LineChart]);

type TopPlayedContentChartProps = {
  data: ContentEngagementByPresentation[];
  domainName: string;
  isFullAccess: boolean;
};

const getXAxisData = (data: ContentEngagementByPresentation[]) =>
  data?.map((value) => {
    const text = transformToLabelText(value.presentationName);
    return text.length > 12 ? `${text.slice(0, 12)}...` : text;
  });

const getYAxisData = (
  data: ContentEngagementByPresentation[],
  view: keyof ContentEngagementByPresentation,
) => data?.map((value) => value[view]);

const TopPlayedContentChart = ({
  data,
  domainName,
  isFullAccess,
}: TopPlayedContentChartProps) => {
  const { t } = useTranslation();

  const getSeries = (data: ContentEngagementByPresentation[]) => {
    let series = [];

    series.push({
      ...CHART_DEFAULT_SERIES.bar,
      data: getYAxisData(data, 'playbackDuration')?.map((data) => {
        return Math.round(Number(data) / 60);
      }),
      name: t('analytics.contentEngagementPage.tableHeaders.playbackDuration'),
      stack: 'interactions',
    });

    series.push({
      ...CHART_DEFAULT_SERIES.line,
      data: getYAxisData(data, 'nOfPlays'),
      name: t('analytics.contentEngagementPage.tableHeaders.nOfPlays'),
      lineStyle: {
        color: '#faab91',
        width: 3,
      },
      showSymbol: false,
      yAxisIndex: 1,
    });

    return series;
  };

  const color = ['#08177a', '#faab91'];

  const options = {
    ...CHART_DEFAULT_OPTIONS,
    color,
    legend: {
      ...CHART_DEFAULT_OPTIONS.legend,
      data: [
        {
          name: t(
            'analytics.contentEngagementPage.tableHeaders.playbackDuration',
          ),
          icon: 'square',
        },
        {
          name: t('analytics.contentEngagementPage.tableHeaders.nOfPlays'),
          icon: 'square',
        },
      ],
    },
    xAxis: [
      {
        ...CHART_DEFAULT_AXIS_OPTIONS.xAxis,
        data: getXAxisData(data),
        name: `${domainName} Content`.toUpperCase(),
        axisLabel: {
          ...CHART_DEFAULT_AXIS_OPTIONS.xAxis.axisLabel,
          interval: 0,
        },
      },
    ],
    yAxis: [
      {
        ...CHART_DEFAULT_AXIS_OPTIONS.yAxis,
        alignTicks: true,
        name: t(
          'analytics.contentEngagementPage.tableHeaders.playbackTime',
        ).toUpperCase(),
        axisLabel: {
          ...CHART_DEFAULT_AXIS_OPTIONS.yAxis.axisLabel,
        },
      },
      {
        ...CHART_DEFAULT_AXIS_OPTIONS.yAxis,
        name: t(
          'analytics.contentEngagementPage.tableHeaders.nOfPlays',
        ).toUpperCase(),
        axisLabel: {
          ...CHART_DEFAULT_AXIS_OPTIONS.yAxis.axisLabel,
          margin: 14,
        },
      },
    ],
    series: getSeries(data),
    tooltip: {
      ...CHART_DEFAULT_OPTIONS.tooltip,
      formatter: (params: EChartsToolTipParams[]) => {
        const index = params[0]?.dataIndex;
        const value = data[index];

        const modifiedParams: EChartsToolTipParams[] = params.map((item) => {
          if (
            item.seriesName ===
            t('analytics.contentEngagementPage.tableHeaders.playbackDuration')
          ) {
            return { ...item, value: `${item.value} min` };
          } else {
            return item;
          }
        });

        return renderToStaticMarkup(
          <ChartToolTip
            header={
              <p className="text-sm px-2 py-1 capitalize text-black">
                {value.presentationName}
              </p>
            }
            values={modifiedParams}
            totalInteractions={''}
          />,
        );
      },
    },
  };

  const mobileOptions = {
    ...options,
    xAxis: [
      {
        ...CHART_DEFAULT_AXIS_OPTIONS.xAxis,
        data: getXAxisData(data),
        name: `${domainName} Content`.toUpperCase(),
      },
    ],
    legend: {
      ...CHART_DEFAULT_OPTIONS.legend,
      data: [
        {
          name: t(
            'analytics.contentEngagementPage.tableHeaders.playbackDuration',
          ),
          icon: 'square',
        },
        {
          name: t('analytics.contentEngagementPage.tableHeaders.nOfPlays'),
          icon: 'square',
        },
      ],
      type: 'scroll',
    },
    series: getSeries(data),
  };

  return (
    <ChartCard
      subTitle={''}
      title={
        <div className="flex gap-3 items-center">
          <h2 className="text-black text-xl font-bold">
            {isFullAccess
              ? t(
                  'analytics.contentEngagementPage.topPlayedContentChart.title.fullAccess',
                )
              : t(
                  'analytics.contentEngagementPage.topPlayedContentChart.title.default',
                )}
          </h2>
          <Popover className="relative">
            <Popover.Button>
              <QuestionMarkIcon />
            </Popover.Button>
            <Popover.Panel className="absolute z-10 card popover">
              <div className="overflow-hidden">
                <p className="mb-3">
                  {t(
                    'analytics.contentEngagementPage.topPlayedContentChart.tooltip',
                  )}
                </p>
                <a
                  href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('analytics.learnmore')}
                </a>
              </div>
            </Popover.Panel>
          </Popover>
        </div>
      }
      showTimeInterval={false}
    >
      <div className="min-w-full min-h-full">
        <Hidden xsDown>
          <ReactECharts
            style={{ height: '410px' }}
            option={options}
            onChartReady={(chart) => chart.resize()}
          />
        </Hidden>
        <Hidden smUp>
          <ReactECharts
            style={{ height: '410px' }}
            option={mobileOptions}
            onChartReady={(chart) => chart.resize()}
          />
        </Hidden>
      </div>
    </ChartCard>
  );
};

export default TopPlayedContentChart;
