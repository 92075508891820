import * as React from 'react';
import AppLayout from '../AppLayout/AppLayout';
import ContentModal from '../ContentModal';
interface PageProps {
  title?: string;
  backTo?: string;
  hideNavigation?: boolean;
}

const Page: React.SFC<PageProps> = ({
  title,
  backTo,
  hideNavigation,
  children,
}) => {
  return (
    <>
      <AppLayout hideNavigation={hideNavigation} backTo={backTo}>
        {children}
      </AppLayout>
      <ContentModal />
    </>
  );
};

export default Page;
