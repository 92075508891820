import { useState } from 'react';
import SelectField from 'raydiant-elements/core/SelectField';
import useStyles from './SelectIntegrationScreen.styles';
import * as D from '../../../clients/mira/types/Device';
import Row from 'raydiant-elements/layout/Row';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';

export interface SelectIntegrationScreenProps {
  devices: D.Device[];
}

const SelectIntegrationScreen = ({ devices }: SelectIntegrationScreenProps) => {
  const classes = useStyles();

  const [selectedDeviceIds, setSelectedDeviceIds] = useState<string[]>([]);

  const addSelectedDeviceId = (value: string) => {
    const updatedSelectedDeviceIds = [value, ...selectedDeviceIds];
    setSelectedDeviceIds(updatedSelectedDeviceIds);
  };

  const removeSelectedDeviceId = (value: string) => {
    const updatedSelectedDeviceIds = selectedDeviceIds.filter(
      (deviceId) => deviceId !== value,
    );
    setSelectedDeviceIds(updatedSelectedDeviceIds);
  };

  const selectOptions = devices.filter(
    (device) => !selectedDeviceIds.includes(device.id),
  );
  const selectedDevices = devices.filter((device) =>
    selectedDeviceIds.includes(device.id),
  );

  return (
    <div className={classes.addScreenSelect}>
      <SelectField
        label="Add a Screen to this location"
        value=""
        onChange={(value) => addSelectedDeviceId(value)}
      >
        <option value="">Select a screen</option>
        {selectOptions.map((option) => (
          <option value={option.id}>{option.name}</option>
        ))}
      </SelectField>
      {selectedDevices.map((device) => {
        return (
          <Row
            key={device.id}
            halfMargin
            center
            className={classes.screenInputRow}
          >
            <div className={classes.screenInput}>
              <Row halfMargin center>
                <div>{device.name}</div>
              </Row>
            </div>
            <div>
              <IconButton
                size="small"
                edge="end"
                onClick={() => removeSelectedDeviceId(device.id)}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </div>
          </Row>
        );
      })}
    </div>
  );
};

export default SelectIntegrationScreen;
