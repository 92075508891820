import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

export default makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      padding: theme.spacing(0.7),
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    success: {
      color: theme.palette.text.headingPrimary,
    },
    divider: {
      color: theme.palette.text.headingPrimary,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),

      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    error: {
      color: theme.palette.error.main,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    errorTooltip: {
      width: '140px',
      borderRadius: theme.spacing(1),
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
      backgroundColor: '#fff',
      boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.5);',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    tooltipTitle: {
      fontWeight: 600,
    },
  });
});
