import PaperModal from 'raydiant-elements/core/PaperModal';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import DeviceAiSettingsVideo, {
  DeviceAiSettingsVideoPlayerState,
} from './DeviceAiSettingsVideo';
import DeviceAiSettingsRegionOfInterestModal from './DeviceAiSettingsRegionOfInterestModal';
import { Grid } from '@material-ui/core';
import Center from 'raydiant-elements/layout/Center';

export interface regionOfInterest {
  x?: number;
  y?: number;
  w?: number;
  h?: number;
}

interface DeviceAiSettingsVideoModalProps {
  isOpen: boolean;
  onClose: () => void;
  onPlay: () => void;
  onStop: () => void;
  onChangeRegionOfInterestX: (value: string) => void;
  onChangeRegionOfInterestY: (value: string) => void;
  onChangeRegionOfInterestW: (value: string) => void;
  onChangeRegionOfInterestH: (value: string) => void;
  regionOfInterestData: regionOfInterest;
  url?: string;
  videoErrors?: string[];
  videoHasError: boolean;
  videoPlayerElement: React.MutableRefObject<HTMLVideoElement>;
  videoPlayerState: DeviceAiSettingsVideoPlayerState;
}

const DeviceAiSettingsVideoModal = ({
  isOpen,
  onClose,
  onPlay,
  onStop,
  onChangeRegionOfInterestX,
  onChangeRegionOfInterestY,
  onChangeRegionOfInterestW,
  onChangeRegionOfInterestH,
  regionOfInterestData,
  url,
  videoErrors,
  videoHasError,
  videoPlayerElement,
  videoPlayerState,
}: DeviceAiSettingsVideoModalProps) => {
  const classes = useStyles();

  return (
    <PaperModal
      className={classes.modal}
      color="lightGrey"
      elevation={3}
      open={isOpen}
      onClose={onClose}
      showClose={false}
    >
      <Scrollable>
        <PaperModal.Body>
          <div className="wrapper">
            <DeviceAiSettingsVideo
              errorMessages={videoErrors}
              fullScreen={true}
              hasError={videoHasError}
              onClose={onClose}
              onPlay={onPlay}
              onStop={onStop}
              showMinimize={true}
              url={url}
              videoPlayerElement={videoPlayerElement}
              videoPlayerState={videoPlayerState}
            />
            <p className="footerText">Region of interest</p>
            <Grid container>
              <Center>
                <Grid item xs={8}>
                  <DeviceAiSettingsRegionOfInterestModal
                    onChangeX={onChangeRegionOfInterestX}
                    onChangeY={onChangeRegionOfInterestY}
                    onChangeW={onChangeRegionOfInterestW}
                    onChangeH={onChangeRegionOfInterestH}
                    data={regionOfInterestData}
                  />
                </Grid>
              </Center>
            </Grid>
          </div>
        </PaperModal.Body>
      </Scrollable>
    </PaperModal>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const defaultModalWidth = 414;
  const modalTopOffset = 136;

  return createStyles({
    modal: {
      borderRadius: theme.borderRadius.lg,
      top: modalTopOffset,
      right: theme.spacing(2) + defaultModalWidth,
      bottom: theme.spacing(2),
      width: 724,
      '& .wrapper': {
        '& .footerText': {
          textAlign: 'center',
          marginTop: theme.spacing(1),
        },
      },
    },
  });
});

export default DeviceAiSettingsVideoModal;
