import React from 'react';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import { Grid } from '@material-ui/core';
import cn from 'classnames';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: `${theme.spacing(10)}px ${theme.spacing(8)}px`,
      [theme.breakpoints.down('sm')]: {
        padding: `${theme.spacing(7)}px ${theme.spacing(5)}px`,
      },
    },
  }),
);

interface ContentCardProps {
  className?: string;
  children?: React.ReactNode;
}

const ContentCard = ({ className, children }: ContentCardProps) => {
  const classes = useStyles();
  return (
    <Grid item container className={cn(classes.root, className)}>
      {children}
    </Grid>
  );
};

export default ContentCard;
