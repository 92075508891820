import TextField from 'raydiant-elements/core/TextField/TextField';
import { regionOfInterest } from '../DeviceAiSettingsVideoModal';
import useStyles from '../DeviceAISettings.styles';
import DeviceAiSettingsGroup from '../DeviceAiSettingsGroup';

interface DeviceAiSettingsRegionOfInterestProps {
  onChangeX?: (value: string) => void;
  onChangeY?: (value: string) => void;
  onChangeW?: (value: string) => void;
  onChangeH?: (value: string) => void;
  data: regionOfInterest;
  showX?: boolean;
  showY?: boolean;
  showW?: boolean;
  showH?: boolean;
}

const DeviceAiSettingsRegionOfInterest = ({
  data,
  onChangeX,
  onChangeY,
  onChangeW,
  onChangeH,
  showX = false,
  showY = false,
  showW = false,
  showH = false,
}: DeviceAiSettingsRegionOfInterestProps) => {
  const classes = useStyles();

  return (
    <DeviceAiSettingsGroup title="Region of interest (px)">
      {showX && (
        <li className={classes.aiSetting} key={'roiX'}>
          <TextField
            label={'ROI X (px)'}
            value={data.x?.toString()}
            onChange={onChangeX}
          />
        </li>
      )}
      {showY && (
        <li className={classes.aiSetting} key={'roiY'}>
          <TextField
            label={'ROI Y (px)'}
            value={data.y?.toString()}
            onChange={onChangeY}
          />
        </li>
      )}
      {showW && (
        <li className={classes.aiSetting} key={'roiW'}>
          <TextField
            label={'ROI W (px)'}
            value={data.w?.toString()}
            onChange={onChangeW}
          />
        </li>
      )}
      {showH && (
        <li className={classes.aiSetting} key={'roiH'}>
          <TextField
            label={'ROI H (px)'}
            value={data.h?.toString()}
            onChange={onChangeH}
          />
        </li>
      )}
    </DeviceAiSettingsGroup>
  );
};

export default DeviceAiSettingsRegionOfInterest;
