import { SVGProps } from 'react';

interface EthernetProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const Ethernet = ({ size = 18, ...props }: EthernetProps) => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.42265 1.46051C4.42265 0.893752 4.8894 0.393669 5.4895 0.393669H11.8906C12.4573 0.393669 12.9574 0.893752 12.9574 1.46051V2.52736H14.0242C14.591 2.52736 15.0911 3.02744 15.0911 3.5942V4.66105H16.1579C16.7247 4.66105 17.2248 5.16113 17.2248 5.72789V12.129C17.2248 12.7291 16.7247 13.1958 16.1579 13.1958H13.4908H10.2903H7.08976H3.88923H1.22212C0.622018 13.1958 0.155273 12.7291 0.155273 12.129V5.72789C0.155273 5.16113 0.622018 4.66105 1.22212 4.66105H2.28896V3.5942C2.28896 3.02744 2.75571 2.52736 3.35581 2.52736H4.42265V1.46051ZM6.02292 1.99394V3.32749C6.02292 3.79423 5.65619 4.12762 5.22278 4.12762H3.88923V5.46118C3.88923 5.92792 3.5225 6.26131 3.0891 6.26131H1.75554V11.5955H3.0891V9.72856C3.0891 9.29515 3.42248 8.92842 3.88923 8.92842C4.32263 8.92842 4.68936 9.29515 4.68936 9.72856V11.5955H6.28963V9.72856C6.28963 9.29515 6.62302 8.92842 7.08976 8.92842C7.52317 8.92842 7.88989 9.29515 7.88989 9.72856V11.5955H9.49016V9.72856C9.49016 9.29515 9.82355 8.92842 10.2903 8.92842C10.7237 8.92842 11.0904 9.29515 11.0904 9.72856V11.5955H12.6907V9.72856C12.6907 9.29515 13.0241 8.92842 13.4908 8.92842C13.9242 8.92842 14.291 9.29515 14.291 9.72856V11.5955H15.6245V6.26131H14.291C13.8242 6.26131 13.4908 5.92792 13.4908 5.46118V4.12762H12.1573C11.6905 4.12762 11.3571 3.79423 11.3571 3.32749V1.99394H6.02292Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Ethernet;
