import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

export default makeStyles((theme: Theme) => {
  return createStyles({
    addScreenSelect: {
      '& select': {
        backgroundColor: `${theme.palette.background.inset} !important`,
      },
    },
    screenInputRow: {
      marginTop: theme.spacing(1.5),
    },
    screenInput: {
      border: `2px solid rgba(0, 0, 0, 0.38)`,
      borderRadius: theme.borderRadius.sm,
      boxShadow: theme.shadows[1],
      fontSize: theme.fontSizes.sm,
      height: 40,
      width: '100%',
      padding: theme.spacing(0, 1),
      paddingRight: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.background.default,
    },
  });
});
