import OutstandingInvoiceBanner from '../OutstandingInvoiceBanner';
import PendingLocationsBanner from '../../pages/LocationsPage/components/PendingLocationsBanner';
import {
  selectIsEnterpriseUser,
  selectUserProfile,
} from '../../selectors/user';
import { useSelector } from 'react-redux';
import { hasRoleOrHigher } from '../../utilities';

const BannerRouter = () => {
  const currentUser = useSelector(selectUserProfile);
  const isEnterpriseUser = useSelector(selectIsEnterpriseUser);

  if (!currentUser) return null;

  const isUserDomainAdminOrHigher = hasRoleOrHigher('admin', currentUser);
  const isSMBUser = !isEnterpriseUser;
  const showPendingLocationsBanner =
    currentUser.isLocationEnabled && (isSMBUser || isUserDomainAdminOrHigher);

  return (
    <>
      <OutstandingInvoiceBanner />
      {showPendingLocationsBanner && <PendingLocationsBanner />}
    </>
  );
};

export default BannerRouter;
