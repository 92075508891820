import { useState } from 'react';
import { Location } from '@raydiant/api-client-js';
import Button from '@raydiant/raydial/components/Button';
import LocationsTable from './LocationsTable';
import SearchBar from '../SearchBar';
import Text from '@raydiant/raydial/components/Text';

interface SelectLocationStepProps {
  selectedLocation: Location | null;
  onTableItemClick: (location: Location) => void;
  onAddLocationClick?: () => void;
  onClick: () => void;
  onCancel: () => void;
}

const SelectLocationStep = ({
  selectedLocation,
  onTableItemClick,
  onAddLocationClick,
  onClick,
  onCancel,
}: SelectLocationStepProps) => {
  const [search, setSearch] = useState('');

  const handleSearchClick = (value: string) => {
    setSearch(value);
  };

  const renderAddLocationButton = () => {
    return (
      <div>
        <button
          onClick={onAddLocationClick ? () => onAddLocationClick() : () => {}}
        >
          <Text className="text-base ml-4 text-blue-600">
            Add a new location
          </Text>
        </button>
      </div>
    );
  };

  const renderButtonGroup = () => {
    return (
      <div className="flex flex-col-reverse justify-between mt-8 mb-4 gap-3 px-0 md:gap-6 md:flex-row md:px-4">
        <Button
          className="grow"
          size="large"
          variant="secondary"
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
        <Button
          className="grow"
          size="large"
          isDisabled={!selectedLocation}
          onClick={() => onClick()}
        >
          Next
        </Button>
      </div>
    );
  };

  return (
    <>
      <SearchBar onClick={handleSearchClick} />
      <LocationsTable
        search={search}
        selectedLocation={selectedLocation}
        onRowClick={onTableItemClick}
      />
      {renderAddLocationButton()}
      {renderButtonGroup()}
    </>
  );
};

export default SelectLocationStep;
