import MarketplaceModal from './MarketplaceModal';

interface ManualSetUpDialogProps {
  open: boolean;
  onClick: () => void;
}

const ManualSetUpModal = ({ open, onClick }: ManualSetUpDialogProps) => {
  return (
    <MarketplaceModal
      heading="Just one more thing"
      content="Thank your for your purchase. Please be aware that the app subscription you’ve selected requires a 3rd party account. Our support team will set one up for you. This process may take up to 72 hours."
      open={open}
      handleClick={onClick}
    />
  );
};

export default ManualSetUpModal;
