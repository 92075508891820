import { FC, useCallback, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Presentation, Folder, VirtualFolder } from '@raydiant/api-client-js';
import * as presentationActions from '../../actions/presentations';
import { getDuplicateResourceName } from '../../utilities';
import { selectUserProfile } from '../../selectors/user';
import LibraryResourceCard from '../../components/LibraryResourceCard';
import PresentationIcon from '../../components/PresentationIcon';
import useDuplicateSnackbar from '../../hooks/useDuplicateSnackbar';
import useStyles from './PresentationCard.styles';
import PresentationCardOutline from './PresentationCardOutline';
import PresentationCardThumbnail from './PresentationCardThumbnail';

interface PresentationCardProps {
  presentation: Presentation;
  mode?: 'select' | 'edit' | 'manage';
  folder: Folder | VirtualFolder;
  isSelected?: boolean;
  isLoading?: boolean;
  isLocked?: boolean;
  hasError?: boolean;
  showSelect?: boolean;
  onSelect: () => void;
  onEdit: () => void;
}

const PresentationCard: FC<PresentationCardProps> = (
  {
    presentation,
    folder,
    mode = 'edit',
    isSelected = false,
    isLoading = false,
    isLocked = false,
    hasError = false,
    showSelect,
    onSelect,
    onEdit,
  },
  ref,
) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueDuplicateSnackbar } = useDuplicateSnackbar();

  // Selectors

  const currentUser = useSelector(selectUserProfile);

  // Callbacks

  const copyPlaylist = useCallback(() => {
    const isDuplicatingAnotherUsersResource =
      presentation.resource.profile.id !== currentUser?.id;

    dispatch(
      presentationActions.copyPresentation(
        {
          presentationId: presentation.id,
          copyName: getDuplicateResourceName(
            presentation.name,
            folder.presentations,
          ),
        },
        {
          onSuccess: () =>
            enqueueDuplicateSnackbar(isDuplicatingAnotherUsersResource),
        },
      ),
    );
  }, [presentation, folder, dispatch, enqueueDuplicateSnackbar, currentUser]);

  const deletePresentation = useCallback(() => {
    dispatch(presentationActions.deleteAllPresentations([presentation.id], {}));
  }, [presentation, dispatch]);

  // Render

  return (
    <LibraryResourceCard
      ref={ref}
      mode={mode}
      name={presentation.name}
      icon={<PresentationIcon presentation={presentation} />}
      resource={presentation.resource}
      isSelected={isSelected}
      isLoading={isLoading}
      isLocked={isLocked}
      hasError={hasError}
      showSelect={showSelect}
      onSelect={onSelect}
      onEdit={onEdit}
      onCopy={copyPlaylist}
      onDelete={deletePresentation}
    >
      {({ isDark }) => (
        <>
          <PresentationCardThumbnail
            presentation={presentation}
            isDark={isDark}
          />

          {isSelected && (
            <PresentationCardOutline className={classes.outline} />
          )}
        </>
      )}
    </LibraryResourceCard>
  );
};

// @ts-ignore
export default forwardRef(PresentationCard);
