import DeviceAiSettingsGroup from '../DeviceAiSettingsGroup';
import useStyles from '../DeviceAISettings.styles';
import Switch from 'raydiant-elements/core/Switch';
import TextField from 'raydiant-elements/core/TextField/TextField';

interface DeviceAiSettingsCurrentStatsData {
  enabled?: boolean;
  duration?: number;
}

interface DeviceAiSettingsCurrentStatsProps {
  data: DeviceAiSettingsCurrentStatsData;
  onChangeDuration?: (value: string) => void;
  onChangeEnabled?: (value: boolean) => void;
  showDuration?: boolean;
}

const DeviceAiSettingsCurrentStats = ({
  data,
  onChangeDuration,
  onChangeEnabled,
  showDuration = false,
}: DeviceAiSettingsCurrentStatsProps) => {
  const classes = useStyles();

  return (
    <DeviceAiSettingsGroup title="Current Stats Settings">
      <li className={classes.aiSetting} key={'enabled'}>
        <Switch
          checked={data.enabled || false}
          onChange={onChangeEnabled}
          label={'Enabled'}
        />
      </li>
      {showDuration && (
        <li className={classes.aiSetting} key={'duration'}>
          <TextField
            disabled={!data.enabled}
            label={'Duration'}
            value={data.duration?.toString()}
            onChange={onChangeDuration}
          />
        </li>
      )}
    </DeviceAiSettingsGroup>
  );
};

export default DeviceAiSettingsCurrentStats;
