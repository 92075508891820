import { FC, useState, useCallback } from 'react';
import { Device, Tag } from '@raydiant/api-client-js';
import Column from 'raydiant-elements/layout/Column';
import { tagData, TagKey } from '../../../components/TagManager/tagManagerData';
import Heading from 'raydiant-elements/core/Heading';
import { useDispatch, useSelector } from 'react-redux';
import { selectBatchDeviceSettings } from '../selectors';
import { BatchDevice, BatchTagKey } from '../deviceBatchSettings.interface';
import * as devicePageActions from '../actions';
import {
  isDayValue,
  mapTypes,
  defaultTagValue,
} from '../deviceBatchSettingsUtils';
import DeviceBatchTagManager from './DeviceBatchTagManager';

export interface TagManagerProps {
  devices: Device[];
  hideSchedulingTags?: boolean;
}

const DeviceBatchTags: FC<TagManagerProps> = ({
  devices,
  hideSchedulingTags = true,
}) => {
  const dispatch = useDispatch();

  // Selectors

  const deviceBatchSettings = useSelector(selectBatchDeviceSettings);
  const { addTags, removeKeyTags, removeValueTags, overwriteTags } =
    deviceBatchSettings;

  // State
  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  const [selectedAction, setSelectedAction] = useState<BatchTagKey | null>(
    null,
  );
  const [open, setOpen] = useState(false);

  // Callbacks

  const updateDevice = useCallback(
    (settings: Partial<BatchDevice>) => {
      dispatch(devicePageActions.updateBatchDeviceSettings(settings));
    },
    [dispatch],
  );

  const handleDelete = (key: BatchTagKey, tag: Tag) => {
    const updatedSettings = { ...deviceBatchSettings };
    const updatedTags = deviceBatchSettings[key].filter(
      (t) => t.key !== tag.key,
    );
    updatedSettings[key] = updatedTags;
    updateDevice(updatedSettings);
  };

  const handleEdit = (key: BatchTagKey, tag: Tag, value: Tag['value']) => {
    const updatedSettings = { ...deviceBatchSettings };

    const updatedTags = deviceBatchSettings[key].map((t) => {
      if (t.key === tag.key) {
        if (isDayValue(tag.type)) {
          return { ...t, value: [...t.value, ...value] as any };
        } else {
          return { ...t, value };
        }
      }
      return t;
    });
    updatedSettings[key] = updatedTags;
    updateDevice(updatedSettings);
  };

  const handleClearTags = (key: TagKey) => {
    const updatedTags: Tag[] = [
      ...removeKeyTags,
      {
        key,
        label: tagData[key].label,
        value: defaultTagValue(key),
        type: mapTypes(key) as any,
        id: '',
        resourceId: '',
        createdAt: '',
      },
    ];
    updateDevice({ removeKeyTags: updatedTags });
    setSelectedKey(key);
  };

  const handleRemoveTags = (key: TagKey) => {
    const updatedTags: Tag[] = [
      ...removeValueTags,
      {
        key,
        label: tagData[key].label,
        value: defaultTagValue(key),
        type: mapTypes(key) as any,
        id: '',
        resourceId: '',
        createdAt: '',
      },
    ];
    updateDevice({ removeValueTags: updatedTags });
    setSelectedKey(key);
  };

  const handleOverwriteTags = (key: TagKey) => {
    const updatedTags: Tag[] = [
      ...overwriteTags,
      {
        key,
        label: tagData[key].label,
        value: defaultTagValue(key),
        type: mapTypes(key) as any,
        id: '',
        resourceId: '',
        createdAt: '',
      },
    ];
    updateDevice({ overwriteTags: updatedTags });
    setSelectedKey(key);
  };

  const handleAddTags = (key: TagKey) => {
    const updatedTags: Tag[] = [
      ...addTags,
      {
        key,
        label: tagData[key].label,
        value: defaultTagValue(key),
        type: mapTypes(key) as any,
        // NOTE: These values are populated by the API and technically not required but
        // here to appease TS. There's probably a better way to structure the types.
        id: '',
        resourceId: '',
        createdAt: '',
      },
    ];
    updateDevice({ addTags: updatedTags });
    setSelectedKey(key);
  };

  const handleToggleMenu = (action: BatchTagKey) => {
    if (selectedAction !== action) {
      setSelectedAction(action);
    }
    setOpen(open ? false : true);
  };

  const handleSelectedKey = (
    action: BatchTagKey | null,
    tagKey: string | null,
  ) => {
    if (action !== selectedAction) {
      setSelectedAction(action);
    }
    setSelectedKey(tagKey);
  };

  // Side-effects

  // Render
  return (
    <Column>
      <Heading size={5} overline gutterBottom>
        Tags
      </Heading>

      <DeviceBatchTagManager
        tags={removeKeyTags}
        action="removeKeyTags"
        selectedKey={selectedKey}
        selectedAction={selectedAction}
        open={selectedAction === 'removeKeyTags' && open}
        hideSchedulingTags={hideSchedulingTags}
        labelOnly={true}
        onMenuItemClick={handleClearTags}
        onToggle={handleToggleMenu}
        onDelete={handleDelete}
      />

      <DeviceBatchTagManager
        tags={removeValueTags}
        action="removeValueTags"
        selectedKey={selectedKey}
        selectedAction={selectedAction}
        open={selectedAction === 'removeValueTags' && open}
        hideSchedulingTags={hideSchedulingTags}
        onEdit={handleSelectedKey}
        onMenuItemClick={handleRemoveTags}
        onToggle={handleToggleMenu}
        onChange={handleEdit}
        onDelete={handleDelete}
      />

      <DeviceBatchTagManager
        tags={overwriteTags}
        action="overwriteTags"
        selectedKey={selectedKey}
        selectedAction={selectedAction}
        open={selectedAction === 'overwriteTags' && open}
        hideSchedulingTags={hideSchedulingTags}
        onEdit={handleSelectedKey}
        onMenuItemClick={handleOverwriteTags}
        onToggle={handleToggleMenu}
        onChange={handleEdit}
        onDelete={handleDelete}
      />

      <DeviceBatchTagManager
        tags={addTags}
        action="addTags"
        selectedKey={selectedKey}
        selectedAction={selectedAction}
        open={selectedAction === 'addTags' && open}
        hideSchedulingTags={hideSchedulingTags}
        onEdit={handleSelectedKey}
        onMenuItemClick={handleAddTags}
        onToggle={handleToggleMenu}
        onChange={handleEdit}
        onDelete={handleDelete}
      />
    </Column>
  );
};

export default DeviceBatchTags;
