import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router';
import { useEffect, useState } from 'react';
import DateRangeSelect, {
  CustomChangeHandlerEventData,
  DATE_RANGE_MAP,
  DateRangeSelectValue,
} from './DateRangeSelect';
import { AnalyticsQueryParams } from '@raydiant/api-client-js';
import { getDefaultQueryParams, getUrlQueryParams } from './utils';
import LocationSelect from './LocationSelect';
import deepEqual from 'fast-deep-equal';

const ANALYTICS_QUERY_KEYS = [
  'location_id',
  'from',
  'to',
  'dateRangeKey',
  'presentation_id',
] as const;

type AnalyticsFilterProps = JSX.IntrinsicElements['div'];
export type AnalyticsFilterQueryKeys = Partial<
  typeof ANALYTICS_QUERY_KEYS[number]
>;

export type AnalyticsFilterQuery = Partial<AnalyticsQueryParams> & {
  dateRangeKey?: DateRangeSelectValue;
} & { presentation_id?: string };

const AnalyticsFilter = ({ className }: AnalyticsFilterProps) => {
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const history = useHistory();
  const [filter, setFilter] = useState<AnalyticsFilterQuery>({});

  useEffect(() => {
    if (!search) {
      setFilter(getDefaultQueryParams());
    }

    if (search && Object.keys(search).length > 0) {
      setFilter(getUrlQueryParams(search));
    }
  }, [search]);

  const isDisabled = !search && deepEqual(filter, getDefaultQueryParams());

  const onApplyFilter = () => {
    const searchParams = new URLSearchParams(filter);
    history.push({
      pathname,
      search: `?${searchParams}`,
    });
  };

  const onClearFilter = () => {
    history.push({
      pathname,
    });
    setFilter(getDefaultQueryParams());
  };

  const handleLocationChange = (value: string) => {
    setFilter({
      ...filter,
      location_id: value,
    });
  };

  const handleDateRangeChange = (value: DateRangeSelectValue) => {
    if (value === t('analytics.custom')) {
      setFilter({
        ...filter,
        dateRangeKey: value,
      });

      return;
    }

    const { from, to } = DATE_RANGE_MAP[value];

    setFilter({
      ...filter,
      dateRangeKey: value,
      from,
      to,
    });
  };

  const handleCustomDateRangeChange = ({
    from,
    to,
  }: CustomChangeHandlerEventData) => {
    setFilter({
      ...filter,
      dateRangeKey: t('analytics.custom') as DateRangeSelectValue,
      from,
      to,
    });
  };

  const hasQueryParams = [!!filter.location_id].includes(true);

  const [showFilter, setShowFilter] = useState(hasQueryParams);

  const renderFilterToggle = () => (
    <button
      className={classNames(
        'btn mini !text-gray-400',
        showFilter ? 'open' : '',
      )}
      onClick={() => setShowFilter(!showFilter)}
    >
      <div className="wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
          <path d="M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z" />
        </svg>
      </div>
    </button>
  );

  return (
    <div className={classNames('card p-6', className)}>
      <div className="flex justify-between">
        <div className={classNames('flex items-center gap-3')}>
          <h2 className="text-xl font-semibold text-black">
            {t('analytics.filter')}
          </h2>
          {renderFilterToggle()}
        </div>
        {!showFilter && (
          <button
            onClick={() => onClearFilter()}
            disabled={isDisabled}
            className="btn"
          >
            {t('analytics.clearFilter')}
          </button>
        )}
      </div>
      {showFilter && (
        <div className="flex flex-col xl:flex-row gap-6 xl:gap-3 xl:justify-between mt-3 divide-y xl:divide-x xl:divide-y-0">
          <div className="flex flex-col xl:flex-row items-center gap-3 w-full">
            <LocationSelect
              className="no-label min-w-[220px] w-full"
              wrapperClassName="w-full xl:w-auto"
              onChange={(value) => handleLocationChange(value as string)}
              value={filter.location_id ?? ''}
            />
            <DateRangeSelect
              className="no-label min-w-[220px] w-full"
              dateRangeValue={{
                from: filter.from!,
                to: filter.to!,
              }}
              value={filter.dateRangeKey!}
              onChange={(value) => {
                handleDateRangeChange(value as DateRangeSelectValue);
              }}
              onChangeCustom={handleCustomDateRangeChange}
              wrapperClassName="w-full xl:w-auto"
            />
          </div>
          <div className="flex justify-center xl:justify-start sm:justify-end items-center gap-6 xl:gap-3 pt-6 xl:pt-0 xl:pl-6 min-w-max">
            <button
              onClick={() => onClearFilter()}
              disabled={isDisabled}
              className="btn sm:mini"
            >
              {t('analytics.clearFilter')}
            </button>
            <button
              onClick={() => onApplyFilter()}
              disabled={isDisabled}
              className="btn btn-primary sm:mini"
            >
              {t('analytics.applyFilter')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AnalyticsFilter;
