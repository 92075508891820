import { Location } from '@raydiant/api-client-js';
import { useState, useEffect } from 'react';
import SelectLocationStep from './SelectLocationStep';
import Modal from '@raydiant/raydial/components/Modal';
import Dialog, {
  DialogHeader,
  DialogHeading,
  DialogTrigger,
} from '@raydiant/raydial/components/Dialog';
import ProgressStepper, {
  ProgressStep,
} from '@raydiant/raydial/components/ProgressStepper';
import RegisterDevice from './RegisterDevice';

interface AddScreenDialogProps {
  location?: Location;
  open: boolean;
  onAddLocationClick?: () => void;
  onClose: () => void;
  onRegister: () => void;
}

const renderStepperVariant = (stepNumber: number, currentStep: number) => {
  if (currentStep > stepNumber) return 'complete';
  if (currentStep === stepNumber) return 'selected';
  return undefined;
};

const AddScreenDialog = ({
  location,
  open,
  onAddLocationClick,
  onClose,
  onRegister,
}: AddScreenDialogProps) => {
  const [step, setStep] = useState(1);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null,
  );

  useEffect(() => {
    if (location) {
      setStep(2);
    } else {
      setStep(1);
    }
  }, [location]);

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handleSelectLocation = (location: Location) => {
    setSelectedLocation(location);
  };

  const renderDialogHeading = () => {
    return (
      <span className="text-black">
        {step === 1 && 'Which location is this screen located at?'}
        {step === 2 && `Add a screen to ${location?.name ?? 'this location'}`}
      </span>
    );
  };

  const renderProgressStepper = () => {
    return (
      <div className="flex justify-center my-4">
        <ProgressStepper className="w-[256px]">
          <ProgressStep step={1} variant={renderStepperVariant(1, step)}>
            Select Location
          </ProgressStep>
          <ProgressStep step={2} variant={renderStepperVariant(2, step)}>
            Add Screen
          </ProgressStep>
        </ProgressStepper>
      </div>
    );
  };

  const renderSelectLocationStep = () => (
    <SelectLocationStep
      selectedLocation={selectedLocation}
      onTableItemClick={handleSelectLocation}
      onAddLocationClick={onAddLocationClick}
      onClick={handleNextStep}
      onCancel={onClose}
    />
  );

  const renderAddScreenStep = () => {
    const isLocationGiven = location ?? selectedLocation;
    if (!isLocationGiven) return null;

    return (
      <RegisterDevice
        location={location ?? selectedLocation}
        onCancel={location ? onClose : handlePreviousStep}
        cancelButtonText={location ? 'Cancel' : 'Back'}
        onRegister={onRegister}
      />
    );
  };

  return (
    <DialogTrigger isOpen={open}>
      <Modal className="overflow-auto">
        <Dialog showCloseButton={true} onClose={onClose}>
          <DialogHeader>
            <DialogHeading>{renderDialogHeading()}</DialogHeading>
          </DialogHeader>
          {renderProgressStepper()}
          {step === 1 && renderSelectLocationStep()}
          {step === 2 && renderAddScreenStep()}
        </Dialog>
      </Modal>
    </DialogTrigger>
  );
};

export default AddScreenDialog;
