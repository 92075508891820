import { ApiClientError, Profile } from '@raydiant/api-client-js';
import { useQueryClient } from 'react-query';
import raydiant from '../clients/raydiant';
import { keys } from '../queryClient';
import useProtectedMutation from './useProtectedMutation';

export default function useUpdateAnalytics() {
  const queryClient = useQueryClient();

  const mutation = useProtectedMutation<
    Profile,
    ApiClientError,
    { profile: Profile }
  >(({ profile }) => raydiant.updateProfile(profile), {
    onSuccess: () => {
      queryClient.invalidateQueries(keys.profile());
    },
  });

  return mutation;
}
