import { useState, useEffect } from 'react';
import cn from 'classnames';
import Hidden from 'raydiant-elements/layout/Hidden';

export interface Content {
  label: string;
  href?: string;
  backgroundImage?: string;
}

interface CallToActionCarouselProps {
  header: string;
  content: Content[];
  backgroundColor: string;
  desktopBackgroundImage: string;
  mobileBackgroundImage: string;
  type?: 'content-engagement' | 'vi' | 'product-engagement';
  href: string;
}

const CallToActionCarousel = ({
  header,
  content,
  backgroundColor,
  desktopBackgroundImage,
  mobileBackgroundImage,
  type,
  href,
}: CallToActionCarouselProps) => {
  const [active, setActive] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setActive((active + 1) % content.length);
    }, 5000);
    return () => clearTimeout(timer);
  }, [active, content.length]);

  const renderDesktopContent = () => {
    return (
      <div
        className={cn('rounded-3xl vi-card vi-only w-full')}
        style={{ backgroundImage: `${backgroundColor}` }}
      >
        <div
          className={cn('h-full justify-between')}
          style={{
            backgroundImage: `url(${desktopBackgroundImage}), ${backgroundColor}`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right',
          }}
        >
          <div className="flex flex-col justify-between h-full">
            <div className={cn('vi-card-header', type)}>{header}</div>
            <ol className="pt-4 pb-6">
              {content.map((item, index) => {
                return (
                  <li
                    key={item.label}
                    className={cn(
                      'vi-card-item vi-only',
                      index === active ? 'active' : '',
                      type,
                    )}
                  >
                    {`${index + 1}. ${item.label}`}
                  </li>
                );
              })}
            </ol>
            <div className="vi-button">
              <a
                className={cn('vi-button', type && type)}
                href={href}
                target="_blank"
                rel="noreferrer"
              >
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderMobileContent = () => {
    return (
      <div
        className={cn('rounded-3xl vi-card w-full')}
        style={{
          backgroundImage: `url(${mobileBackgroundImage}), ${backgroundColor}`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right bottom',
        }}
      >
        <div className="flex flex-col justify-between h-full">
          <div className="vi-card-header mobile">{header}</div>
          <div className="vi-button mobile">
            <a
              className={cn('vi-button', type && type)}
              href={href}
              target="_blank"
              rel="noreferrer"
            >
              Learn More
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Hidden xsDown>{renderDesktopContent()}</Hidden>
      <Hidden smUp>{renderMobileContent()}</Hidden>
    </>
  );
};

export default CallToActionCarousel;
