import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

export default makeStyles((theme: Theme) => {
  const modalWidth = 414;
  const modalTopOffset = 135;

  return createStyles({
    actions: {
      marginBottom: theme.spacing(1),

      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },

    deviceList: {
      paddingBottom: theme.spacing(2),

      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },

    section: {
      padding: theme.spacing(0.5),
    },

    title: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1),
      paddingLeft: theme.spacing(4),

      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },

    modal: {
      top: modalTopOffset,
      right: theme.spacing(2),
      bottom: theme.spacing(2),
      width: modalWidth,
    },

    selectAll: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
    },

    checkbox: {
      marginRight: theme.spacing(1),
    },

    batchSettingsModal: {
      [theme.breakpoints.down('xs')]: {
        height: '55% !important',
        top: `calc(45% - ${theme.spacing(0.5)}px) !important`,
      },
    },
  });
});
