import { Grid } from '@material-ui/core';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import Heading from 'raydiant-elements/core/Heading';
import Text from 'raydiant-elements/core/Text';

import ContentCard from './components/ContentCard';
import ColorCard from './components/ColorCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      paddingBottom: theme.spacing(2),
      color: '#000000',
      fontSize: '26px',
      fontWeight: 600,
    },
    mainTitle: {
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
      color: '#0047D4',
      textAlign: 'center',
      fontSize: '26px',
      fontWeight: 500,
    },
    whiteText: {
      color: '#ffffff',
    },
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      overflow: 'hidden',
      marginBottom: '70px',
    },
    label: {
      textAlign: 'center',
      padding: `${theme.spacing(1)}px ${theme.spacing(7)}px`,
      color: '#0047D4',
      border: '1px solid  #0047D4',
      borderRadius: '4px',
      marginBottom: theme.spacing(3),
    },
  }),
);

const TikTokModalContent = () => {
  const classes = useStyles();

  return (
    <Grid>
      <Heading className={classes.mainTitle}>
        The Future of Brick-and-Mortar
        <br />
        Content Experiences
      </Heading>
      <div className="flex justify-center w-full">
        <div className={classes.label}>Active</div>
      </div>
      <ColorCard type="ai">
        <ContentCard>
          <Heading size={2} weight={500} className={classes.whiteText}>
            With the new TikTok channel, businesses can seize a powerful
            advertising opportunity and entertain patrons. TikTok's engaging
            content will now grace screens in retail stores, restaurants,
            hotels, banks, and more. Immerse yourself in endless entertainment
            and unique advertising experiences!
          </Heading>
        </ContentCard>
      </ColorCard>
      <ContentCard>
        <Heading className={classes.heading}>Curated Content</Heading>
        <Text>
          TikTok offers a diverse range of engaging and entertaining clips,
          carefully selected to be workplace-appropriate. It caters to various
          industries and professional settings, ensuring a safe and enjoyable
          experience.
        </Text>
      </ContentCard>
      <div style={{ padding: '0 64px' }}>
        <Heading className={classes.heading}>
          Replace Traditional T.V. with Engaging Videos
        </Heading>
        <Text>
          Raydiant customers can enjoy free, family-friendly, and brand-friendly
          content across various categories like Entertainment, Fitness, Pets,
          and Fails. There is no need for costly cable or streaming services.
        </Text>
      </div>
      <ContentCard>
        <Heading className={classes.heading}>
          A Variety of Entertainment for Any Location
        </Heading>
        <Text>
          Businesses can utilize curated TikTok videos in various formats
          (landscape and portrait) to suit any environment, including gyms,
          bars, banks, waiting rooms, restaurants, and more.
        </Text>
      </ContentCard>
      <img
        src="https://assets.raydiant.com/marketplace/tiktok-tile-footer-image.png"
        alt="Tiktok and Raydiant"
        className={classes.img}
      />
    </Grid>
  );
};

export default TikTokModalContent;
