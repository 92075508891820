import { useTranslation } from 'react-i18next';
import averageInteractionsPerday from '../../../assets/average-interactions-per-day.svg';
import averageSessionsPerday from '../../../assets/average-sessions-per-day.svg';
import TrendArrow from './TrendArrow';
import { getDisplayValue } from './utils';
import DataCard, { DataCardDisplayProps } from './DataCard';

type AveragePerDayProps = DataCardDisplayProps;

const AveragePerDayCard = ({
  change,
  value,
  type,
  showEmpty = false,
  noPreviousData = false,
  isRXEnabled = false,
}: AveragePerDayProps) => {
  const { t } = useTranslation();
  const isSessions = type === 'sessions';
  const interactionsLabel = isRXEnabled
    ? 'analytics.averageInteractionsPerDay'
    : 'analytics.averageScreenClicksPerDay';
  const label = isSessions
    ? 'analytics.averageEngagedShoppersPerDay'
    : interactionsLabel;

  return (
    <DataCard type={type}>
      <img
        className="image"
        src={isSessions ? averageSessionsPerday : averageInteractionsPerday}
        alt={t(label)!}
      />
      <div className="w-full flex text">
        <div className="ml-6 max-w-[160px]">
          <p className="display-value">
            {showEmpty ? '— —' : getDisplayValue(value)}
          </p>
          <p className="text-sm font-semibold">{t(label)}</p>
          {showEmpty && <p className="text-xs">{t('analytics.noValidData')}</p>}
          {!showEmpty && (
            <>
              {noPreviousData ? (
                <p className="text-xs">
                  {t('analytics.trafficAwarenessPage.overview.noPrevious')}
                </p>
              ) : (
                <TrendArrow
                  change={change}
                  label={t('analytics.fromThePreviousPeriod')}
                />
              )}
            </>
          )}
        </div>
      </div>
    </DataCard>
  );
};

export default AveragePerDayCard;
