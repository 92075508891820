import { AnalyticsQueryParams, ApiClientError } from '@raydiant/api-client-js';

import { keys } from '../queryClient';
import raydiant from '../clients/miraClient';
import useProtectedQuery from './useProtectedQuery';
import { ExportAnalyticsEnum } from '../types/analytics';

export default function useAnalyticsExport(
  params: AnalyticsQueryParams,
  exportType: string | undefined,
) {
  let key: (string | AnalyticsQueryParams)[] = [];
  let fn: () => string | Promise<string> = () => '';

  switch (exportType) {
    case ExportAnalyticsEnum.TrafficAwareness:
      key = keys.trafficAwarenessExport(params);
      fn = () => raydiant.getTrafficAwarenessExport(params);
      break;
    case ExportAnalyticsEnum.ContentTopClicks:
      key = keys.contentTopClicksEngagementExport(params);
      fn = () => raydiant.getContentEngagementClickExport(params);
      break;
    case ExportAnalyticsEnum.ProductEngagement:
      key = keys.analyticsExport(params);
      fn = () => raydiant.getProductEngagementExport(params);
      break;
    default:
      key = keys.analyticsExport(params);
      fn = () => raydiant.getProductEngagementExport(params);
  }

  return useProtectedQuery<string, ApiClientError>(key, fn, {
    enabled: !!exportType,
  });
}
