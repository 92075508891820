import { PieChart } from 'echarts/charts';
import { ContentEngagementByPresentation } from '@raydiant/api-client-js';
import ChartCard from '../ChartCard/ChartCard';
import { useTranslation } from 'react-i18next';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import Hidden from 'raydiant-elements/layout/Hidden';
import { CHART_DEFAULT_OPTIONS } from '../utils';

echarts.use([PieChart]);

interface PlaybackTimeDistributionChartProps {
  data: ContentEngagementByPresentation[];
  totalDistributionTime: string;
}

const makeShort = (value: string) => {
  if (value.length > 16) {
    return value.slice(0, 16) + '...';
  } else {
    return value;
  }
};

const showLastItemToOthers = (data: ContentEngagementByPresentation[]) => {
  if (data.length > 10) {
    const topNineItems = data.slice(0, 9);
    const others = {
      presentationId: '',
      presentationName: 'Others',
      nOfPlays: 0,
      avgDuration: 0,
      playbackDuration: 0,
      nOfLocations: 0,
      lastPlayedTime: 0,
      playbackDistribution:
        100 -
        topNineItems
          .map((item) => item.playbackDistribution)
          .reduce((a, b) => a + b, 0),
    };

    return [...topNineItems, others];
  } else {
    return data;
  }
};

const calculateTopMargin = (data: ContentEngagementByPresentation[]) => {
  let defaultMargin = 3;
  if (data.length < 10) {
    defaultMargin += (10 - data.length) * 4;
  }
  return defaultMargin + '%';
};

const PlaybackTimeDistributionChart = ({
  data,
  totalDistributionTime,
}: PlaybackTimeDistributionChartProps) => {
  const { t } = useTranslation();
  const chartData = showLastItemToOthers(data).map((presentation) => ({
    name: presentation.presentationName,
    value: Math.round(presentation.playbackDistribution * 10) / 10,
  }));

  const options = {
    ...CHART_DEFAULT_OPTIONS,
    title: {
      text: ` ${totalDistributionTime}`,
      itemGap: 0,
      subtext: ['{a|Total Playback Time}', '{b|in minutes}'].join('\n'),
      textStyle: {
        fontSize: 32,
        color: '#343437',
        align: 'center',
      },
      subtextStyle: {
        fontSize: 20,
        rich: {
          a: {
            color: '#343437',
            align: 'center',
            padding: [2, 0],
          },
          b: {
            fontSize: 10,
            color: '#a5a5a5',
            align: 'center',
          },
        },
      },
      x: 83,
      y: 110,
    },
    responsive: false,
    maintainAspectRatio: false,
    color: [
      '#08177a',
      '#0147d4',
      '#4270eb',
      '#879cff',
      '#faab91',
      '#f98063',
      '#ffa3a8',
      '#fa3d4a',
      '#0a3d4d',
      '#63f5d9',
    ],
    tooltip: {
      trigger: 'item',
    },
    legend: {
      orient: 'vertical',
      top: calculateTopMargin(data),
      left: 300,
      icon: 'square',
      textStyle: {
        fontFamily: 'Gellix,sans-serif',
        fontSize: 12,
        overflow: 'truncate',
        rich: {
          a: {
            fontSize: 14,
            fontWeight: 600,
          },
        },
      },
      formatter: (name: any) => {
        let itemValue = chartData.filter((item) => item.name === name);
        return `${makeShort(name)}  {a|${itemValue[0].value}%}`;
      },
    },
    series: [
      {
        type: 'pie',
        width: 400,
        padding: [0, 0, 0, 40],
        radius: ['54%', '78%'],
        center: [140, 130],
        avoidLabelOverlap: false,
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        data: chartData,
      },
    ],
  };

  const mobileOptions = {
    ...options,
    title: {
      ...options.title,
      padding: [95, 0, 0, 0],
      x: 'center',
      y: 'top',
    },
    legend: {
      ...options.legend,
      orient: 'vertical',
      top: '48%',
      bottom: '0%',
      left: 'center',
      textStyle: {
        fontFamily: 'Gellix,sans-serif',
        fontSize: 14,
        overflow: 'truncate',
        rich: {
          a: {
            fontSize: 16,
            fontWeight: 600,
          },
        },
      },
    },
    series: [
      {
        type: 'pie',
        radius: ['50%', '70%'],
        center: ['50%', '24%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        data: chartData,
      },
    ],
  };

  return (
    <ChartCard
      subTitle={''}
      title={
        <div className="flex gap-3 items-center">
          <h2 className="text-black text-xl font-bold">
            {t(
              'analytics.contentEngagementPage.tableHeaders.playbackDistribution',
            )}
          </h2>
        </div>
      }
      showTimeInterval={false}
    >
      <div className="min-w-full min-h-full">
        <Hidden xsDown>
          <ReactECharts
            style={{ height: '310px' }}
            option={options}
            onChartReady={(chart) => chart.resize()}
          />
        </Hidden>
        <Hidden smUp>
          <ReactECharts
            style={{ height: '500px' }}
            option={mobileOptions}
            onChartReady={(chart) => chart.resize()}
          />
        </Hidden>
      </div>
    </ChartCard>
  );
};

export default PlaybackTimeDistributionChart;
