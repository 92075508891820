import Switch from 'raydiant-elements/core/Switch';
import TextField from 'raydiant-elements/core/TextField/TextField';
import useStyles from '../DeviceAISettings.styles';
import DeviceAiSettingsGroup from '../DeviceAiSettingsGroup';

interface DeviceAiSettingsStatusData {
  pullEnabled?: boolean;
  pushEnabled?: boolean;
  pushUrl?: string;
}

interface DeviceAiSettingsStatusProps {
  data: DeviceAiSettingsStatusData;
  onChangePullEnabled?: (value: boolean) => void;
  onChangePushEnabled?: (value: boolean) => void;
  onChangePushUrl?: (value: string) => void;
  showPullEnabled?: boolean;
  showPushEnabled?: boolean;
  showPushUrl?: boolean;
}

const DeviceAiSettingsStatus = ({
  data,
  onChangePullEnabled,
  onChangePushEnabled,
  onChangePushUrl,
  showPullEnabled = false,
  showPushEnabled = false,
  showPushUrl = false,
}: DeviceAiSettingsStatusProps) => {
  const classes = useStyles();

  return (
    <DeviceAiSettingsGroup title="Status">
      {showPullEnabled && (
        <li className={classes.aiSetting} key={'pullEnabled'}>
          <Switch
            checked={data.pullEnabled || false}
            onChange={onChangePullEnabled}
            label={'Pull enabled'}
          />
        </li>
      )}
      {showPushEnabled && (
        <li className={classes.aiSetting} key={'pushEnabled'}>
          <Switch
            checked={data.pushEnabled || false}
            onChange={onChangePushEnabled}
            label={'Push enabled'}
          />
        </li>
      )}
      {showPushUrl && (
        <li className={classes.aiSetting} key={'pushUrl'}>
          <TextField
            label={'Push url'}
            value={data.pushUrl}
            onChange={onChangePushUrl}
          />
        </li>
      )}
    </DeviceAiSettingsGroup>
  );
};

export default DeviceAiSettingsStatus;
