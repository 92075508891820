import { SVGProps } from 'react';

interface TVProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const TV = ({ size = 18, ...props }: TVProps) => {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.125 1.71875C1.82617 1.71875 1.59375 1.98438 1.59375 2.25V11.8125C1.59375 12.1113 1.82617 12.3438 2.125 12.3438H19.125C19.3906 12.3438 19.6562 12.1113 19.6562 11.8125V2.25C19.6562 1.98438 19.3906 1.71875 19.125 1.71875H2.125ZM0 2.25C0 1.08789 0.929688 0.125 2.125 0.125H19.125C20.2871 0.125 21.25 1.08789 21.25 2.25V11.8125C21.25 13.0078 20.2871 13.9375 19.125 13.9375H2.125C0.929688 13.9375 0 13.0078 0 11.8125V2.25ZM3.98438 15.5312H17.2656C17.6973 15.5312 18.0625 15.8965 18.0625 16.3281C18.0625 16.793 17.6973 17.125 17.2656 17.125H3.98438C3.51953 17.125 3.1875 16.793 3.1875 16.3281C3.1875 15.8965 3.51953 15.5312 3.98438 15.5312Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default TV;
