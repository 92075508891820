import classNames from 'classnames';

type DropDownItemProps = {
  disabled?: boolean;
  onClick?: (e: any) => void;
  children: React.ReactNode;
  className?: string;
  selected?: boolean;
};

const DropDownItem = ({
  children,
  className,
  onClick,
  selected,
}: DropDownItemProps) => {
  return (
    <li
      className={classNames(
        'menu-item cursor',
        selected ? 'selected' : '',
        className,
      )}
      onClick={onClick && onClick}
    >
      {children}
    </li>
  );
};

export default DropDownItem;
