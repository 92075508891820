import Dialog, {
  DialogHeader,
  DialogHeading,
} from '@raydiant/raydial/components/Dialog';
import Modal from '@raydiant/raydial/components/Modal';
import Button from '@raydiant/raydial/components/Button';
import Text from '@raydiant/raydial/components/Text';

interface UnableToDeleteDialogProps {
  isOpen: boolean;
  type?: 'location' | 'screen';
  onClick: () => void;
}

const UnableToDeleteDialog = ({
  isOpen,
  type = 'screen',
  onClick,
}: UnableToDeleteDialogProps) => {
  return (
    <Modal isOpen={isOpen} className="text-black">
      <Dialog>
        <DialogHeader>
          <DialogHeading>
            <span>Unable to delete location</span>
          </DialogHeading>
        </DialogHeader>

        <div className="px-4 pt-4 mt-2 !mb-16">
          <Text className="text-black" variant="body">
            {type === 'screen' &&
              'This location you are trying to delete has one or more screens assigned to it. Please move screens to other locations before proceeding.'}
            {type === 'location' &&
              'You cannot have less than one location. Please add another location before deleting this one.'}
          </Text>
        </div>

        <div className="flex flex-col justify-between mb-4 gap-3 px-0 md:px-4">
          <Button
            onClick={() => onClick()}
            variant="primary"
            size="large"
            fullWidth
          >
            Okay
          </Button>
        </div>
      </Dialog>
    </Modal>
  );
};

export default UnableToDeleteDialog;
