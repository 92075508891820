import { FC } from 'react';
import PresentationPreview from 'raydiant-elements/presentation/PresentationPreview';
import PresentationLoader from '../../components/PresentationLoader';
import useApplicationVersion from '../../hooks/useApplicationVersion';
import useTheme from '../../hooks/useTheme';
import usePresentationUploads from '../../hooks/usePresentationUploads';
import usePlaylistPageContext from './usePlaylistPageContext';

interface PlaylistPreviewProps {
  presentationId?: string | null;
  previewMode?: string;
}

const PlaylistPreview: FC<PlaylistPreviewProps> = ({
  presentationId,
  previewMode,
}) => {
  const page = usePlaylistPageContext();

  const presentation = presentationId
    ? page.getPresentation(presentationId)
    : undefined;

  const presentationUploads = usePresentationUploads(presentation);

  // Queries

  const { data: appVersion } = useApplicationVersion(
    presentation?.applicationId,
    presentation?.appVersionId,
  );
  const { data: theme } = useTheme(presentation?.themeId);

  // Render

  return (
    <PresentationPreview previewMode={previewMode} showBorder>
      {presentation && appVersion && (
        <PresentationLoader
          presentation={presentation}
          presentationUploads={presentationUploads}
          appVersion={appVersion}
          theme={theme}
        />
      )}
    </PresentationPreview>
  );
};

export default PlaylistPreview;
