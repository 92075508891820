import { FC } from 'react';
import Select from 'raydiant-elements/core/Select';
import InputLabel from 'raydiant-elements/core/InputLabel';
import {
  ruleGroupOptions,
  ruleGroups,
  RuleGroupID,
} from './ruleTokenInputData';
import { MenuItem } from '@material-ui/core';
import { Hidden } from 'raydiant-elements/layout/Hidden/Hidden';
import useStyles from './RuleSelect.styles';
export interface RuleOperatorSelectProps {
  label: string;
  value: RuleGroupID | null;
  disabled?: boolean;
  onChange: (value: RuleGroupID | null) => void;
}

const RuleGroupSelect: FC<RuleOperatorSelectProps> = ({
  label,
  value,
  disabled = false,
  onChange,
}) => {
  const classes = useStyles();

  const renderDesktopMenu = () => (
    <Select
      classes={{
        select: classes.select,
      }}
      disabled={disabled}
      native={false}
      onChange={(value) => onChange(value ? (value as RuleGroupID) : null)}
      value={value ?? ''}
    >
      {ruleGroupOptions.map(renderMenuItem)}
    </Select>
  );

  const renderMobileMenu = () => (
    <Select
      disabled={disabled}
      native={true}
      onChange={(value) => onChange(value ? (value as RuleGroupID) : null)}
      value={value ?? ''}
    >
      <option value=""></option>
      {ruleGroupOptions.map(renderMobileMenuItem)}
    </Select>
  );

  const renderMenuItem = (operatorId: RuleGroupID) => (
    <MenuItem
      key={operatorId}
      value={operatorId}
      selected={operatorId === value}
      onClick={() => {
        onChange(operatorId);
      }}
    >
      {ruleGroups[operatorId].label}
    </MenuItem>
  );

  const renderMobileMenuItem = (operatorId: RuleGroupID) => (
    <option key={operatorId} value={operatorId}>
      {ruleGroups[operatorId].label}
    </option>
  );

  return (
    <>
      <InputLabel className={classes.root} disabled={disabled}>
        {label}
      </InputLabel>
      <Hidden xsDown>{renderDesktopMenu()}</Hidden>
      <Hidden smUp>{renderMobileMenu()}</Hidden>
    </>
  );
};

export default RuleGroupSelect;
