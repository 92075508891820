import { Application } from '@raydiant/api-client-js';
import Center from 'raydiant-elements/layout/Center';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import ApplicationGroup from './ApplicationGroup';

export interface ApplicationsLayoutProps {
  applications: Application[] | undefined;
  isLoading: boolean;
  onClick: (application: Application) => void;
}

const ApplicationsLayout = ({
  applications,
  isLoading,
  onClick,
}: ApplicationsLayoutProps) => {
  if (isLoading) {
    return (
      <Center>
        <CircularProgress size={30} />
      </Center>
    );
  }
  return (
    <>
      <ApplicationGroup
        data={applications}
        onClick={onClick}
        groupName="content"
      />
      <ApplicationGroup
        data={applications}
        onClick={onClick}
        groupName="menu"
      />
      <ApplicationGroup
        data={applications}
        onClick={onClick}
        groupName="communication"
      />
    </>
  );
};

export default ApplicationsLayout;
