import Button from '@raydiant/raydial/components/Button';
import {
  Footer,
  Header,
  LocationGuidTextFields,
  ProgressStepperContainer,
} from './components';
import toastAddRaydiant from '../../assets/toast-add-raydiant.svg';
import toastCheckEmail from '../../assets/toast-check-email.svg';
import Text from '@raydiant/raydial/components/Text';
import toastLoginSvg from '../../assets/toast-login.svg';
import Link from '@raydiant/raydial/components/Link';
import { useState } from 'react';

const Step1Content = ({
  onNext,
  onPrevious,
}: {
  onNext: () => void;
  onPrevious: () => void;
}) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onNext();
  };

  const handlePrevious = () => {
    onPrevious();
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4 px-4">
        <div className="flex justify-center">
          <img src={toastLoginSvg} alt="toast login" />
        </div>
        <Text variant="smallSubheader">Log into your Toast account</Text>
        <Text as="p">
          Please go to{' '}
          <Link href="//toasttab.com/login" target="_blank">
            toasttab.com/login
          </Link>{' '}
          and enter your Toast login credentials to access your Toast account.
        </Text>
        <div>
          <Link href="#" target="_blank">
            Learn more
          </Link>
        </div>
        <Footer>
          <Button
            size="large"
            variant="secondary"
            className="flex-1"
            onPress={handlePrevious}
          >
            Back
          </Button>
          <Button
            size="large"
            variant="primary"
            className="flex-1"
            type="submit"
          >
            I've logged in
          </Button>
        </Footer>
      </form>
    </>
  );
};

const Step2Content = ({
  onNext,
  onPrevious,
}: {
  onNext: () => void;
  onPrevious: () => void;
}) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onNext();
  };

  const handlePrevious = () => {
    onPrevious();
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4 px-4">
        <div className="flex justify-center">
          <img src={toastAddRaydiant} alt="add Raydiant" />
        </div>
        <Text variant="smallSubheader">Add Raydiant under Integrations</Text>
        <Text as="p">
          Go to the ‘Integrations’ tab in your Toast dashboard. Click on ‘Browse
          & purchase integrations’, then search and add Raydiant
        </Text>

        <div>
          <Link href="#" target="_blank">
            Learn more
          </Link>{' '}
        </div>
        <Footer>
          <Button
            size="large"
            variant="secondary"
            className="flex-1"
            onPress={handlePrevious}
          >
            Back
          </Button>
          <Button
            size="large"
            variant="primary"
            className="flex-1"
            type="submit"
          >
            I'm all set
          </Button>
        </Footer>
      </form>
    </>
  );
};

const Step3Content = ({
  onNext,
  onPrevious,
}: {
  onNext: () => void;
  onPrevious: () => void;
}) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onNext();
  };

  const handlePrevious = () => {
    onPrevious();
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4 px-4">
        <div className="flex justify-center">
          <img src={toastCheckEmail} alt="check email" />
        </div>
        <Text variant="smallSubheader">Check your Email</Text>
        <Text as="p">
          If you just added the Raydiant integration, you will receive an email
          with your restaurant’s location GUID information. If you added it
          previously, look for an email from Toast Partner Connect. Copy and
          paste the GUIDs into the fields in Step 4.
        </Text>
        <Text as="p">
          Reach out to Raydiant support if you are unable to find the email.
        </Text>

        <div>
          <Link href="#" target="_blank">
            Learn more
          </Link>{' '}
        </div>
        <Footer>
          <Button
            size="large"
            variant="secondary"
            className="flex-1"
            onPress={handlePrevious}
          >
            Back
          </Button>
          <Button
            size="large"
            variant="primary"
            className="flex-1"
            type="submit"
          >
            I got the email
          </Button>
        </Footer>
      </form>
    </>
  );
};

const Step4Content = ({
  onSubmit,
  onPrevious,
  locationGuids,
  onChange,
}: {
  onSubmit: () => void;
  onPrevious: () => void;
  locationGuids: Array<string>;
  onChange: (locationGuids: Array<string>) => void;
}) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  const handlePrevious = () => {
    onPrevious();
  };

  const handleChange = (locationGuids: string[]) => {
    onChange(locationGuids);
  };

  const isValid =
    locationGuids.length > 0 && locationGuids.every((guid) => guid.length > 0);

  return (
    <>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4 px-4">
        <Text variant="smallSubheader">
          Enter Location GUID’s from your email
        </Text>
        <Text as="p">
          Add location GUIDs for every location you would like to import Toast
          menus from.{' '}
        </Text>

        <LocationGuidTextFields
          locationGuids={locationGuids}
          onChange={handleChange}
        />

        <Footer>
          <Button
            size="large"
            variant="secondary"
            className="flex-1"
            onPress={handlePrevious}
          >
            Back
          </Button>
          <Button
            size="large"
            variant="primary"
            className="flex-1"
            type="submit"
            isDisabled={!isValid}
          >
            Connect with Toast
          </Button>
        </Footer>
      </form>
    </>
  );
};

const ToastSetupWizard = ({
  locationGuids,
  onLocationGuidsChange,
  onSubmit,
  onExit,
}: {
  locationGuids: Array<string>;
  onSubmit: () => void;
  onLocationGuidsChange: (locationGuids: Array<string>) => void;
  onExit: () => void;
}) => {
  const [step, setStep] = useState(1);

  const handleOnNext = () => {
    setStep(step + 1);
  };

  const handleOnPrevious = () => {
    setStep(step - 1);
  };

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <>
      <Header
        heading="Connect Toast with Raydiant"
        subheading="Follow the steps and complete the integration"
      />
      <ProgressStepperContainer selectedStep={step} />
      {step === 1 && <Step1Content onPrevious={onExit} onNext={handleOnNext} />}
      {step === 2 && (
        <Step2Content onPrevious={handleOnPrevious} onNext={handleOnNext} />
      )}
      {step === 3 && (
        <Step3Content onPrevious={handleOnPrevious} onNext={handleOnNext} />
      )}
      {step === 4 && (
        <Step4Content
          onPrevious={handleOnPrevious}
          onSubmit={handleSubmit}
          locationGuids={locationGuids}
          onChange={onLocationGuidsChange}
        />
      )}
    </>
  );
};

export default ToastSetupWizard;
