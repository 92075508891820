import classNames from 'classnames';

type DataTableHeaderProps<T> =
  React.InputHTMLAttributes<HTMLTableCellElement> & {
    onSort: (sort: TableSort<T>) => void;
    sortKey?: keyof T;
    sortDirection?: TableSortDirection;
    showSort?: boolean;
    wrapperClassName?: string;
  };

export type TableSortDirection = 'asc' | 'desc';
export type TableSort<T> = {
  direction: TableSortDirection;
  key: keyof T;
};

const DataTableHeader = <T,>({
  className,
  children,
  onSort,
  showSort,
  sortDirection = 'asc',
  sortKey,
  wrapperClassName,
  ...others
}: DataTableHeaderProps<T>) => {
  const isAscending = sortDirection === 'asc';

  return (
    <th
      className={classNames('cursor-pointer', className)}
      {...others}
      onClick={() => {
        sortKey &&
          onSort({
            key: sortKey,
            direction: isAscending ? 'desc' : 'asc',
          });
      }}
    >
      <div
        className={classNames(
          'flex items-center gap-3 justify-center w-full text-sm',
          wrapperClassName,
        )}
      >
        {children}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512"
          className={classNames(
            'w-4 h-4',
            showSort ? '' : 'invisible',
            isAscending ? 'rotate-180' : '',
          )}
        >
          <path d="M320 240L160 384 0 240l0-48 320 0 0 48z" />
        </svg>
      </div>
    </th>
  );
};

export default DataTableHeader;
