import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router';
import { useEffect, useState } from 'react';
import ConnectionStatusDropDown, {
  ConnectionStatusQuery,
} from './ConnectionStatusDropDown';
import DeviceSelectDropDown, {
  DeviceQuery,
} from './DeviceSelectDropDown/DeviceSelectDropDown';
import UsersDropDown, { UsersQuery } from './UsersDropDown/UsersDropDown';
import {
  CLEAR_QUERY_KEY,
  DEFAULT_SCREEN_FILTER_QUERY,
  getValidScreensFilterQueryKeys,
  mapScreensFilterQuery,
  parseScreensFilterQueryParams,
  screensFilterQueryString,
} from './utils';
import deepEqual from 'fast-deep-equal';
import ContentSelectDropDown, {
  ContentQuery,
} from './ContentSelectDropDown/ContentSelectDropDown';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsLoadingScreens } from '../../DevicesPage/selectors';
import ClearScreensFilterConfirmation from './ClearFilterConfirmation';
import { devicesPageActions } from '..';
import { selectUserProfile, selectIsVIOnly } from '../../../selectors/user';
import LocationsDropDown, {
  LocationsQuery,
} from './LocationsDropDown/LocationsDropDown';

const SCREENS_QUERY_KEYS = ['connectionStatus'] as const;

type ScreensFilterProps = {
  className?: string;
  isSidebarOpen?: boolean;
};

export type ScreensFilterQueryKeys = Partial<typeof SCREENS_QUERY_KEYS[number]>;

export type ScreensFilterQuery = {
  content?: ContentQuery;
  connectionStatus?: ConnectionStatusQuery;
  device?: DeviceQuery;
  users?: UsersQuery;
  locations?: LocationsQuery;
};

const ScreensFilter = ({
  className,
  isSidebarOpen = false,
}: ScreensFilterProps) => {
  const { t } = useTranslation(['devices']);
  const { pathname, search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState<ScreensFilterQuery>(
    DEFAULT_SCREEN_FILTER_QUERY,
  );
  const isLoading = useSelector(selectIsLoadingScreens);
  const isVIOnly = useSelector(selectIsVIOnly);
  const currentUser = useSelector(selectUserProfile);

  useEffect(() => {
    if (search?.length > 0)
      setFilter({
        ...mapScreensFilterQuery(search),
      });

    if (search?.length === 0) setFilter(DEFAULT_SCREEN_FILTER_QUERY);
  }, [search]);

  const onApplyFilter = () => {
    const data = parseScreensFilterQueryParams(filter);

    dispatch(devicesPageActions.updateSelectedDeviceIds([]));

    history.push({
      pathname,
      search: `?${screensFilterQueryString(data)}`,
    });
  };

  const onClearFilter = () => {
    setFilter(DEFAULT_SCREEN_FILTER_QUERY);
    history.push({
      pathname,
      search: `?${CLEAR_QUERY_KEY}=true`,
    });
  };

  const isDirty = !deepEqual(filter, DEFAULT_SCREEN_FILTER_QUERY);
  const hasQueryParams =
    getValidScreensFilterQueryKeys(search)?.length > 0 && isDirty;
  const isDisabled = !isDirty;

  const [showFilter, setShowFilter] = useState(hasQueryParams);
  const [showClearFilterConfirmation, setShowClearFilterConfirmation] =
    useState(false);

  useEffect(() => {
    setShowFilter(hasQueryParams);
  }, [hasQueryParams]);

  const renderFilterToggle = () => (
    <button
      className={classNames(
        'btn mini !text-gray-400',
        showFilter ? 'open' : '',
      )}
      onClick={() => setShowFilter(!showFilter)}
    >
      <div className="wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
          <path d="M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z" />
        </svg>
      </div>
    </button>
  );

  return (
    <>
      <div
        className={classNames(
          `bg-white px-6 py-3.5 rounded-3xl ${
            isSidebarOpen && 'md:w-[calc(100%_-_414px)]'
          }`,
          className,
        )}
      >
        <div className="flex justify-between">
          <div className={classNames('flex items-center gap-3')}>
            <h2 className="text-xl font-semibold text-black">
              {t('screens.filter')}
            </h2>
            {renderFilterToggle()}
          </div>
          {!showFilter && (
            <button
              disabled={isDisabled || isLoading}
              onClick={() =>
                hasQueryParams
                  ? setShowClearFilterConfirmation(true)
                  : onClearFilter()
              }
              className="btn"
            >
              {t('screens.clearFilter')}
            </button>
          )}
        </div>
        {showFilter && (
          <div
            className={`min-h-10.5 flex flex-col gap-6 xl:gap-3 xl:justify-between mt-3 divide-y ${
              !isSidebarOpen && 'xl:flex-row xl:divide-x xl:divide-y-0'
            }`}
          >
            <div
              className={classNames(
                'flex flex-col xl:flex-row items-center gap-3 w-full',
              )}
            >
              <ConnectionStatusDropDown
                className={`w-full ${
                  isSidebarOpen ? 'md:basis-1/5' : 'md:max-w-min xl:max-w-min'
                }`}
                onChange={(value) =>
                  setFilter({
                    ...filter,
                    connectionStatus: value,
                  })
                }
                value={filter.connectionStatus!}
              />
              {!isVIOnly && (
                <ContentSelectDropDown
                  className={`w-full ${
                    isSidebarOpen ? 'md:basis-1/5' : 'md:max-w-min xl:max-w-min'
                  }`}
                  onChange={(value) =>
                    setFilter({
                      ...filter,
                      content: value,
                    })
                  }
                  value={filter.content!}
                />
              )}
              <DeviceSelectDropDown
                className={`w-full ${
                  isSidebarOpen ? 'md:basis-1/5' : 'md:max-w-min xl:max-w-min'
                }`}
                onChange={(value) =>
                  setFilter({
                    ...filter,
                    device: value,
                  })
                }
                value={filter.device!}
                isVIOnly={isVIOnly}
              />
              {currentUser?.domainId && (
                <UsersDropDown
                  className={`w-full ${
                    isSidebarOpen
                      ? 'md:basis-1/5'
                      : 'md:max-w-fit xl:max-w-fit sm:max-w-max'
                  }`}
                  onChange={(value) =>
                    setFilter({
                      ...filter,
                      users: value,
                    })
                  }
                  value={filter.users!}
                />
              )}
              {currentUser?.isLocationEnabled && (
                <LocationsDropDown
                  className={`w-full ${
                    isSidebarOpen
                      ? 'md:basis-1/5'
                      : 'md:max-w-fit xl:max-w-fit sm:max-w-max'
                  }`}
                  onChange={(value) =>
                    setFilter({
                      ...filter,
                      locations: value,
                    })
                  }
                  value={filter.locations!}
                />
              )}
            </div>
            <div
              className={`flex items-center gap-6 min-w-max ${
                isSidebarOpen
                  ? 'justify-end xl:justify-end pt-6'
                  : 'justify-center xl:justify-start xl:gap-3 pt-6 xl:pt-0 xl:pl-6'
              }`}
            >
              <button
                disabled={isDisabled || isLoading}
                onClick={() =>
                  hasQueryParams
                    ? setShowClearFilterConfirmation(true)
                    : onClearFilter()
                }
                className="btn sm:mini"
              >
                {t('screens.clearFilter')}
              </button>
              <button
                disabled={isDisabled || isLoading}
                onClick={() => onApplyFilter()}
                className="btn btn-primary sm:mini"
              >
                {t('screens.applyFilter')}
              </button>
            </div>
          </div>
        )}
      </div>
      <ClearScreensFilterConfirmation
        open={showClearFilterConfirmation}
        onCancel={() => setShowClearFilterConfirmation(false)}
        onConfirm={() => {
          setShowClearFilterConfirmation(false);
          onClearFilter();
        }}
      />
    </>
  );
};

export default ScreensFilter;
