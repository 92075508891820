import SelectField from 'raydiant-elements/core/SelectField';
import Switch from 'raydiant-elements/core/Switch';
import TextField from 'raydiant-elements/core/TextField/TextField';
import useStyles from '../DeviceAISettings.styles';
import DeviceAiSettingsGroup from '../DeviceAiSettingsGroup';

interface DeviceAiSettingsSdkData {
  useAge?: boolean;
  useEmotions?: boolean;
  useFaceMask?: boolean;
  useGender?: boolean;
  useHeadpose?: boolean;
  useSmile?: boolean;
  detectionRange?: string;
  maxFaceSize?: number;
  minFaceSize?: number;
  maxNumFaces?: string;
  operationMode?: string;
  faceConfidenceThreshold?: string;
  neuralNetworksPath?: string;
  bodyTrackFps?: string;
  dnnTarget?: string;
  logLevel?: string;
  numThreads?: string;
}

interface DeviceAiSettingsSdkProps {
  data: DeviceAiSettingsSdkData;
  detectionRangeOptions?: string[];
  logLevelOptions?: string[];
  onChangeUseAge?: (value: boolean) => void;
  onChangeUseEmotions?: (value: boolean) => void;
  onChangeUseFaceMask?: (value: boolean) => void;
  onChangeUseHeadpose?: (value: boolean) => void;
  onChangeUseGender?: (value: boolean) => void;
  onChangeUseSmile?: (value: boolean) => void;
  onChangeDetectionRange?: (value: string) => void;
  onChangeMaxFaceSize?: (value: string) => void;
  onChangeMinFaceSize?: (value: string) => void;
  onChangeMaxNumFaces?: (value: string) => void;
  onChangeOperationMode?: (value: string) => void;
  onChangeFaceConfidenceThreshold?: (value: string) => void;
  onChangeNeuralNetworksPath?: (value: string) => void;
  onChangeBodyTrackFps?: (value: string) => void;
  onChangeDnnTarget?: (value: string) => void;
  onChangeLogLevel?: (value: string) => void;
  onChangeNumThreads?: (value: string) => void;
  operationModeOptions?: string[];
  showUseAge?: boolean;
  showUseEmotions?: boolean;
  showUseFaceMask?: boolean;
  showUseHeadpose?: boolean;
  showUseGender?: boolean;
  showUseSmile?: boolean;
  showDetectionRange?: boolean;
  showMaxFaceSize?: boolean;
  showMinFaceSize?: boolean;
  showMaxNumFaces?: boolean;
  showOperationMode?: boolean;
  showFaceConfidenceThreshold?: boolean;
  showNeuralNetworksPath?: boolean;
  showBodyTrackFps?: boolean;
  showDnnTarget?: boolean;
  showLogLevel?: boolean;
  showNumThreads?: boolean;
}

const DeviceAiSettingsSdk = ({
  data,
  detectionRangeOptions,
  logLevelOptions,
  onChangeUseAge,
  onChangeUseEmotions,
  onChangeUseFaceMask,
  onChangeUseGender,
  onChangeUseHeadpose,
  onChangeUseSmile,
  onChangeDetectionRange,
  onChangeMaxFaceSize,
  onChangeMinFaceSize,
  onChangeMaxNumFaces,
  onChangeOperationMode,
  onChangeFaceConfidenceThreshold,
  onChangeNeuralNetworksPath,
  onChangeBodyTrackFps,
  onChangeDnnTarget,
  onChangeLogLevel,
  onChangeNumThreads,
  operationModeOptions,
  showUseAge = false,
  showUseEmotions = false,
  showUseFaceMask = false,
  showUseHeadpose = false,
  showUseGender = false,
  showUseSmile = false,
  showDetectionRange = false,
  showMaxFaceSize = false,
  showMinFaceSize = false,
  showMaxNumFaces = false,
  showOperationMode = false,
  showFaceConfidenceThreshold = false,
  showNeuralNetworksPath = false,
  showBodyTrackFps = false,
  showDnnTarget = false,
  showLogLevel = false,
  showNumThreads = false,
}: DeviceAiSettingsSdkProps) => {
  const classes = useStyles();

  return (
    <DeviceAiSettingsGroup title="SDK Settings">
      {showUseAge && (
        <li className={classes.aiSetting} key={'useAge'}>
          <Switch
            checked={data.useAge || false}
            onChange={onChangeUseAge}
            label={'Use age'}
          />
        </li>
      )}
      {showUseEmotions && (
        <li className={classes.aiSetting} key={'useEmotions'}>
          <Switch
            checked={data.useEmotions || false}
            onChange={onChangeUseEmotions}
            label={'Use emotions'}
          />
        </li>
      )}
      {showUseFaceMask && (
        <li className={classes.aiSetting} key={'useFaceMask'}>
          <Switch
            checked={data.useFaceMask || false}
            onChange={onChangeUseFaceMask}
            label={'Use face mask'}
          />
        </li>
      )}
      {showUseGender && (
        <li className={classes.aiSetting} key={'useGender'}>
          <Switch
            checked={data.useGender || false}
            onChange={onChangeUseGender}
            label={'Use gender'}
          />
        </li>
      )}
      {showUseHeadpose && (
        <li className={classes.aiSetting} key={'useHeadpose'}>
          <Switch
            checked={data.useHeadpose || false}
            onChange={onChangeUseHeadpose}
            label={'Use head pose'}
          />
        </li>
      )}
      {showUseSmile && (
        <li className={classes.aiSetting} key={'useSmile'}>
          <Switch
            checked={data.useSmile || false}
            onChange={onChangeUseSmile}
            label={'Use smile'}
          />
        </li>
      )}
      {showDetectionRange && (
        <li className={classes.aiSetting} key={'detectionRancge'}>
          <SelectField
            label={'Detection range'}
            value={data.detectionRange}
            onChange={onChangeDetectionRange}
          >
            {detectionRangeOptions?.map((name, index) => (
              <option value={name} key={index}>
                {name.replaceAll('_', ' ')}
              </option>
            ))}
          </SelectField>
        </li>
      )}
      {showMaxFaceSize && (
        <li className={classes.aiSetting} key={'maxFaceSize'}>
          <TextField
            label={'Max face size'}
            value={data.maxFaceSize?.toString()}
            onChange={onChangeMaxFaceSize}
          />
        </li>
      )}
      {showMinFaceSize && (
        <li className={classes.aiSetting} key={'minFaceSize'}>
          <TextField
            label={'Min face size'}
            value={data.minFaceSize?.toString()}
            onChange={onChangeMinFaceSize}
          />
        </li>
      )}
      {showMaxNumFaces && (
        <li className={classes.aiSetting} key={'maxNumFaces'}>
          <TextField
            label={'Max number of faces'}
            value={data.maxNumFaces?.toString()}
            onChange={onChangeMaxNumFaces}
          />
        </li>
      )}
      {showOperationMode && (
        <li className={classes.aiSetting} key={'operationMode'}>
          <SelectField
            label={'Operation mode'}
            value={data.operationMode}
            onChange={onChangeOperationMode}
          >
            {operationModeOptions?.map((name, index) => (
              <option value={name} key={index}>
                {name.replaceAll('_', ' ')}
              </option>
            ))}
          </SelectField>
        </li>
      )}
      {showFaceConfidenceThreshold && (
        <li className={classes.aiSetting} key={'faceConfidenceThreshold'}>
          <TextField
            label={'Face confidence threshold'}
            value={data.faceConfidenceThreshold?.toString()}
            onChange={onChangeFaceConfidenceThreshold}
          />
        </li>
      )}
      {showNeuralNetworksPath && (
        <li className={classes.aiSetting} key={'neuralNetworksPath'}>
          <TextField
            label={'Neural networks path'}
            value={data.neuralNetworksPath}
            onChange={onChangeNeuralNetworksPath}
          />
        </li>
      )}
      {showBodyTrackFps && (
        <li className={classes.aiSetting} key={'bodyTrackFps'}>
          <TextField
            label={'Body tracks frame per second (FPS)'}
            value={data.bodyTrackFps}
            onChange={onChangeBodyTrackFps}
          />
        </li>
      )}
      {showDnnTarget && (
        <li className={classes.aiSetting} key={'dnnTarget'}>
          <TextField
            label={'Dnn target'}
            value={data.dnnTarget}
            onChange={onChangeDnnTarget}
          />
        </li>
      )}
      {showLogLevel && (
        <li className={classes.aiSetting} key={'logLevel'}>
          <SelectField
            label={'Log level'}
            value={data.logLevel}
            onChange={onChangeLogLevel}
          >
            {logLevelOptions?.map((name, index) => (
              <option value={name} key={index}>
                {name.replaceAll('_', ' ')}
              </option>
            ))}
          </SelectField>
        </li>
      )}
      {showNumThreads && (
        <li className={classes.aiSetting} key={'numThreads'}>
          <TextField
            label={'Number of threads'}
            value={data.numThreads?.toString()}
            onChange={onChangeNumThreads}
          />
        </li>
      )}
    </DeviceAiSettingsGroup>
  );
};

export default DeviceAiSettingsSdk;
