import { Day, Device, Tag } from '@raydiant/api-client-js';
import { ScreensFilterQuery } from './ScreensFilter';
import { DeviceScreenOrientationQuery } from './DeviceSelectDropDown/DeviceScreenOrientation';
import {
  deviceResolutionMap,
  DeviceResolutionQuery,
  DeviceResolutionType,
  deviceScreensDimensionsMap,
} from './DeviceSelectDropDown/DeviceResolution';
import { ConnectionStatusQuery } from './ConnectionStatusDropDown';
import { TagKey, tagData } from '../../../components/TagManager/tagManagerData';
import { mapTypes } from '../../../components/TagManager';

export const CLEAR_QUERY_KEY = 'clear';

export const DEFAULT_SCREEN_FILTER_QUERY: ScreensFilterQuery = {
  connectionStatus: {
    offline: false,
    online: false,
  },
  device: {
    resolution: {
      '720p': false,
      '1080p': false,
      '4K': false,
      '8K': false,
    },
    screenOrientation: {
      left: false,
      right: false,
      normal: false,
    },
    timezone: [],
    tags: [],
  },
  users: {
    screenOwners: [],
    sharedWith: [],
  },
};

type ScreensFilterTagsQueryParams = string;

export type ScreensFilterQueryParams = {
  connection_status?: keyof ConnectionStatusQuery;
  page?: number;
  page_size?: number;
  profile_ids?: string;
  playlist_id?: string;
  screen_dimensions?: string;
  screen_orientation?: keyof DeviceScreenOrientationQuery;
  shared_profile_ids?: string;
  tags?: ScreensFilterTagsQueryParams;
  timezone?: string;
};

export const screensFilterQueryString = (
  params: ScreensFilterQueryParams,
): string => {
  const query = new URLSearchParams(params as any);

  return query.toString();
};

export const parseScreensFilterQueryParams = (
  params: ScreensFilterQuery,
): ScreensFilterQueryParams => {
  let query: ScreensFilterQueryParams = {};

  if (hasConnectionStatusParams(params))
    query.connection_status = params.connectionStatus?.online
      ? 'online'
      : 'offline';
  if (hasScreenOrientationParams(params))
    query.screen_orientation = getScreenOrientation(
      params.device?.screenOrientation!,
    );
  if (hasTimezoneParams(params))
    query.timezone = params.device?.timezone.join(',');
  if (hasScreenResolutionParams(params))
    query.screen_dimensions = getScreenDimensions(params.device?.resolution!);
  if (hasScreenOwners(params)) {
    query.profile_ids = params.users!.screenOwners.join(',');
  }
  if (hasSharedWith(params)) {
    query.shared_profile_ids = params.users!.sharedWith.join(',');
  }
  if (hasTags(params)) {
    const tags = params.device?.tags?.map((tag) => ({
      label: tag.label,
      key: tag.key,
      type: tag.type,
      value: tag.type === 'days_of_week' ? tag.value.join(',') : tag.value,
    }));
    query.tags = JSON.stringify(tags);
  }
  if (hasPlaylistId(params)) {
    query.playlist_id = params.content?.playlistId;
  }

  return query;
};

export const mapScreensFilterQuery = (search: string): ScreensFilterQuery => {
  const queryParams = new URLSearchParams(search);
  const params = Object.fromEntries(queryParams) as ScreensFilterQueryParams;

  let query: Partial<ScreensFilterQuery> = {};

  query = {
    content: {
      playlistId: params.playlist_id ?? '',
    },
    connectionStatus: getConnectionStatusQuery(params.connection_status),
    device: {
      resolution: getScreenResolutionsQuery(params?.screen_dimensions ?? ''),
      screenOrientation: getScreenOrientationQuery(params.screen_orientation),
      timezone: params.timezone?.split(',') || [],
      tags: params.tags ? JSON.parse(params.tags) : [],
    },
    users: {
      screenOwners: params.profile_ids?.split(',') || [],
      sharedWith: params.shared_profile_ids?.split(',') || [],
    },
  };

  return query;
};

const hasScreenOwners = (params: ScreensFilterQuery) =>
  !!params?.users!?.screenOwners.join(',');

const hasSharedWith = (params: ScreensFilterQuery) =>
  !!params?.users!?.sharedWith.join(',');

const hasConnectionStatusParams = (params: ScreensFilterQuery) =>
  params.connectionStatus &&
  (params?.connectionStatus?.offline || params?.connectionStatus?.online);

const hasScreenOrientationParams = (params: ScreensFilterQuery) =>
  params?.device?.screenOrientation &&
  (params?.device?.screenOrientation?.left ||
    params?.device?.screenOrientation?.right ||
    params?.device?.screenOrientation?.normal);

const hasTimezoneParams = (params: ScreensFilterQuery) =>
  !!params.device?.timezone.join('');

const hasScreenResolutionParams = (params: ScreensFilterQuery) =>
  params.device?.resolution &&
  Object.values(params.device?.resolution).includes(true);

const hasTags = (params: ScreensFilterQuery) =>
  params?.device?.tags && params?.device?.tags?.length > 0;

const hasPlaylistId = (params: ScreensFilterQuery) =>
  params?.content?.playlistId && params?.content?.playlistId?.length > 0;

const getScreenOrientation = (
  screenOrientation: DeviceScreenOrientationQuery,
): keyof DeviceScreenOrientationQuery => {
  const [screenOrientationQuery] = Object.keys(screenOrientation).filter(
    (item) => item,
  );

  return screenOrientationQuery as keyof DeviceScreenOrientationQuery;
};

const getScreenDimensions = (screenResolution: DeviceResolutionQuery) =>
  Object.keys(screenResolution)
    .filter((item) => screenResolution[item as keyof DeviceResolutionQuery])
    .map(
      (resolution) => deviceResolutionMap[resolution as DeviceResolutionType],
    )
    .join(',');

const getScreenResolutionsQuery = (screenDimensions: string) => {
  const screenResolution: DeviceResolutionQuery = {
    ...DEFAULT_SCREEN_FILTER_QUERY.device?.resolution!,
  };

  const validDimensions = screenDimensions
    .split(',')
    .filter((item) => Object.keys(deviceScreensDimensionsMap).includes(item));

  if (validDimensions.length > 0) {
    validDimensions.forEach((item) => {
      screenResolution[
        deviceScreensDimensionsMap[item] as keyof DeviceResolutionQuery
      ] = true;
    });
  }

  return screenResolution;
};

const getScreenOrientationQuery = (
  screenOrientation: ScreensFilterQueryParams['screen_orientation'],
) => {
  const screenOrientationQuery: DeviceScreenOrientationQuery = {
    ...DEFAULT_SCREEN_FILTER_QUERY.device?.screenOrientation!,
  };

  if (!screenOrientation) return screenOrientationQuery;

  if (screenOrientationQuery[screenOrientation] !== undefined) {
    screenOrientationQuery[screenOrientation] = true;
  }

  return screenOrientationQuery;
};

const getConnectionStatusQuery = (
  connectionStatus: ScreensFilterQueryParams['connection_status'],
) => {
  const connectionStatusQuery: ConnectionStatusQuery = {
    ...DEFAULT_SCREEN_FILTER_QUERY.connectionStatus!,
  };

  if (!connectionStatus) return connectionStatusQuery;

  if (connectionStatusQuery[connectionStatus] !== undefined) {
    connectionStatusQuery[connectionStatus] = true;
  }

  return connectionStatusQuery;
};

export const getValidScreensFilterQueryKeys = (search: string) =>
  Object.keys(Object.fromEntries(new URLSearchParams(search))).filter(
    (key) => key !== CLEAR_QUERY_KEY,
  );

interface TagDropdown {
  [key: string]: Set<any>;
}

const transformToTag = (tagDropdownOptions: TagDropdown): Tag[] => {
  let options = [] as Tag[];
  const sortedKeys = Object.keys(tagDropdownOptions).sort();

  sortedKeys.forEach((key) => {
    const keyWithType = key as TagKey;
    const value = tagDropdownOptions[keyWithType];
    const setToArray = Array.from(value).sort();
    const arrayToTags = setToArray.map((item) => ({
      key,
      label: tagData[keyWithType].label,
      value: item,
      type: mapTypes(key) as any,
      // NOTE: These values are populated by the API and technically not required but
      // here to appease TS. There's probably a better way to structure the types.
      id: '',
      resourceId: '',
      createdAt: '',
    }));
    options = [...options, ...arrayToTags];
  });

  return options;
};

export const getTagDropDownItems = (devices: Device[]): Tag[] => {
  const tagDropdownOptions = {} as TagDropdown;
  devices.forEach((device) => {
    device.resource.r.tags.forEach((tag) => {
      if (!tagDropdownOptions.hasOwnProperty(tag.key)) {
        tagDropdownOptions[tag.key] = new Set();
      }
      if (Array.isArray(tag.value)) {
        const dayArray = tag.value as Day[];
        dayArray?.forEach((value) =>
          tagDropdownOptions[tag.key].add(value.trim().toLowerCase()),
        );
      } else {
        const tagValue = tag.value as string;
        tagDropdownOptions[tag.key].add(tagValue.trim().toLowerCase());
      }
    });
  });

  return transformToTag(tagDropdownOptions);
};
