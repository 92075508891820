import classNames from 'classnames';
import Text from '@raydiant/raydial/components/Text';

type PageTitleProps = {
  className?: string;
  subTitle?: string;
  title: string;
  button?: React.ReactNode;
};

const PageTitle = ({ className, subTitle, title, button }: PageTitleProps) => {
  return (
    <div className={classNames(className)}>
      <div>
        <Text variant="header" as="h1" className="text-black">
          {title}
        </Text>
        <p className="text-xl gray-400">{subTitle}</p>
      </div>
      {button}
    </div>
  );
};

export default PageTitle;
