import { useTranslation } from 'react-i18next';
import interactionConversions from '../../../assets/interaction-conversions.svg';
import sessionConversions from '../../../assets/session-conversions.svg';
import TrendArrow from './TrendArrow';
import { getDisplayValue, toPercent } from './utils';
import DataCard, { DataCardDisplayProps } from './DataCard';

type ConversionsCardProps = DataCardDisplayProps;

const ConversionsCard = ({
  change,
  value,
  showEmpty = false,
  type,
  noPreviousData = false,
}: ConversionsCardProps) => {
  const { t } = useTranslation();
  const isSessions = type === 'sessions';
  const displayValue = getDisplayValue(toPercent(value), '%');

  return (
    <DataCard type={type}>
      <img
        className="image"
        src={isSessions ? sessionConversions : interactionConversions}
        alt={t('analytics.conversions')!}
      />
      <div className="w-full justify-end flex text">
        <div className="mr-6">
          <p className="display-value">{showEmpty ? '— —' : displayValue}</p>
          <p className="text-sm font-semibold align-top">
            {t('analytics.conversions')}
          </p>
          {showEmpty && <p className="text-xs">{t('analytics.noValidData')}</p>}
          {!showEmpty && (
            <>
              {noPreviousData ? (
                <p className="text-xs">
                  {t('analytics.trafficAwarenessPage.overview.noPrevious')}
                </p>
              ) : (
                <TrendArrow
                  change={change}
                  label={t('analytics.fromThePreviousPeriod')}
                />
              )}
            </>
          )}
        </div>
      </div>
    </DataCard>
  );
};

export default ConversionsCard;
