import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import Center from 'raydiant-elements/layout/Center';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import Text from 'raydiant-elements/core/Text';
import DeviceAiSettingsVideoHeader from './DeviceAiSettingsVideoHeader';

export type DeviceAiSettingsVideoPlayerState =
  | 'playing'
  | 'error'
  | 'idle'
  | 'loading'
  | 'stopping';
interface DeviceAiSettingsVideoProps {
  errorMessages?: string[];
  hasError: boolean;
  fullScreen?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  onPlay?: () => void;
  onStop?: () => void;
  showMaximize?: boolean;
  showMinimize?: boolean;
  url?: string;
  videoPlayerElement: React.MutableRefObject<HTMLVideoElement>;
  videoPlayerState: DeviceAiSettingsVideoPlayerState;
}
const DeviceAiSettingsVideo = ({
  errorMessages,
  fullScreen = false,
  hasError = false,
  onClose,
  onOpen,
  onPlay,
  onStop,
  showMaximize = false,
  showMinimize = false,
  url,
  videoPlayerElement,
  videoPlayerState,
}: DeviceAiSettingsVideoProps) => {
  const classes = useStyles();
  const IVSPlayer = (window as any).IVSPlayer;
  const playerSupported =
    IVSPlayer !== undefined && IVSPlayer.isPlayerSupported;

  let fullErrors = errorMessages || [];

  if (!playerSupported) {
    fullErrors.push('IVS streaming unsupported in this browser');
  }

  const renderErrors = () => {
    return (
      <>
        <ErrorOutlineIcon className="icon" color="error" />
        {fullErrors?.map((message) => (
          <Text small>{message}</Text>
        ))}
      </>
    );
  };

  const isPlaying = videoPlayerState === 'playing';
  const isLoading = videoPlayerState === 'loading';
  const isIdle = videoPlayerState === 'idle';
  const isError = videoPlayerState === 'error';
  const isStopping = videoPlayerState === 'stopping';

  return (
    <div className={classes.video}>
      <DeviceAiSettingsVideoHeader
        onClose={onClose}
        onOpen={onOpen}
        onPlay={onPlay}
        onStop={onStop}
        fullScreen={fullScreen}
        hasError={hasError}
        isPlaying={isPlaying}
        isLoadingVideo={isLoading}
        showMaximize={showMaximize}
        showMinimize={showMinimize}
      />
      {isStopping && (
        <div className="empty">
          <Center className="wrapper">
            <CircularProgress size={30}></CircularProgress>
            <Text>Stopping playback.</Text>
          </Center>
        </div>
      )}
      {isLoading && (
        <div className="empty">
          <Center className="wrapper">
            <CircularProgress size={30}></CircularProgress>
            <Text>This may take a minute.</Text>
          </Center>
        </div>
      )}
      {isError && (
        <div className="error">
          <Center className="wrapper">{renderErrors()}</Center>
        </div>
      )}
      {isIdle && (
        <div className="empty">
          <Center className="wrapper"></Center>
        </div>
      )}
      {
        <video
          className="video"
          ref={videoPlayerElement}
          style={{
            display: isPlaying ? 'block' : 'none',
          }}
        ></video>
      }
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    video: {
      '& .icon': {
        '& .playback': {
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
        },
      },
      '& .empty, & .video, & .error': {
        backgroundColor: theme.palette.grey['400'],
        aspectRatio: '4/3 !important',
        minHeight: 'initial !important',
      },
      '& .video': {
        width: '100%',
      },
      '& .wrapper': {
        height: '100%',
      },
    },
  });
});

export default DeviceAiSettingsVideo;
