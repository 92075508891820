import { SVGProps } from 'react';

interface XMarkProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const XMark = ({ size = 11, ...props }: XMarkProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.97266 2.01016L6.50391 5.47891L9.94375 8.91875C10.2328 9.17891 10.2328 9.6125 9.94375 9.87266C9.68359 10.1617 9.25 10.1617 8.98984 9.87266L5.52109 6.43281L2.08125 9.87266C1.82109 10.1617 1.3875 10.1617 1.12734 9.87266C0.838281 9.6125 0.838281 9.17891 1.12734 8.88984L4.56719 5.45L1.12734 2.01016C0.838281 1.75 0.838281 1.31641 1.12734 1.02734C1.3875 0.767189 1.82109 0.767189 2.11016 1.02734L5.55 4.49609L8.98984 1.05625C9.25 0.767189 9.68359 0.767189 9.97266 1.05625C10.2328 1.31641 10.2328 1.75 9.97266 2.01016Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default XMark;
