import { RuleSourceID } from './ruleTokenInputData';

export const isGenderBasedRuleSource = (ruleSource: RuleSourceID) =>
  ruleSource.includes('primaryGender');

export const isNumberBasedRuleSource = (ruleSource: RuleSourceID) => {
  return [
    'workers.ai-realtime.primaryAttentionTime',
    'workers.ai-realtime.primaryAge',
    'workers.ai-realtime.totalDetections',
  ].includes(ruleSource);
};

export const isPercentBasedRuleSource = (ruleSource: RuleSourceID) => {
  return [
    ruleSource.includes('male'),
    ruleSource.includes('female'),
    ruleSource.includes('unknown'),
    ruleSource.includes('age'),
  ].includes(true);
};

export const isAIRuleSource = (ruleSource: RuleSourceID) => {
  return ruleSource.includes('ai-');
};

export const isRXRuleSource = (ruleSource: RuleSourceID) => {
  return ruleSource.includes('perchview');
};

export const isDateRuleSource = (ruleSource: RuleSourceID) => {
  return [
    'target.tags.start_time',
    'target.tags.start_date',
    'target.tags.end_date',
    'target.tags.end_time',
    'time_and_date.curr_time',
    'time_and_date.curr_date',
  ].includes(ruleSource);
};
