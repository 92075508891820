import { EngagementByDay } from '@raydiant/api-client-js';
import { abbreviateNumber } from 'js-abbreviation-number';
import moment from 'moment';

export const getTotalInteractions = (data: EngagementByDay[]) => {
  if (data.length === 0) return 0;

  return data
    .map((value) => (value.clicks || 0) + (value.pickups || 0))
    .reduce((current, next) => {
      return current + next;
    });
};

export const getTotalSessions = (data: EngagementByDay[]) => {
  if (data.length === 0) return 0;

  return data
    .map((value) => value.sessions || 0)
    .reduce((current, next) => {
      return current + next;
    });
};

export const toPercent = (value: number | string) =>
  Math.round(Number(value) * 100);

export const getPercentChange = (current: number, previous: number) => {
  if (current === 0) return '0%';

  return toPercent((current - previous) / current).toString() + '%';
};

export const getDailyAverageInteractions = (data: EngagementByDay[]) => {
  const dataWithValue: EngagementByDay[] =
    data?.filter((item) => !(item.clicks === null && item.pickups === null)) ||
    [];

  const count = dataWithValue.length;

  if (count === 0) return 0;

  return getAverage(getTotalInteractions(dataWithValue), count);
};

export const getDailyAverageSessions = (data: EngagementByDay[]) => {
  const dataWithValue: EngagementByDay[] =
    data?.filter((item) => item.sessions !== null) || [];

  const count = dataWithValue.length;

  if (count === 0) return 0;
  return getAverage(getTotalSessions(dataWithValue), count);
};

export const getDisplayValue = (value: number | string, unit: string = '') => {
  if (!value && value !== 0) {
    return '-';
  }

  if (typeof value === 'string') {
    return unit ? `${value}${unit}` : value;
  }

  if (value <= 100000) {
    return unit ? `${value?.toLocaleString()}${unit}` : value?.toLocaleString();
  }

  const abbreviatedNumber = `${abbreviateNumber(value, 0).toUpperCase()}${
    value > 100000 && hasRemainder(value) ? '+' : ''
  }`;

  return unit ? `${abbreviatedNumber}${unit}` : abbreviatedNumber;
};

const hasRemainder = (value: number) => (value / 1000) % 1 !== 0;

export const groupByWeek = (data: EngagementByDay[]) => {
  return data.reduce<{
    [key: string]: EngagementByDay[];
  }>((acc, data) => {
    const yearWeek = moment(data.timestamp).week();

    if (!acc[yearWeek]) {
      acc[yearWeek] = [];
    }

    acc[yearWeek].push(data);

    return acc;
  }, {});
};

const getAverage = (total: number, count: number) => Math.round(total / count);

export const getWeeklyAverageInteractions = (data: EngagementByDay[]) => {
  if (data.length === 0) return 0;

  const weeks = groupByWeek(data);
  const weeksCount = Object.keys(weeks).length;
  if (weeksCount === 0) return 0;

  const total = Object.keys(weeks)
    .map((key) => {
      const count = weeks[key].length;
      if (count === 0) return 0;

      return getAverage(getTotalInteractions(weeks[key]), count);
    })
    .reduce((current, next) => current + next);

  return getAverage(total, weeksCount);
};

export const getWeeklyAverageSessions = (data: EngagementByDay[]) => {
  if (data.length === 0) return 0;

  const weeks = groupByWeek(data);
  const weeksCount = Object.keys(weeks).length;
  if (weeksCount === 0) return 0;

  const total = Object.keys(weeks)
    .map((key) => {
      const count = weeks[key].length;
      if (count === 0) return 0;

      return getAverage(getTotalSessions(weeks[key]), count);
    })
    .reduce((current, next) => current + next);

  return getAverage(total, weeksCount);
};

export const getTotalClicks = (data: EngagementByDay[]) => {
  if (data.length === 0) return 0;

  return data
    .map((item) => item.clicks || 0)
    .reduce((acc, total) => acc + total);
};

export const getTotalPickups = (data: EngagementByDay[]) => {
  if (data.length === 0) return 0;

  return data
    .map((item) => item.pickups || 0)
    .reduce((acc, total) => acc + total);
};

export const getInteractionConversions = (data: EngagementByDay[]) => {
  if (data.length === 0) return 0;

  const totalClicks = getTotalClicks(data);
  const totalPickups = getTotalPickups(data);

  if (totalPickups === 0) return 0;

  return totalClicks / totalPickups;
};

export const getAverageDistibution = (data: EngagementByDay[]) => {
  if (data.length === 0) return [0, 0];

  const totalClicks = getTotalClicks(data);
  const totalPickups = getTotalPickups(data);

  const total = totalClicks + totalPickups;

  const pickupPercentage = Math.round((totalPickups / total) * 100);
  const clicksPercentage = 100 - pickupPercentage;

  return [pickupPercentage, clicksPercentage];
};

export const capitalize = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const transformToLabelText = (value: string) =>
  value
    .split(' ')
    .map((item) => capitalize(item))
    .join(' ');
