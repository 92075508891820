import Link from 'raydiant-elements/core/Link';
import AddIcon from '../../../components/AddIcon';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import LocationInput from './LocationInput';
import InputLabel from 'raydiant-elements/core/InputLabel';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import Button from 'raydiant-elements/core/Button';
import { Grid } from '@material-ui/core';
import useStyles from './AddLocationInputManager.styles';

export interface AddLocationInputManagerProps {
  locationIds: string[];
  value: string;
  error?: boolean;
  errorHelperText?: string;
  onChange: (locationId: string) => void;
  onAddIconClick: (locationId: string) => void;
  onDeleteIconClick: (selectedLocationId: string) => void;
  onApplyClick: () => void;
}

const AddLocationInputManager = ({
  locationIds,
  value,
  error = false,
  errorHelperText = '',
  onChange,
  onAddIconClick,
  onDeleteIconClick,
  onApplyClick,
}: AddLocationInputManagerProps) => {
  const classes = useStyles();
  const hasLocationsToApply = locationIds.length > 0;

  return (
    <Grid>
      <Grid item>
        <InputLabel>Add location</InputLabel>
        <LocationInput
          key="addLocation"
          placeholder="ex: 9103c28a-d7fa-48db-a5e5-1449a45ade4f"
          icon={<AddIcon className={classes.addIcon} />}
          value={value}
          onChange={onChange}
          onIconClick={onAddIconClick}
          error={error}
          errorHelperText={errorHelperText}
        />
        {locationIds.map((locationId) => (
          <LocationInput
            key={locationId}
            icon={<DeleteOutlineIcon />}
            value={locationId}
            disabled={true}
            onChange={() => {}}
            onIconClick={onDeleteIconClick}
          />
        ))}
        <InputHelperText indent>
          <Link href="https://support.raydiant.com/" target="_blank">
            What's a GUID?
          </Link>
        </InputHelperText>
      </Grid>
      <Grid item container justifyContent="flex-end" xs={12} md={12}>
        <Grid item xs={4} md={3} className={classes.buttonRow}>
          <Button
            color="primary"
            fullWidth
            onClick={() => onApplyClick()}
            disabled={!hasLocationsToApply}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddLocationInputManager;
