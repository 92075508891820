import moment from 'moment';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Device, RecentDeviceError } from '@raydiant/api-client-js';
import { ContentWarning } from './getDeviceContentWarning';
import { selectProofOfPublishEnabled } from '../../selectors/user';
import * as paths from '../../routes/paths';
import * as presentationPageActions from '../PresentationPage/actions';
import Popover from '@raydiant/raydial/components/Popover';
import CircleCheck from './Icons/CircleCheck';
import CircleExclamation from './Icons/CircleExclamation';
import Link from '@raydiant/raydial/components/Link';

interface DeviceStatusPopoverProps {
  device: Device;
  isOnline: boolean;
  lastHeartbeatAt: string | null;
  contentWarning: ContentWarning | null;
  deviceError: RecentDeviceError | null;
}

const formatDateForOfflineInfo = (dateStr: string) => (
  <>
    <span style={{ whiteSpace: 'nowrap' }}>
      {moment(dateStr).format('h:mm a')}
    </span>
    ,{' '}
    <span style={{ whiteSpace: 'nowrap' }}>
      {moment(dateStr).format('MMMM D YYYY')}
    </span>
  </>
);

const formatDate = (dateStr: string) => (
  <>
    <span style={{ whiteSpace: 'nowrap' }}>
      {moment(dateStr).format('h:mm a')}
    </span>
    ,{' '}
    <span style={{ whiteSpace: 'nowrap' }}>
      {moment(dateStr).format('MMM D')}
    </span>
  </>
);

const DeviceStatusPopover = ({
  device,
  isOnline,
  lastHeartbeatAt,
  contentWarning,
  deviceError,
}: DeviceStatusPopoverProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isProofOfPublishEnabled = useSelector(selectProofOfPublishEnabled);

  const openPresentationBuilder = useCallback(
    (presentationId: string) => {
      dispatch(
        presentationPageActions.clearUnsavedPresentation(presentationId),
      );

      history.push(
        paths.editPresentation(presentationId, {
          backTo: paths.screens(),
          backToLabel: 'Back to Screens',
          saveTo: paths.screens(),
        }),
      );
    },
    [dispatch, history],
  );

  return (
    <Popover placement="bottom" className="w-[320px] px-4 py-2.5">
      <div>
        <div className="flex flex-col justify-center text-lg font-regular text-black gap-1">
          {isOnline && (
            <div className="flex py-2.5">
              <CircleCheck className="text-green-600 mt-0.5" />
              <div className="overflow-hidden ml-3">Connectivity is good!</div>
            </div>
          )}
          {!isOnline && (
            <>
              <div className="flex py-2.5">
                <CircleExclamation className="text-red-600 mt-0.5" />
                {lastHeartbeatAt && (
                  <div className="overflow-hidden ml-3">
                    Device offline since{' '}
                    {formatDateForOfflineInfo(lastHeartbeatAt)}
                  </div>
                )}
                {!lastHeartbeatAt && (
                  <div className="overflow-hidden ml-3">Device offline</div>
                )}
              </div>
            </>
          )}
          {deviceError && (
            <div>
              <div className="flex py-2.5">
                <CircleExclamation className="text-red-600 mt-0.5" />
                <div className="overflow-hidden ml-3">
                  Error at {formatDate(deviceError.timestamp)}:{' '}
                  <div
                    className="decoration-inherit text-blue-600 cursor-pointer"
                    onClick={() =>
                      openPresentationBuilder(deviceError.presentationId)
                    }
                  >
                    {deviceError.presentationName}
                  </div>
                </div>
              </div>
              <Link
                className="pl-7.5"
                target="_blank"
                href="https://support.raydiant.com/hc/en-us/articles/360058191672"
              >
                Learn more
              </Link>
            </div>
          )}
          {/* Only show content warning if there is no device error to 
          have a maximum of 2 items in the popover. */}
          {contentWarning && !deviceError && (
            <div>
              <div className="flex py-2.5">
                <CircleExclamation className="text-red-600 mt-0.5" />
                <div className="overflow-hidden ml-3">
                  {contentWarning.message}
                </div>
              </div>

              <Link
                className="pl-7.5"
                target="_blank"
                href={
                  device.model === 'Poly'
                    ? // TODO: Replace article with Poly upgrade article
                      'https://support.raydiant.com/hc/en-us/articles/4401806369556'
                    : 'https://support.raydiant.com/hc/en-us/articles/360054538031#limitations'
                }
              >
                Learn more
              </Link>
            </div>
          )}
          {isProofOfPublishEnabled && (
            <>
              {!contentWarning && !deviceError && isOnline && (
                <div className="flex py-2.5">
                  <CircleCheck className="text-green-600 mt-0.5" />
                  <div className="overflow-hidden ml-3">Playback is good!</div>
                </div>
              )}
              {!contentWarning && !deviceError && !isOnline && (
                <div className="flex py-2.5">
                  <CircleExclamation className="text-red-600 mt-0.5" />
                  <div className="overflow-hidden ml-3">
                    Playback status is unknown
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Popover>
  );
};

export default DeviceStatusPopover;
