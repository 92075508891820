import { useState, useEffect } from 'react';
import AddIntegrationColumn from './AddIntegrationColumn';
import AddLocationInputManager from './AddLocationInputManager';
import {
  mockIntegrations,
  defaultLocationInfo,
  validateLocationId,
} from '../IntegrationPageUtils';

export interface AddLocationProps {
  selectedIntegration: any;
  onApplyClick: (integration: any) => void;
}

const AddLocation = ({
  selectedIntegration,
  onApplyClick,
}: AddLocationProps) => {
  const [locationIds, setLocationIds] = useState<string[]>([]);
  const [newLocationId, setNewLocationId] = useState('');
  const [error, setError] = useState('');

  const handleAddLocationId = (locationId: string) => {
    if (!newLocationId) return;

    if (validateLocationId(locationIds, newLocationId)) {
      setError('GUID already exists');
      return;
    }

    const updatedLocationIds = [locationId, ...locationIds];
    setLocationIds(updatedLocationIds);
    setNewLocationId('');
    setError('');
  };

  const handleDeleteLocationId = (selectedLocationId: string) => {
    const updatedLocationIds = locationIds.filter(
      (locationId) => locationId !== selectedLocationId,
    );
    setLocationIds(updatedLocationIds);
  };

  // will be removed added for the testing purpose
  const handleApplyIntegration = () => {
    const addedLocations = locationIds.map((locationId) => ({
      ...defaultLocationInfo,
      restaurant_guid: locationId,
    }));
    const addedIntegration = {
      ...mockIntegrations[0],
      locations: addedLocations,
    };
    onApplyClick(addedIntegration);
  };

  // Effects
  useEffect(() => {
    setLocationIds([]);
    setNewLocationId('');
    setError('');
  }, []);

  if (!selectedIntegration) return null;
  return (
    <AddIntegrationColumn heading="your guid">
      <AddLocationInputManager
        locationIds={locationIds}
        value={newLocationId}
        error={!!error}
        errorHelperText={error}
        onChange={setNewLocationId}
        onAddIconClick={handleAddLocationId}
        onDeleteIconClick={handleDeleteLocationId}
        onApplyClick={handleApplyIntegration}
      />
    </AddIntegrationColumn>
  );
};

export default AddLocation;
