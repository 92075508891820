import { useTranslation } from 'react-i18next';
import ScreensFilterControl from '../ScreensFilterControl';
import ScreensFilterDropDownActions from '../ScreensFilterDropDownActions';

type DeviceScreenOrientationProps = {
  className?: string;
  onChange: (value: DeviceScreenOrientationQuery) => void;
  value: DeviceScreenOrientationQuery;
};

export type DeviceScreenOrientationQuery = {
  left: boolean;
  right: boolean;
  normal: boolean;
};

const DeviceScreenOrientation = ({
  onChange,
  value,
}: DeviceScreenOrientationProps) => {
  const { t } = useTranslation(['devices']);

  const hasValue = Object.values(value).some(Boolean);

  return (
    <ScreensFilterControl
      footer={
        hasValue && (
          <ScreensFilterDropDownActions
            onClearAll={() =>
              onChange({
                left: false,
                right: false,
                normal: false,
              })
            }
          />
        )
      }
      title={
        <p className="font-[500] text-[16px]">
          {t('screens.screenOrientation')}
        </p>
      }
    >
      <form>
        <ul className="flex flex-col gap-2">
          <li className="flex items-center" key={'left'}>
            <input
              checked={value.left}
              name="screenOrientation"
              onChange={(e) => {
                onChange({
                  left: e.target.checked,
                  normal: false,
                  right: false,
                });
              }}
              type="radio"
              value="left"
            />
            <label className="text-base text-[#79797f]">
              {t(`screens.portraitLeft`)}
            </label>
          </li>
          <li className="flex items-center" key={'right'}>
            <input
              checked={value.right}
              name="screenOrientation"
              onChange={(e) => {
                onChange({
                  right: e.target.checked,
                  normal: false,
                  left: false,
                });
              }}
              type="radio"
              value="right"
            />
            <label className="text-base text-[#79797f]">
              {t(`screens.portraitRight`)}
            </label>
          </li>
          <li className="flex items-center" key={'normal'}>
            <input
              checked={value.normal}
              name="screenOrientation"
              onChange={(e) => {
                onChange({
                  normal: e.target.checked,
                  right: false,
                  left: false,
                });
              }}
              type="radio"
              value="normal"
            />
            <label className="text-base text-[#79797f]">
              {t(`screens.landscape`)}
            </label>
          </li>
        </ul>
      </form>
    </ScreensFilterControl>
  );
};

export default DeviceScreenOrientation;
