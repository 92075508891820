import { QueryClient } from 'react-query';
import * as keys from './queryKeys';

export { keys };
export * from './queryCache';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      // By default cache all queries for 10 seconds, this assumes we are using
      // optimistic updates or manually invalidating if we need to refetch a query
      // before the cache expires.
      staleTime: 10000,
      // Don't refetch on focus by default.
      refetchOnWindowFocus: false,
      // Don't rerender when data becomes stale by default.
      notifyOnChangePropsExclusions: ['isStale'],
    },
  },
});

export default client;
