import CallToActionCarousel from '../../AnalyticsCallToAction/CallToActionCarousel';
import VIOnlyIllustration from '../assets/cta-desktop.png';
import VIOnlyIllustrationMobile from '../assets/cta-mobile.png';

const carouselItems = [
  {
    label: 'Verify the content playing on screen',
  },
  {
    label: 'See which content best converts',
  },
  {
    label: 'Identify best target demographics',
  },
];

const CallToAction = () => {
  return (
    <CallToActionCarousel
      header="Integrate digital signage with Visitor InSight"
      content={carouselItems}
      backgroundColor="linear-gradient(133deg, #08177a -8%, #0d60b2 140%)"
      desktopBackgroundImage={VIOnlyIllustration}
      mobileBackgroundImage={VIOnlyIllustrationMobile}
      type="content-engagement"
      href="https://www.raydiant.com/solutions/digital-signage"
    />
  );
};

export default CallToAction;
