import { Profile } from '@raydiant/api-client-js';
import cn from 'classnames';
import { getInitials } from '../../../utilities/user';

export type AvatarProps = {
  className?: string;
  user: Pick<Profile, 'name' | 'thumbnailUrl'>;
  size?: 'small' | 'large';
  showThumbnail?: boolean;
  type?: 'circle' | 'square';
};

const DEFAULT_IMAGE_SIZE = 500;

const Avatar = ({
  className,
  size = 'small',
  type = 'circle',
  user,
  showThumbnail = false,
}: AvatarProps) => {
  return (
    <div
      className={cn(
        'text-white flex items-center justify-center bg-black avatar',
        type === 'circle' ? 'rounded-full' : '',
        type === 'square' ? 'rounded-2xl' : '',
        size === 'large' ? 'large' : '',
        className,
      )}
    >
      <>
        {showThumbnail && user?.thumbnailUrl && (
          <img
            src={user?.thumbnailUrl}
            alt={user?.name || ''}
            height={DEFAULT_IMAGE_SIZE}
            width={DEFAULT_IMAGE_SIZE}
          />
        )}
        {!showThumbnail && (
          <span className="initials">
            {getInitials({
              name: user?.name || '',
            })}
          </span>
        )}
      </>
    </div>
  );
};

export default Avatar;
