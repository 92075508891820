import { BarChart, LineChart } from 'echarts/charts';
import ChartCard from '../ChartCard/ChartCard';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import Hidden from 'raydiant-elements/layout/Hidden';
import {
  CHART_DEFAULT_AXIS_OPTIONS,
  CHART_DEFAULT_OPTIONS,
  CHART_DEFAULT_SERIES,
} from '../utils';
import { days, getAverageGenderDistributionChartData } from './utils';
import { WeekdayHourTraffic } from '@raydiant/api-client-js';
import { Popover } from '@headlessui/react';
import QuestionMarkIcon from '../../../components/QuestionMarkIcon';
import PopoverButton from '../../../components/Popover/PopoverButton';
import { useTranslation } from 'react-i18next';
import ChartToolTip, { EChartsToolTipParams } from '../ChartToolTip';
import { renderToStaticMarkup } from 'react-dom/server';

echarts.use([BarChart]);
echarts.use([LineChart]);

const colors = ['#487FB1', '#7AD1F4', '#FDDA5F'];

interface AverageGenderDistributionChartProps {
  data: WeekdayHourTraffic[];
}

const AverageGenderDistributionChart = ({
  data,
}: AverageGenderDistributionChartProps) => {
  const { t } = useTranslation();

  const chartData = getAverageGenderDistributionChartData(data);

  const options = {
    ...CHART_DEFAULT_OPTIONS,
    color: colors,
    tooltip: {
      ...CHART_DEFAULT_OPTIONS.tooltip,
      formatter: (params: EChartsToolTipParams[]) => {
        const modifiedParams: EChartsToolTipParams[] = params.map((item) => {
          if (item.seriesName === 'Male' || item.seriesName === 'Female') {
            return { ...item, value: `${item.value}%` };
          } else {
            return item;
          }
        });

        return renderToStaticMarkup(
          <ChartToolTip
            header={
              <p className="font-bold text-sm px-2 py-1 capitalize text-black">
                {params[0]?.axisValueLabel}
              </p>
            }
            values={modifiedParams}
          />,
        );
      },
    },
    toolbox: {
      feature: {
        saveAsImage: { show: true },
      },
    },
    legend: {
      ...CHART_DEFAULT_OPTIONS.legend,
      data: ['Male', 'Female', 'Total Traffic'],
      icon: 'square',
    },
    xAxis: [
      {
        ...CHART_DEFAULT_AXIS_OPTIONS.xAxis,
        // prettier-ignore
        data: days,
        name: t('analytics.time').toUpperCase(),
      },
    ],
    yAxis: [
      {
        ...CHART_DEFAULT_AXIS_OPTIONS.yAxis,
        name: 'numbers'.toUpperCase(),
        position: 'right',
        alignTicks: true,
      },
      {
        ...CHART_DEFAULT_AXIS_OPTIONS.yAxis,
        name: 'percentage'.toUpperCase(),
        position: 'left',
        axisLabel: {
          ...CHART_DEFAULT_AXIS_OPTIONS.yAxis.axisLabel,
          formatter: (value: string) => {
            return `${value}%`;
          },
        },
      },
    ],
    series: [
      {
        name: 'Female',
        type: 'bar',
        stack: 'total',
        yAxisIndex: 1,
        data: chartData.female,
      },
      {
        name: 'Male',
        type: 'bar',
        stack: 'total',
        yAxisIndex: 1,
        data: chartData.male,
      },
      {
        ...CHART_DEFAULT_SERIES.line,
        name: 'Total Traffic',
        type: 'line',
        yAxisIndex: 0,
        data: chartData.otsFace,
        showSymbol: false,
        lineStyle: {
          color: '#FDDA5F',
          width: 3,
        },
      },
    ],
  };

  const mobileOptions = {
    ...options,
    legend: {
      ...CHART_DEFAULT_OPTIONS.legend,
      type: 'scroll',
    },
  };

  return (
    <ChartCard
      subTitle={''}
      title={
        <div className="flex gap-3 items-center">
          <h2 className="text-black text-xl font-bold">
            {t('analytics.trafficAwarenessPage.avgGenderDistribution.title')!}
          </h2>
          <Popover className="relative">
            <PopoverButton icon={<QuestionMarkIcon />} />
            <Popover.Panel className="absolute z-10 card popover drop-shadow-xl">
              <div className="overflow-hidden">
                <p className="mb-3">
                  {t(
                    'analytics.trafficAwarenessPage.avgGenderDistribution.tooltip',
                  )}
                </p>
                <a
                  href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('analytics.learnmore')}
                </a>
              </div>
            </Popover.Panel>
          </Popover>
        </div>
      }
      showTimeInterval={false}
    >
      <div className="min-w-full min-h-full">
        <Hidden xsDown>
          <ReactECharts
            style={{ height: '410px' }}
            option={options}
            onChartReady={(chart) => chart.resize()}
          />
        </Hidden>
        <Hidden smUp>
          <ReactECharts
            style={{ height: '410px' }}
            option={mobileOptions}
            onChartReady={(chart) => chart.resize()}
          />
        </Hidden>
      </div>
    </ChartCard>
  );
};

export default AverageGenderDistributionChart;
