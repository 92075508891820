import Input from 'raydiant-elements/core/Input';
import { RuleConstantInputPropsType } from './RuleConstantInput';

export type PercentRuleConstantInputProps = RuleConstantInputPropsType<{
  type: 'percent';
  value: number;
  onChange: (value: number) => void;
}>;

const PercentRuleConstantInput = ({
  onChange,
  value,
}: PercentRuleConstantInputProps) => {
  return (
    <Input
      type="number"
      value={value ? value.toString() : ''}
      onChange={(value) => onChange(parseInt(value, 10))}
      max={100}
      min={0}
    />
  );
};

export default PercentRuleConstantInput;
