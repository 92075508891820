import { SVGProps } from 'react';

interface EditProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const Edit = ({ size = 18, ...props }: EditProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.1426 2.08398H15.1094C14.8105 1.78516 14.3125 1.78516 14.0137 2.08398L13.0176 3.04688L14.5781 4.60742L15.541 3.61133C15.8398 3.3125 15.8398 2.81445 15.541 2.48242L15.1426 2.08398ZM7.43945 8.6582C7.33984 8.72461 7.27344 8.85742 7.24023 8.99023L6.67578 10.916L8.63477 10.3848C8.76758 10.3516 8.86719 10.2852 8.9668 10.1855L13.4492 5.70312L11.9219 4.17578L7.43945 8.6582ZM12.8848 0.955078C13.8145 0.0253906 15.3086 0.0253906 16.2383 0.955078L16.6699 1.38672C17.5996 2.31641 17.5996 3.81055 16.6699 4.74023L10.0957 11.3145C9.83008 11.5801 9.46484 11.7793 9.06641 11.9121L5.74609 12.8418C5.48047 12.9414 5.18164 12.875 4.98242 12.6426C4.75 12.4434 4.68359 12.1445 4.75 11.8789L5.71289 8.55859C5.8125 8.16016 6.04492 7.79492 6.31055 7.5293L12.8848 0.955078ZM3.42188 2.25H7.14062C7.57227 2.25 7.9375 2.61523 7.9375 3.04688C7.9375 3.51172 7.57227 3.84375 7.14062 3.84375H3.42188C2.6582 3.84375 2.09375 4.44141 2.09375 5.17188V14.2031C2.09375 14.9668 2.6582 15.5312 3.42188 15.5312H12.4531C13.1836 15.5312 13.7812 14.9668 13.7812 14.2031V10.4844C13.7812 10.0527 14.1133 9.6875 14.5781 9.6875C15.0098 9.6875 15.375 10.0527 15.375 10.4844V14.2031C15.375 15.8301 14.0469 17.125 12.4531 17.125H3.42188C1.79492 17.125 0.5 15.8301 0.5 14.2031V5.17188C0.5 3.57812 1.79492 2.25 3.42188 2.25Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Edit;
