import cn from 'classnames';
import { Grid } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Paper from 'raydiant-elements/core/Paper';
import Text from 'raydiant-elements/core/Text';
import useStyles from './IntegrationCard.styles';

export interface IntegrationCardProps {
  integration?: any;
  empty?: boolean;
  handleIconClick?: (integration: any) => void;
}

const IntegrationCard = ({
  integration,
  empty,
  handleIconClick,
}: IntegrationCardProps) => {
  const classes = useStyles();

  const renderDefaultCard = () => {
    return (
      <Grid item container alignItems="center">
        <Text>You have no Integrations added</Text>
      </Grid>
    );
  };

  const renderCardContent = () => {
    return (
      <>
        <Grid item container>
          <Grid item>
            <img
              src={integration.thumbnail_url}
              alt={`${integration.name}-thumbnail`}
              height="35"
            />
          </Grid>
          <Grid item container alignItems="center">
            <CheckCircleIcon />
            <Text className={classes.connectionStatus}>Connected</Text>
          </Grid>
        </Grid>
        <Grid item>
          <IconButton
            onClick={handleIconClick && (() => handleIconClick(integration.id))}
          >
            <EditIcon />
          </IconButton>
        </Grid>
      </>
    );
  };

  return (
    <Paper className={cn(classes.root)}>
      {empty ? renderDefaultCard() : renderCardContent()}
    </Paper>
  );
};

export default IntegrationCard;
