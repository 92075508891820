import cn from 'classnames';
import { Grid } from '@material-ui/core';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import Text from 'raydiant-elements/core/Text';
import { Theme } from 'raydiant-elements/theme';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      marginBottom: theme.spacing(3),
    },
    heading: {
      fontWeight: 600,
      marginBottom: theme.spacing(1),
    },
    description: {
      color: 'rgba(0, 0, 0, 0.6)',
      letterSpacing: '0.49px',
    },
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'fill',
      overflow: 'hidden',
    },
    contentWrapper: {
      paddingRight: theme.spacing(2),
    },
    text: {
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    },
  });
});

export interface ConnectionSettingsStepCardProps {
  stepNumber: number;
  description: string;
  imageUrl: string;
  imageAlt: string;
}

const ConnectionSettingsStepCard = ({
  stepNumber,
  description,
  imageUrl,
  imageAlt,
}: ConnectionSettingsStepCardProps) => {
  const classes = useStyles();
  return (
    <Grid container justifyContent="space-between" className={classes.root}>
      <Grid item xs={7} md={7} className={classes.contentWrapper}>
        <Grid item className={cn(classes.heading, classes.description)}>
          <Text>Step {stepNumber}.</Text>
        </Grid>
        <Grid item className={classes.description}>
          <Text className={classes.text}>{description}</Text>
        </Grid>
      </Grid>
      <Grid item xs={5} md={5}>
        <img src={imageUrl} alt={imageAlt} className={classes.img} />
      </Grid>
    </Grid>
  );
};

export default ConnectionSettingsStepCard;
