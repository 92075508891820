import { FC, useState } from 'react';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import { Tag } from '@raydiant/api-client-js';
import Column from 'raydiant-elements/layout/Column';
import Row from 'raydiant-elements/layout/Row';
import TagInput from '../TagInput';
import {
  tagData,
  tagOptionGroups,
  TagKey,
  TagOption,
  TagOptionGroup,
} from './tagManagerData';
import Select from 'raydiant-elements/core/Select';
import InputLabel from 'raydiant-elements/core/InputLabel';
import { MenuItem } from '@material-ui/core';
import { Hidden } from 'raydiant-elements/layout/Hidden/Hidden';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import NestedMenuItem from '../NestedMenuItem';
export interface TagManagerProps {
  tags: Tag[];
  onChange: (value: Tag[]) => void;
  hideSchedulingTags?: boolean;
}

const useStyles = makeStyles(() => {
  return createStyles({
    menuItem: {
      display: 'flex',
      justifyContent: 'space-between',
      minWidth: '200px',
    },
  });
});

export const mapTypes = (key: string) => {
  switch (key) {
    case 'end_date':
    case 'start_date':
      return 'date';
    case 'end_time':
    case 'start_time':
      return 'time';
    case 'days_of_week':
      return 'days_of_week';
    default:
      return 'text';
  }
};

export const defaultTagValue = (key: string) =>
  mapTypes(key) === 'date' ? new Date().toDateString() : '';

export const showAllRootMenuItems = (
  group: TagOptionGroup,
  hideSchedulingTags = true,
) => {
  if (hideSchedulingTags) {
    return group.name !== 'Schedule';
  }

  return true;
};

export const isTagAdded = (key: string, tags: Tag[]) => {
  return tags.some((t) => t.key === key);
};

const TagManager: FC<TagManagerProps> = ({
  tags,
  hideSchedulingTags,
  onChange,
}) => {
  const classes = useStyles();
  // State

  const [selectedTagKey, setSelectedTagKey] = useState<string | null>(null);
  const [open, setOpen] = useState(false);

  // Callbacks

  const handleDelete = (tag: Tag) => {
    const updatedTags = tags.filter((t) => t.key !== tag.key);
    onChange(updatedTags);
  };

  const handleEdit = (tag: Tag, value: Tag['value']) => {
    const updatedTags = tags.map((t) => {
      if (t.key === tag.key) {
        return { ...t, value };
      }
      return t;
    });

    onChange(updatedTags as any);
  };

  const handleAddTag = (key: TagKey) => {
    const updatedTags: Tag[] = [
      ...tags,
      {
        key,
        label: tagData[key].label,
        value: defaultTagValue(key),
        type: mapTypes(key) as any,
        // NOTE: These values are populated by the API and technically not required but
        // here to appease TS. There's probably a better way to structure the types.
        id: '',
        resourceId: '',
        createdAt: '',
      },
    ];
    onChange(updatedTags);
    setSelectedTagKey(key);
  };

  const toggleMenu = () => setOpen(open ? false : true);

  // Render

  const renderMenuItem = (
    option: TagOption | TagOptionGroup,
    index: number,
  ) => {
    if ('groups' in option) {
      return (
        <NestedMenuItem
          className={classes.menuItem}
          disabled={option.groups.every((group) =>
            group.options.every((tagKey) => isTagAdded(tagKey, tags)),
          )}
          key={index}
          label={option.name}
          parentMenuOpen={open}
        >
          {option.groups.map(renderMenuItem)}
        </NestedMenuItem>
      );
    } else {
      return (
        <NestedMenuItem
          className={classes.menuItem}
          disabled={option.options.every((tagKey) => isTagAdded(tagKey, tags))}
          key={index}
          label={option.name}
          parentMenuOpen={open}
        >
          {option.options.map((tagKey, index) => (
            <MenuItem
              disabled={isTagAdded(tagKey, tags)}
              key={index}
              value={tagKey}
              selected={tagKey === selectedTagKey}
              onClick={() => {
                handleAddTag(tagKey);
                toggleMenu();
              }}
            >
              {tagData[tagKey].label}
            </MenuItem>
          ))}
        </NestedMenuItem>
      );
    }
  };

  const renderDesktopMenu = () => (
    <div>
      <InputLabel>Add a Tag</InputLabel>
      <Select
        native={false}
        open={open}
        onChange={toggleMenu}
        onClose={toggleMenu}
        onOpen={toggleMenu}
        value={selectedTagKey ?? ''}
      >
        {tagOptionGroups
          .filter((tag) => showAllRootMenuItems(tag, hideSchedulingTags))
          .map(renderMenuItem)}
      </Select>
    </div>
  );

  const renderMobileMenuItem = (
    option: TagOption | TagOptionGroup,
    index: number,
  ): any => {
    if ('groups' in option) {
      return option.groups.map(renderMobileMenuItem);
    } else {
      return option.options.map((tagKey) => (
        <option disabled={isTagAdded(tagKey, tags)} key={index} value={tagKey}>
          {tagData[tagKey].label}
        </option>
      ));
    }
  };

  const renderMobileMenu = () => (
    <div>
      <InputLabel>Add a Tag</InputLabel>
      <Select
        native={true}
        onChange={(event) => handleAddTag(event as TagKey)}
        value=""
      >
        <option value=""></option>
        {tagOptionGroups
          .filter((tag) => showAllRootMenuItems(tag, hideSchedulingTags))
          .map(renderMobileMenuItem)}
      </Select>
    </div>
  );

  return (
    <Column doubleMargin>
      {tags.length > 0 && (
        <Column>
          {tags.map((tag) => (
            <Row key={tag.key} halfMargin center>
              <TagInput
                {...tag}
                editing={selectedTagKey === tag.key}
                onEdit={(editing) =>
                  setSelectedTagKey(editing ? tag.key : null)
                }
                onChange={(value: Tag['value']) => handleEdit(tag, value)}
              />
              <div>
                <IconButton
                  size="small"
                  edge="end"
                  onClick={() => handleDelete(tag)}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </div>
            </Row>
          ))}
        </Column>
      )}
      <>
        <Hidden xsDown>{renderDesktopMenu()}</Hidden>
        <Hidden smUp>{renderMobileMenu()}</Hidden>
      </>
    </Column>
  );
};

export default TagManager;
