import {
  ApiClientError,
  GetProductsQuery,
  Paginated,
  Product,
} from '@raydiant/api-client-js';
import raydiant from '../clients/miraClient';
import useProtectedQuery from './useProtectedQuery';
import { keys } from '../queryClient';

export default function useCatalogItems(
  catalogId: string,
  opts: GetProductsQuery,
) {
  const query = useProtectedQuery<Paginated<Product>, ApiClientError>(
    keys.catalogItems(opts),
    () => raydiant.getProducts(catalogId, opts),
  );

  return query;
}
