import { FC } from 'react';
import Row from 'raydiant-elements/layout/Row';
import TagIcon from 'raydiant-elements/icons/Tag';
import useStyles from './TagInput.styles';
import TagInputTextType from './TagInputTextType';
import TagInputTimeType from './TagInputTimeType';
import TagInputDateType from './TagInputDateType';
import TagInputDaysType from './TagInputDaysType';
import { Day } from '@raydiant/api-client-js';
import moment from 'moment';

type TagInputPropsType<
  T extends { type: string; value: any; onChange: (value: any) => void },
> = {
  label: string;
  labelOnly?: boolean;
  showIcon?: boolean;
  value: T['value'];
  type: T['type'];
  editing?: boolean;
  onEdit: (editing: boolean) => void;
  onChange: T['onChange'];
};

type TagInputTextProps = TagInputPropsType<{
  type: 'text';
  value: string;
  onChange: (value: string) => void;
}>;

type TagInputDateProps = TagInputPropsType<{
  type: 'date';
  value: string;
  onChange: (value: string) => void;
}>;

type TagInputTimeProps = TagInputPropsType<{
  type: 'time';
  value: string;
  onChange: (value: string) => void;
}>;

type TagInputDayProps = TagInputPropsType<{
  type: 'days_of_week';
  value: Day[];
  onChange: (value: Day[]) => void;
}>;

export type TagInputProps =
  | TagInputTextProps
  | TagInputTimeProps
  | TagInputDateProps
  | TagInputDayProps;

const TagInput: FC<TagInputProps> = ({
  editing,
  onEdit,
  labelOnly = false,
  showIcon = true,
  ...tagProps
}) => {
  const classes = useStyles();

  // Render

  if (editing) {
    switch (tagProps.type) {
      case 'date':
        return (
          <TagInputDateType
            {...tagProps}
            onChange={(value) => {
              if (value) {
                tagProps.onChange(value);
              }
              onEdit(false);
            }}
          />
        );
      case 'days_of_week':
        return (
          <TagInputDaysType
            {...tagProps}
            onChange={(value) => {
              tagProps.onChange(value);
              onEdit(false);
            }}
          />
        );
      case 'time':
        return (
          <TagInputTimeType
            {...tagProps}
            onChange={(value) => {
              tagProps.onChange(value);
              onEdit(false);
            }}
          />
        );
      case 'text':
        return (
          <TagInputTextType
            {...tagProps}
            onChange={(value) => {
              tagProps.onChange(value);
              onEdit(false);
            }}
            showIcon={showIcon}
          />
        );
      default:
        return null;
    }
  }

  const tagLabel = () => {
    switch (tagProps.type) {
      case 'days_of_week':
        return Array.isArray(tagProps.value)
          ? tagProps.value.join(',')
          : tagProps.value;
      case 'date':
        return moment(tagProps.value).format('MMM DD YYYY');
      case 'time':
        return moment(tagProps.value, 'HH:mm').format('h:mm A');
      default:
        return tagProps.value;
    }
  };

  return (
    <button
      className={classes.root}
      onClick={() => onEdit(true)}
      onBlur={() => onEdit(false)}
    >
      <Row halfMargin center>
        {showIcon && <TagIcon className={classes.icon} />}
        <div className={classes.tag}>
          {labelOnly ? `${tagProps.label}` : `${tagProps.label}: ${tagLabel()}`}
        </div>
      </Row>
    </button>
  );
};

export default TagInput;
