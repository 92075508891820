import {
  ApiClientError,
  Paginated,
  GetConnectorsQuery,
  Connector,
} from '@raydiant/api-client-js';
import raydiant from '../clients/miraClient';
import useProtectedQuery from './useProtectedQuery';
import { keys } from '../queryClient';

const availableConnectors = ['toast', 'square'];

export default function useConnectors(opts: GetConnectorsQuery = {}) {
  const query = useProtectedQuery<Paginated<Connector>, ApiClientError>(
    keys.connectors(),
    () => raydiant.getConnectors(opts),
    {
      select: (data) => {
        return {
          ...data,
          // For v1, we will only support Toast
          data: data.data.filter((c) => availableConnectors.includes(c.name)),
        };
      },
    },
  );

  return query;
}
