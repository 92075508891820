import Button from '@raydiant/raydial/components/Button';
import { Plus, Trash } from '@raydiant/raydial/components/Icons';
import Link from '@raydiant/raydial/components/Link';
import { tv } from 'tailwind-variants';
import ProgressStepper, {
  ProgressStep,
} from '@raydiant/raydial/components/ProgressStepper';
import Text from '@raydiant/raydial/components/Text';
import TextField from '@raydiant/raydial/components/TextField';

export const ProgressStepperContainer = ({
  selectedStep,
}: {
  selectedStep: number;
}) => {
  const stepStatus = (step: number) => {
    return (
      (step === selectedStep && 'selected') ||
      (selectedStep > step && 'complete') ||
      undefined
    );
  };

  return (
    <div className="px-8 mt-4 mb-7">
      <ProgressStepper>
        <ProgressStep step={1} variant={stepStatus(1)}>
          Login
        </ProgressStep>
        <ProgressStep step={2} variant={stepStatus(2)}>
          Add Raydiant
        </ProgressStep>
        <ProgressStep step={3} variant={stepStatus(3)}>
          Check Email
        </ProgressStep>
        <ProgressStep step={4} variant={stepStatus(4)}>
          Enter GUID's
        </ProgressStep>
      </ProgressStepper>
    </div>
  );
};

export const Header = ({
  heading,
  subheading,
}: {
  heading: string;
  subheading?: string;
}) => {
  return (
    <header className="flex items-center">
      <div className="grid gap-1 p-4 mr-auto">
        <Text as="h2" variant="subheader">
          {heading}
        </Text>
        <Text as="h3" className="text-gray-400 font-regular text-lg">
          {subheading}
        </Text>
      </div>
    </header>
  );
};

export const Footer = ({ children }: { children: React.ReactNode }) => {
  return <footer className="flex flex-wrap gap-6 py-4">{children}</footer>;
};

export type LocationGuidTextFieldsProps = {
  locationGuids: Array<string>;
  onChange: (locationGuids: Array<string>) => void;
  disabledGuids?: Array<string>;
};

const locationGuidTextFieldStyles = tv({
  base: 'text-gray-600 hover:text-red-600',
  variants: {
    topOffset: {
      true: 'relative top-[14.01px]',
    },
    bottomOffset: {
      true: 'relative bottom-[14.01px]',
    },
  },
});

export const LocationGuidTextFields = (props: LocationGuidTextFieldsProps) => {
  const { locationGuids, onChange, disabledGuids = [] } = props;

  const handleTextFieldChange = (guid: string, index: number) => {
    let updatedGuids = [...locationGuids];
    updatedGuids[index] = guid;

    onChange(updatedGuids);
  };

  const handleOnDeleteGuid = (index: number) => {
    let updatedGuids = [...locationGuids];
    updatedGuids.splice(index, 1);

    onChange(updatedGuids);
  };

  const onAddGuid = () => {
    onChange([...locationGuids, '']);
  };

  return (
    <div className="mb-4">
      <div className="mb-4 flex flex-col gap-1">
        {locationGuids.map((guid, index) => {
          const isLastItem = locationGuids.length - 1 === index;
          const isFirstItem = index === 0;
          const addTopOffset = isFirstItem && locationGuids.length > 1;
          const addBottomOffset = isLastItem && locationGuids.length > 1;

          return (
            <div className="flex items-center gap-2" key={index}>
              <div className="flex-1">
                <TextField
                  label={isFirstItem ? 'Location GUID' : ''}
                  placeholder="Enter Location GUID"
                  description={
                    isLastItem && (
                      <Text slot="description">
                        eg: 12kd9gvh-fi39-3ihc-ngi8-h85fwdtyh54ew{' '}
                        <Link>Learn more</Link>
                      </Text>
                    )
                  }
                  value={guid}
                  onChange={(guid) => handleTextFieldChange(guid, index)}
                  isDisabled={disabledGuids.includes(guid)}
                />
              </div>
              <Button
                variant="unstyled"
                className={locationGuidTextFieldStyles({
                  topOffset: addTopOffset,
                  bottomOffset: addBottomOffset,
                })}
                onPress={() => {
                  handleOnDeleteGuid(index);
                }}
                isDisabled={disabledGuids.includes(guid)}
              >
                <Trash />
              </Button>
            </div>
          );
        })}
      </div>

      <Link icon={<Plus size={32} />} onPress={onAddGuid}>
        <Text className="text-lg">Add another Toast Location GUID</Text>
      </Link>
    </div>
  );
};

interface IntegrationItemProps {
  heading: string;
  subheading?: string;
  icon: React.ReactNode;
  action: React.ReactNode;
}

export const IntegrationItem = ({
  heading,
  subheading,
  icon,
  action,
}: IntegrationItemProps) => {
  return (
    <div className="flex w-full p-4 items-center mb-4">
      <div className="mr-7 flex-shrink-0">{icon}</div>
      <div className="flex flex-col mr-auto">
        <Text variant="smallSubheader" className="pb-1">
          {heading}
        </Text>
        <span className="text-gray-400 text-lg capitalize">{subheading}</span>
      </div>
      <div>{action}</div>
    </div>
  );
};

interface CatalogItemProps {
  heading: string;
  subheading?: string;
  action: React.ReactNode;
  thumbnailUrl?: string;
}

export const CatalogItem = ({
  heading,
  subheading,
  action,
  thumbnailUrl,
}: CatalogItemProps) => {
  return (
    <div className="flex w-full p-4 items-center mb-4">
      <div className="mr-7 flex-shrink-0">
        <div className="size-16 bg-gray-200 rounded-lg flex items-center justify-center">
          {thumbnailUrl && <img src={thumbnailUrl} alt="connector type" />}
        </div>
      </div>
      <div className="flex flex-col mr-auto">
        <Text variant="smallSubheader" className="pb-1">
          {heading}
        </Text>
        <span className="text-gray-400 text-lg">{subheading}</span>
      </div>
      <div>{action}</div>
    </div>
  );
};
