import classNames from 'classnames';
export type DataCardType = 'ots' | 'dwellTime' | 'impressions';
export type DataCardProps = {
  key?: string;
  children: React.ReactNode;
  type: DataCardType;
};

export type DataCardDisplayProps = {
  change: string;
  type: DataCardType;
  value: number;
  showEmpty?: boolean;
  label: string;
  src: string;
  unit?: string;
  noPreviousData?: boolean;
};

const DataCard = ({ children, key, type }: DataCardProps) => {
  return (
    <div key={key} className={classNames(`card insights-card ${type}`)}>
      {children}
    </div>
  );
};

export default DataCard;
