import moment from 'moment-timezone';
import PaperModal from 'raydiant-elements/core/PaperModal';
import Button from 'raydiant-elements/core/Button';
import TimeField from 'raydiant-elements/core/TimeField';
import Link from 'raydiant-elements/core/Link';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import Heading from 'raydiant-elements/core/Heading';
import Switch from 'raydiant-elements/core/Switch';
import SelectField from 'raydiant-elements/core/SelectField';
import Column from 'raydiant-elements/layout/Column';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as D from '../../../clients/mira/types/Device';
import * as devicePageActions from '../../DevicesPage/actions';
import {
  selectIsEnterpriseUser,
  selectUserProfile,
  selectIsVIOnly,
} from '../../../selectors/user';
import { canShareResource } from '../../../utilities';
import Row from 'raydiant-elements/layout/Row/Row';
import TimeZoneSelect from '../../../components/TimeZoneSelect';
import useStyles from './DeviceBatchSettings.styles';
import DeviceBatchTags from './DeviceBatchTags';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import DeviceBatchDialog from './DeviceBatchDialog';
import { selectBatchDeviceSettings } from '../../DevicesPage/selectors';
import { BatchDevice } from '../deviceBatchSettings.interface';
import DeviceBatchSharing from './DeviceBatchSharing';
import { initialBatchDeviceSettings } from '../../DevicesPage/reducer';
import isEqual from 'lodash/isEqual';
import { formatToBatchData } from '../deviceBatchSettingsUtils';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { LoadingStatus } from '../../../components/LoadingButton/LoadingButton';
import apiClient from '../../../clients/miraClient';
import { useTranslation } from 'react-i18next';

const fromTimeFieldValue = (time: string | null) => {
  if (!time) return '';

  return moment(time, 'HH:mm').format('HH:mm:ss');
};

const toTimeFieldValue = (time: string | null) => {
  if (!time) return '';

  return moment(time, 'HH:mm:ss').format('HH:mm');
};

const maximumDeviceUpdateBlackoutRange = 21;

interface DeviceSettingsProps {
  devices: D.Device[];
  onPlaylistSelectorClick: () => void;
  lastLoadedDate: string;
  onDialogClick: () => void;
  onClose: () => void;
}

const DeviceBatchSettings = ({
  devices,
  lastLoadedDate,
  onPlaylistSelectorClick,
  onDialogClick,
  onClose,
}: DeviceSettingsProps) => {
  const { t } = useTranslation(['devices']);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [device] = useState(devices[0]); //initialize device props to address race condition when creating new tags
  // Selectors

  const currentUser = useSelector(selectUserProfile);
  const isEnterpriseUser = useSelector(selectIsEnterpriseUser);
  const deviceBatchSettings = useSelector(selectBatchDeviceSettings);
  const isVIOnly = useSelector(selectIsVIOnly);

  // State
  // Device Batch Settings
  const [open, setOpen] = useState<boolean>(false);
  const [batchSettingsLoadingStatus, setBatchSettingsLoadingStatus] =
    useState<LoadingStatus>('idle');
  const [updatedMaintenanceWindowEnabled, setMaintenanceWindowEnabled] =
    useState<boolean | null>(null);
  const [maintenanceWindowValid, setMaintenanceWindowValid] =
    useState<boolean>(false);

  // Callbacks

  const updateDevice = useCallback(
    (settings: Partial<BatchDevice>) => {
      dispatch(devicePageActions.updateBatchDeviceSettings(settings));
    },
    [dispatch],
  );

  const validate = useCallback(() => {
    let isValid = true;
    setMaintenanceWindowValid(isValid);

    const { activeHoursStart, activeHoursEnd } = deviceBatchSettings;

    // skip validation check if either timefield value is empty
    if (!activeHoursStart || !activeHoursEnd) {
      return;
    }

    const startTime = moment(activeHoursStart, 'HH:mm:ss');
    const endTime = moment(activeHoursEnd, 'HH:mm:ss');

    let duration = moment.duration(endTime.diff(startTime));

    // if start date is greater than end date, add a day to the end date and re-calculate difference
    if (duration.hours() < 0) {
      endTime.add(1, 'day');
      duration = moment.duration(endTime.diff(startTime));
    }

    if (
      duration.hours() > maximumDeviceUpdateBlackoutRange ||
      (duration.hours() === maximumDeviceUpdateBlackoutRange &&
        duration.minutes() > 0)
    ) {
      isValid = false;
    }

    setMaintenanceWindowValid(isValid);
    return isValid;
  }, [deviceBatchSettings]);

  const updateActiveHoursTime = useCallback(() => {
    if (!validate()) return;

    updateDevice({
      activeHoursStart: fromTimeFieldValue(
        deviceBatchSettings.activeHoursStart,
      ),
      activeHoursEnd: fromTimeFieldValue(deviceBatchSettings.activeHoursEnd),
    });
  }, [
    updateDevice,
    validate,
    deviceBatchSettings.activeHoursStart,
    deviceBatchSettings.activeHoursEnd,
  ]);

  const closeBatchSettingsConfirmation = useCallback(() => {
    setOpen(false);
  }, []);

  const handleAutoPublish = useCallback(
    (autoPublish: boolean) => {
      if (deviceBatchSettings.autoPublish === autoPublish) return;

      updateDevice({ autoPublish });
    },
    [deviceBatchSettings.autoPublish, updateDevice],
  );

  const handleDialogClick = useCallback(async () => {
    setBatchSettingsLoadingStatus('loading');

    try {
      const selectedDeviceIds = Object.values(devices).map(
        (device) => device.id,
      );
      const params = formatToBatchData(selectedDeviceIds, deviceBatchSettings);
      await apiClient.batchDeviceSettings(params);
      setBatchSettingsLoadingStatus('success');
      onDialogClick();
      setOpen(false);
    } catch (error) {
      console.log('ERROR APPLYING BATCH SETTINGS', error);
      setBatchSettingsLoadingStatus('error');
    }
  }, [deviceBatchSettings, devices, onDialogClick]);

  // Side-effects

  // Update maintenance window toggle status when changed.
  useEffect(() => {
    if (updatedMaintenanceWindowEnabled === null) return;

    if (!updatedMaintenanceWindowEnabled) {
      updateDevice({
        activeHoursStart: null,
        activeHoursEnd: null,
      });
    }
  }, [updateDevice, updatedMaintenanceWindowEnabled]);

  // Render

  const isShareable =
    isEnterpriseUser &&
    currentUser &&
    canShareResource(currentUser, device.resource);

  const maintenanceWindowSet = !!(
    deviceBatchSettings.activeHoursStart && deviceBatchSettings.activeHoursEnd
  );

  const maintenanceWindowEnabled =
    updatedMaintenanceWindowEnabled ?? maintenanceWindowSet ?? false;

  const validActiveHours = updatedMaintenanceWindowEnabled
    ? maintenanceWindowSet && maintenanceWindowValid
    : maintenanceWindowValid;

  const disableApplyButton =
    (updatedMaintenanceWindowEnabled && !validActiveHours) ||
    isEqual(deviceBatchSettings, initialBatchDeviceSettings);

  return (
    <>
      <DeviceBatchDialog
        open={open}
        status={batchSettingsLoadingStatus}
        onClose={closeBatchSettingsConfirmation}
        onHandleAutoPublish={handleAutoPublish}
        onApplyBatchSettings={handleDialogClick}
        settings={deviceBatchSettings}
      />
      <PaperModal.Body>
        <Column>
          <div>
            <Heading className={classes.title}>Batch Manage</Heading>
            <br />
            <Heading size={5} className={classes.type}>
              {devices.length} devices selected
            </Heading>
          </div>
        </Column>
        <br />
      </PaperModal.Body>
      <Scrollable>
        <PaperModal.Body>
          <div className={classes.section}>
            <Column doubleMargin>
              {!isVIOnly && (
                <div>
                  <Button
                    fullWidth
                    icon={<PlaylistPlayIcon />}
                    label="Assign playlist"
                    onClick={() => onPlaylistSelectorClick()}
                  />
                  {deviceBatchSettings.playlist && (
                    <Row
                      key="playlist"
                      halfMargin
                      className={classes.playlistRow}
                    >
                      <div className={classes.playlistName}>
                        Selected Playlist: {deviceBatchSettings.playlist.name}
                      </div>
                      <button
                        className={classes.remove}
                        onClick={() => {
                          updateDevice({ playlistId: null, playlist: null });
                        }}
                      >
                        <HighlightOffIcon />
                      </button>
                    </Row>
                  )}

                  <InputHelperText>
                    Will affect {devices.length} screens
                  </InputHelperText>
                </div>
              )}

              {!isVIOnly && (
                <SelectField
                  label="Screen Resolution"
                  value={deviceBatchSettings.screenDimensions}
                  onChange={(value) =>
                    updateDevice({
                      screenDimensions: value,
                    })
                  }
                >
                  <option value="">Select to apply</option>
                  <option value="1920x1080">1080p</option>
                  <option value="1280x720">720p</option>
                </SelectField>
              )}

              <TimeZoneSelect
                label="Timezone"
                onChange={(value) =>
                  updateDevice({
                    timezone: value,
                  })
                }
                value={deviceBatchSettings.timezone}
                batch
              />

              {!isVIOnly && (
                <SelectField
                  label="Screen Orientation"
                  value={deviceBatchSettings.screenOrientation}
                  onChange={(value) =>
                    updateDevice({
                      screenOrientation: value,
                    })
                  }
                >
                  <option value="">Select to apply</option>
                  <option value="normal">{t(`screens.landscape`)}</option>
                  <option value="right">{t(`screens.portraitRight`)}</option>
                  <option value="left">{t(`screens.portraitLeft`)}</option>
                </SelectField>
              )}

              {
                <div className={classes.section}>
                  <DeviceBatchTags devices={devices} />
                </div>
              }

              {isShareable && (
                <div className={classes.section}>
                  <DeviceBatchSharing devices={devices} />
                </div>
              )}
            </Column>
          </div>

          <div className={classes.section}>
            <Column>
              <Heading size={5} overline gutterBottom>
                Device Management
              </Heading>

              <Switch
                checked={maintenanceWindowEnabled}
                onChange={setMaintenanceWindowEnabled}
                label="device update blackout"
                helperText="Specifies a time of day in which no software updates should occur."
              />

              {maintenanceWindowEnabled && (
                <Row halfMargin>
                  <TimeField
                    helperText={
                      <span>
                        Maximum range of {maximumDeviceUpdateBlackoutRange}{' '}
                        hours.{' '}
                        <Link
                          href="https://support.raydiant.com/hc/en-us/articles/360052905832#update-blackout-window"
                          target="_blank"
                        >
                          Learn more
                        </Link>
                      </span>
                    }
                    label="from"
                    value={
                      toTimeFieldValue(deviceBatchSettings.activeHoursStart) ||
                      ''
                    }
                    onChange={(value) =>
                      updateDevice({
                        activeHoursStart: fromTimeFieldValue(value) || null,
                      })
                    }
                    error={!maintenanceWindowValid}
                    onBlur={updateActiveHoursTime}
                  />
                  <TimeField
                    label="to"
                    value={
                      toTimeFieldValue(deviceBatchSettings.activeHoursEnd) || ''
                    }
                    onChange={(value) =>
                      updateDevice({
                        activeHoursEnd: fromTimeFieldValue(value) || null,
                      })
                    }
                    error={!maintenanceWindowValid}
                    onBlur={updateActiveHoursTime}
                  />
                </Row>
              )}
            </Column>
          </div>
          <Button
            fullWidth
            label="Apply"
            disabled={disableApplyButton}
            onClick={() => setOpen(true)}
            color="primary"
          />
        </PaperModal.Body>
      </Scrollable>
    </>
  );
};

export default DeviceBatchSettings;
