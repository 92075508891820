import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import { Device, RecentDeviceError } from '@raydiant/api-client-js';
import Icon from 'raydiant-elements/core/Icon';
import Link from 'raydiant-elements/core/Link';
import SVGIcon from 'raydiant-elements/core/SVGIcon';
import Text from 'raydiant-elements/typography/Text';
import Row from 'raydiant-elements/layout/Row';
import EthernetIcon from 'raydiant-elements/icons/Ethernet';
import AlertIcon from 'raydiant-elements/core/AlertIcon';
import EthernetAbsentIcon from 'raydiant-elements/icons/EthernetAbsent';
import LTEIcon from 'raydiant-elements/icons/LTE';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { buttonReset } from 'raydiant-elements/mixins';
import { Theme } from 'raydiant-elements/theme';
import { useRef, useMemo } from 'react';
import cn from 'classnames';
import { getDeviceConnectionStatus } from '../../utilities';
import { ContentWarning } from './getDeviceContentWarning';
import DeviceStatusPopover from './DeviceStatusPopover';
import * as errorTypes from './deviceErrorTypes';
import DeviceAIStatus from './DeviceAIStatus';
import DevicePerchStatus from './DevicePerchStatus';
import { useSelector } from 'react-redux';
import {
  selectIsPerchSettingsEnabled,
  selectIsUserAIEnabled,
} from '../../selectors/user';

interface DeviceInfoProps {
  device: Device;
  lastLoadedDate: string;
  muted?: boolean;
  contentWarning: ContentWarning | null;
  deviceErrors: RecentDeviceError[];
  openStatusPopover?: boolean;
  onAiSettingsClick?: () => void;
  onPerchSettingsClick?: () => void;
  onOpenStatusPopover?: (show: boolean) => void;
  showAiDevice?: boolean;
  showPerchDevice?: boolean;
}
enum ConnectionTypes {
  ETHERNET = 'ethernet',
  LTE = 'lte',
  WIFI = 'wifi',
}

const DeviceInfo = ({
  device,
  lastLoadedDate,
  muted,
  contentWarning,
  deviceErrors,
  openStatusPopover = false,
  onAiSettingsClick,
  onPerchSettingsClick,
  onOpenStatusPopover,
  showAiDevice = false,
  showPerchDevice = false,
}: DeviceInfoProps) => {
  const classes = useStyles();

  const serviceCloudChatButton = document.querySelector(
    '#helpButtonSpan > span.message',
  ) as HTMLElement;

  const openChat = () => serviceCloudChatButton?.click();

  const isUserAIEnabled = useSelector(selectIsUserAIEnabled);
  const isUserPerchSettingsEnabled = useSelector(selectIsPerchSettingsEnabled);

  // Refs

  const statusPopoverAnchorRef = useRef<HTMLButtonElement | null>(null);

  // Memoizers

  const deviceError = useMemo(() => {
    // A window_end_error takes precendence over a preload error.
    const windowEndError = deviceErrors.find(
      (e) => e.event === errorTypes.windowEndError,
    );
    if (windowEndError) return windowEndError;
    const windowPreloadError = deviceErrors.find(
      (e) => e.event === errorTypes.windowPreloadError,
    );
    if (windowPreloadError) return windowPreloadError;
    return null;
  }, [deviceErrors]);

  // Render
  const {
    isOnline,
    signalStrength = 0,
    lastHeartbeatAt,
  } = getDeviceConnectionStatus(device, lastLoadedDate);

  const connectionType = device.metrics?.connectionType;
  const isEthernet = device.metrics?.connectionType === 'ethernet';
  const isLTE = device.metrics?.connectionType === 'lte';
  const isSocDevice = device.deviceType === 'SoC';

  const isSignalStrengthWeak =
    (!device.isLite || isSocDevice) && !isEthernet && signalStrength < 60;

  const isPreBelenaDevice = !device.resinUuid && !device.isLite;
  if (isPreBelenaDevice) return <>&nbsp;</>;

  const isPerchDevice = device?.services?.includes('perchview');
  const isAiDevice = device?.services?.includes('deepsight');

  const renderSignalStrength = () => {
    if (device.isLite && !isSocDevice) {
      return null;
    }

    if (!isOnline) {
      return renderOfflineStatusIcon();
    }

    switch (connectionType) {
      case ConnectionTypes.ETHERNET:
        return renderSignalStrengthEthernet();

      case ConnectionTypes.WIFI:
        return renderSignalStrengthWifi(signalStrength);

      case ConnectionTypes.LTE:
        return renderSignalStrengthLTE(signalStrength);

      default:
        // TODO We should return no active interface found icon or smth, as wifiNone icon is not very self explanatory
        return null;
    }
  };

  const renderOfflineStatusIcon = (): JSX.Element | null => {
    switch (connectionType) {
      case ConnectionTypes.ETHERNET:
        return <EthernetAbsentIcon className={classes.muted} />;

      case ConnectionTypes.WIFI:
        return <WifiOffIcon className={classes.muted} />;

      case ConnectionTypes.LTE:
        return <LTEIcon className={classes.muted} />;
      default:
        // TODO We should return no active interface found icon or smth, as wifiNone icon is not very self explanatory
        return null;
    }
  };
  const renderSignalStrengthEthernet = (): JSX.Element => {
    return <EthernetIcon className={classes.muted} />;
  };
  const renderSignalStrengthWifi = (signalStrength: number): JSX.Element => {
    if (signalStrength > 80) {
      return <SVGIcon icon="wifiFull" className={classes.muted} />;
    } else if (signalStrength > 50) {
      return <SVGIcon icon="wifiHigh" className={classes.muted} />;
    } else if (signalStrength > 20) {
      return <SVGIcon icon="wifiMedium" className={classes.muted} />;
    } else if (signalStrength > 0) {
      return <SVGIcon icon="wifiLow" className={classes.muted} />;
    } else {
      return <SVGIcon icon="wifiNone" className={classes.muted} />;
    }
  };
  const renderSignalStrengthLTE = (signalStrength: number): JSX.Element => {
    if (signalStrength > 80) {
      return <Icon icon="lteFull" className={classes.muted} />;
    } else if (signalStrength > 40) {
      return <Icon icon="lteMedium" className={classes.muted} />;
    } else if (signalStrength > 0) {
      return <Icon icon="lteLow" className={classes.muted} />;
    } else {
      return <Icon icon="lteNone" className={classes.muted} />;
    }
  };

  const renderStatusIcon = () => {
    if (
      !isOnline ||
      deviceError?.event === errorTypes.windowEndError ||
      contentWarning?.severity === 'error'
    ) {
      return <AlertIcon color="error" />;
    } else if (
      isSignalStrengthWeak ||
      deviceError?.event === errorTypes.windowPreloadError ||
      contentWarning?.severity === 'warning'
    ) {
      return <AlertIcon color="warning" />;
    } else {
      return <CheckCircleIcon className={classes.muted} />;
    }
  };

  const renderStatusText = () => {
    if (!isOnline) {
      return (
        <Text small className={classes.muted}>
          Offline{' '}
          <Link onClick={openChat} className={cn('live-support', classes.link)}>
            Need Help?
          </Link>
        </Text>
      );
    } else if (isSignalStrengthWeak) {
      return (
        <Text small className={classes.muted}>
          Online, but weak {isLTE ? 'LTE' : isEthernet ? '' : 'wifi'} connection
        </Text>
      );
    } else {
      return (
        <Text small className={classes.muted}>
          Online
        </Text>
      );
    }
  };

  const renderAIStatus = () => {
    return (
      <Row className={classes.aiStatus}>
        <DeviceAIStatus device={device} onEditSettings={onAiSettingsClick} />
      </Row>
    );
  };

  const renderPerchStatus = () => {
    return (
      <Row className={classes.perchStatus}>
        <DevicePerchStatus
          device={device}
          onEditSettings={onPerchSettingsClick}
        />
      </Row>
    );
  };

  const renderStatusPopover = () => {
    if (!onOpenStatusPopover) return null;

    return (
      <DeviceStatusPopover
        open={openStatusPopover}
        device={device}
        isOnline={isOnline}
        lastHeartbeatAt={lastHeartbeatAt}
        contentWarning={contentWarning}
        deviceError={deviceError}
        anchorEl={statusPopoverAnchorRef.current}
        onClose={() => onOpenStatusPopover(false)}
      />
    );
  };

  return (
    <div className={classes.status}>
      <Row
        halfMargin
        center
        className={cn(classes.root, muted && classes.isMuted)}
      >
        {renderSignalStrength()}

        {onOpenStatusPopover ? (
          <button
            ref={statusPopoverAnchorRef}
            className={classes.statusButton}
            onClick={() => onOpenStatusPopover(true)}
          >
            {renderStatusIcon()}
          </button>
        ) : (
          renderStatusIcon()
        )}

        {renderStatusText()}
        {renderStatusPopover()}
      </Row>
      {showAiDevice && isAiDevice && isUserAIEnabled && renderAIStatus()}
      {showPerchDevice &&
        isUserPerchSettingsEnabled &&
        isPerchDevice &&
        renderPerchStatus()}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      color: 'inherit',
    },

    isMuted: {
      '& $muted': {
        opacity: 0.6,
      },
    },

    muted: {},
    status: {
      display: 'flex',
    },

    aiStatus: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: 24,
      marginRight: theme.spacing(1),
    },

    perchStatus: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: 24,
      marginRight: theme.spacing(1),
    },

    info: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(0.5),
      position: 'relative',
    },

    link: {
      color: 'inherit',
      fontSize: theme.fontSizes.xs,
      marginLeft: theme.spacing(0.5),
    },

    weak: {
      color: '#f4eba1',
    },

    statusButton: {
      display: 'flex',
      ...buttonReset(),
    },

    // TODO: Remove duplication from DeviceSharing.tsx
    popover: {
      backgroundColor: theme.modal.overlayBackground,

      '& $popoverPaper': {
        borderRadius: theme.borderRadius.md,
        padding: theme.spacing(2),
        maxWidth: 300,
      },
    },

    popoverPaper: {},
  });
});

export default DeviceInfo;
