import { SVGProps } from 'react';

interface LTEProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const LTE = ({ size = 18, ...props }: LTEProps) => {
  return (
    <svg
      width="20"
      height="11"
      viewBox="0 0 20 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.35708 0.60416V8.22399H6.74053V10.1631H0.336052V0.60416H2.35708ZM8.27273 10.1631V2.54326H5.48699V0.60416H13.0932V2.54326H10.2938V10.1631H8.27273ZM19.9566 0.60416V2.54326H16.1194V4.26386H19.5333V6.20296H16.1194V8.22399H19.9566V10.1631H14.0983V0.60416H19.9566Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default LTE;
