import Button from '@raydiant/raydial/components/Button';
import Text from '@raydiant/raydial/components/Text';
import Link from '@raydiant/raydial/components/Link';
import SquareOAuthButton from '@raydiant/raydial/components/SquareOAuthButton';
import { Footer, Header } from './components';
import squareLoginSvg from '../../assets/square-login.svg';
import squareSuccessSvg from '../../assets/square-success.svg';
import config from '../../config';

const Step1Content = ({
  onNext,
  onPrevious,
}: {
  onNext: () => void;
  onPrevious: () => void;
}) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onNext();
  };

  const handlePrevious = () => {
    onPrevious();
  };

  return (
    <>
      <div className="mb-4">
        <Header
          heading="Connect Square with Raydiant"
          subheading="Follow the steps and complete the integration"
        />
      </div>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4 px-4">
        <div className="flex justify-center">
          <img src={squareLoginSvg} alt="Square login" />
        </div>
        <Text variant="smallSubheader">Log into your Square account</Text>
        <Text as="p">
          Please login to your Square account on the next screen with your
          Square login credentials to connect your Square account with Raydiant.
        </Text>
        <div>
          <Link href="#" target="_blank">
            Learn more
          </Link>
        </div>
        <Footer>
          <Button
            size="large"
            variant="secondary"
            className="flex-1"
            onPress={handlePrevious}
          >
            Back
          </Button>
          <SquareOAuthButton type="submit" className="flex-1" />
        </Footer>
      </form>
    </>
  );
};

const Step2Content = ({
  onNext,
  onExit,
}: {
  onNext: () => void;
  onExit: () => void;
}) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onNext();
  };

  const handleCancel = () => {
    onExit();
  };

  return (
    <>
      <div className="mb-4">
        <Header
          heading="Square Account Authenticated"
          subheading="Your account is now connected"
        />
      </div>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4 px-4">
        <div className="flex justify-center">
          <img src={squareSuccessSvg} alt="add Raydiant" />
        </div>
        <Text variant="smallSubheader">One last step</Text>
        <Text as="p">
          Your Square account has been successfully authenticated. Continue to
          the final step to complete the integration with Raydiant.
        </Text>

        <div>
          <Link href="#" target="_blank">
            Learn more
          </Link>{' '}
        </div>
        <Footer>
          <Button
            size="large"
            variant="secondary"
            className="flex-1"
            onPress={handleCancel}
          >
            Cancel
          </Button>
          <Button
            size="large"
            variant="primary"
            className="flex-1"
            type="submit"
          >
            Save
          </Button>
        </Footer>
      </form>
    </>
  );
};

const SquareSetupWizard = ({
  onSubmit,
  onExit,
  formData,
}: {
  onSubmit: () => void;
  onExit: () => void;
  formData: { connectorId: string; name: string; authId: string };
}) => {
  const handleOnOAuthButtonClick = () => {
    const authUrl = config.squareOAuthUrl;
    const url = new URL(authUrl);

    const redirectUri = new URL(window.location.href);
    redirectUri.searchParams.set('connector', 'square');
    redirectUri.searchParams.set('connectorId', formData.connectorId);
    redirectUri.searchParams.set('name', formData.name);

    url.searchParams.set('redirect_uri', redirectUri.href);
    window.location.href = url.href;
  };

  const handleOnSubmit = () => {
    onSubmit();
  };

  const authIdSet = formData.authId && formData.authId.length > 0;

  return (
    <>
      {!authIdSet ? (
        <Step1Content onPrevious={onExit} onNext={handleOnOAuthButtonClick} />
      ) : (
        <Step2Content onExit={onExit} onNext={handleOnSubmit} />
      )}
    </>
  );
};

export default SquareSetupWizard;
