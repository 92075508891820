import { useState, useEffect } from 'react';
import { NavigationMenu } from './SidebarMenu';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import SidebarMenuTooltip from './SidebarMenuTooltip';

interface SidebarMenuItemProps {
  menuItem: NavigationMenu;
  onTopMenuClick: (value: boolean) => void;
}

const upArrowIcon = (
  <svg
    fill="#000000"
    height="500px"
    width="500px"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 330 330"
  >
    <path
      id="XMLID_224_"
      d="M325.606,229.393l-150.004-150C172.79,76.58,168.974,75,164.996,75c-3.979,0-7.794,1.581-10.607,4.394
l-149.996,150c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.857,15.355,5.858,21.213,0l139.39-139.393l139.397,139.393
C307.322,253.536,311.161,255,315,255c3.839,0,7.678-1.464,10.607-4.394C331.464,244.748,331.464,235.251,325.606,229.393z"
    />
  </svg>
);

const downArrowIcon = (
  <svg
    fill="#000000"
    height="500px"
    width="500px"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 330 330"
  >
    <path
      id="XMLID_225_"
      d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
    />
  </svg>
);

const SidebarMenuItem = ({
  menuItem,
  onTopMenuClick,
}: SidebarMenuItemProps) => {
  const { icon, href, label, subItems = [] } = menuItem;
  const [open, setOpen] = useState(
    () => localStorage.getItem(label) === 'true',
  );
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const isSidebarOpen = localStorage.getItem('isSidebarOpen') === 'true';
  const isMenuItemOpen = localStorage.getItem(label) === 'true';
  const hasSubMenuItem = subItems.length > 0;

  const isSubItemActive = (link: string) =>
    hasSubMenuItem && location.pathname.includes(link);
  const linkIsActive = (link: string) => location.pathname === link;
  const IconIsActive = (link: string) =>
    hasSubMenuItem ? isSubItemActive(link) : linkIsActive(link);

  useEffect(() => {
    if (!isSidebarOpen) {
      localStorage.setItem(label, 'false');
      setOpen(false);
    }
  }, [isSidebarOpen, label]);

  useEffect(() => {
    if (isMenuItemOpen) {
      setOpen(true);
    }
  }, [isMenuItemOpen]);

  const toggleOpen = (value: boolean) => {
    localStorage.setItem(label, JSON.stringify(value));
    setOpen(!open);
  };

  const renderWithoutSubmenu = () => (
    <li
      className={classNames(linkIsActive(href) ? 'active' : '')}
      onClick={() => history.push(href)}
    >
      <span className={classNames('mr-2')}>{icon}</span>
      {t(label)}
    </li>
  );

  const handleTopMenuClick = () => {
    onTopMenuClick(true);
    toggleOpen(true);
  };

  const renderWithSubMenu = () => (
    <>
      <li
        className={classNames(isSubItemActive(href) ? 'active' : '')}
        onClick={() => toggleOpen(!open)}
      >
        {hasSubMenuItem && (
          <div className={classNames('flex flex-1 justify-between')}>
            <div className="flex">
              <span className={classNames('mr-2')}>{icon}</span>
              {t(label)}
            </div>
            <button
              className={classNames(
                'btn mini !text-gray-400',
                isSubItemActive(href) ? '' : 'mr-2',
              )}
            >
              <div className="wrapper">
                {open ? upArrowIcon : downArrowIcon}
              </div>
            </button>
          </div>
        )}
      </li>
      {open && (
        <div className="subItem">
          {subItems.map((item) => (
            <li
              className={classNames(linkIsActive(item.href) ? 'active' : '')}
              onClick={() => history.push(item.href)}
            >
              {t(item.label)}
            </li>
          ))}
        </div>
      )}
    </>
  );

  const renderIconOnly = () => (
    <SidebarMenuTooltip placement="right" title={t(label)!} arrow>
      <li
        className={classNames(IconIsActive(href) ? 'active' : '')}
        onClick={
          subItems.length > 0
            ? () => handleTopMenuClick()
            : () => history.push(href)
        }
      >
        <span>{icon}</span>
      </li>
    </SidebarMenuTooltip>
  );

  return (
    <div key={`sidebarmenu-${label}`}>
      {!isSidebarOpen && renderIconOnly()}
      {isSidebarOpen && (
        <>{hasSubMenuItem ? renderWithSubMenu() : renderWithoutSubmenu()}</>
      )}
    </div>
  );
};

export default SidebarMenuItem;
