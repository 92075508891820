import {
  LegacyRegionsArea,
  LegacyRegionsConfig,
} from './devicePerchSettings.interface';
import {
  PostPerchviewSettingsRequest,
  PerchviewRegion,
  PerchviewRegionResponse,
  PerchviewSettings,
  V2PerchviewSettings,
  PostV2PerchviewSettingsRequest,
} from '@raydiant/api-client-js';

export const initialRegion: PerchviewRegion = {
  name: 'region',
  width: 0,
  height: 0,
  depth: 0,
  positionX: 0,
  positionY: 0,
  positionZ: 0,
  surgeCount: 0,
  surgeFrames: 0,
  volumeAverageFrame: 0,
};

export const initialPerchSettings: V2PerchviewSettings = {
  id: '',
  deviceServiceId: '',
  settings: {} as {
    [key: string]: unknown;
  },
  createdAt: '',
  updatedAt: '',
};

export const formatLegacyAreaToRegions = (
  areas: LegacyRegionsArea[],
): PerchviewRegion[] =>
  areas.map((area) => ({
    name: area.name,
    width: area.size_x || 0,
    height: area.size_y || 0,
    depth: area.size_z || 0,
    positionX: area.offset_x || 0,
    positionY: area.offset_y || 0,
    positionZ: area.offset_z || 0,
    surgeCount: area.surgeCount || 0,
    surgeFrames: area.surgeFrames || 0,
    volumeAverageFrame: area.surgeFrameCount || 0,
  }));

export const formatLegacyConfigToPerchSettings = (
  legacyConfig: LegacyRegionsConfig,
) => ({
  cameraPositionX: parseFloat(legacyConfig.cameraOffset0 || '0').toFixed(6),
  cameraPositionY: parseFloat(legacyConfig.cameraOffset1 || '0').toFixed(6),
  cameraPositionZ: parseFloat(legacyConfig.cameraOffset2 || '0').toFixed(6),
  cameraRotationX: parseFloat(legacyConfig.cameraRotation0 || '0').toFixed(6),
  cameraRotationY: parseFloat(legacyConfig.cameraRotation1 || '0').toFixed(6),
  cameraRotationZ: parseFloat(legacyConfig.cameraRotation2 || '0').toFixed(6),
  minPoints: parseInt(legacyConfig.minAreaProductCount || '0', 10),
  frameThreshold: parseInt(legacyConfig.productFrameThreshold || '0', 10),
  regions: formatLegacyAreaToRegions(legacyConfig.areas || []),
});

export const formatRegionsToLegacyArea = (
  regions: PerchviewRegion[],
): LegacyRegionsArea[] =>
  regions.map((region) => ({
    name: region.name,
    size_x: region.width || 0,
    size_y: region.height || 0,
    size_z: region.depth || 0,
    offset_x: region.positionX || 0,
    offset_y: region.positionY || 0,
    offset_z: region.positionZ || 0,
    surgeCount: region.surgeCount || 0,
    surgeFrames: region.surgeFrames || 0,
    surgeFrameCount: region.volumeAverageFrame || 0,
  }));

export const formatPerchSettingsToLegacyConfig = (
  settings: PerchviewSettings,
) => ({
  cameraOffset0: settings.cameraPositionX.toFixed(6),
  cameraOffset1: settings.cameraPositionY.toFixed(6),
  cameraOffset2: settings.cameraPositionZ.toFixed(6),
  cameraRotation0: settings.cameraRotationX.toFixed(6),
  cameraRotation1: settings.cameraRotationY.toFixed(6),
  cameraRotation2: settings.cameraRotationZ.toFixed(6),
  areas: formatRegionsToLegacyArea(settings.regions),
  minAreaProductCount: settings.minPoints,
  productFrameThreshold: settings.frameThreshold,
});

export const formatRegions = (
  regions: PerchviewRegion[],
): PerchviewRegionResponse[] =>
  regions.map((region) => ({
    name: region.name,
    width: region.width,
    height: region.height,
    depth: region.depth,
    position_x: region.positionX,
    position_y: region.positionY,
    position_z: region.positionZ,
    surge_count: region.surgeCount,
    surge_frames: region.surgeFrames,
    volume_average_frame: region.volumeAverageFrame,
  }));

export const formatPerchSettings = (
  settings: PerchviewSettings,
): PostPerchviewSettingsRequest => ({
  device_service_id: settings.deviceServiceId,
  camera_position_x: settings.cameraPositionX,
  camera_position_y: settings.cameraPositionY,
  camera_position_z: settings.cameraPositionZ,
  camera_rotation_x: settings.cameraRotationX,
  camera_rotation_y: settings.cameraRotationY,
  camera_rotation_z: settings.cameraRotationZ,
  min_points: settings.minPoints,
  frame_threshold: settings.frameThreshold,
  regions: formatRegions(settings.regions),
  created_at: settings.createdAt,
  updated_at: settings.updatedAt,
});

export const formatV2PerchSettings = (
  settings: V2PerchviewSettings,
): PostV2PerchviewSettingsRequest => ({
  device_service_id: settings.deviceServiceId,
  settings: settings.settings,
  created_at: settings.createdAt,
  updated_at: settings.updatedAt,
});

export const isValueNegative = (value: number) => value < 0;

export const formatToFloat = (value: string) => {
  const formattedValue = parseFloat(value);
  if (isNaN(formattedValue)) return 0;
  return Number(formattedValue.toFixed(6));
};

export const formatToInt = (value: string) => {
  const formattedValue = parseInt(value, 0);
  if (isNaN(formattedValue)) return 0;
  return formattedValue;
};

export const validateRegion = (region: PerchviewRegion) => {
  const invalid =
    isValueNegative(Number(region.width)) ||
    isValueNegative(Number(region.height)) ||
    isValueNegative(Number(region.depth)) ||
    isValueNegative(Number(region.volumeAverageFrame)) ||
    Number(region.volumeAverageFrame) > 100;
  if (invalid) return true;
  return false;
};

export const isRegionNameUnique = (regions: PerchviewRegion[]) => {
  const names = new Set(regions.map((region) => region.name));
  return [...names].length === regions.length;
};

export const isPerchSettingsValid = (settings: PerchviewSettings) => {
  const invalidRegions =
    settings.regions.map((region) => validateRegion(region)).includes(true) ||
    !isRegionNameUnique(settings.regions);
  const invalidPerchSettings =
    isValueNegative(Number(settings.minPoints)) ||
    isValueNegative(Number(settings.frameThreshold)) ||
    invalidRegions;
  if (invalidPerchSettings) return false;
  return true;
};
