import * as U from '../clients/mira/types/User';
import * as P from '../clients/mira/types/Presentation';
import * as R from '../clients/mira/types/Resource';
import * as D from '../clients/mira/types/Device';

type Profile = U.Profile | R.ResourceProfile;

const rolesByLevel: U.DomainRole[] = [
  'restricted',
  'standard',
  'admin',
  'superadmin',
];

export const hasRoleOrHigher = (lowerBound: U.DomainRole, profile: Profile) => {
  const lowerBoundLevel = rolesByLevel.indexOf(lowerBound);
  const profileLevel = rolesByLevel.indexOf(profile.domainRole);
  return profileLevel !== -1 && profileLevel >= lowerBoundLevel;
};

// Super admin only permissions.

export const canDeleteResource = (profile: Profile, resource: R.Resource) => {
  return (
    hasRoleOrHigher('superadmin', profile) || resource.profile.id === profile.id
  );
};

export const canDeleteFolderItems = (
  profile: Profile,
  ownerProfile: Profile,
) => {
  return (
    hasRoleOrHigher('superadmin', profile) || profile.id === ownerProfile.id
  );
};

export const canShareResource = (profile: Profile, resource: R.Resource) => {
  return (
    hasRoleOrHigher('superadmin', profile) || resource.profile.id === profile.id
  );
};

// Admin user and above permissions.

export const canEditDomain = (profile: U.Profile) => {
  return hasRoleOrHigher('admin', profile);
};

export const canEditResource = (profile: Profile, resource: R.Resource) => {
  // Profile owns the resource.
  if (resource.profile.id === profile.id) {
    return true;
  }
  // Profile is a domain admin and resource exists in domain.
  if (
    profile.domainId &&
    hasRoleOrHigher('admin', profile) &&
    profile.domainId === resource.profile.domainId
  ) {
    return true;
  }
  // Profile has been granted access.
  if (
    resource.r.resourceACLs.some(
      (acl) => acl.grantProfileId === profile.id && acl.updateGrant,
    )
  ) {
    return true;
  }
  // Profile does not have access.
  return false;
};

export const canEditLocationPlaylist = (
  profile: Profile,
  playlist: D.DevicePlaylist,
  location?: U.Location | null,
) => {
  // Profile owns the resource.
  if (playlist.resource.profile.id === profile.id) {
    return true;
  }
  // Profile is a domain admin.
  if (profile.domainId && hasRoleOrHigher('admin', profile)) {
    return true;
  }
  // Profile has been granted access to the playlist.
  if (location?.acl?.some((acl) => acl.grantProfile.id === profile.id)) {
    return true;
  }
  // Profile has been granted access to the playlist.
  if (
    playlist.resource.r.resourceACLs.some(
      (acl) => acl.grantProfileId === profile.id && acl.updateGrant,
    )
  ) {
    return true;
  }
  // Profile does not have access.
  return false;
};

export const canEditLocationDevice = (profile: Profile, device: D.Device) => {
  // Profile owns the resource.
  if (device.resource.profile.id === profile.id) {
    return true;
  }
  // Profile is a domain admin.
  if (profile.domainId && hasRoleOrHigher('admin', profile)) {
    return true;
  }
  // Profile has been granted access to the location.
  if (device.location?.acl.some((acl) => acl.grantProfile.id === profile.id)) {
    return true;
  }
  // Profile has been granted access to the device.
  if (
    device.resource.r.resourceACLs.some(
      (acl) => acl.grantProfileId === profile.id && acl.updateGrant,
    )
  ) {
    return true;
  }
  // Profile does not have access.
  return false;
};

// Standard user and above permissions.

export const canReadResource = (profile: Profile, resource: R.Resource) => {
  return (
    hasRoleOrHigher('standard', profile) ||
    resource.profile.id === profile.id ||
    resource.r.resourceACLs.some(
      (acl) => acl.grantProfileId === profile.id && acl.readGrant,
    )
  );
};

export const canRemovePresentationFromPlaylist = (
  profile: Profile,
  presentation: P.Presentation,
) => {
  return (
    hasRoleOrHigher('standard', profile) ||
    presentation.resource.profile.id === profile.id
  );
};

// Restricted and above permissions (all users).

export const canMoveFolderItems = (profile: Profile, ownerProfile: Profile) => {
  // No one is allowed to move another user's folder items.
  return profile.id === ownerProfile.id;
};

export const canCreateFolderItem = (
  profile: Profile,
  ownerProfile: Profile,
) => {
  // No one is allowed to create an item in another user's folder.
  return profile.id === ownerProfile.id;
};

export const canEditDomainRole = (profile: Profile, ownerProfile: Profile) => {
  // No one is allowed to edit their own role
  return profile.id !== ownerProfile.id;
};

export const canEnableDomainMultiTimezone = (profile?: Profile) =>
  profile && hasRoleOrHigher('superadmin', profile);
