import { SVGProps } from 'react';

interface ListProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const List = ({ size = 17, ...props }: ListProps) => {
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.828125 0.71875H2.42188C2.85352 0.71875 3.21875 1.08398 3.21875 1.51562V3.10938C3.21875 3.57422 2.85352 3.90625 2.42188 3.90625H0.828125C0.363281 3.90625 0.03125 3.57422 0.03125 3.10938V1.51562C0.03125 1.08398 0.363281 0.71875 0.828125 0.71875ZM5.60938 1.51562H15.7031C16.1348 1.51562 16.5 1.88086 16.5 2.3125C16.5 2.77734 16.1348 3.10938 15.7031 3.10938H5.60938C5.14453 3.10938 4.8125 2.77734 4.8125 2.3125C4.8125 1.88086 5.14453 1.51562 5.60938 1.51562ZM5.60938 6.82812H15.7031C16.1348 6.82812 16.5 7.19336 16.5 7.625C16.5 8.08984 16.1348 8.42188 15.7031 8.42188H5.60938C5.14453 8.42188 4.8125 8.08984 4.8125 7.625C4.8125 7.19336 5.14453 6.82812 5.60938 6.82812ZM5.60938 12.1406H15.7031C16.1348 12.1406 16.5 12.5059 16.5 12.9375C16.5 13.4023 16.1348 13.7344 15.7031 13.7344H5.60938C5.14453 13.7344 4.8125 13.4023 4.8125 12.9375C4.8125 12.5059 5.14453 12.1406 5.60938 12.1406ZM0.03125 6.82812C0.03125 6.39648 0.363281 6.03125 0.828125 6.03125H2.42188C2.85352 6.03125 3.21875 6.39648 3.21875 6.82812V8.42188C3.21875 8.88672 2.85352 9.21875 2.42188 9.21875H0.828125C0.363281 9.21875 0.03125 8.88672 0.03125 8.42188V6.82812ZM0.828125 11.3438H2.42188C2.85352 11.3438 3.21875 11.709 3.21875 12.1406V13.7344C3.21875 14.1992 2.85352 14.5312 2.42188 14.5312H0.828125C0.363281 14.5312 0.03125 14.1992 0.03125 13.7344V12.1406C0.03125 11.709 0.363281 11.3438 0.828125 11.3438Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default List;
