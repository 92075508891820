import { orderBy } from 'lodash';
import { Device, Location, ResourceProfile } from '@raydiant/api-client-js';
import { BatchDevice } from '../../selectors/v2/devices';
import { mapScreensFilterQuery } from './ScreensFilter/utils';
import { getDeviceConnectionStatus } from '../../utilities';

export interface DeviceResultsByLocation {
  [locationId: string]: {
    location: Location;
    devices: BatchDevice[];
  };
}

export const sortLocations = (data: Location[]) => {
  return orderBy(data, ['location.name'], ['asc']);
};

export const getQueryFilteredLocations = (
  locations: Location[],
  query?: string,
) => {
  const searchQuery = mapScreensFilterQuery(query || '');
  const selectedLocationIds = searchQuery.locations?.locationIds || [];

  if (selectedLocationIds.length === 0) return locations;

  const filterdLocations = locations.filter((location) =>
    selectedLocationIds.includes(location.id),
  );

  return sortLocations(filterdLocations);
};

export const getDevicesByLocationId = (
  locations: Location[],
  devices: BatchDevice[],
) => {
  const deviceResults: DeviceResultsByLocation = {};

  locations.forEach(
    (location: Location) =>
      (deviceResults[location.id] = {
        location,
        devices: [],
      }),
  );

  devices.forEach((device) => {
    const locationId = device?.location?.id || '';
    if (deviceResults[locationId]) {
      deviceResults[locationId].devices.push(device);
    }
  });
  return deviceResults;
};

export const getLimitedAccessUsersWithScreens = (devices: Device[]) => {
  type ProfileDeviceStruct = {
    [key: string]: {
      profile: ResourceProfile;
      devices: Device[];
    };
  };

  const profileDevice: ProfileDeviceStruct = {};

  devices.forEach((device) => {
    device.resource?.r?.resourceACLs.forEach((acl) => {
      const profileId = acl?.r?.grantProfile?.id || '';
      if (profileDevice.hasOwnProperty(profileId)) {
        profileDevice[profileId] = {
          ...profileDevice[profileId],
          devices: [...profileDevice[profileId].devices, device],
        };
      } else {
        if (acl.r.grantProfile === null) return;
        profileDevice[profileId] = {
          profile: acl.r.grantProfile,
          devices: [device],
        };
      }
    });
  });

  return Object.values(profileDevice);
};

const avatarColorSet = [
  {
    backgroundColor: 'bg-[#ffebec]',
    color: 'text-[#c22b33]',
  },
  {
    backgroundColor: 'bg-[#e5fbf8]',
    color: 'text-[#058a82]',
  },
  {
    backgroundColor: 'bg-[#f0eafa]',
    color: 'text-[#6e33c9]',
  },
  {
    backgroundColor: 'bg-[#e5ecfb]',
    color: 'text-[#0530a6]',
  },
  {
    backgroundColor: 'bg-[#ffedea]',
    color: 'text-[#d43b1f]',
  },
  {
    backgroundColor: 'bg-[#e7f7ed]',
    color: 'text-[#0f7a33]',
  },
];

export const getAvatarStyle = (index: number) => {
  const backgroundColorIndex = index % 6;
  switch (backgroundColorIndex) {
    case 0:
      return avatarColorSet[0];
    case 1:
      return avatarColorSet[1];
    case 2:
      return avatarColorSet[2];
    case 3:
      return avatarColorSet[3];
    case 4:
      return avatarColorSet[4];
    case 5:
      return avatarColorSet[5];

    default:
      return avatarColorSet[0];
  }
};

export const getInitialsFromName = (name: string) => {
  const [firstName, lastName] = name.split(' ');

  let initials = '';

  if (firstName) {
    initials += firstName.charAt(0);
  }

  if (lastName) {
    initials += lastName.charAt(0);
  }

  return initials.toUpperCase();
};

export const getConnectionStatusFilteredDevice = (
  devices: BatchDevice[],
  query: string,
  lastLoadedDate: string,
): BatchDevice[] => {
  const filterDeviceByConnectionStatusQuery = devices.filter((device) => {
    if (!query) return true;
    const { isOnline } = getDeviceConnectionStatus(device, lastLoadedDate);
    if (query.includes('connection_status=online')) {
      return isOnline;
    }
    if (query.includes('connection_status=offline')) {
      return !isOnline;
    }
    return true;
  });
  return filterDeviceByConnectionStatusQuery;
};
