import { Location, ApiClientError } from '@raydiant/api-client-js';
import { keys } from '../queryClient';
import raydiant from '../clients/miraClient';
import useProtectedQuery from './useProtectedQuery';

export default function useLocations() {
  return useProtectedQuery<Location[], ApiClientError>(keys.locations(), () =>
    raydiant.getLocations(),
  );
}
