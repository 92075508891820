import MarketplaceModal from './MarketplaceModal';

interface OutstandingInvoicesModalProps {
  open: boolean;
  hasPermission: boolean;
  onClick: () => void;
  onCancel: () => void;
}

const OutstandingInvoicesModal = ({
  open,
  hasPermission,
  onCancel,
  onClick,
}: OutstandingInvoicesModalProps) => {
  const renderModalWithPermission = () => (
    <MarketplaceModal
      content="Oops! Looks like you have outstanding invoices and are trying to purchase an app subscription. Press “View Invoices” to see your outstanding invoices."
      heading="Outstanding invoices"
      open={open}
      handleClick={onClick}
      handleClose={onCancel}
      onClickButtonText="View Invoices"
    />
  );

  const renderModalWithoutPermission = () => (
    <MarketplaceModal
      content="Thanks for expressing interest in our apps. Please contact your Raydiant account admin to purchase an app subscription."
      heading="Interested in our apps?"
      open={open}
      handleClick={onClick}
    />
  );

  return (
    <>
      {hasPermission
        ? renderModalWithPermission()
        : renderModalWithoutPermission()}
    </>
  );
};

export default OutstandingInvoicesModal;
