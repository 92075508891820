import { Location } from '@raydiant/api-client-js';
import Dialog, {
  DialogHeader,
  DialogHeading,
} from '@raydiant/raydial/components/Dialog';
import Modal from '@raydiant/raydial/components/Modal';
import Button from '@raydiant/raydial/components/Button';
import Text from '@raydiant/raydial/components/Text';
import { SharableListItem } from './ManageAccessDialog';

interface RemoveAccessConfirmationDialogProps {
  isOpen: boolean;
  isLoading: boolean;
  location: Location;
  user: SharableListItem;
  onClose: () => void;
  onClick: (value: string) => void;
}

const RemoveAccessConfirmationDialog = ({
  isOpen,
  isLoading,
  location,
  user,
  onClose,
  onClick,
}: RemoveAccessConfirmationDialogProps) => {
  return (
    <Modal isOpen={isOpen} className="text-black">
      <Dialog showCloseButton onClose={onClose}>
        <DialogHeader>
          <DialogHeading>
            <span>Confirm remove access?</span>
          </DialogHeading>
        </DialogHeader>

        <div className="px-4 pt-4 mt-2 mb-16">
          <Text variant="body">
            Are you sure you want to remove access for {user.name}. This user
            will not be able to manage screens for the {location.name} location.
          </Text>
        </div>

        <div className="flex flex-col-reverse justify-between mb-4 gap-3 px-0 md:gap-6 md:flex-row md:px-4">
          <Button
            onClick={() => onClose()}
            variant="secondary"
            size="large"
            className="grow"
            isDisabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={() => onClick(user.id)}
            variant="caution"
            size="large"
            className="grow"
            isDisabled={isLoading}
            loading={isLoading}
          >
            Confirm
          </Button>
        </div>
      </Dialog>
    </Modal>
  );
};

export default RemoveAccessConfirmationDialog;
