import CallToActionCarousel from './AnalyticsCallToAction/CallToActionCarousel';
import VIOnlyIllustration from './assets/cta-desktop.png';
import VIOnlyIllustrationMobile from './assets/cta-mobile.png';

const carouselItems = [
  {
    label: 'Effortlessly track shopper’s product pickups',
  },
  {
    label: 'Enhance merchandise using real time data',
  },
  {
    label: 'Boost sales with lift-and-learn digital',
  },
];

const CallToAction = () => {
  return (
    <CallToActionCarousel
      header="Craft a connected retail experience with interactive screens and shelves"
      content={carouselItems}
      backgroundColor="linear-gradient(126deg, #4270eb -7%, #153eb1 139%)"
      desktopBackgroundImage={VIOnlyIllustration}
      mobileBackgroundImage={VIOnlyIllustrationMobile}
      type="product-engagement"
      href="https://www.raydiant.com/solutions/lift-and-learn"
    />
  );
};

export default CallToAction;
