import { ApiClientError } from '@raydiant/api-client-js';
import { useQueryClient } from 'react-query';
import raydiant from '../clients/raydiant';
import { keys } from '../queryClient';
import useProtectedMutation from './useProtectedMutation';
import { Location, InviteUserToLocation } from '@raydiant/api-client-js';

export default function useInviteUserToLocation() {
  const queryClient = useQueryClient();
  const mutation = useProtectedMutation<
    Location,
    ApiClientError,
    { locationId: string; params: Partial<InviteUserToLocation> }
  >(
    ({ locationId, params }) =>
      raydiant.inviteUserToLocation(locationId, params),
    {
      onSuccess: (data) => {
        const locations =
          queryClient.getQueryData<Location[]>(keys.locations()) || [];

        const selectedIndex = locations.findIndex(
          (location) => location.id === data.id,
        );
        const updatedLocations = [...locations];
        updatedLocations[selectedIndex] = data;

        queryClient.setQueryData(keys.locations(), updatedLocations);
      },
    },
  );

  return mutation;
}
