import { useTranslation } from 'react-i18next';
import totalInteractions from '../../../assets/total-interactions.svg';
import totalSessions from '../../../assets/total-sessions.svg';
import TrendArrow from './TrendArrow';
import { getDisplayValue } from './utils';
import DataCard, { DataCardDisplayProps } from './DataCard';

type TotalCardProps = DataCardDisplayProps;

const TotalCard = ({
  change,
  showEmpty = false,
  type,
  value,
  noPreviousData = false,
  isRXEnabled = false,
}: TotalCardProps) => {
  const { t } = useTranslation();
  const isSessions = type === 'sessions';
  const interactionsLabel = isRXEnabled
    ? t('analytics.totalInteractions')
    : t('analytics.totalScreenClicks');
  const label = isSessions
    ? t('analytics.totalEngagedShoppers')
    : interactionsLabel;

  return (
    <DataCard type={type}>
      <img
        className="image"
        src={isSessions ? totalSessions : totalInteractions}
        alt={label}
      />
      <div className="w-full flex justify-end text">
        <div className="mr-6">
          <p className="display-value">
            {showEmpty ? '— —' : getDisplayValue(value)}
          </p>
          <p className="text-sm font-semibold align-top">{label}</p>
          {showEmpty && <p className="text-xs">{t('analytics.noValidData')}</p>}
          {!showEmpty && (
            <>
              {noPreviousData ? (
                <p className="text-xs">
                  {t('analytics.trafficAwarenessPage.overview.noPrevious')}
                </p>
              ) : (
                <TrendArrow
                  change={change}
                  label={t('analytics.fromThePreviousPeriod')}
                />
              )}
            </>
          )}
        </div>
      </div>
    </DataCard>
  );
};

export default TotalCard;
