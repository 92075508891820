import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as paths from '../../../../routes/paths';
import { selectUserProfile } from '../../../../selectors/user';
import config from '../../../../config';
import EmptyLocationCardItem from './EmptyLocationCardItem';
import { hasRoleOrHigher } from '../../../../utilities';

interface EmptyLocationCardProps {
  onAddScreen: () => void;
}

const EmptyLocationCard = ({ onAddScreen }: EmptyLocationCardProps) => {
  const history = useHistory();
  const currentUser = useSelector(selectUserProfile);

  if (!currentUser) return null;
  const isEnterpriseUser = currentUser?.domainId !== null;
  const isAdminOrHigher = hasRoleOrHigher('admin', currentUser);

  const addScreen = {
    content: 'Got your Raydiant device?\nStart pariing it now!',
    buttonText: 'Add Screen',
    onClick: () => onAddScreen(),
  };

  const inviteUser = {
    content: 'Or want a user to add and manage\ndevices for this location?',
    buttonText: 'Invite Users',
    onClick: () => {
      window.open(config.accountManagementUrl, '_blank');
    },
  };

  const goToLibrary = {
    content: 'Dont have a Raydiant device yet?\nStart creating content!',
    buttonText: 'Go to Libarary',
    onClick: () => history.push(paths.library.pattern),
  };

  const renderEnterpriseEmptyLocationCard = () => (
    <>
      <EmptyLocationCardItem
        key={addScreen.buttonText}
        {...addScreen}
        classNames={cn(
          'border-b-[1px] pb-4 mb-4',
          'md:border-0 md:border-r-[1px] md:mb-0 md:pb-0',
        )}
      />

      {isAdminOrHigher && (
        <EmptyLocationCardItem
          key={inviteUser.buttonText}
          {...inviteUser}
          classNames={cn(
            'border-b-[1px] pb-4 mb-4',
            'md:border-b-0 md:border-r-[1px] md:mb-0 md:pb-0',
          )}
        />
      )}

      <EmptyLocationCardItem key={goToLibrary.buttonText} {...goToLibrary} />
    </>
  );

  const renderSMBEmptyLocationCard = () => (
    <>
      <EmptyLocationCardItem
        key={addScreen.buttonText}
        {...addScreen}
        classNames={cn(
          'border-b-[1px] pb-4 mb-4',
          'md:border-0 md:border-r-[1px] md:mb-0 md:pb-0',
        )}
      />
      <EmptyLocationCardItem
        key={inviteUser.buttonText}
        {...inviteUser}
        classNames={cn(
          'border-b-[1px] pb-4 mb-4',
          'md:border-b-0 md:border-r-[1px] md:mb-0 md:pb-0',
        )}
      />
      <EmptyLocationCardItem key={goToLibrary.buttonText} {...goToLibrary} />
    </>
  );

  return (
    <div
      className={cn(
        'bg-white flex flex-col p-4 pb-6 rounded-3xl',
        'md:flex-row',
      )}
    >
      {isEnterpriseUser
        ? renderEnterpriseEmptyLocationCard()
        : renderSMBEmptyLocationCard()}
    </div>
  );
};

export default EmptyLocationCard;
