import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type SidebarFooterProps = {
  className?: string;
  isOpen?: boolean;
};

const SidebarFooter = ({ className, isOpen }: SidebarFooterProps) => {
  const { t } = useTranslation();
  return (
    <footer
      className={classNames('text-primary', isOpen ? 'open' : '', className)}
    >
      {isOpen && (
        <p className="name text-primary">
          {t('sidebar.footer.customerExperience')}
        </p>
      )}
      {!isOpen && (
        <div className="grid grid-cols-1 gap-3 text-primary">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28.995"
            height="28.996"
            viewBox="0 0 28.995 28.996"
            className="w-7 h-7 text-inherit"
            fill="currentColor"
          >
            <g
              id="Group_2216"
              data-name="Group 2216"
              transform="translate(-1.5 -1.5)"
            >
              <path
                id="Union_8"
                data-name="Union 8"
                d="M1545.72-550.007a11.453,11.453,0,0,1-11.452-11.453v-1.273h11.452a1.272,1.272,0,0,1,1.272,1.273v11.453Zm-25.45,0a1.271,1.271,0,0,1-1.273-1.272v-11.452h12.726v11.452a1.272,1.272,0,0,1-1.273,1.272Zm14-15.269V-578h6.363a6.361,6.361,0,0,1,6.361,6.362,6.362,6.362,0,0,1-6.361,6.363Zm-15.271,0A12.724,12.724,0,0,1,1531.723-578v12.725Z"
                transform="translate(-1516.998 580.002)"
              />
            </g>
          </svg>
          <p className="font-medium text-primary">{t('sidebar.footer.cx')}</p>
        </div>
      )}
    </footer>
  );
};

export default SidebarFooter;
