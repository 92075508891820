import { SVGProps } from 'react';

interface CircleExclamationProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const CircleExclamation = ({ size = 18, ...props }: CircleExclamationProps) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.80429 1.86025C7.30387 1.86025 5.03683 3.1938 3.76995 5.32749C2.53641 7.49452 2.53641 10.1283 3.76995 12.262C5.03683 14.429 7.30387 15.7292 9.80429 15.7292C12.2714 15.7292 14.5384 14.429 15.8053 12.262C17.0388 10.1283 17.0388 7.49452 15.8053 5.32749C14.5384 3.1938 12.2714 1.86025 9.80429 1.86025ZM9.80429 17.3295C6.73711 17.3295 3.93664 15.7292 2.40305 13.0621C0.869465 10.4283 0.869465 7.19447 2.40305 4.52736C3.93664 1.89359 6.73711 0.25998 9.80429 0.25998C12.8381 0.25998 15.6386 1.89359 17.1722 4.52736C18.7058 7.19447 18.7058 10.4283 17.1722 13.0621C15.6386 15.7292 12.8381 17.3295 9.80429 17.3295ZM9.80429 4.52736C10.2377 4.52736 10.6044 4.89409 10.6044 5.32749V9.06145C10.6044 9.52819 10.2377 9.86158 9.80429 9.86158C9.33754 9.86158 9.00415 9.52819 9.00415 9.06145V5.32749C9.00415 4.89409 9.33754 4.52736 9.80429 4.52736ZM10.8711 11.9953C10.8711 12.5954 10.371 13.0621 9.80429 13.0621C9.20419 13.0621 8.73744 12.5954 8.73744 11.9953C8.73744 11.4285 9.20419 10.9284 9.80429 10.9284C10.371 10.9284 10.8711 11.4285 10.8711 11.9953Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default CircleExclamation;
