import { SVGProps } from 'react';

interface InfoCircleProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const InfoCircle = ({ size = 18, ...props }: InfoCircleProps) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 1.71875C6.50977 1.71875 4.25195 3.04688 2.99023 5.17188C1.76172 7.33008 1.76172 9.95312 2.99023 12.0781C4.25195 14.2363 6.50977 15.5312 9 15.5312C11.457 15.5312 13.7148 14.2363 14.9766 12.0781C16.2051 9.95312 16.2051 7.33008 14.9766 5.17188C13.7148 3.04688 11.457 1.71875 9 1.71875ZM9 17.125C5.94531 17.125 3.15625 15.5312 1.62891 12.875C0.101562 10.252 0.101562 7.03125 1.62891 4.375C3.15625 1.75195 5.94531 0.125 9 0.125C12.0215 0.125 14.8105 1.75195 16.3379 4.375C17.8652 7.03125 17.8652 10.252 16.3379 12.875C14.8105 15.5312 12.0215 17.125 9 17.125ZM7.67188 11.2812H8.46875V9.15625H7.67188C7.20703 9.15625 6.875 8.82422 6.875 8.35938C6.875 7.92773 7.20703 7.5625 7.67188 7.5625H9.26562C9.69727 7.5625 10.0625 7.92773 10.0625 8.35938V11.2812H10.3281C10.7598 11.2812 11.125 11.6465 11.125 12.0781C11.125 12.543 10.7598 12.875 10.3281 12.875H7.67188C7.20703 12.875 6.875 12.543 6.875 12.0781C6.875 11.6465 7.20703 11.2812 7.67188 11.2812ZM9 6.5C8.40234 6.5 7.9375 6.03516 7.9375 5.4375C7.9375 4.87305 8.40234 4.375 9 4.375C9.56445 4.375 10.0625 4.87305 10.0625 5.4375C10.0625 6.03516 9.56445 6.5 9 6.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default InfoCircle;
