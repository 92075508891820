import { SVGProps } from 'react';
import cn from 'classnames';

interface LocationButtonGroupItemProps {
  className?: string;
  icon: SVGProps<SVGSVGElement>;
  label: string;
  onClick: () => void;
}

const LocationButtonGroupItem = ({
  className,
  icon,
  label,
  onClick,
}: LocationButtonGroupItemProps) => {
  return (
    <div
      className={cn(
        'flex items-center py-2.5 px-4 cursor-pointer hover:bg-gray-200',
        className,
      )}
      onClick={() => onClick()}
    >
      <div className="flex justify-end items-center mr-2 w-5">{icon}</div>
      <div className="pl-2 text-base">{label}</div>
    </div>
  );
};

export default LocationButtonGroupItem;
