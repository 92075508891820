import { TV } from '../../Icons';

interface LocationScreenStatusProps {
  numOfScreens: number;
}

const LocationScreenStatus = ({ numOfScreens }: LocationScreenStatusProps) => {
  return (
    <div className="relative mt-3">
      <div className="flex">
        <TV />
        <div className="text-center ml-1 w-9 bg-gray-200 text-gray-600 rounded-3xl text-base font-medium">
          {numOfScreens || 0}
        </div>
      </div>
    </div>
  );
};

export default LocationScreenStatus;
