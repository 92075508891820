import { useState, useEffect, useCallback } from 'react';
import Button from 'raydiant-elements/core/Button';
import TextField from 'raydiant-elements/core/TextField';
import Link from 'raydiant-elements/core/Link';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import Heading from 'raydiant-elements/core/Heading';
import Column from 'raydiant-elements/layout/Column';
import Row from 'raydiant-elements/layout/Row/Row';
import { Device } from '@raydiant/api-client-js';
import { Grid } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlineIcon from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import useStyles from './DeviceIntegrationManager.styles';
import * as deviceActions from '../../actions/devices';
import { useDispatch } from 'react-redux';
import DeviceIntegrationDialog from '../DeviceIntegrationDialog';

interface DeviceIntegrationManagerProps {
  device: Device;
}

const DeviceIntegrationManager = ({
  device,
}: DeviceIntegrationManagerProps) => {
  const [selectedDevice, setSelectedDevice] = useState(device);
  const [updatedToastGUID, setToastGUID] = useState<string | null>(null);
  const [onEdit, setOnEdit] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleUpdateIntegration = useCallback(
    (toastGUID) => {
      const updatedDevice = {
        ...device,
        toast_restaurant_guid: toastGUID || null,
      };
      dispatch(deviceActions.updateDevice(updatedDevice, {}));
      setSelectedDevice(updatedDevice);
    },
    [dispatch, device],
  );

  const handleEdit = () => {
    setOnEdit(true);
  };

  const handleCancelEdit = () => {
    setToastGUID(selectedDevice.toastRestaurantGuid);
    setOnEdit(false);
  };

  const handleSave = () => {
    if (onEdit) {
      setOnEdit(false);
    }
    handleUpdateIntegration(updatedToastGUID);
  };

  const handleDelete = () => {
    setToastGUID(null);
    handleUpdateIntegration(null);
    closeDeleteConfirmationModal();
  };

  const openDeleteConfirmationModal = () => {
    setDeleteConfirmationOpen(true);
  };

  const closeDeleteConfirmationModal = () => {
    setDeleteConfirmationOpen(false);
  };

  useEffect(() => {
    setSelectedDevice(device);
    setToastGUID(device.toastRestaurantGuid);
  }, [device]);

  const showPostSaveButton = !selectedDevice.toastRestaurantGuid;
  const showIconButtonGroup = !showPostSaveButton && !onEdit;
  const showEditButtonGroup = !showPostSaveButton && onEdit;

  const renderIconButtonGroup = () => (
    <>
      <div>
        <IconButton size="small" edge="end" onClick={() => handleEdit()}>
          <EditOutlineIcon />
        </IconButton>
      </div>
      <div>
        <IconButton
          size="small"
          edge="end"
          onClick={() => openDeleteConfirmationModal()}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </div>
    </>
  );

  const renderEditButtonGroup = () => {
    return (
      <Grid container justifyContent="space-between">
        <Grid item className={classes.editButton}>
          <Button fullWidth onClick={() => handleCancelEdit()}>
            Cancel
          </Button>
        </Grid>
        <Grid item className={classes.editButton}>
          <Button
            fullWidth
            color="primary"
            disabled={!updatedToastGUID}
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <div className={classes.section}>
        <Column>
          <div>
            <Heading size={5} overline>
              Analytics Integrations
            </Heading>
            <InputHelperText className={classes.inputText}>
              <Link
                href="https://support.raydiant.com/hc/en-us/articles/"
                target="_blank"
              >
                What's this?
              </Link>
            </InputHelperText>
          </div>

          <Column>
            <Row key="toast_integration" halfMargin center>
              <TextField
                disabled={showIconButtonGroup}
                placeholder="ex: 3478z12g-y57q-f34w9-1776v85tho"
                label=""
                value={updatedToastGUID ?? ''}
                onChange={setToastGUID}
              />
              {showIconButtonGroup && renderIconButtonGroup()}
            </Row>
          </Column>

          {showPostSaveButton && (
            <Button
              fullWidth
              color="primary"
              disabled={!updatedToastGUID}
              onClick={() => handleSave()}
            >
              Save
            </Button>
          )}

          {showEditButtonGroup && renderEditButtonGroup()}
        </Column>
      </div>
      <DeviceIntegrationDialog
        open={deleteConfirmationOpen}
        handleClose={closeDeleteConfirmationModal}
        handleClick={handleDelete}
      />
    </>
  );
};

export default DeviceIntegrationManager;
