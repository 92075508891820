import { useDispatch } from 'react-redux';
import { Fragment, useCallback, useState } from 'react';
import Link from 'raydiant-elements/core/Link';
import { getFirstName, getLastName } from '../../../utilities/user';
import * as routes from '../../../routes';
import * as userActions from '../../../actions/user';
import * as paths from '../../../routes/paths';
import cn from 'classnames';
import { Menu as HeadlessMenu, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import useCurrentUser from '../../../hooks/useCurrentUser';
import Avatar from './Avatar';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

const isStagingOrLocal = () => {
  if (typeof window === 'undefined') return true;

  return [
    window.location.hostname.includes('staging'),
    window.location.hostname.includes('localhost'),
  ].includes(true);
};

const getAccountManagementUrl = () => {
  if (isStagingOrLocal()) return 'https://account.staging.raydiant.com';

  return 'https://account.raydiant.com';
};

const getDeveloperExperienceUrl = () => {
  if (isStagingOrLocal()) return 'https://developers.staging.raydiant.com';

  return 'https://developers.raydiant.com';
};

const getEmployeeExperienceUrl = () => {
  if (isStagingOrLocal()) return 'https://engage.staging.raydiant.com';

  return 'https://engage.raydiant.com';
};

const URLS = {
  accountManagement: {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
        stroke="currentColor"
        fill="currentColor"
        className="size-6"
      >
        <path d="M32 416c0 17.7 14.3 32 32 32l320 0c17.7 0 32-14.3 32-32l0-320c0-17.7-14.3-32-32-32L64 64C46.3 64 32 78.3 32 96l0 320zM0 96C0 60.7 28.7 32 64 32l320 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zm96 80c0-8.8 7.2-16 16-16l48 0 0-24c0-8.8 7.2-16 16-16s16 7.2 16 16l0 40 0 40c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-24-48 0c-8.8 0-16-7.2-16-16zm128 0c0-8.8 7.2-16 16-16l96 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-96 0c-8.8 0-16-7.2-16-16zM96 336c0-8.8 7.2-16 16-16l112 0 0-24c0-8.8 7.2-16 16-16s16 7.2 16 16l0 40 0 40c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-24-112 0c-8.8 0-16-7.2-16-16zm192 0c0-8.8 7.2-16 16-16l32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16z" />
      </svg>
    ),
    label: 'accountAvatar.accountConsole',
    url: getAccountManagementUrl(),
  },
  dx: {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
        />
      </svg>
    ),
    label: 'accountAvatar.developerExperience',
    url: getDeveloperExperienceUrl(),
  },
  ex: {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
        />
      </svg>
    ),
    label: 'accountAvatar.employeeExperience',
    url: getEmployeeExperienceUrl(),
  },
  helpCenter: {
    label: 'accountAvatar.helpCenter',
    url: 'https://support.raydiant.com',
  },
};

const AccountAvatar = () => {
  const dispatch = useDispatch();
  const [, setAnchorEl] = useState<Element | null>(null);

  const { data: currentUser } = useCurrentUser();

  const { t } = useTranslation();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = useCallback(() => {
    dispatch(userActions.logoutUser({ backTo: paths.home() }));
  }, [dispatch]);

  const serviceCloudChatButton = document.querySelector<HTMLElement>(
    '#helpButtonSpan > span.message',
  );

  const openChat = () => {
    serviceCloudChatButton?.click();
    handleClose();
  };

  const location = useLocation();

  const linkIsActive = (link: string) => location.pathname === link;

  return (
    <HeadlessMenu
      as="div"
      className={cn('relative menu account-avatar-wrapper dropdown-wrapper')}
    >
      <HeadlessMenu.Button>
        <div className="container">
          <div className="avatar-wrapper">
            <Avatar user={currentUser!} />
            <div className="name-wrapper">
              <p className="first-name">{getFirstName(currentUser!)}</p>
              <p className="last-name">{getLastName(currentUser!)}</p>
            </div>
          </div>
          <svg
            className="size-6 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <g transform="translate(9.223 2.215)">
              <circle data-name="Ellipse 109" cx="2.499" cy="2.499" r="2.499" />
              <circle
                data-name="Ellipse 110"
                cx="2.5"
                cy="2.5"
                r="2.5"
                transform="translate(0 6.971)"
              />
              <circle
                data-name="Ellipse 111"
                cx="2.499"
                cy="2.499"
                r="2.499"
                transform="translate(0 14.692)"
              />
            </g>
          </svg>
        </div>
      </HeadlessMenu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <HeadlessMenu.Items
          as="ul"
          className="origin-top-right dropdown-wrapper py-3"
        >
          <HeadlessMenu.Item
            as="li"
            className={classNames(
              'support menu-item',
              linkIsActive(routes.account()) ? 'active' : '',
            )}
          >
            <Link href={routes.account()}>
              <span className="mr-3">{t('account')}</span>
            </Link>
          </HeadlessMenu.Item>
          <HeadlessMenu.Item
            as="li"
            className={'live-support support menu-item'}
            onClick={openChat}
          >
            <Link>
              <span className="mr-3">{t('liveSupport')}</span>
            </Link>
          </HeadlessMenu.Item>
          <HeadlessMenu.Item as="li" className="support menu-item">
            <Link href={URLS.helpCenter.url} target="_blank">
              <span className="mr-3">{t(URLS.helpCenter.label)}</span>
            </Link>
          </HeadlessMenu.Item>
          <HeadlessMenu.Item as="li" className="menu-item menu-item-label">
            <label>{t('accountAvatar.switchTo')}</label>
          </HeadlessMenu.Item>
          <HeadlessMenu.Item as="li" className="dx menu-item">
            <Link href={URLS.dx.url} target="_blank">
              <span className="mr-3">{t(URLS.dx.label)}</span> {URLS.dx.icon}
            </Link>
          </HeadlessMenu.Item>
          <HeadlessMenu.Item as="li" className="ex menu-item">
            <Link href={URLS.ex.url} target="_blank">
              <span className="mr-3">{t(URLS.ex.label)}</span> {URLS.ex.icon}
            </Link>
          </HeadlessMenu.Item>
          <HeadlessMenu.Item as="li" className="dx menu-item">
            <Link href={URLS.accountManagement.url} target="_blank">
              <span className="mr-3">{t(URLS.accountManagement.label)}</span>{' '}
              {URLS.accountManagement.icon}
            </Link>
          </HeadlessMenu.Item>
          <HeadlessMenu.Item as="li" className="sign-out menu-item">
            <Link className="cursor-pointer" href="#" onClick={handleLogout}>
              <span className="mr-3">{t('accountAvatar.signOut')}</span>
            </Link>
          </HeadlessMenu.Item>
        </HeadlessMenu.Items>
      </Transition>
    </HeadlessMenu>
  );
};

export default AccountAvatar;
