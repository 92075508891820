import { Device } from '@raydiant/api-client-js';
import { Theme } from 'raydiant-elements/theme';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { buttonReset } from 'raydiant-elements/mixins';
import SidebarMenuTooltip from '../../components/Sidebar/SidebarMenuTooltip';

interface DeviceAIStatusProps {
  device: Device;
  onEditSettings?: () => void;
}

const DevicePerchStatus = ({ device, onEditSettings }: DeviceAIStatusProps) => {
  const classes = useStyles();

  return (
    <SidebarMenuTooltip title="RX settings" placement="bottom" arrow>
      <button className={classes.button} onClick={onEditSettings}>
        <VideocamOutlinedIcon fontSize="inherit" />
      </button>
    </SidebarMenuTooltip>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    button: {
      ...buttonReset(),
      display: 'flex',
      flexDirection: 'column',
      fontSize: 24,
      alignItems: 'center',
      justifyContent: 'center',
      opacity: 0.6,
      padding: theme.spacing(0),
      '&:hover': {
        opacity: 0.8,
      },
    },
  });
});

export default DevicePerchStatus;
