import { Device, Location } from '@raydiant/api-client-js';
import LocationScreenStatus from './LocationScreenStatus';
import LocationShareStatus from './LocationShareStatus';
import LocationButtonGroup from './LocationButtonGroup';
import { useSelector } from 'react-redux';
import { selectIsEnterpriseUser } from '../../../../selectors/user';
import { LocationsPageModalType } from '../../LocationsPage';

interface LocationActionBarProps {
  devices: Device[];
  location: Location;
  onActionBarClick: (locationId: string) => void;
  onActionBarItemClick: (modalType: LocationsPageModalType) => void;
  onClose: () => void;
}

const LocationActionBar = ({
  devices,
  location,
  onActionBarClick,
  onActionBarItemClick,
  onClose,
}: LocationActionBarProps) => {
  const isEnterpriseUser = useSelector(selectIsEnterpriseUser);

  return (
    <div className="flex gap-4">
      <div className="hidden md:flex gap-2">
        <LocationScreenStatus numOfScreens={devices.length} />
        {isEnterpriseUser && (
          <LocationShareStatus devices={devices} location={location} />
        )}
      </div>
      <LocationButtonGroup
        devices={devices}
        location={location}
        onButtonGroupClick={onActionBarClick}
        onButtonGroupItemClick={onActionBarItemClick}
        onButtonGroupClose={onClose}
      />
    </div>
  );
};

export default LocationActionBar;
