import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { EngagementByDay, GetAnalytics } from '@raydiant/api-client-js';
import { useTranslation } from 'react-i18next';
import ChartCard from './ChartCard/ChartCard';
import {
  CHART_DEFAULT_OPTIONS,
  CHART_DEFAULT_AXIS_OPTIONS,
  formatDateLabel,
} from './utils';
import ChartToolTip, { EChartsToolTipParams } from './ChartToolTip';
import { renderToStaticMarkup } from 'react-dom/server';
import Hidden from 'raydiant-elements/layout/Hidden';
import { Popover } from '@headlessui/react';
import QuestionMarkIcon from '../../components/QuestionMarkIcon';
import { abbreviateNumber } from 'js-abbreviation-number';
import PopoverButton from '../../components/Popover/PopoverButton';

echarts.use([LineChart]);

type EngagementByDayChartView = keyof EngagementByDay;
type EngagementByDayChartProps = {
  data: EngagementByDay[];
  showClicks?: boolean;
  showPickups?: boolean;
  showSessions?: boolean;
  isRXEnabled: boolean;
};

const getXAxisData = (data: GetAnalytics['engagementByDay']) =>
  data?.map((value) => formatDateLabel(value.timestamp, 'ddd D[\n]MMMM'));

const getYAxisData = (
  data: GetAnalytics['engagementByDay'],
  view: EngagementByDayChartView,
) => data?.map((value) => value[view]);

const EngagementByDayChart = ({
  data,
  showClicks,
  showPickups,
  showSessions,
  isRXEnabled,
}: EngagementByDayChartProps) => {
  const { t } = useTranslation();

  const getSeries = (data: GetAnalytics['engagementByDay']) => {
    let series = [];

    if (isRXEnabled && showPickups) {
      series.push({
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#879cff',
            },
            {
              offset: 1,
              color: '#0530a6',
            },
          ]),
        },
        data: getYAxisData(data, 'pickups'),
        emphasis: {
          focus: 'series',
        },
        lineStyle: {
          width: 0,
        },
        name: t('analytics.productPickups'),
        showSymbol: false,
        smooth: false,
        stack: 'Total',
        type: 'line',
      });
    }

    if (showClicks) {
      series.push({
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#4270eb',
            },
            {
              offset: 1,
              color: '#e5eeff',
            },
          ]),
        },
        data: getYAxisData(data, 'clicks'),
        emphasis: {
          focus: 'series',
        },
        lineStyle: {
          width: 0,
        },
        name: t('analytics.screenClicks'),
        smooth: false,
        showSymbol: false,
        stack: 'Total',
        type: 'line',
      });
    }

    if (showSessions) {
      series.push({
        data: getYAxisData(data, 'sessions'),
        lineStyle: {
          color: '#38e3c9',
        },
        name: t('analytics.engagedShoppers'),
        showSymbol: false,
        type: 'line',
      });
    }

    return series;
  };

  const color = isRXEnabled
    ? [...CHART_DEFAULT_OPTIONS.color]
    : ['#4270eb', '#08d4b8'];

  const options = {
    ...CHART_DEFAULT_OPTIONS,
    color,
    legend: {
      ...CHART_DEFAULT_OPTIONS.legend,
      data: [
        { name: t('analytics.productPickups'), icon: 'square' },
        { name: t('analytics.screenClicks'), icon: 'square' },
        { name: t('analytics.engagedShoppers'), icon: 'square' },
        { name: t('analytics.pickupsToScreen'), icon: 'square' },
      ],
    },
    xAxis: {
      ...CHART_DEFAULT_AXIS_OPTIONS.xAxis,
      data: getXAxisData(data),
      name: t('analytics.time').toUpperCase(),
      axisLabel: {
        ...CHART_DEFAULT_AXIS_OPTIONS.xAxis.axisLabel,
        lineHeight: 18,
      },
      nameGap: 55,
    },
    yAxis: {
      ...CHART_DEFAULT_AXIS_OPTIONS.yAxis,
      name: t('analytics.interactions').toUpperCase(),
      type: 'value',
      axisLabel: {
        ...CHART_DEFAULT_AXIS_OPTIONS.yAxis.axisLabel,
      },
    },
    series: getSeries(data),
    tooltip: {
      ...CHART_DEFAULT_OPTIONS.tooltip,
      formatter: (params: EChartsToolTipParams[]) => {
        const index = params[0]?.dataIndex;
        const value = data[index];

        return renderToStaticMarkup(
          <ChartToolTip
            header={
              <p className="text-sm px-2 py-1 text-black">
                {formatDateLabel(value.timestamp, 'ddd, MMMM D, YYYY')}
              </p>
            }
            totalInteractions={abbreviateNumber(value.clicks + value.pickups)}
            values={params}
          />,
        );
      },
    },
  };

  const mobileOptions = {
    ...options,
    legend: {
      ...CHART_DEFAULT_OPTIONS.legend,
      data: [
        { name: t('analytics.productPickups'), icon: 'square' },
        { name: t('analytics.screenClicks'), icon: 'square' },
        { name: t('analytics.engagedShoppers'), icon: 'square' },
        { name: t('analytics.pickupsToScreen'), icon: 'square' },
      ],
      type: 'scroll',
    },
    series: getSeries(data),
  };

  return (
    <ChartCard
      subTitle={''}
      title={
        <div className="flex gap-3 items-center">
          <h2 className="text-black text-xl font-bold">
            {t('analytics.shopperEngagement')!}
          </h2>
          <Popover className="relative">
            <PopoverButton icon={<QuestionMarkIcon />} />
            <Popover.Panel className="absolute z-10 card popover">
              <div className="overflow-hidden">
                <p className="mb-3">
                  {t('analytics.shopperEngagementTooltip')}
                </p>
                <a
                  href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('analytics.learnmore')}
                </a>
              </div>
            </Popover.Panel>
          </Popover>
        </div>
      }
    >
      <div className="min-w-full min-h-full">
        <Hidden xsDown>
          <ReactECharts
            style={{ height: '410px' }}
            option={options}
            onChartReady={(chart) => chart.resize()}
          />
        </Hidden>
        <Hidden smUp>
          <ReactECharts
            style={{ height: '410px' }}
            option={mobileOptions}
            onChartReady={(chart) => chart.resize()}
          />
        </Hidden>
      </div>
    </ChartCard>
  );
};

export default EngagementByDayChart;
