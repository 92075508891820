import Text from '@raydiant/raydial/components/Text';
import Button from '@raydiant/raydial/components/Button';
import { DialogTrigger } from '@raydiant/raydial/components/Dialog';
import Popover from '@raydiant/raydial/components/Popover';
import {
  selectIsEnterpriseUser,
  selectUserProfile,
} from '../../../../selectors/user';
import { useSelector } from 'react-redux';
import { hasRoleOrHigher } from '../../../../utilities';
import Plus from '../../Icons/Plus';

interface AddButtonPopOverProps {
  isOpen: boolean;
  onClick: (open: boolean) => void;
  onScreenClick: () => void;
  onLocationClick: () => void;
}

const AddButtonPopOver = ({
  isOpen,
  onClick,
  onScreenClick,
  onLocationClick,
}: AddButtonPopOverProps) => {
  const currentUser = useSelector(selectUserProfile);
  const isEnterpriseUser = useSelector(selectIsEnterpriseUser);

  if (!currentUser) return null;
  const showAddLocation =
    !isEnterpriseUser ||
    (isEnterpriseUser && hasRoleOrHigher('admin', currentUser));

  return (
    <DialogTrigger isOpen={isOpen} onOpenChange={onClick}>
      <Button
        className="w-[156px] h-14 px-4 py-5 rounded-2xl bg-blue-600"
        aria-label="add"
        variant="primary"
        onClick={() => onClick(true)}
      >
        <div className="flex text-white items-center">
          <Plus />
          <Text className="normal-case ml-2.5" variant="largeSubtitle">
            Add
          </Text>
        </div>
      </Button>
      <Popover className="w-[156px] py-2" offset={2}>
        <button
          className="flex items-center text-lg font-regular h-9 px-2.5 py-4 w-full hover:bg-gray-100 "
          onClick={() => onScreenClick()}
        >
          Screen
        </button>
        {showAddLocation && (
          <button
            className="flex items-center text-lg font-regular h-9 px-2.5 py-4 w-full hover:bg-gray-100"
            disabled={!showAddLocation}
            onClick={() => onLocationClick()}
          >
            Location
          </button>
        )}
      </Popover>
    </DialogTrigger>
  );
};

export default AddButtonPopOver;
