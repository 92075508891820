import InputLabel from 'raydiant-elements/core/InputLabel';
import Row from 'raydiant-elements/layout/Row';
import GenderRuleConstantInput, {
  GenderRuleConstantInputProps,
} from './GenderRuleConstantInput';
import NumberRuleConstantInput, {
  NumberRuleConstantInputProps,
} from './NumberRuleConstantInput';
import PercentRuleConstantInput, {
  PercentRuleConstantInputProps,
} from './PercentRuleConstantInput';
import StringRuleConstantInput, {
  StringRuleConstantInputProps,
} from './StringRuleConstantInput';

export type RuleConstantInputPropsType<
  T extends { type: string; value: any; onChange: (value: any) => void },
> = {
  value: any;
  label?: string;
  type: T['type'];
  onChange: T['onChange'];
};

export type RuleConstantInputProps =
  | GenderRuleConstantInputProps
  | StringRuleConstantInputProps
  | NumberRuleConstantInputProps
  | PercentRuleConstantInputProps;

const RuleConstantInput = ({
  label,
  ...inputProps
}: RuleConstantInputProps) => {
  const renderInput = () => {
    switch (inputProps.type) {
      case 'number':
        return <NumberRuleConstantInput {...inputProps} />;
      case 'percent':
        return <PercentRuleConstantInput {...inputProps} />;
      case 'gender':
        return <GenderRuleConstantInput {...inputProps} />;
      default:
        return <StringRuleConstantInput {...inputProps} />;
    }
  };

  return (
    <>
      {label && <InputLabel>{label}</InputLabel>}
      <Row halfMargin center>
        {renderInput()}
      </Row>
    </>
  );
};

export default RuleConstantInput;
