import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import CloseButton from '../../../components/CloseButton/CloseButton';

interface ClearScreensFilterConfirmationProps {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const ClearScreensFilterConfirmation = ({
  open = false,
  onConfirm,
  onCancel,
}: ClearScreensFilterConfirmationProps) => {
  const { t } = useTranslation(['devices']);

  return (
    <Transition appear={open} show={open} as={Fragment}>
      <Dialog
        as="div"
        className={classNames('relative z-50 !p-0 confirm text-black')}
        onClose={() => onCancel && onCancel()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  'w-full max-w-xl transform overflow-hidden rounded-3xl bg-white text-left align-middle shadow-xl transition-all relative text-lg',
                )}
              >
                {onCancel && (
                  <CloseButton
                    onClick={() => onCancel()}
                    className="absolute top-4 right-4"
                  />
                )}
                <Dialog.Title
                  as="p"
                  className={classNames('p-6 title leading-6')}
                >
                  {t('screens.confirmation.title')}
                </Dialog.Title>
                <div className="p-6 pt-0 pb-3 max-h-[60vh] overflow-auto body !text-[14px]">
                  <p>{t('screens.confirmation.message')}</p>
                </div>
                <hr className="mb-6 mx-3" />
                <footer className={classNames('!pt-0 !p-6')}>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <button className="btn lg" onClick={onCancel}>
                      {t('screens.confirmation.cancel')}
                    </button>
                    <button className="btn btn-primary lg" onClick={onConfirm}>
                      {t('screens.confirmation.confirm')}
                    </button>
                  </div>
                </footer>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ClearScreensFilterConfirmation;
