import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

export default makeStyles((theme: Theme) => {
  const accountWidth = 440;
  const domainWidth = 440;
  const maxSectionBodyWidth = 500;
  const modalWidth = 400;
  const modalTopOffset = 140;
  const modalLeftOffset = accountWidth + domainWidth + 16;
  const modalBottomOffset = 52;
  const modalGutter = 48;
  const modalBreakpoint = modalWidth + modalLeftOffset + modalGutter;

  return createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
      },

      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
      },
    },

    account: {
      width: accountWidth,
      marginRight: theme.spacing(2),

      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        marginRight: 0,
        marginBottom: theme.spacing(4),
      },
    },

    accountNoDomain: {
      flex: 1,
      width: 'auto',
      marginRight: 0,
    },

    domain: {
      flex: 1,

      [theme.breakpoints.down('sm')]: {
        flex: 'auto',
      },
    },

    domainInner: {
      display: 'flex',
      flexDirection: 'row',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },

    domainInfo: {
      width: domainWidth,

      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
    },

    profile: {
      top: modalTopOffset,
      left: modalLeftOffset,
      width: modalWidth,
      height: `calc(100vh - ${modalTopOffset}px - ${modalBottomOffset}px)`,

      [`@media screen and (max-width: ${modalBreakpoint}px)`]: {
        left: 'auto',
        right: modalGutter,
      },
    },

    section: {
      height: '100%',
      paddingTop: theme.spacing(3),

      [theme.breakpoints.down('xs')]: {
        height: 'auto',
        borderRadius: 0,
      },
    },

    sectionHeader: {
      padding: theme.spacing(1, 4),
      marginBottom: theme.spacing(3),
      borderBottom: `1px solid ${theme.divider.primary}`,

      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },

    sectionBody: {
      padding: theme.spacing(2, 4),
      paddingBottom: theme.spacing(4),
      width: '100%',
      maxWidth: maxSectionBodyWidth,
      margin: '0 auto',

      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },

      '& + &': {
        marginTop: theme.spacing(2),
      },
    },

    sectionBody2: {
      padding: theme.spacing(2, 4),
      paddingBottom: theme.spacing(4),
      width: '520px',
      maxWidth: '520px',
      margin: '0 auto',

      [theme.breakpoints.down('xs')]: {
        width: '100%',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },

      '& + &': {
        marginTop: theme.spacing(2),
      },
    },
  });
});
