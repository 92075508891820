import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import StopOutlinedIcon from '@material-ui/icons/StopOutlined';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import { ButtonBase } from '@material-ui/core';

interface DeviceAiSettingsVideoControlsProps {
  disabled?: boolean;
  isPlaying?: boolean;
  onPlay?: () => void;
  onStop?: () => void;
}

const DeviceAiSettingsVideoControls = ({
  disabled,
  isPlaying = false,
  onPlay,
  onStop,
}: DeviceAiSettingsVideoControlsProps) => {
  const classes = useStyles();

  return (
    <div className={classes.controls}>
      <ButtonBase onClick={onPlay} disabled={disabled || isPlaying}>
        <PlayCircleOutlineIcon className="icon" />
      </ButtonBase>
      <ButtonBase onClick={onStop} disabled={disabled || !isPlaying}>
        <StopOutlinedIcon className="icon" />
      </ButtonBase>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    controls: {
      display: 'flex',
      justifyContent: 'space-between',
      '& button': {
        marginRight: 12,
        '&:disabled': {
          opacity: 0.5,
        },
      },
      '& .icon': {
        fontSize: 24,
      },
    },
  });
});

export default DeviceAiSettingsVideoControls;
