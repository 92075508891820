import Text from '@raydiant/raydial/components/Text';
import CloseButton from '../CloseButton/CloseButton';

type SidebarHeaderProps = {
  isOpen?: boolean;
  onClose?: () => void;
};

const SidebarHeader = ({ isOpen, onClose }: SidebarHeaderProps) => {
  return (
    <>
      {isOpen && (
        <div className="p-6">
          <CloseButton onClick={() => onClose && onClose()} />
          <Text as="p" variant="largeSubtitle" className="text-gray-500">
            main menu
          </Text>
        </div>
      )}
    </>
  );
};

export default SidebarHeader;
