import { useTranslation } from 'react-i18next';
import DataTable from '../../components/DataTable/DataTable';
import { EngagementByLocation } from '@raydiant/api-client-js';
import ChartCard from './ChartCard/ChartCard';
import { getDisplayValue, toPercent } from './ShopperEngagementInsights/utils';
import DataTableHeader, {
  TableSort,
} from '../../components/DataTable/DataTableHeader';
import DataTableCell from '../../components/DataTable/DataTableCell';
import { PaginationProps } from '../../components/Pagination/Pagination';
import { Popover } from '@headlessui/react';
import QuestionMarkIcon from '../../components/QuestionMarkIcon';
import PopoverButton from '../../components/Popover/PopoverButton';

type TopLocationsDataTableCardProps = PaginationProps & {
  data: EngagementByLocation[];
  onSort: (sort: TableSort<EngagementByLocation>) => void;
  sort: TableSort<EngagementByLocation>;
  isRXEnabled: boolean;
  showPagination: boolean;
};

const TopLocationsDataTableCard = ({
  currentPage,
  data,
  onSort,
  onPageChange,
  pageCount,
  sort,
  isRXEnabled,
  showPagination,
}: TopLocationsDataTableCardProps) => {
  const { t } = useTranslation();

  return (
    <ChartCard
      subTitle={''}
      title={
        <div className="flex gap-3 items-center">
          <h2 className="text-black text-xl font-bold">
            {t('analytics.productEngagementPage.topLocationsTable.title')!}
          </h2>
          <Popover className="relative">
            <PopoverButton icon={<QuestionMarkIcon />} />
            <Popover.Panel className="absolute z-10 card popover">
              <div className="overflow-hidden">
                <p className="mb-3">
                  {isRXEnabled
                    ? t(
                        'analytics.productEngagementPage.topLocationsTable.tooltip.default',
                      )
                    : t(
                        'analytics.productEngagementPage.topLocationsTable.tooltip.kiosk',
                      )}
                </p>
                <a
                  href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('analytics.learnmore')}
                </a>
              </div>
            </Popover.Panel>
          </Popover>
        </div>
      }
      showTimeInterval={false}
    >
      <DataTable<EngagementByLocation>
        currentPage={currentPage}
        data={data}
        showPagination={showPagination}
        headers={
          <tr>
            <DataTableHeader<EngagementByLocation>
              className="w-10 sticky left-[0] bg-white"
              onSort={onSort}
              showSort={sort.key === 'rank'}
              sortDirection={sort.key === 'rank' ? sort.direction : undefined}
              sortKey={'rank'}
            >
              {t('analytics.rank')}
            </DataTableHeader>
            <DataTableHeader<EngagementByLocation>
              className="w-96 !text-left sticky left-[5.5rem] bg-white"
              onSort={onSort}
              showSort={sort.key === 'locationName'}
              sortDirection={
                sort.key === 'locationName' ? sort.direction : undefined
              }
              sortKey={'locationName'}
              wrapperClassName="!justify-start"
            >
              {t('analytics.store')}
            </DataTableHeader>
            {isRXEnabled && (
              <DataTableHeader<EngagementByLocation>
                showSort={sort.key === 'pickups'}
                sortDirection={
                  sort.key === 'pickups' ? sort.direction : undefined
                }
                sortKey={'pickups'}
                onSort={onSort}
              >
                {t('analytics.productPickups')}
              </DataTableHeader>
            )}
            <DataTableHeader<EngagementByLocation>
              showSort={sort.key === 'clicks'}
              sortDirection={sort.key === 'clicks' ? sort.direction : undefined}
              sortKey={'clicks'}
              onSort={onSort}
            >
              {t('analytics.screenClicks')}
            </DataTableHeader>
            {isRXEnabled && (
              <>
                <DataTableHeader<EngagementByLocation>
                  showSort={sort.key === 'pickupToScreenConversion'}
                  sortDirection={
                    sort.key === 'pickupToScreenConversion'
                      ? sort.direction
                      : undefined
                  }
                  sortKey={'pickupToScreenConversion'}
                  onSort={onSort}
                >
                  {t('analytics.pickupsToScreen')}
                </DataTableHeader>
                <DataTableHeader<EngagementByLocation>
                  showSort={sort.key === 'totalInteractions'}
                  sortDirection={
                    sort.key === 'totalInteractions'
                      ? sort.direction
                      : undefined
                  }
                  sortKey={'totalInteractions'}
                  onSort={onSort}
                >
                  {t('analytics.totalInteractions')}
                </DataTableHeader>
              </>
            )}

            <DataTableHeader<EngagementByLocation>
              showSort={sort.key === 'sessions'}
              sortDirection={
                sort.key === 'sessions' ? sort.direction : undefined
              }
              sortKey={'sessions'}
              onSort={onSort}
            >
              {t('analytics.engagedShoppers')}
            </DataTableHeader>
          </tr>
        }
        onPageChange={onPageChange}
        pageCount={pageCount}
        renderRow={(item, index) => (
          <tr key={index}>
            <DataTableCell hasSort className="sticky left-0 bg-white">
              {item.rank}
            </DataTableCell>
            <DataTableCell
              wrapperClassName="!text-left"
              hasSort
              className="sticky left-[5.5rem] bg-white"
            >
              {item.locationName}
            </DataTableCell>
            {isRXEnabled && (
              <DataTableCell hasSort>
                {getDisplayValue(item.pickups)}
              </DataTableCell>
            )}
            <DataTableCell hasSort>
              {getDisplayValue(item.clicks)}
            </DataTableCell>
            {isRXEnabled && (
              <>
                <DataTableCell hasSort>
                  {item.pickups
                    ? getDisplayValue(
                        toPercent(item.pickupToScreenConversion),
                        '%',
                      )
                    : '-'}
                </DataTableCell>
                <DataTableCell hasSort>
                  {getDisplayValue(item.totalInteractions)}
                </DataTableCell>
              </>
            )}
            <DataTableCell hasSort>
              {getDisplayValue(item.sessions)}
            </DataTableCell>
          </tr>
        )}
      />
    </ChartCard>
  );
};

export default TopLocationsDataTableCard;
