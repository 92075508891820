import { Presentation } from '@raydiant/api-client-js';
import immutable from 'object-path-immutable';
import { LocalFileUploads, PresentationUpload } from '../types';
import { isFileUploading, hasFileUploadError } from './fileUploadUtils';

export const getPresentationFileStatus = (
  presentation: Presentation,
  localUploads: LocalFileUploads,
) => {
  if (!presentation.fileUploads) {
    return { isUploading: false, hasError: false };
  }

  return {
    isUploading: presentation.fileUploads.some((fileUpload) =>
      isFileUploading(fileUpload, localUploads),
    ),
    hasError: presentation.fileUploads.some((fileUpload) =>
      hasFileUploadError(fileUpload, localUploads),
    ),
  };
};

// Inject local file upload URLs into the presentation. Used by the presentation preview
// to display the local file before it is uploaded.
export const injectPresentationUploads = (
  presentation: Presentation,
  uploads: PresentationUpload[],
): Presentation => {
  let presentationWithLocalFiles = presentation;

  for (const upload of uploads) {
    presentationWithLocalFiles = immutable.set(
      presentationWithLocalFiles,
      [...upload.path, 'url'] as string[],
      upload.localUrl,
    );
  }

  return presentationWithLocalFiles;
};
