import { ApiClientError, DomainUser } from '@raydiant/api-client-js';
import { keys } from '../queryClient';
import raydiant from '../clients/miraClient';
import useProtectedQuery from './useProtectedQuery';

export default function useGetDomainUsers() {
  return useProtectedQuery<DomainUser[], ApiClientError>(
    keys.domainUsers(),
    () => raydiant.getDomainUsers(),
  );
}
