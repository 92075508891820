import Hidden from 'raydiant-elements/layout/Hidden';
import { getAvatarStyle, getInitialsFromName } from '../../utils';
import { SharableListItem } from './ManageAccessDialog';
import Trash from '../../Icons/Trash';

interface SharedListItemProps {
  user: SharableListItem;
  avatarStyle?: number;
  onClick: (user: SharableListItem) => void;
}

export const SharedListItem = ({
  user,
  avatarStyle = 0,
  onClick,
}: SharedListItemProps) => {
  const initials = getInitialsFromName(user.name);

  const renderAvatar = () => {
    return (
      <div
        className={`flex justify-center items-center rounded-2xl w-8 h-8 ${
          getAvatarStyle(avatarStyle).backgroundColor
        } ${getAvatarStyle(avatarStyle).color}`}
      >
        <span className="text-base font-bold">{initials}</span>
      </div>
    );
  };

  const renderDesktopItem = () => {
    return (
      <>
        {renderAvatar()}
        <div className="flex flex-1 items-center ml-2 px-3 h-9 truncate">
          <span className="text-lg text-black capitalize truncate">
            {user.name}
          </span>
        </div>
        <button className="text-red-700 text-lg" onClick={() => onClick(user)}>
          Remove Access
        </button>
      </>
    );
  };

  const renderMobileItem = () => {
    return (
      <>
        {renderAvatar()}
        <div className="flex flex-1 items-center ml-2 px-3 h-9 truncate">
          <span className="text-lg text-black capitalize truncate">
            {user.name}
          </span>
        </div>
        <button className="text-red-700 text-lg" onClick={() => onClick(user)}>
          <Trash />
        </button>
      </>
    );
  };

  return (
    <div className="flex justify-between items-center mb-2 px-4">
      <Hidden smDown>{renderDesktopItem()}</Hidden>
      <Hidden mdUp>{renderMobileItem()}</Hidden>
    </div>
  );
};

export default SharedListItem;
