import { useTranslation } from 'react-i18next';
import DropDownPlaceholder from '../../../../components/DropDown/DropDownPlaceholder';
import { Playlist } from '@raydiant/api-client-js';
import { useEffect, useState } from 'react';
import PlaylistLibrarySelector from '../../../../components/PlaylistSelector';
import { useSelector } from 'react-redux';
import { selectUserProfile } from '../../../../selectors/user';
import { Popover } from '@headlessui/react';
import classNames from 'classnames';
import PlaylistSelector from './PlaylistSelector/PlaylistSelector';
import usePlaylist from '../../../../hooks/usePlaylist';

type ContentSelectDropDownProps = {
  className?: string;
  onChange: (value: ContentQuery) => void;
  value: ContentQuery;
};

export type ContentQuery = {
  playlistId?: Playlist['id'];
};

const ContentSelectDropDown = ({
  className,
  onChange,
  value,
}: ContentSelectDropDownProps) => {
  const { t } = useTranslation(['devices']);

  const [showLibrary, setShowLibrary] = useState(false);
  const [selectedProfileId, setSelectedProfileId] = useState('');

  const currentUser = useSelector(selectUserProfile);

  const { data } = usePlaylist(value?.playlistId);

  useEffect(() => {
    if (selectedProfileId) return;
    if (!currentUser) return;
    setSelectedProfileId(currentUser.id);
  }, [currentUser, selectedProfileId, setSelectedProfileId]);
  const [open, setOpen] = useState(false);

  const selectedCount = value?.playlistId ? 1 : 0;

  return (
    <Popover className={classNames('relative', className)}>
      <Popover.Button
        className="input no-label text-left w-full"
        onClick={() => setOpen(true)}
      >
        <DropDownPlaceholder className="md:w-full min-w-[105px]">
          <div className="flex items-center justify-between w-full">
            <p>{t('screens.content')}</p>
            {selectedCount > 0 && (
              <span className="mr-1 text-primary">({selectedCount})</span>
            )}
          </div>
        </DropDownPlaceholder>
      </Popover.Button>
      {open && (
        <div className="inset-0 fixed z-[8]" onClick={() => setOpen(false)} />
      )}
      {open && (
        <Popover.Panel
          className="absolute z-[8] card menu dropdown p-0 min-w-min w-full md:!min-w-[380px] !max-h-max left-0 !overflow-visible"
          static
          focus
        >
          <div
            className={classNames(
              'text-black flex flex-col gap-2',
              showLibrary ? '' : 'p-6',
            )}
          >
            {showLibrary && (
              <div className="p-3">
                <p
                  className="text-[#adadad] cursor-pointer flex items-center gap-3 uppercase text-xs tracking-wider font-bold"
                  onClick={() => {
                    setShowLibrary(false);
                    setSelectedProfileId(currentUser!.id);
                  }}
                >
                  <svg
                    className="w-3 h-3"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    fill="currentColor"
                  >
                    <path d="M7.4 273.4C2.7 268.8 0 262.6 0 256s2.7-12.8 7.4-17.4l176-168c9.6-9.2 24.8-8.8 33.9 .8s8.8 24.8-.8 33.9L83.9 232 424 232c13.3 0 24 10.7 24 24s-10.7 24-24 24L83.9 280 216.6 406.6c9.6 9.2 9.9 24.3 .8 33.9s-24.3 9.9-33.9 .8l-176-168z" />
                  </svg>
                  {t('screens.back')}
                </p>
                <PlaylistLibrarySelector
                  assignButtonLabel={t('screens.select')!}
                  filter={true}
                  onSelectProfile={setSelectedProfileId}
                  onSelectPlaylist={(playlistId) => {
                    onChange({
                      ...value,
                      playlistId,
                    });
                    setShowLibrary(false);
                    setSelectedProfileId(currentUser!.id);
                  }}
                  selectedProfileId={selectedProfileId}
                  showOpenLibraryButton={false}
                />
              </div>
            )}
            {!showLibrary && (
              <PlaylistSelector
                onChange={(playlistId) => {
                  onChange({
                    ...value,
                    playlistId,
                  });
                }}
                onHidePlaylistLibrary={() => setShowLibrary(false)}
                onShowPlaylistLibrary={() => setShowLibrary(true)}
                playlist={data}
                value={value?.playlistId ?? ''}
              />
            )}
          </div>
        </Popover.Panel>
      )}
    </Popover>
  );
};

export default ContentSelectDropDown;
