import React from 'react';
import Row from 'raydiant-elements/layout/Row';
import Column from 'raydiant-elements/layout/Column';
import TextField from 'raydiant-elements/core/TextField';
import IconButton from '@material-ui/core/IconButton';

export interface LocationInputProps {
  key: string;
  placeholder?: string;
  disabled?: boolean;
  icon: React.ReactNode;
  value: string;
  error?: boolean;
  errorHelperText?: string;
  onChange: (value: string) => void;
  onIconClick: (value: string) => void;
}

const LocationInput = ({
  key,
  disabled = false,
  placeholder,
  icon,
  value,
  error,
  errorHelperText,
  onChange,
  onIconClick,
}: LocationInputProps) => {
  return (
    <Column>
      <Row key={key} halfMargin center>
        <Row halfMargin center>
          <TextField
            disabled={disabled}
            placeholder={placeholder}
            autoFocus={false}
            label=""
            value={value}
            onChange={onChange}
            error={error}
            helperText={errorHelperText}
          />
        </Row>
        <div>
          <IconButton
            size="small"
            edge="end"
            onClick={() => onIconClick(value.toLowerCase())}
          >
            {icon}
          </IconButton>
        </div>
      </Row>
    </Column>
  );
};

export default LocationInput;
