import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Row from 'raydiant-elements/layout/Row';
import Column from 'raydiant-elements/layout/Column';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Device, ResourceProfile } from '@raydiant/api-client-js';
import { selectOtherDomainProfiles } from '../../../selectors/v2/domains';
import { domainRoleLabels } from '../../../utilities';
import Heading from 'raydiant-elements/core/Heading';
import { hasRoleOrHigher } from '../../../utilities/permissions';
import SelectField from 'raydiant-elements/core/SelectField';
import { selectBatchDeviceSettings } from '../../DevicesPage/selectors';
import { BatchDevice } from '../deviceBatchSettings.interface';
import * as devicePageActions from '../../DevicesPage/actions';
import useStyles from './DeviceBatchSharing.styles';
import useCurrentUser from '../../../hooks/useCurrentUser';

interface DeviceBatchSharingProps {
  devices: Device[];
}

const getExistingProfiles = (devices: Device[]) => {
  const existingProfiles = [] as string[];
  devices.forEach((device) => {
    const sharedProfiles = device.resource.r.resourceACLs;
    if (sharedProfiles.length > 0) {
      sharedProfiles.forEach((resourceACL) => {
        const profileId = resourceACL.r.grantProfile?.id;
        if (profileId && !existingProfiles.includes(profileId)) {
          existingProfiles.push(profileId);
        }
      });
    }
  });

  return existingProfiles;
};

const DeviceBatchSharing = ({ devices }: DeviceBatchSharingProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Selectors
  const { data: currentUser } = useCurrentUser();
  const otherDomainProfiles = useSelector(selectOtherDomainProfiles);
  const deviceBatchSettings = useSelector(selectBatchDeviceSettings);
  const profileOptions = otherDomainProfiles.filter(
    (profile) => !hasRoleOrHigher('admin', profile),
  );
  const selectedProfileIdsToShareShare = [
    ...deviceBatchSettings.itemsToShare,
    ...deviceBatchSettings.itemsToUnShare,
  ].map((profile) => profile.id);
  const itemsToShareList = profileOptions.filter(
    (profile) => !selectedProfileIdsToShareShare.includes(profile.id),
  );
  const itemsToUnShareList = profileOptions.filter(
    (profile) =>
      getExistingProfiles(devices).includes(profile.id) &&
      !selectedProfileIdsToShareShare.includes(profile.id),
  );

  // Callbacks
  const updateDevice = useCallback(
    (settings: Partial<BatchDevice>) => {
      dispatch(devicePageActions.updateBatchDeviceSettings(settings));
    },
    [dispatch],
  );

  const handleAddToItemsToShare = useCallback(
    (profileId: string) => {
      const selectedProfile = profileOptions.find(
        (profile) => profileId === profile.id,
      );

      if (!selectedProfile) return;

      const updatedItemsToShare = [
        ...deviceBatchSettings.itemsToShare,
        selectedProfile,
      ];
      updateDevice({
        itemsToShare: updatedItemsToShare,
      });
    },
    [deviceBatchSettings, profileOptions, updateDevice],
  );

  const handleRemoveToItemsToShare = useCallback(
    (profileId: string) => {
      const updatedItemsToShare = deviceBatchSettings.itemsToShare.filter(
        (profile) => profile.id !== profileId,
      );
      updateDevice({
        itemsToShare: updatedItemsToShare,
      });
    },
    [deviceBatchSettings, updateDevice],
  );

  const handleAddToitemsToUnShare = useCallback(
    (profileId: string) => {
      const selectedProfile = profileOptions.find(
        (profile) => profileId === profile.id,
      );

      if (!selectedProfile) return;

      const updateditemsToShare = [
        ...deviceBatchSettings.itemsToUnShare,
        selectedProfile,
      ];
      updateDevice({
        itemsToUnShare: updateditemsToShare,
      });
    },
    [deviceBatchSettings, profileOptions, updateDevice],
  );

  const handleRemoveToitemsToUnShare = useCallback(
    (profileId: string) => {
      const updateditemsToShare = deviceBatchSettings.itemsToUnShare.filter(
        (profile) => profile.id !== profileId,
      );
      updateDevice({
        itemsToUnShare: updateditemsToShare,
      });
    },
    [deviceBatchSettings, updateDevice],
  );

  const filterStandardAndRestrictedUsers = (profile: ResourceProfile) => {
    if (currentUser?.domainRole !== 'restricted') return true;

    return (
      profile.domainRole !== 'restricted' && profile.domainRole !== 'standard'
    );
  };

  return (
    <Column>
      <Heading size={5} overline gutterBottom>
        Sharing
      </Heading>
      <SelectField
        label="Share with account"
        value=""
        onChange={handleAddToItemsToShare}
        disabled={
          itemsToShareList.filter(filterStandardAndRestrictedUsers).length === 0
        }
      >
        <option value=""></option>
        {itemsToShareList
          .filter(filterStandardAndRestrictedUsers)
          .map((profile) => (
            <option key={profile.id} value={profile.id}>
              {profile.name}
            </option>
          ))}
      </SelectField>

      {deviceBatchSettings.itemsToShare.map((profile) => {
        return (
          <Row key={profile.id} halfMargin className={classes.acl}>
            <div className={classes.aclName}>{profile.name}</div>
            <div className={classes.aclRole}>
              {domainRoleLabels[profile.domainRole]}
            </div>
            <button
              className={classes.remove}
              onClick={() => handleRemoveToItemsToShare(profile.id)}
            >
              <HighlightOffIcon />
            </button>
          </Row>
        );
      })}
      <SelectField
        label="Remove access to account"
        value=""
        onChange={handleAddToitemsToUnShare}
        disabled={itemsToUnShareList.length === 0}
      >
        <option value=""></option>
        {itemsToUnShareList.map((profile) => (
          <option key={profile.id} value={profile.id}>
            {profile.name}
          </option>
        ))}
      </SelectField>

      {deviceBatchSettings.itemsToUnShare.map((profile) => {
        return (
          <Row key={profile.id} halfMargin className={classes.acl}>
            <div className={classes.aclName}>{profile.name}</div>
            <div className={classes.aclRole}>
              {domainRoleLabels[profile.domainRole]}
            </div>
            <button
              className={classes.remove}
              onClick={() => handleRemoveToitemsToUnShare(profile.id)}
            >
              <HighlightOffIcon />
            </button>
          </Row>
        );
      })}
    </Column>
  );
};

export default DeviceBatchSharing;
