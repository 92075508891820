import { ApiClientError, V2PerchviewSettings } from '@raydiant/api-client-js';
import { keys } from '../queryClient';
import useProtectedQuery from './useProtectedQuery';
import raydiant from '../clients/miraClient';

export default function useDevicePerchSettings(deviceId: string) {
  return useProtectedQuery<V2PerchviewSettings, ApiClientError>(
    keys.devicePerch(deviceId),
    () => raydiant.getDeviceV2PerchviewSettings(deviceId),
  );
}
