import Text from 'raydiant-elements/core/Text';
import { RuleConstantInputPropsType } from './RuleConstantInput';

export type StringRuleConstantInputProps = RuleConstantInputPropsType<{
  type: 'string';
  value: string;
  onChange: (value: string) => void;
}>;

const StringRuleConstantInput = ({
  onChange,
  value,
}: StringRuleConstantInputProps) => {
  return <Text editable value={value ?? ''} onChange={onChange} />;
};

export default StringRuleConstantInput;
