// Applications

import {
  AnalyticsQueryParams,
  ContentDetailQueryParams,
  GetProductsQuery,
} from '@raydiant/api-client-js';

export const applicationVersions = (applicationId: string) => [
  'applications',
  applicationId,
  'versions',
];

export const applicationVersionToken = (applicationVersionId: string) => [
  'applicationVersion',
  applicationVersionId,
  'token',
];

// Playlists

export const playlists = () => 'playlists';
export const playlist = (playlistId: string) => ['playlists', playlistId];
export const playlistsById = (playlistsById: Record<string, true>) => [
  `playlists`,
  playlistsById,
];

// Presentations

export const presentation = (presentationId: string) => [
  'presentations',
  presentationId,
];

export const presentationsById = (presentationsById: Record<string, true>) => [
  `presentations`,
  presentationsById,
];

// Themes

export const themes = () => 'themes';

// Profile

export const profile = () => 'profile';

// Devices

export const device = (deviceId: string) => ['devices', deviceId];

export const deviceAI = (deviceId: string) => ['deviceAI', deviceId];

export const devicePerch = (deviceId: string) => ['devicePerch', deviceId];

export const affectedDevices = (resources: {
  presentationIds: string[];
  playlistIds: string[];
  folderIds: string[];
}) => [resources];

// Fonts

export const fonts = () => 'fonts';

// Marketplace

export const marketPlaceTiles = () => 'marketPlaceTiles';

export const marketPlaceApplications = () => 'marketPlaceApplications';

export const marketPlaceApplication = (grantScope: string) => [
  'marketPlaceApplication',
  grantScope,
];

// Profile

export const outstandingBalance = () => 'outstandingBalance';

export const paymentMethods = () => 'paymentMethods';

export const analyticsLocations = () => 'analyticsLocations';

export const analytics = (params: AnalyticsQueryParams) => [
  'analytics',
  params,
];

export const contentEngagement = (params: AnalyticsQueryParams) => [
  'contentEngagement',
  params,
];

export const trafficAwareness = (params: AnalyticsQueryParams) => [
  'trafficAwareness',
  params,
];

export const contentDetail = (params: ContentDetailQueryParams) => [
  'contentDetail',
  params,
];

// Export Analytics

export const analyticsExport = (params: AnalyticsQueryParams) => [
  'analyticsExport',
  params,
];

export const contentTopClicksEngagementExport = (
  params: AnalyticsQueryParams,
) => ['contentTopClicksEngagementExport', params];

export const trafficAwarenessExport = (params: AnalyticsQueryParams) => [
  'trafficAwarenessExport',
  params,
];

// Catalogs
export const catalogs = () => 'catalogs';

// Catalog items
export const catalogItems = (params: GetProductsQuery) => [
  'catalogItems',
  params,
];

// Connections
export const connections = () => 'connections';

// Connections
export const connectors = () => 'connectors';

// Locations
export const locations = () => ['locations'];
export const pendingLocations = () => ['pendingLocations'];

// Onboarding
export const onboarding = () => ['onboarding'];

// Domains
export const domainInvites = () => 'domainInvites';
export const domainUsers = () => 'domainUsers';
