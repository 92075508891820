import React from 'react';
import { Grid } from '@material-ui/core';
import Heading from 'raydiant-elements/core/Heading';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    heading: {
      marginBottom: theme.spacing(4),
    },
  });
});

export interface AddIntegrationColumnProps {
  heading: string;
  children?: React.ReactNode;
}

const AddIntegrationColumn = ({
  heading,
  children,
}: AddIntegrationColumnProps) => {
  const classes = useStyles();
  return (
    <Grid item md={4} xs={12} className={classes.root}>
      <Heading className={classes.heading} size={5} overline gutterBottom>
        {heading}
      </Heading>
      {children}
    </Grid>
  );
};

export default AddIntegrationColumn;
