import cn from 'classnames';
import { FC } from 'react';
import MUIAddIcon from '@material-ui/icons/Add';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

interface AddIconProps {
  className?: string;
}

const AddIcon: FC<AddIconProps> = ({ className }) => {
  const classes = useStyles();
  return <MUIAddIcon className={cn(classes.root, className)} />;
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      color: theme.palette.progress.contrastText,
      backgroundColor: theme.palette.progress.main,
      borderRadius: 100,
      boxShadow: theme.shadows[1],
    },
  });
});

export default AddIcon;
