import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DatePicker from '../../../components/DatePicker/DatePicker';
import { parseDateToString } from './utils';
import SelectOption from '../../../components/Select/SelectOption';
import Select from '../../../components/Select/Select';

export type CustomChangeHandlerEvent = (
  data: CustomChangeHandlerEventData,
) => void;

export type CustomChangeHandlerEventData = {
  from: string;
  to: string;
};

const fixedRanges = ['7', '30', '90', 'allTime', 'custom'] as const;

type DateRangeMap = {
  [key in typeof fixedRanges[number]]: DateRangeSelectType;
};

type DateRangeSelectType = {
  from?: string;
  to?: string;
  label: string;
};

const now = moment().subtract(1, 'days').format('YYYY-MM-DD').toString();

export const DATE_RANGE_MAP: DateRangeMap = {
  7: {
    from: moment().subtract(7, 'days').format('YYYY-MM-DD').toString(),
    to: now,
    label: 'analytics.last7Days',
  },
  30: {
    from: moment().subtract(30, 'days').format('YYYY-MM-DD').toString(),
    to: now,
    label: 'analytics.last30Days',
  },
  90: {
    from: moment().subtract(90, 'days').format('YYYY-MM-DD').toString(),
    to: now,
    label: 'analytics.last90Days',
  },
  allTime: {
    from: moment().subtract(1, 'year').format('YYYY-MM-DD').toString(),
    to: now,
    label: 'analytics.allTime',
  },
  custom: {
    label: 'analytics.custom',
  },
};

export type DateRangeSelectValue = keyof typeof DATE_RANGE_MAP;

export const DEFAULT_DATE_RANGE_KEY: DateRangeSelectValue = '30';
export const DEFAULT_DATE_RANGE = DATE_RANGE_MAP[DEFAULT_DATE_RANGE_KEY];

type DateRangeSelectProps = {
  dateRangeValue: CustomChangeHandlerEventData;
  onChangeCustom: CustomChangeHandlerEvent;
  onChange: (value: JSX.IntrinsicElements['select']['value']) => void;
  value: DateRangeSelectValue;
  wrapperClassName?: string;
} & Omit<JSX.IntrinsicElements['select'], 'value' | 'onChange'>;

const DateRangeSelect = ({
  dateRangeValue,
  onChange,
  onChangeCustom,
  value,
  wrapperClassName,
  ...props
}: DateRangeSelectProps) => {
  const { t } = useTranslation();

  const isCustomValue = value === t('analytics.custom');

  return (
    <fieldset className={wrapperClassName}>
      <label>{t('analytics.dateRange')}</label>
      <div className="flex items-center flex-col md:flex-row gap-6 md:gap-3 w-full">
        <Select
          className={props.className}
          onChange={(value) => onChange(value)}
          wrapperClassName={wrapperClassName}
          renderAnchorElement={() => (
            <>
              {isCustomValue
                ? t(DATE_RANGE_MAP['custom']?.label)
                : t(DATE_RANGE_MAP[value]?.label) ?? ''}
            </>
          )}
          {...props}
        >
          {(Object.keys(DATE_RANGE_MAP) as DateRangeSelectValue[])
            .filter((key) => key !== 'custom')
            .map((item, key) => (
              <SelectOption
                key={key}
                onClick={() => onChange(item)}
                value={item}
              >
                {t(DATE_RANGE_MAP[item].label)}
              </SelectOption>
            ))}
          <SelectOption
            key="custom"
            value={t('analytics.custom')!}
            onClick={() => onChange(t('analytics.custom')!)}
          >
            {t('analytics.custom')}
          </SelectOption>
        </Select>
        {isCustomValue && (
          <DatePicker
            inputClassName="input min-w-[240px] w-full no-label"
            maxDate={new Date(moment().subtract(1, 'days').format())}
            onChange={(value) =>
              onChangeCustom({
                from: parseDateToString(value?.startDate),
                to: parseDateToString(value?.endDate),
              })
            }
            useRange={false}
            value={{
              startDate: parseDateToString(dateRangeValue.from),
              endDate: parseDateToString(dateRangeValue.to),
            }}
          />
        )}
      </div>
    </fieldset>
  );
};

export default DateRangeSelect;
