import { ApiClientError, UpdateConnection } from '@raydiant/api-client-js';
import { useQueryClient } from 'react-query';
import raydiant from '../clients/raydiant';
import { keys } from '../queryClient';
import useProtectedMutation from './useProtectedMutation';

export default function useUpdateConnection() {
  const queryClient = useQueryClient();

  const mutation = useProtectedMutation<
    void,
    ApiClientError,
    { id: string; params: UpdateConnection }
  >(({ id, params }) => raydiant.updateConnection(id, params), {
    onSuccess: () => {
      queryClient.invalidateQueries(keys.connections());
    },
  });

  return mutation;
}
