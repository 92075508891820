import { EngagementByLocation, GetAnalytics } from '@raydiant/api-client-js';
import ChartCard from './ChartCard/ChartCard';
import { useTranslation } from 'react-i18next';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { BarChart, LineChart } from 'echarts/charts';
import {
  CHART_DEFAULT_AXIS_OPTIONS,
  CHART_DEFAULT_OPTIONS,
  CHART_DEFAULT_SERIES,
} from './utils';
import ChartToolTip, { EChartsToolTipParams } from './ChartToolTip';
import { renderToStaticMarkup } from 'react-dom/server';
import { getDisplayValue, toPercent } from './ShopperEngagementInsights/utils';
import Hidden from 'raydiant-elements/layout/Hidden';
import { Popover } from '@headlessui/react';
import QuestionMarkIcon from '../../components/QuestionMarkIcon';
import PopoverButton from '../../components/Popover/PopoverButton';

echarts.use([BarChart, LineChart]);

type TopLocationsChartProps = {
  data: EngagementByLocation[];
  showClicks?: boolean;
  showPickups?: boolean;
  showEngagedShoppers?: boolean;
  showPickupsToScreen?: boolean;
  isRXEnabled?: boolean;
};

const getXAxisData = (data: GetAnalytics['engagementByLocation']) =>
  data?.map((value) => value.locationName);

const getYAxisData = (
  data: GetAnalytics['engagementByLocation'],
  view: keyof EngagementByLocation,
) => data?.map((value) => value[view]);

const TopLocationsChart = ({
  data,
  showClicks,
  showPickups,
  showEngagedShoppers,
  showPickupsToScreen,
  isRXEnabled = false,
}: TopLocationsChartProps) => {
  const { t } = useTranslation();

  const getSeries = (data: GetAnalytics['engagementByLocation']) => {
    let series = [];

    if (isRXEnabled && showPickups) {
      series.push({
        ...CHART_DEFAULT_SERIES.bar,
        data: getYAxisData(data, 'pickups'),
        name: t('analytics.productPickups'),
        stack: 'interactions',
      });
    }

    if (showClicks) {
      series.push({
        ...CHART_DEFAULT_SERIES.bar,
        data: getYAxisData(data, 'clicks'),
        name: t('analytics.screenClicks'),
        stack: 'interactions',
      });
    }

    if (showEngagedShoppers) {
      series.push({
        ...CHART_DEFAULT_SERIES.bar,
        data: getYAxisData(data, 'sessions'),
        name: t('analytics.engagedShoppers'),
      });
    }

    if (isRXEnabled && showPickupsToScreen) {
      series.push({
        ...CHART_DEFAULT_SERIES.line,
        data: getYAxisData(data, 'pickupToScreenConversion')?.map((data) =>
          toPercent(data!),
        ),
        name: t('analytics.pickupsToScreen'),
        lineStyle: {
          color: '#879cff',
          type: 'dashed',
          width: 3,
        },
        showSymbol: true,
        yAxisIndex: 1,
      });
    }

    return series;
  };

  const color = isRXEnabled
    ? [...CHART_DEFAULT_OPTIONS.color]
    : ['#4270eb', '#08d4b8'];

  const options = {
    ...CHART_DEFAULT_OPTIONS,
    color,
    legend: {
      ...CHART_DEFAULT_OPTIONS.legend,
      data: [
        { name: t('analytics.productPickups'), icon: 'square' },
        { name: t('analytics.screenClicks'), icon: 'square' },
        { name: t('analytics.engagedShoppers'), icon: 'square' },
        { name: t('analytics.pickupsToScreen'), icon: 'square' },
      ],
    },
    xAxis: [
      {
        ...CHART_DEFAULT_AXIS_OPTIONS.xAxis,
        data: getXAxisData(data),
        name: t('analytics.locations').toUpperCase(),
      },
    ],
    yAxis: [
      {
        ...CHART_DEFAULT_AXIS_OPTIONS.yAxis,
        alignTicks: true,
        name: t('analytics.engagementNumber').toUpperCase(),
        axisLabel: {
          ...CHART_DEFAULT_AXIS_OPTIONS.yAxis.axisLabel,
        },
      },
      {
        ...CHART_DEFAULT_AXIS_OPTIONS.yAxis,
        name: t('analytics.conversionRatio').toUpperCase(),
        axisLabel: {
          ...CHART_DEFAULT_AXIS_OPTIONS.yAxis.axisLabel,
          margin: 8,
        },
      },
    ],
    series: getSeries(data),
    tooltip: {
      ...CHART_DEFAULT_OPTIONS.tooltip,
      formatter: (params: EChartsToolTipParams[]) => {
        const index = params[0]?.dataIndex;
        const value = data[index];

        return renderToStaticMarkup(
          <ChartToolTip
            header={
              <p className="text-sm px-2 py-1 capitalize text-black">
                {value.locationName}
              </p>
            }
            totalInteractions={getDisplayValue(value.clicks + value.pickups)}
            values={params}
          />,
        );
      },
    },
  };

  const mobileOptions = {
    ...options,
    legend: {
      ...CHART_DEFAULT_OPTIONS.legend,
      data: [
        { name: t('analytics.productPickups'), icon: 'square' },
        { name: t('analytics.screenClicks'), icon: 'square' },
        { name: t('analytics.engagedShoppers'), icon: 'square' },
        { name: t('analytics.pickupsToScreen'), icon: 'square' },
      ],
      type: 'scroll',
    },
    series: getSeries(data),
  };

  return (
    <ChartCard
      subTitle={''}
      title={
        <div className="flex gap-3 items-center">
          <h2 className="text-black text-xl font-bold">
            {t('analytics.topLocationsChart')!}
          </h2>
          <Popover className="relative">
            <PopoverButton icon={<QuestionMarkIcon />} />
            <Popover.Panel className="absolute z-10 card popover drop-shadow-xl">
              <div className="overflow-hidden">
                <p className="mb-3">{t('analytics.topLocationsTooltip')}</p>
                <a
                  href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('analytics.learnmore')}
                </a>
              </div>
            </Popover.Panel>
          </Popover>
        </div>
      }
    >
      <div className="min-w-full min-h-full">
        <Hidden xsDown>
          <ReactECharts
            style={{ height: '410px' }}
            option={options}
            onChartReady={(chart) => chart.resize()}
          />
        </Hidden>
        <Hidden smUp>
          <ReactECharts
            style={{ height: '410px' }}
            option={mobileOptions}
            onChartReady={(chart) => chart.resize()}
          />
        </Hidden>
      </div>
    </ChartCard>
  );
};

export default TopLocationsChart;
