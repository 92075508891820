import { ResourceProfile } from '@raydiant/api-client-js';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SelectOption from '../../../../components/Select/SelectOption';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import { selectDomainProfiles } from '../../../../selectors/v2/domains';
import ScreensFilterControl from '../ScreensFilterControl';
import ScreensFilterListItem from '../ScreensFilterListItem';
import ScreensFilterFooterAction from '../ScreensFilterFooterAction';
import { filterStandardAndRestrictedUsers } from '../../../../components/ShareResource/ShareResource';
import Select from '../../../../components/Select/Select';
import { useMemo, useState } from 'react';
import DropDownHeader from '../../../../components/DropDown/DropDownHeader';
import DropDownEmpty from '../../../../components/DropDown/DropDownEmpty';

type SharedWithSelectProps = {
  className?: string;
  onChange: (value: ResourceProfile) => void;
  onClear: () => void;
  onRemoveProfile?: (id: ResourceProfile['id']) => void;
  value: ResourceProfile['id'][];
};

const getProfile = (
  profiles: ResourceProfile[],
  profileId: ResourceProfile['id'],
) => profiles.find((profile) => profile.id === profileId);

const SharedWithSelect = ({
  className,
  onChange,
  onClear,
  onRemoveProfile,
  value,
}: SharedWithSelectProps) => {
  const { t } = useTranslation(['devices']);

  const domainProfiles = useSelector(selectDomainProfiles);
  const { data: currentUser } = useCurrentUser();
  const profiles = domainProfiles.filter((profile) =>
    filterStandardAndRestrictedUsers(profile, currentUser),
  );
  const getPlaceholder = t('screens.selectUsers');

  const [term, setTerm] = useState('');
  const [open, setOpen] = useState(false);

  const sharedWithUsers = useMemo(() => {
    if (term === '') return profiles;

    return profiles?.filter((profile) =>
      profile.name.toLocaleLowerCase().includes(term.toLocaleLowerCase()),
    );
  }, [profiles, term]);

  const showEmpty = sharedWithUsers?.length === 0 && term.length > 0;

  return (
    <ScreensFilterControl
      title={
        <p className="font-[500] text-[16px]">{t('screens.sharedWith')}</p>
      }
      footer={
        value?.length > 0 && (
          <ul className="flex items-center divide-x divide-black text-sm text-primary">
            <li className="pr-2">
              <ScreensFilterFooterAction
                label={t('screens.clearSharedWith')}
                onClick={onClear}
              />
            </li>
          </ul>
        )
      }
    >
      <Select
        className={className}
        disableMobile
        header={
          <DropDownHeader className="hover:bg-transparent!">
            <input
              type="text"
              className="input no-label"
              onChange={(e) => setTerm(e.target.value)}
            />
          </DropDownHeader>
        }
        menuClassname="max-h-[300px] overflow-y-auto"
        onChange={() => {}} //we dont want to trigger on change when user is typing
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        panelClassname="p-0 min-w-min w-full left-0 !overflow-visible"
        wrapperClassName={classNames('no-label')}
        renderAnchorElement={() => <>{getPlaceholder}</>}
        value={value}
        popover
      >
        {sharedWithUsers
          .filter((profile) => !value.includes(profile.id))
          .map((profile, key) => (
            <SelectOption
              disableMobile
              key={key}
              onClick={() => {
                onChange(
                  sharedWithUsers.find((value) => profile.id === value?.id)!,
                );
                setTerm('');
                setOpen(false);
              }}
              value={profile.id}
            >
              {profile.name}
            </SelectOption>
          ))}
        {showEmpty && <DropDownEmpty />}
      </Select>
      {value && (
        <ul>
          {value?.map((profileId) => {
            const profile = getProfile(profiles, profileId);
            return (
              <ScreensFilterListItem
                key={profileId}
                label={
                  <div className="flex justify-between w-full items-center">
                    <p className="capitalize text-ellipsis overflow-hidden max-w-[100px]">
                      {profile?.name}
                    </p>
                    <p className="capitalize">{profile?.domainRole}</p>
                  </div>
                }
                onClear={() =>
                  onRemoveProfile ? onRemoveProfile(profileId) : undefined
                }
              />
            );
          })}
        </ul>
      )}
    </ScreensFilterControl>
  );
};

export default SharedWithSelect;
