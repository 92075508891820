import { useCallback, useMemo, useRef, useState } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import REPresentationForm from 'raydiant-elements/presentation/PresentationForm';
import Text from 'raydiant-elements/core/Text';
import Form from 'raydiant-elements/core/Form';
import Link from 'raydiant-elements/core/Link';
import Button from 'raydiant-elements/core/Button';
import Hidden from 'raydiant-elements/layout/Hidden';
import Row from 'raydiant-elements/layout/Row';
import Spacer from 'raydiant-elements/layout/Spacer';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import {
  Application,
  ApplicationVersion,
  Playlist,
  Presentation,
  SYBSoundZone,
  Catalog,
} from '@raydiant/api-client-js';
import {
  PresentationError,
  PresentationFile,
  PresentationUpload,
  Path,
  BuilderState,
  ApplicationVersionLocalized,
} from '../../types';
import {
  getVersionText,
  isVersionApproved,
} from '../../utilities/appVersionUtils';
import OptionHeader from '../../components/OptionHeader';
import Popover from '../../components/Popover';
import PopoverListItem from '../../components/Popover/PopoverListItem';
import useStyles from './PresentationPage.styles';
import PresentationFormActions from './PresentationFormActions';
import * as actions from './actions';
import useQueryParams from './useQueryParams';
import getUnsavedPresentationId from './getUnsavedPresentationId';
import { selectSystemThemes, selectDomainThemesByProfile } from './selectors';
import ThemeOptionItem from './ThemeOptionItem';
import ThemeItem from './ThemeItem';
import useCustomThemes from './useCustomThemes';

interface PresentationFormProps {
  preview: React.ReactNode;
  presentation?: Presentation;
  appVersion?: ApplicationVersion;
  appVersionLocalized?: ApplicationVersionLocalized;
  errors: PresentationError[];
  soundZones?: SYBSoundZone[];
  playlists?: Playlist[];
  catalogs?: Catalog[];
  application?: Application;
  versions?: ApplicationVersion[];
  presentationUploads: PresentationUpload[];
  presentationErrors: PresentationError[];
  presentationFiles: PresentationFile[];
  selectedPlaylistPath?: Path;
  enableVersionSelector?: boolean;
  onChange: (presentation: Presentation, files: PresentationFile[]) => void;
  onBuilderStateChange?: (builderState: BuilderState) => void;
  builderState?: BuilderState;
  onPlaylistEdit?: (playlistId: string, path: Path) => void;
  onPlaylistCreate?: (path: Path) => void;
  onPlaylistSelect?: (path: Path) => Promise<string>;
  onThemeEdit?: (themeId: string) => void;
  onThemeManage?: () => void;
  onThemeAdd?: () => void;
  onVersionSelect?: (versionId: string) => void;
  createObjectURL: (blob: Blob) => Promise<string>;
}

const getVersionLabel = (
  version: ApplicationVersion,
  application: Application,
) => {
  if (application.isMarketplace) {
    return `${getVersionText(version)}${
      !isVersionApproved(version) ? ' (Unapproved)' : ''
    }`;
  }

  return getVersionText(version);
};

const PresentationForm = ({
  preview,
  presentation,
  appVersion,
  appVersionLocalized,
  presentationErrors,
  presentationFiles,
  presentationUploads,
  application,
  versions = [],
  enableVersionSelector,
  onVersionSelect,
  createObjectURL,
  ...rest
}: PresentationFormProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = useQueryParams();
  const customThemes = useCustomThemes(presentation);

  // Selectors

  const systemThemes = useSelector(selectSystemThemes);
  const domainThemesByProfile = useSelector(selectDomainThemesByProfile);

  // State

  const [isVersionSelectorOpen, setIsVersionSelectorOpen] = useState(false);

  // Refs

  const versionSelectorRef = useRef<HTMLDivElement | null>(null);

  // Memoizers

  const themeOptions = useMemo(() => {
    const options: React.ReactNode[] = [];

    if (customThemes.length > 0) {
      options.push(<OptionHeader key="custom" label="Custom Themes" />);

      customThemes.forEach((theme) => {
        options.push(
          <ThemeOptionItem key={theme.id} value={theme.id}>
            <ThemeItem theme={theme} />
          </ThemeOptionItem>,
        );
      });
    }

    if (systemThemes.length > 0) {
      // Don't show system theme header if there are no custom themes or domain themes.
      if (
        customThemes.length > 0 ||
        domainThemesByProfile.some(({ themes }) => themes.length > 0)
      ) {
        options.push(<OptionHeader key="system" label="System Themes" />);
      }

      systemThemes.forEach((theme) => {
        options.push(
          <ThemeOptionItem key={theme.id} value={theme.id}>
            <ThemeItem theme={theme} />
          </ThemeOptionItem>,
        );
      });
    }

    if (domainThemesByProfile.length > 0) {
      domainThemesByProfile.forEach(({ profile, themes }) => {
        if (themes.length > 0) {
          options.push(
            <OptionHeader key={profile.name} label={profile.name} />,
          );

          themes.forEach((theme) => {
            options.push(
              <ThemeOptionItem key={theme.id} value={theme.id}>
                <ThemeItem theme={theme} />
              </ThemeOptionItem>,
            );
          });
        }
      });
    }

    return options;
  }, [customThemes, systemThemes, domainThemesByProfile]);

  const versionsSorted = useMemo(() => {
    return versions.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
  }, [versions]);

  // Callbacks

  const goBack = useCallback(() => {
    history.push(queryParams.backTo);

    if (presentation) {
      dispatch(
        actions.clearUnsavedPresentation(
          getUnsavedPresentationId(presentation),
        ),
      );
    }
  }, [queryParams.backTo, history, presentation, dispatch]);

  if (!presentation) return null;
  if (!appVersion) return null;
  if (!appVersionLocalized) return null;

  // TOOD: Find a way to surface this with the app platform or remove it.
  let header = null;
  if (appVersion?.name === 'PosterMyWall') {
    header = (
      <>
        <Text small>
          <Link
            target="_blank"
            href="https://www.raydiant.com/partners/poster-my-wall"
          >
            What is PosterMyWall?
          </Link>
        </Text>
        <br />
        <Text small>
          <Link
            target="_blank"
            href="https://support.raydiant.com/s/article/How-can-I-put-my-PosterMyWall-designs-on-my-Raydiant-screens-1660092396883"
          >
            How Does it Work?
          </Link>
        </Text>
      </>
    );
  }

  if (!!appVersion?.helperLinkText) {
    header = (
      <>
        <Text small>
          <Link target="_blank" href={appVersion?.helperLinkUrl}>
            {appVersion?.helperLinkText}
          </Link>
        </Text>
      </>
    );
  }

  const isNewPresentation = !presentation.id;

  return (
    <>
      <Scrollable>
        <Hidden smUp>{preview}</Hidden>

        <Form.Section>
          <Hidden xsDown>
            <Button
              icon="arrowLeft"
              iconAlignment="start"
              label={queryParams.backToLabel || 'Back'}
              onClick={goBack}
            />
          </Hidden>
          <Row>
            <Text muted>Presentation Builder</Text>
            <Spacer />
            {enableVersionSelector && application && onVersionSelect && (
              <div ref={versionSelectorRef}>
                <Text xsmall muted>
                  Version:{' '}
                  <Link onClick={() => setIsVersionSelectorOpen(true)}>
                    {getVersionText(appVersion)}
                  </Link>
                </Text>
                <Popover
                  variant="list"
                  open={isVersionSelectorOpen}
                  anchorEl={versionSelectorRef.current}
                  onClose={() => setIsVersionSelectorOpen(false)}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                  {versionsSorted.map((version) => (
                    <PopoverListItem
                      key={version.id}
                      onClick={() => {
                        onVersionSelect(version.id);
                        setIsVersionSelectorOpen(false);
                      }}
                    >
                      {getVersionLabel(version, application)}
                    </PopoverListItem>
                  ))}
                </Popover>
              </div>
            )}
          </Row>
          {header && <div>{header}</div>}
        </Form.Section>
        <REPresentationForm
          className={classes.scrollContents}
          presentation={presentation}
          appVersion={appVersionLocalized}
          newThemeDisabled={isNewPresentation}
          newThemeDisabledMessage="Please save this presentation before creating a new theme"
          themeOptions={themeOptions}
          createObjectURL={createObjectURL}
          {...rest}
        />
      </Scrollable>

      <PresentationFormActions
        presentation={presentation}
        appVersion={appVersion}
        presentationUploads={presentationUploads}
        presentationErrors={presentationErrors}
        presentationFiles={presentationFiles}
      />
    </>
  );
};

export default PresentationForm;
