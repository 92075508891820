import Card from '@raydiant/raydial/components/Card';
import Page from '../../components/Page';
import PageLayout from '../../components/PageLayout/PageLayout';
import PageTitle from '../../components/PageLayout/PageTitle';
import Text from '@raydiant/raydial/components/Text';
import TextField from '@raydiant/raydial/components/TextField';
import Select, { SelectItem } from '@raydiant/raydial/components/Select';
import Link from '@raydiant/raydial/components/Link';
import { Plus } from '@raydiant/raydial/components/Icons';
import Button from '@raydiant/raydial/components/Button';
import toastSVG from '../../assets/toast-one-more-thing.svg';
import LoadingModal from '@raydiant/raydial/components/LoadingModal';
import { Key, useState } from 'react';
import history from '../../history';
import * as paths from '../../routes/paths';
import useConnections from '../../hooks/useConnections';
import Modal from '@raydiant/raydial/components/Modal';
import Dialog, {
  DialogHeader,
  DialogHeading,
  DialogSubheading,
} from '@raydiant/raydial/components/Dialog';
import useCreateCatalog from '../../hooks/useCreateCatalog';
import { useToastState } from '@raydiant/raydial/components/Toast/ToastProvider';

const ProductHubCreateCataloguePage = () => {
  const connectionsQuery = useConnections();
  const state = useToastState();
  const createCatalogQuery = useCreateCatalog();
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [formData, setFormData] = useState({ connectionId: '', name: '' });

  const { mutateAsync: createCatalog } = createCatalogQuery;

  if (!connectionsQuery || connectionsQuery.isLoading) {
    return (
      <Page>
        <LoadingModal isOpen={true} />
      </Page>
    );
  }

  if (createCatalogQuery.isLoading) {
    return (
      <Page>
        <LoadingModal isOpen={true}>Creating Catalog</LoadingModal>
      </Page>
    );
  }

  if (!connectionsQuery.data) {
    return null;
  }

  const { data: connections } = connectionsQuery.data;
  const selectedConnection = connections.find(
    (c) => c.id === formData.connectionId,
  );
  const selectedConnectorName = selectedConnection?.connector.name;
  const selectedConnectorDisplayName =
    selectedConnection?.connector.displayName;

  const handleNameChange = (name: string) => {
    setFormData({ ...formData, name });
  };

  const handleConnectionChange = (connectionId: Key) => {
    setFormData({ ...formData, connectionId: connectionId.toString() });
  };

  const handleCancel = () => {
    history.push(paths.productHubCatalogs());
  };

  const handleClickAddNewIntegration = () => {
    history.push(paths.productHubCreateIntegrations());
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedConnectorName === 'toast') {
      setShowWarningModal(true);
      return;
    }

    handleCreateCatalog();
  };

  const onCloseWarningModal = () => {
    setShowWarningModal(false);
  };

  const handleCreateCatalog = async () => {
    const catalog = await createCatalog(formData);
    state.add({
      title: `${selectedConnectorDisplayName} Connected`,
      description: `${selectedConnectorDisplayName} menus synced successfully`,
    });
    history.push(paths.productHubCatalogDetails(catalog.id));
  };

  const onSubmitWarningModal = () => {
    setShowWarningModal(false);
    handleCreateCatalog();
  };

  const renderToastWarningModal = () => {
    return (
      <Modal isOpen={showWarningModal} onOpenChange={onCloseWarningModal}>
        <Dialog showCloseButton>
          <form onSubmit={onSubmitWarningModal}>
            <DialogHeader>
              <DialogHeading>One more thing</DialogHeading>
              <DialogSubheading>Importing menus from Toast</DialogSubheading>
            </DialogHeader>

            <div className="px-4 mb-8 flex mt-4 flex-col">
              <img
                src={toastSVG}
                alt="example of enabled kiosk for Toast"
                className="self-center"
              />

              <Text variant="smallSubheader" className="mb-2">
                Please make sure your menus in Toast are visible to Kiosks
              </Text>

              <Text>
                Only menus in Toast that are marked as “visible” to ‘POS’ and
                ‘Kiosk, Toast Order and Pay’ can be imported into Raydiant.
                Please make sure your menus are mark as visible to those two
                options before you continue.
              </Text>
            </div>

            <footer className="flex flex-wrap gap-6 pb-4 px-4">
              <Button
                size="large"
                variant="secondary"
                className="grow"
                onClick={onCloseWarningModal}
              >
                Back
              </Button>
              <Button
                size="large"
                variant="primary"
                className="grow"
                onClick={onSubmitWarningModal}
                type="submit"
              >
                Confirm
              </Button>
            </footer>
          </form>
        </Dialog>
      </Modal>
    );
  };

  const isValid = formData.name.length > 0 && formData.connectionId.length > 0;

  return (
    <Page>
      <PageLayout
        title={<PageTitle title="Catalogue" />}
        sectionClassName="text-black"
      >
        {selectedConnectorName === 'toast' && renderToastWarningModal()}
        <form
          className="grid grid-cols-1 lg:grid-cols-[3fr_2fr] gap-12 items-start"
          onSubmit={onSubmit}
        >
          <Card>
            <header className="flex items-center px-4">
              <div className="grid gap-1 py-4 mr-auto">
                <Text as="h2" variant="subheader">
                  Add a Catalog
                </Text>
                <Text as="h3" className="text-gray-400 font-regular text-lg">
                  Create a catalog and connect with an integration
                </Text>
              </div>
            </header>

            <div className="flex flex-col gap-4 mb-8">
              <TextField
                label="Catalog Name"
                description="This could be your restaurant or brand, eg: The Burger Barn"
                onChange={handleNameChange}
                value={formData.name}
                placeholder="Enter catalog name"
              />
              <Select
                label="Integration for catalog"
                onSelectionChange={handleConnectionChange}
                selectedKey={formData.connectionId}
              >
                {connections.map((connection) => (
                  <SelectItem key={connection.id} id={connection.id}>
                    {connection.name}
                  </SelectItem>
                ))}
              </Select>

              <Link
                icon={<Plus size={32} />}
                className="px-4"
                onPress={handleClickAddNewIntegration}
              >
                <Text className="text-lg">Add a new integration</Text>
              </Link>
            </div>

            <div className="flex gap-4 px-4 mb-4 flex-col md:flex-row">
              <Button
                size="large"
                variant="secondary"
                fullWidth
                onPress={handleCancel}
              >
                Cancel
              </Button>
              <Button
                size="large"
                fullWidth
                type="submit"
                isDisabled={!isValid}
              >
                Save
              </Button>
            </div>
          </Card>
        </form>
      </PageLayout>
    </Page>
  );
};

export default ProductHubCreateCataloguePage;
