import { FC, useState } from 'react';
import cn from 'classnames';
import { Application } from '@raydiant/api-client-js';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Hidden from 'raydiant-elements/layout/Hidden';
import useStyles from './MarketplaceV2Card.styles';
import { MarketplaceApplication } from '../../pages/MarketplacePage/MarketplaceV2Page';

interface MarketplaceCardV2Props {
  application: MarketplaceApplication;
  onLearnMoreClick?: (app: string) => void;
  onInstallClick?: () => void;
  onClick?: (application: Application) => void;
  auto?: boolean;
}

const MarketplaceV2Card: FC<MarketplaceCardV2Props> = ({
  application,
  onClick,
  auto = false,
}) => {
  const [overlay, setOverlay] = useState<boolean>(false);
  const { currentAppVersion, name, checked } = application;

  const { thumbnailUrl } = currentAppVersion;
  const cta = currentAppVersion.name || name;

  const classes = useStyles();

  const handleOverlay = () => {
    setOverlay(!overlay);
  };

  const renderDesktopCard = () => {
    return (
      <div
        className={classes.image}
        style={{ backgroundImage: `url(${thumbnailUrl})` }}
        onMouseEnter={() => handleOverlay()}
      />
    );
  };

  const renderMobileCard = () => {
    return (
      <div
        className={classes.image}
        style={{ backgroundImage: `url(${thumbnailUrl})` }}
        onClick={() => handleOverlay()}
      />
    );
  };

  if (!application) return null;

  return (
    <div className={cn(classes.root, auto && classes.auto)}>
      {checked && (
        <div className={classes.checkmark}>
          <CheckCircleIcon className={classes.checkIcon} />
        </div>
      )}
      <button className={classes.thumbnail}>
        <Hidden xsDown>{renderDesktopCard()}</Hidden>
        <Hidden smUp>{renderMobileCard()}</Hidden>
        {overlay && (
          <div
            className={classes.overlay}
            onMouseLeave={() => handleOverlay()}
            onClick={() => onClick?.(application)}
          >
            <div className={classes.buyButton}>Buy</div>
          </div>
        )}
      </button>
      {cta && <div className={classes.cta}>{cta}</div>}
    </div>
  );
};

export default MarketplaceV2Card;
