import { SVGProps } from 'react';

interface UserProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const User = ({ size = 18, ...props }: UserProps) => {
  return (
    <svg
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.5938 5.375C10.5938 4.44531 10.0625 3.58203 9.26562 3.08398C8.43555 2.61914 7.40625 2.61914 6.60938 3.08398C5.7793 3.58203 5.28125 4.44531 5.28125 5.375C5.28125 6.33789 5.7793 7.20117 6.60938 7.69922C7.40625 8.16406 8.43555 8.16406 9.26562 7.69922C10.0625 7.20117 10.5938 6.33789 10.5938 5.375ZM3.6875 5.375C3.6875 3.88086 4.48438 2.48633 5.8125 1.72266C7.10742 0.958984 8.73438 0.958984 10.0625 1.72266C11.3574 2.48633 12.1875 3.88086 12.1875 5.375C12.1875 6.90234 11.3574 8.29688 10.0625 9.06055C8.73438 9.82422 7.10742 9.82422 5.8125 9.06055C4.48438 8.29688 3.6875 6.90234 3.6875 5.375ZM2.12695 16.5312H13.7148C13.416 14.4395 11.623 12.8125 9.43164 12.8125H6.41016C4.21875 12.8125 2.42578 14.4395 2.12695 16.5312ZM0.5 17.1621C0.5 13.875 3.12305 11.2188 6.41016 11.2188H9.43164C12.7188 11.2188 15.375 13.875 15.375 17.1621C15.375 17.6934 14.9102 18.125 14.3789 18.125H1.46289C0.931641 18.125 0.5 17.6934 0.5 17.1621Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default User;
