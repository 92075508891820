import { FC } from 'react';
import cn from 'classnames';
import { Dialog, DialogContent, DialogContentText } from '@material-ui/core';
import Button from 'raydiant-elements/core/Button';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

import DXModalContent from './DXModalContent';
import CXModalContent from './CXModalContent';
import EXModalContent from './EXModalContent';
import AIModalContent from './AIModalContent';
import TikTokModalContent from './TikTokModalContent';

import { MarketPlaceTile as MarketPlaceTileType } from '../../hooks/useMarketPlaceTiles';
import MarketplaceTile from '../../components/MarketplaceTile';

import { Grid } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import ContentCard from './components/ContentCard';

interface TileModalLayoutProps {
  data: MarketPlaceTileType | null;
  open: boolean;
  onClick: () => void;
  onClose: () => void;
  isDisabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      padding: '0px 0px !important',
    },
    header: {
      height: '25em',
    },
    largerHeader: {
      height: '30em',
    },
    button: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    heading: {
      fontSize: '2.564rem',
      lineHeight: '1.09',
      letterSpacing: '0.25px',
      fontWeight: 300,
    },
    footer: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: theme.spacing(2),
      right: theme.spacing(2),
      flexWrap: 'wrap',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    closeButton: {
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: 1.26,
      letterSpacing: '2px',
      color: 'rgba(0, 0, 0, 0.75)',
    },
    closeButtonIcon: {
      fontSize: 24,
      color: 'rgba(0, 0, 0, 0.75)',
    },
    ai: {
      backgroundColor: '#0047d4',
      '&:hover': {
        backgroundColor: '#0047d4',
      },
    },
  }),
);

const TileModalLayout: FC<TileModalLayoutProps> = ({
  data,
  open,
  onClick,
  onClose,
  isDisabled,
}) => {
  const classes = useStyles();

  if (!data) return null;

  return (
    <Dialog open={open} maxWidth="md">
      <DialogContent className={classes.dialogContent}>
        <DialogContentText id="alert-dialog-description">
          <Grid
            className={
              data?.type === 'tiktok' ? classes.largerHeader : classes.header
            }
          >
            <MarketplaceTile
              isLoading={false}
              data={data}
              onClick={() => onClick()}
              isModal
              isDisabled={isDisabled}
            />
          </Grid>
          {data?.type === 'dx' && <DXModalContent />}
          {data?.type === 'ex' && <EXModalContent />}
          {data?.type === 'cx' && <CXModalContent />}
          {data?.type === 'ai' && <AIModalContent />}
          {data?.type === 'tiktok' && <TikTokModalContent />}
          {data?.type !== 'tiktok' && (
            <ContentCard className={classes.footer}>
              <Grid>
                <h1
                  className={classes.heading}
                  style={{ color: `${data.color}` }}
                >
                  Like what you see?
                </h1>
                <Button
                  label="Reach out to Sales"
                  color={data.buttonColor}
                  onClick={() => onClick()}
                  className={cn(
                    classes.button,
                    data?.type === 'ai' && classes.ai,
                  )}
                  disabled={isDisabled}
                />
              </Grid>
            </ContentCard>
          )}
        </DialogContentText>
      </DialogContent>
      <Grid className={classes.buttonWrapper} onClick={() => onClose()}>
        <div className={classes.closeButton}>CLOSE</div>
        <ClearIcon className={classes.closeButtonIcon} />
      </Grid>
    </Dialog>
  );
};

export default TileModalLayout;
