import { useState } from 'react';
import { Grid } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import * as paths from '../../routes/paths';
import { useHistory } from 'react-router-dom';
import Page from '../../components/Page';
import Paper from 'raydiant-elements/core/Paper';
import Title from 'raydiant-elements/typography/Title';
import Button from 'raydiant-elements/core/Button';
import useStyles from './AddIntegrationPage.styles';
import ConnectionSettings from './components/ConnectionSettings';
import ConnectionSteps from './components/ConnectionSteps';
import AddLocation from './components/AddLocation';

export interface AddIntegrationPageProps {
  integrations: any[];
  onApplyClick: (integration: any) => void;
}

const AddIntegrationPage = ({
  integrations,
  onApplyClick,
}: AddIntegrationPageProps) => {
  const classes = useStyles();
  const history = useHistory();

  // State
  const [createIntegration, setCreateIntegration] = useState<string>('');

  const handleCreateintegrationSelect = (integration: string) => {
    setCreateIntegration(integration);
  };

  return (
    <Page>
      <Paper color="light" className={classes.integration}>
        <div className={classes.integrationMain}>
          <div className={classes.section}>
            <Grid item>
              <Button
                icon={<ArrowBackIcon />}
                iconAlignment="start"
                label="Back to integrations"
                onClick={() => history.push(paths.integration())}
              />
            </Grid>
            <Grid item className={classes.heading}>
              <Title>Settings</Title>
            </Grid>

            <Grid item container className={classes.wrapper} spacing={8}>
              <ConnectionSettings
                integrations={integrations}
                selectedIntegration={createIntegration}
                onIntegrationSelect={handleCreateintegrationSelect}
              />
              <ConnectionSteps selectedIntegrationId={createIntegration} />
              <AddLocation
                selectedIntegration={createIntegration}
                onApplyClick={onApplyClick}
              />
            </Grid>
          </div>
        </div>
      </Paper>
    </Page>
  );
};

export default AddIntegrationPage;
