import { SVGProps } from 'react';

interface BuildingsProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const Buildings = ({ size = 15, ...props }: BuildingsProps) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.95 1.4375H8.325C8.06484 1.4375 7.8625 1.66875 7.8625 1.9V13C7.8625 13.2602 8.06484 13.4625 8.325 13.4625H12.95C13.1812 13.4625 13.4125 13.2602 13.4125 13V1.9C13.4125 1.66875 13.1812 1.4375 12.95 1.4375ZM8.325 0.0500015H12.95C13.9617 0.0500015 14.8 0.888283 14.8 1.9V13C14.8 14.0406 13.9617 14.85 12.95 14.85H8.325C7.28437 14.85 6.475 14.0406 6.475 13V1.9C6.475 0.888283 7.28437 0.0500015 8.325 0.0500015ZM5.55 3.75V5.1375H2.08125C1.67656 5.1375 1.3875 5.45547 1.3875 5.83125V12.7688C1.3875 13.1734 1.67656 13.4625 2.08125 13.4625H5.57891C5.66562 14.0117 5.89687 14.4742 6.24375 14.85H2.08125C0.925 14.85 0 13.925 0 12.7688V5.83125C0 4.70391 0.925 3.75 2.08125 3.75H5.55ZM3.2375 9.3H4.1625C4.39375 9.3 4.625 9.53125 4.625 9.7625V10.6875C4.625 10.9477 4.39375 11.15 4.1625 11.15H3.2375C2.97734 11.15 2.775 10.9477 2.775 10.6875V9.7625C2.775 9.53125 2.97734 9.3 3.2375 9.3ZM9.7125 9.7625V8.8375C9.7125 8.60625 9.91484 8.375 10.175 8.375H11.1C11.3312 8.375 11.5625 8.60625 11.5625 8.8375V9.7625C11.5625 10.0227 11.3312 10.225 11.1 10.225H10.175C9.91484 10.225 9.7125 10.0227 9.7125 9.7625ZM3.2375 6.525H4.1625C4.39375 6.525 4.625 6.75625 4.625 6.9875V7.9125C4.625 8.17266 4.39375 8.375 4.1625 8.375H3.2375C2.97734 8.375 2.775 8.17266 2.775 7.9125V6.9875C2.775 6.75625 2.97734 6.525 3.2375 6.525ZM9.7125 3.2875C9.7125 3.05625 9.91484 2.825 10.175 2.825H11.1C11.3312 2.825 11.5625 3.05625 11.5625 3.2875V4.2125C11.5625 4.47266 11.3312 4.675 11.1 4.675H10.175C9.91484 4.675 9.7125 4.47266 9.7125 4.2125V3.2875ZM10.175 7.45C9.91484 7.45 9.7125 7.24766 9.7125 6.9875V6.0625C9.7125 5.83125 9.91484 5.6 10.175 5.6H11.1C11.3312 5.6 11.5625 5.83125 11.5625 6.0625V6.9875C11.5625 7.24766 11.3312 7.45 11.1 7.45H10.175Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Buildings;
