import { useEffect, useRef } from 'react';
import { BatchDevice, TagAction } from './deviceBatchSettings.interface';
import {
  Device,
  Tag,
  Profile,
  BatchDeviceStatus,
  BatchActionStatus,
  ActionStatusAction,
} from '@raydiant/api-client-js';
import * as R from '../../clients/mira/types/Resource';
import { capitalize } from '../../utilities/stringUtils';

interface TagFilter {
  [key: string]: any;
}

export const formatOrientation = (orientation: string): string => {
  if (orientation === 'left') {
    return 'screens.portraitLeft';
  } else if (orientation === 'right') {
    return 'screens.portraitRight';
  } else if (orientation === 'normal') {
    return 'screens.landscape';
  } else {
    return '';
  }
};

export const formatTagsToData = (op: TagAction, tags: Tag[]) => {
  const updatedTags = tags.map((tag) => {
    const { type, key, label, value } = tag;
    return { name: 'tags', op, payload: { type, key, label, value } };
  });
  return updatedTags;
};

export const formatSharingProfilesToData = (
  users: (Profile | R.ResourceProfile)[],
) => users.map((user) => user.id);

export const formatDataToPayload = (key: string, value: any) => {
  return { name: key, payload: value };
};

export const formatToBatchData = (
  selectedDeviceIds: string[],
  deviceBatchSettings: BatchDevice,
) => {
  const batchDeviceIds = selectedDeviceIds;
  const batchActions = [] as any[];
  const {
    playlistId,
    screenDimensions,
    screenOrientation,
    timezone,
    removeKeyTags,
    removeValueTags,
    overwriteTags,
    addTags,
    activeHoursStart,
    activeHoursEnd,
    dailyRebootTime,
    dailyRestartTime,
    itemsToShare,
    itemsToUnShare,
    autoPublish,
  } = deviceBatchSettings;

  if (playlistId) {
    batchActions.push(formatDataToPayload('playlist', playlistId));
  }

  if (screenDimensions) {
    batchActions.push(
      formatDataToPayload('screen_dimensions', screenDimensions),
    );
  }

  if (screenOrientation) {
    batchActions.push(formatDataToPayload('orientation', screenOrientation));
  }

  if (timezone) {
    batchActions.push(formatDataToPayload('timezone', timezone));
  }

  if (removeKeyTags.length) {
    batchActions.push(...formatTagsToData('remove_key', removeKeyTags));
  }

  if (removeValueTags.length) {
    batchActions.push(...formatTagsToData('remove_value', removeValueTags));
  }

  if (overwriteTags.length) {
    batchActions.push(...formatTagsToData('overwrite', overwriteTags));
  }

  if (addTags.length) {
    batchActions.push(...formatTagsToData('add', addTags));
  }

  if (activeHoursStart && activeHoursEnd) {
    batchActions.push(
      formatDataToPayload('active_hours', {
        start: activeHoursStart,
        end: activeHoursEnd,
      }),
    );
  }

  if (dailyRebootTime) {
    batchActions.push(
      formatDataToPayload('daily_reboot_time', dailyRebootTime),
    );
  }

  if (dailyRestartTime) {
    batchActions.push(
      formatDataToPayload('daily_restart_time', dailyRestartTime),
    );
  }

  if (itemsToShare.length > 0) {
    batchActions.push(
      formatDataToPayload(
        'device_sharing',
        formatSharingProfilesToData(itemsToShare),
      ),
    );
  }

  if (itemsToUnShare.length > 0) {
    batchActions.push(
      formatDataToPayload(
        'revoke_sharing',
        formatSharingProfilesToData(itemsToUnShare),
      ),
    );
  }

  if (autoPublish) {
    batchActions.push({ name: 'publish' });
  }

  return { device_ids: batchDeviceIds, actions: batchActions };
};

export const isDayValue = (value: any) =>
  value === 'days_of_week' ? true : false;

export const formatTagValueToOptions = (value: string | R.Day[]) => {
  if (Array.isArray(value)) {
    return value;
  } else {
    return value.split('|').map((value) => value.trim());
  }
};

export const getRemainingTagValue = (
  tags: Tag[],
  partialTags: Tag[],
  tagKey: string,
) => {
  const selectedTag = tags.find((tag) => tag.key === tagKey);

  if (!selectedTag) return [];

  const selectedPartialTag = partialTags.find((tag) => tag.key === tagKey);

  if (!selectedPartialTag) return [];

  const tagOptions = formatTagValueToOptions(selectedTag.value);
  const partialTagOptions = formatTagValueToOptions(selectedPartialTag.value);
  const remainingOptions = tagOptions.filter(
    (option) => !partialTagOptions.includes(option),
  );

  return remainingOptions;
};

export const getExistingTags = (devices: Device[]) => {
  const existingTagsObj = {} as TagFilter;

  devices.forEach((device) => {
    const deviceTags = device.resource.r.tags;
    if (deviceTags.length > 0) {
      deviceTags.forEach((tag) => {
        if (tag.key in existingTagsObj) {
          const value = getRemainingTagValue(
            [tag],
            Object.values(existingTagsObj),
            tag.key,
          );
          if (isDayValue(tag.key)) {
            existingTagsObj[tag.key].value = [
              ...existingTagsObj[tag.key].value,
              ...value,
            ];
          } else {
            existingTagsObj[tag.key].value = `${
              existingTagsObj[tag.key].value
            } | ${value.join(' | ')}`;
          }
        } else {
          existingTagsObj[tag.key] = { ...tag };
        }
      });
    }
  });

  const existingTags = Object.values(existingTagsObj) as Tag[];

  return existingTags;
};

export const getBatchActionStatus = (
  deviceId: string,
  data: BatchDeviceStatus[],
): BatchDeviceStatus[] => {
  const selectedDevice = data.filter(
    (batchDeviceStatus) => batchDeviceStatus.deviceId === deviceId,
  );

  return selectedDevice;
};

const filterNonProgressBatchActions = (batchActions: BatchActionStatus[]) =>
  batchActions.filter((batchAction) => batchAction.status !== 'in_progress');

export const getUserOwnedBatchActions = (
  data: BatchDeviceStatus[],
): BatchActionStatus[] => {
  let batchActions = [] as BatchActionStatus[];
  data.forEach((batchDevice) => {
    batchActions = [
      ...batchActions,
      ...filterNonProgressBatchActions(batchDevice.actionStatus),
    ];
  });
  return batchActions;
};

export const isOnlyPublishRemaining = (data: BatchDeviceStatus[]) => {
  let nextActionStatus = [] as BatchActionStatus[];
  data.forEach((batchDevice) => {
    nextActionStatus = [...nextActionStatus, ...batchDevice.actionStatus];
  });

  const inProgressActions = nextActionStatus.filter(
    (actionStatus) => actionStatus.status === 'in_progress',
  );

  const inPublishAction = nextActionStatus.filter(
    (actionStatus) => actionStatus.action === 'publish',
  );

  return inProgressActions.length === inPublishAction.length;
};

export const clientFacingBatchActions = (action: ActionStatusAction) => {
  switch (action) {
    case 'screen_dimensions':
      return 'Screen Dimensions';
    case 'orientation':
      return 'Screen Orientation';
    case 'device_sharing':
      return 'Share with Account';
    case 'revoke_sharing':
      return 'Unshare with Account';
    case 'daily_restart_time':
      return 'Restart Devices';
    case 'daily_reboot_time':
      return 'Reboot Devices';
    case 'active_hours':
      return 'Active Hours';
    case 'publish':
      return 'Publish Devices';
    default:
      capitalize(action);
  }
};

export const isTagAdded = (tags: Tag[], key: string) => {
  return tags.some((t) => t.key === key);
};

export const mapTypes = (key: string) => {
  switch (key) {
    case 'end_date':
    case 'start_date':
      return 'date';
    case 'end_time':
    case 'start_time':
      return 'time';
    case 'days_of_week':
      return 'days_of_week';
    default:
      return 'text';
  }
};

export const defaultTagValue = (key: string) =>
  mapTypes(key) === 'date' ? new Date().toDateString() : '';

export const useInterval = (callback: any, delay: number | null) => {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    if (delay === null) return;

    const timer = setInterval(() => savedCallback.current(), delay);
    return () => clearInterval(timer);
  }, [delay]);
};
