import { useState } from 'react';
import Column from 'raydiant-elements/layout/Column';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import Heading from 'raydiant-elements/core/Heading';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import Link from 'raydiant-elements/core/Link';
import Button from 'raydiant-elements/core/Button';
import { SettingsConfig } from '../devicePerchSettings.interface';

interface DevicePerchSettingsConfigManagerProps {
  onImportClick: (data: SettingsConfig) => void;
}

const DevicePerchSettingsConfigManager = ({
  onImportClick,
}: DevicePerchSettingsConfigManagerProps) => {
  const [configuration, setConfiguration] = useState('');

  const classes = useStyles();

  const handleChange = (e: any) => {
    const fileReader = new FileReader();
    if (!e.target.files[0]) return;
    fileReader.readAsText(e.target.files[0], 'UTF-8');
    fileReader.onload = (e) => {
      const importedFile = e?.target?.result as string;

      if (!importedFile || importedFile === configuration) return;
      onImportClick(JSON.parse(importedFile));
      setConfiguration(importedFile);
    };
    e.target.value = '';
  };

  return (
    <Column className={classes.root}>
      <div>
        <Heading size={5} overline gutterBottom className={classes.heading}>
          Import Configuration
        </Heading>
        <InputHelperText>
          <Link
            href="https://support.raydiant.com/hc/en-us/articles/"
            target="_blank"
          >
            What's this?
          </Link>
        </InputHelperText>
      </div>
      <input
        type="file"
        id="perch_config_files"
        hidden
        onChange={handleChange}
        accept="application/json"
      />
      <label htmlFor="perch_config_files">
        <Button
          onClick={() => {
            document.getElementById('perch_config_files')?.click();
          }}
          color="primary"
          fullWidth
          className={classes.button}
        >
          Import Configuration from file
        </Button>
      </label>
    </Column>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      marginBottom: theme.spacing(2),
    },

    heading: {
      marginBottom: 0,
      paddingBottom: 0,
    },

    wrapper: {
      border: `1px solid #e3e3e2`,
      borderRadius: theme.spacing(0.5),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      boxShadow:
        '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    },

    button: {
      marginBottom: theme.spacing(2),
    },

    text: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '0.786rem',
      fontWeight: 400,
      letterSpacing: '0.29px',
    },
  });
});

export default DevicePerchSettingsConfigManager;
