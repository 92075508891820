import classNames from 'classnames';

type DropDownHeaderProps = {
  children: React.ReactNode;
  className?: string;
};

const DropDownHeader = ({ children, className }: DropDownHeaderProps) => {
  return (
    <li
      className={classNames(
        'menu-item hover:!bg-transparent cursor-none',
        className,
      )}
    >
      {children}
    </li>
  );
};

export default DropDownHeader;
