import { ApiClientError } from '@raydiant/api-client-js';
import { useQueryClient } from 'react-query';
import raydiant from '../clients/raydiant';
import { keys } from '../queryClient';
import useProtectedMutation from './useProtectedMutation';

export default function useDeleteConnection() {
  const queryClient = useQueryClient();

  const mutation = useProtectedMutation<void, ApiClientError, string>(
    (id) => raydiant.deleteConnection(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(keys.connections());
      },
    },
  );

  return mutation;
}
