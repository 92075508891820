import { ContentEngagementByPresentation } from '@raydiant/api-client-js';
import ChartCard from '../ChartCard/ChartCard';
import { useTranslation } from 'react-i18next';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { BarChart, LineChart } from 'echarts/charts';
import {
  CHART_DEFAULT_AXIS_OPTIONS,
  CHART_DEFAULT_OPTIONS,
  CHART_DEFAULT_SERIES,
} from '../utils';
import ChartToolTip, { EChartsToolTipParams } from '../ChartToolTip';
import { renderToStaticMarkup } from 'react-dom/server';
import Hidden from 'raydiant-elements/layout/Hidden';
import { Popover } from '@headlessui/react';
import QuestionMarkIcon from '../../../components/QuestionMarkIcon';
import PopoverButton from '../../../components/Popover/PopoverButton';
import {
  getDisplayValue,
  transformToLabelText,
} from '../ShopperEngagementInsights/utils';

echarts.use([BarChart, LineChart]);
type TopLocationsChartProps = {
  data: ContentEngagementByPresentation[];
  domainName: string;
};

const getXAxisData = (data: ContentEngagementByPresentation[]) =>
  data?.map((value) => transformToLabelText(value.presentationName));

const getYAxisData = (
  data: ContentEngagementByPresentation[],
  view: keyof ContentEngagementByPresentation,
) => data?.map((value) => value[view]);

const TopPerformingContentChart = ({
  data,
  domainName,
}: TopLocationsChartProps) => {
  const { t } = useTranslation();

  const getSeries = (data: ContentEngagementByPresentation[]) => {
    let series = [];

    series.push({
      ...CHART_DEFAULT_SERIES.bar,
      data: getYAxisData(data, 'ots'),
      name: t('analytics.contentEngagementPage.tableHeaders.ots'),
      stack: 'ots',
    });

    series.push({
      ...CHART_DEFAULT_SERIES.bar,
      data: getYAxisData(data, 'verifiedViews'),
      name: t('analytics.contentEngagementPage.tableHeaders.contentViews'),
      stack: 'verifiedViews',
    });

    series.push({
      ...CHART_DEFAULT_SERIES.line,
      data: getYAxisData(data, 'avgViewDuration'),
      name: t('analytics.contentEngagementPage.tableHeaders.avgViewingTime'),
      lineStyle: {
        color: '#faab91',
        width: 3,
      },
      showSymbol: false,
      yAxisIndex: 1,
    });

    return series;
  };

  const color = ['#08177a', '#879cff', '#faab91'];

  const options = {
    ...CHART_DEFAULT_OPTIONS,
    color,
    legend: {
      ...CHART_DEFAULT_OPTIONS.legend,
      data: [
        {
          name: t('analytics.contentEngagementPage.tableHeaders.ots'),
          icon: 'square',
        },
        {
          name: t('analytics.contentEngagementPage.tableHeaders.contentViews'),
          icon: 'square',
        },
        {
          name: t(
            'analytics.contentEngagementPage.tableHeaders.avgViewingTime',
          ),
          icon: 'square',
        },
      ],
    },
    xAxis: [
      {
        ...CHART_DEFAULT_AXIS_OPTIONS.xAxis,
        data: getXAxisData(data),
        name: `${domainName} Content`.toUpperCase(),
      },
    ],
    yAxis: [
      {
        ...CHART_DEFAULT_AXIS_OPTIONS.yAxis,
        alignTicks: true,
        name: t(
          'analytics.contentEngagementPage.tableHeaders.engagement',
        ).toUpperCase(),
        axisLabel: {
          ...CHART_DEFAULT_AXIS_OPTIONS.yAxis.axisLabel,
        },
      },
      {
        ...CHART_DEFAULT_AXIS_OPTIONS.yAxis,
        name: `${t(
          'analytics.contentEngagementPage.tableHeaders.avgViewingTime',
        )} (${t(
          'analytics.trafficAwarenessPage.tableHeaders.inSeconds',
        )})`.toUpperCase(),
        axisLabel: {
          ...CHART_DEFAULT_AXIS_OPTIONS.yAxis.axisLabel,
          margin: 14,
        },
      },
    ],
    series: getSeries(data),
    tooltip: {
      ...CHART_DEFAULT_OPTIONS.tooltip,
      formatter: (params: EChartsToolTipParams[]) => {
        const index = params[0]?.dataIndex;
        const value = data[index];

        const modifiedParams: EChartsToolTipParams[] = params.map((item) => {
          if (
            item.seriesName ===
            t('analytics.contentEngagementPage.tableHeaders.avgViewingTime')
          ) {
            return { ...item, value: getDisplayValue(item.value, ' sec') };
          } else {
            return { ...item, value: getDisplayValue(item.value) };
          }
        });

        return renderToStaticMarkup(
          <ChartToolTip
            header={
              <p className="font-bold text-sm px-2 py-1 capitalize text-black">
                {transformToLabelText(value.presentationName)}
              </p>
            }
            values={modifiedParams}
          />,
        );
      },
    },
  };

  const mobileOptions = {
    ...options,
    legend: {
      ...CHART_DEFAULT_OPTIONS.legend,
      data: [
        {
          name: t('analytics.contentEngagementPage.tableHeaders.impressions'),
          icon: 'square',
        },
        {
          name: t('analytics.contentEngagementPage.tableHeaders.verifiedViews'),
          icon: 'square',
        },
        {
          name: t(
            'analytics.contentEngagementPage.tableHeaders.avgViewingTime',
          ),
          icon: 'square',
        },
      ],
      type: 'scroll',
    },
    series: getSeries(data),
  };

  return (
    <ChartCard
      subTitle={''}
      title={
        <div className="flex gap-3 items-center">
          <h2 className="text-black text-xl font-bold">
            {
              t(
                'analytics.contentEngagementPage.topPerformingContentChart.title',
              )!
            }
          </h2>
          <Popover className="relative">
            <PopoverButton icon={<QuestionMarkIcon />} />
            <Popover.Panel className="absolute z-10 card popover drop-shadow-xl">
              <div className="overflow-hidden">
                <p className="mb-3">
                  {
                    t(
                      'analytics.contentEngagementPage.topPerformingContentChart.tooltip',
                    )!
                  }
                </p>
                <a
                  href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('analytics.learnmore')}
                </a>
              </div>
            </Popover.Panel>
          </Popover>
        </div>
      }
      showTimeInterval={false}
    >
      <div className="min-w-full min-h-full">
        <Hidden xsDown>
          <ReactECharts
            style={{ height: '410px' }}
            option={options}
            onChartReady={(chart) => chart.resize()}
          />
        </Hidden>
        <Hidden smUp>
          <ReactECharts
            style={{ height: '410px' }}
            option={mobileOptions}
            onChartReady={(chart) => chart.resize()}
          />
        </Hidden>
      </div>
    </ChartCard>
  );
};

export default TopPerformingContentChart;
