import ChartCard from '../ChartCard/ChartCard';
import { useTranslation } from 'react-i18next';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { CHART_DEFAULT_AXIS_OPTIONS, CHART_DEFAULT_OPTIONS } from '../utils';
import Hidden from 'raydiant-elements/layout/Hidden';
import { Popover } from '@headlessui/react';
import QuestionMarkIcon from '../../../components/QuestionMarkIcon';
import { WeekdayHourTraffic } from '@raydiant/api-client-js';
import { days, getAgeDistributionChartData } from './utils';
import ChartToolTip, { EChartsToolTipParams } from '../ChartToolTip';
import { renderToStaticMarkup } from 'react-dom/server';

echarts.use([LineChart]);

interface AgeDistributionChartProps {
  data: WeekdayHourTraffic[];
}

const AgeDistributionChart = ({ data }: AgeDistributionChartProps) => {
  const { t } = useTranslation();

  const dataToAgeGroup = getAgeDistributionChartData(data);

  const options = {
    ...CHART_DEFAULT_OPTIONS,
    color: [
      '#FC787D',
      '#FF8063',
      '#4270EB',
      '#A870FF',
      '#38E3C9',
      '#33CC70',
      '#FDDA5F',
      '#FFC245',
    ],
    title: {
      text: '',
    },
    tooltip: {
      ...CHART_DEFAULT_OPTIONS.tooltip,
      formatter: (params: EChartsToolTipParams[]) => {
        const modifiedParams = params.map((item) => {
          if (item.seriesName === 'Male' || item.seriesName === 'Female') {
            return { ...item, value: `${item.value}%` };
          } else {
            return item;
          }
        });

        return renderToStaticMarkup(
          <ChartToolTip
            header={
              <p className="font-bold text-sm px-2 py-1 capitalize text-black">
                {params[0]?.axisValueLabel}
              </p>
            }
            values={modifiedParams}
          />,
        );
      },
    },
    legend: {
      ...CHART_DEFAULT_OPTIONS.legend,
      data: [
        '0-12',
        '13-17',
        '18-24',
        '25-34',
        '35-44',
        '45-54',
        '55-64',
        '65+',
      ],
      icon: 'square',
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      ...CHART_DEFAULT_AXIS_OPTIONS.xAxis,
      name: t('analytics.time').toUpperCase(),
      data: days,
    },
    yAxis: {
      ...CHART_DEFAULT_AXIS_OPTIONS.yAxis,
      name: t('analytics.trafficAwarenessPage.tableHeaders.ots').toUpperCase(),
    },
    series: [
      {
        name: '0-12',
        type: 'line',
        data: dataToAgeGroup.a0_12,
        lineStyle: {
          width: 3,
        },
        showSymbol: false,
      },
      {
        name: '13-17',
        type: 'line',
        data: dataToAgeGroup.a13_17,
        lineStyle: {
          width: 3,
        },
        showSymbol: false,
      },
      {
        name: '18-24',
        type: 'line',
        data: dataToAgeGroup.a18_24,
        lineStyle: {
          width: 3,
        },
        showSymbol: false,
      },
      {
        name: '25-34',
        type: 'line',
        data: dataToAgeGroup.a25_34,
        lineStyle: {
          width: 3,
        },
        showSymbol: false,
      },
      {
        name: '35-44',
        type: 'line',
        data: dataToAgeGroup.a35_44,
        lineStyle: {
          width: 3,
        },
        showSymbol: false,
      },
      {
        name: '45-54',
        type: 'line',
        data: dataToAgeGroup.a45_54,
        lineStyle: {
          width: 3,
        },
        showSymbol: false,
      },
      {
        name: '55-64',
        type: 'line',
        data: dataToAgeGroup.a55_64,
        lineStyle: {
          width: 3,
        },
        showSymbol: false,
      },
      {
        name: '65+',
        type: 'line',
        data: dataToAgeGroup.a65_plus,
        lineStyle: {
          width: 3,
        },
        showSymbol: false,
      },
    ],
  };

  const mobileOptions = {
    ...options,
    legend: {
      ...CHART_DEFAULT_OPTIONS.legend,
      type: 'scroll',
    },
  };

  return (
    <ChartCard
      subTitle={''}
      title={
        <div className="flex gap-3 items-center">
          <h2 className="text-black text-xl font-bold">
            {t('analytics.trafficAwarenessPage.ageDistribution.title')!}
          </h2>
          <Popover className="relative">
            <Popover.Button>
              <QuestionMarkIcon />
            </Popover.Button>
            <Popover.Panel className="absolute z-10 card popover">
              <div className="overflow-hidden">
                <p className="mb-3">
                  {t('analytics.trafficAwarenessPage.ageDistribution.tooltip')}
                </p>
                <a
                  href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('analytics.learnmore')}
                </a>
              </div>
            </Popover.Panel>
          </Popover>
        </div>
      }
      showTimeInterval={false}
    >
      <div className="min-w-full min-h-full">
        <Hidden xsDown>
          <ReactECharts
            style={{ height: '410px' }}
            option={options}
            onChartReady={(chart) => chart.resize()}
          />
        </Hidden>
        <Hidden smUp>
          <ReactECharts
            style={{ height: '410px' }}
            option={mobileOptions}
            onChartReady={(chart) => chart.resize()}
          />
        </Hidden>
      </div>
    </ChartCard>
  );
};

export default AgeDistributionChart;
