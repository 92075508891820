import DropDown from '../../../components/DropDown/DropDown';
import { useTranslation } from 'react-i18next';
import DropDownPlaceholder from '../../../components/DropDown/DropDownPlaceholder';
import ScreensFilterControl from './ScreensFilterControl';
import ScreensFilterDropDownActions from './ScreensFilterDropDownActions';

type ConnectionStatusDropDownProps = {
  className?: string;
  onChange: (value: ConnectionStatusQuery) => void;
  value: ConnectionStatusQuery;
};

export type ConnectionStatusQuery = {
  offline: boolean;
  online: boolean;
};

const ConnectionStatusDropDown = ({
  className,
  onChange,
  value,
}: ConnectionStatusDropDownProps) => {
  const { t } = useTranslation(['devices']);

  const selectedCount = value?.online || value?.offline ? 1 : 0;
  const hasValue = Object.values(value).some(Boolean);

  return (
    <DropDown
      anchorElement={
        <DropDownPlaceholder className="min-w-[198px]">
          <div className="flex items-center justify-between w-full">
            <p>{t('screens.connectionStatus')}</p>
            {selectedCount > 0 && (
              <span className="mr-1 text-primary">({selectedCount})</span>
            )}
          </div>
        </DropDownPlaceholder>
      }
      className={className}
      itemsClassNameAs="ul"
      itemsClassName="!origin-top-left min-w-full md:!min-w-[380px] left-0"
    >
      <div className="p-6 text-black flex flex-col gap-2">
        <ScreensFilterControl
          footer={
            hasValue && (
              <ScreensFilterDropDownActions
                onClearAll={() =>
                  onChange({
                    online: false,
                    offline: false,
                  })
                }
              />
            )
          }
          title={
            <p className="font-[500] text-[16px]">
              {t('screens.connectionStatus')}
            </p>
          }
        >
          <form>
            <ul className="flex flex-col gap-2">
              <li className="flex items-center" key={'online'}>
                <input
                  checked={value?.online}
                  name={'connectionStatus'}
                  onChange={(e) =>
                    onChange({ offline: false, online: e.target.checked })
                  }
                  type="radio"
                  value={'online'}
                />
                <label className="text-base text-[#79797f]">
                  {t(`screens.online`)}
                </label>
              </li>
              <li className="flex items-center" key={'offline'}>
                <input
                  checked={value?.offline}
                  name={'connectionStatus'}
                  onChange={(e) =>
                    onChange({ online: false, offline: e.target.checked })
                  }
                  type="radio"
                  value={'offline'}
                />
                <label className="text-base text-[#79797f]">
                  {t(`screens.offline`)}
                </label>
              </li>
            </ul>
          </form>
        </ScreensFilterControl>
      </div>
    </DropDown>
  );
};

export default ConnectionStatusDropDown;
