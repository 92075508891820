import classNames from 'classnames';
import Hidden from 'raydiant-elements/layout/Hidden';
import { Popover } from '@headlessui/react';
import DropDownPlaceholder from '../DropDown/DropDownPlaceholder';
import DropDown from '../DropDown/DropDown';

type RaydialSelectProps = Omit<JSX.IntrinsicElements['select'], 'onChange'> & {
  disableMobile?: boolean;
  header?: React.ReactNode;
  label?: string;
  menuClassname?: string;
  onChange: (value: JSX.IntrinsicElements['select']['value']) => void;
  onClose?: () => void;
  onOpen?: () => void;
  open?: boolean;
  popover?: boolean;
  panelClassname?: string;
  wrapperClassName?: string;
  renderAnchorElement?: () => React.ReactNode;
};

//Note that this component should move to raydiant-elements v3 and should be replacing raydiant elements Select component

const Select = ({
  children,
  className,
  disableMobile,
  header,
  label,
  menuClassname,
  open = false,
  onClose,
  onOpen,
  panelClassname,
  popover = false,
  renderAnchorElement,
  wrapperClassName,
  ...props
}: RaydialSelectProps) => {
  const renderPopover = () => (
    <Popover
      className={classNames('w-full relative', className)}
      onChange={() => props.onChange(props.value)}
    >
      <Popover.Button
        className="input no-label text-left w-full"
        onClick={() => onOpen && onOpen()}
      >
        <DropDownPlaceholder className="md:w-full min-w-[122px]">
          <div className="flex items-center justify-between">
            {renderAnchorElement && renderAnchorElement()}
          </div>
        </DropDownPlaceholder>
      </Popover.Button>
      {open && (
        <>
          <div
            className="inset-0 fixed z-[8]"
            onClick={() => onClose && onClose()}
          />
          <Popover.Panel
            className={classNames(
              'absolute z-[8] card menu dropdown',
              panelClassname,
            )}
            static
            focus
          >
            {header && header}
            <div className={classNames(menuClassname)}>{children}</div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );

  const renderDropDown = () => (
    <DropDown
      className={classNames('w-full', className)}
      anchorElement={
        <div className="flex items-center justify-between">
          {renderAnchorElement && renderAnchorElement()}
          <svg
            className="w-4 text-black -rotate-90"
            height="1em"
            fill="currentColor"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 256 512"
          >
            <path d="M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z" />
          </svg>
        </div>
      }
      onChange={() => props.onChange(props.value)}
    >
      {header && header}
      {children}
    </DropDown>
  );

  return (
    <fieldset className={classNames(wrapperClassName)}>
      {label && <label>{label}</label>}
      {/* 
        Depending on Hidden which uses MUI under the hood is not ideal in the long run 
        We should be able to define our own Hidden component using custom hooks like useMediaQuery
        https://usehooks-ts.com/react-hook/use-media-query
      */}
      <Hidden xsDown={!disableMobile}>
        {popover && renderPopover()}
        {!popover && renderDropDown()}
      </Hidden>
      {!disableMobile && (
        <Hidden smUp>
          <select
            {...props}
            onChange={(e) => {
              props.onChange(e.currentTarget.value);
            }}
            className={classNames('w-full', className)}
          >
            {children}
          </select>
        </Hidden>
      )}
    </fieldset>
  );
};

export default Select;
