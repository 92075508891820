import { useState } from 'react';
import _ from 'lodash';
import {
  Location,
  CreateLocationRequest,
  LocationACLPendingInvite,
  LocationACL,
  DomainRole,
  DomainUser,
  PendingDomainUser,
} from '@raydiant/api-client-js';
import Button from '@raydiant/raydial/components/Button';
import Select, { SelectItem } from '@raydiant/raydial/components/Select';
import config from '../../../../config';
import {
  useGetDomainInvites,
  useGetDomainUsers,
  useInviteUserToLocation,
  useRemoveUserFromLocation,
} from '../../../../hooks';
import Modal from '@raydiant/raydial/components/Modal';
import Dialog, {
  DialogHeader,
  DialogHeading,
  DialogSubheading,
} from '@raydiant/raydial/components/Dialog';
import Text from '@raydiant/raydial/components/Text';
import SharedListItem from './SharedListItem';
import RemoveAccessConfirmationDialog from './RemoveAccessConfirmationDialog';
import { useToastState } from '@raydiant/raydial/components/Toast/ToastProvider';

interface ManageAccessDialogProps {
  isOpen: boolean;
  onSubmit: (locationId: string, params: CreateLocationRequest) => void;
  onClose: () => void;
  location: Location;
}

export interface SharableListItem {
  id: string;
  name: string;
}

const isNonAdminUser = (domainRole: DomainRole) => {
  return domainRole === 'restricted' || domainRole === 'standard';
};

const getSharableDomainUsers = (
  domainUsers: DomainUser[],
  sharedUsers: LocationACL[],
) => {
  const sharedUsersIds = sharedUsers.map((item) => item.grantProfile.id);

  const nonAdminUsers = domainUsers.filter((user) =>
    isNonAdminUser(user.domainRole),
  );
  const unSharedUsers = nonAdminUsers.filter(
    (user) => !sharedUsersIds.includes(user.id || ''),
  );

  return unSharedUsers;
};

const getSharableDomainInvites = (
  domainInvites: PendingDomainUser[],
  sharedInvites: LocationACLPendingInvite[],
) => {
  const sharedInviteIds = sharedInvites.map((item) => item.inviteId);

  const nonAdminInvites = domainInvites.filter((invite) =>
    isNonAdminUser(invite.domainRole as DomainRole),
  );
  const unSharedInvites = nonAdminInvites.filter(
    (invite) => !sharedInviteIds.includes(invite.id || ''),
  );

  return unSharedInvites;
};

const getSharableDomainUsersAndInviteList = (
  sharableDomainUsers: DomainUser[],
  sharableDomainInvites: PendingDomainUser[],
) => {
  const keyAddedDomainUsersList = sharableDomainUsers.map((user) => ({
    id: `profileId-${user.id}`,
    name: user.name,
  }));
  const keyAddedDomainInvites = sharableDomainInvites.map((invite) => ({
    id: `inviteId-${invite.id}`,
    name: `${invite.name} (Pending User)`,
  }));

  const sortedDomainUserList = _.sortBy(keyAddedDomainUsersList, 'name');
  const sortedDomainInviteList = _.sortBy(keyAddedDomainInvites, 'name');

  const addedList = [...sortedDomainUserList, ...sortedDomainInviteList];

  return addedList;
};

const getSharedDomainUsersAndInviteList = (
  sharedDomainUsers: LocationACL[],
  sharedDomainInvites: LocationACLPendingInvite[],
) => {
  const keyAddedDomainUsersList = sharedDomainUsers.map((user) => ({
    id: `profileId-${user.grantProfile.id}`,
    name: user.grantProfile.name,
  }));

  const pendingInvites = sharedDomainInvites.filter((invite) =>
    sharedDomainUsers.some((user) => user.grantProfile.email === invite.email),
  );

  const keyAddedDomainInvites = pendingInvites.map((invite) => ({
    id: `inviteId-${invite.inviteId}`,
    name: `${invite.name} (Pending User)`,
  }));

  const sortedDomainUsersList = _.sortBy(keyAddedDomainUsersList, 'name');
  const sortedDomainInviteList = _.sortBy(keyAddedDomainInvites, 'name');

  const addedList = [...sortedDomainUsersList, ...sortedDomainInviteList];

  return addedList;
};

const ManageAccessDialog = ({
  isOpen = false,
  onClose,
  location,
}: ManageAccessDialogProps) => {
  const state = useToastState();

  const { data: domainUsers, isLoading: isDomainUsersLoading } =
    useGetDomainUsers();
  const { data: pendingInvites, isLoading: isInvitesLoading } =
    useGetDomainInvites();
  const inviteUserToLocation = useInviteUserToLocation();
  const removeUserFromLocation = useRemoveUserFromLocation();

  const [selectedUser, setSelectedUser] = useState<SharableListItem | null>(
    null,
  );

  const filteredDomainUsers = domainUsers?.filter((user) => user.name);
  const filteredPendingInvites = pendingInvites?.filter(
    (invite) => invite.name,
  );

  const isLoading = isDomainUsersLoading || isInvitesLoading;
  const sharedDomainUsers = location?.acl ?? [];
  const sharedDomainInvites = location?.aclPendingInvites ?? [];
  const sharableDomainUsers = getSharableDomainUsers(
    filteredDomainUsers || [],
    sharedDomainUsers,
  );
  const sharableDomainInvites = getSharableDomainInvites(
    filteredPendingInvites || [],
    sharedDomainInvites,
  );
  const sharableDomainUsersAndInviteList = getSharableDomainUsersAndInviteList(
    sharableDomainUsers,
    sharableDomainInvites,
  );
  const sharedDomainUsersAndInviteList = getSharedDomainUsersAndInviteList(
    sharedDomainUsers,
    sharedDomainInvites,
  );

  const handleInviteUserToLocation = async (value: string) => {
    const isValueValid =
      value.includes('profileId-') || value.includes('inviteId-');
    if (!isValueValid) return;

    const body = {
      profileId: '',
      inviteId: '',
    };

    if (value.includes('profileId-')) {
      body.profileId = value.split('profileId-')[1];
    } else if (value.includes('inviteId-')) {
      body.inviteId = value.split('inviteId-')[1];
    }

    try {
      await inviteUserToLocation.mutate({
        locationId: location.id,
        params: body,
      });
      state.add({
        title: 'User access added',
        description: 'User added successfully',
      });
    } catch (error) {
      state.add({
        title: 'User access could not be added',
        description: 'Please try again',
        variant: 'caution',
      });
    }
  };

  const handleRemoveUserFromLocation = async (value: string) => {
    const isValueValid =
      value.includes('profileId-') || value.includes('inviteId-');
    if (!isValueValid) return;

    const body = {
      profileId: '',
      inviteId: '',
    };

    if (value.includes('profileId-')) {
      body.profileId = value.split('profileId-')[1];
    } else if (value.includes('inviteId-')) {
      body.inviteId = value.split('inviteId-')[1];
    }

    try {
      await removeUserFromLocation.mutate({
        locationId: location.id,
        params: body,
      });
      state.add({
        title: 'User access removed',
        description: 'User removed successfully',
      });
      setSelectedUser(null);
    } catch (error) {
      state.add({
        title: 'User access could not be removed',
        description: 'Please try again',
        variant: 'caution',
      });
    }
  };

  const handleRemoveButtonClick = (user: SharableListItem) => {
    setSelectedUser(user);
  };

  if (isLoading) return null;
  return (
    <Modal isOpen={isOpen} className="text-black overflow-auto">
      <Dialog showCloseButton onClose={onClose}>
        <DialogHeader>
          <DialogHeading>
            <span>{location.name} Users</span>
          </DialogHeading>
          <DialogSubheading>
            <Text>{location.acl.length} Users with location access</Text>
          </DialogSubheading>
        </DialogHeader>
        <div className="px-3">
          <div className="my-4">
            <Text>
              Invite restricted or standard users below to give them access to
              manage all screens for this location.
            </Text>
          </div>
          <div className="mb-8">
            <Text>
              Note: Admins and above already have access to screens at all
              locations by default.
            </Text>
          </div>
          <div>
            <div className=" text-xl font-semibold">Add a new user</div>
          </div>
        </div>
        <div className="flex justify-between items-center p-4 mt-4 border border-solid rounded-3xl mb-4">
          <div className="text-base">
            Invite and add a new user and give them access to locations
          </div>
          <Button
            className="text-lg min-w-[132px] px-0"
            onClick={() =>
              window.open(`${config.accountManagementUrl}/users/invite/create`)
            }
          >
            Invite Users
          </Button>
        </div>

        <div className="px-3 flex items-center gap-6 my-6">
          <div className="border-b-2 border-gray-200 flex flex-1 h-[50%]" />
          <div className="text-gray-200 text-center text-base font-bold">
            OR
          </div>
          <div className="border-b-2 border-gray-200 flex flex-1 h-[50%]" />
        </div>

        <div className="px-3 text-xl font-semibold mb-2">
          Add an existing user
        </div>

        <Select
          label=""
          selectedKey=""
          onSelectionChange={(key) => handleInviteUserToLocation(key as string)}
        >
          <SelectItem id="" key="">
            Select Users
          </SelectItem>
          {sharableDomainUsersAndInviteList.map((item) => (
            <SelectItem key={item.id} id={item.id}>
              {item.name}
            </SelectItem>
          ))}
        </Select>

        <div className="pt-4 max-h-[194px] overflow-y-auto">
          {sharedDomainUsersAndInviteList.map((item, i) => (
            <SharedListItem
              user={item}
              avatarStyle={i}
              onClick={handleRemoveButtonClick}
            />
          ))}
        </div>

        {selectedUser && (
          <RemoveAccessConfirmationDialog
            isOpen={!!selectedUser}
            isLoading={false}
            location={location}
            user={selectedUser}
            onClose={() => setSelectedUser(null)}
            onClick={handleRemoveUserFromLocation}
          />
        )}
        <div className="mt-8 md:px-4">
          <Button onClick={() => onClose()} variant="primary" fullWidth>
            Done
          </Button>
        </div>
      </Dialog>
    </Modal>
  );
};

export default ManageAccessDialog;
