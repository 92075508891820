import { ApiClientError } from '@raydiant/api-client-js';
import { Font } from '@raydiant/api-client-js/build/types/Font';
import { keys } from '../queryClient';
import useProtectedQuery from './useProtectedQuery';
import raydiant from '../clients/miraClient';
import { useMemo } from 'react';
import {
  fontFamilies,
  FontFamily,
  injectFontFaces,
} from '../components/TextStyleField/fontFamilies';
import { parseFonts } from '../utilities/fontUtils';

export default function useFonts() {
  const query = useProtectedQuery<Font[], ApiClientError>(keys.fonts(), () =>
    raydiant.getFonts(),
  );

  const groupedFonts = useMemo<FontFamily[]>(() => {
    const fontWeights = parseFonts(query.data ?? []);

    const fonts = [...fontFamilies, ...fontWeights];

    injectFontFaces(fonts);

    return fonts;
  }, [query.data]);

  return {
    ...query,
    data: groupedFonts,
  };
}
