import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Heading from 'raydiant-elements/core/Heading';
import Text from 'raydiant-elements/core/Text';
import Row from 'raydiant-elements/layout/Row';
import Column from 'raydiant-elements/layout/Column';
import * as D from '../../../clients/mira/types/Device';
import SelectIntegrationScreen from './SelectIntegrationScreen';
import { Location } from '../integrationTypes';
import useStyles from './LocationCard.styles';

export interface LocationCardProps {
  devices: D.Device[];
  location: Location;
  onIconClick: (locationId: string) => void;
}

const LocationCard = ({
  devices,
  location,
  onIconClick,
}: LocationCardProps) => {
  const classes = useStyles();
  return (
    <Grid className={classes.root}>
      <Column>
        <Row key={location.restaurant_guid} halfMargin>
          <Grid item className={classes.contentCard}>
            <Heading size={5}>{location.restaurant_guid}</Heading>
            <br />
            <Text>
              {location.restaurant_name} - {location.location_name}
            </Text>
            <br />
            <SelectIntegrationScreen devices={devices} />
          </Grid>
          <div className={classes.buttonContainer}>
            <IconButton
              size="small"
              edge="end"
              onClick={() => onIconClick(location.restaurant_guid)}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </div>
        </Row>
      </Column>
    </Grid>
  );
};

export default LocationCard;
