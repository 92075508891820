import { useState } from 'react';
import * as paths from '../../../../routes/paths';
import { useHistory } from 'react-router-dom';
import Buildings from '../../Icons/Buildings';
import XMark from '../../Icons/XMark';
import { useGetPendingLocations } from '../../../../hooks';

const PendingLocationsBanner = () => {
  const [isOpen, setIsOpen] = useState(true);

  const { data, isLoading } = useGetPendingLocations();
  const history = useHistory();

  const showPendingLocationsBanner = !isLoading && (data || []).length > 0;

  const handleViewButtonClick = () => {
    history.push({
      pathname: paths.screens(),
      search: `location_ids=${data!.map((location) => location.id).join(',')}`,
    });
  };

  if (!showPendingLocationsBanner) return null;

  return (
    <>
      {isOpen && (
        <div className="bg-teal-200 p-2 flex items-center justify-center gap-1 relative">
          <Buildings />
          <span className="text-lg font-semibold text-teal-800 px-2.5">
            {data!.length > 1
              ? `${data!.length} locations`
              : `${data!.length} location`}{' '}
            require screens
          </span>
          <button
            className="bg-white rounded-3xl flex justify-center items-center h-6 w-11"
            onClick={() => handleViewButtonClick()}
          >
            <span className="text-sm font-bold text-teal-800 px-2 py-1">
              View
            </span>
          </button>
          <button
            className="absolute right-2.5"
            onClick={() => setIsOpen(false)}
          >
            <XMark />
          </button>
        </div>
      )}
    </>
  );
};

export default PendingLocationsBanner;
