import { useTranslation } from 'react-i18next';
import { ContentEngagementByPresentation } from '@raydiant/api-client-js';
import moment from 'moment';
import { Popover } from '@headlessui/react';
import { useHistory, useLocation } from 'react-router-dom';

import * as paths from '../../../routes/paths';
import DataTable from '../../../components/DataTable/DataTable';
import ChartCard from '../ChartCard/ChartCard';
import { getDisplayValue } from '../ShopperEngagementInsights/utils';
import DataTableHeader, {
  TableSort,
} from '../../../components/DataTable/DataTableHeader';
import DataTableCell from '../../../components/DataTable/DataTableCell';
import { PaginationProps } from '../../../components/Pagination/Pagination';
import QuestionMarkIcon from '../../../components/QuestionMarkIcon';
import {
  DEFAULT_DATE_RANGE,
  DEFAULT_DATE_RANGE_KEY,
} from '../AnalyticsFilter/DateRangeSelect';

type ContentEffectivenessTableProps = PaginationProps & {
  data: ContentEngagementByPresentation[];
  onSort: (sort: TableSort<ContentEngagementByPresentation>) => void;
  sort: TableSort<ContentEngagementByPresentation>;
  showViewingMetrics?: boolean;
  showPagination?: boolean;
  showContentDetailPage?: boolean;
};

const ContentEffectivenessTable = ({
  currentPage,
  data,
  onPageChange,
  onSort,
  pageCount,
  sort,
  showViewingMetrics = false,
  showPagination = true,
  showContentDetailPage = false,
}: ContentEffectivenessTableProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();

  const renderDateTimeString = (dateTime: string) => {
    const currentDate = new Date();
    const givenDate = new Date(dateTime);

    const givenTimeString = moment(dateTime).format('HH:mm');
    const givenDateString = moment(givenDate).format('L');

    const isDateEqual = moment(currentDate).format('L') === givenDateString;

    if (!isDateEqual) {
      return (
        <>
          <div>{givenTimeString}</div>
          <div>{`(${givenDateString})`}</div>
        </>
      );
    }

    return <>{givenTimeString}</>;
  };

  const selectContent = (presentation_id: string) => {
    history.push({
      pathname: paths.contentDetail(),
      search: search
        ? `${search.replace(
            'presentation_id=',
            `presentation_id=${presentation_id}`,
          )}`
        : `?presentation_id=${presentation_id}&from=${DEFAULT_DATE_RANGE.from}&to=${DEFAULT_DATE_RANGE.to}&dateRangeKey=${DEFAULT_DATE_RANGE_KEY}`,
    });
  };

  return (
    <ChartCard
      subTitle={''}
      title={
        <div className="flex gap-3 items-center mb-3">
          <h2 className="text-black text-xl font-bold">
            {t(
              'analytics.contentEngagementPage.contentEffectivenessTable.title',
            )}
          </h2>
          <Popover className="relative">
            <Popover.Button>
              <QuestionMarkIcon />
            </Popover.Button>
            <Popover.Panel className="absolute z-10 card popover">
              <div className="overflow-hidden">
                <p className="mb-3">
                  {t(
                    'analytics.contentEngagementPage.contentEffectivenessTable.tooltip.default',
                  )}
                </p>
                <a
                  href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('analytics.learnmore')}
                </a>
              </div>
            </Popover.Panel>
          </Popover>
        </div>
      }
      showTimeInterval={false}
    >
      <DataTable<ContentEngagementByPresentation>
        currentPage={currentPage}
        data={data}
        showPagination={showPagination}
        headers={
          <tr>
            <DataTableHeader<ContentEngagementByPresentation>
              className="w-96 !text-left sticky left-0 bg-white"
              onSort={onSort}
              showSort={sort.key === 'presentationName'}
              sortDirection={
                sort.key === 'presentationName' ? sort.direction : undefined
              }
              sortKey="presentationName"
              wrapperClassName="!justify-start"
            >
              {t(
                'analytics.contentEngagementPage.tableHeaders.presentationName',
              )}
            </DataTableHeader>
            <DataTableHeader<ContentEngagementByPresentation>
              showSort={sort.key === 'playbackDuration'}
              sortKey="playbackDuration"
              sortDirection={
                sort.key === 'playbackDuration' ? sort.direction : undefined
              }
              onSort={onSort}
            >
              {t(
                'analytics.contentEngagementPage.tableHeaders.playbackDuration',
              )}{' '}
              (min)
            </DataTableHeader>
            <DataTableHeader<ContentEngagementByPresentation>
              showSort={sort.key === 'nOfPlays'}
              sortKey="nOfPlays"
              sortDirection={
                sort.key === 'nOfPlays' ? sort.direction : undefined
              }
              onSort={onSort}
            >
              {t('analytics.contentEngagementPage.tableHeaders.nOfPlays')}
            </DataTableHeader>
            <DataTableHeader<ContentEngagementByPresentation>
              showSort={sort.key === 'playbackDistribution'}
              sortKey="playbackDistribution"
              sortDirection={
                sort.key === 'playbackDistribution' ? sort.direction : undefined
              }
              onSort={onSort}
            >
              {t(
                'analytics.contentEngagementPage.tableHeaders.playbackDistribution',
              )}{' '}
              (%)
            </DataTableHeader>
            {showViewingMetrics && (
              <>
                <DataTableHeader<ContentEngagementByPresentation>
                  showSort={sort.key === 'ots'}
                  sortKey="ots"
                  sortDirection={
                    sort.key === 'ots' ? sort.direction : undefined
                  }
                  onSort={onSort}
                >
                  {t('analytics.contentEngagementPage.tableHeaders.ots')}
                </DataTableHeader>
                <DataTableHeader<ContentEngagementByPresentation>
                  showSort={sort.key === 'verifiedViews'}
                  sortKey="verifiedViews"
                  sortDirection={
                    sort.key === 'verifiedViews' ? sort.direction : undefined
                  }
                  onSort={onSort}
                >
                  {t(
                    'analytics.contentEngagementPage.tableHeaders.contentViews',
                  )}
                </DataTableHeader>
                <DataTableHeader<ContentEngagementByPresentation>
                  showSort={sort.key === 'verifiedViewsRate'}
                  sortKey="verifiedViewsRate"
                  sortDirection={
                    sort.key === 'verifiedViewsRate'
                      ? sort.direction
                      : undefined
                  }
                  onSort={onSort}
                >
                  {t(
                    'analytics.contentEngagementPage.tableHeaders.contentViewRate',
                  )}{' '}
                  (%)
                </DataTableHeader>
                <DataTableHeader<ContentEngagementByPresentation>
                  showSort={sort.key === 'avgViewDuration'}
                  sortKey="avgViewDuration"
                  sortDirection={
                    sort.key === 'avgViewDuration' ? sort.direction : undefined
                  }
                  onSort={onSort}
                >
                  {t(
                    'analytics.contentEngagementPage.tableHeaders.avgViewingTime',
                  )}{' '}
                  (sec)
                </DataTableHeader>
              </>
            )}
            <DataTableHeader<ContentEngagementByPresentation>
              showSort={sort.key === 'lastPlayedTime'}
              sortKey="lastPlayedTime"
              sortDirection={
                sort.key === 'lastPlayedTime' ? sort.direction : undefined
              }
              onSort={onSort}
            >
              {t('analytics.contentEngagementPage.tableHeaders.lastPlayedTime')}
            </DataTableHeader>
            <DataTableHeader<ContentEngagementByPresentation>
              showSort={sort.key === 'nOfLocations'}
              sortKey="nOfLocations"
              sortDirection={
                sort.key === 'nOfLocations' ? sort.direction : undefined
              }
              onSort={onSort}
            >
              {t('analytics.contentEngagementPage.tableHeaders.nOfLocations')}
            </DataTableHeader>
          </tr>
        }
        onPageChange={onPageChange}
        pageCount={pageCount}
        renderRow={(item, index) => (
          <tr
            key={index}
            onClick={
              showContentDetailPage
                ? () => selectContent(item.presentationId)
                : undefined
            }
            className={`${showContentDetailPage ? 'cursor-pointer' : ''}`}
          >
            <DataTableCell
              className="sticky left-0 bg-white"
              wrapperClassName="!text-left"
              hasSort
            >
              {item.presentationName}
            </DataTableCell>
            <DataTableCell hasSort>
              {getDisplayValue(Math.round(item.playbackDuration / 60), ' min')}
            </DataTableCell>
            <DataTableCell hasSort>
              {getDisplayValue(item.nOfPlays)}
            </DataTableCell>
            <DataTableCell hasSort>
              {getDisplayValue(
                Math.round(item.playbackDistribution * 10) / 10,
                '%',
              )}
            </DataTableCell>
            {showViewingMetrics && (
              <>
                <DataTableCell hasSort>
                  {getDisplayValue(item.ots)}
                </DataTableCell>
                <DataTableCell hasSort>
                  {getDisplayValue(item.verifiedViews)}
                </DataTableCell>
                <DataTableCell hasSort>
                  {item.verifiedViews
                    ? getDisplayValue(
                        Math.round(item.verifiedViewsRate * 10) / 10,
                        '%',
                      )
                    : '-'}
                </DataTableCell>
                <DataTableCell hasSort>
                  {getDisplayValue(item.avgViewDuration, ' sec')}
                </DataTableCell>
              </>
            )}
            <DataTableCell hasSort>
              {renderDateTimeString(item.lastPlayedTime)}
            </DataTableCell>
            <DataTableCell hasSort>{item.nOfLocations}</DataTableCell>
          </tr>
        )}
      />
    </ChartCard>
  );
};

export default ContentEffectivenessTable;
