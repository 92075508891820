import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

export default makeStyles((theme: Theme) => {
  return createStyles({
    button: {
      minWidth: '8rem',
    },

    section: {
      marginBottom: theme.spacing(5),

      '&:last-child': {
        marginBottom: 0,
      },
    },

    checkbox: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(1),
    },

    buttonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: theme.spacing(2),
      right: theme.spacing(2),
      flexWrap: 'wrap',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    closeButton: {
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: 1.26,
      letterSpacing: '2px',
      color: 'rgba(0, 0, 0, 0.32)',
    },
    closeButtonIcon: {
      fontSize: 24,
      color: 'rgba(0, 0, 0, 0.32)',
    },
    multiple: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
    },
  });
});
