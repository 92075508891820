import { createSelector } from 'reselect';
import { RootState } from '../reducers';

export const selectUserProfile = (state: RootState) => state.user.profile;

export const selectUserIsFetching = (state: RootState) => state.user.isFetching;

export const selectUserRoles = (state: RootState) => state.user.roles;

export const selectUserPreferences = (state: RootState) =>
  state.user.preferences;

export const selectProofOfPublishEnabled = createSelector(
  [selectUserRoles],
  // TODO: Remove selectProofOfPublishEnabled from codebase after successfully deploying to production.
  // I left this commented as an easy way to role back if we need to.
  // roles => roles.includes('proof_of_publish'),
  () => true,
);

export const selectIsRaydiantSuperAdmin = createSelector(
  [selectUserRoles],
  (roles) =>
    roles.includes('admin_dashboard_user') ||
    roles.includes('raydiant_superadmin'),
);

export const selectIsCustomerSupport = createSelector(
  [selectUserRoles],
  (roles) => roles.includes('customer_support'),
);

export const selectIs4kEnabled = createSelector([selectUserRoles], (roles) =>
  roles.includes('4k_beta'),
);

export const selectIsEnterpriseUser = createSelector(
  [selectUserProfile],
  (profile) => !!profile?.domainId,
);

export const selectIsRestrictedUser = createSelector(
  [selectUserProfile],
  (profile) => profile?.domainRole === 'restricted',
);

export const selectHasAccessToInvoices = createSelector(
  [selectUserProfile],
  (profile) =>
    (profile?.domainId && profile?.domainRole === 'superadmin') ||
    !profile?.domainId,
);

export const selectHomeTabPreference = createSelector(
  [selectUserPreferences],
  (preferences) => {
    return preferences ? preferences.homeTab || 'screens' : null;
  },
);

export const selectIsNestedPlaylistsEnabled = createSelector(
  [selectUserRoles],
  () => true,
  // TODO: Add Auth0 role.
  // roles => roles.includes('nested_playlists'),
);

export const selectIsDeveloper = createSelector([selectUserRoles], (roles) =>
  roles.includes('developer'),
);

export const selectIsAppReviewer = createSelector([selectUserRoles], (roles) =>
  roles.includes('app_reviewer'),
);

export const selectIsUserAIEnabled = createSelector(
  [selectUserRoles],
  (roles) => roles.includes('ai_device_options'),
);

export const selectUserHasRole = createSelector(
  [selectUserRoles],
  (roles) => (role: string) => roles.includes(role),
);

export const selectIsAIPOSEnabled = createSelector([selectUserRoles], (roles) =>
  roles.includes('ai_pos'),
);

export const selectIsPerchSettingsEnabled = createSelector(
  [selectUserRoles],
  (roles) => roles.includes('perch_settings'),
);

export const selectIsExAdministrator = createSelector(
  [selectUserRoles],
  (roles) => roles.includes('ex:administrator'),
);

export const selectIsVIOnly = createSelector([selectUserRoles], (roles) =>
  roles.includes('visitor_insight_only'),
);

export const selectIsVIEnabled = createSelector([selectUserRoles], (roles) =>
  roles.includes('visitor_insight'),
);

export const selectIsRXEnabled = createSelector([selectUserRoles], (roles) =>
  roles.includes('rx'),
);

export const selectIsKioskEnabled = createSelector([selectUserRoles], (roles) =>
  roles.includes('kiosk'),
);

export const selectIsProductHubEnabled = createSelector(
  [selectUserRoles],
  (roles) => roles.includes('product_hub'),
);

export const selectIsContentDetailEnabled = createSelector(
  [selectUserRoles],
  (roles) => roles.includes('content_detail'),
);
