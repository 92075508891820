import {
  AnalyticsQueryParams,
  ApiClientError,
  GetTrafficAwareness,
} from '@raydiant/api-client-js';
import { keys } from '../queryClient';
import raydiant from '../clients/miraClient';
import useProtectedQuery from './useProtectedQuery';

export default function useTrafficAwareness(params: AnalyticsQueryParams) {
  return useProtectedQuery<GetTrafficAwareness, ApiClientError>(
    keys.trafficAwareness(params),
    () => raydiant.getTrafficAwareness(params),
  );
}
