import AddIntegrationColumn from './AddIntegrationColumn';
import SelectField from 'raydiant-elements/core/SelectField';
import Link from 'raydiant-elements/core/Link';
import { Grid } from '@material-ui/core';
import { capitalize } from '../../../utilities/stringUtils';

export interface ConnectionSetttingsProps {
  integrations: any[];
  selectedIntegration: string;
  onIntegrationSelect: (integration: string) => void;
}

const integrationOptions = [
  {
    id: '800fa298-6c22-46f3-b12c-c36ce3c511b1',
    name: 'toast',
  },
];

const ConnectionSettings = ({
  integrations,
  selectedIntegration,
  onIntegrationSelect,
}: ConnectionSetttingsProps) => {
  const exsitingIntegrationIds = integrations.map(
    (integration) => integration.id,
  );
  const validIntegrationOptions = integrationOptions.filter(
    (option) => !exsitingIntegrationIds.includes(option.id),
  );
  const hasValidIntegrationOptions = validIntegrationOptions.length > 0;
  const defaultText = hasValidIntegrationOptions
    ? 'Select an integration to connect'
    : 'No integration to select';

  return (
    <AddIntegrationColumn heading="connection settings">
      <Grid md={11}>
        <SelectField
          label="add an integration"
          value={selectedIntegration}
          onChange={onIntegrationSelect}
          disabled={!hasValidIntegrationOptions}
          helperText={
            <Link href="https://support.raydiant.com/" target="_blank">
              Tell me more
            </Link>
          }
        >
          <option value="">{defaultText}</option>
          {validIntegrationOptions.map((option) => (
            <option value={option.id}>{capitalize(option.name)}</option>
          ))}
        </SelectField>
      </Grid>
    </AddIntegrationColumn>
  );
};

export default ConnectionSettings;
