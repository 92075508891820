import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Popover } from '@headlessui/react';
import { useLocation } from 'react-router-dom';
import { useToastState } from '@raydiant/raydial/components/Toast/ToastProvider';
import Spinner from '@raydiant/raydial/components/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Export } from '@raydiant/raydial/components/Icons';
import cn from 'classnames';

import * as analyticsActions from '../../actions/analytics';
import QuestionMarkIcon from '../QuestionMarkIcon';
import { ExportAnalyticsEnum } from '../../types/analytics';
import { useAnalyticsExport } from '../../hooks';
import { getApiQueryParams } from '../../pages/AnalyticsPage/AnalyticsFilter/utils';
import { selectExportType } from '../../selectors/analytics';

type PageLayoutProps = {
  sectionClassName?: string;
  className?: string;
  children: React.ReactNode;
  sidebar?: React.ReactNode;
  title: React.ReactNode;
  isExportNeeded?: boolean;
};

const TRAFFIC_PATHNAME = 'traffic';

const PageLayout = ({
  children,
  sectionClassName,
  className,
  sidebar,
  title,
  isExportNeeded = false,
}: PageLayoutProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const state = useToastState();
  const { search } = useLocation();
  const query = getApiQueryParams(search);
  const dispatch = useDispatch();

  const exportType = useSelector(selectExportType);

  const { isLoading: isExportLoading } = useAnalyticsExport(query, exportType);

  const handleExport = () => {
    if (pathname.includes(TRAFFIC_PATHNAME)) {
      dispatch(
        analyticsActions.setExportType(ExportAnalyticsEnum.TrafficAwareness),
      );
    } else {
      dispatch(
        analyticsActions.setExportType(ExportAnalyticsEnum.ProductEngagement),
      );
    }
    state.add({
      title: t('export.exportingToasts.loadingAllDataTitle'),
      description: t('export.exportingToasts.loadingDescription'),
      variant: 'info',
    });
  };

  return (
    <>
      <div className="p-6 md:px-12 md:sticky top-0 left-0 bg-gray-100 z-[9] max-h-min flex items-center justify-between flex-nowrap">
        <div className="w-full">{title}</div>
        {isExportNeeded && (
          <div className="flex items-center">
            <button
              className={cn(
                'min-w-[156px] flex items-center py-3 px-6 rounded-2xl ml-5 hover:bg-blue-50 mr-3 disabled:opacity-100',
                isExportLoading ? 'bg-blue-50' : 'bg-white',
              )}
              onClick={handleExport}
              disabled={isExportLoading}
            >
              {isExportLoading ? <Spinner /> : <Export />}
              <div className="text-nowrap text-lg text-blue-600 font-semibold pl-2 leading-4">
                {isExportLoading ? t('export.exporting') : t('export.title')}
              </div>
            </button>
            <Popover className="relative">
              <Popover.Button>
                <QuestionMarkIcon />
              </Popover.Button>
              <Popover.Panel className="absolute z-10 right-0 top-9 card popover drop-shadow-xl">
                <div className="overflow-hidden">
                  <p className="mb-3">{t('export.exportAllTooltip')}</p>
                  <a
                    href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics#FAQs"
                    rel="noreferrer"
                    target="_blank"
                  >
                    {t('analytics.learnmore')}
                  </a>
                </div>
              </Popover.Panel>
            </Popover>
          </div>
        )}
      </div>
      <div className={classNames(className, 'px-4 md:px-12 grow mb-6')}>
        <section
          className={classNames(
            sectionClassName,
            'flex flex-col md:flex-row gap-6 h-full',
          )}
        >
          <div className="w-full min-h-full">{children}</div>
          {sidebar && <div className="w-full md:w-[308px]">{sidebar}</div>}
        </section>
      </div>
    </>
  );
};

export default PageLayout;
