import { GetTrafficAwareness, LocationTraffic } from '@raydiant/api-client-js';
import Hidden from 'raydiant-elements/layout/Hidden';
import ShopperEngagementChart from './ShopperEngagementChart';
import TopLocationsChart from './TopLocationsChart';
import TopLocationsTable from './TopLocationsTable';
import TrafficByHourChart from './TrafficByHourChart';
import DwellTimesByHourChart from './DwellTimesByHourChart';
import DemographicBreakdownChart from './DemographicBreakdownChart';
import AgeDistributionChart from './AgeDistributionChart';
import AverageGenderDistributionChart from './AverageGenderDistributionChart';
import { TableSort } from '../../../components/DataTable/DataTableHeader';
import { TopLocationsData } from './TrafficAwarenessPage';
import VisitorInsightCarousel from './VisitorInsightCarousel';

interface VIOnlyLayoutProps {
  data: GetTrafficAwareness | undefined;
  isError: boolean;
  topTenLocationsData: LocationTraffic[];
  topLocationsData: TopLocationsData;
  topLocationsCurrentPage: number;
  topLocationsTableSort: TableSort<LocationTraffic>;
  onTopLocationsCurrentPage: (page: number) => void;
  onTopLocationsTableSort: (sort: TableSort<LocationTraffic>) => void;
}

const VIOnlyLayout = ({
  data,
  isError,
  topTenLocationsData,
  topLocationsData,
  topLocationsCurrentPage,
  topLocationsTableSort,
  onTopLocationsCurrentPage,
  onTopLocationsTableSort,
}: VIOnlyLayoutProps) => {
  const renderContent = () => {
    return (
      <>
        <VisitorInsightCarousel />

        <div className="pt-9">
          {data?.trafficByDay && !isError && (
            <ShopperEngagementChart data={data?.trafficByDay} />
          )}
          {data?.trafficByLocation && !isError && (
            <>
              <TopLocationsChart data={topTenLocationsData} />
              <TopLocationsTable
                currentPage={topLocationsCurrentPage}
                data={topLocationsData?.data}
                onPageChange={(page) => {
                  onTopLocationsCurrentPage(page);
                }}
                onSort={(sort) => {
                  onTopLocationsTableSort({
                    ...topLocationsTableSort,
                    ...sort,
                  });
                  onTopLocationsCurrentPage(0);
                }}
                pageCount={topLocationsData?.pageCount}
                sort={topLocationsTableSort}
                showPagination={data?.trafficByLocation?.length > 10}
              />
            </>
          )}

          {data?.trafficByWeekdayHour && (
            <div className="flex flex-col w-full md:flex-row">
              <div style={{ width: '100%' }}>
                <TrafficByHourChart data={data.trafficByWeekdayHour} />
              </div>
              <div style={{ width: '100%' }}>
                <DwellTimesByHourChart data={data.trafficByWeekdayHour} />
              </div>
            </div>
          )}

          {data?.trafficSummary && data.trafficSummary.length > 0 && (
            <DemographicBreakdownChart data={data.trafficSummary[0]} />
          )}

          {data?.trafficByWeekdayHour && (
            <>
              <AgeDistributionChart data={data.trafficByWeekdayHour} />
              <AverageGenderDistributionChart
                data={data.trafficByWeekdayHour}
              />
            </>
          )}
        </div>
      </>
    );
  };

  const renderDesktopLayout = () => {
    return <div className="p-6">{renderContent()}</div>;
  };

  const renderMobileLayout = () => {
    return <div className="p-1">{renderContent()}</div>;
  };

  return (
    <div className="card">
      <Hidden xsDown>{renderDesktopLayout()}</Hidden>
      <Hidden smUp>{renderMobileLayout()}</Hidden>
    </div>
  );
};

export default VIOnlyLayout;
