import { TrafficSummary, WeekdayHourTraffic } from '@raydiant/api-client-js';
import { toPercent } from '../ShopperEngagementInsights/utils';

export const ageGroups = [
  '0-12',
  '13-17',
  '18-24',
  '25-34',
  '35-44',
  '45-54',
  '55-64',
  '65+',
];

export const getDemographicBreakdownData = (data: TrafficSummary) => {
  const maleDistribution: Partial<TrafficSummary> = {
    m0_12: data.m0_12,
    m13_17: data.m13_17,
    m18_24: data.m18_24,
    m25_34: data.m25_34,
    m35_44: data.m35_44,
    m45_54: data.m45_54,
    m55_64: data.m55_64,
    m65_plus: data.m65_plus,
  };

  const femaleDistribution = {
    f0_12: data.f0_12,
    f13_17: data.f13_17,
    f18_24: data.f18_24,
    f25_34: data.f25_34,
    f35_44: data.f35_44,
    f45_54: data.f45_54,
    f55_64: data.f55_64,
    f65_plus: data.f65_plus,
  };

  return {
    male: Object.values(maleDistribution),
    female: Object.values(femaleDistribution),
  };
};

export const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

type DataByDays = {
  [key: string]: WeekdayHourTraffic;
};

const addWeekdayHourDataToDays = (data: WeekdayHourTraffic[]) => {
  const dataByDays: DataByDays = {};

  data.forEach((item) => {
    if (dataByDays.hasOwnProperty(item.weekday)) {
      dataByDays[item.weekday] = {
        ...dataByDays[item.weekday],
        otsFace: (dataByDays[item.weekday].otsFace += item.otsFace || 0),
        male: (dataByDays[item.weekday].male += item.male || 0),
        female: (dataByDays[item.weekday].female += item.female || 0),
        ots: (dataByDays[item.weekday].ots += item.ots || 0),
        a0_12: (dataByDays[item.weekday].a0_12 += item.a0_12 || 0),
        a13_17: (dataByDays[item.weekday].a13_17 += item.a13_17 || 0),
        a18_24: (dataByDays[item.weekday].a18_24 += item.a18_24 || 0),
        a25_34: (dataByDays[item.weekday].a25_34 += item.a25_34 || 0),
        a35_44: (dataByDays[item.weekday].a35_44 += item.a35_44 || 0),
        a45_54: (dataByDays[item.weekday].a45_54 += item.a45_54 || 0),
        a55_64: (dataByDays[item.weekday].a55_64 += item.a55_64 || 0),
        a65_plus: (dataByDays[item.weekday].a65_plus += item.a65_plus || 0),
      };
    } else {
      dataByDays[item.weekday] = { ...item };
    }
  });

  return dataByDays;
};

const orderWeekdayHourDataByDays = (data: DataByDays) => {
  return days.map((day) => data[day]);
};

const weekdayHourToAgeGroup = (data: WeekdayHourTraffic[]) => {
  return {
    a0_12: data.map((item) => item?.a0_12 || 0),
    a13_17: data.map((item) => item?.a13_17 || 0),
    a18_24: data.map((item) => item?.a18_24 || 0),
    a25_34: data.map((item) => item?.a25_34 || 0),
    a35_44: data.map((item) => item?.a35_44 || 0),
    a45_54: data.map((item) => item?.a45_54 || 0),
    a55_64: data.map((item) => item?.a55_64 || 0),
    a65_plus: data.map((item) => item?.a65_plus || 0),
  };
};

export const getAgeDistributionChartData = (data: WeekdayHourTraffic[]) => {
  const totalWeekdayHourData = addWeekdayHourDataToDays(data);
  const orderedWeekdayHourData =
    orderWeekdayHourDataByDays(totalWeekdayHourData);
  const weekdayHourToAgeGroupData = weekdayHourToAgeGroup(
    orderedWeekdayHourData,
  );

  return weekdayHourToAgeGroupData;
};

export const getAverageGenderDistributionChartData = (
  data: WeekdayHourTraffic[],
) => {
  const totalWeekdayHourData = addWeekdayHourDataToDays(data);
  const orderedWeekdayHourData =
    orderWeekdayHourDataByDays(totalWeekdayHourData);

  const femaleData = orderedWeekdayHourData.map(
    (item) => toPercent((item?.female || 0) / (item?.otsFace || 0)) || 0,
  );
  const maleData = femaleData.map(
    (item, i) =>
      ((orderedWeekdayHourData[i]?.otsFace || 0) > 0 && 100 - Number(item)) ||
      0,
  );

  return {
    female: femaleData,
    male: maleData,
    ots: orderedWeekdayHourData.map((item) => item?.ots || 0),
    otsFace: orderedWeekdayHourData.map((item) => item?.otsFace || 0),
  };
};

type WeekdayToHourByDays = {
  [key: string]: {
    [key: string]: {
      ots: number;
      avgDwellTime: number;
    };
  };
};

const weekdayToHour = (data: WeekdayHourTraffic[]) => {
  const dataByDays: WeekdayToHourByDays = {};

  data.forEach((item) => {
    if (dataByDays.hasOwnProperty(item.weekday)) {
      if (dataByDays[item.weekday].hasOwnProperty(item.hour)) {
        dataByDays[item.weekday][item.hour] = {
          ots: (dataByDays[item.weekday][item.hour].ots += item.ots),
          avgDwellTime: (dataByDays[item.weekday][item.hour].avgDwellTime +=
            item.avgDwellTime),
        };
      } else {
        dataByDays[item.weekday][item.hour] = {
          ots: item.ots,
          avgDwellTime: item.avgDwellTime,
        };
      }
    } else {
      dataByDays[item.weekday] = {
        [item.hour]: {
          ots: item.ots,
          avgDwellTime: item.avgDwellTime,
        },
      };
    }
  });

  return dataByDays;
};

type DayToAxis = {
  [key: string]: number;
};

const dayToYAxis: DayToAxis = {
  Sunday: 6,
  Monday: 5,
  Tuesday: 4,
  Wednesday: 3,
  Thursday: 2,
  Friday: 1,
  Saturday: 0,
};

const hoursToXAxis: DayToAxis = {
  '00': 0,
  '01': 1,
  '02': 2,
  '03': 3,
  '04': 4,
  '05': 5,
  '06': 6,
  '07': 7,
  '08': 8,
  '09': 9,
  '10': 10,
  '11': 11,
  '12': 12,
  '13': 13,
  '14': 14,
  '15': 15,
  '16': 16,
  '17': 17,
  '18': 18,
  '19': 19,
  '20': 20,
  '21': 21,
  '22': 22,
  '23': 23,
};

export const getTrafficByHourChartData = (data: WeekdayHourTraffic[]) => {
  const formattedData = weekdayToHour(data);
  const result = [] as number[][];

  Object.keys(formattedData).forEach((day) =>
    Object.keys(formattedData[day]).forEach((hour) =>
      result.push([
        hoursToXAxis[hour],
        dayToYAxis[day],
        formattedData[day][hour].ots,
      ]),
    ),
  );

  return result;
};

export const getDwellTimesByHourChartData = (data: WeekdayHourTraffic[]) => {
  const formattedData = weekdayToHour(data);
  const result = [] as any[];

  Object.keys(formattedData).forEach((day) =>
    Object.keys(formattedData[day]).forEach((hour) =>
      result.push([
        hoursToXAxis[hour],
        dayToYAxis[day],
        formattedData[day][hour].avgDwellTime,
      ]),
    ),
  );

  return result;
};

// prettier-ignore
export const heatmapHours = [
  '00:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00',
  '7:00', '8:00', '9:00', '10:00', '11:00',
  '12:00', '13:00', '14:00', '15:00', '16:00', '17:00',
  '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'
];

export const displayHeatmapHours = [...heatmapHours, '24:00'];

// prettier-ignore
export const heatmapDays = [
  'Sat', 'Fri', 'Thu',
  'Wed', 'Tue', 'Mon', 'Sun'
];

export const displayHeatmapDays = [
  'Saturday',
  'Friday',
  'Thursday',
  'Wednesday',
  'Tuesday',
  'Monday',
  'Sunday',
];

export const dwellTime = [
  '0-10 sec',
  '10-30 sec',
  '30-60 sec',
  '1-2 min',
  '2-5 min',
  '5-10 min',
  '10+ min',
];

export const getDwellTimePercentageArrData = (data: TrafficSummary) => {
  const dwellTimeDistributionPercentage = {
    dwell_0_10s_percentage: data.dwell_0_10s_percentage,
    dwell_10_30s_percentage: data.dwell_10_30s_percentage,
    dwell_30_60s_percentage: data.dwell_30_60s_percentage,
    dwell_60_120s_percentage: data.dwell_60_120s_percentage,
    dwell_120_300s_percentage: data.dwell_120_300s_percentage,
    dwell_300_600s_percentage: data.dwell_300_600s_percentage,
    dwell_600splus_percentage: data.dwell_600splus_percentage,
  };

  return {
    dwellTimePercentageArr: Object.values(dwellTimeDistributionPercentage),
  };
};

export const dwellTimeData = (data: TrafficSummary) => {
  const dwellTimeDistribution = {
    [dwellTime[0]]: data.dwell_0_10s,
    [dwellTime[1]]: data.dwell_10_30s,
    [dwellTime[2]]: data.dwell_30_60s,
    [dwellTime[3]]: data.dwell_60_120s,
    [dwellTime[4]]: data.dwell_120_300s,
    [dwellTime[5]]: data.dwell_300_600s,
    [dwellTime[6]]: data.dwell_600splus,
  };

  return dwellTimeDistribution;
};
