import Card from '@raydiant/raydial/components/Card';
import Page from '../../components/Page';
import PageLayout from '../../components/PageLayout/PageLayout';
import PageTitle from '../../components/PageLayout/PageTitle';
import { useCatalogs } from '../../hooks';
import Text from '@raydiant/raydial/components/Text';
import Button from '@raydiant/raydial/components/Button';
import LoadingModal from '@raydiant/raydial/components/LoadingModal';
import * as paths from '../../routes/paths';
import history from '../../history';
import { useState } from 'react';
import moment from 'moment';
import { CatalogItem } from './components';

const connectorThumbnailMap: Record<string, string> = {
  toast: 'https://assets.raydiant.com/connectors/toast.svg',
  square: 'https://assets.raydiant.com/connectors/square.svg',
};

const ProductHubCataloguePage = () => {
  const catalogsQuery = useCatalogs();
  const [selectedCatalogId, setSelectedCatalogId] = useState('');

  if (!catalogsQuery || catalogsQuery.isLoading) {
    return (
      <Page>
        <LoadingModal isOpen={true} />
      </Page>
    );
  }

  if (!catalogsQuery.data) {
    return null;
  }

  const { data: catalogs } = catalogsQuery.data;

  const handleOnAddCatalog = () => {
    history.push(paths.productHubCreateCatalog());
  };

  const navigateToCatalogDetails = (catalogId: string) => {
    history.push(paths.productHubCatalogDetails(catalogId));
  };

  const selectedCatalog = catalogs.find((c) => c.id === selectedCatalogId);

  return (
    <Page>
      <PageLayout
        title={
          <PageTitle title="Catalog" subTitle="View and manage catalogs" />
        }
        sectionClassName="text-black"
        className="overflow-auto"
      >
        <div className="grid grid-cols-1 lg:grid-cols-[3fr_2fr] gap-12 items-start max-w-[1200px]">
          <Card>
            <header className="flex items-center px-4">
              <div className="grid gap-1 py-4 mr-auto">
                <Text as="h2" variant="subheader">
                  Your Catalogs
                </Text>
                <Text as="h3" className="text-gray-400 font-regular text-lg">
                  {catalogs.length === 0 && 'You have no catalogs'}
                  {catalogs.length === 1 && 'You have 1 catalog'}
                  {catalogs.length > 1 &&
                    `You have ${catalogs.length} catalogs`}
                </Text>
              </div>

              {catalogs.length > 0 && (
                <div>
                  <Button onPress={handleOnAddCatalog}>Add Catalog</Button>
                </div>
              )}
            </header>
            {catalogs.length === 0 && (
              <CatalogItem
                heading="Add a catalog"
                subheading="Create a catalog from a integration"
                action={<Button onPress={handleOnAddCatalog}>Add</Button>}
              />
            )}
            {catalogs.length > 0 &&
              catalogs.map((catalog) => {
                const connectorName = catalog.connection?.connector.name || '';
                return (
                  <CatalogItem
                    key={catalog.id}
                    heading={catalog.name}
                    thumbnailUrl={connectorThumbnailMap[connectorName]}
                    subheading={catalog.connection?.connector.displayName}
                    action={
                      <>
                        {/* Link to catalog page on smaller viewports */}
                        <Button
                          onPress={() => {
                            navigateToCatalogDetails(catalog.id);
                          }}
                          className="block lg:hidden"
                        >
                          View Catalog
                        </Button>
                        {/* Display additional catalog info on larger viewports */}
                        <Button
                          onPress={() => {
                            setSelectedCatalogId(catalog.id);
                          }}
                          className="hidden lg:block"
                        >
                          Manage
                        </Button>
                      </>
                    }
                  />
                );
              })}
          </Card>
          {selectedCatalog && (
            <Card className="sticky top-0">
              <div className="grid gap-1 p-4 mr-auto items-start">
                <Text as="h2" variant="subheader">
                  {selectedCatalog.name}
                </Text>
                <Text as="h3" className="text-gray-400 font-regular text-lg">
                  Catalog Details
                </Text>
              </div>

              <div className="px-4 mb-4">
                <Text>
                  Created on:{' '}
                  {moment(selectedCatalog.createdAt).format('MMMM Do, YYYY')}
                </Text>
              </div>

              <div className="grid gap-4 px-4 mb-4">
                <Button
                  size="large"
                  onPress={() => {
                    navigateToCatalogDetails(selectedCatalog.id);
                  }}
                >
                  View Catalog
                </Button>
              </div>
            </Card>
          )}
        </div>
      </PageLayout>
    </Page>
  );
};

export default ProductHubCataloguePage;
