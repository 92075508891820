import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { useToastState } from '@raydiant/raydial/components/Toast/ToastProvider';
import { useDispatch, useSelector } from 'react-redux';

import * as analyticsActions from '../../actions/analytics';
import NavBar from '../Navigation/NavBar';
import Sidebar from '../Sidebar/Sidebar';
import * as paths from '../../routes/paths';
import BannerRouter from '../BannerRouter';
import { useAnalyticsExport } from '../../hooks';
import { getApiQueryParams } from '../../pages/AnalyticsPage/AnalyticsFilter/utils';
import { selectExportType } from '../../selectors/analytics';

type AppLayoutProps = {
  backTo?: string;
  children: React.ReactNode;
  hideNavigation?: boolean;
};

const AppLayout = ({
  hideNavigation = false,
  backTo,
  children,
}: AppLayoutProps) => {
  const [isOpen, setIsOpen] = useState(
    () => localStorage.getItem('isSidebarOpen') === 'true',
  );
  const { search } = useLocation();
  const query = getApiQueryParams(search);
  const { t } = useTranslation();
  const state = useToastState();
  const dispatch = useDispatch();

  const exportType = useSelector(selectExportType);

  const {
    data: exportedData,
    isError: isExportError,
    isLoading: isExportLoading,
  } = useAnalyticsExport(query, exportType);

  useEffect(() => {
    if (localStorage.getItem('isSidebarOpen') === null) {
      localStorage.setItem('isSidebarOpen', `true`);
      setIsOpen(true);
    }
  }, []);

  const handleToggleSidebar = (value: boolean) => {
    localStorage.setItem('isSidebarOpen', `${value}`);
    setIsOpen(value);
  };

  const devicesPage = !!useRouteMatch({ path: paths.screens.pattern });
  const libraryPage = !!useRouteMatch({ path: paths.library.pattern });
  const marketplacePage = !!useRouteMatch({ path: paths.marketplace.pattern });
  const accountPage = !!useRouteMatch({ path: paths.account.pattern });
  const analyticsPage = !!useRouteMatch({ path: paths.analytics.pattern });
  const aiAnalyticsPage = !!useRouteMatch({ path: paths.aiAnalytics.pattern });
  const contentEngagementPage = !!useRouteMatch({
    path: paths.contentEngagement.pattern,
  });
  const productHub = !!useRouteMatch({
    path: paths.productHubCatalogs.pattern,
  });

  const navigationPages =
    devicesPage ||
    libraryPage ||
    marketplacePage ||
    accountPage ||
    analyticsPage ||
    aiAnalyticsPage ||
    contentEngagementPage ||
    productHub;

  const handleDownload = (
    exportedData: BlobPart,
    filename = `export_${exportType}.csv`,
  ) => {
    const blob = new Blob([exportedData], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, filename);
  };

  if (!isExportLoading && exportType) {
    if (exportedData) {
      handleDownload(exportedData);
    }
    dispatch(analyticsActions.setExportType(''));
  }
  if (isExportError) {
    state.add({
      title: t('export.exportingToasts.errorTitle'),
      description: t('export.exportingToasts.errorDescription'),
      variant: 'caution',
    });
  }

  return (
    <>
      {hideNavigation && <>{children}</>}
      {!hideNavigation && (
        <div className={classNames('app-container')}>
          <div className={isOpen ? 'overlay' : ''}></div>
          <div className="main">
            <NavBar onToggleMenu={() => handleToggleSidebar(!isOpen)} />
            <section className="wrapper">
              <Sidebar
                onClose={() => handleToggleSidebar(false)}
                isOpen={isOpen}
                onTopMenuClick={handleToggleSidebar}
              />
              <main className="flex flex-col w-full overflow-auto main-wrapper">
                {navigationPages && <BannerRouter />}
                {children}
              </main>
            </section>
          </div>
        </div>
      )}
    </>
  );
};

export default AppLayout;
