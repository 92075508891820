import { Application, PaymentMethod } from '@raydiant/api-client-js';
import moment from 'moment';

export const userHasPremiumApp = (
  premiumApp: Application,
  applications: Application[],
) => applications.map((application) => application.id).includes(premiumApp.id);

export const sendFreetrialRequest = () => {
  const lastSentTime = localStorage.getItem('raydiant_freetrial_sent_at');

  if (!lastSentTime) {
    localStorage.setItem(
      'raydiant_freetrial_sent_at',
      new Date().toLocaleString(),
    );
    return true;
  }

  const hours = moment().diff(moment(lastSentTime), 'hours');

  if (hours >= 24) {
    localStorage.setItem(
      'raydiant_freetrial_sent_at',
      new Date().toLocaleString(),
    );
    return true;
  }

  return false;
};

export const getApplicationName = (application: Application) => {
  const { currentAppVersion, name } = application;
  const cta = currentAppVersion.name || name;
  return cta;
};

export const getGrantScope = (application: Application) => {
  const nonGrantScope = ['dev', 'sales', 'appreview', 'public'];
  const resourceACLs = application?.resource?.r?.resourceACLs || [];
  const resourceACL = resourceACLs.find(
    (resourceACL) =>
      resourceACL.grantScope && !nonGrantScope.includes(resourceACL.grantScope),
  );
  return resourceACL?.grantScope;
};

export const calculateTermPrice = (term: string, price: number) =>
  term === 'Annual'
    ? Number((price * 12).toFixed(2))
    : Number(price.toFixed(2));

export const validatePaymentMethods = (
  paymentMethods: PaymentMethod[],
): PaymentMethod[] =>
  paymentMethods.filter((paymentMethod) => paymentMethod.isActive);

export const formatCurrency = (number: number, currency = 'USD') =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    maximumFractionDigits: 2,
  }).format(number);
