import { useTranslation } from 'react-i18next';
import useCurrentUser from '../../hooks/useCurrentUser';
import AccountAvatar from './AccountAvatar/AccountAvatar';

type NavBarProps = {
  className?: string;
  onToggleMenu: () => void;
};

const NavBar = ({ onToggleMenu }: NavBarProps) => {
  const { data: user } = useCurrentUser();

  const { t } = useTranslation();

  return (
    <nav>
      <div className="logo-wrapper">
        <button className="btn min-w-min burger" onClick={onToggleMenu}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </button>
        <div className="image-wrapper">
          <div className="logo">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28.995"
              height="28.996"
              viewBox="0 0 28.995 28.996"
              className="w-5 h-5 text-inherit"
              fill="currentColor"
            >
              <g
                id="Group_2216"
                data-name="Group 2216"
                transform="translate(-1.5 -1.5)"
              >
                <path
                  id="Union_8"
                  data-name="Union 8"
                  d="M1545.72-550.007a11.453,11.453,0,0,1-11.452-11.453v-1.273h11.452a1.272,1.272,0,0,1,1.272,1.273v11.453Zm-25.45,0a1.271,1.271,0,0,1-1.273-1.272v-11.452h12.726v11.452a1.272,1.272,0,0,1-1.273,1.272Zm14-15.269V-578h6.363a6.361,6.361,0,0,1,6.361,6.362,6.362,6.362,0,0,1-6.361,6.363Zm-15.271,0A12.724,12.724,0,0,1,1531.723-578v12.725Z"
                  transform="translate(-1516.998 580.002)"
                />
              </g>
            </svg>
          </div>
          <p className="text-2xl hidden md:block font-medium text-acctconsole">
            {t('navBar.raydiant')}
          </p>
        </div>
      </div>
      <div className="flex">
        {!user && <div className="animte-pulse avatar rounded-full"></div>}
        {user && <AccountAvatar />}
      </div>
    </nav>
  );
};

export default NavBar;
