import { Grid, useMediaQuery } from '@material-ui/core';
import cn from 'classnames';

import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import Heading from 'raydiant-elements/core/Heading';
import Text from 'raydiant-elements/core/Text';

import ImageContentCard from './components/ImageContentCard';
import ContentCard from './components/ContentCard';
import ColorCard from './components/ColorCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      paddingBottom: theme.spacing(2),
    },
    boxHeading: {
      paddingBottom: theme.spacing(2),
      color: '#fff',
      textAlign: 'center',
    },
    box: {
      color: '#fff',
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      overflow: 'hidden',
    },
    imgBox: {
      display: 'flex',
    },
    intro: {
      color: '#000000',
    },
    case: {
      color: '#28d972',
    },
    appWrapper: {
      paddingTop: theme.spacing(3),
    },
    appName: {
      color: '#fff',
    },
    removeUpperPadding: {
      paddingTop: 'unset',
    },
    imageToTop: {
      display: 'flex',
      alignItems: 'flex-start',
    },
  }),
);

const EXModalContent = () => {
  const classes = useStyles();
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Grid>
      <ImageContentCard
        className={classes.img}
        imageSrc="https://assets.raydiant.com/marketplace/ex-modal-1.png"
        imageAlt="ex-modal-1"
        imageDirectionOnDesktop="left"
        imageDirectionOnMobile="top"
      >
        <ContentCard>
          <Heading size={2} weight={600} className={classes.heading}>
            Data & Integrations
          </Heading>
          <Text>
            With our robust data integrations (including the Toast & Square POS
            integrations), we can pull data from the source to populate
            Leaderboards, build Challenges, and even manage users, all in an
            easy, automated way. Data updates from POS systems shared across the
            entire organization screens (from breakrooms to corporate offices)
            keep everyone in the loop in real-time. Gamification features enable
            companies to foster healthy competition amongst team members or even
            between locations while encouraging teams to hit company goals and
            personal targets.
          </Text>
        </ContentCard>
      </ImageContentCard>

      <ImageContentCard
        className={classes.img}
        imageSrc="https://assets.raydiant.com/marketplace/ex-modal-2.png"
        imageAlt="ex-modal-2"
        imageDirectionOnDesktop="right"
        imageDirectionOnMobile="top"
      >
        <ContentCard
          className={mobile ? undefined : classes.removeUpperPadding}
        >
          <Heading size={2} weight={600} className={classes.heading}>
            Rewards & Recognition
          </Heading>
          <Text>
            Recognizing employees for their hard work is key to maintaining
            staff morale and motivation. People need to feel valued and
            appreciated. With Newsflashes, reward employees for hitting key
            targets and milestones through both an on-screen announcement and an
            alert within our app. Employers can also highlight specific
            employees through a recognition message as a way to celebrate their
            hard work without the need to tie recognition to a specific target.
            With Points & Rewards, businesses can further recognize a person's
            hard work with a tangible reward. EX is integrated with the largest
            rewards platform in the business, meaning employees can redeem
            points for everything from gift cards to Apple watches or even
            vacations.
          </Text>
        </ContentCard>
      </ImageContentCard>

      <ImageContentCard
        className={cn(classes.img, classes.imageToTop)}
        imageSrc="https://assets.raydiant.com/marketplace/ex-modal-3.png"
        imageAlt="ex-modal-3"
        imageDirectionOnDesktop="left"
        imageDirectionOnMobile="top"
      >
        <ContentCard
          className={mobile ? undefined : classes.removeUpperPadding}
        >
          <Heading size={2} weight={600} className={classes.heading}>
            Messaging & Alerts
          </Heading>
          <Text>
            With our internal Huddle messaging platform, businesses can send
            company-wide news to anyone, anywhere, keeping everyone in the loop.
            Huddle was designed FOR the “deskless employee,” making it easy for
            everyone to feel informed. Huddle messages don't just include
            general news – managers can send their staff critical or emergency
            alerts when they are not onsite. Newsflashes & recognition messages
            can be seen and accessed at any time within Huddle - so everyone can
            celebrate the team's success.
          </Text>
        </ContentCard>
      </ImageContentCard>

      <ColorCard type="ex">
        <ContentCard>
          <Grid item container justifyContent="center" xs={12} md={12}>
            <Heading size={2} weight={700} className={classes.boxHeading}>
              Raydiant Marketplace - Suggested App
            </Heading>
          </Grid>

          <Grid item container justifyContent="center" xs={12} md={12}>
            <Heading size={3} weight={300} className={classes.box}>
              Raydiant Marketplace also offers a variety of apps available to
              enhance your on screen Experience. Use Instagram, Vimeo, Bloomberg
              or Poster My Wall (to name a few) to make your screens a full
              visual experience, everywhere from your back office, break room or
              corporate offices.
            </Heading>
          </Grid>

          <Grid
            item
            container
            spacing={mobile ? 0 : 2}
            justifyContent="space-between"
            alignItems="center"
            xs={12}
            md={12}
            className={classes.appWrapper}
          >
            <Grid item container justifyContent="center" xs={5} md={3}>
              <img
                src="https://assets.raydiant.com/marketplace/pmw-thumbnail.svg"
                alt="postermywall"
                className={classes.img}
              />
              <Grid item className={classes.appName}>
                PosterMyWall
              </Grid>
            </Grid>
            <Grid item container justifyContent="center" xs={5} md={3}>
              <img
                src="https://assets.raydiant.com/marketplace/bloomberg-thumbnail.svg"
                alt="bloomberg"
                className={classes.img}
              />
              <Grid item className={classes.appName}>
                Bloomberg
              </Grid>
            </Grid>
            <Grid item container justifyContent="center" xs={5} md={3}>
              <img
                src="https://assets.raydiant.com/marketplace/vimeo-thumbnail.png"
                alt="vimeo"
                className={classes.img}
              />
              <Grid item className={classes.appName}>
                Vimeo
              </Grid>
            </Grid>
            <Grid item container justifyContent="center" xs={5} md={3}>
              <img
                src="https://assets.raydiant.com/marketplace/instagram-thumbnail.svg"
                alt="instagram"
                className={classes.img}
              />
              <Grid item className={classes.appName}>
                Instagram
              </Grid>
            </Grid>
          </Grid>
        </ContentCard>
      </ColorCard>

      <Grid item container justifyContent="center" alignItems="center"></Grid>
    </Grid>
  );
};

export default EXModalContent;
