import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import Button from 'raydiant-elements/core/Button';
import Text from 'raydiant-elements/core/Text';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import Row from 'raydiant-elements/layout/Row';
import Hidden from 'raydiant-elements/layout/Hidden';
import PublishPendingIcon from 'raydiant-elements/icons/PublishPending';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PublishIcon from '@material-ui/icons/Publish';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import { Device } from '@raydiant/api-client-js';
import {
  selectDeviceStatusById,
  selectPlaylistStatusByDeviceIdBulkUpdate,
} from '../../selectors/v2/devices';
import { selectProofOfPublishEnabled } from '../../selectors/user';
import {
  getDevicePublishPlaybackStatus,
  isDevicePlaybackEventsSupported,
  isDevicePublishable,
} from '../../utilities';

export interface PublishButtonProps {
  className?: string;
  device: Device;
  isOnline: boolean;
  isBatchOperationInProgress: boolean;
  onClick: () => void;
}

const PublishButton = ({
  className,
  device,
  isOnline,
  isBatchOperationInProgress,
  onClick,
}: PublishButtonProps) => {
  const classes = useStyles();

  // Selectors

  const deviceStatusById = useSelector(selectDeviceStatusById);
  const playlistStatusByDeviceId = useSelector(
    selectPlaylistStatusByDeviceIdBulkUpdate,
  );
  // TODO: Remove when we release proof of publish to all users.
  const isProofOfPublishEnabled = useSelector(selectProofOfPublishEnabled);

  const deviceStatus = deviceStatusById[device.id];
  const playlistStatus = playlistStatusByDeviceId[device.id];
  const publishPlaybackStatus = getDevicePublishPlaybackStatus(device);

  // State

  const [publishStatus, setPublishStatus] = useState<
    '' | 'pending' | 'received' | 'success' | 'queued'
  >('');

  // Effects

  const deviceSupportsProofOfPlayback = isDevicePlaybackEventsSupported(device);
  const isRequestingPublish = deviceStatus === 'publishing';
  const isWaitingForPublishAck = publishPlaybackStatus === 'waitingForAck';
  const didReceivePublishAck = publishPlaybackStatus === 'ackReceived';
  // If the device does not support proof of playback (Lite and SecondScreen), there is
  // no publish playback status so we can only use isRequestingPublish to determine
  // if it's complete.
  const didPublishComplete = deviceSupportsProofOfPlayback
    ? publishPlaybackStatus === 'playbackComplete'
    : !isRequestingPublish;

  const prevDidPublishComplete = useRef<boolean>(didPublishComplete);
  const prevIsOnline = useRef<boolean>(isOnline);
  // Update publish status UI state.
  useEffect(() => {
    if (!isBatchOperationInProgress) {
      let timeout: ReturnType<typeof setTimeout>;

      if (isProofOfPublishEnabled) {
        if (isRequestingPublish || isWaitingForPublishAck) {
          setPublishStatus(isOnline ? 'pending' : 'queued');
        } else if (didReceivePublishAck) {
          setPublishStatus(isOnline ? 'received' : 'queued');
        } else if (didPublishComplete) {
          // Show 'success' if device is online and didPublishComplete changes
          // from false to true.
          if (prevDidPublishComplete.current === false) {
            timeout = setTimeout(() => {
              setPublishStatus('success');
              timeout = setTimeout(() => {
                setPublishStatus('');
              }, 9000); // Published! should remain visible for 9 seconds
            }, 1000); // Publishing... should remain visible for at least 1 second
          }
        }
      } else {
        setPublishStatus(isRequestingPublish ? 'pending' : '');
      }

      // Reset status if device changes from online to offline and we're no
      // longer publishing. This is required to remove the queued state from
      // Lite and SecondScreen when they come back online since they currently
      // do not implment playback events.
      if (!prevIsOnline.current && isOnline) {
        setPublishStatus('');
      }

      prevDidPublishComplete.current = didPublishComplete;
      prevIsOnline.current = isOnline;

      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }
  }, [
    isBatchOperationInProgress,
    isRequestingPublish,
    isWaitingForPublishAck,
    didReceivePublishAck,
    didPublishComplete,
    isOnline,
    isProofOfPublishEnabled,
    deviceSupportsProofOfPlayback,
  ]);

  // Render

  const isPublishDisabled =
    playlistStatus === 'pendingUpload' ||
    playlistStatus === 'uploadError' ||
    (!isProofOfPublishEnabled && isRequestingPublish);

  const isPublishable = isDevicePublishable(
    device,
    deviceStatus,
    playlistStatus,
  );

  if (isBatchOperationInProgress) {
    return (
      <div className={className}>
        <Row
          halfMargin
          center
          className={cn(classes.publishStatus, classes.primary)}
        >
          <Hidden smDown>
            <Text small>Applying changes ...</Text>
          </Hidden>
          <CircularProgress color="inherit" size={20} />
        </Row>
      </div>
    );
  }

  if (isPublishable || (!isProofOfPublishEnabled && isRequestingPublish)) {
    return (
      <div className={className}>
        <Hidden smDown>
          <Button
            color="progress"
            label="Publish"
            icon={<PublishIcon />}
            onClick={onClick}
            disabled={isPublishDisabled}
          />
        </Hidden>
        <Hidden mdUp>
          <Button
            className={className}
            color="progress"
            icon={<PublishIcon />}
            onClick={onClick}
            disabled={isPublishDisabled}
          />
        </Hidden>
      </div>
    );
  }

  if (publishStatus === 'queued') {
    return (
      <div className={className}>
        <Row
          halfMargin
          center
          className={cn(classes.publishStatus, classes.muted)}
        >
          <Hidden smDown>
            <Text xsmall>Publish pending</Text>
          </Hidden>
          <PublishPendingIcon />
        </Row>
      </div>
    );
  }

  if (publishStatus === 'pending' || publishStatus === 'received') {
    return (
      <div className={className}>
        <Row
          halfMargin
          center
          className={cn(classes.publishStatus, classes.primary)}
        >
          <Hidden smDown>
            <Text xsmall>
              {publishStatus === 'pending'
                ? 'Publishing...'
                : 'Publish received'}
            </Text>
          </Hidden>
          <CircularProgress color="inherit" size={20} />
        </Row>
      </div>
    );
  }

  if (publishStatus === 'success') {
    return (
      <div className={className}>
        <Row
          halfMargin
          center
          className={cn(classes.publishStatus, classes.primary)}
        >
          <Hidden smDown>
            <Text xsmall>Published!</Text>
          </Hidden>
          <CheckCircleIcon className={classes.success} />
        </Row>
      </div>
    );
  }

  return null;
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    publishStatus: {
      color: theme.palette.primary.main,
      // Copied from Button styles to match alignment.
      height: 40,

      [theme.breakpoints.down('xs')]: {
        height: 'auto',
      },
    },

    primary: {
      color: theme.palette.primary.main,
    },

    muted: {
      color: theme.palette.text.secondary,
    },

    success: {
      color: theme.palette.progress.main,
    },
  });
});

export default PublishButton;
