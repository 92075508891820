import { FC } from 'react';
import { Dialog, DialogContent, DialogActions } from '@material-ui/core';
import Button from 'raydiant-elements/core/Button';
import Spacer from 'raydiant-elements/layout/Spacer';

import PaperModal from 'raydiant-elements/core/PaperModal';
import Heading from 'raydiant-elements/core/Heading';
import PropertiesTable from '../../../components/PropertiesTable';
import Checkbox from 'raydiant-elements/core/Checkbox';
import ClearIcon from '@material-ui/icons/Clear';
import { Grid } from '@material-ui/core';
import useStyles from './DeviceBatchDialog.styles';
import moment from 'moment-timezone';
import { formatOrientation } from '../deviceBatchSettingsUtils';
import { BatchDevice } from '../deviceBatchSettings.interface';
import LoadingButton from '../../../components/LoadingButton';
import { LoadingStatus } from '../../../components/LoadingButton/LoadingButton';
import { useTranslation } from 'react-i18next';

interface MarketplaceAppDialogProps {
  open: boolean;
  settings: BatchDevice;
  status: LoadingStatus;
  onApplyBatchSettings: () => void;
  onHandleAutoPublish: (checked: boolean) => void;
  onClose: () => void;
}

const parseTimeToPM = (time: string) => {
  return moment(time, 'HH:mm').format('hh:mm a');
};

const DeviceDialog: FC<MarketplaceAppDialogProps> = ({
  open,
  settings: {
    playlist,
    screenDimensions,
    screenOrientation,
    timezone,
    activeHoursStart,
    activeHoursEnd,
    dailyRebootTime,
    dailyRestartTime,
    removeKeyTags,
    removeValueTags,
    overwriteTags,
    addTags,
    itemsToShare,
    itemsToUnShare,
    autoPublish,
  },
  status,
  onApplyBatchSettings,
  onClose,
  onHandleAutoPublish,
}) => {
  const { t } = useTranslation(['devices']);
  const classes = useStyles();

  const hasKeyTagsToRemove = removeKeyTags.length > 0;
  const hasValueTagsToRemove = removeValueTags.length > 0;
  const hasOverwriteTags = overwriteTags.length > 0;
  const hasTagsToAdd = addTags.length > 0;
  const hasItemsToShare = itemsToShare.length > 0;
  const hasItemsToUnShare = itemsToUnShare.length > 0;

  const showDefaultSummarySection =
    playlist || screenDimensions || timezone || screenOrientation;
  const showTagsSection =
    hasKeyTagsToRemove ||
    hasValueTagsToRemove ||
    hasOverwriteTags ||
    hasTagsToAdd;
  const showSharingSection = hasItemsToShare || hasItemsToUnShare;
  const showDeviceUpdateBlackoutSection =
    activeHoursStart || activeHoursEnd || dailyRebootTime || dailyRestartTime;

  return (
    <Dialog open={open} onClose={onClose}>
      <Grid className={classes.buttonWrapper} onClick={() => onClose()}>
        <div className={classes.closeButton}>CLOSE</div>
        <ClearIcon className={classes.closeButtonIcon} />
      </Grid>
      <DialogContent>
        <PaperModal.Body>
          <Heading size={1} overline gutterBottom>
            Summary
          </Heading>

          {showDefaultSummarySection && (
            <div className={classes.section}>
              <PropertiesTable>
                <tbody>
                  {playlist && (
                    <tr>
                      <th>Playlist Added</th>
                      <td>{playlist.name}</td>
                    </tr>
                  )}

                  {screenDimensions && (
                    <tr>
                      <th>Screen Resolution</th>
                      <td>{screenDimensions}</td>
                    </tr>
                  )}

                  {timezone && (
                    <tr>
                      <th>Timezone</th>
                      <td>{`(GMT${moment
                        .tz(timezone)
                        .format('Z')}) ${timezone}`}</td>
                    </tr>
                  )}

                  {screenOrientation && (
                    <tr>
                      <th>Screen Orientation</th>
                      <td>{t(formatOrientation(screenOrientation))}</td>
                    </tr>
                  )}
                </tbody>
              </PropertiesTable>
            </div>
          )}

          {showTagsSection && (
            <div className={classes.section}>
              <Heading size={5} gutterBottom>
                Tags
              </Heading>
              <PropertiesTable>
                <tbody>
                  {hasKeyTagsToRemove && (
                    <tr>
                      <th>Removed (key only):</th>
                      <div className={classes.multiple}>
                        {removeKeyTags.map((tag) => (
                          <td>{tag.label}</td>
                        ))}
                      </div>
                      <br />
                    </tr>
                  )}

                  {hasValueTagsToRemove && (
                    <tr>
                      <th>Removed:</th>
                      <div className={classes.multiple}>
                        {removeValueTags.map((tag) => (
                          <td>
                            {tag.label}: {tag.value}
                          </td>
                        ))}
                      </div>
                      <br />
                    </tr>
                  )}

                  {hasOverwriteTags && (
                    <tr>
                      <th>Overwritted:</th>
                      <div className={classes.multiple}>
                        {overwriteTags.map((tag) => (
                          <td>
                            {tag.label}: {tag.value}
                          </td>
                        ))}
                      </div>
                      <br />
                    </tr>
                  )}

                  {hasTagsToAdd && (
                    <tr>
                      <th>Added:</th>
                      <div className={classes.multiple}>
                        {addTags.map((tag) => (
                          <td>
                            {tag.label}: {tag.value}
                          </td>
                        ))}
                      </div>
                    </tr>
                  )}
                </tbody>
              </PropertiesTable>
            </div>
          )}

          {showSharingSection && (
            <div className={classes.section}>
              <Heading size={5} gutterBottom>
                Sharing
              </Heading>
              <PropertiesTable>
                <tbody>
                  {hasItemsToShare && (
                    <>
                      <tr>
                        <th>Added</th>
                        <div className={classes.multiple}>
                          {itemsToShare.map((profile) => (
                            <td>{profile.name}</td>
                          ))}
                        </div>
                      </tr>
                      <br />
                    </>
                  )}

                  {hasItemsToUnShare && (
                    <tr>
                      <th>Removed</th>
                      <div className={classes.multiple}>
                        {itemsToUnShare.map((profile) => (
                          <td>{profile.name}</td>
                        ))}
                      </div>
                    </tr>
                  )}
                </tbody>
              </PropertiesTable>
            </div>
          )}

          {showDeviceUpdateBlackoutSection && (
            <div className={classes.section}>
              <Heading size={5} gutterBottom>
                Device Update Blackout
              </Heading>
              <div>
                {timezone
                  ? `Timezone: (GMT${moment
                      .tz(timezone)
                      .format('Z')}) ${timezone}`
                  : 'Will follow the device’s current timezone setting.'}
              </div>
              <PropertiesTable>
                <tbody>
                  {activeHoursStart && (
                    <tr>
                      <th>From</th>
                      <td>{parseTimeToPM(activeHoursStart)}</td>
                    </tr>
                  )}

                  {activeHoursEnd && (
                    <tr>
                      <th>To</th>
                      <td>{parseTimeToPM(activeHoursEnd)}</td>
                    </tr>
                  )}

                  {dailyRestartTime && (
                    <tr>
                      <th>Restart</th>
                      <td>{parseTimeToPM(dailyRestartTime)}</td>
                    </tr>
                  )}
                  {dailyRebootTime && (
                    <tr>
                      <th>Restart</th>
                      <td>{parseTimeToPM(dailyRebootTime)}</td>
                    </tr>
                  )}
                </tbody>
              </PropertiesTable>
            </div>
          )}
        </PaperModal.Body>
        <DialogActions>
          <Button
            className={classes.button}
            onClick={onClose}
            color="default"
            size="small"
          >
            Cancel
          </Button>
          <Spacer />
          <div className={classes.button}>
            <LoadingButton
              color="primary"
              label="Confirm"
              disabled={status === 'loading' || status === 'error'}
              iconAlignment="start"
              fullWidth
              status={status}
              successLabel="Applied"
              loadingLabel="Applying..."
              errorLabel="Failed!"
              delay={1000}
              onClick={onApplyBatchSettings}
            />
          </div>
        </DialogActions>
        <div className={classes.checkbox}>
          <Checkbox
            checked={autoPublish}
            onChange={onHandleAutoPublish}
            label="Automatically publish after applying all settings"
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DeviceDialog;
