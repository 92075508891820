import { FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import Button from 'raydiant-elements/core/Button';
import Spacer from 'raydiant-elements/layout/Spacer';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import { titleCase } from '../../utilities/stringUtils';
import Text from 'raydiant-elements/typography/Text';

interface MarketplaceAppDialogProps {
  open: boolean;
  handleClose: () => void;
  handleClick: (selected: string) => void;
  name: string | null;
  error: string | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      minWidth: '8rem',
    },
    error: {
      color: '#b00020',
    },
  }),
);

const MarketplaceAppDialog: FC<MarketplaceAppDialogProps> = ({
  open,
  handleClose,
  handleClick,
  name,
  error,
}) => {
  const classes = useStyles();

  if (!name) return null;

  const isTile =
    name === 'dx' || name === 'ex' || name === 'cx' || name === 'ai';

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Text>
            {!isTile &&
              `Thanks for expressing interest in ${titleCase(name)}. `}
            A Raydiant sales representative will reach out to you to complete
            your order. Press OK to confirm.
          </Text>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          onClick={handleClose}
          color="default"
        >
          Cancel
        </Button>
        <Spacer />
        {error && <Text className={classes.error}>{error}</Text>}
        <Button
          className={classes.button}
          onClick={() => handleClick(name)}
          color="primary"
          disabled={!!error}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MarketplaceAppDialog;
