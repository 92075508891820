import classNames from 'classnames';

type CloseButtonProps = {
  className?: string;
  onClick: () => void;
};

const CloseButton = ({ className, onClick }: CloseButtonProps) => {
  return (
    <button
      className={classNames('btn text-black close', className)}
      onClick={onClick}
    >
      <div className="wrapper">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
    </button>
  );
};

export default CloseButton;
