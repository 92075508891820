import classNames from 'classnames';
import DropDownItem from '../DropDown/DropDownItem';
import { Hidden } from 'raydiant-elements/layout/Hidden/Hidden';

type SelectOptionProps = JSX.IntrinsicElements['option'] & {
  disableMobile?: boolean;
  onClick?: (value: any) => void;
};

const SelectOption = ({
  children,
  disableMobile,
  onClick,
  ...props
}: SelectOptionProps) => {
  return (
    <>
      {/* 
        Depending on Hidden which uses MUI under the hood is not ideal in the long run 
        We should be able to define our own Hidden component using custom hooks like useMediaQuery
        https://usehooks-ts.com/react-hook/use-media-query
      */}
      <Hidden xsDown={!disableMobile}>
        <DropDownItem
          onClick={() => onClick && onClick(props.value)}
          className={classNames('cursor', props.className)}
        >
          {children}
        </DropDownItem>
      </Hidden>
      {!disableMobile && (
        <Hidden smUp>
          <option {...props} className={classNames('cursor', props.className)}>
            {children}
          </option>
        </Hidden>
      )}
    </>
  );
};

export default SelectOption;
