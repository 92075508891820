import { useState } from 'react';
import classNames from 'classnames';
import { Popover } from '@headlessui/react';
import { Location } from '@raydiant/api-client-js';
import DropDownPlaceholder from '../../../../components/DropDown/DropDownPlaceholder';
import LocationsSelect from './LocationsSelect';

type LocationsDropDownProps = {
  className?: string;
  onChange: (value: LocationsQuery) => void;
  value: LocationsQuery;
};

export type LocationsQuery = {
  locationIds: Location['id'][];
};

const LocationsDropDown = ({
  className,
  onChange,
  value,
}: LocationsDropDownProps) => {
  const locationsCount = Object.values(value?.locationIds ?? [])?.length;
  const selectedFiltersCount = locationsCount;

  const [open, setOpen] = useState(false);

  return (
    <Popover className={classNames('relative', className)}>
      <Popover.Button
        className="input no-label text-left w-full"
        onClick={() => setOpen(true)}
      >
        <DropDownPlaceholder className="w-full md:min-w-[110px]">
          <div className="flex items-center justify-between w-full">
            <p>Location</p>
            {selectedFiltersCount > 0 && (
              <span className="mr-1 text-primary">
                ({selectedFiltersCount})
              </span>
            )}
          </div>
        </DropDownPlaceholder>
      </Popover.Button>
      {open && (
        <>
          <div className="inset-0 fixed z-[8]" onClick={() => setOpen(false)} />
          <Popover.Panel
            className="absolute z-[8] card menu dropdown p-0 min-w-min w-full md:!min-w-[380px] !max-h-max left-0 !overflow-visible"
            static
            focus
          >
            <div className="p-6 text-black flex flex-col gap-3">
              <LocationsSelect
                className=""
                onChange={(location) => {
                  onChange({
                    ...value,
                    locationIds: [...(value?.locationIds || []), location.id],
                  });
                }}
                onClear={() => onChange({ ...value, locationIds: [] })}
                onRemoveLocation={(id) =>
                  onChange({
                    ...value,
                    locationIds: [...(value?.locationIds || [])].filter(
                      (locationId) => locationId !== id,
                    ),
                  })
                }
                value={value?.locationIds ?? []}
              />
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default LocationsDropDown;
