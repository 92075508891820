import { HeatmapChart } from 'echarts/charts';
import ChartCard from '../ChartCard/ChartCard';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import Hidden from 'raydiant-elements/layout/Hidden';
import { CHART_DEFAULT_AXIS_OPTIONS, CHART_DEFAULT_OPTIONS } from '../utils';
import {
  getDwellTimesByHourChartData,
  heatmapDays,
  displayHeatmapDays,
  heatmapHours,
  displayHeatmapHours,
} from './utils';
import { WeekdayHourTraffic } from '@raydiant/api-client-js';
import { Popover } from '@headlessui/react';
import QuestionMarkIcon from '../../../components/QuestionMarkIcon';
import PopoverButton from '../../../components/Popover/PopoverButton';
import { useTranslation } from 'react-i18next';
import ChartToolTip, { EChartsToolTipParams } from '../ChartToolTip';
import { renderToStaticMarkup } from 'react-dom/server';
import { getDisplayValue } from '../ShopperEngagementInsights/utils';

echarts.use([HeatmapChart]);

interface DwellTimesByHourChartProps {
  data: WeekdayHourTraffic[];
}

const DwellTimesByHourChart = ({ data }: DwellTimesByHourChartProps) => {
  const { t } = useTranslation();

  const options = {
    ...CHART_DEFAULT_OPTIONS,
    animation: false,
    legend: {},
    tooltip: {
      position: 'top',
      borderWidth: 1,
      padding: 0,
      formatter: (params: EChartsToolTipParams) => {
        const currentDayIndex = params?.data[1] || 0;
        const currentHourIndex = params?.data[0] || 0;
        const currentHour = displayHeatmapHours[currentHourIndex] || 0;
        const nextHour = displayHeatmapHours[currentHourIndex + 1];

        const modifiedParams = [
          {
            ...params,
            seriesName: `${currentHour} - ${nextHour}`,
            value: getDisplayValue(params?.data[2], ' sec'),
          },
        ];

        return renderToStaticMarkup(
          <ChartToolTip
            header={
              <p className="font-bold text-sm px-2 py-1 text-black">
                {displayHeatmapDays[currentDayIndex]}
              </p>
            }
            values={modifiedParams}
          />,
        );
      },
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    grid: {
      height: '85%',
      top: '13%',
    },
    xAxis: {
      ...CHART_DEFAULT_AXIS_OPTIONS.xAxis,
      type: 'category',
      data: heatmapHours,
      splitArea: {
        show: true,
      },
      position: 'top',
      axisLabel: {
        ...CHART_DEFAULT_AXIS_OPTIONS.yAxis.axisLabel,
        formatter: (value: number) => `${value}`,
      },
      axisTick: {
        alignWithLabel: false,
      },
    },
    yAxis: {
      ...CHART_DEFAULT_AXIS_OPTIONS.yAxis,
      data: heatmapDays,
      type: 'category',
      splitArea: {
        show: true,
      },
      axisLabel: {
        ...CHART_DEFAULT_AXIS_OPTIONS.yAxis.axisLabel,
        formatter: (value: number) => `${value}`,
        height: 23,
      },
      axisTick: {
        alignWithLabel: true,
      },
    },
    visualMap: {
      min: 0,
      max: Math.max(...data.map((item) => item.avgDwellTime)),
      calculable: true,
      orient: 'horizontal',
      right: '10%',
      top: '-30%',
      inRange: {
        color: ['#FFF9EC', '#FFC245'], //From smaller to bigger value ->
      },
    },
    series: [
      {
        name: '',
        type: 'heatmap',
        data: getDwellTimesByHourChartData(data),
        label: {
          show: false,
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
            borderColor: '#000',
          },
        },
      },
    ],
  };

  const mobileOptions = {
    ...options,
    legend: {
      ...CHART_DEFAULT_OPTIONS.legend,
      type: 'scroll',
    },
  };

  return (
    <ChartCard
      subTitle={''}
      title={
        <div className="flex gap-3 items-center">
          <h2 className="text-black text-xl font-bold">
            {t('analytics.trafficAwarenessPage.dwellTimesByHour.title')!}
          </h2>
          <Popover className="relative">
            <PopoverButton icon={<QuestionMarkIcon />} />
            <Popover.Panel className="absolute z-10 card popover">
              <div className="overflow-hidden">
                <p className="mb-3">
                  {t('analytics.trafficAwarenessPage.dwellTimesByHour.tooltip')}
                </p>
                <a
                  href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('analytics.learnmore')}
                </a>
              </div>
            </Popover.Panel>
          </Popover>
        </div>
      }
      showTimeInterval={false}
    >
      <div className="min-w-full min-h-full">
        <Hidden xsDown>
          <ReactECharts
            style={{ height: '190px' }}
            option={options}
            onChartReady={(chart) => chart.resize()}
          />
        </Hidden>
        <Hidden smUp>
          <ReactECharts
            style={{ height: '190px' }}
            option={mobileOptions}
            onChartReady={(chart) => chart.resize()}
          />
        </Hidden>
      </div>
    </ChartCard>
  );
};

export default DwellTimesByHourChart;
