import { FC, useEffect, useState, useRef } from 'react';
import useStyles from './TagInput.styles';
import TagIcon from '../TagIcon';
export interface TagInputTimeTypeProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
}

const TagInputTimeType: FC<TagInputTimeTypeProps> = ({
  label,
  value: initialValue,
  onChange,
}) => {
  const classes = useStyles();

  const [value, setValue] = useState<string>(initialValue);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div className="border-2 border-[#f5f5f5] rounded-2xl w-full text-neutral-400 px-4 h-[40px]">
      <div className="flex items-center w-full gap-3 h-full">
        <TagIcon className={classes.icon} />
        <div className={classes.tag}>
          <span>{label}:</span>
          &nbsp;
          <input
            ref={inputRef}
            type="time"
            className={classes.tagTimeInput}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onBlur={() => onChange(value)}
          />
        </div>
      </div>
    </div>
  );
};

export default TagInputTimeType;
