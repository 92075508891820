import {
  ApiClientError,
  ContentDetailQueryParams,
  GetContentDetail,
} from '@raydiant/api-client-js';

import { keys } from '../queryClient';
import raydiant from '../clients/miraClient';
import useProtectedQuery from './useProtectedQuery';

export default function useContentDetail(params: ContentDetailQueryParams) {
  return useProtectedQuery<GetContentDetail, ApiClientError>(
    keys.contentDetail(params),
    () => raydiant.getContentDetail(params),
  );
}
