import AddIntegrationColumn from './AddIntegrationColumn';
import { Grid } from '@material-ui/core';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import ConnectionSettingsStepCard from './ConnectionSettingsStepCard';
import { connectionSteps } from '../IntegrationPageUtils';
import Scrollable from 'raydiant-elements/layout/Scrollable';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      '&:nth-child()': {
        marginBottom: theme.spacing(5),
      },
    },
  });
});

export interface ConnectionStepsProps {
  selectedIntegrationId: string;
}

const ConnectionSteps = ({ selectedIntegrationId }: ConnectionStepsProps) => {
  const classes = useStyles();
  const integration = connectionSteps.find(
    ({ id }) => id === selectedIntegrationId,
  );
  if (!integration) return null;

  return (
    <AddIntegrationColumn heading="connection steps">
      <Scrollable>
        <Grid className={classes.root}>
          {integration?.steps.map((step, i) => (
            <ConnectionSettingsStepCard
              stepNumber={i + 1}
              description={step.description}
              imageUrl={step.imageUrl}
              imageAlt={`${integration.name}-step-${i + 1}`}
            />
          ))}
        </Grid>
      </Scrollable>
    </AddIntegrationColumn>
  );
};

export default ConnectionSteps;
