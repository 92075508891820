import { SVGProps } from 'react';

interface ArrowDownArrowUpProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const ArrowDownArrowUp = ({ size = 18, ...props }: ArrowDownArrowUpProps) => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.635254 12.1426C0.303223 11.8438 0.303223 11.3457 0.635254 11.0137C0.934082 10.7148 1.43213 10.7148 1.76416 11.0137L3.59033 12.873V1.48438C3.59033 1.05273 3.92236 0.6875 4.38721 0.6875C4.81885 0.6875 5.18408 1.05273 5.18408 1.48438V12.873L7.01025 11.0469C7.30908 10.7148 7.80713 10.7148 8.10596 11.0469C8.43799 11.3457 8.43799 11.8438 8.10596 12.1426L4.91846 15.3301C4.61963 15.6621 4.12158 15.6621 3.82275 15.3301L0.635254 12.1426ZM12.3228 0.919922C12.6216 0.621094 13.1196 0.621094 13.4517 0.919922L16.606 4.10742C16.938 4.43945 16.938 4.9375 16.606 5.23633C16.3071 5.56836 15.8091 5.56836 15.4771 5.23633L13.6509 3.41016V14.7656C13.6509 15.2305 13.3188 15.5625 12.854 15.5625C12.4224 15.5625 12.0571 15.2305 12.0571 14.7656V3.41016L10.231 5.23633C9.93213 5.56836 9.43408 5.56836 9.13525 5.23633C8.80322 4.9375 8.80322 4.43945 9.13525 4.10742L12.3228 0.919922Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default ArrowDownArrowUp;
