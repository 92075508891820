import { SVGProps } from 'react';

interface EllipsisProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const Ellipsis = ({ size = 18, ...props }: EllipsisProps) => {
  return (
    <svg
      width="15"
      height="5"
      viewBox="0 0 15 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.8437 2.625C14.8437 3.22266 14.5117 3.7207 14.0469 4.01953C13.5488 4.31836 12.918 4.31836 12.4531 4.01953C11.9551 3.7207 11.6562 3.22266 11.6562 2.625C11.6562 2.06055 11.9551 1.5625 12.4531 1.26367C12.918 0.964844 13.5488 0.964844 14.0469 1.26367C14.5117 1.5625 14.8437 2.06055 14.8437 2.625ZM9.53125 2.625C9.53125 3.22266 9.19922 3.7207 8.73437 4.01953C8.23633 4.31836 7.60547 4.31836 7.14062 4.01953C6.64258 3.7207 6.34375 3.22266 6.34375 2.625C6.34375 2.06055 6.64258 1.5625 7.14062 1.26367C7.60547 0.964844 8.23633 0.964844 8.73437 1.26367C9.19922 1.5625 9.53125 2.06055 9.53125 2.625ZM2.625 4.21875C2.02734 4.21875 1.5293 3.91992 1.23047 3.42188C0.931641 2.95703 0.931641 2.32617 1.23047 1.82812C1.5293 1.36328 2.02734 1.03125 2.625 1.03125C3.18945 1.03125 3.6875 1.36328 3.98633 1.82813C4.28516 2.32617 4.28516 2.95703 3.98633 3.42188C3.6875 3.91992 3.18945 4.21875 2.625 4.21875Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Ellipsis;
