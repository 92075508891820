import MarketplaceModal from './MarketplaceModal';

interface ReachOutToSalesModalProps {
  open: boolean;
  onClick: () => void;
}

const ReachOutToSalesModal = ({ open, onClick }: ReachOutToSalesModalProps) => {
  return (
    <MarketplaceModal
      content="Thanks for expressing interest in our apps. Please contact your Raydiant account admin to purchase an app subscription."
      heading="Interested in our apps?"
      open={open}
      handleClick={onClick}
    />
  );
};

export default ReachOutToSalesModal;
