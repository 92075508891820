import { ApiClientError, DeviceAIResponse } from '@raydiant/api-client-js';
import { keys } from '../queryClient';
import useProtectedQuery from './useProtectedQuery';
import raydiant from '../clients/miraClient';

export default function useDeviceAISettings(deviceId: string) {
  return useProtectedQuery<DeviceAIResponse, ApiClientError>(
    keys.deviceAI(deviceId),
    () => raydiant.getDeviceAISettings(deviceId),
  );
}
