export const getToolTipContent = (sourceId: string) => {
  switch (sourceId) {
    case 'workers.ai-realtime.primaryAttentionTime':
      return `Attention time of the person with the largest face to have reached or crossed 
      "Min Distance From Camera" threashold and to have paid more or equal attention then "Min Attention Time"`;
    case 'workers.ai-realtime.primaryGender':
      return `Gender of the person with the largest face
      to have reached or crossed "Min Distance From Camera" threshold and to have paid more or equal attention
      than "Min Attention Time"`;
    case 'workers.ai-realtime.primaryAge':
      return `Age of the person with the largest face
      to have reached or crossed "Min Distance From Camera" threshold and to have paid more or equal attention
      than "Min Attention Time"`;
    default:
      return '';
  }
};
