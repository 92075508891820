import { useState, useCallback } from 'react';
import moment from 'moment';
import { ApiClientError, Location } from '@raydiant/api-client-js';
import TextField from '@raydiant/raydial/components/TextField';
import Button from '@raydiant/raydial/components/Button';
import Text from '@raydiant/raydial/components/Text';
import TimezoneSelect from '../TimezoneSelect';
import ActivationCodeImage from './activation-code.svg';
import Link from '@raydiant/raydial/components/Link';
import { useDispatch } from 'react-redux';
import * as deviceActions from '../../../../actions/devices';
import { useToastState } from '@raydiant/raydial/components/Toast/ToastProvider';
import { useGetPendingLocations } from '../../../../hooks';
import { useHistory } from 'react-router-dom';
import * as paths from '../../../../routes/paths';

interface DeviceRegisterProps {
  location: Location | null;
  defaultCode?: string | null;
  onRegister: () => void;
  cancelButtonText?: string;
  onCancel: () => void;
}

const defaulTimeZone = moment.tz.guess();

const validateActivationCode = (activationCode: string | null) =>
  activationCode && !!activationCode.match(/^[A-Z0-9]+-[A-Z0-9]+$/);

const RegisterDevice = ({
  location,
  defaultCode = null,
  cancelButtonText,
  onRegister,
  onCancel,
}: DeviceRegisterProps) => {
  const dispatch = useDispatch();
  const state = useToastState();
  const history = useHistory();
  const { refetch: refetchPendingLocations } = useGetPendingLocations();

  // State

  const [activationCode, setActivationCode] = useState<string | null>(
    defaultCode,
  );
  const [name, setName] = useState<string | null>(null);
  const [registrationStatus, setRegistrationStatus] = useState<
    '' | 'pending' | 'success' | 'failed'
  >('');
  const [errorCode, setErrorCode] = useState<number | null>(null);
  const [timezone, setTimezone] = useState<string | null>(defaulTimeZone);

  // Callbacks

  const registerDevice = useCallback(() => {
    if (!activationCode) return;

    setRegistrationStatus('pending');

    dispatch(
      deviceActions.registerDevice(
        {
          activationCode,
          name: name ?? '',
          timezone: timezone ?? '',
          isAudioOnly: false,
          locationId: location?.id ?? '',
        },
        {
          onRegister: () => {
            setRegistrationStatus('success');
            setName('');
            setActivationCode('');
            setTimezone(defaulTimeZone);
            state.add({
              title: 'Screen Added',
              description: (
                <div>
                  Screen added under the{' '}
                  <Link
                    className="outline-none underline"
                    onClick={() =>
                      history.push({
                        pathname: paths.screens(),
                        search: `location_ids=${location?.id}`,
                      })
                    }
                  >
                    {location?.name}
                  </Link>
                </div>
              ),
            });
            refetchPendingLocations();
            if (onRegister) {
              onRegister();
            }
          },
          onError: (error) => {
            setRegistrationStatus('failed');
            if (error instanceof ApiClientError) {
              setErrorCode(error.status ?? null);
            }
          },
        },
      ),
    );
  }, [
    dispatch,
    activationCode,
    name,
    timezone,
    location?.id,
    location?.name,
    state,
    history,
    setRegistrationStatus,
    refetchPendingLocations,
    onRegister,
  ]);

  let helperText: React.ReactNode = '';
  if (registrationStatus === 'failed') {
    if (errorCode === 403) {
      helperText = (
        <>
          Sorry, you have reached your limit on ScreenRay licenses. Please
          contact{' '}
          <Link href="mailto:support@raydiant.com" target="_blank">
            support@raydiant.com
          </Link>
          for further assistance.
        </>
      );
    } else {
      helperText = `Sorry, we couldn't activate your ScreenRay. Please double check your Activation Code and try again.`;
    }
  }

  const handleActivationCode = (value: string) => {
    const regex = /[a-zA-Z0-9\-\s]/;
    const validCharacters = value
      .split('')
      .map((letter) => (regex.test(letter) ? letter : ''))
      .join('');
    const newValue = validCharacters.toUpperCase().replace(' ', '-');
    setActivationCode(newValue);
  };

  return (
    <form
      className="text-black"
      onSubmit={(e) => {
        e.preventDefault();
        registerDevice();
      }}
    >
      <div className="flex flex-col gap-2">
        <div className="text-center mb-3">
          <Text variant="body" className="text-center">
            Enter the two word Activation Code on your TV screen
          </Text>
        </div>
        <img src={ActivationCodeImage} alt="Activation Code" />
        <TextField
          label=""
          placeholder="Activation Code"
          autoFocus={!defaultCode}
          value={activationCode ?? ''}
          onChange={(value) => {
            handleActivationCode(value);
          }}
        />
        {helperText && <Text>{helperText}</Text>}

        <TextField
          label=""
          placeholder="Name"
          autoFocus={!!defaultCode}
          value={name ?? ''}
          onChange={setName}
        />

        <TimezoneSelect
          label=""
          value={timezone ?? ''}
          onChange={setTimezone}
        />
      </div>

      <div className="flex flex-col-reverse justify-between mt-8 mb-4 gap-3 px-0 md:gap-6 md:flex-row md:px-4">
        <Button
          className="grow"
          size="large"
          variant="secondary"
          onClick={() => onCancel()}
        >
          {cancelButtonText || 'Cancel'}
        </Button>
        <Button
          className="grow"
          size="large"
          isDisabled={
            !validateActivationCode(activationCode) ||
            registrationStatus === 'pending' ||
            !timezone
          }
          type="submit"
        >
          Add Screen
        </Button>
      </div>
    </form>
  );
};

export default RegisterDevice;
