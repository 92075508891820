import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import { Hidden } from 'raydiant-elements/layout/Hidden/Hidden';

interface ImageContentCardProps {
  className?: string;
  imageSrc: string;
  imageAlt?: string;
  imageDirectionOnDesktop: 'left' | 'right';
  imageDirectionOnMobile: 'top' | 'bottom';
  children?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    text: {
      paddingLeft: theme.spacing(5),
    },
  }),
);

const ImageContentCard = ({
  className,
  imageSrc,
  imageAlt,
  imageDirectionOnDesktop,
  imageDirectionOnMobile,
  children,
}: ImageContentCardProps) => {
  const classes = useStyles();

  const renderImage = () => {
    return (
      <Grid item xs={12} md={6} container>
        <Grid container>
          <img className={classes.image} src={imageSrc} alt={imageAlt} />
        </Grid>
      </Grid>
    );
  };

  const renderDesktopContent = () => {
    if (children) {
      return (
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          className={className}
        >
          {imageDirectionOnDesktop === 'left' && renderImage()}
          <Grid item xs={12} md={6}>
            {children}
          </Grid>
          {imageDirectionOnDesktop === 'right' && renderImage()}
        </Grid>
      );
    }
  };

  const renderMobileContent = () => {
    if (children) {
      return (
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          className={className}
        >
          {imageDirectionOnMobile === 'top' && renderImage()}
          <Grid item xs={12} md={6}>
            {children}
          </Grid>
          {imageDirectionOnMobile === 'bottom' && renderImage()}
        </Grid>
      );
    }
  };

  return (
    <>
      <Hidden xsDown>{renderDesktopContent()}</Hidden>
      <Hidden smUp>{renderMobileContent()}</Hidden>
    </>
  );
};

export default ImageContentCard;
