import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

export default makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      padding: theme.spacing(2, 4),
      marginBottom: theme.spacing(0.5),
      transition: 'none', // Prevent box-shadow animation on select.
      borderRadius: theme.spacing(0.5),
      height: theme.spacing(11),
      minHeight: theme.spacing(11),
      border: '1px solid #c3c3c3',
      color: 'rgba(53, 53, 53, 0.6)',

      '&:last-child': {
        marginBottom: 0,
      },

      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1, 2),
      },
    },
    connectionStatus: {
      marginLeft: theme.spacing(1),
    },
  });
});
