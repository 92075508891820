import { useTranslation } from 'react-i18next';
import TotalCard from './TotalCard';
import { GetAnalytics } from '@raydiant/api-client-js';
import {
  getDailyAverageInteractions,
  getTotalInteractions,
  getPercentChange,
  getTotalSessions,
  getDailyAverageSessions,
  getInteractionConversions,
  getAverageDistibution,
} from './utils';
import AveragePerDay from './AveragePerDayCard';
import ConversionsCard from './ConversionsCard';
import AveragePerDayCard from './AveragePerDayCard';
import AveragePerWeekCard from './AveragePerWeekCard';
import AverageDistributionCard from './AverageDistributionCard';
import { Popover } from '@headlessui/react';
import QuestionMarkIcon from '../../../components/QuestionMarkIcon';
import PopoverButton from '../../../components/Popover/PopoverButton';

type ShopperEngagementInsightsProps = {
  data: GetAnalytics;
  showEmpty?: boolean;
  isRXEnabled: boolean;
  isAllTime: boolean;
};

const ShopperEngagementInsights = ({
  data,
  showEmpty = false,
  isRXEnabled,
  isAllTime = false,
}: ShopperEngagementInsightsProps) => {
  const { t } = useTranslation();

  const currentTotalInteractions = getTotalInteractions(
    data?.engagementByDay ?? [],
  );
  const previousTotalInteractions = getTotalInteractions(
    data?.engagementByDayPreviousPeriod ?? [],
  );

  const currentDailyAverageInteractions = getDailyAverageInteractions(
    data?.engagementByDay ?? [],
  );
  const previousDailyAverageInteractions = getDailyAverageInteractions(
    data?.engagementByDayPreviousPeriod ?? [],
  );

  const currentWeeklyAverageInteractions = currentDailyAverageInteractions * 7;
  const previousWeeklyAverageInteractions =
    previousDailyAverageInteractions * 7;

  const currentTotalSessions = getTotalSessions(data?.engagementByDay ?? []);
  const previousTotalSessions = getTotalSessions(
    data?.engagementByDayPreviousPeriod ?? [],
  );

  const currentDailyAverageSessions = getDailyAverageSessions(
    data?.engagementByDay ?? [],
  );
  const previousDailyAverageSessions = getDailyAverageSessions(
    data?.engagementByDayPreviousPeriod ?? [],
  );

  const currentWeeklyAverageSessions = currentDailyAverageSessions * 7;
  const previousWeeklyAverageSessions = previousDailyAverageSessions * 7;

  const currentInteractionConversions = getInteractionConversions(
    data?.engagementByDay ?? [],
  );
  const previousInteractionConversions = getInteractionConversions(
    data?.engagementByDayPreviousPeriod ?? [],
  );

  const currentAverageDistribution = getAverageDistibution(
    data?.engagementByDay ?? [],
  );

  return (
    <div className="mb-8">
      <div className="flex gap-3 items-center mb-6 mx-6">
        <h2 className="text-black text-xl font-bold">
          {t('analytics.shopperEngagementOverview')}
        </h2>
        <Popover className="relative">
          <PopoverButton icon={<QuestionMarkIcon />} />
          <Popover.Panel className="absolute z-10 card popover">
            <div className="overflow-hidden">
              <p className="mb-3">
                {t('analytics.productEngagementPage.overview.tooltip.default')}
              </p>
              <a
                href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                rel="noreferrer"
                target="_blank"
              >
                {t('analytics.learnmore')}
              </a>
            </div>
          </Popover.Panel>
        </Popover>
      </div>
      <div className="insights touch-pan-x">
        <TotalCard
          change={getPercentChange(
            currentTotalInteractions,
            previousTotalInteractions,
          )}
          key="totalInteractions"
          type="interactions"
          showEmpty={showEmpty}
          value={currentTotalInteractions}
          noPreviousData={isAllTime || previousTotalInteractions === 0}
          isRXEnabled={isRXEnabled}
        />
        <AveragePerWeekCard
          change={getPercentChange(
            currentWeeklyAverageInteractions,
            previousWeeklyAverageInteractions,
          )}
          key="averageInteractionsPerWeek"
          type="interactions"
          showEmpty={showEmpty}
          value={currentWeeklyAverageInteractions}
          noPreviousData={isAllTime || previousWeeklyAverageInteractions === 0}
          isRXEnabled={isRXEnabled}
        />
        <AveragePerDayCard
          change={getPercentChange(
            currentDailyAverageInteractions,
            previousDailyAverageInteractions,
          )}
          key="averageInteractionsPerDay"
          type="interactions"
          showEmpty={showEmpty}
          value={currentDailyAverageInteractions}
          noPreviousData={isAllTime || previousDailyAverageInteractions === 0}
          isRXEnabled={isRXEnabled}
        />
        {isRXEnabled && (
          <>
            <ConversionsCard
              change={getPercentChange(
                currentInteractionConversions,
                previousInteractionConversions,
              )}
              key="conversions"
              type="interactions"
              showEmpty={showEmpty}
              noPreviousData={isAllTime || previousInteractionConversions === 0}
              value={currentInteractionConversions}
            />
            <AverageDistributionCard
              type="interactions"
              key="averageDistribution"
              showEmpty={showEmpty}
              value={currentAverageDistribution}
            />
          </>
        )}
        <TotalCard
          change={getPercentChange(currentTotalSessions, previousTotalSessions)}
          key="totalSessions"
          type="sessions"
          showEmpty={showEmpty}
          value={currentTotalSessions}
          noPreviousData={isAllTime || previousTotalSessions === 0}
        />
        <AveragePerWeekCard
          change={getPercentChange(
            currentWeeklyAverageSessions,
            previousWeeklyAverageSessions,
          )}
          key="averageSessionsPerWeek"
          type="sessions"
          showEmpty={showEmpty}
          value={currentWeeklyAverageSessions}
          noPreviousData={isAllTime || previousWeeklyAverageSessions === 0}
        />
        <AveragePerDay
          change={getPercentChange(
            currentDailyAverageSessions,
            previousDailyAverageSessions,
          )}
          key="averageSessionsPerDay"
          type="sessions"
          showEmpty={showEmpty}
          value={currentDailyAverageSessions}
          noPreviousData={isAllTime || previousDailyAverageSessions === 0}
        />
      </div>
    </div>
  );
};

export default ShopperEngagementInsights;
