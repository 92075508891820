import { useTranslation } from 'react-i18next';
import defaultIcon from '../../assets/error.svg';

type ErrorProps = {
  children?: React.ReactNode;
  message?: string;
  title?: string;
};

const Error = ({ children, message, title }: ErrorProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col md:flex-row gap-4 md:gap-14 items-center md:max-w-lg h-full justify-center mx-auto">
      <div className="w-full flex items-center justify-center md:justify-start">
        <img className={'w-1/2 md:w-full'} src={defaultIcon} alt={title} />
      </div>
      <div className="flex flex-col gap-4">
        <h1 className="text-xl font-bold">{title ?? t('error.oops')}</h1>
        <p className="text-sm">{message ?? t('error.somethingsWrong')}</p>
        {children && <div>{children}</div>}
      </div>
    </div>
  );
};

export default Error;
