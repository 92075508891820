import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Center from 'raydiant-elements/layout/Center';
import CircularProgress from 'raydiant-elements/core/CircularProgress';

import { getContentDetailApiQueryParams } from '../AnalyticsFilter/utils';
import useContentDetail from '../../../hooks/useContentDetail';
import { selectUserProfile } from '../../../selectors/user';
import Page from '../../../components/Page';
import PageLayout from '../../../components/PageLayout/PageLayout';
import PageTitle from '../../../components/PageLayout/PageTitle';
import AnalyticsFilter from '../AnalyticsFilter/AnalyticsFilter';
import AnalyticsPageSkeleton from '../AnalyticsPageSkeleton';
import Empty from '../../../components/Empty/Empty';
import Error from '../../../components/Error/Error';

export default function ContentDetailPage() {
  const { t } = useTranslation();
  const { search } = useLocation();
  const query = getContentDetailApiQueryParams(search);
  const hasQueryParams = Object.values(query).length > 0;

  const { data, isError, isLoading } = useContentDetail(query);
  const currentUser = useSelector(selectUserProfile);

  const showEmptyState = !isLoading && !data;
  const showErrorState = !isLoading && isError;
  const showSkeleton = isLoading && !hasQueryParams;
  const showFilter = hasQueryParams;
  const showLoader = isLoading && hasQueryParams && !isError;

  if (!currentUser) {
    return (
      <Page>
        <PageLayout
          sectionClassName="min-h-full"
          title={
            <PageTitle
              title={t('analytics.overview')}
              subTitle={t('analytics.pageSubTitle')!}
            />
          }
        >
          <AnalyticsFilter className="mb-8" />
          <Center className="h-[500px]">
            <CircularProgress size={30}></CircularProgress>
          </Center>
        </PageLayout>
      </Page>
    );
  }

  return (
    <Page>
      <PageLayout
        sectionClassName="min-h-full"
        title={
          <PageTitle
            title={t('analytics.contentDetailPage.title')}
            subTitle={t('analytics.contentDetailPage.subTitle')!}
          />
        }
      >
        {showSkeleton && <AnalyticsPageSkeleton />}
        {showFilter && <AnalyticsFilter className="mb-8" />}
        {showLoader && (
          <Center className="h-[500px]">
            <CircularProgress size={30}></CircularProgress>
          </Center>
        )}
        {showEmptyState && (
          <div className="card bg-[#fafafa] w-full h-full items-center justify-center">
            <Empty
              message="There are no results matching your search or filters. Please adjust it and try again."
              title={t('noResults')!}
            >
              <a
                href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                rel="noreferrer"
                target="_blank"
              >
                {t('analytics.learnmore')}
              </a>
            </Empty>
          </div>
        )}
        {showErrorState && (
          <div className="card bg-[#fafafa] w-full h-full items-center justify-center">
            <Error>
              <a
                href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                rel="noreferrer"
                target="_blank"
              >
                {t('analytics.learnmore')}
              </a>
            </Error>
          </div>
        )}
        {data && !isError && <>Content Detail</>}
      </PageLayout>
    </Page>
  );
}
