import moment from 'moment';
import { chunk, orderBy } from 'lodash';
import { TableSortDirection } from '../../components/DataTable/DataTableHeader';
import { PAGE_SIZE } from '../../components/Pagination/Pagination';
import { abbreviateNumber } from 'js-abbreviation-number';
import { ContentEngagementByPresentation } from '@raydiant/api-client-js';
import { getDisplayValue } from './ShopperEngagementInsights/utils';

export const formatDateLabel = (date: string, format = 'ddd D') =>
  moment(date).format(format).toString();

export const CHART_DEFAULT_SERIES = {
  bar: {
    barGap: 0,
    emphasis: {
      focus: 'series',
    },
    type: 'bar',
  },
  line: {
    emphasis: {
      focus: 'series',
    },
    lineStyle: {
      width: 1,
    },
    showSymbol: false,
    smooth: false,
    stack: 'Total',
    type: 'line',
  },
};

export const CHART_DEFAULT_OPTIONS = {
  color: ['#0530a6', '#4270eb', '#08d4b8', '#879cff'],
  grid: {
    containLabel: true,
    show: true,
    left: 30,
    borderWidth: 0,
    bottom: 30,
    top: 30,
    right: 30,
  },
  legend: {
    itemGap: 20,
    textStyle: {
      fontFamily: 'Gellix,sans-serif',
      fontSize: 14,
      fontWeight: 600,
      overflow: 'truncate',
    },
  },
  tooltip: {
    trigger: 'axis',
    borderWidth: 1,
    padding: 0,
  },
};

export const CHART_DEFAULT_AXIS_OPTIONS = {
  xAxis: {
    axisLabel: {
      color: '#343437',
      fontFamily: 'Gellix,sans-serif',
      fontSize: 14,
      height: 50,
      hideOverlap: true,
      interval: 0,
      lineHeight: 24,
      overflow: 'truncate',
      width: 100,
      showFirstLabel: true,
      showLastLabel: true,
    },
    min: 0,
    nameLocation: 'middle',
    nameGap: 40,
    nameTextStyle: {
      color: '#343437',
      fontFamily: 'Gellix,sans-serif',
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 24,
      height: 100,
    },
    scale: true,
    show: true,
    type: 'category',
    axisTick: {
      alignWithLabel: true,
    },
  },
  yAxis: {
    axisLabel: {
      color: '#343437',
      fontFamily: 'Gellix,sans-serif',
      fontSize: 10,
      height: 40,
      margin: 8,
      lineHeight: 24,
      formatter: (value: string) => abbreviateNumber(Number(value), 0),
    },
    min: 0,
    nameLocation: 'middle',
    nameGap: 30,
    nameTextStyle: {
      color: '#343437',
      fontFamily: 'Gellix,sans-serif',
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 24,
      height: 100,
    },
    scale: true,
    show: true,
    type: 'value',
  },
};

export const sort = <T>(
  data: T[],
  key: keyof T,
  order: TableSortDirection = 'asc',
) => {
  const dataWithValue = data?.filter((item) => item[key]) || [];
  const dataWithNull = data?.filter((item) => !item[key]) || [];
  const sortedDataWithValue = orderBy<T>(dataWithValue, [key], [order]);

  return order === 'asc'
    ? [...dataWithNull, ...sortedDataWithValue]
    : [...sortedDataWithValue, ...dataWithNull];
};

export const paginate = <T>(data: any[], size: number = PAGE_SIZE) => {
  return chunk<T>(data, size);
};

export const getTotalDistributionTime = (
  data: ContentEngagementByPresentation[],
) => {
  let totalDistributionTime = 0;

  data.forEach(
    (presentation) => (totalDistributionTime += presentation.playbackDuration),
  );

  return getDisplayValue(Math.round(totalDistributionTime / 60));
};
