import SelectField from 'raydiant-elements/core/SelectField';
import Switch from 'raydiant-elements/core/Switch';
import TextField from 'raydiant-elements/core/TextField/TextField';
import useStyles from '../DeviceAISettings.styles';
import DeviceAiSettingsGroup from '../DeviceAiSettingsGroup';

interface DeviceAiSettingsVideoFeedData {
  blurFaces?: boolean;
  drawResult?: boolean;
  flipHorizontally?: boolean;
  flipVertically?: boolean;
  instanceName?: string;
  loopVideo?: boolean;
  processAllFrames?: boolean;
  resolutionHeight?: string;
  resolutionWidth?: string;
  rotateMode?: string;
  sourceType?: string;
  videoFilePath?: string;
  videoStreamUrl?: string;
  webcamIndex?: number;
}

interface DeviceAiSettingsVideoFeedProps {
  data: DeviceAiSettingsVideoFeedData;
  onChangeBlurFaces?: (value: boolean) => void;
  onChangeDrawResult?: (value: boolean) => void;
  onChangeFlipHorizontally: (value: boolean) => void;
  onChangeFlipVertically?: (value: boolean) => void;
  onChangeInstanceName?: (value: string) => void;
  onChangeLoopVideo?: (value: boolean) => void;
  onChangeProcessAllFrames?: (value: boolean) => void;
  onChangeResolutionHeight?: (value: string) => void;
  onChangeResolutionWidth?: (value: string) => void;
  onChangeRotateMode?: (value: string) => void;
  onChangeSourceType?: (value: string) => void;
  onChangeVideoFilePath?: (value: string) => void;
  onChangeVideoStreamUrl?: (value: string) => void;
  onChangeWebcamIndex?: (value: string) => void;
  showBlurFaces?: boolean;
  showDrawResult?: boolean;
  showFlipHorizontally?: boolean;
  showFlipVeritcally?: boolean;
  showInstanceName?: boolean;
  showLoopVideo?: boolean;
  showProcessAllFrames?: boolean;
  showResolutionHeight?: boolean;
  showResolutionWidth?: boolean;
  showRotateMode?: boolean;
  showSourceType?: boolean;
  showVideoFilePath?: boolean;
  showVideoStreamUrl?: boolean;
  showWebcamIndex?: boolean;
  rotateModeOptions?: string[];
  sourceTypeOptions?: string[];
}

const DeviceAiSettingsVideoFeed = ({
  data,
  onChangeBlurFaces,
  onChangeDrawResult,
  onChangeFlipHorizontally,
  onChangeFlipVertically,
  onChangeInstanceName,
  onChangeLoopVideo,
  onChangeProcessAllFrames,
  onChangeResolutionHeight,
  onChangeResolutionWidth,
  onChangeRotateMode,
  onChangeSourceType,
  onChangeVideoFilePath,
  onChangeVideoStreamUrl,
  onChangeWebcamIndex,
  showBlurFaces = false,
  showDrawResult = false,
  showFlipHorizontally = false,
  showFlipVeritcally = false,
  showInstanceName = false,
  showLoopVideo = false,
  showProcessAllFrames = false,
  showResolutionHeight = false,
  showResolutionWidth = false,
  showRotateMode = false,
  showSourceType = false,
  showVideoFilePath = false,
  showVideoStreamUrl = false,
  showWebcamIndex = false,
  rotateModeOptions,
  sourceTypeOptions,
}: DeviceAiSettingsVideoFeedProps) => {
  const classes = useStyles();

  return (
    <DeviceAiSettingsGroup title="Video feed settings">
      {showBlurFaces && (
        <li className={classes.aiSetting} key={'blurFaces'}>
          <Switch
            checked={data.blurFaces || false}
            onChange={onChangeBlurFaces}
            label={'Blur faces'}
          />
        </li>
      )}
      {showDrawResult && (
        <li className={classes.aiSetting} key={'drawResult'}>
          <Switch
            checked={data.drawResult || false}
            onChange={onChangeDrawResult}
            label={'Draw result'}
          />
        </li>
      )}
      {showFlipHorizontally && (
        <li className={classes.aiSetting} key={'flipHorizontally'}>
          <Switch
            checked={data.flipHorizontally || false}
            onChange={onChangeFlipHorizontally}
            label={'Flip horizontally'}
          />
        </li>
      )}
      {showFlipVeritcally && (
        <li className={classes.aiSetting} key={'flipVertically'}>
          <Switch
            checked={data.flipVertically || false}
            onChange={onChangeFlipVertically}
            label={'Flip vertically'}
          />
        </li>
      )}
      {showInstanceName && (
        <li className={classes.aiSetting} key={'instanceName'}>
          <TextField
            label={'Instance name'}
            value={data.instanceName}
            onChange={onChangeInstanceName}
          />
        </li>
      )}
      {showLoopVideo && (
        <li className={classes.aiSetting} key={'loopVideo'}>
          <Switch
            checked={data.loopVideo || false}
            onChange={onChangeLoopVideo}
            label={'loop video'}
          />
        </li>
      )}
      {showProcessAllFrames && (
        <li className={classes.aiSetting} key={'processAllFrames'}>
          <Switch
            checked={data.processAllFrames || false}
            onChange={onChangeProcessAllFrames}
            label={'Process all frames'}
          />
        </li>
      )}
      {showResolutionWidth && (
        <li className={classes.aiSetting} key={'resolutionWidth'}>
          <TextField
            label={'Resolution width'}
            value={data.resolutionWidth}
            onChange={onChangeResolutionWidth}
          />
        </li>
      )}
      {showResolutionHeight && (
        <li className={classes.aiSetting} key={'resolutionHeight'}>
          <TextField
            label={'Resolution height'}
            value={data.resolutionHeight}
            onChange={onChangeResolutionHeight}
          />
        </li>
      )}
      {showRotateMode && (
        <li className={classes.aiSetting} key={'rotateMode'}>
          <SelectField
            label={'Rotate mode'}
            value={data.rotateMode}
            onChange={onChangeRotateMode}
          >
            {rotateModeOptions?.map((name, index) => (
              <option value={name} key={index}>
                {name.replaceAll('_', ' ')}
              </option>
            ))}
          </SelectField>
        </li>
      )}
      {showSourceType && (
        <li className={classes.aiSetting} key={'sourceType'}>
          <SelectField
            label={'Source type'}
            value={data.sourceType}
            onChange={onChangeSourceType}
          >
            {sourceTypeOptions?.map((name, index) => (
              <option value={name} key={index}>
                {name.replaceAll('_', ' ')}
              </option>
            ))}
          </SelectField>
        </li>
      )}
      {showVideoFilePath && (
        <li className={classes.aiSetting} key={'videoFilePath'}>
          <TextField
            label={'Video file path'}
            value={data.videoFilePath}
            onChange={onChangeVideoFilePath}
          />
        </li>
      )}
      {showVideoStreamUrl && (
        <li className={classes.aiSetting} key={'videoStreamUrl'}>
          <TextField
            label={'Video stream url'}
            value={data.videoStreamUrl}
            onChange={onChangeVideoStreamUrl}
          />
        </li>
      )}
      {showWebcamIndex && (
        <li className={classes.aiSetting} key={'webcamIndex'}>
          <TextField
            label={'Webcam index'}
            value={data.webcamIndex?.toString()}
            onChange={onChangeWebcamIndex}
          />
        </li>
      )}
    </DeviceAiSettingsGroup>
  );
};

export default DeviceAiSettingsVideoFeed;
