import TimeInterval from '../TimeInterval';

type CardCardProps = {
  classNames?: string;
  children: React.ReactNode;
  showTimeInterval?: boolean;
  subTitle?: string;
  title: React.ReactNode;
};

const ChartCard = ({
  children,
  showTimeInterval = true,
  subTitle,
  title,
}: CardCardProps) => {
  return (
    <div className="card chart p-3 md:p-9">
      <div className="flex justify-between p-6 md:p-0">
        <div className="mb-6 w-full">
          {title}
          <p className="text-gray-400">{subTitle}</p>
        </div>
        {showTimeInterval && <TimeInterval />}
      </div>
      <div className="grid grid-cols-1 gap-3 w-full">{children}</div>
    </div>
  );
};

export default ChartCard;
