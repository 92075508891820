import { BarChart } from 'echarts/charts';
import ChartCard from '../ChartCard/ChartCard';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { CHART_DEFAULT_AXIS_OPTIONS, CHART_DEFAULT_OPTIONS } from '../utils';
import { useTranslation } from 'react-i18next';
import Hidden from 'raydiant-elements/layout/Hidden';
import { TrafficSummary } from '@raydiant/api-client-js';
import { ageGroups, getDemographicBreakdownData } from './utils';
import { Popover } from '@headlessui/react';
import QuestionMarkIcon from '../../../components/QuestionMarkIcon';
import PopoverButton from '../../../components/Popover/PopoverButton';
import ChartToolTip, { EChartsToolTipParams } from '../ChartToolTip';
import { renderToStaticMarkup } from 'react-dom/server';

echarts.use([BarChart]);

interface DemographicBreakdownChartProps {
  data: TrafficSummary;
}

const getTotal = (value: number[]) =>
  value
    .filter((item) => item)
    .reduce((prev, next) => {
      return prev + next;
    }, 0);

const DemographicBreakdownChart = ({
  data,
}: DemographicBreakdownChartProps) => {
  const { t } = useTranslation();

  const { male, female } = getDemographicBreakdownData(data);

  const maleData = male.map((value) =>
    ((value / data.otsFace) * 100).toFixed(2),
  );
  const femaleData = female.map((value) =>
    ((value / data.otsFace) * 100).toFixed(2),
  );
  const maleTotal = getTotal(male) || 0;
  const femaleTotal = getTotal(female) || 0;
  const noValue = maleTotal <= 0 && femaleTotal <= 0;
  const malePercentage = (
    (maleTotal / (maleTotal + femaleTotal)) *
    100
  ).toFixed(2);
  const femalePercentage = (100 - Number(malePercentage)).toFixed(2);

  const options = {
    ...CHART_DEFAULT_OPTIONS,
    toolbox: {
      feature: {
        saveAsImage: { show: true },
      },
    },
    title: {
      text: '',
    },
    tooltip: {
      ...CHART_DEFAULT_OPTIONS.tooltip,
      formatter: (params: EChartsToolTipParams[]) => {
        const modifiedParams: EChartsToolTipParams[] = params.map((item) => ({
          ...item,
          value: `${item.value}%`,
        }));

        return renderToStaticMarkup(
          <ChartToolTip
            header={
              <p className="font-bold text-sm px-2 py-1 text-black">
                {params[0].axisValueLabel}
              </p>
            }
            values={modifiedParams}
          />,
        );
      },
    },
    legend: {
      ...CHART_DEFAULT_OPTIONS.legend,
      icon: 'square',
    },
    color: ['#7AD1F4', '#487FB1'],
    xAxis: {
      ...CHART_DEFAULT_AXIS_OPTIONS.xAxis,
      type: 'value',
      name: 'percentage'.toUpperCase(),
      axisLabel: {
        ...CHART_DEFAULT_AXIS_OPTIONS.xAxis.axisLabel,
        formatter: (value: string) => `${value}%`,
      },
    },
    yAxis: {
      ...CHART_DEFAULT_AXIS_OPTIONS.yAxis,
      type: 'category',
      data: ageGroups,
      name: 'age groups'.toUpperCase(),
      axisLabel: {
        ...CHART_DEFAULT_AXIS_OPTIONS.yAxis.axisLabel,
        alignTicks: true,
        formatter: (value: string) => value,
      },
    },
    series: [
      {
        name: `Male (${noValue ? '0' : malePercentage}%)`,
        type: 'bar',
        emphasis: {
          focus: 'series',
        },
        data: maleData,
      },
      {
        name: `Female (${noValue ? '0' : femalePercentage}%)`,
        type: 'bar',
        emphasis: {
          focus: 'series',
        },
        data: femaleData,
      },
    ],
  };

  const mobileOptions = {
    ...options,
    legend: {
      ...CHART_DEFAULT_OPTIONS.legend,
      type: 'scroll',
    },
  };

  return (
    <ChartCard
      subTitle={''}
      title={
        <div className="flex gap-3 items-center">
          <h2 className="text-black text-xl font-bold">
            {t('analytics.trafficAwarenessPage.demographicBreakdown.title')!}
          </h2>
          <Popover className="relative">
            <PopoverButton icon={<QuestionMarkIcon />} />
            <Popover.Panel className="absolute z-10 card popover">
              <div className="overflow-hidden">
                <p className="mb-3">
                  {t(
                    'analytics.trafficAwarenessPage.demographicBreakdown.tooltip',
                  )}
                </p>
                <a
                  href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('analytics.learnmore')}
                </a>
              </div>
            </Popover.Panel>
          </Popover>
        </div>
      }
      showTimeInterval={false}
    >
      <div className="min-w-full min-h-full">
        <Hidden xsDown>
          <ReactECharts
            style={{ height: '500px' }}
            option={options}
            onChartReady={(chart) => chart.resize()}
          />
        </Hidden>
        <Hidden smUp>
          <ReactECharts
            style={{ height: '500px' }}
            option={mobileOptions}
            onChartReady={(chart) => chart.resize()}
          />
        </Hidden>
      </div>
    </ChartCard>
  );
};

export default DemographicBreakdownChart;
