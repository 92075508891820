import classNames from 'classnames';
import SidebarFooter from './SidebarFooter';
import SidebarHeader from './SidebarHeader';
import SidebarMenu from './SidebarMenu';

type SidebarProps = {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  onTopMenuClick: (value: boolean) => void;
};

const Sidebar = ({
  className,
  isOpen,
  onClose,
  onTopMenuClick,
}: SidebarProps) => {
  return (
    <aside className={classNames('sidebar', isOpen ? 'open' : '', className)}>
      <div className="wrapper">
        <div>
          <SidebarHeader isOpen={isOpen} onClose={onClose} />
          <SidebarMenu isOpen={isOpen} onTopMenuClick={onTopMenuClick} />
        </div>
        <SidebarFooter isOpen={isOpen} />
      </div>
    </aside>
  );
};

export default Sidebar;
