import { FC } from 'react';
import moment from 'moment-timezone';
import Select, { SelectItem } from '@raydiant/raydial/components/Select';

export interface TimezoneSelectProps {
  className?: string;
  label: string;
  onChange: (value: string) => void;
  value: string;
  batch?: boolean;
  selectedTimezones?: string[];
}

export const getFormattedTimezoneNames = () => {
  const getOffset = (tz: string) => moment.tz(tz).format('Z');
  const byOffset = (a: string, b: string) =>
    parseInt(getOffset(a), 10) > parseInt(getOffset(b), 10) ? 1 : -1;

  return moment.tz
    .names()
    .filter(getOffset)
    .sort(byOffset)
    .map((tz) => ({ value: tz, label: `(GMT${getOffset(tz)}) ${tz}` }));
};

const TimezoneSelect: FC<TimezoneSelectProps> = ({
  label,
  onChange,
  value,
  batch,
}) => {
  return (
    <Select
      label={label}
      selectedKey={value}
      onSelectionChange={(key) => onChange(key as string)}
    >
      {batch && (
        <SelectItem id="" key="">
          Select to apply
        </SelectItem>
      )}
      {getFormattedTimezoneNames().map(({ value, label }) => (
        <SelectItem key={value} id={value}>
          {label}
        </SelectItem>
      ))}
    </Select>
  );
};

export default TimezoneSelect;
