import { createSelector } from 'reselect';
import { RootState } from '../../reducers';
import * as PL from '../../clients/mira/types/Playlist';
import { isNotNullOrUndefined, groupResourcesByOwner } from '../../utilities';
import { LocalFileUploads } from '../../types';
import {
  selectFileStatus as selectPresentationFileStatus,
  PresentationsById,
} from './presentations';

export interface PlaylistsById {
  [playlistId: string]: PL.Playlist | undefined;
}

export const selectPlaylistsById = (state: RootState): PlaylistsById =>
  state.v2Playlists.byId;

export const selectPlaylists = createSelector(
  [selectPlaylistsById],
  (playlistById): PL.Playlist[] => {
    return Object.values(playlistById).filter(isNotNullOrUndefined);
  },
);

export const selectNonScheduledPlaylists = createSelector(
  [selectPlaylists],
  (playlists): PL.Playlist[] => {
    return playlists.filter((pl) => !pl.startDatetime);
  },
);

export const selectFileStatus = (
  playlist: PL.Playlist,
  presentationsById: PresentationsById,
  localUploads: LocalFileUploads,
) => {
  let isUploading = false;
  let hasError = false;

  for (const { presentationId } of playlist.items) {
    if (!presentationId) continue;

    const presentation = presentationsById[presentationId];
    if (!presentation) continue;
    if (presentation.resource.deletedAt !== null) continue;

    const fileStatus = selectPresentationFileStatus(presentation, localUploads);

    isUploading = isUploading || fileStatus.isUploading;
    hasError = hasError || fileStatus.hasError;
  }

  return { isUploading, hasError };
};

export const selectPresentationIds = (
  playlist: PL.Playlist,
  playlistsById: PlaylistsById,
) => {
  const presentationIds: string[] = [];

  playlist.items.forEach((item) => {
    if (item.presentationId) {
      presentationIds.push(item.presentationId);
    }

    if (item.playlistId) {
      const nestedPlaylist = playlistsById[item.playlistId];
      if (!nestedPlaylist) return;

      nestedPlaylist.items.forEach((nestedItem) => {
        if (nestedItem.presentationId) {
          presentationIds.push(nestedItem.presentationId);
        }
      });
    }
  });

  return presentationIds;
};

export const selectPlaylistsByOwner = createSelector(
  [selectPlaylistsById],
  (playlistsById) => {
    return groupResourcesByOwner({
      playlists: Object.values(playlistsById) as PL.Playlist[],
    });
  },
);

export const selectNonScheduledPlaylistsByOwner = createSelector(
  [selectNonScheduledPlaylists],
  (playlists) => {
    return groupResourcesByOwner({ playlists });
  },
);
