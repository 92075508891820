type ScreensFilterFooterActionProps = {
  label: React.ReactNode;
  onClick: () => void;
};

const ScreensFilterFooterAction = ({
  label,
  onClick,
}: ScreensFilterFooterActionProps) => {
  return (
    <p className="hover:text-blue-500 cursor-pointer" onClick={onClick}>
      {label}
    </p>
  );
};

export default ScreensFilterFooterAction;
