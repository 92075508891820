import { SVGProps } from 'react';

interface CircleCheckProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const CircleCheck = ({ size = 18, ...props }: CircleCheckProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 2.21875C6.50977 2.21875 4.25195 3.54688 2.99023 5.67188C1.76172 7.83008 1.76172 10.4531 2.99023 12.5781C4.25195 14.7363 6.50977 16.0312 9 16.0312C11.457 16.0312 13.7148 14.7363 14.9766 12.5781C16.2051 10.4531 16.2051 7.83008 14.9766 5.67188C13.7148 3.54688 11.457 2.21875 9 2.21875ZM9 17.625C5.94531 17.625 3.15625 16.0312 1.62891 13.375C0.101562 10.752 0.101562 7.53125 1.62891 4.875C3.15625 2.25195 5.94531 0.625 9 0.625C12.0215 0.625 14.8105 2.25195 16.3379 4.875C17.8652 7.53125 17.8652 10.752 16.3379 13.375C14.8105 16.0312 12.0215 17.625 9 17.625ZM12.752 7.56445L8.50195 11.8145C8.16992 12.1465 7.67188 12.1465 7.37305 11.8145L5.24805 9.68945C4.91602 9.39062 4.91602 8.89258 5.24805 8.59375C5.54688 8.26172 6.04492 8.26172 6.37695 8.59375L7.9375 10.1543L11.623 6.46875C11.9219 6.13672 12.4199 6.13672 12.752 6.46875C13.0508 6.76758 13.0508 7.26562 12.752 7.56445Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default CircleCheck;
