import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

export default makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      marginTop: theme.spacing(4),
    },
    contentCard: {
      backgroundColor: theme.palette.background.inset,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(3, 2),
      paddingBottom: theme.spacing(5),
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    buttonContainer: {
      paddingTop: theme.spacing(9),
    },
  });
});
