import { BatchDeviceStatus, Device, Profile } from '@raydiant/api-client-js';
import cn from 'classnames';
import EditIcon from './Icons/Edit';
import ListIcon from './Icons/List';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDeviceErrorsById } from '../../selectors/v2/devices';
import { selectPlaylistsById } from '../../selectors/v2/playlists';
import {
  canEditLocationDevice,
  canEditLocationPlaylist,
  getDeviceConnectionStatus,
  isResourceDeleted,
} from '../../utilities';
import PublishButton from './components/PublishButton';
import DeviceInfo from './DeviceInfo';
import getDeviceContentWarning from './getDeviceContentWarning';
import {
  selectStatusPopoverDeviceId,
  selectBatchMode,
  selectMoveScreenMode,
} from '../DevicesPage/selectors';
import { clearOpenStatusPopoverOnLoad } from '../DevicesPage/actions';
import Checkbox from 'raydiant-elements/core/Checkbox';
import HttpsIcon from '@material-ui/icons/Https';
import {
  getUserOwnedBatchActions,
  isOnlyPublishRemaining,
} from './deviceBatchSettingsUtils';
import DeviceBatchStatus from './DeviceBatchSettings/DeviceBatchStatus';
import { selectIsVIOnly } from '../../selectors/user';
import Text from '@raydiant/raydial/components/Text';
import SidebarMenuTooltip from '../../components/Sidebar/SidebarMenuTooltip';

const getDeviceStatusColor = (isEditable: boolean, isOnline: boolean) => {
  if (!isEditable && !isOnline) {
    return {
      backgroundColor: 'bg-gray-400',
      shadowColor: 'shadow-gray-400',
    };
  }
  if (!isEditable && isOnline) {
    return {
      backgroundColor: 'bg-blue-100',
      shadowColor: 'shadow-blue-100',
    };
  }
  if (isEditable && isOnline) {
    return {
      backgroundColor: 'bg-blue-600',
      shadowColor: 'shadow-blue-600',
    };
  }
  return {
    backgroundColor: 'bg-gray-600',
    shadowColor: 'shadow-gray-600',
  };
};

interface DeviceCardProps {
  device: Device;
  checked?: boolean;
  currentUser: Profile;
  lastLoadedDate: string;
  selected?: boolean;
  selectedPlaylist?: boolean;
  batchStatus?: BatchDeviceStatus[];
  onClick: () => void;
  onSettingsClick: () => void;
  onAiSettingsClick: () => void;
  onPerchSettingsClick: () => void;
  onCheckboxClick?: (device: Device) => void;
  onEditPlaylistClick: () => void;
  onSelectPlaylistClick: () => void;
  onPublishClick: () => void;
}

const DeviceCard = ({
  device,
  checked,
  currentUser,
  lastLoadedDate,
  selected,
  selectedPlaylist,
  batchStatus,
  onAiSettingsClick,
  onPerchSettingsClick,
  onSettingsClick,
  onCheckboxClick,
  onEditPlaylistClick,
  onSelectPlaylistClick,
  onPublishClick,
}: DeviceCardProps) => {
  const dispatch = useDispatch();

  // State

  const [openStatusPopover, setOpenStatusPopover] = useState(false);

  // Selectors

  const playlistsById = useSelector(selectPlaylistsById);
  const contentWarningDeviceId = useSelector(selectStatusPopoverDeviceId);
  const deviceErrorsById = useSelector(selectDeviceErrorsById);
  const isBatchMode = useSelector(selectBatchMode);
  const isMoveScreenMode = useSelector(selectMoveScreenMode);
  const isVIOnly = useSelector(selectIsVIOnly);

  const devicePlaylist = device.playlistId
    ? playlistsById[device.playlistId]
    : null;

  const deviceErrors = deviceErrorsById[device.id] || [];

  // Unset playlist if playlist was deleted.
  const playlist =
    devicePlaylist && !isResourceDeleted(devicePlaylist)
      ? devicePlaylist
      : null;

  const contentWarning =
    playlist && currentUser
      ? getDeviceContentWarning(device, playlist, currentUser)
      : null;

  const shouldOpenStatusPopoverOnLoad =
    contentWarningDeviceId && contentWarningDeviceId === device.id;

  const userOwnedBatchOperation = batchStatus?.filter(
    (batchDevice) => batchDevice.profileId === currentUser.id,
  );
  const showBatchOperationStatus =
    (userOwnedBatchOperation && userOwnedBatchOperation.length > 0) || false;
  const isBatchOperationInProgress =
    batchStatus &&
    batchStatus.length > 0 &&
    !isOnlyPublishRemaining(batchStatus)
      ? true
      : false;
  const userOwnedBatchDetails =
    userOwnedBatchOperation &&
    getUserOwnedBatchActions(userOwnedBatchOperation);

  // Effects

  // Open content warning on page load.
  useEffect(() => {
    if (shouldOpenStatusPopoverOnLoad) {
      // Only open the content warning popover if there is a warning.
      if (contentWarning) {
        setOpenStatusPopover(true);
      }
      // Clear the content warning on load even if there is no warning.
      dispatch(clearOpenStatusPopoverOnLoad());
    }
  }, [shouldOpenStatusPopoverOnLoad, contentWarning, dispatch]);

  // Render
  const { isOnline } = getDeviceConnectionStatus(device, lastLoadedDate);
  const isEditable = canEditLocationDevice(currentUser, device);
  const isEditablePlaylist =
    !!(device.playlistId && device.playlist) &&
    canEditLocationPlaylist(currentUser, device.playlist, device?.location);

  const showCheckbox = isBatchMode || isMoveScreenMode;

  return (
    <div className="flex justify-center items-center mb-0.5">
      {showCheckbox && (
        <div className="mr-4">
          {isEditable ? (
            <Checkbox
              checked={checked || false}
              onChange={
                onCheckboxClick ? () => onCheckboxClick(device) : () => {}
              }
            />
          ) : (
            <HttpsIcon />
          )}
        </div>
      )}
      <div
        className={cn(
          'tour-device',
          'w-full flex flex-col mb-1 transition-none relative bg-white',
          'md:flex-row md:h-[85px]',
          '[&:last-child]:mb-0',
          selected &&
            `shadow-[inset_0_0_0_2.5px] ${
              getDeviceStatusColor(isEditable, isOnline).shadowColor
            }`,
          'rounded-3xl',
        )}
      >
        {/* If we are opening the content warning on load then set focus to an invisible button
      so that the popover and the device are in view. A possible better solution could be to 
      preserve the scroll position while navigating but this needs more thought in order to solve
      for other areas in the Dashboard. */}
        {shouldOpenStatusPopoverOnLoad && (
          <button
            autoFocus
            style={{
              position: 'absolute',
              pointerEvents: 'none',
              opacity: 0,
              bottom: '-200%',
            }}
          />
        )}
        <div
          className={cn(
            '!rounded-t-3xl px-3 py-2 text-white w-full',
            'md:!rounded-tr-none md:!rounded-l-3xl md:px-0 md:py-0 md:w-[356px]',
            `${getDeviceStatusColor(isEditable, isOnline).backgroundColor}`,
          )}
        >
          <DeviceInfo
            device={device}
            contentWarning={contentWarning}
            deviceErrors={deviceErrors}
            isEditable={isEditable}
            lastLoadedDate={lastLoadedDate}
            onSettingsClick={onSettingsClick}
            onAiSettingsClick={onAiSettingsClick}
            onPerchSettingsClick={onPerchSettingsClick}
            openStatusPopover={openStatusPopover}
            onOpenStatusPopover={setOpenStatusPopover}
          />
        </div>

        {!isVIOnly && (
          <>
            {!device.isAudioOnly && (
              <div
                className={cn(
                  'flex flex-1 px-5 pt-2 pb-4 fill-gray-600 text-gray-600',
                  'md:py-3 md:px-6',
                )}
              >
                <div className="flex flex-col flex-1 justify-between md:flex-none md:w-[288px]">
                  <div className="pt-1">
                    <Text variant="body">Playlist Options</Text>
                  </div>
                  <div className="flex w-full ml-0 mr-0 md:w-[288px]">
                    <div className="flex flex-1">
                      <button
                        className={cn(
                          'flex items-end',
                          isEditablePlaylist && 'hover:text-blue-600',
                        )}
                        disabled={!isEditablePlaylist}
                        onClick={
                          isEditablePlaylist ? onEditPlaylistClick : undefined
                        }
                      >
                        <div className="mb-0.5 md:mb-1.5">
                          <SidebarMenuTooltip
                            placement="bottom"
                            title="Edit Playlist"
                            arrow
                          >
                            <div>
                              <EditIcon />
                            </div>
                          </SidebarMenuTooltip>
                        </div>
                        <div
                          className={cn(
                            'truncate ml-3 text-lg font-regular leading-2 tracking-wide text-left mr-2',
                            'md:text-xl md:w-[245px]',
                          )}
                        >
                          {device.playlistId && device.playlist
                            ? device.playlist.name
                            : 'Assign a playlist'}
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex items-end">
                  <SidebarMenuTooltip
                    className="text-base"
                    placement="bottom"
                    title="Select Playlist"
                    arrow
                  >
                    <button
                      className={cn(
                        'p-0 mb-1 md:mb-2',
                        'tour-device-playlist-select',
                        'hover:text-blue-600',
                        selectedPlaylist && 'text-blue-600',
                      )}
                      disabled={!isEditable}
                      onClick={onSelectPlaylistClick}
                    >
                      <ListIcon />
                    </button>
                  </SidebarMenuTooltip>
                </div>

                <span className="hidden md:flex md:flex-1" />

                <div className="flex gap-1.5">
                  {batchStatus &&
                    !isOnlyPublishRemaining(batchStatus) &&
                    showBatchOperationStatus &&
                    userOwnedBatchDetails && (
                      <DeviceBatchStatus batchActions={userOwnedBatchDetails} />
                    )}

                  <PublishButton
                    className="flex items-end md:items-center"
                    device={device}
                    isOnline={isOnline}
                    isBatchOperationInProgress={isBatchOperationInProgress}
                    onClick={onPublishClick}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DeviceCard;
