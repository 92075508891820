import { useState, useEffect } from 'react';
import cn from 'classnames';
import { Dialog, DialogContent, DialogContentText } from '@material-ui/core';
import Button from 'raydiant-elements/core/Button';
import Heading from 'raydiant-elements/core/Heading';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import { Grid } from '@material-ui/core';
import Text from 'raydiant-elements/core/Text';
import SelectField from 'raydiant-elements/core/SelectField';
import Hidden from 'raydiant-elements/layout/Hidden';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import Link from 'raydiant-elements/core/Link';
import Checkbox from 'raydiant-elements/core/Checkbox';
import OpenInNewIcon from '@material-ui/icons/OpenInNewOutlined';
import useMarketplaceApplication from '../../../hooks/useMarketPlaceApplication';
import Center from 'raydiant-elements/layout/Center';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import TextField from 'raydiant-elements/core/TextField';
import {
  Application,
  PaymentMethod,
  PurchaseProductRequest,
} from '@raydiant/api-client-js';
import {
  calculateTermPrice,
  formatCurrency,
  getGrantScope,
  validatePaymentMethods,
} from '../MarketplacePageUtils';
import { LoadingStatus } from '../../../components/LoadingButton/LoadingButton';
import usePaymentMethods from '../../../hooks/usePaymentMethods';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4, 4),

      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0.5, 1),
      },
    },
    heading: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      color: theme.palette.primary.main,
      fontSize: theme.spacing(3.2),
      fontWeight: 400,

      [theme.breakpoints.down('xs')]: {
        paddingTop: '0px',
      },
    },
    dialogContent: {
      minHeight: '500px',
    },
    button: {
      minWidth: '8rem',

      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(2),
        width: '100%',
      },
    },
    section: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),

      [theme.breakpoints.down('xs')]: {
        paddingTop: '0px',
        paddingBottom: '0px',
      },
    },
    rightSection: {
      borderLeft: `1px solid rgba(0, 0, 0, 0.1)`,

      [theme.breakpoints.down('xs')]: {
        borderLeft: 'none',
      },
    },
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      overflow: 'hidden',
    },
    appVersion: {
      paddingLeft: theme.spacing(2),
    },
    spacer: {
      marginTop: theme.spacing(1.5),
    },
    divider: {
      borderBottom: `1px solid rgba(0, 0, 0, 0.1)`,
      height: theme.spacing(5),
      marginBottom: theme.spacing(4),

      [theme.breakpoints.down('xs')]: {
        height: theme.spacing(2),
        marginBottom: theme.spacing(4),
      },
    },
    subTotal: {
      fontSize: theme.spacing(2),

      [theme.breakpoints.down('xs')]: {
        fontSize: theme.spacing(1.5),
        marginTop: theme.spacing(2),
      },
    },
    dueNow: {
      marginTop: theme.spacing(1),
      fontSize: theme.spacing(2.5),
      fontWeight: 505,
      color: 'rgba(0, 0, 0, 0.87)',

      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(0.25),
        fontSize: theme.spacing(1.8),
      },
    },

    paymentOption: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: theme.spacing(2),
      border: '1px solid #fff',
      backgroundColor: '#fff',
      padding: theme.spacing(1, 1),
      borderRadius: theme.spacing(0.75),
      boxShadow: theme.shadows[3],
      textAlign: 'center',
    },
    nonCreditCardOption: {
      justifyContent: 'flex-start',
    },
    inputText: {
      textAlign: 'right',
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(4),
      },
    },
    checkboxText: {
      display: 'flex',
      alignItems: 'center',
    },
    textWrapper: {
      display: 'flex',
    },
    lowerSection: {
      [theme.breakpoints.up('xs')]: {
        minHeight: theme.spacing(10),
      },
    },
    paymentSection: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    paymentOptions: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      paddingBottom: theme.spacing(1),
    },
    validOptions: {
      height: '300px',
    },
    paymentWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    licenses: {
      '& > div > div > input': {
        backgroundColor: '#fff !important',
      },
    },
    clickable: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    selectedPaymentMethod: {
      border: '1px solid',
    },
    cardImage: {
      width: theme.spacing(10),
      height: theme.spacing(8),
    },
    cardText: {
      paddingRight: theme.spacing(2.5),
    },
    cardTypeText: {
      paddingRight: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    defaultPaymentOption: {
      height: theme.spacing(10.275),
      justifyContent: 'center',
    },
    planPricing: {
      marginTop: theme.spacing(1),
    },
    defaultSubscription: {
      height: '150px',
      [theme.breakpoints.down('xs')]: {
        height: 'auto',
      },
    },
    subscription: {
      height: '100px',
      [theme.breakpoints.down('xs')]: {
        height: 'auto',
      },
    },
  }),
);

interface AppPurchaseDialogProps {
  open: boolean;
  selectedApplication: Application;
  submitStatus: LoadingStatus;
  onClick: (
    orderDetail: PurchaseProductRequest,
    manualEnablementRequired: boolean,
  ) => void;
  onClose: () => void;
  onCustomPricing: () => void;
}

type Subscription = 'Annual' | 'Monthly';

const validateQuantity = (value: string) => {
  if (!value) return 1;
  const formattedValue = +value;
  if (isNaN(formattedValue)) return 1;
  return formattedValue;
};

const calculatePrice = (terms: string, price: number, quantity: number) => {
  const totalPrice = price * quantity;

  if (totalPrice <= 0) {
    return 'Free';
  }

  switch (terms) {
    case 'Annual':
      return `US ${formatCurrency(totalPrice)}/yr`;
    case 'Monthly':
      return `US ${formatCurrency(totalPrice)}/mo`;
  }
};

const AppPurchaseDialog = ({
  open,
  selectedApplication,
  submitStatus,
  onClick,
  onCustomPricing,
  onClose,
}: AppPurchaseDialogProps) => {
  const [selectedPlan, setSelectedPlan] = useState<string>('');
  const [quantity, setQuantity] = useState<string>('1');
  const [subscription, setSubscription] = useState('Monthly');
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethod | null>(null);
  const [termsOfUseChecked, setTermsOfUseChecked] = useState(false);

  const classes = useStyles();
  const { currentAppVersion, websiteUrl, name } = selectedApplication;

  const { thumbnailUrl } = currentAppVersion;
  const cta = currentAppVersion.name || name;
  const grantScope = getGrantScope(selectedApplication);

  const {
    data,
    isLoading: isMarketplaceApplicationLoading,
    isError,
  } = useMarketplaceApplication(grantScope || '');
  const { data: paymentMethodsData, isLoading: isPaymentMethodsDataLoading } =
    usePaymentMethods();

  const paymentMethods = validatePaymentMethods(
    paymentMethodsData?.paymentMethods || [],
  );

  const achOptions = paymentMethods?.filter(
    (paymentMethod) => paymentMethod.paymentType === 'ACH',
  );
  const checkOptions = paymentMethods?.filter(
    (paymentMethod) => paymentMethod.paymentType === 'Check',
  );
  const creditCardOptions = paymentMethods?.filter(
    (paymentMethod) => paymentMethod.paymentType === 'Credit Card',
  );

  const isLoading =
    isMarketplaceApplicationLoading || isPaymentMethodsDataLoading;

  const handleChecked = () => {
    setTermsOfUseChecked(!termsOfUseChecked);
  };

  useEffect(() => {
    if (!grantScope || isError || submitStatus === 'error') {
      onCustomPricing();
    }

    if (data) {
      const isAllVersionReachOutToSales = data.every(
        ({ reachOutToSales }) => reachOutToSales,
      );

      if (isAllVersionReachOutToSales) {
        onCustomPricing();
      } else {
        setSelectedPlan(data[0].productAppPlan);
      }
    }
  }, [grantScope, isError, submitStatus, data, onCustomPricing]);

  useEffect(() => {
    setQuantity('1');
  }, [selectedPlan]);

  useEffect(() => {
    const isPaymentMethods = paymentMethods?.length > 0;
    const isCreditCardOptions = creditCardOptions?.length > 0;

    if (isPaymentMethods && !selectedPaymentMethod) {
      if (isCreditCardOptions) {
        setSelectedPaymentMethod(creditCardOptions[0]);
      } else {
        setSelectedPaymentMethod(paymentMethods[0]);
      }
    }
  }, [paymentMethods, selectedPaymentMethod, creditCardOptions]);

  if (isLoading) {
    return (
      <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="md">
        <DialogContent className={classes.root}>
          <Heading className={classes.heading}>Your Order</Heading>
          <DialogContentText id="alert-dialog-description">
            <Center className={classes.dialogContent}>
              <CircularProgress size={30} />
              <Text small error>
                Loading Product Data...
              </Text>
            </Center>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

  if (submitStatus === 'loading') {
    return (
      <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="md">
        <DialogContent className={classes.root}>
          <Heading className={classes.heading}>Your Order</Heading>
          <DialogContentText id="alert-dialog-description">
            <Center className={classes.dialogContent}>
              <CircularProgress size={30} />
              <Text small error>
                Processing your order...
              </Text>
            </Center>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

  const selectedPlanDetail = data?.find(
    (application) => application.productAppPlan === selectedPlan,
  );
  const isMultipleLicenseEnabled =
    selectedPlanDetail?.marketplaceQuantityEditable;
  const isPurchased = selectedPlanDetail?.purchasedSubscriptionDetails;
  const isPurchaseValid =
    (isMultipleLicenseEnabled || (!isMultipleLicenseEnabled && !isPurchased)) &&
    termsOfUseChecked &&
    selectedPaymentMethod;
  const clientFacingPrice = selectedPlanDetail?.netPrice || 0;
  const selectedPlanTermPrice = calculateTermPrice(
    subscription,
    clientFacingPrice,
  );

  const isPaymentMethodsValid = paymentMethods && paymentMethods.length > 0;

  const handleConfirmButtonClick = () => {
    if (selectedPlanDetail && selectedPaymentMethod) {
      const orderDetail = {
        product_id: selectedPlanDetail.productId,
        quantity: +quantity,
        billing_frequency: subscription as Subscription,
        payment_method_id: selectedPaymentMethod.id,
      };

      onClick(orderDetail, selectedPlanDetail?.manualEnablementRequired);
    }
  };

  const renderAppVersionDesktop = () => (
    <Grid item md={12} className={classes.spacer}>
      <SelectField
        label="Version"
        value={selectedPlan}
        onChange={setSelectedPlan}
      >
        {data?.map(({ productAppPlan, purchasedSubscriptionDetails }) => (
          <option value={productAppPlan}>
            {purchasedSubscriptionDetails
              ? `${productAppPlan} (purchased)`
              : productAppPlan}
          </option>
        ))}
      </SelectField>
    </Grid>
  );

  const renderAppVersionMobile = () => (
    <Grid item xs={12} className={classes.spacer}>
      <SelectField
        label="Version"
        value={selectedPlan}
        onChange={setSelectedPlan}
      >
        {data?.map(({ productAppPlan, purchasedSubscriptionDetails }) => (
          <option value={productAppPlan}>
            {purchasedSubscriptionDetails
              ? `${productAppPlan} (purchased)`
              : productAppPlan}
          </option>
        ))}
      </SelectField>
    </Grid>
  );

  const renderDivider = () => (
    <Grid item xs={12} md={12} className={classes.divider} />
  );

  const renderPrice = () => {
    const calculatedPrice = calculatePrice(
      subscription,
      selectedPlanTermPrice || 0,
      validateQuantity(quantity),
    );

    return (
      <Grid item className={classes.lowerSection}>
        <Grid item container justifyContent="space-between">
          <Text className={classes.subTotal}>Sub Total</Text>
          <Text className={classes.subTotal}>{calculatedPrice}</Text>
        </Grid>
        <Grid item container justifyContent="space-between">
          <Text className={classes.dueNow}>Due Now</Text>
          <Text className={classes.dueNow}>{calculatedPrice}</Text>
        </Grid>
      </Grid>
    );
  };

  const renderProductPlanPrice = () => {
    return (
      <SelectField
        label="Subscription"
        value={subscription}
        onChange={setSubscription}
      >
        <option value="Monthly">
          Monthly - US {formatCurrency(clientFacingPrice)} / mo
        </option>
        <option value="Annual">
          Yearly, billed upfront - US {formatCurrency(clientFacingPrice * 12)} /
          yr
        </option>
      </SelectField>
    );
  };

  const renderProductQuantity = () => {
    if (isMultipleLicenseEnabled) {
      return (
        <TextField
          label="Number of Licenses"
          value={validateQuantity(quantity).toString()}
          onChange={setQuantity}
        />
      );
    }
  };

  const renderAchOption = (paymentMethod: PaymentMethod) => (
    <Grid
      item
      className={cn(
        classes.paymentOption,
        classes.nonCreditCardOption,
        classes.clickable,
        selectedPaymentMethod === paymentMethod &&
          classes.selectedPaymentMethod,
      )}
      onClick={() => setSelectedPaymentMethod(paymentMethod)}
    >
      <img
        src="https://assets.raydiant.com/payment-methods/ach.svg"
        alt="check"
        className={classes.cardImage}
      />
      <Text large className={classes.cardText}>
        Pay via Automated Clearning House
      </Text>
    </Grid>
  );

  const renderCheckOption = (paymentMethod: PaymentMethod) => (
    <Grid
      item
      className={cn(
        classes.paymentOption,
        classes.nonCreditCardOption,
        classes.clickable,
        selectedPaymentMethod === paymentMethod &&
          classes.selectedPaymentMethod,
      )}
      onClick={() => setSelectedPaymentMethod(paymentMethod)}
    >
      <img
        src="https://assets.raydiant.com/payment-methods/cheque.svg"
        alt="check"
        className={classes.cardImage}
      />
      <Text large className={classes.cardText}>
        Pay via Check
      </Text>
    </Grid>
  );

  const transformToPaymentCard = (paymentMethod: PaymentMethod) => {
    const cardType = paymentMethod.cardType.toLowerCase();
    const cardNumber = `**** **** **** ${paymentMethod.cardLastFour}`;

    switch (cardType) {
      case 'visa':
        return (
          <Grid
            item
            className={cn(
              classes.paymentOption,
              classes.clickable,
              selectedPaymentMethod === paymentMethod &&
                classes.selectedPaymentMethod,
            )}
            onClick={() => setSelectedPaymentMethod(paymentMethod)}
          >
            <img
              src="https://assets.raydiant.com/payment-methods/visa.svg"
              alt="visa"
              className={classes.cardImage}
            />
            <Text large className={classes.cardTypeText}>
              VISA
            </Text>
            <Text large className={classes.cardText}>
              {cardNumber}
            </Text>
          </Grid>
        );
      case 'mastercard':
        return (
          <Grid
            item
            className={cn(
              classes.paymentOption,
              classes.clickable,
              selectedPaymentMethod === paymentMethod &&
                classes.selectedPaymentMethod,
            )}
            onClick={() => setSelectedPaymentMethod(paymentMethod)}
          >
            <img
              src="https://assets.raydiant.com/payment-methods/mastercard.svg"
              alt="mastercard"
              className={classes.cardImage}
            />
            <Text large className={classes.cardTypeText}>
              MASTERCARD
            </Text>
            <Text large className={classes.cardText}>
              {cardNumber}
            </Text>
          </Grid>
        );
      case 'american express':
        return (
          <Grid
            item
            className={cn(
              classes.paymentOption,
              classes.clickable,
              selectedPaymentMethod === paymentMethod &&
                classes.selectedPaymentMethod,
            )}
            onClick={() => setSelectedPaymentMethod(paymentMethod)}
          >
            <img
              src="https://assets.raydiant.com/payment-methods/amex.svg"
              alt="american express"
              className={classes.cardImage}
            />
            <Text large className={classes.cardTypeText}>
              AMEX
            </Text>
            <Text large>{cardNumber}</Text>
          </Grid>
        );
      case 'maestro':
        return (
          <Grid
            item
            className={cn(
              classes.paymentOption,
              classes.clickable,
              selectedPaymentMethod === paymentMethod &&
                classes.selectedPaymentMethod,
            )}
            onClick={() => setSelectedPaymentMethod(paymentMethod)}
          >
            <img
              src="https://assets.raydiant.com/payment-methods/mastercard.svg"
              alt="maestro"
              className={classes.cardImage}
            />
            <Text large className={classes.cardTypeText}>
              MAESTRO
            </Text>
            <Text large>{cardNumber}</Text>
          </Grid>
        );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="md">
      <DialogContent className={classes.root}>
        <Heading className={classes.heading}>Your Order</Heading>
        <DialogContentText id="alert-dialog-description">
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className={classes.section}
            >
              <Grid item xs={12} md={11}>
                <Grid item container>
                  <Grid item xs={5} md={5}>
                    <img
                      src={thumbnailUrl}
                      alt={cta}
                      className={cn(
                        classes.img,
                        websiteUrl && classes.clickable,
                      )}
                      onClick={() => {
                        websiteUrl && window.open(websiteUrl);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    xs={7}
                    md={7}
                    className={classes.appVersion}
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Text large>{cta}</Text>
                    <Hidden smDown>{renderAppVersionDesktop()}</Hidden>
                  </Grid>
                </Grid>
                <Grid item className={classes.spacer}>
                  <Grid item>
                    <Text medium>
                      {selectedPlanDetail?.clientFacingPlanDescription}
                    </Text>
                  </Grid>
                  <Grid item className={classes.planPricing}>
                    <Text medium>
                      {selectedPlanDetail?.clientFacingPricing}
                    </Text>
                  </Grid>
                </Grid>
                <Hidden smUp>{renderAppVersionMobile()}</Hidden>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={cn(
                    classes.spacer,
                    clientFacingPrice > 0
                      ? classes.subscription
                      : classes.defaultSubscription,
                  )}
                >
                  {clientFacingPrice > 0 && renderProductPlanPrice()}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={cn(classes.spacer, classes.licenses)}
                >
                  {renderProductQuantity()}
                </Grid>
                <Hidden smUp>{renderPrice()}</Hidden>
                {renderDivider()}
                <Hidden smDown>{renderPrice()}</Hidden>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              container
              className={cn(classes.section, classes.rightSection)}
              justifyContent="flex-end"
            >
              <Grid item xs={12} md={11} className={classes.paymentWrapper}>
                <Grid item className={classes.paymentSection}>
                  <Text large bold>
                    Payment Method
                  </Text>
                  <Grid
                    className={cn(
                      classes.paymentOptions,
                      paymentMethods.length > 0 && classes.validOptions,
                    )}
                  >
                    {achOptions?.map((paymentMethod) =>
                      renderAchOption(paymentMethod),
                    )}
                    {checkOptions?.map((paymentMethod) =>
                      renderCheckOption(paymentMethod),
                    )}
                    {creditCardOptions?.map(
                      (paymentMethod) =>
                        paymentMethod.paymentType === 'Credit Card' &&
                        transformToPaymentCard(paymentMethod),
                    )}
                  </Grid>
                  {!isPaymentMethodsValid && (
                    <Grid
                      item
                      className={cn(
                        classes.paymentOption,
                        classes.defaultPaymentOption,
                      )}
                    >
                      <Text large>No payment method on account</Text>
                    </Grid>
                  )}

                  <InputHelperText
                    className={cn(classes.inputText, classes.spacer)}
                  >
                    <Link
                      href={`${process.env.REACT_APP_ACCOUNT_MANAGEMENT_URL}/payment`}
                      target="_blank"
                    >
                      Modify Payment Method
                    </Link>
                  </InputHelperText>
                </Grid>

                {renderDivider()}
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  className={classes.lowerSection}
                >
                  <Grid item className={classes.textWrapper}>
                    <Checkbox
                      checked={termsOfUseChecked}
                      onChange={() => handleChecked()}
                      label={
                        <Text className={classes.checkboxText}>
                          I agree to the&nbsp;
                          <Link
                            href="https://www.raydiant.com/terms-of-service"
                            target="_blank"
                            className={classes.checkboxText}
                          >
                            Raydiant Terms&nbsp;
                            <OpenInNewIcon />
                          </Link>
                        </Text>
                      }
                    />
                  </Grid>
                  <Button
                    className={classes.button}
                    onClick={() => {
                      selectedPlanDetail?.reachOutToSales
                        ? onCustomPricing()
                        : handleConfirmButtonClick();
                    }}
                    color="primary"
                    disabled={!isPurchaseValid}
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default AppPurchaseDialog;
