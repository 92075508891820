import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { DayTraffic } from '@raydiant/api-client-js';
import { useTranslation } from 'react-i18next';
import ChartCard from '../ChartCard/ChartCard';
import {
  CHART_DEFAULT_OPTIONS,
  CHART_DEFAULT_AXIS_OPTIONS,
  formatDateLabel,
} from '../utils';
import ChartToolTip, { EChartsToolTipParams } from '../ChartToolTip';
import { renderToStaticMarkup } from 'react-dom/server';
import Hidden from 'raydiant-elements/layout/Hidden';
import { Popover } from '@headlessui/react';
import QuestionMarkIcon from '../../../components/QuestionMarkIcon';
import PopoverButton from '../../../components/Popover/PopoverButton';
import { getDisplayValue } from '../ShopperEngagementInsights/utils';

echarts.use([LineChart]);

type ShopperEngagementChartView = keyof DayTraffic;
type ShopperEngagementChartProps = {
  data: DayTraffic[];
  showAll?: boolean;
};

const getXAxisData = (data: DayTraffic[]) =>
  data?.map((value) => formatDateLabel(value.timestamp, 'ddd D[\n]MMMM'));

const getYAxisData = (data: DayTraffic[], view: ShopperEngagementChartView) =>
  data?.map((value) => value[view]);

const ShopperEngagementChart = ({
  data,
  showAll = false,
}: ShopperEngagementChartProps) => {
  const { t } = useTranslation();

  const getSeries = (data: DayTraffic[]) => {
    let series = [];

    series.push({
      areaStyle: {
        opacity: 0.8,
        color: '#FDDA5F',
      },
      data: getYAxisData(data, 'ots'),
      emphasis: {
        focus: 'series',
      },
      lineStyle: {
        width: 0,
      },
      name: t('analytics.trafficAwarenessPage.tableHeaders.ots'),
      showSymbol: false,
      smooth: false,
      stack: 'Traffic',
      type: 'line',
    });

    if (showAll) {
      series.push({
        areaStyle: {
          opacity: 0.8,
          color: '#7AD1F4',
        },
        data: getYAxisData(data, 'verifiedViews'),
        emphasis: {
          focus: 'series',
        },
        lineStyle: {
          width: 0,
        },
        name: t('analytics.trafficAwarenessPage.tableHeaders.verifiedViews'),
        smooth: false,
        showSymbol: false,
        stack: 'Verified Views',
        type: 'line',
      });
    }

    series.push({
      data: getYAxisData(data, 'avgDwellTime'),
      name: t('analytics.trafficAwarenessPage.tableHeaders.avgDwellTime'),
      lineStyle: {
        color: '#E69E09',
        width: 3,
      },
      type: 'line',
      showSymbol: false,
      yAxisIndex: 1,
    });

    return series;
  };

  const color = showAll
    ? ['#FDDA5F', '#7AD1F4', '#E69E09']
    : ['#FDDA5F', '#E69E09'];

  const options = {
    ...CHART_DEFAULT_OPTIONS,
    color: color,
    toolbox: {
      feature: {
        saveAsImage: { show: true },
      },
    },
    legend: {
      ...CHART_DEFAULT_OPTIONS.legend,
      data: [
        {
          name: t('analytics.trafficAwarenessPage.tableHeaders.ots'),
          icon: 'square',
        },
        {
          name: t('analytics.trafficAwarenessPage.tableHeaders.verifiedViews'),
          icon: 'square',
        },
        {
          name: t('analytics.trafficAwarenessPage.tableHeaders.avgDwellTime'),
          icon: 'square',
        },
      ],
    },
    xAxis: {
      ...CHART_DEFAULT_AXIS_OPTIONS.xAxis,
      data: getXAxisData(data),
      name: t('analytics.time').toUpperCase(),
      axisLabel: {
        ...CHART_DEFAULT_AXIS_OPTIONS.xAxis.axisLabel,
        lineHeight: 18,
      },
      nameGap: 55,
    },
    yAxis: [
      {
        ...CHART_DEFAULT_AXIS_OPTIONS.yAxis,
        name: t(
          'analytics.trafficAwarenessPage.tableHeaders.ots',
        ).toUpperCase(),
        axisLabel: {
          ...CHART_DEFAULT_AXIS_OPTIONS.yAxis.axisLabel,
        },
      },
      {
        ...CHART_DEFAULT_AXIS_OPTIONS.yAxis,
        alignTicks: true,
        name: `${t(
          'analytics.trafficAwarenessPage.tableHeaders.avgDwellTime',
        )} (${t(
          'analytics.trafficAwarenessPage.tableHeaders.inSeconds',
        )})`.toUpperCase(),
        axisLabel: {
          ...CHART_DEFAULT_AXIS_OPTIONS.yAxis.axisLabel,
          margin: 8,
        },
      },
    ],
    series: getSeries(data),
    tooltip: {
      ...CHART_DEFAULT_OPTIONS.tooltip,
      formatter: (params: EChartsToolTipParams[]) => {
        const index = params[0]?.dataIndex;
        const value = data[index];

        const modifiedParams: EChartsToolTipParams[] = params.map((item) => {
          if (
            item.seriesName ===
            t('analytics.trafficAwarenessPage.tableHeaders.avgDwellTime')
          ) {
            return { ...item, value: getDisplayValue(item.value, ' sec') };
          } else {
            return { ...item, value: getDisplayValue(item.value) };
          }
        });

        return renderToStaticMarkup(
          <ChartToolTip
            header={
              <p className="font-bold text-sm px-2 py-1 text-black">
                {formatDateLabel(value.timestamp, 'ddd, MMMM D, YYYY')}
              </p>
            }
            values={modifiedParams}
          />,
        );
      },
    },
  };

  const mobileOptions = {
    ...options,
    legend: {
      ...CHART_DEFAULT_OPTIONS.legend,
      data: [
        {
          name: t('analytics.trafficAwarenessPage.tableHeaders.ots'),
          icon: 'square',
        },
        {
          name: t('analytics.trafficAwarenessPage.tableHeaders.verifiedViews'),
          icon: 'square',
        },
        {
          name: t('analytics.trafficAwarenessPage.tableHeaders.avgDwellTime'),
          icon: 'square',
        },
      ],
      type: 'scroll',
    },
    series: getSeries(data),
  };

  return (
    <ChartCard
      subTitle={''}
      title={
        <div className="flex gap-3 items-center">
          <h2 className="text-black text-xl font-bold">
            {t('analytics.trafficAwarenessPage.shopperEngagement.title')!}
          </h2>
          <Popover className="relative">
            <PopoverButton icon={<QuestionMarkIcon />} />
            <Popover.Panel className="absolute z-10 card popover">
              <div className="overflow-hidden">
                <p className="mb-3">
                  {showAll
                    ? t(
                        'analytics.trafficAwarenessPage.shopperEngagement.tooltip.default',
                      )
                    : t(
                        'analytics.trafficAwarenessPage.shopperEngagement.tooltip.viOnly',
                      )}
                </p>
                <a
                  href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('analytics.learnmore')}
                </a>
              </div>
            </Popover.Panel>
          </Popover>
        </div>
      }
    >
      <div className="min-w-full min-h-full">
        <Hidden xsDown>
          <ReactECharts
            style={{ height: '410px' }}
            option={options}
            onChartReady={(chart) => chart.resize()}
          />
        </Hidden>
        <Hidden smUp>
          <ReactECharts
            style={{ height: '410px' }}
            option={mobileOptions}
            onChartReady={(chart) => chart.resize()}
          />
        </Hidden>
      </div>
    </ChartCard>
  );
};

export default ShopperEngagementChart;
