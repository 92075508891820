import { Theme } from 'raydiant-elements/theme';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { textTruncate } from 'raydiant-elements/mixins';

export const playlistItemIndentWidth = 4;
const playlistItemHeight = 40;
const playlistModalWidth = 390;

export default makeStyles((theme: Theme) => {
  return createStyles({
    header: {
      padding: theme.spacing(0, 1),
    },

    footer: {
      padding: theme.spacing(0, 1),
      paddingBottom: theme.spacing(1),
    },

    playlistItems: {
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(0.25),
    },

    playlistItemsSkeleton: {
      flex: 1,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(0.25),
      backgroundImage: `url(${
        require('../../assets/playlist-builder-skeleton.svg').default
      })`,
      backgroundPosition: 'top center',
      backgroundRepeat: 'repeat',
      backgroundSize: '100%',
    },

    playlistActions: {
      marginBottom: theme.spacing(1),
    },

    playlistItemMoreActions: {
      width: 160,
    },

    playlistItemMoreActionsTitle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      marginBottom: theme.spacing(1),
    },

    playlistItemMoreActionsTitleIcon: {
      marginRight: theme.spacing(0.5),
      width: 16,
      height: 16,
      display: 'flex',
      alignItems: 'center',
    },

    playlistItemMoreActionsTitleLabel: {
      ...textTruncate(),
      fontSize: theme.fontSizes.xxs,
    },

    playlistItemMoreActionsPresentationIcon: {
      // The playlist icon is visually smaller but the same size as the presentation icon.
      // This reduces the size of the presentation icon to match the size of the playlist icon.
      '& > *': {
        width: 14,
        height: 14,
      },
    },

    playlistItemsScrollShadow: {
      // Compensate for padding and rounded corners of the header and footer.
      left: theme.spacing(2),
      right: theme.spacing(2),
    },

    playlistItemOuter: {
      position: 'relative',
      paddingBottom: theme.spacing(0.25),
    },

    rootPlaylistItem: {
      paddingBottom: 0,
    },

    playlistItem: {
      display: 'flex',
      flexDirection: 'row',
      height: playlistItemHeight,
      alignItems: 'center',
      paddingLeft: theme.spacing(1.5),
      flexShrink: 0,
      backgroundColor: '#f5f5f5',
      borderRadius: theme.borderRadius.sm,

      '&:hover $playlistItemActions': {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
      },
      '&:hover $playlistItemActionsSecondary': {
        opacity: 1,
      },
    },

    playlistItemInfo: {
      overflow: 'hidden', // Needed for text-ellipsis on name
    },

    playlistItemActions: {
      position: 'relative',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      borderRadius: theme.borderRadius.sm,
    },

    playlistItemActionsSecondary: {
      opacity: 0,
      position: 'absolute',
      top: 0,
      left: 2,
      paddingRight: 2,
      transform: 'translateX(-100%)',
      height: '100%',
      paddingLeft: theme.spacing(1.5),
      borderRadius: theme.borderRadius.sm,
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
    },

    playlistItemActionsContainer: {
      height: '100%',
    },

    playlistItemPlaylist: {
      position: 'relative',

      '&:before': {
        content: '" "',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: playlistItemHeight,
        pointerEvents: 'none',
        boxShadow: `0 4px 8px 0 rgba(0, 0, 0, 0.2)`,
        opacity: 0,
        transition: 'opacity 0.15s ease-in-out',
        zIndex: 1,
        borderRadius: theme.borderRadius.sm,
      },
    },

    playlistItemSelected: {
      backgroundColor: '#ffffff',

      // Use a pseudo-element for the selected border so it sits on top of playlistItemActions.
      '&:after': {
        content: '" "',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        borderRadius: theme.borderRadius.sm,
        border: '2px solid rgba(0, 0, 0, 0.38)',
        pointerEvents: 'none',
      },
    },

    playlistItemPresentationSelected: {
      cursor: 'pointer',
    },

    playlistItemPlaylistOver: {
      boxShadow: `inset 0px 0px 0px 2px #000`,
    },

    playlistItemDisabled: {
      backgroundColor: '#eeeeee',
      color: '#999999',

      '& $playlistName': {
        color: 'inherit',
      },

      '& $presentationName': {
        color: 'inherit',
      },
    },

    playlistItemDropIndicatorOver: {
      position: 'absolute',
      left: 0,
      right: 0,
      height: 2,
      backgroundColor: '#000',
      pointerEvents: 'none',
      zIndex: 1,
    },

    playlistItemPlaylistExpanded: {
      '&:before': {
        opacity: 1,
      },
    },

    playlistItemRoot: {
      height: 52,

      '&:before': {
        // Align expanded shadow height.
        height: 52,
      },
    },

    playlistName: {
      ...textTruncate(),
      fontWeight: 500,
      fontSize: theme.fontSizes.lg,
      color: theme.palette.text.headingSecondary,
    },

    presentationName: {
      ...textTruncate(),
      fontSize: theme.fontSizes.sm,
      color: theme.palette.text.headingSecondary,
    },

    playlistItemIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 20,
      width: 20,
      flexShrink: 0,
      position: 'relative',
    },

    playlistItemErrorIcon: {
      position: 'absolute',
      top: 5,
      left: 6,
      height: 15,
      width: 15,
      zIndex: 1,
    },

    playlistItemButton: {
      color: theme.palette.text.secondary,
      padding: 1,
    },

    playlistItemButtonPrimary: {
      color: theme.palette.text.headingSecondary,
    },

    playlistItemButtonSelected: {
      color: '#fff',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',

      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
      },
    },

    expandIndicator: {
      transform: 'rotate(-90deg)',
    },

    expandIndiciatorExpanded: {
      transform: 'rotate(0deg)',
    },

    dragLayer: {
      position: 'fixed',
      pointerEvents: 'none',
      zIndex: 9999, // Should always be on top
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },

    dragLayerItem: {
      position: 'absolute',
      boxShadow: theme.shadows[2],
    },

    playlistModal: {
      top: 140,
      left: 398,
      bottom: theme.spacing(1),
      width: playlistModalWidth,
    },

    customValueModal: {
      top: 140,
      left: 398 + playlistModalWidth + theme.spacing(1),
      bottom: theme.spacing(1),
      width: 390,
    },

    playlistModalContents: {
      paddingBottom: theme.spacing(4),
    },

    previewActions: {
      padding: theme.spacing(0, 1),
      position: 'relative', // Fix to make drop shadow appear ontop of below preview.
    },
  });
});
