import {
  CreateLocationRequest,
  Location,
  ApiClientError,
} from '@raydiant/api-client-js';
import { useQueryClient } from 'react-query';
import raydiant from '../clients/raydiant';
import { keys } from '../queryClient';
import useProtectedMutation from './useProtectedMutation';
import { useToastState } from '@raydiant/raydial/components/Toast/ToastProvider';
import { useHistory } from 'react-router-dom';
import Link from '@raydiant/raydial/components/Link';
import * as paths from '../routes/paths';

export default function useCreateLocation() {
  const queryClient = useQueryClient();
  const state = useToastState();
  const history = useHistory();

  const mutation = useProtectedMutation<
    Location,
    ApiClientError,
    { params: CreateLocationRequest }
  >(({ params }) => raydiant.createLocation(params), {
    onSuccess: (location) => {
      state.add({
        title: `Location Added`,
        description: (
          <div>
            Location{' '}
            <Link
              className="outline-none underline"
              onClick={() =>
                history.push({
                  pathname: paths.screens(),
                  search: `location_ids=${location?.id}`,
                })
              }
            >
              {location?.name}
            </Link>{' '}
            added successfully
          </div>
        ),
      });
      queryClient.invalidateQueries(keys.locations());
      queryClient.invalidateQueries(keys.pendingLocations());
    },
    onError: () => {
      state.add({
        title: 'Location could not be added',
        description: 'Please try again',
        variant: 'caution',
      });
    },
  });

  return mutation;
}
