import WifiIcon from '@material-ui/icons/Wifi';
import moment from 'moment-timezone';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import PaperModal from 'raydiant-elements/core/PaperModal';
import Button from 'raydiant-elements/core/Button';
import TextField from 'raydiant-elements/core/TextField';
import TimeField from 'raydiant-elements/core/TimeField';
import Heading2 from 'raydiant-elements/typography/Heading2';
import Link from 'raydiant-elements/core/Link';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import Heading from 'raydiant-elements/core/Heading';
import Switch from 'raydiant-elements/core/Switch';
import EthernetIcon from 'raydiant-elements/icons/Ethernet';
import LTEIcon from 'raydiant-elements/icons/LTE';
import NetworkIcon from 'raydiant-elements/icons/Network';
import SelectField from 'raydiant-elements/core/SelectField';
import Column from 'raydiant-elements/layout/Column';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import Text from 'raydiant-elements/typography/Text';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as D from '../../clients/mira/types/Device';
import miraClient from '../../clients/miraClient';
import * as deviceActions from '../../actions/devices';
import * as soundZoneActions from '../../actions/soundZones';
import * as pairingCodeActions from '../../actions/pairingCodes';
import {
  selectSoundZones,
  selectSoundZonesFetching,
} from '../../selectors/soundZones';
import {
  selectPairingCodes,
  selectPairingCodesGenerating,
} from '../../selectors/pairingCodes';
import {
  selectIsEnterpriseUser,
  selectUserProfile,
} from '../../selectors/user';
import { selectPlaylistsById } from '../../selectors/v2/playlists';
import { selectDeviceErrorsById } from '../../selectors/v2/devices';
import config from '../../config';
import {
  getDeviceConnectionStatus,
  canShareResource,
  canDeleteResource,
  getDeviceType,
  is8kResolutionSupported,
  is4kResolutionSupported,
} from '../../utilities';
import Popover from '../../components/Popover';
import PropertiesTable from '../../components/PropertiesTable';
import UnregisterDeviceConfirmation from '../../components/UnregisterDeviceConfirmation';
import DeviceStatus from '../DevicesPage/DeviceStatus';
import DeviceSharing from './DeviceSharing';
import DeviceTags from './DeviceTags';
import getDeviceContentWarning from './getDeviceContentWarning';
import Row from 'raydiant-elements/layout/Row/Row';
import TimeZoneSelect from '../../components/TimeZoneSelect';
import useCurrentUserRoles from '../../hooks/useCurrentUserRoles';
import DeviceIntegrationManager from '../../components/DeviceIntegrationManager';
import { useTranslation } from 'react-i18next';

const fromTimeFieldValue = (time: string | null) => {
  if (!time) return '';

  return moment(time, 'HH:mm').format('HH:mm:ss');
};

const toTimeFieldValue = (time: string | null) => {
  if (!time) return '';

  return moment(time, 'HH:mm:ss').format('HH:mm');
};

const maximumDeviceUpdateBlackoutRange = 21;

interface DeviceSettingsProps {
  device: D.Device;
  lastLoadedDate: string;
  onClose: () => void;
  isVIOnly?: boolean;
}

const DeviceSettings = ({
  device: initialDevice,
  lastLoadedDate,
  onClose,
  isVIOnly = false,
}: DeviceSettingsProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [device] = useState(initialDevice); //initialize device props to address race condition when creating new tags
  const roles = useCurrentUserRoles();

  // Selectors

  const soundZones = useSelector(selectSoundZones);
  const soundZonesFetching = useSelector(selectSoundZonesFetching);
  const pairingCodes = useSelector(selectPairingCodes);
  const pairingCodesGenerating = useSelector(selectPairingCodesGenerating);
  const currentUser = useSelector(selectUserProfile);
  const isEnterpriseUser = useSelector(selectIsEnterpriseUser);
  const playlistsById = useSelector(selectPlaylistsById);
  const deviceErrorsById = useSelector(selectDeviceErrorsById);

  const deviceSoundZone = soundZones.find((sz) => sz.deviceId === device.id);
  const isFetchingSoundZone = soundZonesFetching.includes(device.id);
  const devicePairingCode = pairingCodes.find((c) => c.id === device.id);
  const isGeneratingPairingCode = pairingCodesGenerating.includes(device.id);
  const playlist = playlistsById[device.playlistId || ''] ?? null;
  const deviceErrors = deviceErrorsById[device.id] || [];

  const contentWarning =
    playlist && currentUser
      ? getDeviceContentWarning(device, playlist, currentUser)
      : null;

  // State
  const [updatedName, setName] = useState<string | null>(null);
  const [updatedNotes, setNotes] = useState<string | null>(null);
  const [updatedResolution, setResolution] = useState<string | null>(null);
  const [updatedTimezone, setTimezone] = useState<string | null>(null);
  const [updatedOrientation, setOrientation] = useState<string | null>(null);
  const [updatedMaintenanceWindowEnabled, setMaintenanceWindowEnabled] =
    useState<boolean | null>(null);
  const [activeHoursStart, setActiveHoursStart] = useState<string>('');
  const [activeHoursEnd, setActiveHoursEnd] = useState<string>('');
  const [maintenanceWindowValid, setMaintenanceWindowValid] =
    useState<boolean>(true);
  const [dailyRebootTime, setDailyRebootTime] = useState<string>('');
  const [dailyRestartTime, setDailyRestartTime] = useState<string>('');
  const [musicServiceStatus, setMusicServiceStatus] = useState<boolean>(
    device?.services?.includes('music'),
  );
  const [restartStatus, setRestartStatus] = useState<
    '' | 'pending' | 'success' | 'error'
  >('');
  const [rebootStatus, setRebootStatus] = useState<
    '' | 'pending' | 'success' | 'error'
  >('');
  const [isUnregisterConfirmationOpen, setIsUnregisterConfirmationOpen] =
    useState(false);
  const [unregisterStatus, setUnregisterStatus] = useState<
    '' | 'pending' | 'success' | 'error'
  >('');
  const [rebootDeviceConfirmationOpen, setRebootDeviceConfirmationOpen] =
    useState<boolean>(false);
  const [rebootDeviceButtonEl, setRebootDeviceButtonEl] =
    useState<Element | null>(null);

  // Callbacks

  const updateDevice = useCallback(
    (updatedDevice: Partial<D.Device>) => {
      if (!device) return;
      dispatch(
        deviceActions.updateDevice(
          {
            ...device,
            ...updatedDevice,
          },
          {},
        ),
      );
    },
    [dispatch, device],
  );

  const updateName = useCallback(() => {
    if (!device) return;
    if (updatedName === null) return;
    if (updatedName === device.name) return;
    updateDevice({ name: updatedName });
  }, [updateDevice, updatedName, device]);

  const updateNotes = useCallback(() => {
    if (!device) return;
    if (updatedNotes === null) return;
    if (updatedNotes === device.name) return;
    updateDevice({ description: updatedNotes });
  }, [updateDevice, updatedNotes, device]);

  const updateDailyRebootTime = useCallback(() => {
    if (!device) return;
    if (dailyRebootTime === null) return;
    if (fromTimeFieldValue(dailyRebootTime) === device.dailyRebootTime) {
      return;
    }

    if (dailyRebootTime === '') {
      updateDevice({ dailyRebootTime: null });
    } else {
      updateDevice({
        dailyRebootTime: fromTimeFieldValue(dailyRebootTime),
      });
    }
  }, [updateDevice, dailyRebootTime, device]);

  const validate = useCallback(() => {
    let isValid = true;
    setMaintenanceWindowValid(isValid);

    // skip validation check if either timefield value is empty
    if (!activeHoursStart || !activeHoursEnd) {
      return;
    }

    const startTime = moment(activeHoursStart, 'HH:mm:ss');
    const endTime = moment(activeHoursEnd, 'HH:mm:ss');

    let duration = moment.duration(endTime.diff(startTime));
    // if start date is greater than end date, add a day to the end date and re-calculate difference
    if (duration.hours() < 0) {
      endTime.add(1, 'day');
      duration = moment.duration(endTime.diff(startTime));
    }

    if (
      duration.hours() > maximumDeviceUpdateBlackoutRange ||
      (duration.hours() === maximumDeviceUpdateBlackoutRange &&
        duration.minutes() > 0)
    ) {
      isValid = false;
    }

    setMaintenanceWindowValid(isValid);
    return isValid;
  }, [activeHoursStart, activeHoursEnd]);

  const updateActiveHoursStartTime = useCallback(() => {
    if (!device) return;
    if (!validate()) return;

    if (
      fromTimeFieldValue(activeHoursStart) === device.activeHoursStart &&
      fromTimeFieldValue(activeHoursEnd) === device.activeHoursEnd
    ) {
      return;
    }

    updateDevice({
      activeHoursStart: fromTimeFieldValue(activeHoursStart),
      activeHoursEnd: fromTimeFieldValue(activeHoursEnd),
    });
  }, [updateDevice, activeHoursStart, activeHoursEnd, device, validate]);

  const updateActiveHoursEndTime = useCallback(() => {
    if (!device) return;
    if (!validate()) return;

    if (
      fromTimeFieldValue(activeHoursStart) === device.activeHoursStart &&
      fromTimeFieldValue(activeHoursEnd) === device.activeHoursEnd
    ) {
      return;
    }

    updateDevice({
      activeHoursStart: fromTimeFieldValue(activeHoursStart),
      activeHoursEnd: fromTimeFieldValue(activeHoursEnd),
    });
  }, [updateDevice, activeHoursEnd, activeHoursStart, device, validate]);

  const updateDailyRestartTime = useCallback(() => {
    if (!device) return;
    if (dailyRestartTime === null) return;
    if (fromTimeFieldValue(dailyRestartTime) === device.dailyRestartTime) {
      return;
    }

    if (dailyRestartTime === '') {
      updateDevice({ dailyRestartTime: null });
    } else {
      updateDevice({
        dailyRestartTime: fromTimeFieldValue(dailyRestartTime),
      });
    }
  }, [updateDevice, dailyRestartTime, device]);

  const restartDevice = useCallback(async () => {
    setRestartStatus('pending');

    try {
      await miraClient.restartDevice(device.id);
      setRestartStatus('success');
    } catch (err) {
      setRestartStatus('error');
    }
  }, [device]);

  const openRebootDeviceConfirmation = useCallback((event) => {
    setRebootDeviceButtonEl(event.currentTarget);
    setRebootDeviceConfirmationOpen(true);
  }, []);

  const closeRebootDeviceConfirmation = useCallback(() => {
    setRebootDeviceConfirmationOpen(false);
    setRebootDeviceButtonEl(null);
  }, []);

  const rebootDevice = useCallback(async () => {
    setRebootStatus('pending');

    try {
      await miraClient.rebootDevice(device.id);
      setRebootStatus('success');
    } catch (err) {
      setRebootStatus('error');
    } finally {
      closeRebootDeviceConfirmation();
    }
  }, [device, closeRebootDeviceConfirmation]);

  const resolveUnregisterConfirmation = useRef<
    ((result: boolean) => void) | null
  >(null);

  const showUnregisterConfirmation = useCallback(async (): Promise<boolean> => {
    return new Promise((resolve) => {
      resolveUnregisterConfirmation.current = (result: boolean) => {
        resolveUnregisterConfirmation.current = null;
        setIsUnregisterConfirmationOpen(false);
        resolve(result);
      };

      setIsUnregisterConfirmationOpen(true);
    });
  }, [resolveUnregisterConfirmation, setIsUnregisterConfirmationOpen]);

  const unregisterDevice = useCallback(async () => {
    try {
      const userConfirmedRemove = await showUnregisterConfirmation();
      if (!userConfirmedRemove) {
        return;
      }
      setUnregisterStatus('pending');
      await miraClient.unregisterDevice(device.id);
      setUnregisterStatus('success');
      dispatch(deviceActions.removeDevice({ deviceId: device.id }));
      onClose();
    } catch (err) {
      setUnregisterStatus('error');
    }
  }, [device, dispatch, showUnregisterConfirmation, onClose]);

  // Side-effects

  // Fetch sound zone on load and periodically.
  useEffect(() => {
    const fetchSoundZone = () => {
      if (document.hidden) return;
      dispatch(soundZoneActions.fetchSoundZone(device.id));
    };

    // Fetch when window becomes active.
    window.addEventListener('visibilitychange', fetchSoundZone, false);

    // Fetch periodically.
    const interval = window.setInterval(
      fetchSoundZone,
      config.SYBSoundZonePollMS,
    );

    // Fetch on page load.
    fetchSoundZone();

    return () => {
      window.removeEventListener('visibilitychange', fetchSoundZone, false);
      clearInterval(interval);
    };
  }, [dispatch, device.id]);

  // Fetch pairing code on load.
  useEffect(() => {
    if (devicePairingCode) return;
    dispatch(pairingCodeActions.generatePairingCode(device.id));
  }, [dispatch, device, devicePairingCode]);

  // Update device resolution when changed.
  useEffect(() => {
    if (!device) return;
    if (updatedResolution === null) return;
    if (updatedResolution === device.screenDimensions) return;
    updateDevice({ screenDimensions: updatedResolution });
  }, [updateDevice, updatedResolution, device]);

  // Update device timezone when changed.
  useEffect(() => {
    if (!device) return;
    if (updatedTimezone === null) return;
    if (updatedTimezone === device.timezone) return;
    updateDevice({ timezone: updatedTimezone });
  }, [updateDevice, updatedTimezone, device]);

  // Update device orientation when changed.
  useEffect(() => {
    if (!device) return;
    if (updatedOrientation === null) return;
    if (updatedOrientation === device.screenOrientation) return;
    updateDevice({
      screenOrientation: updatedOrientation as D.ScreenOrientation,
    });
  }, [updateDevice, updatedOrientation, device]);

  // Update maintenance window toggle status when changed.
  useEffect(() => {
    if (!device) return;
    if (!device.activeHoursStart || !device.activeHoursStart) return;
    if (updatedMaintenanceWindowEnabled === null) return;

    if (!updatedMaintenanceWindowEnabled) {
      updateDevice({
        activeHoursStart: null,
        activeHoursEnd: null,
      });
    }
  }, [updateDevice, updatedMaintenanceWindowEnabled, device]);

  // Update active hour start/end time when changed
  useEffect(() => {
    if (!device) return;
    setActiveHoursStart(toTimeFieldValue(device.activeHoursStart));
    setActiveHoursEnd(toTimeFieldValue(device.activeHoursEnd));
  }, [device]);

  // Update daily reboot/reset times when changed
  useEffect(() => {
    if (!device) return;
    setDailyRestartTime(toTimeFieldValue(device.dailyRestartTime));
    setDailyRebootTime(toTimeFieldValue(device.dailyRebootTime));
  }, [device]);

  const onMusicServiceChange = useCallback(async () => {
    try {
      if (musicServiceStatus) {
        await miraClient.disableDeviceService(device.id, 'music');
        setMusicServiceStatus(false);
      } else {
        await miraClient.enableDeviceService(device.id, 'music');
        setMusicServiceStatus(true);
      }
    } catch (err) {
      return;
    }
  }, [device, musicServiceStatus]);

  // Render

  const { isOnline, signalStrength, wifiSsid } = getDeviceConnectionStatus(
    device,
    lastLoadedDate,
  );

  const isEthernet = device.metrics?.connectionType === 'ethernet';
  const isLTE = device.metrics?.connectionType === 'lte';
  const isWifi = !isEthernet && !isLTE;

  const isShareable =
    isEnterpriseUser &&
    currentUser &&
    canShareResource(currentUser, device.resource);

  const isDeleteable =
    currentUser && canDeleteResource(currentUser, device.resource);

  const isPreBelenaDevice = !device.resinUuid && !device.isLite;

  const deviceType = getDeviceType(device);

  const maintenanceWindowSet = !!(
    device.activeHoursStart && device.activeHoursEnd
  );

  const maintenanceWindowEnabled =
    updatedMaintenanceWindowEnabled ?? maintenanceWindowSet ?? false;

  const renderIntegration = () =>
    !device.isLite &&
    !isPreBelenaDevice && <DeviceIntegrationManager device={device} />;

  const { t } = useTranslation(['devices']);

  const isDeviceSoC = device.deviceType === 'SoC';

  return (
    <>
      <PaperModal.Body>
        <Column>
          <div>
            <Heading className={classes.title}>
              <Text
                editable
                value={updatedName ?? device.name ?? ''}
                onChange={setName}
                onBlur={updateName}
              />
            </Heading>
            {deviceType && (
              <Heading size={5} className={classes.type}>
                {deviceType}
              </Heading>
            )}
          </div>
          <DeviceStatus
            device={device}
            contentWarning={contentWarning}
            deviceErrors={deviceErrors}
            lastLoadedDate={lastLoadedDate}
          />
        </Column>
        <br />
      </PaperModal.Body>
      <Scrollable>
        <PaperModal.Body>
          <div className={classes.section}>
            <Column doubleMargin>
              <div>
                <Heading size={5} overline gutterBottom>
                  Properties
                </Heading>
                <PropertiesTable>
                  <tbody>
                    {isEnterpriseUser && (
                      <>
                        <tr>
                          <th>{isDeviceSoC ? 'Added By' : 'Owner'}</th>
                          <td>{device.resource.profile.name}</td>
                        </tr>
                      </>
                    )}
                    {(!device.isLite || isDeviceSoC) && (
                      <tr>
                        <th>MAC address</th>
                        <td>{device.id}</td>
                      </tr>
                    )}
                    <tr>
                      <th>Activated</th>
                      <td>
                        {device.registeredAt
                          ? moment(device.registeredAt).format('MMM Do, YYYY')
                          : 'N/A'}
                      </td>
                    </tr>
                    {isDeviceSoC && (
                      <>
                        <tr>
                          <th>Manufacturer</th>
                          <td>{device.screenManufacturer}</td>
                        </tr>
                        <tr>
                          <th>Model</th>
                          <td>{device.screenModel}</td>
                        </tr>
                        <tr>
                          <th>OS Version</th>
                          <td>{device.screenOsVersion}</td>
                        </tr>
                        <tr>
                          <th>Serial Number</th>
                          <td>{device.screenSerialNumber}</td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </PropertiesTable>
              </div>

              <TextField
                label="Notes"
                multiline
                value={updatedNotes ?? device.description ?? ''}
                onChange={setNotes}
                onBlur={updateNotes}
              />

              {!device.isLite && !isVIOnly && !isDeviceSoC && (
                <SelectField
                  label="Screen Resolution"
                  value={updatedResolution ?? device.screenDimensions ?? ''}
                  onChange={setResolution}
                >
                  {roles.is4kEnabled &&
                    is8kResolutionSupported(device.model) && (
                      <option value="7680×4320">8K [UHD]</option>
                    )}
                  {roles.is4kEnabled &&
                    is4kResolutionSupported(device.model) && (
                      <option value="3840x2160">4K [UHD]</option>
                    )}
                  <option value="1920x1080">1080p</option>
                  <option value="1280x720">720p</option>
                </SelectField>
              )}

              {!device.isLite && !isDeviceSoC && (
                <TimeZoneSelect
                  label="Timezone"
                  onChange={setTimezone}
                  value={updatedTimezone ?? device.timezone ?? ''}
                />
              )}

              {!isVIOnly && !isDeviceSoC && (
                <SelectField
                  label={t('screens.screenOrientation')!}
                  value={updatedOrientation ?? device.screenOrientation ?? ''}
                  onChange={setOrientation}
                >
                  <option value="normal">{t('screens.landscape')!}</option>
                  <option value="right">{t('screens.portraitRight')!}</option>
                  <option value="left">{t('screens.portraitLeft')!}</option>
                </SelectField>
              )}
            </Column>
          </div>

          {roles.isUserAIEnabled && renderIntegration()}

          {(!device.isLite || isDeviceSoC) && !isPreBelenaDevice && (
            <div className={classes.section}>
              <Heading size={5} overline gutterBottom>
                Connectivity
              </Heading>
              <PropertiesTable>
                <tbody>
                  <tr>
                    <th>Connection type</th>
                    <td>
                      {isEthernet && (
                        <div className={classes.cellWithIcon}>
                          <span>Ethernet</span>
                          <EthernetIcon />
                        </div>
                      )}
                      {isLTE && (
                        <div className={classes.cellWithIcon}>
                          <span>LTE</span>
                          <LTEIcon />
                        </div>
                      )}
                      {isWifi && (
                        <div className={classes.cellWithIcon}>
                          <span>Wireless</span>
                          <WifiIcon />
                        </div>
                      )}
                    </td>
                  </tr>
                  {!isEthernet && (
                    <tr>
                      <th>Signal strength</th>
                      <td>
                        {isOnline && (
                          <div className={classes.cellWithIcon}>
                            <span>{signalStrength}</span>
                            <CheckCircleIcon />
                          </div>
                        )}
                        {!isOnline && (
                          <div className={classes.cellWithIcon}>
                            <span>No signal</span>
                            <ErrorIcon />
                          </div>
                        )}
                      </td>
                    </tr>
                  )}
                  {isWifi && (
                    <tr>
                      <th>Network</th>
                      <td>
                        <div className={classes.cellWithIcon}>
                          {wifiSsid ? (
                            <span>{wifiSsid}</span>
                          ) : (
                            <span>N/A</span>
                          )}
                          <NetworkIcon />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </PropertiesTable>
            </div>
          )}

          {
            <div className={classes.section}>
              <DeviceTags device={device} />
            </div>
          }

          {isShareable && (
            <div className={classes.section}>
              <DeviceSharing device={device} />
            </div>
          )}

          <div className={classes.section}>
            <Column>
              <Heading size={5} overline gutterBottom>
                Device Management
              </Heading>

              {!device.isLite && (
                <Switch
                  checked={maintenanceWindowEnabled}
                  onChange={setMaintenanceWindowEnabled}
                  label="device update blackout"
                  helperText="Specifies a time of day in which no software updates should occur."
                />
              )}

              {maintenanceWindowEnabled && (
                <Row halfMargin>
                  <TimeField
                    helperText={
                      <span>
                        Maximum range of {maximumDeviceUpdateBlackoutRange}{' '}
                        hours.{' '}
                        <Link
                          href="https://support.raydiant.com/hc/en-us/articles/360052905832#update-blackout-window"
                          target="_blank"
                        >
                          Learn more
                        </Link>
                      </span>
                    }
                    label="from"
                    value={activeHoursStart}
                    onChange={setActiveHoursStart}
                    error={!maintenanceWindowValid}
                    onBlur={updateActiveHoursStartTime}
                  />
                  <TimeField
                    label="to"
                    value={activeHoursEnd}
                    onChange={setActiveHoursEnd}
                    error={!maintenanceWindowValid}
                    onBlur={updateActiveHoursEndTime}
                  />
                </Row>
              )}

              {!isPreBelenaDevice && (
                <>
                  <div>
                    <Row halfMargin className={classes.section1}>
                      <div>
                        <Button
                          fullWidth
                          icon={<SettingsPowerIcon />}
                          label={
                            restartStatus === 'pending'
                              ? 'Restarting...'
                              : 'Restart'
                          }
                          disabled={restartStatus === 'pending'}
                          onClick={restartDevice}
                          color={'primary'}
                        />
                        <InputHelperText>
                          Will restart the software.{' '}
                          <Link
                            href={
                              'https://support.raydiant.com/hc/en-us/articles/360052905832#restart-reboot'
                            }
                            target="_blank"
                          >
                            Learn more
                          </Link>
                        </InputHelperText>
                        {restartStatus === 'error' && (
                          <InputHelperText error>
                            Failed to restart, please try again later.
                          </InputHelperText>
                        )}
                      </div>
                      {!device.isLite && (
                        <TimeField
                          helperText="daily"
                          label="Repeats"
                          value={dailyRestartTime}
                          onChange={setDailyRestartTime}
                          onBlur={updateDailyRestartTime}
                        />
                      )}
                    </Row>
                  </div>

                  {!device.isLite && (
                    <div>
                      <Row halfMargin className={classes.section1}>
                        <div>
                          <Button
                            fullWidth
                            icon={<SettingsPowerIcon />}
                            label={
                              rebootStatus === 'pending'
                                ? 'Rebooting...'
                                : 'Reboot'
                            }
                            disabled={rebootStatus === 'pending'}
                            onClick={openRebootDeviceConfirmation}
                          />
                          <InputHelperText>
                            Will reboot the software.{' '}
                            <Link
                              href={
                                'https://support.raydiant.com/hc/en-us/articles/360052905832#restart-reboot'
                              }
                              target="_blank"
                            >
                              Learn more
                            </Link>
                          </InputHelperText>
                          {rebootStatus === 'error' && (
                            <InputHelperText error>
                              Failed to reboot, please try again later.
                            </InputHelperText>
                          )}
                        </div>
                        <TimeField
                          helperText="daily"
                          label="Repeats"
                          value={dailyRebootTime}
                          onChange={setDailyRebootTime}
                          onBlur={updateDailyRebootTime}
                        />
                      </Row>
                      <Popover
                        variant="alert"
                        open={rebootDeviceConfirmationOpen}
                        anchorEl={rebootDeviceButtonEl}
                        onClose={closeRebootDeviceConfirmation}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <Column>
                          <div>
                            <Heading2>Reboot Will...</Heading2>
                            <ul>
                              <li>
                                Cause this device to disconnect and attempt to
                                reconnect to the network.
                              </li>
                              <li>
                                It will not be accessible from the Dashboard
                                during this time.
                              </li>
                              <li>
                                Please use caution if you do not have physical
                                access to this device.
                              </li>
                            </ul>
                          </div>
                          <Row>
                            <Button
                              fullWidth
                              label="Dismiss"
                              onClick={closeRebootDeviceConfirmation}
                            />
                            <Button
                              fullWidth
                              icon={<SettingsPowerIcon />}
                              label={
                                rebootStatus === 'pending'
                                  ? 'Rebooting...'
                                  : 'Reboot'
                              }
                              disabled={rebootStatus === 'pending'}
                              color={'primary'}
                              onClick={rebootDevice}
                            />
                          </Row>
                        </Column>
                      </Popover>
                    </div>
                  )}
                </>
              )}

              {isDeleteable && (
                <div>
                  <Button
                    fullWidth
                    color="destructive"
                    icon={<HighlightOffIcon />}
                    label={
                      unregisterStatus === 'pending'
                        ? 'Removing...'
                        : `${
                            roles.isVIOnlyEnabled
                              ? 'Remove Device'
                              : 'Remove Screen'
                          }`
                    }
                    disabled={unregisterStatus === 'pending'}
                    onClick={unregisterDevice}
                  />
                  {unregisterStatus === 'error' && (
                    <InputHelperText error>
                      Failed to unregister, please try again later.
                    </InputHelperText>
                  )}
                </div>
              )}
            </Column>
          </div>

          {!device.isLite && !isPreBelenaDevice && (
            <div className={classes.section}>
              <Column>
                <Heading size={5} overline gutterBottom>
                  Soundtrack Your Brand
                  <br />
                  <Text small className={classes.sybLink}>
                    <Link
                      href="https://support.raydiant.com/hc/en-us/articles/360019314214"
                      target="_blank"
                    >
                      What's this?
                    </Link>
                  </Text>
                </Heading>

                <PropertiesTable>
                  <tbody>
                    <tr>
                      <th>Pairing code</th>
                      <td>
                        {isGeneratingPairingCode && <span>Checking...</span>}
                        {devicePairingCode && !isGeneratingPairingCode && (
                          <span>{devicePairingCode.pairingCode}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Status</th>
                      <td>
                        {isFetchingSoundZone && <span>Checking...</span>}
                        {deviceSoundZone && !isFetchingSoundZone && (
                          <span>Paired</span>
                        )}
                        {!deviceSoundZone && !isFetchingSoundZone && (
                          <span>Not paired</span>
                        )}
                      </td>
                    </tr>
                    {deviceSoundZone && (
                      <tr>
                        <th>Zone</th>
                        <td>{deviceSoundZone.name}</td>
                      </tr>
                    )}
                    <tr>
                      <th>Enable</th>
                      <td>
                        <Switch
                          checked={musicServiceStatus}
                          onChange={onMusicServiceChange}
                        />
                      </td>
                    </tr>
                  </tbody>
                </PropertiesTable>
              </Column>
            </div>
          )}
        </PaperModal.Body>
      </Scrollable>

      <UnregisterDeviceConfirmation
        open={isUnregisterConfirmationOpen}
        onConfirm={resolveUnregisterConfirmation.current}
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    title: {
      marginBottom: theme.spacing(0.5),
    },

    type: {
      margin: 0,
    },

    cellWithIcon: {
      display: 'flex',
      justifyContent: 'space-between',
    },

    section: {
      marginBottom: theme.spacing(5),

      '&:last-child': {
        marginBottom: 0,
      },
    },

    sybLink: {
      fontWeight: 400,
      textTransform: 'none',
      letterSpacing: 0,
    },

    section1: {
      display: 'flex',
      alignItems: 'flex-end',
      '& :first-child': {
        flex: 7,
      },
      '& :nth-child(2)': {
        flex: 5,
      },
    },
  });
});

export default DeviceSettings;
