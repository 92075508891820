import { useState, useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import Heading from 'raydiant-elements/core/Heading';
import PaperModal from 'raydiant-elements/core/PaperModal';
import Column from 'raydiant-elements/layout/Column';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import Center from 'raydiant-elements/layout/Center';
import Button from 'raydiant-elements/core/Button';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import LoadingButton from '../../../components/LoadingButton';
import { LoadingStatus } from '../../../components/LoadingButton/LoadingButton';
import * as D from '../../../clients/mira/types/Device';
import apiClient from '../../../clients/miraClient';
import {
  initialPerchSettings,
  formatV2PerchSettings,
} from '../devicePerchSettingsUtils';
import { SettingsConfig } from '../devicePerchSettings.interface';
import useDevicePerchSettings from '../../../hooks/useDevicePerchSettings';
import DevicePerchSettingsConfigManager from './DevicePerchSettingsConfigManager';
import _ from 'lodash';
import { V2PerchviewSettings } from '@raydiant/api-client-js';
import TextField from 'raydiant-elements/core/TextField';

interface DevicePerchSettingsProps {
  device: D.Device;
}

const DevicePerchSettings = ({ device }: DevicePerchSettingsProps) => {
  const [perchSettings, setPerchSettings] =
    useState<V2PerchviewSettings>(initialPerchSettings);
  const [submitStatus, setSubmitStatus] = useState<LoadingStatus>('idle');
  const [settings, setSettings] = useState<SettingsConfig>({});

  const { data, isLoading } = useDevicePerchSettings(device.id);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const updatedPerchSettings = useMemo(
    () =>
      ({
        ...perchSettings,
        settings: _.isEmpty(settings) ? perchSettings.settings : settings,
      } as V2PerchviewSettings),
    [perchSettings, settings],
  );

  const isSettingChanged = !_.isEqual(perchSettings, updatedPerchSettings);

  const handleImport = (settingsConfig: SettingsConfig) => {
    setSettings(settingsConfig);
  };

  const handleExport = () => {
    const fileData = JSON.stringify(updatedPerchSettings.settings, null, 2);
    const blob = new Blob([fileData], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = 'regions.json';
    link.href = url;
    link.click();
  };

  const handleCreatePerchSettings = async () => {
    try {
      setSubmitStatus('loading');
      const formattedPerchSettings =
        formatV2PerchSettings(updatedPerchSettings);
      const response = await apiClient.setDeviceV2PerchviewSettings(
        device.id,
        formattedPerchSettings,
      );
      setPerchSettings(response);
      setSubmitStatus('success');
    } catch (error) {
      console.log('ERROR CREATING PERCH SETTINGS', error);

      enqueueSnackbar('There was an error creatubg settings', {
        variant: 'error',
      });
      setSubmitStatus('error');
    }
  };

  const handleUpdatePerchSettings = async () => {
    try {
      setSubmitStatus('loading');
      const formattedPerchSettings =
        formatV2PerchSettings(updatedPerchSettings);
      const response = await apiClient.updateDeviceV2PerchviewSettings(
        device.id,
        formattedPerchSettings,
      );
      setPerchSettings(response);
      setSubmitStatus('success');
    } catch (error) {
      console.log('ERROR UPDATING PERCH SETTINGS', error);

      enqueueSnackbar('There was an error updating settings', {
        variant: 'error',
      });
      setSubmitStatus('error');
    }
  };

  // Side Effects
  useEffect(() => {
    if (data) {
      setPerchSettings(data);
    }
  }, [data]);

  const isInitialPerchSetting = _.isEmpty(updatedPerchSettings.settings || {});
  const isSettingsEmpty = _.isEmpty(settings);
  const disableButton =
    (isInitialPerchSetting && isSettingsEmpty) || !isSettingChanged;

  const renderSettingsConfig = () => {
    if (_.isEmpty(settings)) {
      if (_.isEmpty(perchSettings.settings)) {
        return 'Please import configuration from file.';
      } else {
        return JSON.stringify(perchSettings.settings, null, 2);
      }
    } else {
      return JSON.stringify(settings, null, 2);
    }
  };

  if (isLoading) {
    return (
      <Center>
        <CircularProgress size={30} />
      </Center>
    );
  }

  return (
    <>
      <PaperModal.Body>
        <Column>
          <div>
            <Heading size={5} gutterBottom>
              RX Settings
            </Heading>
          </div>
        </Column>
        <br />
      </PaperModal.Body>

      <Scrollable>
        <PaperModal.Body className={classes.content}>
          <div className={classes.section}>
            <DevicePerchSettingsConfigManager onImportClick={handleImport} />
            <div className={classes.perchConfig}>
              <TextField
                label="JSON"
                multiline
                value={renderSettingsConfig()}
                onChange={() => {}}
                onBlur={() => {}}
                disabled={true}
              />
            </div>
          </div>
        </PaperModal.Body>
      </Scrollable>
      <PaperModal.Body className={classes.footer}>
        <div>
          <Button
            className={classes.button}
            color="default"
            label="Export current configuration as a JSON file"
            fullWidth
            onClick={() => handleExport()}
          />
          <LoadingButton
            color="primary"
            label="Save Settings"
            disabled={disableButton}
            iconAlignment="end"
            fullWidth
            status={submitStatus}
            successLabel="Saved"
            loadingLabel="Saving..."
            errorLabel="Failed!"
            delay={1000}
            onClick={() =>
              data ? handleUpdatePerchSettings() : handleCreatePerchSettings()
            }
          />
        </div>
      </PaperModal.Body>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    footer: {
      position: 'relative',
      bottom: '0px',
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      paddingTop: theme.spacing(4),
      borderBottomLeftRadius: theme.spacing(1.5),
      borderBottomRightRadius: theme.spacing(1.5),
      boxShadow:
        '0px -3px 3px -2px rgb(0 0 0 / 20%), 0px -3px 4px 0px rgb(0 0 0 / 14%), 0px -1px 8px 0px rgb(0 0 0 / 12%)',
    },

    headingTitle: {
      marginBottom: 0,
    },

    button: {
      marginBottom: theme.spacing(2),
    },

    content: {
      paddingTop: '0px !important',
    },

    section: {
      marginBottom: theme.spacing(5),

      '&:last-child': {
        marginBottom: 0,
      },
    },

    error: {
      textAlign: 'center',
    },

    perchConfig: {
      '&>div>div': {
        height: '400px',
        minHeight: '400px',
      },
    },
  });
});

export default DevicePerchSettings;
