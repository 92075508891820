import DropDownPlaceholder from '../../../../components/DropDown/DropDownPlaceholder';
import { useTranslation } from 'react-i18next';
import ScreenOwnersSelect from './ScreenOwnersSelect';
import { ResourceProfile } from '@raydiant/api-client-js';
import SharedWithSelect from './SharedWithSelect';
import { Popover } from '@headlessui/react';
import classNames from 'classnames';
import { useState } from 'react';

type UsersDropDownProps = {
  className?: string;
  onChange: (value: UsersQuery) => void;
  value: UsersQuery;
};

export type UsersQuery = {
  screenOwners: ResourceProfile['id'][];
  sharedWith: ResourceProfile['id'][];
};

const UsersDropDown = ({ className, onChange, value }: UsersDropDownProps) => {
  const { t } = useTranslation(['devices']);

  const screenOwnersCount = Object.values(value?.screenOwners ?? [])?.length;
  const sharedWithCount = Object.values(value?.sharedWith ?? [])?.length;
  const selectedFiltersCount = screenOwnersCount + sharedWithCount;

  const [open, setOpen] = useState(false);

  return (
    <Popover className={classNames('relative', className)}>
      <Popover.Button
        className="input no-label text-left w-full"
        onClick={() => setOpen(true)}
      >
        <DropDownPlaceholder className="w-full md:min-w-[102px]">
          <div className="flex items-center justify-between w-full">
            <p>{t('screens.users')}</p>
            {selectedFiltersCount > 0 && (
              <span className="mr-1 text-primary">
                ({selectedFiltersCount})
              </span>
            )}
          </div>
        </DropDownPlaceholder>
      </Popover.Button>
      {open && (
        <>
          <div className="inset-0 fixed z-[8]" onClick={() => setOpen(false)} />
          <Popover.Panel
            className="absolute z-[8] card menu dropdown p-0 min-w-min w-full md:!min-w-[380px] !max-h-max left-0 !overflow-visible"
            static
            focus
          >
            <div className="p-6 text-black flex flex-col gap-3">
              <ScreenOwnersSelect
                className=""
                onChange={(profile) => {
                  onChange({
                    ...value,
                    screenOwners: [...value?.screenOwners, profile.id],
                  });
                }}
                onClear={() => onChange({ ...value, screenOwners: [] })}
                onRemoveProfile={(id) =>
                  onChange({
                    ...value,
                    screenOwners: [...value?.screenOwners].filter(
                      (profileId) => profileId !== id,
                    ),
                  })
                }
                value={value?.screenOwners ?? []}
              />
              <SharedWithSelect
                className=""
                onChange={(profile) => {
                  onChange({
                    ...value,
                    sharedWith: [...value?.sharedWith, profile.id],
                  });
                }}
                onClear={() => onChange({ ...value, sharedWith: [] })}
                onRemoveProfile={(id) =>
                  onChange({
                    ...value,
                    sharedWith: [...value?.sharedWith].filter(
                      (profileId) => profileId !== id,
                    ),
                  })
                }
                value={value?.sharedWith ?? []}
              />
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default UsersDropDown;
