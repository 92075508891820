import { RouteComponentProps } from 'react-router-dom';
import Page from '../../components/Page';
import PageLayout from '../../components/PageLayout/PageLayout';
import PageTitle from '../../components/PageLayout/PageTitle';
import useCatalogItems from '../../hooks/useCatalogItems';
import { useCatalogs } from '../../hooks';
import LoadingModal from '@raydiant/raydial/components/LoadingModal';
import { capitalize } from '../../utilities/stringUtils';
import Card from '@raydiant/raydial/components/Card';
import Pagination from '@raydiant/raydial/components/Pagination';
import Text from '@raydiant/raydial/components/Text';
import { Basket } from '@raydiant/raydial/components/Icons';
import zeroItemsSvg from '../../assets/zero-items.svg';
import { useEffect, useState } from 'react';
import PageSizeSelect from '../../components/PageSizeSelect/PageSizeSelect';

type RouteParams = {
  catalogId: string;
};

const ProductHubCatalogueDetailsPage = (
  props: RouteComponentProps<RouteParams>,
) => {
  const { params } = props.match;
  const { catalogId } = params;
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const catalogsQuery = useCatalogs();

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const productsQuery = useCatalogItems(catalogId, { page, pageSize });

  if (!catalogsQuery.data || !productsQuery.data) {
    return (
      <Page>
        <LoadingModal isOpen={true} />
      </Page>
    );
  }

  const { data: catalogs } = catalogsQuery.data;
  let { data: products, total: productsTotal } = productsQuery.data;

  const catalog = catalogs.find((c) => c.id === catalogId);

  if (!catalog) {
    return null;
  }

  const getProductsSubtitleText = () => {
    if (productsTotal === 0) {
      return 'Pending Items';
    }

    if (productsTotal === 1) {
      return '1 item';
    }

    return `${productsTotal} items`;
  };

  const productsSubtitleText = `${capitalize(
    catalog?.connection?.connector?.name || '',
  )}${'  '}•${' '}${getProductsSubtitleText()}`;

  const handlePageSizeChange = (page: number) => {
    setPage(page);
  };

  const count = Math.ceil(productsTotal / pageSize);

  return (
    <Page>
      <PageLayout title={<PageTitle title="Catalogs" />} className="text-black">
        <Card className="h-full">
          <div className="px-4 flex flex-col h-full">
            <header className="flex items-center">
              <div className="grid gap-1 py-4 mr-auto">
                <Text as="h2" variant="subheader">
                  {catalog.name}
                </Text>
                <Text as="h3" className="text-gray-400 font-regular text-lg">
                  {productsSubtitleText}
                </Text>
              </div>
            </header>

            <div className="rounded-2xl bg-gray-100 px-8 py-3 mb-1">
              <Text className="text-gray-600 font-semibold text-xl">ITEMS</Text>
            </div>

            <div className="px-9 flex flex-col gap-1 flex-1 mb-3">
              {productsTotal > 0 ? (
                products.map((product) => (
                  <div className="flex py-2 items-center" key={product.id}>
                    <div className="bg-blue-50 rounded-md text-blue-600 size-5 flex items-center justify-center mr-3">
                      <Basket />
                    </div>
                    <Text className="text-lg">{product.name}</Text>
                  </div>
                ))
              ) : (
                // There isn't a way to distinguish between 0 items and items that still haven't been synced yet.
                //  It's rare to actually have 0 items, so we accommodate for the most common use case which is items still syncing.
                <div className="flex items-center justify-center flex-col h-full">
                  <img
                    src={zeroItemsSvg}
                    alt="zero items placeholder"
                    className="mb-2"
                  />
                  <Text as="p" variant="smallSubheader" className="mb-2">
                    Hold Tight!
                  </Text>
                  <Text alignment="center">
                    We’re importing your items from your POS. This may take a
                    few minutes.
                  </Text>
                </div>
              )}
            </div>

            <div className="flex items-center justify-center relative">
              <Pagination
                count={count}
                onChange={handlePageSizeChange}
                page={page}
              />
              <div className="absolute right-0 flex items-center">
                <PageSizeSelect onChange={setPageSize} pageSize={pageSize} />
              </div>
            </div>
          </div>
        </Card>
      </PageLayout>
    </Page>
  );
};

export default ProductHubCatalogueDetailsPage;
