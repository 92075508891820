import DeviceAiSettingsGroup from '../DeviceAiSettingsGroup';
import useStyles from '../DeviceAISettings.styles';
import Switch from 'raydiant-elements/core/Switch';
import TextField from 'raydiant-elements/core/TextField/TextField';

interface DeviceAiSettingsUcmsData {
  csvEnabled?: boolean;
  csvPath?: string;
  enabled?: boolean;
  mode?: string;
  pushEnabled?: boolean;
  pushUrl?: string;
}

interface DeviceAiSettingsUcmsProps {
  data: DeviceAiSettingsUcmsData;
  onChangeEnabled?: (value: boolean) => void;
  onChangeCsvEnabled?: (value: boolean) => void;
  onChangeCsvPath?: (value: string) => void;
  onChangeMode?: (value: string) => void;
  onChangePushEnabled?: (value: boolean) => void;
  onChangePushUrl?: (value: string) => void;
  showCsvEnabled?: boolean;
  showCsvPath?: boolean;
  showEnabled?: boolean;
  showMode?: boolean;
  showPushEnabled?: boolean;
  showPushUrl?: boolean;
}

const DeviceAiSettingsUcms = ({
  data,
  onChangeEnabled,
  onChangeCsvEnabled,
  onChangeCsvPath,
  onChangeMode,
  onChangePushEnabled,
  onChangePushUrl,
  showEnabled = false,
  showCsvEnabled = false,
  showCsvPath = false,
  showMode = false,
  showPushEnabled = false,
  showPushUrl = false,
}: DeviceAiSettingsUcmsProps) => {
  const classes = useStyles();

  return (
    <DeviceAiSettingsGroup title="UCMS Settings">
      {showEnabled && (
        <li className={classes.aiSetting} key={'enabled'}>
          <Switch
            checked={data.enabled || false}
            onChange={onChangeEnabled}
            label={'Enabled'}
          />
        </li>
      )}
      {showMode && (
        <li className={classes.aiSetting} key={'mode'}>
          <TextField
            disabled={!data.enabled}
            label={'Mode'}
            value={data.mode}
            onChange={onChangeMode}
          />
        </li>
      )}
      {showCsvEnabled && (
        <li className={classes.aiSetting} key={'csv'}>
          <Switch
            disabled={!data.enabled}
            checked={data.csvEnabled || false}
            onChange={onChangeCsvEnabled}
            label={'CSV Enabled'}
          />
        </li>
      )}
      {showCsvPath && (
        <li className={classes.aiSetting} key={'csvPath'}>
          <TextField
            disabled={!data.enabled}
            label={'CSV path'}
            value={data.csvPath}
            onChange={onChangeCsvPath}
          />
        </li>
      )}
      {showPushEnabled && (
        <li className={classes.aiSetting} key={'pushEnabled'}>
          <Switch
            disabled={!data.enabled}
            checked={data.pushEnabled || false}
            onChange={onChangePushEnabled}
            label={'Push enabled'}
          />
        </li>
      )}
      {showPushUrl && (
        <li className={classes.aiSetting} key={'pushUrl'}>
          <TextField
            disabled={!data.enabled}
            label={'Push url'}
            value={data.pushUrl}
            onChange={onChangePushUrl}
          />
        </li>
      )}
    </DeviceAiSettingsGroup>
  );
};

export default DeviceAiSettingsUcms;
