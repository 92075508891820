import Modal from 'raydiant-elements/core/Modal';
import { Component } from 'react';
import { connect } from 'react-redux';
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import ApplicationsPage from '../../pages/ApplicationsPage';
import AddContentPage from '../../pages/AddContentPage';
import * as paths from '../../routes/paths';
import * as actions from './actions';
import mapStateToProps from './selectors';

interface ContentModalProps extends RouteComponentProps<{}> {
  contentPath: string;
  contentModalOpened: () => void;
  contentModalClosed: () => void;
}
interface ContentModalState {}

class ContentModal extends Component<ContentModalProps, ContentModalState> {
  state: ContentModalState = {};

  getLocation(contentPath: string) {
    const [pathname, search] = contentPath.split('?');
    return {
      pathname,
      search,
      state: null,
      hash: '',
    };
  }

  isOpen(props: ContentModalProps) {
    return !!props.contentPath;
  }

  componentDidUpdate(prevProps: ContentModalProps) {
    const { contentModalOpened, contentModalClosed } = this.props;

    if (this.isOpen(this.props) && !this.isOpen(prevProps)) {
      contentModalOpened();
    } else if (!this.isOpen(this.props) && this.isOpen(prevProps)) {
      contentModalClosed();
    }
  }

  render() {
    const { contentPath } = this.props;
    const isOpen = this.isOpen(this.props);
    const location = this.getLocation(contentPath);

    return (
      <Modal open={isOpen} testId="content-modal">
        <Switch location={location}>
          <Route
            path={paths.applications.pattern}
            render={(routeProps) => (
              <ApplicationsPage {...routeProps} isModal />
            )}
          />
          <Route
            path={paths.presentations.pattern}
            render={(routeProps) => <AddContentPage {...routeProps} isModal />}
          />
        </Switch>
      </Modal>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, { ...actions })(ContentModal),
);
