import { useTranslation } from 'react-i18next';
import ScreensFilterControl from '../../ScreensFilterControl';
import { TagManagerProps } from '../../../../../components/TagManager';
import { Tag } from '@raydiant/api-client-js';
import TagInput from './TagInput';
import ScreensFilterFooterAction from '../../ScreensFilterFooterAction';
import TagSelect from './TagSelect';

//When the time comes that this component should replace the TagManager component in the src/components/TagManager/TagManager.tsx file
const TagManager = ({
  onChange,
  showLabel = true,
  tags,
}: TagManagerProps & { showLabel?: boolean }) => {
  const { t } = useTranslation(['devices']);

  const handleDelete = (tag: Tag) => {
    const updatedTags = tags.filter(
      (t) => !(t.key === tag.key && t.value === tag.value),
    );
    onChange(updatedTags);
  };

  const handleAddTag = (tag: Tag) => {
    const updatedTags: Tag[] = [...tags, tag];
    onChange(updatedTags);
  };

  return (
    <ScreensFilterControl
      title={<p className="font-[500] text-[16px]">{t('screens.tags')}</p>}
      footer={
        <ul className="flex items-center divide-x divide-black text-sm text-primary">
          {tags?.length > 0 && (
            <li className="pr-2">
              <ScreensFilterFooterAction
                label={t('screens.clearTags')}
                onClick={() => onChange([])}
              />
            </li>
          )}
        </ul>
      }
    >
      <div className="flex flex-col gap-3">
        <>
          <TagSelect value={tags} onChange={handleAddTag} />
        </>
        {tags.length > 0 && (
          <div className="flex flex-col gap-3">
            {tags.map((tag) => (
              <div
                key={tag.key}
                className="flex items-center justify-between gap-4"
              >
                <TagInput
                  {...tag}
                  editing={undefined}
                  onEdit={() => {}}
                  onChange={() => {}}
                />
                <button onClick={() => handleDelete(tag)}>
                  <svg
                    className="w-4 h-4 text-neutral-400"
                    fill="currentColor"
                    height="1em"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M177.1 48h93.7c2.7 0 5.2 1.3 6.7 3.6l19 28.4h-145l19-28.4c1.5-2.2 4-3.6 6.7-3.6zM354.2 80L317.5 24.9C307.1 9.4 289.6 0 270.9 0H177.1c-18.7 0-36.2 9.4-46.6 24.9L93.8 80H80.1 32 24C10.7 80 0 90.7 0 104s10.7 24 24 24H35.6L59.6 452.7c2.5 33.4 30.3 59.3 63.8 59.3H324.6c33.5 0 61.3-25.9 63.8-59.3L412.4 128H424c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8H367.9 354.2zm10.1 48L340.5 449.2c-.6 8.4-7.6 14.8-16 14.8H123.4c-8.4 0-15.3-6.5-16-14.8L83.7 128H364.3z" />
                  </svg>
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </ScreensFilterControl>
  );
};

export default TagManager;
