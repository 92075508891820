import { ApiClientError, GetOutstandingBalance } from '@raydiant/api-client-js';
import { useSelector } from 'react-redux';

import raydiant from '../clients/raydiant';
import useProtectedQuery from './useProtectedQuery';
import { keys } from '../queryClient';
import { selectUserProfile } from '../selectors/user';

export default function useOutstandingBalance() {
  const currentUser = useSelector(selectUserProfile);
  const query = useProtectedQuery<GetOutstandingBalance, ApiClientError>(
    keys.outstandingBalance(),
    () => raydiant.getOutstandingBalance(),
    {
      enabled: currentUser?.isFreetrialUser === false,
      // Cache the current user profile for the lifetime of the page.
      staleTime: Infinity,
    },
  );

  const outStandingBalanceData = query.data?.response;

  return {
    ...query,
    data: outStandingBalanceData,
  };
}
