import FilterListIcon from '@material-ui/icons/FilterList';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import ActionBar from 'raydiant-elements/core/ActionBar/v2';
import Hidden from 'raydiant-elements/layout/Hidden';
import Spacer from 'raydiant-elements/layout/Spacer';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import { FC, useState, useEffect } from 'react';
import * as R from '../../clients/mira/types/Resource';
import AddIcon from '../../components/AddIcon';

interface IntegrationMainActionBarProps {
  selectedProfile?: R.ResourceProfile;
  sortDisabled?: boolean;
  onCreate: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),

      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(0),
      },
    },

    alert: {
      color: theme.palette.warning.main,
      backgroundColor: '#000',
      borderRadius: 100,
    },
  }),
);

const IntegrationMainActionBar: FC<IntegrationMainActionBarProps> = ({
  selectedProfile,
  sortDisabled,
  onCreate,
}) => {
  const classes = useStyles();

  // Refs

  // Selectors

  // State

  const [isSortMode, setIsSortMode] = useState(false);
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  // Callbacks

  // Side-effects

  // Reset search query when exiting search mode.
  useEffect(() => {
    if (!isSearchMode) setSearchTerm('');
  }, [isSearchMode]);

  // Render

  const sortActionOptions = (
    <>
      <ActionBar.SelectOption
        icon={<SwapVertIcon />}
        label="Name"
        selected={true}
        onClick={() => {}}
      />
    </>
  );

  const sortAction = (
    <>
      <Hidden mdDown>
        <ActionBar.Select
          icon={<FilterListIcon />}
          label="Name"
          open={isSortMode}
          onOpen={setIsSortMode}
        >
          {sortActionOptions}
        </ActionBar.Select>
      </Hidden>
      <Hidden lgUp>
        <ActionBar.Select
          icon={<FilterListIcon />}
          open={isSortMode}
          onOpen={setIsSortMode}
        >
          {sortActionOptions}
        </ActionBar.Select>
      </Hidden>
    </>
  );

  const createAction = (
    <>
      <Hidden mdDown>
        <ActionBar.Action
          icon={<AddIcon />}
          label="Add Integration"
          onClick={onCreate}
        />
      </Hidden>
      <Hidden lgUp>
        <ActionBar.Action icon={<AddIcon />} onClick={onCreate} />
      </Hidden>
    </>
  );

  const searchAction = isSearchMode ? (
    <ActionBar.Input
      autoFocus
      label="Search"
      maxWidth={145}
      icon={
        <ActionBar.Action
          icon={<CloseIcon />}
          onClick={() => setIsSearchMode(false)}
        />
      }
      onChange={setSearchTerm}
      value={searchTerm}
    />
  ) : (
    <ActionBar.Action
      icon={<SearchIcon />}
      onClick={() => setIsSearchMode(true)}
    />
  );

  return (
    <ActionBar className={classes.root}>
      <Hidden smDown>
        {sortAction}
        {createAction}
        <Spacer />
        {searchAction}
      </Hidden>

      <Hidden mdUp>
        {sortAction}
        {!isSearchMode && createAction}
        {searchAction}
      </Hidden>
    </ActionBar>
  );
};

export default IntegrationMainActionBar;
