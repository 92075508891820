import { makeStyles, createStyles } from '../../components/withStyles';
import { Theme } from 'raydiant-elements/theme';

export default makeStyles((theme: Theme) => {
  return createStyles({
    paper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },

    appsGrid: {
      '&>:nth-child(2)': {
        '&>:nth-child(1)': {
          alignItems: 'flex-end',
        },
      },
    },
  });
});
