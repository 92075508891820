import { Location } from '@raydiant/api-client-js';
import Dialog, {
  DialogHeader,
  DialogHeading,
} from '@raydiant/raydial/components/Dialog';
import Modal from '@raydiant/raydial/components/Modal';
import Button from '@raydiant/raydial/components/Button';
import Text from '@raydiant/raydial/components/Text';
import { useDeleteLocation } from '../../../../hooks';
import UnableToDeleteDialog from './UnableToDeleteDialog';
import { useToastState } from '@raydiant/raydial/components/Toast/ToastProvider';

interface DeleteLocationDialogProps {
  isOpen: boolean;
  location: Location;
  isDeleteDisabled: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const RemoveAccessConfirmationDialog = ({
  isOpen,
  location,
  isDeleteDisabled,
  onClose,
  onSubmit,
}: DeleteLocationDialogProps) => {
  const state = useToastState();
  const deleteLocation = useDeleteLocation();

  const handleDeleteLocation = async () => {
    try {
      await deleteLocation.mutate({ locationId: location.id });
      state.add({
        title: 'Location Deleted',
        description: 'Location deleted successfully',
      });
      onSubmit();
    } catch (error) {
      state.add({
        title: 'Location could not be deleted',
        description: 'Please try again',
        variant: 'caution',
      });
      onClose();
    }
  };

  if (isDeleteDisabled)
    return <UnableToDeleteDialog isOpen={isOpen} onClick={onClose} />;
  return (
    <Modal isOpen={isOpen}>
      <Dialog showCloseButton={true} onClose={onClose}>
        <DialogHeader>
          <DialogHeading>
            <span className="text-black"> Confirm delete location</span>
          </DialogHeading>
        </DialogHeader>

        <div className="px-4 pt-4 mt-2 !mb-16">
          <Text className="text-black" variant="body">
            Are you sure you want to delete location "{location.name}"? This
            cannot be undone.
          </Text>
        </div>

        <div className="flex flex-col-reverse justify-between mt-8 mb-4 gap-3 px-0 md:gap-6 md:flex-row md:px-4">
          <Button
            onClick={() => onClose()}
            variant="secondary"
            size="large"
            className="grow"
            isDisabled={deleteLocation.isLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteLocation()}
            variant="caution"
            size="large"
            className="grow"
            isDisabled={deleteLocation.isLoading}
            loading={deleteLocation.isLoading}
          >
            Delete Location
          </Button>
        </div>
      </Dialog>
    </Modal>
  );
};

export default RemoveAccessConfirmationDialog;
