import { SVGProps } from 'react';

interface CirclePlusProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const CirclePlus = ({ size = 18, ...props }: CirclePlusProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 2.21875C6.50977 2.21875 4.25195 3.54688 2.99023 5.67188C1.76172 7.83008 1.76172 10.4531 2.99023 12.5781C4.25195 14.7363 6.50977 16.0312 9 16.0312C11.457 16.0312 13.7148 14.7363 14.9766 12.5781C16.2051 10.4531 16.2051 7.83008 14.9766 5.67188C13.7148 3.54688 11.457 2.21875 9 2.21875ZM9 17.625C5.94531 17.625 3.15625 16.0312 1.62891 13.375C0.101562 10.752 0.101562 7.53125 1.62891 4.875C3.15625 2.25195 5.94531 0.625 9 0.625C12.0215 0.625 14.8105 2.25195 16.3379 4.875C17.8652 7.53125 17.8652 10.752 16.3379 13.375C14.8105 16.0312 12.0215 17.625 9 17.625ZM8.20312 12.0469V9.92188H6.07812C5.61328 9.92188 5.28125 9.58984 5.28125 9.125C5.28125 8.69336 5.61328 8.32812 6.07812 8.32812H8.20312V6.20312C8.20312 5.77148 8.53516 5.40625 9 5.40625C9.43164 5.40625 9.79688 5.77148 9.79688 6.20312V8.32812H11.9219C12.3535 8.32812 12.7188 8.69336 12.7188 9.125C12.7188 9.58984 12.3535 9.92188 11.9219 9.92188H9.79688V12.0469C9.79688 12.5117 9.43164 12.8438 9 12.8438C8.53516 12.8438 8.20312 12.5117 8.20312 12.0469Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default CirclePlus;
