import {
  isGenderBasedRuleSource,
  isNumberBasedRuleSource,
  isPercentBasedRuleSource,
} from './ruleSourceIdUtils';
import {
  getRuleSourceLongLabelForId,
  RuleSourceID,
} from './ruleTokenInputData';

export const getRuleConstantInputType = (ruleSource: RuleSourceID | null) => {
  if (!ruleSource) return 'string';

  switch (true) {
    case isGenderBasedRuleSource(ruleSource):
      return 'gender';
    case isNumberBasedRuleSource(ruleSource):
      return 'number';
    case isPercentBasedRuleSource(ruleSource):
      return 'percent';
    default:
      return 'string';
  }
};

export const getRuleConstantInputLabel = (ruleSource: RuleSourceID) => {
  if (ruleSource === 'workers.ai-realtime.primaryAttentionTime')
    return 'Total Attention Time (in seconds)';

  if (ruleSource === 'workers.perchview.activeProduct') return 'Product Name';

  return getRuleSourceLongLabelForId(ruleSource);
};
