import { Grid, useMediaQuery } from '@material-ui/core';
import cn from 'classnames';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import Heading from 'raydiant-elements/core/Heading';
import Text from 'raydiant-elements/core/Text';
import ContentCard from './components/ContentCard';
import ImageContentCard from './components/ImageContentCard';
import ColorCard from './components/ColorCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    testimonal: {
      [theme.breakpoints.up('sm')]: {
        paddingBottom: theme.spacing(4),
      },
    },
    video: {
      display: 'flex',
      width: '100%',
      height: 'auto',
      overflow: 'hidden',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
        paddingBottom: theme.spacing(10),
      },
    },
    heading: {
      paddingBottom: theme.spacing(2),
      color: '#000000',
    },
    whiteText: {
      color: '#ffffff',
    },
    box: {
      color: '#fff',
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      overflow: 'hidden',
    },
    list: {
      padding: '1rem',
      marginBlockStart: 'unset',
      marginBlockEnd: 'unset',
    },
    lastItem: {
      paddingBottom: 'unset',
    },
    stretch: {
      display: 'flex',
      flex: 1,
    },
  }),
);

const AIModalContent = () => {
  const classes = useStyles();
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Grid>
      <ImageContentCard
        className={classes.img}
        imageSrc="https://assets.raydiant.com/marketplace/ai-modal-1.png"
        imageAlt="ai-modal-1"
        imageDirectionOnDesktop="right"
        imageDirectionOnMobile="top"
      >
        <ContentCard>
          <Heading size={2} weight={600} className={classes.heading}>
            Keep Customers Coming Back for More
          </Heading>
          <Text>
            Raydiant’s AI customer analytics software empowers brick-and-mortar
            businesses with real-time, anonymous customer insights that can be
            utilized to create targeted & memorable customer experiences to
            improve in-store experiences. From large-scale enterprise
            deployments to small stores, we have your in-store analytics needs
            covered. You can easily review and analyze data all within
            Raydiant’s intuitive dashboard.
          </Text>
        </ContentCard>
      </ImageContentCard>
      <ColorCard type="ai">
        <ContentCard>
          <Heading
            size={2}
            weight={500}
            className={cn(classes.heading, classes.whiteText)}
          >
            “Through the aggregation of data with multiple data detection
            points, people-counting, and Wi-Fi analytics, our ads and messages
            are more targeted and honed to specific audiences resulting in
            enhanced customer experience and interaction, and consequently
            delivering an overall higher ROI.”
          </Heading>
          <Heading
            size={2}
            weight={300}
            className={cn(classes.heading, classes.whiteText)}
          >
            - Patrick Hayes, Managing Director, Focal Media
          </Heading>
        </ContentCard>
      </ColorCard>
      <Grid item container justifyContent="flex-start" alignItems="center">
        <ContentCard className={classes.testimonal}>
          <Heading size={2} weight={600} className={classes.heading}>
            Understand The Impact of your In-location Experiences
          </Heading>
          <Text>
            Raydiant’s AI customer analytics software The AI analytics addon for
            your Raydiant digital signage can anonymously:
          </Text>
          <Grid item xs={12} md={12}>
            <ul className={classes.list}>
              <li>
                Track footfall data in and around your digital displays and
                count impressions
              </li>
              <li>
                Gather customer analytics across thousands of cameras with ease
              </li>
              <li>
                Measure the number of viewers at each screen on a per content
                basis
              </li>
              <li>
                Uncover which customer persona groups view your digital
                marketing content – and for how long
              </li>
              <li>
                Measure consumer reactions to ads in real-time with mood
                detection analysis
              </li>
              <li>
                Combine digital signage analytics data with POS data to close
                the loop on the impact of your marketing/content shown to
                customers
              </li>
            </ul>
          </Grid>
        </ContentCard>
        <Grid className={classes.video}>
          <video width="100%" controls>
            <source
              src="https://sightcorp.com/wp-content/uploads/2022/02/Sightcorp_Face_-1.mp4"
              type="video/mp4"
            />
          </video>
        </Grid>
      </Grid>
      <Grid item container justifyContent="center">
        {!mobile && (
          <Grid item xs={12} md={6}>
            <img
              src="https://assets.raydiant.com/marketplace/ai-modal-2.png"
              alt="ai-modal-2"
              className={classes.img}
            />
          </Grid>
        )}

        <Grid item xs={12} md={6} className={classes.stretch}>
          <ColorCard type="ai">
            <ContentCard>
              <Heading
                size={2}
                weight={600}
                className={cn(classes.heading, classes.whiteText)}
              >
                Unlock The Power of Predictive Analytics
              </Heading>
              <Text className={classes.whiteText}>
                Through the power of our on-premise AI analytics, you can better
                organize and deploy your resources:
              </Text>
              <Grid item xs={12} md={12}>
                <ul className={cn(classes.list, classes.whiteText)}>
                  <li>
                    Automatically count footfall for accurate data on store
                    visitors
                  </li>
                  <li>Establish store peak hours</li>
                  <li>Better organize staff</li>
                  <li>Optimize your stock inventory</li>
                  <li>
                    See what percentage of your visitors are real purchasing
                    customers
                  </li>
                  <li>Manage store occupancy levels</li>
                </ul>
              </Grid>
            </ContentCard>
          </ColorCard>
        </Grid>
      </Grid>
      <ContentCard>
        <Heading size={2} weight={600} className={classes.heading}>
          Targeted campaigns
        </Heading>
        <Text>
          Utilize the customer intelligence gathered from our AI analytics
          technology to deliver hyper-personalized content in-location:
        </Text>
        <Grid item xs={12} md={12}>
          <ul className={classes.list}>
            <li>
              Display tailored product promotions to customers dependent on
              their demographics
            </li>
            <li>
              Play adaptive content that changes with distance and proximity to
              the screen
            </li>
            <li>
              Display safety messaging in real-time as customers enter and exit
              your store
            </li>
            <li>
              Show optimized and intelligent up-sells throughout the customer’s
              entire path to the purchase
            </li>
            <li>
              Boost attention times and increase customers’ ability to recall
              advertised products
            </li>
            <li>Increase brand awareness and loyalty while driving up sales</li>
          </ul>
        </Grid>
      </ContentCard>
      <Grid item xs={12} md={12}>
        <img
          src="https://assets.raydiant.com/marketplace/ai-modal-3.png"
          alt="ai-modal-3"
          className={classes.img}
        />
      </Grid>
    </Grid>
  );
};

export default AIModalContent;
