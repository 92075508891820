import { GetPaymentMethod, ApiClientError } from '@raydiant/api-client-js';
import raydiant from '../clients/raydiant';
import useProtectedQuery from './useProtectedQuery';
import { keys } from '../queryClient';

export default function usePaymentMethods() {
  const query = useProtectedQuery<GetPaymentMethod, ApiClientError>(
    keys.paymentMethods(),
    () => raydiant.getPaymentMethods(),
    {
      // Cache the current user profile for the lifetime of the page.
      staleTime: Infinity,
    },
  );

  const paymentMethodsData = query.data?.response;

  return {
    ...query,
    data: paymentMethodsData,
  };
}
