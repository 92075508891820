import { RuleOperatorType, RuleVariable } from '@raydiant/playlist-rule-engine';

// Rule Sources

export const AI_WORKER_RULE_SOURCE_CONSTANT = 'workers.ai-realtime.constant';
export const AI_WORKER_LEARNMORE = 'workers.learnmore';
export const AI_WORKER_LEARNMORE_URL =
  'https://support.raydiant.com/s/article/Targeted-Content-With-AI';

export const RX_WORKER_LEARNMORE = 'workers.perchview.learnmore';
export const RX_WORKER_LEARNMORE_URL =
  'https://support.raydiant.com/s/article/Rules-and-Tags-1660092390502';

export const ruleSources = {
  'target.tags.country': {
    label: 'Country',
    longLabel: 'This Item: Country',
  },
  'target.tags.state': {
    label: 'State',
    longLabel: 'This Item: State',
  },
  'target.tags.province': {
    label: 'Province',
    longLabel: 'This Item: Province',
  },
  'target.tags.territory': {
    label: 'Territory',
    longLabel: 'This Item: Territory',
  },
  'target.tags.city': {
    label: 'City',
    longLabel: 'This Item: City',
  },
  'target.tags.region': {
    label: 'Region',
    longLabel: 'This Item: Region',
  },
  'target.tags.area': {
    label: 'Area',
    longLabel: 'This Item: Area',
  },
  'target.tags.campus': {
    label: 'Campus',
    longLabel: 'This Item: Campus',
  },
  'target.tags.building': {
    label: 'Building',
    longLabel: 'This Item: Building',
  },
  'target.tags.floor': {
    label: 'Floor',
    longLabel: 'This Item: Floor',
  },
  'target.tags.room': {
    label: 'Room',
    longLabel: 'This Item: Room',
  },
  'target.tags.franchise': {
    label: 'Franchise',
    longLabel: 'This Item: Franchise',
  },
  'target.tags.space': {
    label: 'Space',
    longLabel: 'This Item: Space',
  },
  'target.tags.sale': {
    label: 'Sale',
    longLabel: 'This Item: Sale',
  },
  'target.tags.promotion': {
    label: 'Promotion',
    longLabel: 'This Item: Promotion',
  },
  'target.tags.product': {
    label: 'Product',
    longLabel: 'This Item: Product',
  },
  'target.tags.initiative': {
    label: 'Initiative',
    longLabel: 'This Item: Initiative',
  },
  'target.tags.vertical': {
    label: 'Vertical',
    longLabel: 'This Item: Vertical',
  },
  'target.tags.client': {
    label: 'Client',
    longLabel: 'This Item: Client',
  },
  'target.tags.sponsor': {
    label: 'Sponsor',
    longLabel: 'This Item: Sponsor',
  },
  'target.tags.brand': {
    label: 'Brand',
    longLabel: 'This Item: Brand',
  },
  'target.tags.audience': {
    label: 'Audience',
    longLabel: 'This Item: Audience',
  },
  'target.tags.meeting': {
    label: 'Meeting',
    longLabel: 'This Item: Meeting',
  },
  'target.tags.project': {
    label: 'Project',
    longLabel: 'This Item: Project',
  },
  'device.tags.country': {
    label: 'Country',
    longLabel: 'ScreenRay: Country',
  },
  'device.tags.state': {
    label: 'State',
    longLabel: 'ScreenRay: State',
  },
  'device.tags.province': {
    label: 'Province',
    longLabel: 'ScreenRay: Province',
  },
  'device.tags.territory': {
    label: 'Territory',
    longLabel: 'ScreenRay: Territory',
  },
  'device.tags.city': {
    label: 'City',
    longLabel: 'ScreenRay: City',
  },
  'device.tags.region': {
    label: 'Region',
    longLabel: 'ScreenRay: Region',
  },
  'device.tags.area': {
    label: 'Area',
    longLabel: 'ScreenRay: Area',
  },
  'device.tags.campus': {
    label: 'Campus',
    longLabel: 'ScreenRay: Campus',
  },
  'device.tags.building': {
    label: 'Building',
    longLabel: 'ScreenRay: Building',
  },
  'device.tags.floor': {
    label: 'Floor',
    longLabel: 'ScreenRay: Floor',
  },
  'device.tags.room': {
    label: 'Room',
    longLabel: 'ScreenRay: Room',
  },
  'device.tags.franchise': {
    label: 'Franchise',
    longLabel: 'ScreenRay: Franchise',
  },
  'device.tags.space': {
    label: 'Space',
    longLabel: 'ScreenRay: Space',
  },
  'device.tags.sale': {
    label: 'Sale',
    longLabel: 'ScreenRay: Sale',
  },
  'device.tags.promotion': {
    label: 'Promotion',
    longLabel: 'ScreenRay: Promotion',
  },
  'device.tags.product': {
    label: 'Product',
    longLabel: 'ScreenRay: Product',
  },
  'device.tags.initiative': {
    label: 'Initiative',
    longLabel: 'ScreenRay: Initiative',
  },
  'device.tags.vertical': {
    label: 'Vertical',
    longLabel: 'ScreenRay: Vertical',
  },
  'device.tags.client': {
    label: 'Client',
    longLabel: 'ScreenRay: Client',
  },
  'device.tags.sponsor': {
    label: 'Sponsor',
    longLabel: 'ScreenRay: Sponsor',
  },
  'device.tags.brand': {
    label: 'Brand',
    longLabel: 'ScreenRay: Brand',
  },
  'device.tags.audience': {
    label: 'Audience',
    longLabel: 'ScreenRay: Audience',
  },
  'device.tags.meeting': {
    label: 'Meeting',
    longLabel: 'ScreenRay: Meeting',
  },
  'device.tags.project': {
    label: 'Project',
    longLabel: 'ScreenRay: Project',
  },
  'time_and_date.curr_date': {
    label: 'Current Date',
    longLabel: 'Time & Date: Current Date',
  },
  'time_and_date.curr_time': {
    label: 'Current Time',
    longLabel: 'Time & Date: Current Time',
  },
  'time_and_date.curr_day': {
    label: 'Day of the week',
    longLabel: 'Time & Date: Day of the week',
  },
  'target.tags.start_time': {
    label: 'Start Time',
    longLabel: 'Time: Start Time',
  },
  'target.tags.end_time': {
    label: 'End Time',
    longLabel: 'Time: End Time',
  },
  'target.tags.start_date': {
    label: 'Start Date',
    longLabel: 'Date: Start Date',
  },
  'target.tags.end_date': {
    label: 'End Date',
    longLabel: 'Date: End Date',
  },
  'target.tags.days_of_week': {
    label: 'Day(s) of the week',
    longLabel: 'Days: Day(s) of the week',
  },
  'workers.ai-aggregated.age0to12': {
    label: '0-12',
    longLabel: 'Age Percentage: 0-12',
  },
  'workers.ai-aggregated.age13to17': {
    label: '13-17',
    longLabel: 'Age Percentage: 13-17',
  },
  'workers.ai-aggregated.age18to21': {
    label: '18-21',
    longLabel: 'Age Percentage: 18-21',
  },
  'workers.ai-aggregated.age22to25': {
    label: '22-25',
    longLabel: 'Age Percentage: 22-25',
  },
  'workers.ai-aggregated.age26to34': {
    label: '26-34',
    longLabel: 'Age Percentage: 26-34',
  },
  'workers.ai-aggregated.age35to44': {
    label: '35-44',
    longLabel: 'Age Percentage:35-44',
  },
  'workers.ai-aggregated.age45to54': {
    label: '45-54',
    longLabel: 'Age Percentage: 45-54',
  },
  'workers.ai-aggregated.age55to64': {
    label: '55-64',
    longLabel: 'Age Percentage: 55-64',
  },
  'workers.ai-aggregated.age65plus': {
    label: '65+',
    longLabel: 'Age Percentage: 65+',
  },
  'workers.ai-aggregated.male': {
    label: 'Male Percentage',
    longLabel: 'Gender Percentage: Male Percentage',
  },
  'workers.ai-aggregated.female': {
    label: 'Female Percentage',
    longLabel: 'Gender Percentage: Female Percentage',
  },
  'workers.ai-aggregated.unknown': {
    label: 'Undetermined Gender Percentage',
    longLabel: 'Gender Percentage: Undetermined Gender Percentage',
  },
  'workers.ai-aggregated.male0to12': {
    label: 'Males aged 0-12',
    longLabel: 'Age by Gender Percentage: Males aged 0-12',
  },
  'workers.ai-aggregated.male13to17': {
    label: 'Males aged 13-17',
    longLabel: 'Age by Gender Percentage: Males aged 13-17',
  },
  'workers.ai-aggregated.male18to21': {
    label: 'Males aged 18-21',
    longLabel: 'Age by Gender Percentage: Males aged 18-21',
  },
  'workers.ai-aggregated.male22to25': {
    label: 'Males aged 22-25',
    longLabel: 'Age by Gender Percentage: Males aged 22-25',
  },
  'workers.ai-aggregated.male26to34': {
    label: 'Males aged 26-34',
    longLabel: 'Age by Gender Percentage: Males aged 26-34',
  },
  'workers.ai-aggregated.male35to44': {
    label: 'Males aged 35-44',
    longLabel: 'Age by Gender Percentage: Males aged 35-44',
  },
  'workers.ai-aggregated.male45to54': {
    label: 'Males aged 45-54',
    longLabel: 'Age by Gender Percentage: Males aged 45-54',
  },
  'workers.ai-aggregated.male55to64': {
    label: 'Males aged 55-64',
    longLabel: 'Age by Gender Percentage: Males aged 55-64',
  },
  'workers.ai-aggregated.male65plus': {
    label: 'Males aged 65+',
    longLabel: 'Age by Gender Percentage: Males aged 65+',
  },
  'workers.ai-aggregated.female0to12': {
    label: 'Females aged 0-12',
    longLabel: 'Age by Gender Percentage: Females aged 0-12',
  },
  'workers.ai-aggregated.female13to17': {
    label: 'Females aged 13-17',
    longLabel: 'Age by Gender Percentage: Females aged 13-17',
  },
  'workers.ai-aggregated.female18to21': {
    label: 'Females aged 18-21',
    longLabel: 'Age by Gender Percentage: Females aged 18-21',
  },
  'workers.ai-aggregated.female22to25': {
    label: 'Females aged 22-25',
    longLabel: 'Age by Gender Percentage: Females aged 22-25',
  },
  'workers.ai-aggregated.female26to34': {
    label: 'Females aged 26-34',
    longLabel: 'Age by Gender Percentage: Females aged 26-34',
  },
  'workers.ai-aggregated.female35to44': {
    label: 'Females aged 35-44',
    longLabel: 'Age by Gender Percentage: Females aged 35-44',
  },
  'workers.ai-aggregated.female45to54': {
    label: 'Females aged 45-54',
    longLabel: 'Age by Gender Percentage: Females aged 45-54',
  },
  'workers.ai-aggregated.female55to64': {
    label: 'Females aged 55-64',
    longLabel: 'Age by Gender Percentage: Females aged 55-64',
  },
  'workers.ai-aggregated.female65plus': {
    label: 'Females aged 65+',
    longLabel: 'Age by Gender Percentage: Females aged 65+',
  },
  'workers.ai-aggregated.unknown0to12': {
    label: 'Undetermined Genders aged 0-12',
    longLabel: 'Age by Gender Percentage: Undetermined Genders aged 0-12',
  },
  'workers.ai-aggregated.unknown13to17': {
    label: 'Undetermined Genders aged 13-17',
    longLabel: 'Age by Gender Percentage: Undetermined Genders aged 13-17',
  },
  'workers.ai-aggregated.unknown18to21': {
    label: 'Undetermined Genders aged 18-21',
    longLabel: 'Age by Gender Percentage: Undetermined Genders aged 18-21',
  },
  'workers.ai-aggregated.unknown22to25': {
    label: 'Undetermined Genders aged 22-25',
    longLabel: 'Age by Gender Percentage: Undetermined Genders aged 22-25',
  },
  'workers.ai-aggregated.unknown26to34': {
    label: 'Undetermined Genders aged 26-34',
    longLabel: 'Age by Gender Percentage: Undetermined Genders aged 26-34',
  },
  'workers.ai-aggregated.unknown35to44': {
    label: 'Undetermined Genders aged 35-44',
    longLabel: 'Age by Gender Percentage: Undetermined Genders aged 35-44',
  },
  'workers.ai-aggregated.unknown45to54': {
    label: 'Undetermined Genders aged 45-54',
    longLabel: 'Age by Gender Percentage: Undetermined Genders aged 45-54',
  },
  'workers.ai-aggregated.unknown55to64': {
    label: 'Undetermined Genders aged 55-64',
    longLabel: 'Age by Gender Percentage: Undetermined Genders aged 55-64',
  },
  'workers.ai-aggregated.unknown65plus': {
    label: 'Undetermined Genders aged 65+',
    longLabel: 'Age by Gender Percentage: Undetermined Genders aged 65+',
  },
  'workers.ai-realtime.primaryAttentionTime': {
    label: 'Total Attention Time',
    longLabel: 'Person Paying Most Attention: Total Attention Time',
  },
  'workers.ai-realtime.primaryGender': {
    label: 'Gender',
    longLabel: 'Person Paying Most Attention: Gender',
  },
  'workers.ai-realtime.primaryAge': {
    label: 'Age',
    longLabel: 'Person Paying Most Attention: Age',
  },
  'workers.ai-realtime.time-since-last-attention': {
    label: 'Time Since Last Attention',
    longLabel: 'Time Since Last Attention',
  },
  'workers.ai-realtime.male': {
    label: 'Male Percentage',
    longLabel: 'Gender Percentage: Male Percentage',
  },
  'workers.ai-realtime.female': {
    label: 'Female Percentage',
    longLabel: 'Gender Percentage: Female Percentage',
  },
  'workers.ai-realtime.unknown': {
    label: 'Undetermined Gender Percentage',
    longLabel: 'Gender Percentage: Undetermined Gender Percentage',
  },
  'workers.ai-realtime.age0to12': {
    label: '0-12',
    longLabel: 'Age Percentage: 0-12',
  },
  'workers.ai-realtime.age13to17': {
    label: '13-17',
    longLabel: 'Age Percentage: 13-17',
  },
  'workers.ai-realtime.age18to21': {
    label: '18-21',
    longLabel: 'Age Percentage: 18-21',
  },
  'workers.ai-realtime.age22to25': {
    label: '22-25',
    longLabel: 'Age Percentage: 22-25',
  },
  'workers.ai-realtime.age26to34': {
    label: '26-34',
    longLabel: 'Age Percentage: 26-34',
  },
  'workers.ai-realtime.age35to44': {
    label: '35-44',
    longLabel: 'Age Percentage:35-44',
  },
  'workers.ai-realtime.age45to54': {
    label: '45-54',
    longLabel: 'Age Percentage: 45-54',
  },
  'workers.ai-realtime.age55to64': {
    label: '55-64',
    longLabel: 'Age Percentage: 55-64',
  },
  'workers.ai-realtime.age65plus': {
    label: '65+',
    longLabel: 'Age Percentage: 65+',
  },
  'workers.ai-realtime.male0to12': {
    label: 'Males aged 0-12',
    longLabel: 'Age by Gender Percentage: Males aged 0-12',
  },
  'workers.ai-realtime.male13to17': {
    label: 'Males aged 13-17',
    longLabel: 'Age by Gender Percentage: Males aged 13-17',
  },
  'workers.ai-realtime.male18to21': {
    label: 'Males aged 18-21',
    longLabel: 'Age by Gender Percentage: Males aged 18-21',
  },
  'workers.ai-realtime.male22to25': {
    label: 'Males aged 22-25',
    longLabel: 'Age by Gender Percentage: Males aged 22-25',
  },
  'workers.ai-realtime.male26to34': {
    label: 'Males aged 26-34',
    longLabel: 'Age by Gender Percentage: Males aged 26-34',
  },
  'workers.ai-realtime.male35to44': {
    label: 'Males aged 35-44',
    longLabel: 'Age by Gender Percentage: Males aged 35-44',
  },
  'workers.ai-realtime.male45to54': {
    label: 'Males aged 45-54',
    longLabel: 'Age by Gender Percentage: Males aged 45-54',
  },
  'workers.ai-realtime.male55to64': {
    label: 'Males aged 55-64',
    longLabel: 'Age by Gender Percentage: Males aged 55-64',
  },
  'workers.ai-realtime.male65plus': {
    label: 'Males aged 65+',
    longLabel: 'Age by Gender Percentage: Males aged 65+',
  },
  'workers.ai-realtime.female0to12': {
    label: 'Females aged 0-12',
    longLabel: 'Age by Gender Percentage: Females aged 0-12',
  },
  'workers.ai-realtime.female13to17': {
    label: 'Females aged 13-17',
    longLabel: 'Age by Gender Percentage: Females aged 13-17',
  },
  'workers.ai-realtime.female18to21': {
    label: 'Females aged 18-21',
    longLabel: 'Age by Gender Percentage: Females aged 18-21',
  },
  'workers.ai-realtime.female22to25': {
    label: 'Females aged 22-25',
    longLabel: 'Age by Gender Percentage: Females aged 22-25',
  },
  'workers.ai-realtime.female26to34': {
    label: 'Females aged 26-34',
    longLabel: 'Age by Gender Percentage: Females aged 26-34',
  },
  'workers.ai-realtime.female35to44': {
    label: 'Females aged 35-44',
    longLabel: 'Age by Gender Percentage: Females aged 35-44',
  },
  'workers.ai-realtime.female45to54': {
    label: 'Females aged 45-54',
    longLabel: 'Age by Gender Percentage: Females aged 45-54',
  },
  'workers.ai-realtime.female55to64': {
    label: 'Females aged 55-64',
    longLabel: 'Age by Gender Percentage: Females aged 55-64',
  },
  'workers.ai-realtime.female65plus': {
    label: 'Females aged 65+',
    longLabel: 'Age by Gender Percentage: Females aged 65+',
  },
  'workers.ai-realtime.unknown0to12': {
    label: 'Undetermined Genders aged 0-12',
    longLabel: 'Age by Gender Percentage: Undetermined Genders aged 0-12',
  },
  'workers.ai-realtime.unknown13to17': {
    label: 'Undetermined Genders aged 13-17',
    longLabel: 'Age by Gender Percentage: Undetermined Genders aged 13-17',
  },
  'workers.ai-realtime.unknown18to21': {
    label: 'Undetermined Genders aged 18-21',
    longLabel: 'Age by Gender Percentage: Undetermined Genders aged 18-21',
  },
  'workers.ai-realtime.unknown22to25': {
    label: 'Undetermined Genders aged 22-25',
    longLabel: 'Age by Gender Percentage: Undetermined Genders aged 22-25',
  },
  'workers.ai-realtime.unknown26to34': {
    label: 'Undetermined Genders aged 26-34',
    longLabel: 'Age by Gender Percentage: Undetermined Genders aged 26-34',
  },
  'workers.ai-realtime.unknown35to44': {
    label: 'Undetermined Genders aged 35-44',
    longLabel: 'Age by Gender Percentage: Undetermined Genders aged 35-44',
  },
  'workers.ai-realtime.unknown45to54': {
    label: 'Undetermined Genders aged 45-54',
    longLabel: 'Age by Gender Percentage: Undetermined Genders aged 45-54',
  },
  'workers.ai-realtime.unknown55to64': {
    label: 'Undetermined Genders aged 55-64',
    longLabel: 'Age by Gender Percentage: Undetermined Genders aged 55-64',
  },
  'workers.ai-realtime.unknown65plus': {
    label: 'Undetermined Genders aged 65+',
    longLabel: 'Age by Gender Percentage: Undetermined Genders aged 65+',
  },
  'workers.ai-realtime.totalDetections': {
    label: 'People Count',
    longLabel: 'People Count',
  },
  [AI_WORKER_RULE_SOURCE_CONSTANT]: {
    label: 'Custom Value',
    longLabel: 'Custom Value',
  },
  [AI_WORKER_LEARNMORE]: {
    label: 'Learn more',
    longLabel: 'Learn more',
  },
  'workers.perchview.activeProduct': {
    label: 'Product Picked up',
    longLabel: 'Product Picked up',
  },
  [RX_WORKER_LEARNMORE]: {
    label: 'Learn more',
    longLabel: 'Learn more',
  },
} as const;

export type RuleSourceID = keyof typeof ruleSources;

export type RuleSourceOption = {
  name: string;
  options?: RuleSourceID[];
  groups?: RuleSourceOption[];
};

export const ruleSourceOptionGroups: Array<RuleSourceOption> = [
  {
    name: 'This item',
    groups: [
      {
        name: 'Location',
        options: [
          'target.tags.building',
          'target.tags.country',
          'target.tags.state',
          'target.tags.province',
          'target.tags.territory',
          'target.tags.city',
          'target.tags.region',
          'target.tags.area',
          'target.tags.campus',
          'target.tags.floor',
          'target.tags.room',
          'target.tags.franchise',
          'target.tags.space',
        ],
      },
      {
        name: 'Campaign',
        options: [
          'target.tags.sale',
          'target.tags.promotion',
          'target.tags.product',
          'target.tags.initiative',
          'target.tags.vertical',
          'target.tags.client',
          'target.tags.sponsor',
          'target.tags.brand',
          'target.tags.audience',
          'target.tags.meeting',
          'target.tags.project',
        ],
      },
      {
        name: 'Schedule',
        options: [
          'target.tags.start_time',
          'target.tags.end_time',
          'target.tags.start_date',
          'target.tags.end_date',
          'target.tags.days_of_week',
        ],
      },
    ],
  },
  {
    name: 'This ScreenRay',
    groups: [
      {
        name: 'Location',
        options: [
          'device.tags.building',
          'device.tags.country',
          'device.tags.state',
          'device.tags.province',
          'device.tags.territory',
          'device.tags.city',
          'device.tags.region',
          'device.tags.area',
          'device.tags.campus',
          'device.tags.floor',
          'device.tags.room',
          'device.tags.franchise',
          'device.tags.space',
        ],
      },
      {
        name: 'Campaign',
        options: [
          'device.tags.sale',
          'device.tags.promotion',
          'device.tags.product',
          'device.tags.initiative',
          'device.tags.vertical',
          'device.tags.client',
          'device.tags.sponsor',
          'device.tags.brand',
          'device.tags.audience',
          'device.tags.meeting',
          'device.tags.project',
        ],
      },
    ],
  },
  {
    name: 'Time & Date',
    options: [
      'time_and_date.curr_time',
      'time_and_date.curr_date',
      'time_and_date.curr_day',
    ],
  },
  {
    name: 'Aggregated Visitor Targeting',
    groups: [
      {
        name: 'Age Percentages',
        options: [
          'workers.ai-aggregated.age0to12',
          'workers.ai-aggregated.age13to17',
          'workers.ai-aggregated.age18to21',
          'workers.ai-aggregated.age22to25',
          'workers.ai-aggregated.age26to34',
          'workers.ai-aggregated.age35to44',
          'workers.ai-aggregated.age45to54',
          'workers.ai-aggregated.age55to64',
          'workers.ai-aggregated.age65plus',
        ],
      },
      {
        name: 'Gender Percentages',
        options: [
          'workers.ai-aggregated.male',
          'workers.ai-aggregated.female',
          'workers.ai-aggregated.unknown',
        ],
      },
      {
        name: 'Age by Gender Percentages',
        options: [
          'workers.ai-aggregated.male0to12',
          'workers.ai-aggregated.male13to17',
          'workers.ai-aggregated.male18to21',
          'workers.ai-aggregated.male22to25',
          'workers.ai-aggregated.male26to34',
          'workers.ai-aggregated.male35to44',
          'workers.ai-aggregated.male45to54',
          'workers.ai-aggregated.male55to64',
          'workers.ai-aggregated.male65plus',
          'workers.ai-aggregated.female0to12',
          'workers.ai-aggregated.female13to17',
          'workers.ai-aggregated.female18to21',
          'workers.ai-aggregated.female22to25',
          'workers.ai-aggregated.female26to34',
          'workers.ai-aggregated.female35to44',
          'workers.ai-aggregated.female45to54',
          'workers.ai-aggregated.female55to64',
          'workers.ai-aggregated.female65plus',
          'workers.ai-aggregated.unknown0to12',
          'workers.ai-aggregated.unknown13to17',
          'workers.ai-aggregated.unknown18to21',
          'workers.ai-aggregated.unknown22to25',
          'workers.ai-aggregated.unknown26to34',
          'workers.ai-aggregated.unknown35to44',
          'workers.ai-aggregated.unknown45to54',
          'workers.ai-aggregated.unknown55to64',
          'workers.ai-aggregated.unknown65plus',
        ],
      },
    ],
    options: [AI_WORKER_LEARNMORE],
  },
  {
    name: 'Real Time Visitor Targeting',
    groups: [
      {
        name: 'Person Paying Most Attention',
        options: [
          'workers.ai-realtime.primaryAttentionTime',
          'workers.ai-realtime.primaryGender',
          'workers.ai-realtime.primaryAge',
        ],
      },
      {
        name: 'Age Percentages',
        options: [
          'workers.ai-realtime.age0to12',
          'workers.ai-realtime.age13to17',
          'workers.ai-realtime.age18to21',
          'workers.ai-realtime.age22to25',
          'workers.ai-realtime.age26to34',
          'workers.ai-realtime.age35to44',
          'workers.ai-realtime.age45to54',
          'workers.ai-realtime.age55to64',
          'workers.ai-realtime.age65plus',
        ],
      },
      {
        name: 'Gender Percentages',
        options: [
          'workers.ai-realtime.male',
          'workers.ai-realtime.female',
          'workers.ai-realtime.unknown',
        ],
      },
      {
        name: 'Age by Gender Percentages',
        options: [
          'workers.ai-realtime.male0to12',
          'workers.ai-realtime.male13to17',
          'workers.ai-realtime.male18to21',
          'workers.ai-realtime.male22to25',
          'workers.ai-realtime.male26to34',
          'workers.ai-realtime.male35to44',
          'workers.ai-realtime.male45to54',
          'workers.ai-realtime.male55to64',
          'workers.ai-realtime.male65plus',
          'workers.ai-realtime.female0to12',
          'workers.ai-realtime.female13to17',
          'workers.ai-realtime.female18to21',
          'workers.ai-realtime.female22to25',
          'workers.ai-realtime.female26to34',
          'workers.ai-realtime.female35to44',
          'workers.ai-realtime.female45to54',
          'workers.ai-realtime.female55to64',
          'workers.ai-realtime.female65plus',
          'workers.ai-realtime.unknown0to12',
          'workers.ai-realtime.unknown13to17',
          'workers.ai-realtime.unknown18to21',
          'workers.ai-realtime.unknown22to25',
          'workers.ai-realtime.unknown26to34',
          'workers.ai-realtime.unknown35to44',
          'workers.ai-realtime.unknown45to54',
          'workers.ai-realtime.unknown55to64',
          'workers.ai-realtime.unknown65plus',
        ],
      },
    ],
    options: ['workers.ai-realtime.totalDetections', AI_WORKER_LEARNMORE],
  },
  {
    name: 'Product Events',
    options: ['workers.perchview.activeProduct', RX_WORKER_LEARNMORE],
  },
];

export const getRuleSourceIdForVariable = (
  variable: RuleVariable,
): RuleSourceID | null => {
  const ruleSourceId = variable.var.join('.');

  if (ruleSourceId in ruleSources) {
    return ruleSourceId as RuleSourceID;
  }

  return null;
};

const IsAiWorkerRule = (rule: RuleOperatorType) => 'const' in rule.right;

export const getRuleSourceIdForSource2 = (
  rule: RuleOperatorType,
): RuleSourceID | null => {
  if (IsAiWorkerRule(rule)) return AI_WORKER_RULE_SOURCE_CONSTANT;

  return 'var' in rule.right ? getRuleSourceIdForVariable(rule.right) : null;
};

export const getRuleSourceLabelForVariable = (
  variable: RuleVariable,
): string => {
  const ruleSourceId = getRuleSourceIdForVariable(variable);

  if (ruleSourceId) {
    return ruleSources[ruleSourceId].longLabel;
  }

  return '';
};

export const getRuleSourceLongLabelForId = (
  ruleSourceId: RuleSourceID,
): string => {
  return ruleSources[ruleSourceId]?.longLabel;
};

export const getRuleSourceShortLabelForId = (
  ruleSourceId: RuleSourceID,
): string => {
  return ruleSources[ruleSourceId].label;
};

// Rule Operators

export const ruleOperators = {
  equals: {
    label: 'Equals',
    symbol:
      'M19.37 9.486V6.813H5.522v2.673H19.37zm0 6.89V13.72H5.522v2.656H19.37z',
  },
  notEquals: {
    label: 'Does not equal',
    symbol:
      'm8.196 21.427 2.358-4.051h8.815V14.72h-7.255l2.457-4.234h4.798V7.813h-3.237l1.975-3.37-1.577-.98-2.54 4.35H5.523v2.673h6.907L9.956 14.72H5.523v2.656h2.89l-1.794 3.071z',
  },
  greaterThan: {
    label: 'Greater than',
    symbol: 'M5 19.991 19.178 13.7v-2.424L5 5v3.154l10.974 4.3L5 16.804z',
  },
  greaterThanOrEqual: {
    label: 'Greater or equal to',
    symbol:
      'm5.017 16.492 14.177-5.662V8.648L5.017 3v2.839l10.973 3.87-10.973 3.915v2.868zm13.845 4.822v-2.507H5v2.507h13.862z',
  },
  lessThan: {
    label: 'Lesser than',
    symbol: 'M18.58 18.991v-3.254l-10.392-4.2L18.58 7.254V4L5 10.275V12.7z',
  },
  lessThanOrEqual: {
    label: 'Lesser than or equal to',
    symbol:
      'M18.775 16.476v-2.929L8.383 9.767l10.392-3.855V2.984L5.195 8.632v2.181l13.58 5.663zm.117 4.49V18.46H5.029v2.507h13.863z',
  },
  contains: {
    label: 'Contains',
    symbol:
      'M11.836 3c2.295.088 4.076.919 5.673 2.504 1.648 1.648 2.47 3.625 2.47 5.932 0 2.503-.829 4.562-2.473 6.183-1.641 1.618-3.72 2.435-6.244 2.435H4.005v-2.726l7.18-.017c1.552-.06 2.69-.442 3.707-1.17.61-.432 1.08-.918 1.4-1.464.27-.46.523-1.086.765-1.878H4.02v-2.725l13.002-.049c-.243-.802-.448-1.31-.668-1.696-.291-.512-.726-.987-1.3-1.43-1.044-.81-2.164-1.222-3.36-1.222l-7.669.035L4 3h7.836z',
  },
  notContains: {
    label: 'Does not contain',
    symbol:
      'm16.54 2 1.443.736-1.287 2.04c.277.222.547.464.813.728 1.648 1.648 2.47 3.625 2.47 5.932 0 2.503-.829 4.562-2.473 6.183-1.567 1.545-3.533 2.359-5.903 2.43l-.341.005-4.203-.001L5.831 22l-1.477-.751.756-1.196H4.005v-2.725l2.834-.007 2.864-4.523H4.021v-2.724l7.424-.029 2.45-3.867a5.087 5.087 0 0 0-1.925-.494l-.274-.007-7.67.035L4 3h7.836c1.318.05 2.467.346 3.508.889L16.539 2zM8.785 17.316l2.4-.005c1.55-.06 2.69-.442 3.706-1.17.61-.432 1.08-.918 1.4-1.464.236-.402.46-.932.674-1.589l.09-.29h-5.42l-2.85 4.518zm6.47-10.255-1.879 2.977 3.646-.013c-.243-.802-.448-1.31-.668-1.696-.256-.45-.623-.872-1.098-1.268z',
  },
} as const;

export type RuleOperatorID = keyof typeof ruleOperators;

export const ruleOperatorOptions: RuleOperatorID[] = [
  'equals',
  'notEquals',
  'greaterThan',
  'greaterThanOrEqual',
  'lessThan',
  'lessThanOrEqual',
  'contains',
  'notContains',
];

export const ruleSourceWithToolTips = [
  'workers.ai-realtime.primaryAttentionTime',
  'workers.ai-realtime.primaryGender',
  'workers.ai-realtime.primaryAge',
];

export const getRuleOperatorLabelForType = (type: string): string => {
  if (type in ruleOperators) {
    return ruleOperators[type as RuleOperatorID].label;
  }

  return '';
};

export const getRuleOperatorSymbolForType = (type: string): string => {
  if (type in ruleOperators) {
    return ruleOperators[type as RuleOperatorID].symbol;
  }

  return '';
};

// Rule Groups

export const ruleGroups = {
  and: { label: 'And' },
  or: { label: 'Or' },
} as const;

export type RuleGroupID = keyof typeof ruleGroups;

export const ruleGroupOptions: RuleGroupID[] = ['and', 'or'];

export const getRuleGroupLabelForType = (type: string): string => {
  if (type in ruleGroups) {
    return ruleGroups[type as RuleGroupID].label;
  }

  return '';
};
