import { useTranslation } from 'react-i18next';
import ScreensFilterControl from '../ScreensFilterControl';
import ScreensFilterFooterAction from '../ScreensFilterFooterAction';
import Select from '../../../../components/Select/Select';
import classNames from 'classnames';
import SelectOption from '../../../../components/Select/SelectOption';
import { useSelector } from 'react-redux';
import { selectDomainForCurrentUser } from '../../../../selectors/v2/domains';
import { filterStandardAndRestrictedUsers } from '../../../../components/ShareResource/ShareResource';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import { ResourceProfile } from '@raydiant/api-client-js';
import ScreensFilterListItem from '../ScreensFilterListItem';
import { useMemo, useState } from 'react';
import DropDownHeader from '../../../../components/DropDown/DropDownHeader';
import DropDownEmpty from '../../../../components/DropDown/DropDownEmpty';

type ScreenOwnersSelectProps = {
  className?: string;
  onChange: (value: ResourceProfile) => void;
  onClear: () => void;
  onRemoveProfile?: (id: ResourceProfile['id']) => void;
  value: ResourceProfile['id'][];
};

const getProfile = (
  profiles: ResourceProfile[],
  profileId: ResourceProfile['id'],
) => profiles.find((profile) => profile.id === profileId);

const ScreenOwnersSelect = ({
  className,
  onChange,
  onClear,
  onRemoveProfile,
  value,
}: ScreenOwnersSelectProps) => {
  const { t } = useTranslation(['devices']);

  const domain = useSelector(selectDomainForCurrentUser);
  const { data: currentUser } = useCurrentUser();
  const profiles = domain?.r.profiles
    .filter((profile) => filterStandardAndRestrictedUsers(profile, currentUser))
    .sort((a, b) => a.name.localeCompare(b.name));
  const getPlaceholder = t('screens.selectUsers');

  const [term, setTerm] = useState('');
  const [open, setOpen] = useState(false);

  const screenOwners = useMemo(() => {
    if (term === '') return profiles;

    return profiles?.filter((profile) =>
      profile.name.toLocaleLowerCase().includes(term.toLocaleLowerCase()),
    );
  }, [profiles, term]);

  const showEmpty = screenOwners?.length === 0 && term.length > 0;

  return (
    <ScreensFilterControl
      title={
        <p className="font-[500] text-[16px]">{t('screens.screenOwners')}</p>
      }
      footer={
        value?.length > 0 && (
          <ul className="flex items-center divide-x divide-black text-sm text-primary">
            <li className="pr-2">
              <ScreensFilterFooterAction
                label={t('screens.clearScreenOwners')}
                onClick={onClear}
              />
            </li>
          </ul>
        )
      }
    >
      <Select
        className={className}
        disableMobile
        header={
          <DropDownHeader className="hover:bg-transparent!">
            <input
              type="text"
              className="input no-label"
              onChange={(e) => setTerm(e.target.value)}
            />
          </DropDownHeader>
        }
        menuClassname="max-h-[300px] overflow-y-auto"
        onChange={() => {}} //we dont want to trigger on change when user is typing
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        panelClassname="p-0 min-w-min w-full left-0 !overflow-visible"
        wrapperClassName={classNames('no-label')}
        renderAnchorElement={() => <>{getPlaceholder}</>}
        popover
      >
        {screenOwners
          ?.filter((profile) => !value.includes(profile.id))
          .map((profile, key) => (
            <SelectOption
              disableMobile
              key={key}
              onClick={() => {
                onChange(
                  screenOwners?.find((value) => profile.id === value.id)!,
                );
                setTerm('');
                setOpen(false);
              }}
              value={profile.id}
            >
              {profile.name}
            </SelectOption>
          ))}
        {showEmpty && <DropDownEmpty />}
      </Select>
      {value && (
        <ul>
          {value?.map((profileId) => {
            const profile = getProfile(profiles!, profileId);
            return (
              <ScreensFilterListItem
                key={profileId}
                label={
                  <div className="flex justify-between items-center w-full">
                    <p className="capitalize text-ellipsis overflow-hidden max-w-[100px]">
                      {profile?.name}
                    </p>
                    <p className="capitalize">{profile?.domainRole}</p>
                  </div>
                }
                onClear={() =>
                  onRemoveProfile ? onRemoveProfile(profileId) : undefined
                }
              />
            );
          })}
        </ul>
      )}
    </ScreensFilterControl>
  );
};

export default ScreenOwnersSelect;
