import { ApiClientError } from '@raydiant/api-client-js';
import raydiant from '../clients/miraClient';
import useProtectedQuery from './useProtectedQuery';
import { keys } from '../queryClient';

export default function useApplicationVersionToken(
  applicationVersionId: string | undefined,
) {
  const query = useProtectedQuery<string, ApiClientError>(
    applicationVersionId
      ? keys.applicationVersionToken(applicationVersionId)
      : [],
    () =>
      applicationVersionId
        ? raydiant.generateApplicationVersionToken(applicationVersionId)
        : '',
    {
      enabled: applicationVersionId !== undefined,
      // Tokens expire after 5 minutes, refresh every 2 minutes to prevent sending expired JWTs to the preview.
      staleTime: 2 * 1000 * 60,
      refetchInterval: 2 * 1000 * 60,
      refetchOnWindowFocus: true,
    },
  );

  return query;
}
