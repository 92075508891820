import { SVGProps } from 'react';

interface ArrowUpToLineProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const ArrowUpToLine = ({ size = 16, ...props }: ArrowUpToLineProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.3838 0.171551H12.5857C13.0191 0.171551 13.3858 0.538279 13.3858 0.971684C13.3858 1.43843 13.0191 1.77182 12.5857 1.77182H1.3838C0.917053 1.77182 0.583664 1.43843 0.583664 0.971684C0.583664 0.538279 0.917053 0.171551 1.3838 0.171551ZM7.55149 4.70564L11.8189 9.23973C12.1189 9.57312 12.1189 10.0732 11.7855 10.3732C11.4521 10.6733 10.9521 10.6733 10.652 10.3399L7.78486 7.27273V10.0399V14.3072C7.78486 14.774 7.41814 15.1074 6.98473 15.1074C6.51799 15.1074 6.1846 14.774 6.1846 14.3072V10.0399V7.27273L3.28411 10.3399C2.98406 10.6733 2.48398 10.6733 2.15059 10.3732C1.8172 10.0732 1.8172 9.57312 2.11725 9.23973L6.38463 4.70564C6.55132 4.53894 6.75136 4.43893 6.98473 4.43893C7.18476 4.43893 7.41814 4.53894 7.55149 4.70564Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default ArrowUpToLine;
