import { useTranslation } from 'react-i18next';
import ScreensFilterFooterAction from './ScreensFilterFooterAction';
import classNames from 'classnames';

type ScreensFilterDropDownActionsProps = {
  onClearAll?: () => void;
  onSelectAll?: () => void;
};

const ScreensFilterDropDownActions = ({
  onClearAll,
  onSelectAll,
}: ScreensFilterDropDownActionsProps) => {
  const { t } = useTranslation(['devices']);

  return (
    <ul className="flex items-center divide-x divide-black text-sm text-primary">
      {onSelectAll && (
        <li className={classNames(onClearAll ? 'pr-2' : '')}>
          <ScreensFilterFooterAction
            label={t('screens.selectAll')}
            onClick={onSelectAll}
          />
        </li>
      )}
      {onClearAll && (
        <li className={classNames(onSelectAll ? 'pl-2' : '')}>
          <ScreensFilterFooterAction
            label={t('screens.clearAll')}
            onClick={onClearAll}
          />
        </li>
      )}
    </ul>
  );
};

export default ScreensFilterDropDownActions;
