import { GetLocations, ApiClientError } from '@raydiant/api-client-js';
import { useQueryClient } from 'react-query';
import raydiant from '../clients/raydiant';
import { keys } from '../queryClient';
import useProtectedMutation from './useProtectedMutation';

export default function useDeleteLocation() {
  const queryClient = useQueryClient();

  const mutation = useProtectedMutation<
    void,
    ApiClientError,
    { locationId: string },
    GetLocations
  >(({ locationId }) => raydiant.deleleteLocation(locationId), {
    onSuccess: () => {
      queryClient.invalidateQueries(keys.locations());
      queryClient.invalidateQueries(keys.pendingLocations());
    },
  });

  return mutation;
}
