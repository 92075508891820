import { Font } from '@raydiant/api-client-js/build/types/Font';
import { titleCase } from './stringUtils';
import { FontFamily } from '../components/TextStyleField/fontFamilies';

const REGULAR_FONT_WEIGHT = 'Regular';
const FONT_WEIGHTS: string[] = [
  'black',
  'blackitalic',
  'bold',
  'bolditalic',
  'extra',
  'extrabold',
  'extrabolditalic',
  'extraitalic',
  'extralight',
  'extralightitalic',
  'italic',
  'light',
  'lightitalic',
  'medium',
  'mediumitalic',
  'regular',
  'semi',
  'semibold',
  'semibolditalic',
  'semiitalic',
  'thin',
  'thinitalic',
];

export const getFontWeight = (file: File): string => {
  const fontWeight = FONT_WEIGHTS.find((fontWeight) =>
    matchesFontWeights(file, fontWeight),
  );

  return fontWeight ? parseFontWeight(fontWeight) : 'regular';
};

export const matchesFontWeights = (file: File, fontWeight: string) =>
  fileName(file).match(new RegExp(`\\b${fontWeight}\\b`, 'gmi'));

export const parseFontName = (file: File) => {
  const fontWeightMatch = matchesFontWeights(file, getFontWeight(file));

  if (fontWeightMatch && fontWeightMatch[0]) {
    const name = fileName(file).replace(fontWeightMatch[0], '');

    return name.endsWith('-') ? name.slice(0, -1) : name;
  }

  return fileName(file);
};

export const contentType = (file: File) => `font/${fileExtension(file)}`;

export const fileName = (file: File) =>
  file.name.replace(`.${fileExtension(file)}`, '');

export const parseFontWeight = (string: string) =>
  string.replace(/ |-|_/g, '').toLowerCase();

export const groupByFontName = (array: Font[]): { [key: string]: Font[] } => {
  return array.reduce((acc: any, item: Font) => {
    if (acc[item.name]) {
      acc[item.name].push(item);
    } else {
      acc[item.name] = [item];
    }

    return acc;
  }, {});
};

export const groupByFontWeight = (array: Font[]) =>
  array.reduce((acc: any, item: Font) => {
    if (acc[item.name]) {
      acc[titleCase(item.weight)].push({
        name: titleCase(item.name),
        url: getUrl(item),
      });
    } else {
      acc[titleCase(item.weight)] = {
        name: titleCase(item.name),
        url: getUrl(item),
      };
    }

    return acc;
  }, {});

export const getFontFamilyDefault = (fontWeights: string[]): string => {
  if (fontWeights.includes(REGULAR_FONT_WEIGHT)) {
    return REGULAR_FONT_WEIGHT;
  }
  if (fontWeights.length > 0) {
    return fontWeights[0];
  }
  return REGULAR_FONT_WEIGHT;
};

export const parseFonts = (fonts: Font[]): FontFamily[] => {
  const fontGroups = groupByFontName(fonts);

  return Object.keys(fontGroups).map((name) => {
    const item = fontGroups[name];
    const fontWeightGroup = groupByFontWeight(item);

    return {
      default: getFontFamilyDefault(Object.keys(fontWeightGroup)),
      fonts: Object.keys(fontWeightGroup).map((name) => ({
        name,
        url: fontWeightGroup[name].url,
      })),
      id: name,
      name: name,
    };
  });
};

const getUrl = (font: Font): string => font.fileUpload.url;

const fileExtension = (file: File) => file.name.split('.').pop();
