import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

export default makeStyles((theme: Theme) => {
  return createStyles({
    integration: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      overflow: 'hidden', // Needed to show inner scroll bar.
      borderRadius: theme.borderRadius.md,

      [theme.breakpoints.down('xs')]: {
        flex: 'auto',
        margin: theme.spacing(0, 1),
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },

    integrationMain: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden', // Needed to show inner scroll bar.
      marginTop: theme.spacing(4),

      [theme.breakpoints.down('xs')]: {
        flex: 'auto',
        marginTop: 0,
      },
    },

    section: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden', // Needed to show inner scroll bar.
      padding: theme.spacing(0, 4),
      paddingBottom: theme.spacing(1),

      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
      },
    },

    heading: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(4),
    },

    wrapper: {
      flex: 1,
      overflow: 'hidden',
      flexWrap: 'nowrap',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        overflow: 'auto',
      },
    },
  });
});
