type ScreensFilterControlProps = {
  children: React.ReactNode;
  title: React.ReactNode;
  footer?: React.ReactNode;
};

const ScreensFilterControl = ({
  children,
  title,
  footer,
}: ScreensFilterControlProps) => {
  return (
    <div className="text-black flex flex-col gap-2">
      <div className="font-[500] text-[16px]">{title}</div>
      {children}
      {footer && footer}
    </div>
  );
};

export default ScreensFilterControl;
