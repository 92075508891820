import { batchActions } from 'redux-batched-actions';
import { all, call, fork, put, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import * as appActions from '../../actions/applications';
import * as folderActions from '../../actions/folders';
import * as domainActions from '../../actions/domains';
import * as A from '../../clients/mira/types/Application';
import * as F from '../../clients/mira/types/Folder';
import * as DM from '../../clients/mira/types/Domain';
import miraClient from '../../clients/miraClient';
import logger from '../../logger';
import * as actions from './actions';
import { V4LibraryQueryParams } from '@raydiant/api-client-js';

type LoadAddContentAction = ReturnType<typeof actions.loadAddContentPage>;

const watchLoadAddContentPage = function* () {
  while (true) {
    const action: LoadAddContentAction = yield take(
      getType(actions.loadAddContentPage),
    );
    const { profileId, search } = action.payload;

    try {
      yield put(actions.loadAddContentPageAsync.request());

      const [libraryFolder, applications, domain]: [
        F.VirtualFolder,
        A.Application[],
        DM.Domain | null,
      ] = yield all([
        call(() => {
          let params: V4LibraryQueryParams = {
            profile_id: profileId as string,
          };

          if (profileId) {
            if (search) {
              params.search = search;
            }

            return miraClient.getV4LibraryFolder(params);
          }

          return miraClient.getV4LibraryFolder();
        }),
        call(() => miraClient.getApplications()),
        call(() => miraClient.getDomain()),
      ]);

      yield put(
        batchActions([
          folderActions.fetchVirtualFolderAsync.success({
            id: 'library',
            ...libraryFolder,
          }),
          appActions.fetchApplicationsAsync.success(applications),
          actions.loadAddContentPageAsync.success(),
          ...(domain ? [domainActions.fetchDomainAsync.success(domain)] : []),
        ]),
      );
    } catch (error: any) {
      logger.error(error);
      yield put(actions.loadAddContentPageAsync.failure(error));
    }
  }
};

export default all([fork(watchLoadAddContentPage)]);
