import {
  AnalyticsQueryParams,
  ApiClientError,
  GetContentEngagement,
} from '@raydiant/api-client-js';
import { keys } from '../queryClient';
import raydiant from '../clients/miraClient';
import useProtectedQuery from './useProtectedQuery';

export default function useContentEngagement(params: AnalyticsQueryParams) {
  return useProtectedQuery<GetContentEngagement, ApiClientError>(
    keys.contentEngagement(params),
    () => raydiant.getContentEngagement(params),
  );
}
