import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as devicesPageActions from '../DevicesPage/actions';
import LocationsPage from './LocationsPage';
import reducer from '../DevicesPage/reducer';
import devicesPageSagas from '../DevicesPage/sagas';

const devicesPageReducer = persistReducer(
  {
    key: 'DevicesPage',
    whitelist: ['sortOptions', 'sharingSortOptions'],
    storage,
  },
  reducer,
);

export default LocationsPage;
export { devicesPageReducer, devicesPageSagas, devicesPageActions };
