import { useTranslation } from 'react-i18next';
import DataTable from '../../../components/DataTable/DataTable';
import { LocationTraffic } from '@raydiant/api-client-js';
import ChartCard from '../ChartCard/ChartCard';
import { getDisplayValue, toPercent } from '../ShopperEngagementInsights/utils';
import DataTableHeader, {
  TableSort,
} from '../../../components/DataTable/DataTableHeader';
import DataTableCell from '../../../components/DataTable/DataTableCell';
import { PaginationProps } from '../../../components/Pagination/Pagination';
import { Popover } from '@headlessui/react';
import QuestionMarkIcon from '../../../components/QuestionMarkIcon';
import PopoverButton from '../../../components/Popover/PopoverButton';

type TopLocationsTableProps = PaginationProps & {
  data: LocationTraffic[];
  onSort: (sort: TableSort<LocationTraffic>) => void;
  sort: TableSort<LocationTraffic>;
  showAll?: boolean;
  showPagination?: boolean;
};

const TopLocationsTable = ({
  currentPage,
  data,
  onSort,
  onPageChange,
  pageCount,
  sort,
  showAll = false,
  showPagination = true,
}: TopLocationsTableProps) => {
  const { t } = useTranslation();

  return (
    <ChartCard
      subTitle={''}
      title={
        <div className="flex gap-3 items-center">
          <h2 className="text-black text-xl font-bold">
            {t('analytics.trafficAwarenessPage.topLocationsTable.title')!}
          </h2>
          <Popover className="relative">
            <PopoverButton icon={<QuestionMarkIcon />} />
            <Popover.Panel className="absolute z-10 card popover">
              <div className="overflow-hidden">
                <p className="mb-3">
                  {showAll
                    ? t(
                        'analytics.trafficAwarenessPage.topLocationsTable.tooltip.default',
                      )
                    : t(
                        'analytics.trafficAwarenessPage.topLocationsTable.tooltip.viOnly',
                      )}
                </p>
                <a
                  href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('analytics.learnmore')}
                </a>
              </div>
            </Popover.Panel>
          </Popover>
        </div>
      }
      showTimeInterval={false}
    >
      <DataTable<LocationTraffic>
        currentPage={currentPage}
        data={data}
        showPagination={showPagination}
        headers={
          <tr>
            <DataTableHeader<LocationTraffic>
              className="!text-left sticky left-[0] bg-white"
              onSort={onSort}
              showSort={sort.key === 'location'}
              sortDirection={
                sort.key === 'location' ? sort.direction : undefined
              }
              sortKey={'location'}
            >
              {t('analytics.trafficAwarenessPage.tableHeaders.location')}
            </DataTableHeader>
            <DataTableHeader<LocationTraffic>
              onSort={onSort}
              showSort={sort.key === 'ots'}
              sortDirection={sort.key === 'ots' ? sort.direction : undefined}
              sortKey={'ots'}
              // wrapperClassName="!justify-start"
            >
              {t('analytics.trafficAwarenessPage.tableHeaders.ots')}
            </DataTableHeader>
            {showAll && (
              <>
                <DataTableHeader<LocationTraffic>
                  showSort={sort.key === 'impressions'}
                  sortDirection={
                    sort.key === 'impressions' ? sort.direction : undefined
                  }
                  sortKey={'impressions'}
                  onSort={onSort}
                >
                  {t('analytics.trafficAwarenessPage.tableHeaders.impressions')}
                </DataTableHeader>
                <DataTableHeader<LocationTraffic>
                  showSort={sort.key === 'verifiedViews'}
                  sortDirection={
                    sort.key === 'verifiedViews' ? sort.direction : undefined
                  }
                  sortKey={'verifiedViews'}
                  onSort={onSort}
                >
                  {t(
                    'analytics.trafficAwarenessPage.tableHeaders.verifiedViews',
                  )}
                </DataTableHeader>
                <DataTableHeader<LocationTraffic>
                  showSort={sort.key === 'screenPlacementQuality'}
                  sortDirection={
                    sort.key === 'screenPlacementQuality'
                      ? sort.direction
                      : undefined
                  }
                  sortKey={'screenPlacementQuality'}
                  onSort={onSort}
                >
                  {t(
                    'analytics.trafficAwarenessPage.tableHeaders.screenPlacementQuality',
                  )}
                </DataTableHeader>
                <DataTableHeader<LocationTraffic>
                  showSort={sort.key === 'verifiedViewsRate'}
                  sortDirection={
                    sort.key === 'verifiedViewsRate'
                      ? sort.direction
                      : undefined
                  }
                  sortKey={'verifiedViewsRate'}
                  onSort={onSort}
                >
                  {t(
                    'analytics.trafficAwarenessPage.tableHeaders.verifiedViewsRate',
                  )}
                </DataTableHeader>
              </>
            )}
            <DataTableHeader<LocationTraffic>
              showSort={sort.key === 'avgDwellTime'}
              sortDirection={
                sort.key === 'avgDwellTime' ? sort.direction : undefined
              }
              sortKey={'avgDwellTime'}
              onSort={onSort}
            >
              {t('analytics.trafficAwarenessPage.tableHeaders.avgDwellTime')}{' '}
              (SEC)
            </DataTableHeader>
            <DataTableHeader<LocationTraffic>
              showSort={sort.key === 'avgViewingTime'}
              sortDirection={
                sort.key === 'avgViewingTime' ? sort.direction : undefined
              }
              sortKey={'avgViewingTime'}
              onSort={onSort}
            >
              {t('analytics.trafficAwarenessPage.tableHeaders.avgViewingTime')}{' '}
              (SEC)
            </DataTableHeader>
          </tr>
        }
        onPageChange={onPageChange}
        pageCount={pageCount}
        renderRow={(item, index) => (
          <tr key={index}>
            <DataTableCell hasSort className="sticky left-0 bg-white">
              {item.location.split('|')[1]}
            </DataTableCell>
            <DataTableCell hasSort>{getDisplayValue(item.ots)}</DataTableCell>
            {showAll && (
              <>
                <DataTableCell hasSort>
                  {getDisplayValue(item.impressions)}
                </DataTableCell>
                <DataTableCell hasSort>
                  {getDisplayValue(item.verifiedViews)}
                </DataTableCell>
                <DataTableCell hasSort>
                  {getDisplayValue(
                    toPercent(item.screenPlacementQuality / 100),
                    '%',
                  )}
                </DataTableCell>
                <DataTableCell hasSort>
                  {item.verifiedViews
                    ? getDisplayValue(
                        toPercent(item.verifiedViewsRate / 100),
                        '%',
                      )
                    : '-'}
                </DataTableCell>
              </>
            )}
            <DataTableCell hasSort>
              {getDisplayValue(item.avgDwellTime, ' sec')}
            </DataTableCell>
            <DataTableCell hasSort>
              {getDisplayValue(item.avgViewingTime, ' sec')}
            </DataTableCell>
          </tr>
        )}
      />
    </ChartCard>
  );
};

export default TopLocationsTable;
