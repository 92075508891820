import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/common.json';
import devicesEN from './locales/en/devices.json';

const resources = {
  en: {
    translation: translationEN,
    translationsNS: {},
  },
};

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
  keySeparator: '.',
});
i18n.addResourceBundle('en', 'devices', devicesEN);

export default i18n;
