import useProtectedQuery from './useProtectedQuery';
import { keys } from '../queryClient';
import { ApiClientError } from '@raydiant/api-client-js';

export interface MarketPlaceTile {
  type: 'cx' | 'dx' | 'ex' | 'ai' | 'tiktok';
  heading: string;
  body: string;
  backgroundImage: string;
  color: string;
  buttonColor: 'progress' | 'default' | 'primary';
}

const marketPlaceData: MarketPlaceTile[] = [
  {
    body: 'Decrease content management &\ndeployment costs.',
    backgroundImage:
      'https://assets.raydiant.com/marketplace/cx-tile-thumbnail.png',
    buttonColor: 'progress',
    color: '#28d972',
    heading: 'Rules & Tags',
    type: 'cx',
  },
  {
    body: 'Build custom apps for digital\nsignage and kiosks',
    backgroundImage:
      'https://assets.raydiant.com/marketplace/dx-tile-thumbnail.png',
    buttonColor: 'default',
    color: '#333333',
    heading: 'Developer Platform',
    type: 'dx',
  },
  {
    body: 'Creating a more connected\nemployee experience',
    backgroundImage:
      'https://assets.raydiant.com/marketplace/ex-tile-thumbnail.png',
    buttonColor: 'primary',
    color: '#003874',
    heading: 'Employee Experience\nfor Brick & Mortar',
    type: 'ex',
  },
  {
    body: 'Engage customers in real-time\nthrough the power of AI',
    backgroundImage:
      'https://assets.raydiant.com/marketplace/ai-tile-thumbnail.png',
    buttonColor: 'primary',
    color: '#0047d4',
    heading: 'AI Customer Analytics',
    type: 'ai',
  },
  {
    body: 'The future of Brick-and-Mortar\ncontent experiences.',
    backgroundImage:
      'https://assets.raydiant.com/marketplace/tictok-tile-thumbnail.png',
    buttonColor: 'default',
    color: '#28d972',
    heading: 'TikTok',
    type: 'tiktok',
  },
];

const shuffleArray = (array: MarketPlaceTile[]): MarketPlaceTile[] => {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));

    let temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  return array;
};

export default function useMarketPlaceTiles() {
  const query = useProtectedQuery<MarketPlaceTile[], ApiClientError>(
    keys.marketPlaceTiles(),
    () => shuffleArray(marketPlaceData),
  );

  return query;
}
