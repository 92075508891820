import React from 'react';
import useCurrentUserRoles from '../../hooks/useCurrentUserRoles';
import SidebarMenuItem from './SidebarMenuItem';
import { Book, CircleNodes } from '@raydiant/raydial/components/Icons';

interface NavigationSubMenu {
  href: string;
  label: string;
}

export interface NavigationMenu extends NavigationSubMenu {
  icon: React.ReactNode;
  subItems?: NavigationSubMenu[];
}

export const NAVIGATION: NavigationMenu[] = [
  {
    href: '/screens',
    label: 'screens.pageTitle',
    icon: (
      <svg
        fill="currentColor"
        className="size-6 text-inherit"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 640 512"
      >
        <path d="M64 64V352H576V64H64zM0 64C0 28.7 28.7 0 64 0H576c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zM128 448H512c17.7 0 32 14.3 32 32s-14.3 32-32 32H128c-17.7 0-32-14.3-32-32s14.3-32 32-32z" />
      </svg>
    ),
    subItems: [],
  },
  {
    href: '/library',
    label: 'library.pageTitle',
    icon: (
      <svg
        className="size-6 text-inherit"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 640 512"
      >
        <path d="M192 64c0-35.3 28.7-64 64-64H576c35.3 0 64 28.7 64 64v69.6c-12.9-6.1-27.9-7.1-41.7-2.5l-98.9 33-37.8-60.5c-2.9-4.7-8.1-7.5-13.6-7.5s-10.6 2.8-13.6 7.5L388 177.9l-15.3-19.7c-3-3.9-7.7-6.2-12.6-6.2s-9.6 2.3-12.6 6.2l-56 72c-3.8 4.8-4.4 11.4-1.7 16.9s8.3 9 14.4 9h64l0 64H256c-35.3 0-64-28.7-64-64V64zM319.5 404.6c-13.8 10.3-25.2 25.2-29.6 43.4H64c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h96V360c0 17.7 14.3 32 32 32H342.2c-8.2 3.3-15.8 7.5-22.6 12.6zM320 96a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM56 168v16c0 8.8 7.2 16 16 16H88c8.8 0 16-7.2 16-16V168c0-8.8-7.2-16-16-16H72c-8.8 0-16 7.2-16 16zm16 80c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16H88c8.8 0 16-7.2 16-16V264c0-8.8-7.2-16-16-16H72zM56 360v16c0 8.8 7.2 16 16 16H88c8.8 0 16-7.2 16-16V360c0-8.8-7.2-16-16-16H72c-8.8 0-16 7.2-16 16zM630 164.5c6.3 4.5 10 11.8 10 19.5v48V392c0 1.2-.1 2.4-.3 3.6c.2 1.5 .3 2.9 .3 4.4c0 26.5-28.7 48-64 48s-64-21.5-64-48s28.7-48 64-48c5.5 0 10.9 .5 16 1.5V265.3l-144 48V464c0 26.5-28.7 48-64 48s-64-21.5-64-48s28.7-48 64-48c5.5 0 10.9 .5 16 1.5V296 248c0-10.3 6.6-19.5 16.4-22.8l192-64c7.3-2.4 15.4-1.2 21.6 3.3z" />
      </svg>
    ),
    subItems: [],
  },
  {
    href: '/marketplace',
    label: 'marketplace.pageTitle',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        className="size-6 text-inherit"
        viewBox="0 0 576 512"
      >
        <path d="M547.6 103.8L490.3 13.1C485.2 5 476.1 0 466.4 0H109.6C99.9 0 90.8 5 85.7 13.1L28.3 103.8c-29.6 46.8-3.4 111.9 51.9 119.4c4 .5 8.1 .8 12.1 .8c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.2 0 49.3-11.4 65.2-29c16 17.6 39.1 29 65.2 29c4.1 0 8.1-.3 12.1-.8c55.5-7.4 81.8-72.5 52.1-119.4zM499.7 254.9l-.1 0c-5.3 .7-10.7 1.1-16.2 1.1c-12.4 0-24.3-1.9-35.4-5.3V384H128V250.6c-11.2 3.5-23.2 5.4-35.6 5.4c-5.5 0-11-.4-16.3-1.1l-.1 0c-4.1-.6-8.1-1.3-12-2.3V384v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V384 252.6c-4 1-8 1.8-12.3 2.3z" />
      </svg>
    ),
    subItems: [],
  },
  {
    href: '/analytics',
    label: 'analytics.analytics',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        className="size-6 text-inherit"
        viewBox="0 0 512 512"
      >
        <path d="M503.6 74.2c10.1-8.6 11.2-23.8 2.6-33.8s-23.8-11.2-33.8-2.6L320.2 168.3 207.8 69.9c-8.2-7.1-20.1-7.9-29.1-1.9L10.7 180c-11 7.4-14 22.3-6.7 33.3s22.3 14 33.3 6.7L190 118.2l114.2 99.9c9 7.9 22.4 7.9 31.4 .2l168-144zM200 432c0 4.4-3.6 8-8 8s-8-3.6-8-8V272c0-4.4 3.6-8 8-8s8 3.6 8 8V432zm-8-208c-26.5 0-48 21.5-48 48V432c0 26.5 21.5 48 48 48s48-21.5 48-48V272c0-26.5-21.5-48-48-48zM72 432c0 4.4-3.6 8-8 8s-8-3.6-8-8V368c0-4.4 3.6-8 8-8s8 3.6 8 8v64zM64 320c-26.5 0-48 21.5-48 48v64c0 26.5 21.5 48 48 48s48-21.5 48-48V368c0-26.5-21.5-48-48-48zm264 16v96c0 4.4-3.6 8-8 8s-8-3.6-8-8V336c0-4.4 3.6-8 8-8s8 3.6 8 8zm-56 0v96c0 26.5 21.5 48 48 48s48-21.5 48-48V336c0-26.5-21.5-48-48-48s-48 21.5-48 48zm184 96c0 4.4-3.6 8-8 8s-8-3.6-8-8V272c0-4.4 3.6-8 8-8s8 3.6 8 8V432zm-8-208c-26.5 0-48 21.5-48 48V432c0 26.5 21.5 48 48 48s48-21.5 48-48V272c0-26.5-21.5-48-48-48z" />
      </svg>
    ),
    subItems: [
      {
        href: '/analytics',
        label: 'analytics.analytics',
      },
      {
        href: '/analytics/content',
        label: 'analytics.contentEngagementPage.title',
      },
    ],
  },
];

const productHubNavigation = [
  {
    href: '/product-hub/catalogs',
    label: 'catalogs.pageTitle',
    icon: <Book className="size-6" />,
  },
  {
    href: '/product-hub/integrations',
    label: 'integrations.pageTitle',
    icon: <CircleNodes className="size-6" />,
  },
];

type SidebarMenuProps = {
  isOpen: boolean;
  onTopMenuClick: (value: boolean) => void;
};

const SidebarMenu = ({ isOpen, onTopMenuClick }: SidebarMenuProps) => {
  const roles = useCurrentUserRoles();

  const analyticsMenuItem = NAVIGATION.find((navigation) =>
    navigation.href.match(/analytics/),
  );

  const getDefaultAnalytics = () => {
    if (roles.isRXEnabled) {
      return '/analytics';
    }
    if (roles.isVIEnabled || roles.isVIOnlyEnabled) {
      return '/analytics/traffic';
    }
    return '/analytics/content';
  };

  const renderAnalyticsMenu = () => {
    const analyticsMenu = {
      href: getDefaultAnalytics(),
      icon: analyticsMenuItem?.icon,
      label: 'analytics.analytics',
      subItems: [] as NavigationSubMenu[],
    };

    analyticsMenu.subItems = [
      ...analyticsMenu.subItems,
      {
        href: '/analytics/traffic',
        label: 'analytics.trafficAwarenessPage.title',
      },
    ];

    analyticsMenu.subItems = [
      ...analyticsMenu.subItems,
      {
        href: '/analytics/content',
        label: 'analytics.contentEngagementPage.title',
      },
    ];

    analyticsMenu.subItems = [
      ...analyticsMenu.subItems,
      {
        href: '/analytics',
        label: 'Product Engagement',
      },
    ];

    return analyticsMenu;
  };

  const viOnlyDefaultNavigation = {
    href: '/screens',
    label: 'Devices',
    icon: (
      <svg
        fill="currentColor"
        className="size-6 text-inherit"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 640 512"
      >
        <path d="M64 64V352H576V64H64zM0 64C0 28.7 28.7 0 64 0H576c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zM128 448H512c17.7 0 32 14.3 32 32s-14.3 32-32 32H128c-17.7 0-32-14.3-32-32s14.3-32 32-32z" />
      </svg>
    ),
    subItems: [],
  };

  return (
    <ul className="menu">
      {!roles.isVIOnlyEnabled &&
        NAVIGATION.filter((link) => link.href !== analyticsMenuItem?.href).map(
          (link) => {
            return (
              <SidebarMenuItem
                menuItem={link}
                onTopMenuClick={onTopMenuClick}
              />
            );
          },
        )}
      {roles.isVIOnlyEnabled && (
        <SidebarMenuItem
          menuItem={viOnlyDefaultNavigation}
          onTopMenuClick={onTopMenuClick}
        />
      )}
      {roles.isProductHubEnabled &&
        productHubNavigation.map((link) => {
          return (
            <SidebarMenuItem menuItem={link} onTopMenuClick={onTopMenuClick} />
          );
        })}
      <SidebarMenuItem
        menuItem={renderAnalyticsMenu()}
        onTopMenuClick={onTopMenuClick}
      />
    </ul>
  );
};

export default SidebarMenu;
