import { useState, useEffect } from 'react';
import { Location } from '@raydiant/api-client-js';
import Button from '@raydiant/raydial/components/Button';
import Modal from '@raydiant/raydial/components/Modal';
import TextField from '@raydiant/raydial/components/TextField';
import TextArea from '@raydiant/raydial/components/TextArea';
import Dialog, {
  DialogHeader,
  DialogHeading,
  DialogSubheading,
} from '@raydiant/raydial/components/Dialog';
import useUpdateLocation from '../../../../hooks/useUpdateLocation';
import { useToastState } from '@raydiant/raydial/components/Toast/ToastProvider';

interface EditLocationDialogProps {
  isOpen: boolean;
  location: Location;
  onSubmit: () => void;
  onClose: () => void;
}

const EditLocationDialog = ({
  isOpen = false,
  location,
  onSubmit,
  onClose,
}: EditLocationDialogProps) => {
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const updateLocation = useUpdateLocation();
  const state = useToastState();

  const handleUpdateLocation = async () => {
    try {
      await updateLocation.mutate({
        locationId: location.id,
        params: {
          name,
          description,
        },
      });
      setName('');
      setDescription('');
      state.add({
        title: 'Location Updated',
        description: 'Location updated successfully',
      });
    } catch (err) {
      state.add({
        title: 'Location could not be updated',
        description: 'Please try again',
        variant: 'caution',
      });
    } finally {
      onSubmit && onSubmit();
    }
  };

  useEffect(() => {
    setName(location.name);
    setDescription(location.description);
  }, [location.name, location.description]);

  return (
    <Modal isOpen={isOpen} className="overflow-auto">
      <Dialog showCloseButton={true} onClose={onClose}>
        <DialogHeader>
          <DialogHeading>
            <span className="text-black">Edit Location</span>
          </DialogHeading>
          <DialogSubheading>Set up a location</DialogSubheading>
        </DialogHeader>

        <TextField
          value={name || ''}
          onChange={setName}
          placeholder="Location Name"
          description="eg: Austin, TX, Financial District, City Centre Mall, etc."
        />

        <div className="mt-4">
          <TextArea
            value={description || ''}
            onChange={setDescription}
            placeholder="Location Details - Optional"
            description="Add any helpful notes for this location"
            rows={14}
          />
        </div>

        <div className="flex flex-col-reverse justify-between mt-8 mb-4 gap-3 px-0 md:gap-6 md:flex-row md:px-4">
          <Button
            onClick={() => onClose()}
            variant="secondary"
            size="large"
            className="grow"
            isDisabled={updateLocation.isLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleUpdateLocation()}
            variant="primary"
            size="large"
            className="grow"
            isDisabled={updateLocation.isLoading}
            loading={updateLocation.isLoading}
          >
            Edit Location
          </Button>
        </div>
      </Dialog>
    </Modal>
  );
};

export default EditLocationDialog;
