import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  selectIsRaydiantSuperAdmin,
  selectIsCustomerSupport,
  selectIsDeveloper,
  selectIsAppReviewer,
  selectIsAIPOSEnabled,
  selectIsUserAIEnabled,
  selectIsPerchSettingsEnabled,
  selectIs4kEnabled,
  selectIsExAdministrator,
  selectIsVIOnly,
  selectIsVIEnabled,
  selectIsRXEnabled,
  selectIsKioskEnabled,
  selectIsProductHubEnabled,
  selectIsContentDetailEnabled,
} from '../selectors/user';

export default function useCurrentUserRoles() {
  // We are using useSelector here to re-use the state that's in redux, which is populated
  // on page load in actions/user.ts. Ideally we would use react-query to fetch and cache the
  // current user's roles but to avoid two fetches we will use the redux state until we can
  // refactor more pages to use react-query instead.
  const raydiantSuperadmin = useSelector(selectIsRaydiantSuperAdmin);
  const customerSupport = useSelector(selectIsCustomerSupport);
  const developer = useSelector(selectIsDeveloper);
  const appReviewer = useSelector(selectIsAppReviewer);
  const exAdmin = useSelector(selectIsExAdministrator);
  const isAIPOSEnabled = useSelector(selectIsAIPOSEnabled);
  const isUserAIEnabled = useSelector(selectIsUserAIEnabled);
  const is4kEnabled = useSelector(selectIs4kEnabled);
  const isPerchSettingsEnabled = useSelector(selectIsPerchSettingsEnabled);

  const isVIOnlyEnabled = useSelector(selectIsVIOnly);
  const isVIEnabled = useSelector(selectIsVIEnabled);
  const isRXEnabled = useSelector(selectIsRXEnabled);
  const isKioskEnabled = useSelector(selectIsKioskEnabled);
  const isProductHubEnabled = useSelector(selectIsProductHubEnabled);
  const isContentDetailEnabled = useSelector(selectIsContentDetailEnabled);

  // Convert role list to a map for easy lookup.
  const roles = useMemo(() => {
    return {
      raydiantSuperadmin,
      customerSupport,
      developer,
      appReviewer,
      exAdmin,
      isAIPOSEnabled,
      isUserAIEnabled,
      isPerchSettingsEnabled,
      is4kEnabled,
      isVIOnlyEnabled,
      isVIEnabled,
      isRXEnabled,
      isKioskEnabled,
      isProductHubEnabled,
      isContentDetailEnabled,
    };
  }, [
    raydiantSuperadmin,
    customerSupport,
    developer,
    appReviewer,
    exAdmin,
    isAIPOSEnabled,
    isUserAIEnabled,
    isPerchSettingsEnabled,
    is4kEnabled,
    isVIOnlyEnabled,
    isVIEnabled,
    isRXEnabled,
    isKioskEnabled,
    isProductHubEnabled,
    isContentDetailEnabled,
  ]);

  return roles;
}
