import { useTranslation } from 'react-i18next';
import DataTable from '../../components/DataTable/DataTable';
import { EngagementByProduct } from '@raydiant/api-client-js';
import ChartCard from './ChartCard/ChartCard';
import { getDisplayValue, toPercent } from './ShopperEngagementInsights/utils';
import DataTableHeader, {
  TableSort,
} from '../../components/DataTable/DataTableHeader';
import DataTableCell from '../../components/DataTable/DataTableCell';
import { PaginationProps } from '../../components/Pagination/Pagination';
import { Popover } from '@headlessui/react';
import QuestionMarkIcon from '../../components/QuestionMarkIcon';
import PopoverButton from '../../components/Popover/PopoverButton';

type TopProductsDataTableCardProps = PaginationProps & {
  data: EngagementByProduct[];
  onSort: (sort: TableSort<EngagementByProduct>) => void;
  sort: TableSort<EngagementByProduct>;
  isRXEnabled: boolean;
  showPagination: boolean;
};

const TopProductsDataTableCard = ({
  currentPage,
  data,
  onPageChange,
  onSort,
  pageCount,
  sort,
  isRXEnabled,
  showPagination = true,
}: TopProductsDataTableCardProps) => {
  const { t } = useTranslation();

  return (
    <ChartCard
      subTitle={''}
      title={
        <div className="flex gap-3 items-center">
          <h2 className="text-black text-xl font-bold">
            {t('analytics.topProductsTable')!}
          </h2>
          <Popover className="relative">
            <PopoverButton icon={<QuestionMarkIcon />} />
            <Popover.Panel className="absolute z-10 card popover drop-shadow-md">
              <div className="overflow-hidden">
                <p className="mb-3">{t('analytics.topProductsTooltip')}</p>
                <a
                  href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('analytics.learnmore')}
                </a>
              </div>
            </Popover.Panel>
          </Popover>
        </div>
      }
      showTimeInterval={false}
    >
      <DataTable<EngagementByProduct>
        currentPage={currentPage}
        data={data}
        showPagination={showPagination}
        headers={
          <tr>
            <DataTableHeader<EngagementByProduct>
              className="w-96 !text-left sticky left-0 bg-white"
              onSort={onSort}
              showSort={sort.key === 'productCategoryName'}
              sortDirection={
                sort.key === 'productCategoryName' ? sort.direction : undefined
              }
              sortKey={'productCategoryName'}
              wrapperClassName="!justify-start"
            >
              {t('analytics.product')}
            </DataTableHeader>
            {isRXEnabled && (
              <DataTableHeader<EngagementByProduct>
                showSort={sort.key === 'pickups'}
                sortKey={'pickups'}
                sortDirection={
                  sort.key === 'pickups' ? sort.direction : undefined
                }
                onSort={onSort}
              >
                {t('analytics.productPickups')}
              </DataTableHeader>
            )}
            <DataTableHeader<EngagementByProduct>
              showSort={sort.key === 'clicks'}
              sortKey={'clicks'}
              sortDirection={sort.key === 'clicks' ? sort.direction : undefined}
              onSort={onSort}
            >
              {t('analytics.screenClicks')}
            </DataTableHeader>
            {isRXEnabled && (
              <>
                <DataTableHeader<EngagementByProduct>
                  showSort={sort.key === 'pickupToScreenConversion'}
                  sortKey={'pickupToScreenConversion'}
                  sortDirection={
                    sort.key === 'pickupToScreenConversion'
                      ? sort.direction
                      : undefined
                  }
                  onSort={onSort}
                >
                  {t('analytics.pickupsToScreen')}
                </DataTableHeader>
                <DataTableHeader<EngagementByProduct>
                  showSort={sort.key === 'totalInteractions'}
                  sortKey={'totalInteractions'}
                  sortDirection={
                    sort.key === 'totalInteractions'
                      ? sort.direction
                      : undefined
                  }
                  onSort={onSort}
                >
                  {t('analytics.totalInteractions')}
                </DataTableHeader>
              </>
            )}
            <DataTableHeader<EngagementByProduct>
              showSort={sort.key === 'sessions'}
              sortKey={'sessions'}
              sortDirection={
                sort.key === 'sessions' ? sort.direction : undefined
              }
              onSort={onSort}
            >
              {t('analytics.engagedShoppers')}
            </DataTableHeader>
          </tr>
        }
        onPageChange={onPageChange}
        pageCount={pageCount}
        renderRow={(item, index) => (
          <tr key={index}>
            <DataTableCell
              className="sticky left-0 bg-white"
              wrapperClassName="!text-left"
              hasSort
            >
              {item.productCategoryName}
            </DataTableCell>
            {isRXEnabled && (
              <DataTableCell hasSort>
                {getDisplayValue(item.pickups)}
              </DataTableCell>
            )}
            <DataTableCell hasSort>
              {getDisplayValue(item.clicks)}
            </DataTableCell>
            {isRXEnabled && (
              <>
                <DataTableCell hasSort>
                  {item.pickups
                    ? getDisplayValue(
                        toPercent(item.pickupToScreenConversion),
                        '%',
                      )
                    : '-'}
                </DataTableCell>
                <DataTableCell hasSort>
                  {getDisplayValue(item.totalInteractions)}
                </DataTableCell>
              </>
            )}
            <DataTableCell hasSort>
              {getDisplayValue(item.sessions)}
            </DataTableCell>
          </tr>
        )}
      />
    </ChartCard>
  );
};

export default TopProductsDataTableCard;
