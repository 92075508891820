import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

export default makeStyles((theme: Theme) => {
  return createStyles({
    section: {
      marginBottom: theme.spacing(5),

      '&:last-child': {
        marginBottom: 0,
      },
    },
    section1: {
      display: 'flex',
      alignItems: 'flex-end',
      '& :first-child': {
        flex: 7,
      },
      '& :nth-child(2)': {
        flex: 5,
      },
    },
    checkmark: {
      display: 'flex',
      color: '#28d972',
      marginLeft: theme.spacing(1),
    },
    checkIcon: {
      backgroundColor: '#ffffff',
      borderRadius: '1.5rem',
    },
    iconTextWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
  });
});
