import RaydialDatePicker from 'raydial-tailwind-datepicker';
import { DatepickerType } from 'raydial-tailwind-datepicker/dist/types';

export type DatePickerProps = DatepickerType;

const DatePicker = (props: DatePickerProps) => {
  return (
    <RaydialDatePicker
      containerClassName={'datepicker w-full relative'}
      {...props}
    />
  );
};

export default DatePicker;
