import { createStandardAction } from 'typesafe-actions';

import { ExportAnalyticsEnum } from '../types/analytics';

export const setUTMParameters = createStandardAction(
  '@ANALYTICS/SET_UTM_PARAMETERS',
)<{ source: string; medium: string; campaign: string }>();

export const clearUTMParameters = createStandardAction(
  '@ANALYTICS/CLEAR_UTM_PARAMETERS',
)<void>();

export const setExportType = createStandardAction('@ANALYTICS/SET_EXPORT_TYPE')<
  ExportAnalyticsEnum | ''
>();
