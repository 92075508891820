import { FC, useState } from 'react';
import Select from 'raydiant-elements/core/Select';
import InputLabel from 'raydiant-elements/core/InputLabel';
import {
  ruleSourceOptionGroups,
  ruleSources,
  RuleSourceID,
  RuleSourceOption,
  ruleSourceWithToolTips,
  AI_WORKER_RULE_SOURCE_CONSTANT,
  AI_WORKER_LEARNMORE,
  AI_WORKER_LEARNMORE_URL,
  RX_WORKER_LEARNMORE,
  RX_WORKER_LEARNMORE_URL,
} from './ruleTokenInputData';
import { MenuItem, Tooltip } from '@material-ui/core';
import { Hidden } from 'raydiant-elements/layout/Hidden/Hidden';
import NestedMenuItem from '../NestedMenuItem';
import useStyles from './RuleSelect.styles';
import { selectIsRXEnabled, selectIsUserAIEnabled } from '../../selectors/user';
import { useSelector } from 'react-redux';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import { getToolTipContent } from './ruleSourceToolTipUtils';
import { isAIRuleSource, isRXRuleSource } from './ruleSourceIdUtils';
import { InfoOutlined } from '@material-ui/icons';
export interface RuleSourceSelectProps {
  disabled?: boolean;
  label: string;
  onChange: (value: RuleSourceID | null) => void;
  onHandleConstantValue?: () => void;
  ruleSource?: RuleSourceID | null;
  value: RuleSourceID | null;
  isSource1?: boolean;
  isSource2?: boolean;
}

const menuValues = (menuItem: RuleSourceID) => menuItem.split('.');

const rootMenu = (menuItem: RuleSourceID) => menuValues(menuItem)[0];

const locationTag = (menuItem: RuleSourceID) => menuValues(menuItem)[2];

const schedulingTag = (menuItem: RuleSourceID) => menuValues(menuItem)[1];

const schedulingSource = (menuItem: RuleSourceID) => menuValues(menuItem)[2];

const AiGroupNames = [
  'Aggregated Visitor Targeting',
  'Real Time Visitor Targeting',
];
const RXGroupNames = ['Product Events'];

const RuleSourceSelect: FC<RuleSourceSelectProps> = ({
  disabled = false,
  label,
  onChange,
  onHandleConstantValue,
  ruleSource,
  value,
  isSource1,
  isSource2,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const isUserAIEnabled = useSelector(selectIsUserAIEnabled);
  const isRXEnabled = useSelector(selectIsRXEnabled);

  const showConstantsMenu = isSource2 && isUserAIEnabled;

  const toggleMenu = () => setOpen(open ? false : true);

  const filterSchedulingMenuItems = (group: RuleSourceOption) => {
    if (!isSource2) {
      return true;
    }

    return isSource2 && group.name !== 'Schedule';
  };

  const filterAiWorkerMenuItems = (group: RuleSourceOption) => {
    if (!isUserAIEnabled && AiGroupNames.includes(group.name)) return false; //feature flag

    if (!isSource2) return true;

    return isSource2 && !AiGroupNames.includes(group.name);
  };

  const filterAiWorkerMobileMenuItems = (group: RuleSourceOption) => {
    return !AiGroupNames.includes(group.name);
  };

  const filterRXWorkerMenuItems = (group: RuleSourceOption) => {
    if (!isRXEnabled && RXGroupNames.includes(group.name)) return false; //feature flag

    if (!isSource2) return true;

    return isSource2 && !RXGroupNames.includes(group.name);
  };

  const filterRXWorkerMobileMenuItems = (group: RuleSourceOption) => {
    return !RXGroupNames.includes(group.name);
  };

  const filterTimeAndDateMenuItems = (group: RuleSourceOption) => {
    if (!isSource1) {
      return true;
    }

    return isSource1 && group.name !== 'Time & Date';
  };

  const isMenuItemDisabled = (menuItem: RuleSourceID): boolean => {
    if (!ruleSource) {
      return false;
    }

    if (
      schedulingSource(ruleSource) === 'start_time' ||
      schedulingSource(ruleSource) === 'end_time'
    ) {
      return schedulingTag(menuItem) !== 'curr_time';
    }

    if (
      schedulingSource(ruleSource) === 'start_date' ||
      schedulingSource(ruleSource) === 'end_date'
    ) {
      return schedulingTag(menuItem) !== 'curr_date';
    }

    if (schedulingSource(ruleSource) === 'days_of_week') {
      return schedulingTag(menuItem) !== 'curr_day';
    }

    return (
      rootMenu(ruleSource) === rootMenu(menuItem) ||
      locationTag(ruleSource) !== locationTag(menuItem)
    );
  };

  const renderTooltip = (sourceId: string) => {
    return (
      <Tooltip title={getToolTipContent(sourceId)} placement="right-start">
        <HelpOutlineRoundedIcon />
      </Tooltip>
    );
  };

  const renderLabel = (label: string, sourceId: string) => {
    if (sourceId === AI_WORKER_LEARNMORE || sourceId === RX_WORKER_LEARNMORE) {
      return (
        <div className={classes.menuLabel}>
          {label} {<InfoOutlined />}
        </div>
      );
    }

    return (
      <div className={classes.menuLabel}>
        {label} {showTooltip(sourceId) ? renderTooltip(sourceId) : null}
      </div>
    );
  };

  const showTooltip = (sourceId: string) =>
    ruleSourceWithToolTips.includes(sourceId);

  const handleMenuClick = (sourceId: RuleSourceID) => {
    if (sourceId === AI_WORKER_LEARNMORE) {
      window?.open(AI_WORKER_LEARNMORE_URL, '_blank')?.focus();
    } else if (sourceId === RX_WORKER_LEARNMORE) {
      window?.open(RX_WORKER_LEARNMORE_URL, '_blank')?.focus();
    } else {
      onChange(sourceId);
      toggleMenu();
    }
  };

  const renderMenuItem = (option: RuleSourceOption, index: number) => {
    if ('groups' in option) {
      return (
        <>
          <NestedMenuItem
            disabled={option.groups?.every((option: RuleSourceOption) =>
              option.options?.every((sourceId) => isMenuItemDisabled(sourceId)),
            )}
            className={classes.menuItem}
            key={index}
            label={option.name}
            parentMenuOpen={open}
          >
            {option.groups
              ?.filter(filterSchedulingMenuItems)
              .map(renderMenuItem)}
            {option.options?.map((sourceId) => (
              <MenuItem
                className={classes.menuItem}
                disabled={isMenuItemDisabled(sourceId)}
                key={sourceId}
                value={sourceId}
                selected={sourceId === value}
                onClick={() => handleMenuClick(sourceId)}
              >
                {renderLabel(ruleSources[sourceId].label, sourceId)}
              </MenuItem>
            ))}
          </NestedMenuItem>
        </>
      );
    } else {
      return (
        <NestedMenuItem
          disabled={option.options?.every((sourceId) =>
            isMenuItemDisabled(sourceId),
          )}
          className={classes.menuItem}
          key={index}
          label={option.name}
          parentMenuOpen={open}
        >
          {option.options?.map((sourceId) => {
            return (
              <MenuItem
                className={classes.menuItem}
                disabled={isMenuItemDisabled(sourceId)}
                key={sourceId}
                value={sourceId}
                selected={sourceId === value}
                onClick={() => handleMenuClick(sourceId)}
              >
                {renderLabel(ruleSources[sourceId].label, sourceId)}
              </MenuItem>
            );
          })}
        </NestedMenuItem>
      );
    }
  };

  const renderDesktopMenu = () => (
    <>
      <InputLabel className={classes.root} disabled={disabled}>
        {label}
      </InputLabel>
      <Select
        classes={{
          select: classes.select,
        }}
        disabled={disabled}
        native={false}
        open={open}
        onClose={toggleMenu}
        onChange={() => toggleMenu()}
        onOpen={toggleMenu}
        value={value ?? ''}
        renderValue={() => (value ? ruleSources[value]?.label : '')}
      >
        {ruleSourceOptionGroups
          .filter(filterTimeAndDateMenuItems)
          .filter(filterAiWorkerMenuItems)
          .filter(filterRXWorkerMenuItems)
          .map(renderMenuItem)}
        {showConstantsMenu && (
          <MenuItem
            disabled={
              (ruleSource &&
                !isAIRuleSource(ruleSource) &&
                !isRXRuleSource(ruleSource) &&
                isSource2) ??
              false
            }
            className={classes.menuItem}
            key={'custom'}
            value={AI_WORKER_RULE_SOURCE_CONSTANT}
            selected={AI_WORKER_RULE_SOURCE_CONSTANT === value}
            onClick={() => onHandleConstantValue && onHandleConstantValue()}
          >
            Custom Value
          </MenuItem>
        )}
      </Select>
    </>
  );

  const renderMobileMenuItem = (
    option: RuleSourceOption,
    index: number,
  ): any => {
    if ('groups' in option) {
      return option.groups?.map(renderMobileMenuItem);
    } else {
      return option.options?.map((sourceId) => (
        <option
          key={index}
          disabled={isMenuItemDisabled(sourceId)}
          value={sourceId}
        >
          {ruleSources[sourceId].longLabel}
        </option>
      ));
    }
  };

  const renderMobileMenu = () => (
    <>
      <InputLabel className={classes.root} disabled={disabled}>
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        native={true}
        onChange={(event) => onChange(event as RuleSourceID)}
        value={value ?? ''}
      >
        <option value=""></option>
        {ruleSourceOptionGroups
          .filter(filterTimeAndDateMenuItems)
          .filter(filterAiWorkerMobileMenuItems)
          .filter(filterRXWorkerMobileMenuItems)
          .map(renderMobileMenuItem)}
      </Select>
    </>
  );

  return (
    <>
      <Hidden xsDown>{renderDesktopMenu()}</Hidden>
      <Hidden smUp>{renderMobileMenu()}</Hidden>
    </>
  );
};

export default RuleSourceSelect;
