import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      color: theme.palette.primary.main,
      fontSize: theme.spacing(3),
      fontWeight: 500,
    },
    button: {
      minWidth: '8rem',
    },
  }),
);
