export const connectionSteps = [
  {
    id: '800fa298-6c22-46f3-b12c-c36ce3c511b1',
    name: 'toast',
    steps: [
      {
        description: 'Login to your Toast account',
        imageUrl:
          'https://assets.raydiant.com/ai-integrations/toast-step-1.svg',
      },
      {
        description:
          'Go to the Integration tab in Toast. Click on Browse and Purchase Integration, search and then add Raydiant',
        imageUrl:
          'https://assets.raydiant.com/ai-integrations/toast-step-2.svg',
      },
      {
        description:
          'Go to the Integration tab in Toast. Click on Browse and Purchase Integration, search and then add Raydiant',
        imageUrl:
          'https://assets.raydiant.com/ai-integrations/toast-step-3.svg',
      },
    ],
  },
];

export const mockIntegrations = [
  {
    id: '800fa298-6c22-46f3-b12c-c36ce3c511b1',
    POS_integration_type: 'toast',
    name: 'toast',
    thumbnail_url: 'https://assets.raydiant.com/ai-integrations/toast-logo.svg',
    dds_key: '541c43b23-23a2-4-r45-ba23-0e9er3b4yy79',
    locations: [
      {
        restaurant_guid: 'kei939sj-ip93-0ekd-f9sk-jp7suw93nf98',
        restaurant_name: 'Butter and Toast',
        location_name: '155 East-brook Ave, Los Gatos, CA',
      },
      {
        restaurant_guid: '9103c28a-d7fa-48db-a5e5-1449a45ade4f',
        restaurant_name: 'Butter and Toast',
        location_name: '23 Colorado Blvd, Pasadena, CA',
      },
      {
        restaurant_guid: '71c40b33-cec9-4c95-be84-0e9dc4bbb579',
        restaurant_name: "Little O's",
        location_name: '521 w McDowell',
      },
    ],
  },
];

export const defaultLocationInfo = {
  restaurant_guid: '',
  restaurant_name: 'Butter and Toast',
  location_name: '155 East-brook Ave, Los Gatos, CA',
};

export const validateLocationId = (locationIds: string[], locationId: string) =>
  locationIds.includes(locationId);
