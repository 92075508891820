import {
  AnalyticsQueryParams,
  ContentDetailQueryParams,
} from '@raydiant/api-client-js';
import {
  AnalyticsFilterQuery,
  AnalyticsFilterQueryKeys,
} from './AnalyticsFilter';
import { DEFAULT_DATE_RANGE, DEFAULT_DATE_RANGE_KEY } from './DateRangeSelect';
import moment from 'moment';

export const getDefaultQueryParams = () => {
  let data: AnalyticsFilterQuery = {};

  data.location_id = '';
  data.from = DEFAULT_DATE_RANGE.from;
  data.to = DEFAULT_DATE_RANGE.to;
  data.dateRangeKey = DEFAULT_DATE_RANGE_KEY;
  data.presentation_id = '';

  return data;
};

export const getUrlQueryParams = (query: string) => {
  let data: AnalyticsFilterQuery = {};

  const keys: AnalyticsFilterQueryKeys[] = [
    'location_id',
    'from',
    'to',
    'dateRangeKey',
    'presentation_id',
  ];

  keys.forEach((key) => {
    const value = getQueryParam(query, key);

    if (value) data[key] = value as any;
  });

  return data;
};

export const getApiQueryParams = (query: string) => {
  let { dateRangeKey, ...data } = getUrlQueryParams(query);
  const defaultParams = getDefaultQueryParams();

  return (
    Object.keys(data).length > 0 ? data : defaultParams
  ) as AnalyticsQueryParams;
};

export const getQueryParam = (query: string, key: string) => {
  const url = new URLSearchParams(query);

  return url.get(key);
};

export const parseDateToString = (
  date: moment.MomentInput,
  format = 'YYYY-MM-DD',
) => moment(date).format(format).toString();

export const getContentDetailApiQueryParams = (query: string) => {
  let { dateRangeKey, ...data } = getUrlQueryParams(query);
  const defaultParams = getDefaultQueryParams();

  return (
    Object.keys(data).length > 0 ? data : defaultParams
  ) as ContentDetailQueryParams;
};
