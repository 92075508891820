import apiClient from '../clients/miraClient';
import {
  contentType,
  fileName,
  getFontWeight,
  parseFontName,
} from './fontUtils';
import { titleCase } from './stringUtils';

export const uploadFontFile = async (file: File) => {
  const [font, fileUploads] = await apiClient.createFont({
    file: {
      'content-length': file.size,
      'content-type': contentType(file),
      filename: fileName(file),
    },
    name: titleCase(parseFontName(file)),
    weight: getFontWeight(file).toLowerCase(),
  });

  const [fontUpload] = fileUploads;

  if (!font || !fontUpload) {
    throw new Error('Failed to upload font');
  }

  await apiClient.uploadFile(
    fontUpload.fileUploadId,
    fontUpload.uploadUrl,
    file,
  );
};
