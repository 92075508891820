import MarketplaceModal from './MarketplaceModal';

interface CustomPricingModalProps {
  appName: string;
  open: boolean;
  onClick: () => void;
  onClose: () => void;
}

const CustomPricingModal = ({
  appName,
  open,
  onClick,
  onClose,
}: CustomPricingModalProps) => {
  return (
    <MarketplaceModal
      heading="We're here to help you get started"
      content={`${appName} tailors pricing to your needs. Would you like to be contacted by a Raydiant Sales representative to discuss your requirements?`}
      open={open}
      onClickButtonText="Yes, contact me"
      onCloseButtonText="No, maybe later"
      handleClick={onClick}
      handleClose={onClose}
    />
  );
};

export default CustomPricingModal;
