import Page from '../../../components/Page';
import PageLayout from '../../../components/PageLayout/PageLayout';
import PageTitle from '../../../components/PageLayout/PageTitle';
import { useTranslation } from 'react-i18next';
import AnalyticsFilter from '../AnalyticsFilter/AnalyticsFilter';
import { useLocation } from 'react-router';
import AnalyticsPageSkeleton from '../AnalyticsPageSkeleton';
import AnalyticsCallToAction from '../AnalyticsCallToAction/AnalyticsCallToAction';
import Center from 'raydiant-elements/layout/Center';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import { getApiQueryParams } from '../AnalyticsFilter/utils';
import {
  ContentEngagementByClick,
  ContentEngagementByPresentation,
} from '@raydiant/api-client-js';
import { useEffect, useMemo, useState } from 'react';
import { TableSort } from '../../../components/DataTable/DataTableHeader';
import { getTotalDistributionTime, paginate, sort } from '../utils';
import Empty from '../../../components/Empty/Empty';
import Error from '../../../components/Error/Error';
import useContentEngagement from '../../../hooks/useContentEngagement';
import ContentEffectivenessTable from './ContentEffectvienessTable';
import { Popover } from '@headlessui/react';
import QuestionMarkIcon from '../../../components/QuestionMarkIcon';
import BestPerformingContentChart from './TopPlayedContentChart';
import { useSelector } from 'react-redux';
import { selectDomainForCurrentUser } from '../../../selectors/v2/domains';
import {
  selectIsContentDetailEnabled,
  selectIsKioskEnabled,
  selectIsRXEnabled,
  selectIsVIEnabled,
  selectIsVIOnly,
  selectUserProfile,
} from '../../../selectors/user';
import PlaybackTimeDistributionChart from './PlaybackTimeDistributionChart';
import AnalyticsCarousel from './AnalyticsCarousel';
import Hidden from 'raydiant-elements/layout/Hidden';
import VIOnlyLayout from '../ContentEngagementPage/VIOnlyLayout';
import TopPerformingContentChart from './TopPerformingContentChart';
import TopClicksDataTableCard from '../TopClicksDataTableCard';

const ContentEngagementPage = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const query = getApiQueryParams(search);

  const { data, isError, isLoading } = useContentEngagement(query);
  const profile = useSelector(selectUserProfile);
  const domain = useSelector(selectDomainForCurrentUser);

  const currentUser = useSelector(selectUserProfile);
  const isVIOnlyEnabled = useSelector(selectIsVIOnly);
  const isVIEnabled = useSelector(selectIsVIEnabled);
  const isRXEnabled = useSelector(selectIsRXEnabled);
  const isKioskEnabled = useSelector(selectIsKioskEnabled);
  const isContentDetailEnabled = useSelector(selectIsContentDetailEnabled);

  const showTopClicks = isKioskEnabled || isRXEnabled;
  const showViewingMetrics = isVIEnabled;
  const showContentDetailPage = isContentDetailEnabled; // Replace it with isVIEnabled when the ContentDetailPage is ready
  const showGraphicsPage =
    !showTopClicks && !showViewingMetrics && isVIOnlyEnabled;

  // Selectors
  const showLandingPage = !profile?.analyticsOnboardingEnabled;
  const hasQueryParams = Object.values(query).length > 0;

  const [contentEffectivenessTableSort, setContentEffectivenessTableSort] =
    useState<TableSort<ContentEngagementByPresentation>>({
      key: 'playbackDuration',
      direction: 'desc',
    });
  const [contentEffectivenessCurrentPage, setContentEffectivenessCurrentPage] =
    useState(0);
  const [topClicksTableSort, setTopClicksTableSort] = useState<
    TableSort<ContentEngagementByClick>
  >({
    key: 'clicks',
    direction: 'desc',
  });
  const [topClicksCurrentPage, setTopClicksCurrentPage] = useState(0);

  const contentEffectivenessData = useMemo(() => {
    const sortedItems = sort<ContentEngagementByPresentation>(
      data?.contentEngagementByPresentation!,
      contentEffectivenessTableSort.key,
      contentEffectivenessTableSort.direction,
    );

    const paginatedData = paginate<ContentEngagementByPresentation>(
      sortedItems!,
      10,
    );

    return {
      data: paginatedData[contentEffectivenessCurrentPage]?.map(
        (item, index) => ({
          rank: index + 1,
          ...item,
        }),
      ),
      pageCount: paginatedData?.length,
    };
  }, [
    data?.contentEngagementByPresentation,
    contentEffectivenessTableSort,
    contentEffectivenessCurrentPage,
  ]);

  const topClicksData = useMemo(() => {
    const sortedItems = sort<ContentEngagementByClick>(
      data?.contentEngagementByClick!,
      topClicksTableSort.key,
      topClicksTableSort.direction,
    );
    const paginatedData = paginate<ContentEngagementByClick>(sortedItems);

    return {
      data: paginatedData[topClicksCurrentPage]?.map((item, index) => ({
        rank: index + 1,
        ...item,
      })),
      pageCount: paginatedData?.length,
    };
  }, [
    data?.contentEngagementByClick,
    topClicksTableSort,
    topClicksCurrentPage,
  ]);

  useEffect(() => {
    setContentEffectivenessCurrentPage(0);
    setTopClicksCurrentPage(0);
  }, [isLoading]);

  const hasData = data && data?.contentEngagementByPresentation?.length > 0;
  const showEmptyState = !isLoading && !hasData;
  const showErrorState = !isLoading && isError;
  const showSkeleton = isLoading && !hasQueryParams;
  const showFilter = hasQueryParams;
  const showLoader = isLoading && hasQueryParams && !isError;
  const topPlayedContents = sort<ContentEngagementByPresentation>(
    data?.contentEngagementByPresentation!,
    'playbackDuration',
    'desc',
  );
  const topPerformingContents = sort<ContentEngagementByPresentation>(
    data?.contentEngagementByPresentation?.filter(
      (item) => !(item.ots === null && item.verifiedViews === null),
    )!,
    'ots',
    'desc',
  );
  const totalDistributionTime =
    hasData && getTotalDistributionTime(data.contentEngagementByPresentation);

  if (!currentUser) {
    return (
      <Page>
        <PageLayout
          sectionClassName="min-h-full"
          title={
            <PageTitle
              title={t('analytics.overview')}
              subTitle={t('analytics.pageSubTitle')!}
            />
          }
        >
          <AnalyticsFilter className="mb-8" />
          <Center className="h-[500px]">
            <CircularProgress size={30}></CircularProgress>
          </Center>
        </PageLayout>
      </Page>
    );
  }

  return (
    <Page>
      {showLandingPage && <AnalyticsCallToAction />}
      {showGraphicsPage && <VIOnlyLayout />}
      {!showGraphicsPage && (
        <>
          {!showLandingPage && (
            <PageLayout
              sectionClassName="min-h-full"
              title={
                <PageTitle
                  title={t('analytics.contentEngagementPage.title')}
                  subTitle={t('analytics.contentEngagementPage.subTitle')!}
                />
              }
            >
              {showSkeleton && <AnalyticsPageSkeleton />}
              {showFilter && <AnalyticsFilter className="mb-8" />}
              {showLoader && (
                <Center className="h-[500px]">
                  <CircularProgress size={30}></CircularProgress>
                </Center>
              )}
              {showEmptyState && (
                <div className="card bg-[#fafafa] w-full h-full items-center justify-center">
                  <Empty
                    title="There’s no data to show for the selected filters"
                    message="Please try different locations or dates. Also, make sure that you have set up a building tag for all your devices to start pushing data to the analytics dashboard. Check the FAQs section in the help article to learn more about the setup. You can also reach our support team there for help."
                  >
                    <a
                      href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                      rel="noreferrer"
                      target="_blank"
                    >
                      {t('analytics.learnmore')}
                    </a>
                  </Empty>
                </div>
              )}
              {showErrorState && (
                <div className="card bg-[#fafafa] w-full h-full items-center justify-center">
                  <Error>
                    <a
                      href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                      rel="noreferrer"
                      target="_blank"
                    >
                      {t('analytics.learnmore')}
                    </a>
                  </Error>
                </div>
              )}
              {hasData && !isError && (
                <>
                  <div className="flex gap-3 items-center mb-6 ml-6">
                    <h2 className="text-black text-xl font-bold">
                      {showViewingMetrics
                        ? t(
                            'analytics.contentEngagementPage.topPlayedAndTopPerformingContent.title.fullAccess',
                          )
                        : t(
                            'analytics.contentEngagementPage.topPlayedAndTopPerformingContent.title.default',
                          )}
                    </h2>
                    <Popover className="relative">
                      <Popover.Button>
                        <QuestionMarkIcon />
                      </Popover.Button>
                      <Popover.Panel className="absolute z-10 card popover">
                        <div className="overflow-hidden">
                          <p className="mb-3">
                            {t(
                              'analytics.contentEngagementPage.topPlayedAndTopPerformingContent.tooltip',
                            )}
                          </p>
                          <a
                            href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                            rel="noreferrer"
                            target="_blank"
                          >
                            {t('analytics.learnmore')}
                          </a>
                        </div>
                      </Popover.Panel>
                    </Popover>
                  </div>

                  <div className="card">
                    <div className="flex flex-col w-full md:flex-row">
                      <div className="flex-1">
                        <PlaybackTimeDistributionChart
                          data={topPlayedContents}
                          totalDistributionTime={totalDistributionTime || ''}
                        />
                      </div>
                      <Hidden smDown>
                        <div
                          className="flex justify-end items-center md:p-9"
                          style={{ width: '45%' }}
                        >
                          <AnalyticsCarousel />
                        </div>
                      </Hidden>
                      <Hidden smUp>
                        <div className="flex justify-end items-center mt-3 mb-3">
                          <AnalyticsCarousel />
                        </div>
                      </Hidden>
                    </div>
                    <div>
                      <BestPerformingContentChart
                        data={topPlayedContents.slice(0, 10)}
                        domainName={domain?.name || ''}
                        isFullAccess={showViewingMetrics}
                      />
                    </div>
                    {showViewingMetrics && (
                      <div>
                        <TopPerformingContentChart
                          data={topPerformingContents.slice(0, 10)}
                          domainName={domain?.name || ''}
                        />
                      </div>
                    )}
                    <div className="mb-6">
                      <ContentEffectivenessTable
                        currentPage={contentEffectivenessCurrentPage}
                        data={contentEffectivenessData?.data}
                        onPageChange={(page) => {
                          setContentEffectivenessCurrentPage(page);
                        }}
                        onSort={(sort) => {
                          setContentEffectivenessTableSort({
                            ...contentEffectivenessTableSort,
                            ...sort,
                          });
                          setContentEffectivenessCurrentPage(0);
                        }}
                        pageCount={contentEffectivenessData.pageCount}
                        sort={contentEffectivenessTableSort}
                        showViewingMetrics={showViewingMetrics}
                        showContentDetailPage={showContentDetailPage}
                        showPagination={
                          data?.contentEngagementByPresentation.length > 10
                        }
                      />
                      {showTopClicks && (
                        <>
                          {data?.contentEngagementByClick && !isError && (
                            <TopClicksDataTableCard
                              currentPage={topClicksCurrentPage}
                              data={topClicksData.data}
                              onPageChange={(page) => {
                                setTopClicksCurrentPage(page);
                              }}
                              onSort={(sort) => {
                                setTopClicksTableSort({
                                  ...topClicksTableSort,
                                  ...sort,
                                });
                                setTopClicksCurrentPage(0);
                              }}
                              pageCount={topClicksData.pageCount}
                              sort={topClicksTableSort}
                              showPagination={
                                data?.contentEngagementByClick.length > 10
                              }
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </PageLayout>
          )}
        </>
      )}
    </Page>
  );
};

export default ContentEngagementPage;
