import React from 'react';
import { Grid } from '@material-ui/core';

export type MarketplaceTileType = 'ai' | 'cx' | 'ex' | 'dx';

interface ColorGridProps {
  className?: string;
  type: MarketplaceTileType;
  children?: React.ReactNode;
}

const ColorCard = ({ className, type, children }: ColorGridProps) => {
  const getBackgroundColor = (type: MarketplaceTileType) => {
    switch (type) {
      case 'ai':
        return '#0047d4';
      case 'cx':
        return '#28d972';
      case 'ex':
        return '#003874';
      case 'dx':
        return '#333';
    }
  };

  return (
    <Grid
      item
      container
      justifyContent="flex-start"
      alignItems="center"
      style={{ backgroundColor: getBackgroundColor(type) }}
      className={className}
    >
      {children}
    </Grid>
  );
};

export default ColorCard;
