import Select, { SelectItem } from '@raydiant/raydial/components/Select';
import React, { Key } from 'react';

interface Props {
  pageSize: number;
  onChange: (pageSize: number) => void;
}

const PageSizeSelect: React.FC<Props> = (props) => {
  const { pageSize, onChange } = props;

  const handlePageSizeChange = (pageSize: Key) => {
    onChange(pageSize as number);
  };

  return (
    <>
      <label
        htmlFor="pageSizeSelect"
        className="mr-5 text-base hidden lg:block"
      >
        Rows per page
      </label>
      <Select
        compact
        selectedKey={pageSize}
        id="pageSizeSelect"
        aria-labelledby="pageSizeSelect"
        onSelectionChange={handlePageSizeChange}
      >
        <SelectItem key={10} id={10}>
          10
        </SelectItem>
        <SelectItem key={20} id={20}>
          20
        </SelectItem>
        <SelectItem key={50} id={50}>
          50
        </SelectItem>
      </Select>
    </>
  );
};

export default PageSizeSelect;
