import { Device, RecentDeviceError } from '@raydiant/api-client-js';
import DeviceStatus from './DeviceStatus';
import { ContentWarning } from './getDeviceContentWarning';
import cn from 'classnames';
import { getDeviceConnectionStatus, getDeviceType } from '../../utilities';

interface DeviceInfoProps {
  device: Device;
  contentWarning: ContentWarning | null;
  deviceErrors: RecentDeviceError[];
  lastLoadedDate: string;
  isEditable?: boolean;
  onSettingsClick: () => void;
  onAiSettingsClick: () => void;
  onPerchSettingsClick: () => void;
  openStatusPopover: boolean;
  onOpenStatusPopover: (open: boolean) => void;
}

const DeviceInfo = ({
  device,
  contentWarning,
  deviceErrors,
  isEditable,
  lastLoadedDate,
  onSettingsClick,
  onAiSettingsClick,
  onPerchSettingsClick,
}: DeviceInfoProps) => {
  const deviceType = getDeviceType(device);

  const { isOnline } = getDeviceConnectionStatus(device, lastLoadedDate);

  return (
    <div className={cn('p-2', 'w-full md:py-4 md:pl-7')}>
      <div className="flex flex-row justify-between mb-1 mt-1 relative text-2xl md:flex-col">
        <div
          className={cn(
            'text-sm  text-white h-3 absolute bottom-6',
            isOnline ? 'text-blue-100' : 'text-white',
          )}
        >
          {deviceType}
        </div>
        <span
          className="text-lg font-semibold truncate w-[260px] md:w-[320px]"
          title={device.name}
        >
          {device.name}
        </span>
      </div>
      <DeviceStatus
        device={device}
        contentWarning={contentWarning}
        deviceErrors={deviceErrors}
        isEditable={!!isEditable}
        lastLoadedDate={lastLoadedDate}
        onAiSettingsClick={onAiSettingsClick}
        onPerchSettingsClick={onPerchSettingsClick}
        onSettingsClick={onSettingsClick}
        showAiDevice={!!isEditable}
        showPerchDevice={!!isEditable}
      />
    </div>
  );
};

export default DeviceInfo;
