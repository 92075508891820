import { useEffect, useMemo, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, useRouteMatch } from 'react-router-dom';
import deepEqual from 'fast-deep-equal';
import {
  Presentation,
  PresentationProperty,
  Application,
  Theme,
  ApplicationVersion,
} from '@raydiant/api-client-js';
import ThemeSelector from 'raydiant-elements/core/ThemeSelector';
import Text from 'raydiant-elements/core/Text';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import OneThirdLayout from 'raydiant-elements/layout/OneThirdLayout';
import Center from 'raydiant-elements/layout/Center';
import PresentationPreview from 'raydiant-elements/presentation/PresentationPreview';
import config from '../../config';
import * as paths from '../../routes/paths';
import * as soundZoneActions from '../../actions/soundZones';
import * as playlistActions from '../../actions/playlists';
import { selectApplicationsById } from '../../selectors/v2/applications';
import { selectPresentationsById } from '../../selectors/v2/presentations';
import { selectPlaylistsById } from '../../selectors/v2/playlists';
import { selectThemesById } from '../../selectors/v2/themes';
import {
  selectUserProfile,
  selectIsDeveloper,
  selectIsAppReviewer,
  selectIs4kEnabled,
} from '../../selectors/user';
import { selectSoundZones } from '../../selectors/soundZones';
import { selectLocalUploads } from '../../selectors/fileUploads';
import {
  createPresentationFromAppVersion,
  localizeAppStrings,
  validatePresentation,
  isNotNullOrUndefined,
  collectApplicationVariables,
  createDefaultTheme,
  canEditResource,
  getDefaultThemeForUser,
} from '../../utilities';
import { createNewId } from '../../utilities/identifiers';
import { getVersionText } from '../../utilities/appVersionUtils';
import raydiant from '../../clients/raydiant';
import PresentationLoader, {
  PresentationLoaderHandle,
} from '../../components/PresentationLoader';
import Page from '../../components/Page';
import {
  PresentationFile,
  Path,
  BuilderState,
  ApplicationVersionLocalized,
} from '../../types';
import * as actions from './actions';
import {
  selectLoadingStatus,
  selectUnsavedPresentations,
  selectUnsavedThemes,
} from './selectors';
import { useCallback } from 'react';
import getPageUrl from './getPageUrl';
import PresentationForm from './PresentationForm';
import ThemeForm from './ThemeForm';
import ThemeManager from './ThemeManager';
import PlaylistSelectorModal from './PlaylistSelectorModal';
import ThemeSharingModal from './ThemeSharingModal';
import useQueryParams from './useQueryParams';
import getUnsavedPresentationId from './getUnsavedPresentationId';
import getUnsavedThemeId from './getUnsavedThemeId';
import useFonts from '../../hooks/useFonts';
import usePresentationUploads from '../../hooks/usePresentationUploads';
import useCatalogs from '../../hooks/useCatalogs';
import useApplicationVersionToken from '../../hooks/useApplicationVersionToken';

interface PresentationPageProps
  extends RouteComponentProps<{ presentationId: string; themeId: string }> {}

const PresentationPage = ({ history, match }: PresentationPageProps) => {
  const dispatch = useDispatch();
  const queryParams = useQueryParams();
  const routeParams = match.params;

  const newThemeMatch = useRouteMatch([
    paths.newPresentationNewTheme.pattern,
    paths.newPresentationEditTheme.pattern,
  ]);

  const editThemeMatch = useRouteMatch([
    paths.editPresentationNewTheme.pattern,
    paths.editPresentationEditTheme.pattern,
  ]);

  const manageThemeMatch = useRouteMatch([
    paths.newPresentationManageThemes.pattern,
    paths.editPresentationManageThemes.pattern,
  ]);

  let activeForm: 'presentationBuilder' | 'themeBuilder' | 'themeManager';
  if (newThemeMatch || editThemeMatch) {
    activeForm = 'themeBuilder';
  } else if (manageThemeMatch) {
    activeForm = 'themeManager';
  } else {
    activeForm = 'presentationBuilder';
  }

  // Selectors

  const loadingStatus = useSelector(selectLoadingStatus);
  const unsavedPresentations = useSelector(selectUnsavedPresentations);
  const unsavedThemes = useSelector(selectUnsavedThemes);
  const presentationsById = useSelector(selectPresentationsById);
  const applicationsById = useSelector(selectApplicationsById);
  const themesById = useSelector(selectThemesById);
  const playlistsById = useSelector(selectPlaylistsById);
  const currentUser = useSelector(selectUserProfile);
  const localUploads = useSelector(selectLocalUploads);
  const soundZones = useSelector(selectSoundZones);
  const isDeveloper = useSelector(selectIsDeveloper);
  const isAppReviewer = useSelector(selectIsAppReviewer);
  const is4KBetaEnabled = useSelector(selectIs4kEnabled);

  // State

  const [presentationFiles, setPresentationFiles] = useState<
    PresentationFile[]
  >([]);
  const [selectedPlaylistPath, setSelectedPlaylistPath] = useState<Path>([]);
  const [builderState, setBuilderState] = useState<BuilderState>({
    inputs: [],
    profileId: currentUser && currentUser.id,
  });
  const [presentationProperties, setPresentationProperties] = useState<
    PresentationProperty[] | null
  >(null);
  const [presentationStrings, setPresentationStrings] = useState<Record<
    string,
    string
  > | null>(null);
  const [themeSharingId, setThemeSharingId] = useState<string | null>(null);
  const [versions, setVersions] = useState<ApplicationVersion[]>([]);

  // Refs

  const presentationLoaderRef = useRef<PresentationLoaderHandle | null>(null);
  const previewPresentationRef = useRef<Presentation | null>(null);
  const prevUnfetchedPlaylistIdsRef = useRef<string[]>([]);
  const prevPresentationRef = useRef<Presentation | null>(null);
  const resolveSelectedPlaylistRef = useRef<
    ((playlistId: string) => void) | null
  >(null);

  // Memoizers

  const application = useMemo(() => {
    const pres = presentationsById[routeParams.presentationId];
    let app: Application | undefined;

    if (queryParams.applicationId) {
      app = applicationsById[queryParams.applicationId];
    } else if (pres?.applicationId) {
      app = applicationsById[pres.applicationId];
    }

    return app;
  }, [
    routeParams.presentationId,
    queryParams.applicationId,
    presentationsById,
    applicationsById,
  ]);

  const appVersion = useMemo(() => {
    if (!application) return;

    const originalPresentation = presentationsById[routeParams.presentationId];
    // Default to using the application's current deployment version.
    let version = application.currentAppVersion;
    if (queryParams.version) {
      // Use the version passed in via query params if set.
      const selectedVersion = versions.find(
        (v) => getVersionText(v) === queryParams.version,
      );
      if (selectedVersion) {
        version = selectedVersion;
      }
    } else if (originalPresentation?.appVersionId) {
      // Use the version of the presentation if it's newer than the app's current app version.
      // This can happen if a developer or app reviewer is testing an unapproved version.
      const presentationAppVersion = versions.find(
        (v) => v.id === originalPresentation.appVersionId,
      );

      if (
        presentationAppVersion &&
        presentationAppVersion.createdAt >
          application.currentAppVersion.createdAt
      ) {
        version = presentationAppVersion;
      }
    }

    return {
      ...version,
      // Use the updated presentation properties if provided. Presentation properties can be updated via
      // conditional controls.
      presentationProperties:
        presentationProperties || version.presentationProperties,
    };
  }, [
    queryParams.version,
    routeParams.presentationId,
    application,
    presentationProperties,
    versions,
    presentationsById,
  ]);

  const appVersionLocalized = useMemo(() => {
    if (!appVersion) return;

    return {
      ...appVersion,
      strings: {
        // TODO: Only currentAppVersion.strings needs to be localized, presentationStrings
        // is already localized. We should clean this up.
        ...localizeAppStrings(appVersion.strings),
        ...presentationStrings,
      },
    } as ApplicationVersionLocalized;
  }, [appVersion, presentationStrings]);

  const defaultPresentationTheme = useMemo(() => {
    return getDefaultThemeForUser(currentUser, themesById);
  }, [currentUser, themesById]);

  const presentation = useMemo(() => {
    let pres: Presentation | undefined;

    // Check if editing a new presentation or if creating a new one.
    if (routeParams.presentationId) {
      const originalPresentation =
        presentationsById[routeParams.presentationId];

      const unsavedPresentation =
        unsavedPresentations[
          getUnsavedPresentationId({ id: routeParams.presentationId })
        ];

      if (unsavedPresentation) {
        // Use unsaved presentation for selected presentation.
        pres = { ...unsavedPresentation };
      } else if (originalPresentation && appVersion) {
        // Use the presentation set to the latest app version.
        pres = {
          ...originalPresentation,
          appVersionId: appVersion.id,
        };
      } else if (originalPresentation) {
        pres = { ...originalPresentation };
      }

      // Check if theme is deleted and set to default theme if it is.
      if (pres && pres.themeId && defaultPresentationTheme) {
        const presentationTheme = themesById[pres.themeId];
        if (presentationTheme?.resource.deletedAt !== null) {
          pres.themeId = defaultPresentationTheme.id;
        }
      }
    } else if (appVersion) {
      const unsavedPresentation =
        unsavedPresentations[
          getUnsavedPresentationId({ applicationId: appVersion?.applicationId })
        ];

      if (unsavedPresentation) {
        // Use unsaved presentation for new presentation.
        pres = unsavedPresentation;
      } else if (currentUser) {
        // If the app version uses a theme presentation property then wait for the
        // default themes to load before creating the default app version. This is
        // a fix for an issue where the API doesn't return the default themes if the
        // user does not have the public app scope.
        const appVersionHasTheme = appVersion.presentationProperties.some(
          (p) => p.type === 'theme',
        );

        if (
          !appVersionHasTheme ||
          (appVersionHasTheme && defaultPresentationTheme)
        ) {
          // Use the default presentation for current application version using
          // the user's custom theme if available.
          pres = createPresentationFromAppVersion(
            appVersion,
            currentUser.id,
            defaultPresentationTheme,
          );
        }
      }
    }

    return pres;
  }, [
    routeParams,
    presentationsById,
    themesById,
    appVersion,
    defaultPresentationTheme,
    currentUser,
    unsavedPresentations,
  ]);

  // Set previous presentation ref to the current presentation.
  useEffect(() => {
    prevPresentationRef.current = presentation || null;
  }, [presentation]);

  const theme = useMemo(() => {
    if (activeForm === 'themeBuilder') {
      if (routeParams.themeId) {
        // Get the unsaved theme for the selected theme.
        return (
          unsavedThemes[routeParams.themeId] || themesById[routeParams.themeId]
        );
      }
      // Get the unsaved theme id for a new theme by calling getUnsavedThemeId({}).
      return unsavedThemes[getUnsavedThemeId({})];
    }
    // Get the theme of the presentation.
    let currentTheme = themesById[presentation?.themeId || ''];

    if (
      currentTheme &&
      currentTheme.backgroundImageFileUpload &&
      localUploads
    ) {
      const localBackgroundImageUpload =
        localUploads[currentTheme.backgroundImageFileUpload?.id];

      if (localBackgroundImageUpload) {
        currentTheme = {
          ...currentTheme,
          backgroundImageFileUpload: {
            ...currentTheme.backgroundImageFileUpload,
            url: localBackgroundImageUpload.localUrl,
          },
        };
      }
    }

    return currentTheme;
  }, [
    presentation,
    unsavedThemes,
    themesById,
    activeForm,
    routeParams,
    localUploads,
  ]);

  const presentationUploads = usePresentationUploads(presentation);

  const presentationPlaylistIds = useMemo(() => {
    let playlistIds: string[] = [];
    if (presentation && appVersion) {
      playlistIds = collectApplicationVariables(
        'playlist',
        presentation,
        appVersion,
      )
        .map((c) => c.applicationVariable)
        .filter(isNotNullOrUndefined);
    }
    return playlistIds;
  }, [presentation, appVersion]);

  const unfetchedPlaylistIds = useMemo(() => {
    return presentationPlaylistIds.filter(
      (playlistId) => !playlistsById[playlistId],
    );
  }, [presentationPlaylistIds, playlistsById]);

  const presentationPlaylists = useMemo(() => {
    return presentationPlaylistIds
      .map((playlistId) => playlistsById[playlistId])
      .filter(isNotNullOrUndefined);
  }, [presentationPlaylistIds, playlistsById]);

  const presentationErrors = useMemo(() => {
    if (!presentation) return [];
    if (!appVersion) return [];
    return validatePresentation(
      presentation,
      appVersion,
      config.legacyMinDuration,
    );
  }, [presentation, appVersion]);

  const hasCatalogInput = useMemo(() => {
    return (
      appVersion?.presentationProperties.some((p) => p.type === 'catalog') ||
      false
    );
  }, [appVersion]);

  // Callbacks

  const handlePresentationFormChange = useCallback(
    (updatedPresentation: Presentation, updatedFiles: PresentationFile[]) => {
      dispatch(actions.setUnsavedPresentation(updatedPresentation));
      setPresentationFiles(updatedFiles);
    },
    [dispatch, setPresentationFiles],
  );

  const handlePresentationPropertiesChange = useCallback(
    (properties: PresentationProperty[], strings: Record<string, string>) => {
      setPresentationProperties(properties);
      setPresentationStrings(strings);
    },
    [setPresentationProperties, setPresentationStrings],
  );

  const handlePreviewModeChange = useCallback(
    (previewMode: string) => {
      history.replace(
        getPageUrl({
          presentation,
          theme: activeForm === 'themeBuilder' ? theme : undefined,
          themeManager: activeForm === 'themeManager',
          queryParams: { ...queryParams, previewMode },
        }),
      );
    },
    [presentation, theme, activeForm, history, queryParams],
  );

  const onPreviewResolutionChange = useCallback(
    (previewResolution: string) => {
      history.replace(
        getPageUrl({
          presentation,
          theme: activeForm === 'themeBuilder' ? theme : undefined,
          themeManager: activeForm === 'themeManager',
          queryParams: { ...queryParams, previewResolution },
        }),
      );
    },
    [presentation, theme, activeForm, history, queryParams],
  );

  const editPlaylist = useCallback(
    (playlistId: string, path: Path) => {
      if (!presentation) return;

      history.push(
        paths.editPlaylist(playlistId, {
          folderId: queryParams.folderId,
          previewMode: queryParams.previewMode,
          backTo: getPageUrl({ presentation, queryParams }),
          saveTo: getPageUrl({
            presentation,
            queryParams,
            playlistIdPath: path,
          }),
          backToLabel: `Back to ${presentation?.name}`,
          sessionId: queryParams.sessionId,
        }),
      );
    },
    [presentation, history, queryParams],
  );

  const newPlaylist = useCallback(() => {
    if (!presentation) return;

    history.push(
      paths.newPlaylist({
        playlistId: createNewId(),
        folderId: queryParams.folderId,
        previewMode: queryParams.previewMode,
        backTo: getPageUrl({ presentation, queryParams }),
        backToLabel: `Back to ${presentation?.name}`,
        saveTo: getPageUrl({
          presentation,
          queryParams,
          playlistIdPath: selectedPlaylistPath,
        }),
        sessionId: queryParams.sessionId,
      }),
    );
  }, [presentation, history, queryParams, selectedPlaylistPath]);

  const openPlaylistSelector = useCallback(async (path: Path) => {
    // Resolve previously selected playlist input before
    // opening the selector for another playlist input
    if (resolveSelectedPlaylistRef.current) {
      resolveSelectedPlaylistRef.current('');
    }

    setSelectedPlaylistPath(path);

    return new Promise<string>((resolve) => {
      resolveSelectedPlaylistRef.current = resolve;
    });
  }, []);

  const closePlaylistSelector = useCallback(() => {
    setSelectedPlaylistPath([]);
  }, [setSelectedPlaylistPath]);

  const editTheme = useCallback(() => {
    if (!presentation) return;
    if (!theme) return;

    dispatch(actions.clearUnsavedTheme(getUnsavedThemeId(theme)));

    const backTo = getPageUrl({ presentation, queryParams });
    const saveTo = getPageUrl({ presentation, queryParams });

    history.push(
      getPageUrl({
        presentation,
        theme,
        queryParams: { ...queryParams, backTo, saveTo },
      }),
    );
  }, [presentation, theme, history, queryParams, dispatch]);

  const manageThemes = useCallback(() => {
    if (!presentation) return;

    const backTo = getPageUrl({ presentation, queryParams });
    const saveTo = getPageUrl({ presentation, queryParams });

    history.push(
      getPageUrl({
        presentation,
        themeManager: true,
        queryParams: { ...queryParams, backTo, saveTo },
      }),
    );
  }, [presentation, history, queryParams]);

  const newTheme = useCallback(() => {
    if (!presentation) return;

    dispatch(
      actions.setUnsavedTheme(
        createDefaultTheme({
          name: 'New Theme',
          presentationId: presentation.id,
        }),
      ),
    );

    const backTo = getPageUrl({ presentation, queryParams });
    const saveTo = getPageUrl({ presentation, queryParams });

    history.push(
      getPageUrl({
        presentation,
        theme: null,
        queryParams: { ...queryParams, backTo, saveTo },
      }),
    );
  }, [presentation, history, queryParams, dispatch]);

  const handleThemeFormChange = useCallback(
    (updatedTheme: Theme) => {
      dispatch(actions.setUnsavedTheme(updatedTheme));
    },
    [dispatch],
  );

  const closeThemeSharing = useCallback(() => {
    setThemeSharingId(null);
  }, []);

  const selectVersion = useCallback(
    (versionId) => {
      const version = versions.find((v) => v.id === versionId);

      if (version) {
        history.push(
          getPageUrl({
            presentation,
            queryParams: {
              ...queryParams,
              version: getVersionText(version),
            },
          }),
        );
      }
    },
    [presentation, versions, history, queryParams],
  );

  const createObjectURL = async (blob: Blob) => {
    if (presentationLoaderRef.current) {
      return presentationLoaderRef.current.createObjectURL(blob);
    }
    return '';
  };

  // Side-effects

  // Update builder state when current user changes
  useEffect(() => {
    setBuilderState((builderState) => ({
      ...builderState,
      profileId: currentUser && currentUser.id,
    }));
  }, [currentUser]);

  // Load presentation page.
  useEffect(() => {
    dispatch(
      actions.loadPresentationPage({
        presentationId: routeParams.presentationId,
      }),
    );
  }, [routeParams.presentationId, queryParams, dispatch]);

  useEffect(() => {
    const didPresentationPlaylistsChange = !deepEqual(
      unfetchedPlaylistIds,
      prevUnfetchedPlaylistIdsRef.current,
    );
    if (didPresentationPlaylistsChange && unfetchedPlaylistIds.length > 0) {
      dispatch(playlistActions.fetchPlaylists(unfetchedPlaylistIds));
    }
    prevUnfetchedPlaylistIdsRef.current = unfetchedPlaylistIds;
  }, [unfetchedPlaylistIds, dispatch]);

  // Load sound zones on first presentation load if app has soundZone property.
  useEffect(() => {
    if (!presentation) return;

    const didPresentationLoad = presentation && !prevPresentationRef.current;
    if (!didPresentationLoad) return;

    // TODO: Remove when API has an endpoint to retrieve all soundzones. Fetching
    // sound zones currently needs to fetch all devices in the domain and then
    // for each device fetches the sound zone (if one exists).
    const hasSoundZoneProp = presentation.presentationProperties.some(
      (appVar) => appVar.type === 'soundZone',
    );
    if (!hasSoundZoneProp) return;
    dispatch(soundZoneActions.fetchAllSoundZones());
  }, [presentation, dispatch]);

  // Fetch versions on mount if the user owns the application and has the developer role,
  // or is an app reviewer.
  const userOwnsApp =
    application &&
    currentUser &&
    application.resource.profile.id === currentUser.id;
  const shouldFetchVersions = (isDeveloper && userOwnsApp) || isAppReviewer;
  const didFetchVersions = useRef(false);

  useEffect(() => {
    const fetchVersions = async (applicationId: string) => {
      setVersions(await raydiant.getApplicationVersions(applicationId));
    };

    if (!didFetchVersions.current && shouldFetchVersions && application) {
      didFetchVersions.current = true;
      fetchVersions(application.id);
    }
  }, [application, shouldFetchVersions]);

  // Render

  // Update the presentation preview if there are no presentation errors.
  if (presentationErrors.length === 0) {
    previewPresentationRef.current = presentation || null;
  }
  const isLoadingTheme = presentation?.themeId && !theme;
  const isLoadingPlaylists = unfetchedPlaylistIds.length > 0;
  const isLoadingVersions = shouldFetchVersions && versions.length === 0;
  const formErrors = queryParams.didSave ? presentationErrors : [];

  const { data: fonts, isLoading: isLoadingFonts } = useFonts();

  const { data: catalogs } = useCatalogs({ enabled: hasCatalogInput });
  const isLoadingCatalogs = hasCatalogInput && !catalogs;

  // Inject the application version token into the presentation. This is used by
  // the SelectionInput's remote option feature.
  const { data: token } = useApplicationVersionToken(appVersion?.id);
  if (presentation) {
    presentation.token = token;
  }

  const isLoading =
    !presentation ||
    !appVersion ||
    isLoadingPlaylists ||
    isLoadingTheme ||
    isLoadingVersions ||
    isLoadingFonts ||
    isLoadingCatalogs;

  const canEditTheme =
    theme && currentUser ? canEditResource(currentUser, theme.resource) : false;

  // Show the version selector for new presentations if the logged in user is the developer
  // who created the app or if they are an app reviewer.
  const isNewPresentation = !presentation?.id;
  const isAllowedToSelectAppVersion = isNewPresentation && shouldFetchVersions;

  const preview = (
    <ThemeSelector color="dark">
      <PresentationPreview
        appVersion={
          appVersionLocalized && {
            ...appVersionLocalized,
            embeddedUrlFormat:
              appVersionLocalized.embeddedUrlFormat ?? undefined,
          }
        }
        previewMode={queryParams.previewMode}
        previewResolution={queryParams.previewResolution}
        onPreviewModeChange={handlePreviewModeChange}
        enableResolutionSelection={true}
        is4kEnabled={is4KBetaEnabled}
        onPreviewResolutionChange={onPreviewResolutionChange}
        showBorder
      >
        {previewPresentationRef.current && appVersion && !isLoadingTheme && (
          <PresentationLoader
            ref={presentationLoaderRef}
            presentation={previewPresentationRef.current}
            presentationUploads={presentationUploads}
            appVersion={appVersion}
            theme={theme}
            builderState={builderState}
            onPresentationProperties={handlePresentationPropertiesChange}
          />
        )}
      </PresentationPreview>
    </ThemeSelector>
  );

  return (
    <Page title={presentation?.name} backTo={queryParams.backTo}>
      <OneThirdLayout className="h-full">
        <ThemeSelector color="grey">
          <OneThirdLayout.ColumnSmall>
            {isLoading && (
              <Center>
                <CircularProgress size={30} />
              </Center>
            )}

            {!isLoading && loadingStatus === 'error' && (
              <Center>
                <Text muted>Oops! Something went wrong.</Text>
              </Center>
            )}

            {!isLoading && activeForm === 'presentationBuilder' && (
              <PresentationForm
                preview={preview}
                presentation={presentation}
                appVersion={appVersion}
                appVersionLocalized={appVersionLocalized}
                errors={formErrors}
                soundZones={soundZones}
                playlists={presentationPlaylists}
                catalogs={catalogs?.data}
                builderState={builderState}
                presentationUploads={presentationUploads}
                presentationErrors={presentationErrors}
                presentationFiles={presentationFiles}
                selectedPlaylistPath={selectedPlaylistPath}
                enableVersionSelector={isAllowedToSelectAppVersion}
                versions={versions}
                application={application}
                onChange={handlePresentationFormChange}
                onBuilderStateChange={setBuilderState}
                onPlaylistEdit={editPlaylist}
                onPlaylistCreate={newPlaylist}
                onPlaylistSelect={openPlaylistSelector}
                onThemeEdit={canEditTheme ? editTheme : undefined}
                onThemeManage={manageThemes}
                onThemeAdd={newTheme}
                onVersionSelect={selectVersion}
                createObjectURL={createObjectURL}
              />
            )}

            {!isLoading && activeForm === 'themeBuilder' && (
              <ThemeForm
                fonts={fonts}
                preview={preview}
                theme={theme}
                presentation={presentation}
                onChange={handleThemeFormChange}
              />
            )}

            {!isLoading && activeForm === 'themeManager' && (
              <ThemeManager
                presentation={presentation}
                onThemeShare={setThemeSharingId}
              />
            )}
          </OneThirdLayout.ColumnSmall>
        </ThemeSelector>
        <OneThirdLayout.ColumnLarge>{preview}</OneThirdLayout.ColumnLarge>
      </OneThirdLayout>

      <PlaylistSelectorModal
        presentation={presentation}
        selectedPlaylistPath={selectedPlaylistPath}
        onClose={closePlaylistSelector}
        onSelect={resolveSelectedPlaylistRef.current || (() => {})}
      />

      <ThemeSharingModal
        theme={themeSharingId ? themesById[themeSharingId] : undefined}
        onClose={closeThemeSharing}
      />
    </Page>
  );
};

export default PresentationPage;
