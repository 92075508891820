import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import { textTruncate, buttonReset } from 'raydiant-elements/mixins';

export default makeStyles((theme: Theme) => {
  return createStyles({
    title: {
      marginBottom: theme.spacing(0.5),
    },

    type: {
      margin: 0,
    },

    cellWithIcon: {
      display: 'flex',
      justifyContent: 'space-between',
    },

    section: {
      marginBottom: theme.spacing(5),

      '&:last-child': {
        marginBottom: 0,
      },
    },

    sybLink: {
      fontWeight: 400,
      textTransform: 'none',
      letterSpacing: 0,
    },

    section1: {
      display: 'flex',
      alignItems: 'flex-end',
      '& :first-child': {
        flex: 7,
      },
      '& :nth-child(2)': {
        flex: 5,
      },
    },

    playlistRow: {
      alignItems: 'center',
      marginTop: theme.spacing(2),
    },

    playlistName: {
      ...textTruncate(),
      flex: 1,
      fontSize: theme.fontSizes.md,
      letterSpacing: 0.15,
    },

    remove: {
      ...buttonReset(),
      display: 'flex',
      color: theme.palette.text.secondary,
      opacity: 0.6,

      '&:hover': {
        opacity: 1,
      },

      '&:disabled': {
        opacity: 0,
        cursor: 'default',
      },
    },
  });
});
