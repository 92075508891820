import { DayTraffic } from '@raydiant/api-client-js';
import { abbreviateNumber } from 'js-abbreviation-number';
import moment from 'moment';

type TrafficOverviewKeys = 'ots' | 'avgDwellTime' | 'impressions';

export const getTotalTraffic = (data: DayTraffic[]) => {
  if (data.length === 0) return 0;

  return data
    .map((value) => value.ots)
    .reduce((current, next) => {
      return current + next;
    });
};

export const getTotalDwellTime = (data: DayTraffic[]) => {
  if (data.length === 0) return 0;

  return data
    .map((value) => value.dwellTime)
    .reduce((current, next) => {
      return current + next;
    });
};

export const getTotalImpressions = (data: DayTraffic[]) => {
  if (data.length === 0) return 0;

  return data
    .map((value) => value.impressions)
    .reduce((current, next) => {
      return current + next;
    });
};

export const getTrafficOverviewTotalValue = (
  data: DayTraffic[],
  type: TrafficOverviewKeys,
) => {
  switch (type) {
    case 'ots':
      return getTotalTraffic(data);
    case 'avgDwellTime':
      return getTotalDwellTime(data);
    case 'impressions':
      return getTotalImpressions(data);
    default:
      return 0;
  }
};

export const toPercent = (value: number | string) =>
  Math.round(Number(value) * 100);

export const getPercentChange = (current: number, previous: number) => {
  if (current === 0) return '0%';

  return toPercent((current - previous) / current).toString() + '%';
};

export const getDailyAverage = (
  data: DayTraffic[],
  type: TrafficOverviewKeys,
) => {
  const count = data.length;

  if (count === 0) return 0;

  return getAverage(getTrafficOverviewTotalValue(data, type), count);
};

export const getDisplayValue = (value: number, unit: string) => {
  let result = '';
  if (value <= 100000) {
    result = value.toLocaleString();
  } else {
    result = `${abbreviateNumber(value, 0).toUpperCase()}${
      value > 100000 && hasRemainder(value) ? '+' : ''
    }`;
  }

  return unit ? `${result}${unit}` : result;
};

const hasRemainder = (value: number) => (value / 1000) % 1 !== 0;

export const groupByWeek = (data: DayTraffic[]) => {
  return data.reduce<{
    [key: string]: DayTraffic[];
  }>((acc, data) => {
    const yearWeek = moment(data.timestamp).week();

    if (!acc[yearWeek]) {
      acc[yearWeek] = [];
    }

    acc[yearWeek].push(data);

    return acc;
  }, {});
};

const getAverage = (total: number, count: number) => Math.round(total / count);

export const getWeeklyAverage = (
  data: DayTraffic[],
  type: TrafficOverviewKeys,
) => {
  if (data.length === 0) return 0;

  const weeks = groupByWeek(data);
  const weeksCount = Object.keys(weeks).length;
  if (weeksCount === 0) return 0;

  const total = Object.keys(weeks)
    .map((key) => {
      const count = weeks[key].length;
      if (count === 0) return 0;

      return getAverage(getTrafficOverviewTotalValue(weeks[key], type), count);
    })
    .reduce((current, next) => current + next);

  return getAverage(total, weeksCount);
};
