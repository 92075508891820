import { useState } from 'react';
import Popover from '../Popover';
import Row from 'raydiant-elements/layout/Row';
import Button from 'raydiant-elements/core/Button';
import Column from 'raydiant-elements/layout/Column';
import { makeStyles } from 'raydiant-elements/styles';
import { RuleConstant } from '@raydiant/playlist-rule-engine';
import RuleConstantInput from '../RuleConstantInput/RuleConstantInput';
import { RuleSourceID } from './ruleTokenInputData';
import {
  getRuleConstantInputLabel,
  getRuleConstantInputType,
} from './ruleConstantInputUtils';

interface RuleConstantPopOverProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (value: RuleConstant['const'] | null) => void;
  defaultValue: RuleConstant['const'] | null;
  ruleSource: RuleSourceID | null;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
  },
}));

const RuleConstantPopOver = ({
  isOpen,
  onClose,
  onConfirm,
  defaultValue,
  ruleSource,
}: RuleConstantPopOverProps) => {
  const classes = useStyles();

  const [customValue, setCustomValue] = useState(defaultValue);
  const ruleConstantInputType = getRuleConstantInputType(ruleSource);
  const isDirty = defaultValue !== customValue;
  const ruleConstantIsPercent = ruleConstantInputType === 'percent';
  const ruleConstantIsNumber = ruleConstantInputType === 'number';
  const isPercentValueOver100 =
    ruleConstantIsPercent && customValue
      ? customValue > 100
      : false || !customValue;
  const isPercentValueBelow100 =
    ruleConstantIsPercent && customValue
      ? customValue < 0
      : false || !customValue;
  const isNumberValueNegative =
    ruleConstantIsNumber && customValue
      ? customValue < 0
      : false || !customValue;
  const isButtonDisabled =
    !isDirty ||
    isPercentValueOver100 ||
    isNumberValueNegative ||
    isPercentValueBelow100;

  return (
    <Popover
      className={classes.wrapper}
      open={isOpen}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      variant="alert"
    >
      <Column>
        <RuleConstantInput
          label={ruleSource ? getRuleConstantInputLabel(ruleSource) : ''}
          type={ruleConstantInputType}
          onChange={setCustomValue}
          value={customValue}
        />
        <Row>
          <Button fullWidth label="Dismiss" onClick={onClose} />
          <Button
            label={'Done'}
            fullWidth
            disabled={isButtonDisabled}
            color={'primary'}
            onClick={() => onConfirm(customValue ?? null)}
          />
        </Row>
      </Column>
    </Popover>
  );
};

export default RuleConstantPopOver;
