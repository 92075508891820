import { useState, useEffect } from 'react';
import Search from '../../Icons/Search';

interface SearchBarProps {
  onClick: (value: string) => void;
}

const SearchBar = ({ onClick }: SearchBarProps) => {
  const [search, setSearch] = useState('');

  useEffect(() => {
    setSearch('');
  }, []);

  return (
    <form className="flex">
      <div className="flex-1">
        <input
          className={
            'h-12 w-full rounded-l-2xl pl-4 focus:outline-none px-4 py-2 bg-gray-100 text-lg font-regular'
          }
          placeholder="Search Locations"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <button
        type="submit"
        className="bg-acctconsole w-12 flex justify-center items-center rounded-r-2xl"
        onClick={(e) => {
          e.preventDefault();
          onClick(search);
        }}
      >
        <Search className="text-white" />
      </button>
    </form>
  );
};

export default SearchBar;
