import Button from 'raydiant-elements/core/Button';
import cn from 'classnames';
import Text from 'raydiant-elements/core/Text';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import Heading from 'raydiant-elements/core/Heading';
import { FC } from 'react';

import Center from 'raydiant-elements/layout/Center';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import { MarketPlaceTile as MarketPlaceTileType } from '../../hooks/useMarketPlaceTiles';
interface MarketplaceTileProps {
  data: MarketPlaceTileType;
  isLoading: boolean;
  onClick: (app: string) => void;
  isModal?: boolean;
  isDisabled?: boolean;
}

interface StyleProps {
  backgroundImage: string;
  buttonColor:
    | 'light'
    | 'default'
    | 'progress'
    | 'primary'
    | 'destructive'
    | undefined;
}

const MarketplaceTile: FC<MarketplaceTileProps> = ({
  data,
  isLoading,
  onClick,
  isModal,
  isDisabled,
}) => {
  const classes = useStyles(data);

  if (isLoading) {
    return (
      <Center>
        <CircularProgress size={30} />
      </Center>
    );
  }

  return (
    <>
      {data.type !== 'tiktok' && (
        <div className={classes.wrapper}>
          <div>
            <Heading size={1} weight={600} className={classes.heading}>
              {data.heading}
            </Heading>
            <div className={classes.textWrapper}>
              <Text className={classes.text} xlarge>
                {data.body}
              </Text>
            </div>
            <Button
              label={isModal ? 'Reach out to Sales' : 'Learn More'}
              color={data.buttonColor}
              onClick={() => onClick(data.type)}
              className={cn(classes.button, data.type === 'ai' && classes.ai)}
              disabled={isModal && isDisabled}
            />
          </div>
        </div>
      )}
      {data.type === 'tiktok' && (
        <>
          {isModal && (
            <div className="h-full pt-9">
              <video
                className="w-full h-full"
                src="https://assets.raydiant.com/marketplace/tiktok-tile-video.mp4"
                preload="auto"
                autoPlay
                loop
              />
            </div>
          )}
          {!isModal && (
            <div className={classes.wrapper}>
              <div className="absolute w-full h-full top-0 left-0 bg-black-50" />
              <div className="z-10">
                <Heading size={1} weight={600} className={classes.heading}>
                  {data.heading}
                </Heading>
                <div className={classes.textWrapper}>
                  <Text className={classes.text} xlarge>
                    {data.body}
                  </Text>
                </div>

                <Button
                  label="Learn More"
                  color={data.buttonColor}
                  onClick={() => onClick(data.type)}
                  className={classes.button}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => {
  return createStyles({
    wrapper: {
      position: 'relative',
      height: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      flex: 1,
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(9),
      backgroundImage: ({ backgroundImage }) => `url(${backgroundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      '&>:nth-child(1)': {
        justifyContent: 'flex-start',
      },

      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
    button: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    heading: {
      color: '#ffffff',
      whiteSpace: 'pre',
    },
    textWrapper: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      color: '#ffffff',

      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
    text: {
      whiteSpace: 'pre',
    },
    ai: {
      backgroundColor: '#0047d4',
      '&:hover': {
        backgroundColor: '#0047d4',
      },
    },
  });
});

export default MarketplaceTile;
