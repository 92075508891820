import { FC, useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ActionBar from 'raydiant-elements/core/ActionBar/v2';
import Row from 'raydiant-elements/layout/Row';
import Spacer from 'raydiant-elements/layout/Spacer';
import Column from 'raydiant-elements/layout/Column';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {
  RuleSourceID,
  RuleOperatorID,
  RuleGroupID,
  AI_WORKER_RULE_SOURCE_CONSTANT,
} from './ruleTokenInputData';
import {
  RuleToken,
  RuleError,
  RuleTokenRule,
  RuleTokenAnd,
  RuleTokenOr,
  RuleTokenOpenBracket,
  RuleTokenCloseBracket,
  updateTokenAtIndex,
  insertTokenAtIndex,
  removeTokenAtIndex,
  initialRuleToken,
} from './ruleTokens';
import RuleSourceSelect from './RuleSourceSelect';
import RuleOperatorSelect from './RuleOperatorSelect';
import RuleGroupSelect from './RuleGroupSelect';
import RuleTokenSelect from './RuleTokenSelect';
import AddRuleButton from './AddRuleButton';
import OpenBracketIcon from './OpenBracketIcon';
import CloseBracketIcon from './CloseBracketIcon';
import RuleTokenHelperText from './RuleTokenHelperText';
import useStyles from './RuleSelect.styles';
import RuleConstantPopOver from './RuleConstantPopOver';
import { RuleConstant } from '@raydiant/api-client-js/build/types/Rule';
export interface RuleInputProps {
  value: RuleToken[];
  errors: RuleError[];
  onChange: (value: RuleToken[]) => void;
}

const RuleInput: FC<RuleInputProps> = ({ value, errors, onChange }) => {
  const classes = useStyles();
  // State
  const [source1, setSource1] = useState<RuleSourceID | null>(null);
  const [operator, setOperator] = useState<RuleOperatorID | null>(null);
  const [source2, setSource2] = useState<RuleSourceID | null>(null);
  const [andOr, setAndOr] = useState<RuleGroupID | null>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [isConstantValueModalOpen, setIsConstantValueModalOpen] =
    useState(false);

  const selectedToken: RuleToken | undefined = value[selectedIndex];

  const isAndOrSelected =
    selectedToken?.type === 'and' || selectedToken?.type === 'or';
  const isRuleSelected = selectedToken?.type === 'rule';
  const isOpenBracketSelected = selectedToken?.type === 'open';
  const isCloseBracketSelected = selectedToken?.type === 'close';
  const isBracketSelected = isOpenBracketSelected || isCloseBracketSelected;

  const isSelectedRuleIncomplete =
    selectedToken?.type === 'rule' && (!source1 || !operator || !source2);

  const isSource1Disabled = isAndOrSelected || isBracketSelected;
  const isOperatorDisabled = isAndOrSelected || isBracketSelected || !source1;
  const isSource2Disabled = isAndOrSelected || isBracketSelected || !operator;
  const isAndOrDisabled = isOpenBracketSelected || isSelectedRuleIncomplete;
  const isAddDisabled = isRuleSelected;

  const areBracketsDisabled = andOr === 'and' || andOr === 'or';

  // Callbacks

  const handleSource1Change = (updatedSource1: RuleSourceID | null) => {
    setSource1(updatedSource1);
    // Update source1 at selected index.
    const updatedToken = { ...selectedToken };
    if (updatedToken.type === 'rule' && updatedSource1) {
      updatedToken.source1 = updatedSource1;
      onChange(updateTokenAtIndex(value, updatedToken, selectedIndex));
    }
  };

  const handleOperatorChange = (updatedOperator: RuleOperatorID | null) => {
    setOperator(updatedOperator);
    // Update opertor at selected index.
    const updatedToken = { ...selectedToken };
    if (updatedToken.type === 'rule' && updatedOperator) {
      updatedToken.operator = updatedOperator;
      onChange(updateTokenAtIndex(value, updatedToken, selectedIndex));
    }
  };

  const handleSource2Change = (
    updatedSource2: RuleSourceID | null,
    constantValue?: RuleConstant['const'] | null,
  ) => {
    setSource2(updatedSource2);
    // Update source2 at selected index.
    const updatedToken = { ...selectedToken };
    if (updatedToken.type === 'rule' && updatedSource2) {
      updatedToken.source2 = updatedSource2;
      if (constantValue) updatedToken.source2ConstantValue = constantValue;
      onChange(updateTokenAtIndex(value, updatedToken, selectedIndex));
    }
  };

  const handleAndOrChange = (updatedAndOr: RuleGroupID | null) => {
    setAndOr(updatedAndOr);

    if (isAndOrSelected) {
      // If an 'and' or 'or' is selected, update it.
      const updatedToken = { ...selectedToken };
      if (
        (updatedToken.type === 'and' || updatedToken.type === 'or') &&
        updatedAndOr
      ) {
        updatedToken.type = updatedAndOr;
        onChange(updateTokenAtIndex(value, updatedToken, selectedIndex));
      }
    } else if (updatedAndOr) {
      // Otherwise add a new and/or after the selected index.
      const newToken: RuleTokenAnd | RuleTokenOr = { type: updatedAndOr };
      const newSelectedIndex = selectedIndex + 1;
      onChange(insertTokenAtIndex(value, newToken, newSelectedIndex));
      setSelectedIndex(newSelectedIndex);
    }
  };

  const handleAddRule = () => {
    const newToken: RuleTokenRule = { type: 'rule' };
    const newSelectedIndex = selectedIndex + 1;
    onChange(insertTokenAtIndex(value, newToken, newSelectedIndex));
    setSelectedIndex(newSelectedIndex);
  };

  const handleAddOpenBracket = () => {
    const newToken: RuleTokenOpenBracket = { type: 'open' };
    const newSelectedIndex = selectedIndex;
    onChange(insertTokenAtIndex(value, newToken, newSelectedIndex));
    setSelectedIndex(newSelectedIndex);
  };

  const handleAddCloseBracket = () => {
    const newToken: RuleTokenCloseBracket = { type: 'close' };
    const newSelectedIndex = selectedIndex + 1;
    onChange(insertTokenAtIndex(value, newToken, newSelectedIndex));
    setSelectedIndex(newSelectedIndex);
  };

  const handleDelete = () => {
    onChange(removeTokenAtIndex(value, selectedIndex));
    setSelectedIndex(Math.max(selectedIndex - 1, 0));
  };

  const handleSetConstantValue = (value: RuleConstant['const'] | null) => {
    handleSource2Change(AI_WORKER_RULE_SOURCE_CONSTANT, value);
    setIsConstantValueModalOpen(false);
  };

  // Effects

  // Set default empty rule if value is set not.
  useEffect(() => {
    if (value.length === 0) {
      onChange([initialRuleToken]);
    }
  }, [value, onChange]);

  // Set select defaults when selectedToken changes.
  useEffect(() => {
    if (selectedToken?.type === 'rule') {
      setSource1(selectedToken.source1 ?? null);
      setOperator(selectedToken.operator ?? null);
      setSource2(selectedToken.source2 ?? null);
      setAndOr(null);
    } else if (selectedToken?.type === 'or' || selectedToken?.type === 'and') {
      setSource1(null);
      setOperator(null);
      setSource2(null);
      setAndOr(selectedToken.type);
    } else {
      setSource1(null);
      setOperator(null);
      setSource2(null);
      setAndOr(null);
    }
  }, [selectedToken]);

  // Render

  return (
    <>
      <Column doubleMargin>
        <Row>
          <div className={classes.selectWrapper}>
            <RuleSourceSelect
              disabled={isSource1Disabled}
              label="Source 1"
              value={source1}
              onChange={handleSource1Change}
              isSource1={true}
            />
          </div>
          <div className={classes.ruleOperatorSelectWrapper}>
            <RuleOperatorSelect
              disabled={isOperatorDisabled}
              label="Compare"
              value={operator}
              onChange={handleOperatorChange}
              ruleSource={source1}
            />
          </div>
          <div className={classes.selectWrapper}>
            <RuleSourceSelect
              disabled={isSource2Disabled}
              isSource2={true}
              label="Source 2"
              onChange={handleSource2Change}
              onHandleConstantValue={() => setIsConstantValueModalOpen(true)}
              ruleSource={source1}
              value={source2}
            />
          </div>
          <div className={classes.selectWrapper}>
            <RuleGroupSelect
              disabled={isAndOrDisabled}
              label="+ And Or"
              value={andOr}
              onChange={handleAndOrChange}
            />
          </div>
          <div>
            <AddRuleButton disabled={isAddDisabled} onClick={handleAddRule} />
          </div>
        </Row>
        <div>
          <RuleTokenSelect
            tokens={value}
            errors={errors}
            selectedIndex={selectedIndex}
            onSelect={setSelectedIndex}
          />
          <RuleTokenHelperText
            tokens={value}
            errors={errors}
            selectedIndex={selectedIndex}
          />
        </div>
        <ActionBar size="medium">
          <Spacer />
          <IconButton
            disabled={areBracketsDisabled}
            onClick={handleAddOpenBracket}
          >
            <OpenBracketIcon />
          </IconButton>
          <IconButton
            disabled={areBracketsDisabled}
            onClick={handleAddCloseBracket}
          >
            <CloseBracketIcon />
          </IconButton>
          <IconButton onClick={handleDelete}>
            <DeleteOutlineIcon />
          </IconButton>
          <Spacer />
        </ActionBar>
      </Column>
      <RuleConstantPopOver
        isOpen={isConstantValueModalOpen}
        onClose={() => setIsConstantValueModalOpen(false)}
        onConfirm={handleSetConstantValue}
        defaultValue={
          selectedToken && 'source2ConstantValue' in selectedToken
            ? selectedToken.source2ConstantValue ?? null
            : null
        }
        ruleSource={source1}
      />
    </>
  );
};

export default RuleInput;
