import { ActionType, getType } from 'typesafe-actions';
import * as devicesPageActions from './actions';
import { SortDeviceOptions, SortProfileOptions } from '../../utilities';
import { BatchDevice } from './deviceBatchSettings.interface';

export type DevicesPageActions = ActionType<typeof devicesPageActions>;

export type DevicesPageState = Readonly<{
  lastLoadedDate: string;
  isLoading: boolean;
  sortOptions: SortDeviceOptions;
  searchQuery: string;
  sharingSortOptions: SortProfileOptions;
  sharingSearchQuery: string;
  error: string;
  statusPopoverDeviceId: string | null;
  isBatchMode: boolean;
  isMoveScreenMode: boolean;
  batchDeviceSettings: BatchDevice;
  selectedDeviceIds: string[];
  query?: string;
}>;

export const initialBatchDeviceSettings: BatchDevice = {
  playlistId: null,
  playlist: null,
  screenDimensions: '',
  screenOrientation: '',
  timezone: '',
  activeHoursStart: null,
  activeHoursEnd: null,
  dailyRebootTime: null,
  dailyRestartTime: null,
  itemsToShare: [],
  itemsToUnShare: [],
  autoPublish: true,
  removeKeyTags: [],
  removeValueTags: [],
  overwriteTags: [],
  addTags: [],
};

const initialDevicesPageState: DevicesPageState = {
  lastLoadedDate: '',
  isLoading: false,
  sortOptions: { property: 'name', direction: 'asc' },
  searchQuery: '',
  sharingSortOptions: { property: 'name', direction: 'asc' },
  sharingSearchQuery: '',
  error: '',
  statusPopoverDeviceId: null,
  isBatchMode: false,
  isMoveScreenMode: false,
  batchDeviceSettings: initialBatchDeviceSettings,
  selectedDeviceIds: [],
};

export default function devicesPageReducer(
  state = initialDevicesPageState,
  action: DevicesPageActions,
): DevicesPageState {
  switch (action.type) {
    case getType(devicesPageActions.loadDevicesPageAsync.request):
      return {
        ...state,
        isLoading: true,
        error: '',
      };

    case getType(devicesPageActions.loadDevicesPageAsync.success):
      return {
        ...state,
        isLoading: false,
        lastLoadedDate: new Date().toISOString(),
      };

    case getType(devicesPageActions.loadDevicesPageAsync.failure):
      return {
        ...state,
        isLoading: false,
        error: action.payload.message,
      };

    case getType(devicesPageActions.setSortOptions):
      return {
        ...state,
        sortOptions: action.payload,
      };

    case getType(devicesPageActions.setSearchQuery):
      return {
        ...state,
        searchQuery: action.payload,
      };

    case getType(devicesPageActions.setSharingSortOptions):
      return {
        ...state,
        sharingSortOptions: action.payload,
      };

    case getType(devicesPageActions.setSharingSearchQuery):
      return {
        ...state,
        sharingSearchQuery: action.payload,
      };

    case getType(devicesPageActions.setOpenContentWarningOnLoad):
      return {
        ...state,
        statusPopoverDeviceId: action.payload,
      };

    case getType(devicesPageActions.clearOpenStatusPopoverOnLoad):
      return {
        ...state,
        statusPopoverDeviceId: null,
      };

    case getType(devicesPageActions.toggleBatchMode):
      if (state.isBatchMode) {
        return {
          ...state,
          isBatchMode: !state.isBatchMode,
          isMoveScreenMode: false,
          batchDeviceSettings: initialBatchDeviceSettings,
        };
      }
      return {
        ...state,
        isBatchMode: !state.isBatchMode,
        isMoveScreenMode: false,
      };

    case getType(devicesPageActions.toggleMoveScreenMode):
      return {
        ...state,
        isMoveScreenMode: !state.isMoveScreenMode,
        isBatchMode: false,
      };

    case getType(devicesPageActions.updateBatchDeviceSettings):
      return {
        ...state,
        batchDeviceSettings: {
          ...state.batchDeviceSettings,
          ...action.payload,
        },
      };

    case getType(devicesPageActions.updateSelectedDeviceIds):
      return {
        ...state,
        selectedDeviceIds: action.payload,
      };

    case getType(devicesPageActions.loadDevicesPage):
      return {
        ...state,
        isLoading: action.payload?.query ? true : false,
        error: '',
      };

    default:
      return state;
  }
}
