import cn from 'classnames';
import PaperModal from 'raydiant-elements/core/PaperModal';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import Center from 'raydiant-elements/layout/Center';
import { FC, useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import Page from '../../components/Page';
import LibraryDragLayer from '../../components/LibraryDragLayer';
import {
  selectIsEnterpriseUser,
  selectIsVIOnly,
  selectUserProfile,
} from '../../selectors/user';
import { BatchDevice, selectDevicesById } from '../../selectors/v2/devices';
import * as deviceActions from '../../actions/devices';
import { createNewId } from '../../utilities/identifiers';
import * as paths from '../../routes/paths';
import * as D from '../../clients/mira/types/Device';
import * as actions from '../DevicesPage/actions';
import {
  selectBatchMode,
  selectIsLoadingScreens,
  selectLastLoadedDate,
  selectLocationsPageDeviceResults,
  selectMoveScreenMode,
  selectScreensPageHasError,
  selectSearchQuery,
  selectSelectedDeviceIds,
} from '../DevicesPage/selectors';
import DeviceCard from './DeviceCard';
import useStyles from './DevicesPage.styles';
import DeviceSettings from './DeviceSettings';
import DevicePlaylistSelector from './DevicePlaylistSelector';
import DevicesActionBar from './DevicesActionBar';
import DeviceAISettings from './DeviceAISettings/DeviceAISettings';
import Checkbox from 'raydiant-elements/core/Checkbox';
import {
  canEditLocationDevice,
  hasRoleOrHigher,
  sortDevices,
} from '../../utilities';
import DeviceBatchSettings from './DeviceBatchSettings/DeviceBatchSettings';
import DeviceBatchPlaylistSelector from './DeviceBatchSettings/DeviceBatchPlaylistSelector';
import { devicesPageActions } from '.';
import { initialBatchDeviceSettings } from '../DevicesPage/reducer';
import DevicePerchSettings from './DevicePerchSettings/DevicePerchSettings';
import { getBatchActionStatus, useInterval } from './deviceBatchSettingsUtils';
import raydiant from '../../clients/raydiant';
import { Location, Device, BatchDeviceStatus } from '@raydiant/api-client-js';
import PageLayout from '../../components/PageLayout/PageLayout';
import PageTitle from '../../components/PageLayout/PageTitle';
import { useTranslation } from 'react-i18next';
import ScreensFilter from './ScreensFilter/ScreensFilter';
import Empty from '../../components/Empty/Empty';
import Error from '../../components/Error/Error';
import {
  getValidScreensFilterQueryKeys,
  mapScreensFilterQuery,
  parseScreensFilterQueryParams,
  screensFilterQueryString,
} from './ScreensFilter/utils';
import useLocations from '../../hooks/useLocations';
import useOnboardingStatus from '../../hooks/useOnboardingStatus';
import LocationOnboardingDialog from './components/LocationOnboardingDialog';
import AddLocationDialog from './components/AddLocationDialog';
import {
  getConnectionStatusFilteredDevice,
  getDevicesByLocationId,
  getQueryFilteredLocations,
} from './utils';
import Text from '@raydiant/raydial/components/Text';
import EmptyLocationCard from './components/EmptyLocationCard';
import LocationsPageActionButton from './components/LocationsPageActionButton';
import LocationActionBar from './components/LocationActionBar';
import LocationDetailsPopover from './components/LocationDetailsPopover';
import EditLocationDialog from './components/EditLocationDialog';
import AddScreenDialog from './components/AddScreenDialog';
import ManageAccessDialog from './components/ManageAccessDialog';
import MoveScreenModal from './components/MoveScreenModal';
import DeleteLocationDialog from './components/DeleteLocationDialog';
import UnableToDeleteDialog from './components/DeleteLocationDialog/UnableToDeleteDialog';
import { useUpdateOnboardingStatus } from '../../hooks';
import { selectSortOptions } from '../DevicesPage/selectors';

interface DevicesPageProps {}

export type LocationsPageModalType =
  | 'post'
  | 'patch'
  | 'delete'
  | 'screen'
  | 'manage'
  | 'actionBar'
  | null;

const LocationsPage: FC<DevicesPageProps> = () => {
  const { t } = useTranslation(['devices']);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const didCheckOnboardingStatus = useRef(false);

  const settingsMatch = useRouteMatch<{ deviceId?: string }>({
    path: paths.screenSettings.pattern,
  });
  const aiSettingsMatch = useRouteMatch<{ deviceId?: string }>({
    path: paths.screenAiSettings.pattern,
  });
  const perchSettingsMatch = useRouteMatch<{ deviceId?: string }>({
    path: paths.screenPerchSettings.pattern,
  });
  const playlistMatch = useRouteMatch<{ deviceId?: string }>({
    path: paths.screenPlaylist.pattern,
  });

  // Selectors

  const deviceResults = useSelector(selectLocationsPageDeviceResults);
  const sortOptions = useSelector(selectSortOptions);
  const lastLoadedDate = useSelector(selectLastLoadedDate);
  const currentUser = useSelector(selectUserProfile);
  const devicesById = useSelector(selectDevicesById);
  const searchQuery = useSelector(selectSearchQuery);
  const isBatchMode = useSelector(selectBatchMode);
  const isMoveScreenMode = useSelector(selectMoveScreenMode);
  const selectedDeviceIds = useSelector(selectSelectedDeviceIds);
  const hasError = useSelector(selectScreensPageHasError);
  const isLocationsPageLoading = useSelector(selectIsLoadingScreens);
  const isVIOnlyEnabled = useSelector(selectIsVIOnly);
  const isEnterpriseUser = useSelector(selectIsEnterpriseUser);

  const { pathname, search } = useLocation();
  const query = search?.length > 0 ? search.slice(1) : undefined;

  // Quries
  const { data: locationsData, isLoading: locationsLoading } = useLocations();
  const { data: onboardingData, isLoading: onboardingLoading } =
    useOnboardingStatus();
  const updateOnboardingStatus = useUpdateOnboardingStatus();

  const isLocationOnboardingCompleted = !!onboardingData?.some(
    (item) => item.name === 'locations',
  );
  const isLoading =
    isLocationsPageLoading || locationsLoading || onboardingLoading;
  const hasNoLocations = locationsData?.length === 0;

  const queryFilteredLocations = getQueryFilteredLocations(
    locationsData || [],
    query,
  );

  const devices: BatchDevice[] = Object.values(deviceResults).reduce(
    (acc, current) => {
      return acc.concat(current.devices);
    },
    [] as BatchDevice[],
  );

  const sortedDevices = sortDevices(devices, sortOptions, lastLoadedDate);

  const devicesWithLocationData = getConnectionStatusFilteredDevice(
    sortedDevices,
    query || '',
    lastLoadedDate,
  ).filter((device) => device.location !== null);

  const deviceResultsByLocationId = getDevicesByLocationId(
    queryFilteredLocations || [],
    devicesWithLocationData,
  );

  // State

  const [selectedProfileId, setSelectedProfileId] = useState('');
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [showBatchPlaylistSelector, setShowBatchPlaylistSelector] =
    useState<boolean>(false);
  const [batchOperationStatus, setBatchOperationStatus] = useState<
    BatchDeviceStatus[]
  >([]);
  const [batchInterval, setBatchInterval] = useState(5000);
  const [isAddPopOverOpen, setIsAddPopOverOpen] = useState(false);
  const [modalType, setModalType] = useState<LocationsPageModalType>(null);
  const [selectedLocationId, setSelectedLocationId] = useState<string | null>(
    null,
  );

  const loadDevicesPage = useCallback(() => {
    if (document.hidden) return;

    // since we are not updating isOnline filed in the mira-api we need to filter
    // connection_status from client side to meet with our DevieCard indication(lastLoadedDate)
    // we will need better way to solve this issue
    const screenFilterQuery = mapScreensFilterQuery(query || '');
    const removeConnectionStatus = {
      ...screenFilterQuery,
      connectionStatus: {
        offline: false,
        online: false,
      },
    };
    const updatedQuery = parseScreensFilterQueryParams(removeConnectionStatus);
    const updatedQueryString = screensFilterQueryString(updatedQuery);

    const payload = {
      query: updatedQueryString,
    };

    dispatch(actions.loadDevicesPage(payload));
  }, [dispatch, query]);

  const fetchDeviceStatus = useCallback(async () => {
    let result = [] as BatchDeviceStatus[];
    try {
      const res = await raydiant.getBatchDeviceStatus();
      result = res;
      setBatchOperationStatus(res);
    } catch (err) {
      result = [];
      setBatchOperationStatus([]);
    } finally {
      if (batchOperationStatus.length > 0 && result.length <= 0) {
        dispatch(deviceActions.fetchDevices());
      }
    }
  }, [batchOperationStatus.length, dispatch]);

  const handleBatchSettingsDialogClick = () => {
    fetchDeviceStatus();
  };

  useEffect(() => {
    const batchTimer = batchOperationStatus.length > 0 ? 5000 : 10000;
    setBatchInterval(batchTimer);
  }, [batchOperationStatus.length]);

  useInterval(() => {
    fetchDeviceStatus();
  }, batchInterval);

  const publishDevice = useCallback(
    (device: D.Device) => {
      dispatch(deviceActions.publishDevice(device.id));
    },
    [dispatch],
  );

  const updateSelectedDeviceIds = useCallback(
    (deviceIds: string[]) => {
      dispatch(devicesPageActions.updateSelectedDeviceIds(deviceIds));
    },
    [dispatch],
  );

  const closeModal = useCallback(() => {
    if (isBatchMode || isMoveScreenMode) {
      updateSelectedDeviceIds([]);
    }

    history.push({
      pathname: paths.screens(),
      search,
    });
  }, [history, isBatchMode, isMoveScreenMode, search, updateSelectedDeviceIds]);

  const editSettings = useCallback(
    (device: D.Device) => {
      history.push({
        pathname: paths.screenSettings(device.id),
        search,
      });
    },
    [history, search],
  );

  const editAiSettings = useCallback(
    (device: D.Device) => {
      history.push({
        pathname: paths.screenAiSettings(device.id),
        search,
      });
    },
    [history, search],
  );

  const editPerchSettings = useCallback(
    (device: D.Device) => {
      history.push({
        pathname: paths.screenPerchSettings(device.id),
        search,
      });
    },
    [history, search],
  );

  const selectPlaylist = useCallback(
    (device: D.Device) => {
      history.push({
        pathname: paths.screenPlaylist(device.id),
        search,
      });
    },
    [history, search],
  );

  const editPlaylist = useCallback(
    (device: D.Device) => {
      if (device.playlistId) {
        history.push(
          paths.editPlaylist(device.playlistId, {
            deviceId: device.id,
            backTo: paths.screens(),
            saveTo: paths.screens(),
            backToLabel: 'Back to Screens',
            previewMode:
              device.screenOrientation === 'normal' ? 'horizontal' : 'vertical',
          }),
        );
      } else {
        history.push(paths.screenPlaylist(device.id));
      }
    },

    [history],
  );

  const newPlaylist = useCallback(
    (device: D.Device) => {
      history.push(
        paths.newPlaylist({
          playlistId: createNewId(),
          deviceId: device.id,
          backTo: paths.screenPlaylist(device.id),
          saveTo: paths.screens(),
          backToLabel: 'Back to Screens',
          previewMode:
            device.screenOrientation === 'normal' ? 'horizontal' : 'vertical',
        }),
      );
    },
    [history],
  );

  const newPresentation = useCallback(
    (device: D.Device, applicationId: string) => {
      history.push(
        paths.newPresentation({
          applicationId,
          backTo: paths.screenPlaylist(device.id),
          saveTo: paths.screenPlaylist(device.id),
          backToLabel: 'Back to Screens',
          previewMode:
            device.screenOrientation === 'normal' ? 'horizontal' : 'vertical',
        }),
      );
    },
    [history],
  );

  const resetBatchDeviceSettings = useCallback(() => {
    dispatch(
      devicesPageActions.updateBatchDeviceSettings(initialBatchDeviceSettings),
    );
  }, [dispatch]);

  // Batch Actions

  const newBatchPlaylist = useCallback(() => {
    history.push(
      paths.newPlaylist({
        playlistId: createNewId(),
        backTo: paths.screens(),
        saveTo: paths.screens(),
        backToLabel: 'Back to Screens',
        previewMode: 'horizontal',
      }),
    );
  }, [history]);

  const selectAllDevices = useCallback(
    (devices: D.Device[]) => {
      if (selectAll) {
        setSelectAll(false);
        updateSelectedDeviceIds([]);
      } else {
        const deviceIds = Object.values(devices).map((device) => device.id);
        setSelectAll(true);
        updateSelectedDeviceIds(deviceIds);
      }
    },
    [selectAll, updateSelectedDeviceIds],
  );

  const toggleCheckbox = useCallback(
    (device: D.Device) => {
      if (selectedDeviceIds.includes(device.id)) {
        updateSelectedDeviceIds(
          selectedDeviceIds.filter((deviceId) => deviceId !== device.id),
        );
      } else {
        updateSelectedDeviceIds([...selectedDeviceIds, device.id]);
      }
    },
    [selectedDeviceIds, updateSelectedDeviceIds],
  );

  const closeBatchModal = useCallback(() => {
    if (showBatchPlaylistSelector) {
      setShowBatchPlaylistSelector(false);
    } else {
      updateSelectedDeviceIds([]);
      resetBatchDeviceSettings();
    }
  }, [
    showBatchPlaylistSelector,
    resetBatchDeviceSettings,
    updateSelectedDeviceIds,
  ]);

  const handleBatchPlaylistSelectorClick = () => {
    setShowBatchPlaylistSelector(!showBatchPlaylistSelector);
  };

  const handleAddPopOverLocationClick = () => {
    setIsAddPopOverOpen(false);
    setModalType('post');
  };

  const handleActionBarClick = (locationId: string) => {
    setSelectedLocationId(locationId);
  };

  const handleActionBarItemClick = (type: LocationsPageModalType) => {
    setModalType(type);
  };

  const handleModalClose = () => {
    setModalType(null);
    setSelectedLocationId(null);
  };

  const handleAddPopOverScreenClick = () => {
    setIsAddPopOverOpen(false);
    setModalType('screen');
  };

  const handleAddPopOverClick = (value: boolean) => {
    setIsAddPopOverOpen(value);
  };

  // Side-effects

  // Fetch devices on page load and when window gains focus to keep
  // metrics updated.
  useEffect(() => {
    loadDevicesPage();

    window.addEventListener('visibilitychange', loadDevicesPage, false);
    return () => {
      window.removeEventListener('visibilitychange', loadDevicesPage, false);
    };
  }, [dispatch, loadDevicesPage]);

  // Update selected profile to current user if not set.
  useEffect(() => {
    if (selectedProfileId) return;
    if (!currentUser) return;
    setSelectedProfileId(currentUser.id);
  }, [currentUser, selectedProfileId, setSelectedProfileId]);

  // Get out of batch mode when single device setting selected.
  useEffect(() => {
    const isDeviceSelected =
      settingsMatch?.params?.deviceId ||
      playlistMatch?.params?.deviceId ||
      aiSettingsMatch?.params?.deviceId;

    if (isDeviceSelected && isBatchMode) {
      dispatch(actions.toggleBatchMode());
    }

    if (isDeviceSelected && isMoveScreenMode) {
      dispatch(actions.toggleMoveScreenMode());
    }

    if (!isBatchMode) {
      setSelectAll(false);
      updateSelectedDeviceIds([]);
      setShowBatchPlaylistSelector(false);
    }

    if (!isMoveScreenMode) {
      setSelectAll(false);
      updateSelectedDeviceIds([]);
    }
  }, [
    isBatchMode,
    isMoveScreenMode,
    history,
    aiSettingsMatch?.params?.deviceId,
    settingsMatch?.params?.deviceId,
    playlistMatch?.params?.deviceId,
    dispatch,
    updateSelectedDeviceIds,
  ]);

  useEffect(() => {
    if (!isBatchMode || selectedDeviceIds.length === 0) {
      resetBatchDeviceSettings();
    }
  }, [isBatchMode, selectedDeviceIds, resetBatchDeviceSettings]);

  useEffect(() => {
    if (
      !isLocationOnboardingCompleted &&
      !didCheckOnboardingStatus.current &&
      currentUser?.domainRole === 'restricted'
    ) {
      updateOnboardingStatus.mutate({ name: 'locations' });
      didCheckOnboardingStatus.current = true;
    }
  }, [
    currentUser?.domainRole,
    isLocationOnboardingCompleted,
    updateOnboardingStatus,
  ]);

  const totalDevices = devices.length;
  const hasNoDevices = !totalDevices && !searchQuery;
  const hasScreensFilterQuery =
    getValidScreensFilterQueryKeys(search)?.length > 0;

  const headerEl = (
    <div className="mb-1.5">
      <DevicesActionBar disabled={hasNoDevices} />
    </div>
  );

  // Render
  if (!lastLoadedDate || !currentUser || isLoading) {
    return (
      <Page
        title={
          isVIOnlyEnabled
            ? t('screens.viOnly.title')!
            : t('screens.default.title')!
        }
      >
        <PageLayout
          title={
            <PageTitle
              title={
                isVIOnlyEnabled
                  ? t('screens.viOnly.title')!
                  : t('screens.default.title')!
              }
              subTitle={
                isVIOnlyEnabled
                  ? t('screens.viOnly.subTitle')!
                  : t('screens.default.subTitle')!
              }
            />
          }
        >
          {headerEl}
          <ScreensFilter className="mb-3" isSidebarOpen={false} />
          <Center className="h-full">
            <CircularProgress size={30} />
          </Center>
        </PageLayout>
      </Page>
    );
  }

  const settingsDeviceId = settingsMatch?.params?.deviceId ?? '';
  const aiSettingsDeviceId = aiSettingsMatch?.params?.deviceId ?? '';
  const perchSettingsDeviceId = perchSettingsMatch?.params?.deviceId ?? '';
  const playlistDeviceId = playlistMatch?.params?.deviceId ?? '';
  const selectedDeviceId =
    settingsDeviceId ||
    playlistDeviceId ||
    aiSettingsDeviceId ||
    perchSettingsDeviceId;
  const selectedSettingsDevice = devices.find(
    (device) => device.id === settingsDeviceId,
  );
  const selectedAiSettingsDevice = devices.find(
    (device) => device.id === aiSettingsDeviceId,
  );
  const selectedPerchSettingsDevice = devices.find(
    (device) => device.id === perchSettingsDeviceId,
  );
  const selectedPlaylistDevice = devices.find(
    (device) => device.id === playlistDeviceId,
  );
  const showBatchSettings = isBatchMode && selectedDeviceIds.length > 0;
  const showMoveScreenModal = isMoveScreenMode && selectedDeviceIds.length > 0;
  const isModalOpen =
    !!selectedSettingsDevice ||
    !!selectedPlaylistDevice ||
    !!selectedAiSettingsDevice ||
    !!selectedPerchSettingsDevice ||
    showBatchSettings ||
    showMoveScreenModal;
  const allDevices = getConnectionStatusFilteredDevice(
    Object.values(devicesById) as D.Device[],
    query || '',
    lastLoadedDate,
  );
  const selectableDevices = allDevices.filter((device) =>
    canEditLocationDevice(currentUser, device),
  );
  const selectedDevices = allDevices.filter((device) =>
    selectedDeviceIds.includes(device.id),
  );

  const smbLocationOnboardingDialogOpen =
    !isLocationOnboardingCompleted && !isEnterpriseUser;
  const enterpriseLocationOnboardingDialogOpen =
    !isLocationOnboardingCompleted &&
    isEnterpriseUser &&
    hasRoleOrHigher('standard', currentUser);
  const isLocationsOnboardingDialogOpen =
    smbLocationOnboardingDialogOpen || enterpriseLocationOnboardingDialogOpen;

  const smbAddFirstLocationDialogOpen =
    isLocationOnboardingCompleted && hasNoLocations && !isEnterpriseUser;
  const enterpriseAddFirstLocationDialogOpen =
    isLocationOnboardingCompleted &&
    hasNoLocations &&
    isEnterpriseUser &&
    hasRoleOrHigher('admin', currentUser);
  const isAddFirstLocationDialogOpen =
    smbAddFirstLocationDialogOpen || enterpriseAddFirstLocationDialogOpen;

  const showEnterpriseRestrictedUserNoLocationAccess =
    isLocationOnboardingCompleted &&
    isEnterpriseUser &&
    !hasRoleOrHigher('standard', currentUser) &&
    hasNoLocations;
  const selectedLocation =
    locationsData?.find((location) => selectedLocationId === location.id) ||
    null;
  const locationHasDevices = !!(
    selectedLocationId &&
    deviceResultsByLocationId[selectedLocationId]?.devices?.length > 0
  );
  const deviceResultsWithLocation =
    Object.values(deviceResultsByLocationId) || [];
  const filteredDeviceResultsWithLocation = deviceResultsWithLocation.filter(
    ({ location }) =>
      location.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const noFilteredResult =
    (hasScreensFilterQuery || searchQuery) &&
    !(filteredDeviceResultsWithLocation.length > 0);

  const renderModals = (modalType: string | null) => {
    switch (modalType) {
      case 'post':
        return (
          <AddLocationDialog
            isOpen={modalType === 'post'}
            onClose={handleModalClose}
            onSubmit={handleModalClose}
          />
        );
      case 'manage':
        return selectedLocation ? (
          <ManageAccessDialog
            isOpen={modalType === 'manage'}
            location={selectedLocation}
            onClose={handleModalClose}
            onSubmit={handleModalClose}
          />
        ) : null;
      case 'patch':
        return selectedLocation ? (
          <EditLocationDialog
            isOpen={modalType === 'patch'}
            onClose={handleModalClose}
            onSubmit={handleModalClose}
            location={selectedLocation}
          />
        ) : null;
      case 'delete':
        if (selectedLocation) {
          return locationsData!.length > 1 ? (
            <DeleteLocationDialog
              isOpen={modalType === 'delete'}
              onClose={handleModalClose}
              onSubmit={handleModalClose}
              isDeleteDisabled={locationHasDevices}
              location={selectedLocation}
            />
          ) : (
            <UnableToDeleteDialog
              isOpen={modalType === 'delete'}
              onClick={handleModalClose}
              type="location"
            />
          );
        }
        return null;
      case 'screen':
        return (
          <AddScreenDialog
            open={modalType === 'screen'}
            onAddLocationClick={() => setModalType('post')}
            onClose={handleModalClose}
            onRegister={handleModalClose}
            location={selectedLocation || undefined}
          />
        );
      default:
        return null;
    }
  };

  const renderLocationNameWithActionBar = (
    location: Location,
    devices: Device[],
  ) => {
    return (
      <div className="pl-4 pr-6 pb-2 mt-4">
        <div className="flex justify-between">
          <div className="flex items-center">
            <Text variant="subheader" className="text-black">
              {location.name}
            </Text>
            <LocationDetailsPopover description={location.description} />
          </div>
          <LocationActionBar
            devices={devices}
            location={location}
            onActionBarClick={handleActionBarClick}
            onActionBarItemClick={handleActionBarItemClick}
            onClose={handleModalClose}
          />
        </div>
      </div>
    );
  };

  if (!isLoading && hasNoLocations) {
    return (
      <Page
        title={
          isVIOnlyEnabled
            ? t('screens.viOnly.title')!
            : t('screens.default.title')!
        }
      >
        <PageLayout
          title={
            <PageTitle
              title={
                isVIOnlyEnabled
                  ? t('screens.viOnly.title')!
                  : t('screens.default.title')!
              }
              subTitle={
                isVIOnlyEnabled
                  ? t('screens.viOnly.subTitle')!
                  : t('screens.default.subTitle')!
              }
            />
          }
        >
          {headerEl}
          <ScreensFilter className="mb-3" isSidebarOpen={isModalOpen} />
          <Scrollable className="h-full pb-3">
            {hasScreensFilterQuery && (
              <div className="card bg-white w-full h-full items-center justify-center">
                <Empty
                  message={t('screens.noDevices')!}
                  title={t('screens.noResults')!}
                >
                  <button
                    onClick={() => {
                      history.push({
                        pathname,
                      });
                    }}
                    className="btn btn-primary sm:mini"
                  >
                    {t('screens.clearFilter')}
                  </button>
                </Empty>
              </div>
            )}
            {hasError && (
              <div className="card bg-white w-full h-full items-center justify-center">
                <Error
                  message={t('screens.pleaseRefresh')!}
                  title={t('screens.couldNotLoad')!}
                >
                  <a
                    href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                    rel="noreferrer"
                    target="_blank"
                  >
                    {t('analytics.learnmore')}
                  </a>
                </Error>
              </div>
            )}
            {isLocationsOnboardingDialogOpen && (
              <LocationOnboardingDialog
                isOpen={isLocationsOnboardingDialogOpen}
              />
            )}
            {isAddFirstLocationDialogOpen && (
              <AddLocationDialog
                isOpen={isAddFirstLocationDialogOpen}
                type="first"
              />
            )}
            {showEnterpriseRestrictedUserNoLocationAccess && (
              <div className="card bg-white w-full h-full items-center justify-center">
                <Empty
                  title="Oops, no location access"
                  message="You cannot see screens because you do not have location access. Reach out to your Raydiant admin for access."
                />
              </div>
            )}
          </Scrollable>
        </PageLayout>
      </Page>
    );
  }

  const areAllDevicesSelected = selectableDevices.every((device) =>
    selectedDeviceIds.includes(device.id),
  );

  const selectedDevicesResults = selectedDevices;

  return (
    <Page
      title={
        isVIOnlyEnabled
          ? t('screens.viOnly.title')!
          : t('screens.default.title')!
      }
    >
      <PageLayout
        title={
          <PageTitle
            className="flex justify-between align-top"
            title={
              isVIOnlyEnabled
                ? t('screens.viOnly.title')!
                : t('screens.default.title')!
            }
            subTitle={
              isVIOnlyEnabled
                ? t('screens.viOnly.subTitle')!
                : t('screens.default.subTitle')!
            }
            button={
              <LocationsPageActionButton
                onScreenClick={handleAddPopOverScreenClick}
                onLocationClick={handleAddPopOverLocationClick}
                isOpen={isAddPopOverOpen}
                onClick={handleAddPopOverClick}
              />
            }
          />
        }
      >
        {headerEl}
        <ScreensFilter className="mb-7" isSidebarOpen={isModalOpen} />
        <Scrollable className="h-full pb-3">
          {noFilteredResult && (
            <div className="card bg-white w-full h-full items-center justify-center">
              <Empty
                message="There are no results matching your search or filters. Please adjust it and try again."
                title={t('screens.noResults')!}
              ></Empty>
            </div>
          )}
          {!noFilteredResult && (
            <div className={classes.deviceList}>
              {(isBatchMode || isMoveScreenMode) && (
                <div className={classes.selectAll}>
                  <Checkbox
                    className="mr-4"
                    checked={areAllDevicesSelected}
                    onChange={() => selectAllDevices(selectableDevices)}
                  />
                  <Text>
                    {t('screens.selectAll')} |{' '}
                    {t('screens.devicesSelected', {
                      count: selectedDevicesResults?.length ?? 0,
                    })}
                  </Text>
                </div>
              )}

              {filteredDeviceResultsWithLocation.length > 0 &&
                filteredDeviceResultsWithLocation.map(
                  ({ location, devices }) => (
                    <div key={location.id} className="mb-8">
                      {renderLocationNameWithActionBar(location, devices)}
                      {devices.length === 0 && (
                        <EmptyLocationCard
                          onAddScreen={() => {
                            handleActionBarClick(location.id);
                            handleActionBarItemClick('screen');
                          }}
                        />
                      )}
                      <div className="flex flex-col gap-2 md:gap-1">
                        {devices.length > 0 &&
                          devices.map((device) => (
                            <DeviceCard
                              key={device.id}
                              device={device}
                              currentUser={currentUser}
                              lastLoadedDate={lastLoadedDate}
                              selected={device.id === selectedDeviceId}
                              selectedPlaylist={
                                device.id === selectedDeviceId &&
                                !!selectedPlaylistDevice
                              }
                              onAiSettingsClick={() => editAiSettings(device)}
                              onPerchSettingsClick={() =>
                                editPerchSettings(device)
                              }
                              onClick={() => editPlaylist(device)}
                              onSettingsClick={() => editSettings(device)}
                              onEditPlaylistClick={() => editPlaylist(device)}
                              onSelectPlaylistClick={() =>
                                selectPlaylist(device)
                              }
                              onPublishClick={() => publishDevice(device)}
                              checked={selectedDeviceIds.includes(device.id)}
                              onCheckboxClick={toggleCheckbox}
                              batchStatus={getBatchActionStatus(
                                device.id,
                                batchOperationStatus,
                              )}
                            />
                          ))}
                      </div>
                    </div>
                  ),
                )}
            </div>
          )}
        </Scrollable>

        {!isBatchMode && !isMoveScreenMode && (
          <PaperModal
            color="lightGrey"
            className={cn(
              classes.modal,
              selectedPlaylistDevice && 'tour-select-playlist',
            )}
            open={isModalOpen}
            onClose={closeModal}
          >
            {selectedSettingsDevice && (
              <DeviceSettings
                key={selectedSettingsDevice.id} // Clear state when selecting another device.
                device={selectedSettingsDevice}
                lastLoadedDate={lastLoadedDate}
                onClose={closeModal}
                isVIOnly={isVIOnlyEnabled}
              />
            )}
            {selectedAiSettingsDevice && (
              <DeviceAISettings device={selectedAiSettingsDevice} />
            )}
            {selectedPerchSettingsDevice && (
              <DevicePerchSettings device={selectedPerchSettingsDevice} />
            )}
            {selectedPlaylistDevice && (
              <DevicePlaylistSelector
                device={selectedPlaylistDevice}
                selectedProfileId={selectedProfileId}
                onSelectProfile={setSelectedProfileId}
                onNewPlaylist={() => newPlaylist(selectedPlaylistDevice)}
                onNewPresentation={(applicationId) =>
                  newPresentation(selectedPlaylistDevice, applicationId)
                }
                onClose={closeModal}
              />
            )}
          </PaperModal>
        )}

        {showBatchSettings && !showBatchPlaylistSelector && (
          <PaperModal
            color="lightGrey"
            className={cn(
              classes.modal,
              selectedPlaylistDevice && 'tour-select-playlist',
              classes.batchSettingsModal,
            )}
            open={isModalOpen}
            onClose={closeBatchModal}
          >
            <DeviceBatchSettings
              devices={selectedDevicesResults}
              lastLoadedDate={lastLoadedDate}
              onPlaylistSelectorClick={handleBatchPlaylistSelectorClick}
              onDialogClick={handleBatchSettingsDialogClick}
              onClose={closeBatchModal}
            />
          </PaperModal>
        )}

        {showBatchSettings && showBatchPlaylistSelector && (
          <PaperModal
            color="lightGrey"
            className={cn(
              classes.modal,
              selectedPlaylistDevice && 'tour-select-playlist',
            )}
            open={isModalOpen}
            onClose={handleBatchPlaylistSelectorClick}
          >
            <DeviceBatchPlaylistSelector
              selectedProfileId={selectedProfileId}
              onSelectProfile={setSelectedProfileId}
              onClose={handleBatchPlaylistSelectorClick}
              onNewPlaylist={newBatchPlaylist}
            />
          </PaperModal>
        )}
        {showMoveScreenModal && (
          <PaperModal
            color="lightGrey"
            className={cn(classes.modal, classes.batchSettingsModal)}
            open={isModalOpen}
            onClose={closeModal}
          >
            <MoveScreenModal
              locations={locationsData || []}
              onClose={closeModal}
              onSubmit={() => {}}
              selectedDeviceIds={selectedDeviceIds}
            />
          </PaperModal>
        )}
        {isLocationsOnboardingDialogOpen && (
          <LocationOnboardingDialog isOpen={isLocationsOnboardingDialogOpen} />
        )}
        <LibraryDragLayer />
        {renderModals(modalType)}
      </PageLayout>
    </Page>
  );
};

export default LocationsPage;
