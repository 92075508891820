import { ApiClientError, PendingDomainUser } from '@raydiant/api-client-js';
import { keys } from '../queryClient';
import raydiant from '../clients/miraClient';
import useProtectedQuery from './useProtectedQuery';

export default function useGetDomainInvites() {
  return useProtectedQuery<PendingDomainUser[], ApiClientError>(
    keys.domainInvites(),
    () => raydiant.getPendingDomainUsers(),
  );
}
