import cn from 'classnames';
import Paper from 'raydiant-elements/core/Paper';
import PaperModal from 'raydiant-elements/core/PaperModal';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import Center from 'raydiant-elements/layout/Center';
import Heading from 'raydiant-elements/core/Heading';
import Text from 'raydiant-elements/typography/Text';
import { FC, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import Page from '../../components/Page';
import LibraryDragLayer from '../../components/LibraryDragLayer';
import { selectIsVIOnly, selectUserProfile } from '../../selectors/user';
import { selectDevicesById } from '../../selectors/v2/devices';
import { selectOtherDomainProfiles } from '../../selectors/v2/domains';
import * as deviceActions from '../../actions/devices';
import { createNewId } from '../../utilities/identifiers';
import * as paths from '../../routes/paths';
import * as D from '../../clients/mira/types/Device';
import * as actions from './actions';
import {
  selectBatchMode,
  selectDeviceResults,
  selectIsLoadingScreens,
  selectLastLoadedDate,
  selectScreensPageHasError,
  selectSearchQuery,
  selectSelectedDeviceIds,
} from './selectors';
import DeviceCard from './DeviceCard';
import useStyles from './DevicesPage.styles';
import DeviceSettings from './DeviceSettings';
import DevicePlaylistSelector from './DevicePlaylistSelector';
import DevicesActionBar from './DevicesActionBar';
import DeviceEmptyState from './DeviceEmptyState';
import DeviceAISettings from './DeviceAISettings/DeviceAISettings';
import Checkbox from 'raydiant-elements/core/Checkbox';
import { canEditResource } from '../../utilities';
import DeviceBatchSettings from './DeviceBatchSettings/DeviceBatchSettings';
import DeviceBatchPlaylistSelector from './DeviceBatchSettings/DeviceBatchPlaylistSelector';
import { devicesPageActions } from '.';
import { initialBatchDeviceSettings } from './reducer';
import DevicePerchSettings from './DevicePerchSettings/DevicePerchSettings';
import { getBatchActionStatus, useInterval } from './deviceBatchSettingsUtils';
import raydiant from '../../clients/raydiant';
import { BatchDeviceStatus } from '@raydiant/api-client-js';
import classNames from 'classnames';
import PageLayout from '../../components/PageLayout/PageLayout';
import PageTitle from '../../components/PageLayout/PageTitle';
import { useTranslation } from 'react-i18next';
import ScreensFilter from './ScreensFilter/ScreensFilter';
import Empty from '../../components/Empty/Empty';
import Error from '../../components/Error/Error';
import { getValidScreensFilterQueryKeys } from './ScreensFilter/utils';

interface DevicesPageProps {}

const DevicesPage: FC<DevicesPageProps> = () => {
  const { t } = useTranslation(['devices']);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const settingsMatch = useRouteMatch<{ deviceId?: string }>({
    path: paths.screenSettings.pattern,
  });
  const aiSettingsMatch = useRouteMatch<{ deviceId?: string }>({
    path: paths.screenAiSettings.pattern,
  });
  const perchSettingsMatch = useRouteMatch<{ deviceId?: string }>({
    path: paths.screenPerchSettings.pattern,
  });
  const playlistMatch = useRouteMatch<{ deviceId?: string }>({
    path: paths.screenPlaylist.pattern,
  });

  // Selectors

  const deviceResults = useSelector(selectDeviceResults);
  const lastLoadedDate = useSelector(selectLastLoadedDate);
  const currentUser = useSelector(selectUserProfile);
  const devicesById = useSelector(selectDevicesById);
  const otherDomainProfiles = useSelector(selectOtherDomainProfiles);
  const searchQuery = useSelector(selectSearchQuery);
  const isBatchMode = useSelector(selectBatchMode);
  const selectedDeviceIds = useSelector(selectSelectedDeviceIds);
  const hasError = useSelector(selectScreensPageHasError);
  const isLoading = useSelector(selectIsLoadingScreens);
  const isVIOnlyEnabled = useSelector(selectIsVIOnly);

  // State

  const [selectedProfileId, setSelectedProfileId] = useState('');
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [showBatchPlaylistSelector, setShowBatchPlaylistSelector] =
    useState<boolean>(false);
  const [batchOperationStatus, setBatchOperationStatus] = useState<
    BatchDeviceStatus[]
  >([]);
  const [batchInterval, setBatchInterval] = useState(5000);

  const { pathname, search } = useLocation();

  const loadDevicesPage = useCallback(() => {
    if (document.hidden) return;

    const payload = {
      query: search?.length > 0 ? search.slice(1) : undefined,
    };

    dispatch(actions.loadDevicesPage(payload));
  }, [dispatch, search]);

  const fetchDeviceStatus = useCallback(async () => {
    let result = [] as BatchDeviceStatus[];
    try {
      const res = await raydiant.getBatchDeviceStatus();
      result = res;
      setBatchOperationStatus(res);
    } catch (err) {
      result = [];
      setBatchOperationStatus([]);
    } finally {
      if (batchOperationStatus.length > 0 && result.length <= 0) {
        dispatch(deviceActions.fetchDevices());
      }
    }
  }, [batchOperationStatus.length, dispatch]);

  const handleBatchSettingsDialogClick = () => {
    fetchDeviceStatus();
  };

  useEffect(() => {
    const batchTimer = batchOperationStatus.length > 0 ? 5000 : 10000;
    setBatchInterval(batchTimer);
  }, [batchOperationStatus.length]);

  useInterval(() => {
    fetchDeviceStatus();
  }, batchInterval);

  const publishDevice = useCallback(
    (device: D.Device) => {
      dispatch(deviceActions.publishDevice(device.id));
    },
    [dispatch],
  );

  const updateSelectedDeviceIds = useCallback(
    (deviceIds: string[]) => {
      dispatch(devicesPageActions.updateSelectedDeviceIds(deviceIds));
    },
    [dispatch],
  );

  const closeModal = useCallback(() => {
    if (isBatchMode) {
      updateSelectedDeviceIds([]);
    }
    history.push({
      pathname: paths.screens(),
      search,
    });
  }, [history, isBatchMode, search, updateSelectedDeviceIds]);

  const editSettings = useCallback(
    (device: D.Device) => {
      history.push({
        pathname: paths.screenSettings(device.id),
        search,
      });
    },
    [history, search],
  );

  const editAiSettings = useCallback(
    (device: D.Device) => {
      history.push({
        pathname: paths.screenAiSettings(device.id),
        search,
      });
    },
    [history, search],
  );

  const editPerchSettings = useCallback(
    (device: D.Device) => {
      history.push({
        pathname: paths.screenPerchSettings(device.id),
        search,
      });
    },
    [history, search],
  );

  const selectPlaylist = useCallback(
    (device: D.Device) => {
      history.push({
        pathname: paths.screenPlaylist(device.id),
        search,
      });
    },
    [history, search],
  );

  const editPlaylist = useCallback(
    (device: D.Device) => {
      if (device.playlistId) {
        history.push(
          paths.editPlaylist(device.playlistId, {
            deviceId: device.id,
            backTo: paths.screens(),
            saveTo: paths.screens(),
            backToLabel: 'Back to Screens',
            previewMode:
              device.screenOrientation === 'normal' ? 'horizontal' : 'vertical',
          }),
        );
      } else {
        history.push(paths.screenPlaylist(device.id));
      }
    },

    [history],
  );

  const newPlaylist = useCallback(
    (device: D.Device) => {
      history.push(
        paths.newPlaylist({
          playlistId: createNewId(),
          deviceId: device.id,
          backTo: paths.screenPlaylist(device.id),
          saveTo: paths.screens(),
          backToLabel: 'Back to Screens',
          previewMode:
            device.screenOrientation === 'normal' ? 'horizontal' : 'vertical',
        }),
      );
    },
    [history],
  );

  const newPresentation = useCallback(
    (device: D.Device, applicationId: string) => {
      history.push(
        paths.newPresentation({
          applicationId,
          backTo: paths.screenPlaylist(device.id),
          saveTo: paths.screenPlaylist(device.id),
          backToLabel: 'Back to Screens',
          previewMode:
            device.screenOrientation === 'normal' ? 'horizontal' : 'vertical',
        }),
      );
    },
    [history],
  );

  const resetBatchDeviceSettings = useCallback(() => {
    dispatch(
      devicesPageActions.updateBatchDeviceSettings(initialBatchDeviceSettings),
    );
  }, [dispatch]);

  // Batch Actions

  const newBatchPlaylist = useCallback(() => {
    history.push(
      paths.newPlaylist({
        playlistId: createNewId(),
        backTo: paths.screens(),
        saveTo: paths.screens(),
        backToLabel: 'Back to Screens',
        previewMode: 'horizontal',
      }),
    );
  }, [history]);

  const selectAllDevices = useCallback(
    (devices: D.Device[]) => {
      if (selectAll) {
        setSelectAll(false);
        updateSelectedDeviceIds([]);
      } else {
        const deviceIds = Object.values(devices).map((device) => device.id);
        setSelectAll(true);
        updateSelectedDeviceIds(deviceIds);
      }
    },
    [selectAll, updateSelectedDeviceIds],
  );

  const toggleCheckbox = useCallback(
    (device: D.Device) => {
      if (selectedDeviceIds.includes(device.id)) {
        updateSelectedDeviceIds(
          selectedDeviceIds.filter((deviceId) => deviceId !== device.id),
        );
      } else {
        updateSelectedDeviceIds([...selectedDeviceIds, device.id]);
      }
    },
    [selectedDeviceIds, updateSelectedDeviceIds],
  );

  const closeBatchModal = useCallback(() => {
    if (showBatchPlaylistSelector) {
      setShowBatchPlaylistSelector(false);
    } else {
      updateSelectedDeviceIds([]);
      resetBatchDeviceSettings();
    }
  }, [
    showBatchPlaylistSelector,
    resetBatchDeviceSettings,
    updateSelectedDeviceIds,
  ]);

  const handleBatchPlaylistSelectorClick = () => {
    setShowBatchPlaylistSelector(!showBatchPlaylistSelector);
  };

  // Side-effects

  // Fetch devices on page load and when window gains focus to keep
  // metrics updated.
  useEffect(() => {
    loadDevicesPage();

    window.addEventListener('visibilitychange', loadDevicesPage, false);
    return () => {
      window.removeEventListener('visibilitychange', loadDevicesPage, false);
    };
  }, [dispatch, loadDevicesPage]);

  // Update selected profile to current user if not set.
  useEffect(() => {
    if (selectedProfileId) return;
    if (!currentUser) return;
    setSelectedProfileId(currentUser.id);
  }, [currentUser, selectedProfileId, setSelectedProfileId]);

  // Get out of batch mode when single device setting selected.
  useEffect(() => {
    const isDeviceSelected =
      settingsMatch?.params?.deviceId ||
      playlistMatch?.params?.deviceId ||
      aiSettingsMatch?.params?.deviceId;

    if (isDeviceSelected && isBatchMode) {
      dispatch(actions.toggleBatchMode());
    }

    if (!isBatchMode) {
      setSelectAll(false);
      updateSelectedDeviceIds([]);
      setShowBatchPlaylistSelector(false);
    }
  }, [
    isBatchMode,
    history,
    aiSettingsMatch?.params?.deviceId,
    settingsMatch?.params?.deviceId,
    playlistMatch?.params?.deviceId,
    dispatch,
    updateSelectedDeviceIds,
  ]);

  useEffect(() => {
    if (!isBatchMode || selectedDeviceIds.length === 0) {
      resetBatchDeviceSettings();
    }
  }, [isBatchMode, selectedDeviceIds, resetBatchDeviceSettings]);

  // Fetch recent errors every 2 minutes.
  // Turn off recent device errors until we can investigate the performance
  // issues happening in TimescaleDB.
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     dispatch(deviceActions.fetchRecentDeviceErrors());
  //   }, 2 * 60 * 1000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [dispatch]);

  const totalDevices = Object.values(deviceResults).reduce(
    (a, b) => a + b.devices.length,
    0,
  );
  const hasNoDevices = !totalDevices && !searchQuery;
  const hasScreensFilterQuery =
    getValidScreensFilterQueryKeys(search)?.length > 0;

  const headerEl = (
    <div className={classNames(classes.actions)}>
      <Paper color="light" className={classes.section}>
        <DevicesActionBar
          disabled={hasNoDevices}
          hasScreensFilterQuery={hasScreensFilterQuery}
        />
      </Paper>
    </div>
  );

  // Render
  if (!lastLoadedDate || !currentUser || isLoading) {
    return (
      <Page
        title={
          isVIOnlyEnabled
            ? t('screens.viOnly.title')!
            : t('screens.default.title')!
        }
      >
        <PageLayout
          title={
            <PageTitle
              title={
                isVIOnlyEnabled
                  ? t('screens.viOnly.title')!
                  : t('screens.default.title')!
              }
              subTitle={
                isVIOnlyEnabled
                  ? t('screens.viOnly.subTitle')!
                  : t('screens.default.subTitle')!
              }
            />
          }
        >
          {headerEl}
          <ScreensFilter className="mb-3" isSidebarOpen={false} />
          <Center className="h-full">
            <CircularProgress size={30} />
          </Center>
        </PageLayout>
      </Page>
    );
  }

  const myDeviceResults = deviceResults[currentUser.id];
  const settingsDeviceId = settingsMatch?.params?.deviceId ?? '';
  const aiSettingsDeviceId = aiSettingsMatch?.params?.deviceId ?? '';
  const perchSettingsDeviceId = perchSettingsMatch?.params?.deviceId ?? '';
  const playlistDeviceId = playlistMatch?.params?.deviceId ?? '';
  const selectedDeviceId =
    settingsDeviceId ||
    playlistDeviceId ||
    aiSettingsDeviceId ||
    perchSettingsDeviceId;
  const selectedSettingsDevice = devicesById[settingsDeviceId];
  const selectedAiSettingsDevice = devicesById[aiSettingsDeviceId];
  const selectedPerchSettingsDevice = devicesById[perchSettingsDeviceId];
  const selectedPlaylistDevice = devicesById[playlistDeviceId];
  const showBatchSettings = isBatchMode && selectedDeviceIds.length > 0;
  const isModalOpen =
    !!selectedSettingsDevice ||
    !!selectedPlaylistDevice ||
    !!selectedAiSettingsDevice ||
    !!selectedPerchSettingsDevice ||
    showBatchSettings;
  const allDevices = Object.values(devicesById) as D.Device[];
  const selectableDevices = allDevices.filter((device) =>
    canEditResource(currentUser, device.resource),
  );
  const selectedDevices = allDevices.filter((device) =>
    selectedDeviceIds.includes(device.id),
  );
  /*this is a hacky fix. devicesById get's out of sync when filters are applied
  We should have been using deviceResults instead as that will always contain the devices seen on the screen. 
  This should suffice for now as this code will all be going away soon */
  const totalDeviceResults = Object.keys(deviceResults)
    .filter(
      (key) =>
        otherDomainProfiles.some((profile) => profile.id === key) ||
        key === currentUser.id,
    )
    .map((profileId) => deviceResults[profileId].devices);

  if (!isLoading && hasNoDevices) {
    return (
      <Page
        title={
          isVIOnlyEnabled
            ? t('screens.viOnly.title')!
            : t('screens.default.title')!
        }
      >
        <PageLayout
          title={
            <PageTitle
              title={
                isVIOnlyEnabled
                  ? t('screens.viOnly.title')!
                  : t('screens.default.title')!
              }
              subTitle={
                isVIOnlyEnabled
                  ? t('screens.viOnly.subTitle')!
                  : t('screens.default.subTitle')!
              }
            />
          }
        >
          {headerEl}
          <ScreensFilter className="mb-3" isSidebarOpen={isModalOpen} />
          <Scrollable className="h-full pb-3">
            {hasScreensFilterQuery && (
              <div className="card bg-[#fafafa] w-full h-full items-center justify-center">
                <Empty
                  message={t('screens.noDevices')!}
                  title={t('screens.noResults')!}
                >
                  <button
                    onClick={() => {
                      history.push({
                        pathname,
                      });
                    }}
                    className="btn btn-primary sm:mini"
                  >
                    {t('screens.clearFilter')}
                  </button>
                </Empty>
              </div>
            )}
            {hasError && (
              <div className="card bg-[#fafafa] w-full h-full items-center justify-center">
                <Error
                  message={t('screens.pleaseRefresh')!}
                  title={t('screens.couldNotLoad')!}
                >
                  <a
                    href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                    rel="noreferrer"
                    target="_blank"
                  >
                    {t('analytics.learnmore')}
                  </a>
                </Error>
              </div>
            )}
            {!hasScreensFilterQuery && <DeviceEmptyState />}
          </Scrollable>
        </PageLayout>
      </Page>
    );
  }

  const areAllDevicesSelected = selectableDevices.every((device) =>
    selectedDeviceIds.includes(device.id),
  );

  const selectedDevicesResults = areAllDevicesSelected
    ? totalDeviceResults.flat()
    : selectedDevices;

  return (
    <Page
      title={
        isVIOnlyEnabled
          ? t('screens.viOnly.title')!
          : t('screens.default.title')!
      }
    >
      <PageLayout
        title={
          <PageTitle
            title={
              isVIOnlyEnabled
                ? t('screens.viOnly.title')!
                : t('screens.default.title')!
            }
            subTitle={
              isVIOnlyEnabled
                ? t('screens.viOnly.subTitle')!
                : t('screens.default.subTitle')!
            }
          />
        }
      >
        {headerEl}
        <ScreensFilter className="mb-3" isSidebarOpen={isModalOpen} />
        <Scrollable>
          <div className={classes.deviceList}>
            {isBatchMode && (
              <div className={classes.selectAll}>
                <Checkbox
                  className={classes.checkbox}
                  checked={areAllDevicesSelected}
                  onChange={() => selectAllDevices(selectableDevices)}
                />
                <Text>
                  {t('screens.selectAll')} |{' '}
                  {t('screens.devicesSelected', {
                    count: selectedDevicesResults?.length ?? 0,
                  })}
                </Text>
              </div>
            )}

            {myDeviceResults &&
              myDeviceResults.devices.map((device) => (
                <DeviceCard
                  key={device.id}
                  device={device}
                  currentUser={currentUser}
                  lastLoadedDate={lastLoadedDate}
                  selected={device.id === selectedDeviceId}
                  selectedPlaylist={
                    device.id === selectedDeviceId && !!selectedPlaylistDevice
                  }
                  onAiSettingsClick={() => editAiSettings(device)}
                  onPerchSettingsClick={() => editPerchSettings(device)}
                  onClick={() => editPlaylist(device)}
                  onSettingsClick={() => editSettings(device)}
                  onEditPlaylistClick={() => editPlaylist(device)}
                  onSelectPlaylistClick={() => selectPlaylist(device)}
                  onPublishClick={() => publishDevice(device)}
                  checked={selectedDeviceIds.includes(device.id)}
                  onCheckboxClick={toggleCheckbox}
                  batchStatus={getBatchActionStatus(
                    device.id,
                    batchOperationStatus,
                  )}
                />
              ))}

            {otherDomainProfiles.map((profile) => {
              const group = deviceResults[profile.id];
              if (!group) return null;

              const devices = group.devices;

              return (
                <div key={profile.id}>
                  <div className={classes.title}>
                    <Heading>{profile.name}</Heading>
                    <Text xsmall muted>
                      {devices.length === 1
                        ? `1 ${isVIOnlyEnabled ? 'device' : 'screen'}`
                        : `${devices.length} ${
                            isVIOnlyEnabled ? 'devices' : 'screens'
                          }`}
                    </Text>
                  </div>
                  {devices.map((device) => (
                    <DeviceCard
                      key={device.id}
                      device={device}
                      currentUser={currentUser}
                      lastLoadedDate={lastLoadedDate}
                      selected={device.id === selectedDeviceId}
                      onClick={() => editPlaylist(device)}
                      onSettingsClick={() => editSettings(device)}
                      onAiSettingsClick={() => editAiSettings(device)}
                      onPerchSettingsClick={() => editPerchSettings(device)}
                      onEditPlaylistClick={() => editPlaylist(device)}
                      onSelectPlaylistClick={() => selectPlaylist(device)}
                      onPublishClick={() => publishDevice(device)}
                      checked={selectedDeviceIds.includes(device.id)}
                      onCheckboxClick={toggleCheckbox}
                      batchStatus={getBatchActionStatus(
                        device.id,
                        batchOperationStatus,
                      )}
                    />
                  ))}
                </div>
              );
            })}
          </div>
        </Scrollable>

        {!isBatchMode && (
          <PaperModal
            color="lightGrey"
            className={cn(
              classes.modal,
              selectedPlaylistDevice && 'tour-select-playlist',
            )}
            open={isModalOpen}
            onClose={closeModal}
          >
            {selectedSettingsDevice && (
              <DeviceSettings
                key={selectedSettingsDevice.id} // Clear state when selecting another device.
                device={selectedSettingsDevice}
                lastLoadedDate={lastLoadedDate}
                onClose={closeModal}
                isVIOnly={isVIOnlyEnabled}
              />
            )}
            {selectedAiSettingsDevice && (
              <DeviceAISettings device={selectedAiSettingsDevice} />
            )}
            {selectedPerchSettingsDevice && (
              <DevicePerchSettings device={selectedPerchSettingsDevice} />
            )}
            {selectedPlaylistDevice && (
              <DevicePlaylistSelector
                device={selectedPlaylistDevice}
                selectedProfileId={selectedProfileId}
                onSelectProfile={setSelectedProfileId}
                onNewPlaylist={() => newPlaylist(selectedPlaylistDevice)}
                onNewPresentation={(applicationId) =>
                  newPresentation(selectedPlaylistDevice, applicationId)
                }
                onClose={closeModal}
              />
            )}
          </PaperModal>
        )}

        {showBatchSettings && !showBatchPlaylistSelector && (
          <PaperModal
            color="lightGrey"
            className={cn(
              classes.modal,
              selectedPlaylistDevice && 'tour-select-playlist',
              classes.batchSettingsModal,
            )}
            open={isModalOpen}
            onClose={closeBatchModal}
          >
            <DeviceBatchSettings
              devices={selectedDevicesResults}
              lastLoadedDate={lastLoadedDate}
              onPlaylistSelectorClick={handleBatchPlaylistSelectorClick}
              onDialogClick={handleBatchSettingsDialogClick}
              onClose={closeBatchModal}
            />
          </PaperModal>
        )}

        {showBatchSettings && showBatchPlaylistSelector && (
          <PaperModal
            color="lightGrey"
            className={cn(
              classes.modal,
              selectedPlaylistDevice && 'tour-select-playlist',
            )}
            open={isModalOpen}
            onClose={handleBatchPlaylistSelectorClick}
          >
            <DeviceBatchPlaylistSelector
              selectedProfileId={selectedProfileId}
              onSelectProfile={setSelectedProfileId}
              onClose={handleBatchPlaylistSelectorClick}
              onNewPlaylist={newBatchPlaylist}
            />
          </PaperModal>
        )}

        <LibraryDragLayer />
      </PageLayout>
    </Page>
  );
};

export default DevicesPage;
