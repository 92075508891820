import useProtectedQuery from './useProtectedQuery';
import { keys } from '../queryClient';
import raydiant from '../clients/miraClient';
import { ApiClientError, GetProductList } from '@raydiant/api-client-js';

export default function useMarketplaceApplication(grantScope: string) {
  const query = useProtectedQuery<GetProductList, ApiClientError>(
    keys.marketPlaceApplication(grantScope),
    () => raydiant.getProductList({ grant_scope: grantScope }),
  );

  const applicationData =
    query?.data?.response && query?.data?.response[0]?.productList;

  return {
    ...query,
    data: applicationData,
  };
}
