import { useTranslation } from 'react-i18next';
import useAnalyticsLocations from '../../../hooks/useAnalyticsLocations';
import SelectOption from '../../../components/Select/SelectOption';
import Select from '../../../components/Select/Select';
import classNames from 'classnames';

type LocationSelectProps = Omit<JSX.IntrinsicElements['select'], 'onChange'> & {
  onChange: (value: JSX.IntrinsicElements['select']['value']) => void;
  wrapperClassName?: string;
};

const LocationSelect = ({ onChange, ...props }: LocationSelectProps) => {
  const { t } = useTranslation();
  const { isLoading, data } = useAnalyticsLocations();
  const getPlaceholder = data?.find((item) => item.id === props.value);

  return (
    <Select
      className={props.className}
      label={t('analytics.location')!}
      onChange={(value) => onChange(value)}
      wrapperClassName={classNames('no-label', props.wrapperClassName)}
      renderAnchorElement={() => (
        <>{getPlaceholder?.name ?? t('analytics.allLocations')}</>
      )}
      {...props}
    >
      <SelectOption key="" value="" onClick={() => onChange('')}>
        {t('analytics.allLocations')}
      </SelectOption>
      {!isLoading &&
        data?.map((item, key) => (
          <SelectOption
            key={key}
            onClick={() => onChange(item.id)}
            value={item.id}
          >
            {item.name}
          </SelectOption>
        ))}
    </Select>
  );
};

export default LocationSelect;
