import { Tooltip } from '@material-ui/core';
import { withStyles } from 'raydiant-elements/styles';

const SidebarMenuTooltip = withStyles({
  arrow: {
    '&::before': {
      backgroundColor: '#343437',
    },
  },
  tooltip: {
    color: '#fff',
    backgroundColor: '#343437',
    fontSize: 14,
    padding: 12,
    fontWeight: 400,
    borderRadius: 16,
  },
})(Tooltip);

export default SidebarMenuTooltip;
