import { batchActions } from 'redux-batched-actions';
import { all, call, fork, put, take, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import {
  VirtualFolder,
  Domain,
  Profile,
  RecentDeviceError,
} from '@raydiant/api-client-js';
import * as folderActions from '../../actions/folders';
import * as domainActions from '../../actions/domains';
import * as deviceActions from '../../actions/devices';
import * as userActions from '../../actions/user';
import { pollDevicesWithPendingPublish } from '../../sagas/device';
import miraClient from '../../clients/miraClient';
import logger from '../../logger';
import * as devicePageActions from '../../pages/DevicesPage/actions';
import { selectLastLoadedDate } from './selectors';
import { selectUserProfile } from '../../selectors/user';
import * as actions from './actions';

export type LoadDevicesPageAction = ReturnType<typeof actions.loadDevicesPage>;

const watchLoadDevicesPage = function* () {
  while (true) {
    const action: LoadDevicesPageAction = yield take(
      getType(devicePageActions.loadDevicesPage),
    );
    const { query } = action.payload;

    try {
      let currentUser: Profile = yield select(selectUserProfile);

      while (currentUser === undefined) {
        yield take(getType(userActions.getProfileAsync.success));
        currentUser = yield select(selectUserProfile);
      }

      const isLocationEnabled = currentUser?.isLocationEnabled;

      yield put(devicePageActions.loadDevicesPageAsync.request());
      const [devicesFolder, deviceErrors, domain]: [
        VirtualFolder,
        RecentDeviceError[],
        Domain | null,
      ] = yield all([
        call(() =>
          isLocationEnabled
            ? miraClient.getV5DevicesFolder(query || '')
            : miraClient.getDevicesFolder(query || ''),
        ),
        // Turn off recent device errors until we can investigate the performance
        // issues happening in TimescaleDB.
        // call(() => miraClient.getRecentDeviceErrors()),
        call(() => []),
        call(() => miraClient.getDomain()),
      ]);

      yield put(
        batchActions([
          folderActions.fetchVirtualFolderAsync.success({
            id: 'devices',
            ...devicesFolder,
          }),
          deviceActions.fetchRecentDeviceErrorsAsync.success(deviceErrors),
          devicePageActions.loadDevicesPageAsync.success(),
          ...(domain ? [domainActions.fetchDomainAsync.success(domain)] : []),
        ]),
      );

      const deviceIds: string[] = [];

      devicesFolder.devices.forEach((device) => {
        if (device.resource.deletedAt === null) {
          deviceIds.push(device.id);
        }
      });

      const lastLoadedDate: string = yield select(selectLastLoadedDate);
      call(pollDevicesWithPendingPublish, deviceIds, lastLoadedDate);
    } catch (error: any) {
      logger.error(error);
      yield put(devicePageActions.loadDevicesPageAsync.failure(error));
    }
  }
};

export default all([fork(watchLoadDevicesPage)]);
