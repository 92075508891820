import { FC } from 'react';
import Select from 'raydiant-elements/core/Select';
import InputLabel from 'raydiant-elements/core/InputLabel';
import {
  ruleOperatorOptions,
  ruleOperators,
  RuleOperatorID,
  RuleSourceID,
} from './ruleTokenInputData';
import MenuItem from '@material-ui/core/MenuItem';
import Hidden from 'raydiant-elements/layout/Hidden/Hidden';
import useStyles from './RuleSelect.styles';
import {
  isAIRuleSource,
  isDateRuleSource,
  isGenderBasedRuleSource,
  isRXRuleSource,
} from './ruleSourceIdUtils';

export interface RuleOperatorSelectProps {
  label: string;
  value: RuleOperatorID | null;
  disabled?: boolean;
  onChange: (value: RuleOperatorID | null) => void;
  ruleSource?: RuleSourceID | null;
}

const numericComparators = [
  'greaterThan',
  'greaterThanOrEqual',
  'lessThan',
  'lessThanOrEqual',
];

const equalityComparators = ['equals', 'notEquals'];

const containsComparators = ['contains', 'notContains'];

const isContainsOperator = (operatorId: RuleOperatorID) =>
  containsComparators.includes(operatorId);
const isNumericOperator = (operatorId: RuleOperatorID) =>
  numericComparators.includes(operatorId);
const isEqualityOperator = (operatorId: RuleOperatorID) =>
  equalityComparators.includes(operatorId);

const RuleOperatorSelect: FC<RuleOperatorSelectProps> = ({
  label,
  value,
  disabled = false,
  onChange,
  ruleSource,
}) => {
  const classes = useStyles();

  const isMenuItemDisabled = (operatorId: RuleOperatorID): boolean => {
    if (!ruleSource) {
      return false;
    }

    if (isGenderBasedRuleSource(ruleSource))
      return !isEqualityOperator(operatorId);
    if (isAIRuleSource(ruleSource)) return isContainsOperator(operatorId);
    if (isRXRuleSource(ruleSource)) return !isEqualityOperator(operatorId);

    return (
      (!isDateRuleSource(ruleSource) && isNumericOperator(operatorId)) ||
      (isDateRuleSource(ruleSource) && isContainsOperator(operatorId))
    );
  };

  const renderDesktopMenu = () => (
    <Select
      classes={{
        select: classes.select,
      }}
      disabled={disabled}
      native={false}
      onChange={(value) => onChange(value ? (value as RuleOperatorID) : null)}
      value={value ?? ''}
    >
      {ruleOperatorOptions.map(renderMenuItem)}
    </Select>
  );

  const renderMobileMenu = () => (
    <Select
      disabled={disabled}
      native={true}
      onChange={(value) => onChange(value ? (value as RuleOperatorID) : null)}
      value={value ?? ''}
    >
      <option value=""></option>
      {ruleOperatorOptions.map(renderMobileMenuItem)}
    </Select>
  );

  const renderMenuItem = (operatorId: RuleOperatorID) => (
    <MenuItem
      disabled={isMenuItemDisabled(operatorId)}
      key={operatorId}
      value={operatorId}
      selected={operatorId === value}
      onClick={() => {
        onChange(operatorId);
      }}
    >
      {ruleOperators[operatorId].label}
    </MenuItem>
  );

  const renderMobileMenuItem = (operatorId: RuleOperatorID) => (
    <option
      disabled={isMenuItemDisabled(operatorId)}
      key={operatorId}
      value={operatorId}
    >
      {ruleOperators[operatorId].label}
    </option>
  );

  return (
    <>
      <InputLabel className={classes.root} disabled={disabled}>
        {label}
      </InputLabel>
      <Hidden xsDown>{renderDesktopMenu()}</Hidden>
      <Hidden smUp>{renderMobileMenu()}</Hidden>
    </>
  );
};

export default RuleOperatorSelect;
