import {
  ApiClientError,
  GetAnalyticsLocationsResponse,
} from '@raydiant/api-client-js';
import { keys } from '../queryClient';
import raydiant from '../clients/miraClient';
import useProtectedQuery from './useProtectedQuery';

export default function useAnalyticsLocations() {
  const query = useProtectedQuery<
    GetAnalyticsLocationsResponse,
    ApiClientError
  >(keys.analyticsLocations(), async () => raydiant.getAnalyticsLocations());

  return query;
}
