import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: 0,
      textAlign: 'center',
    },
    select: {
      display: 'block',
      lineHeight: '38px',
    },

    ruleOperatorSelectWrapper: {
      flexGrow: 0,
      flexBasis: '18%',
      maxWidth: '18%',
    },

    selectWrapper: {
      flexGrow: 0,
      flexBasis: '20%',
      maxWidth: '20%',
    },

    menuItem: {
      display: 'flex',
      justifyContent: 'space-between',
      minWidth: '250px',
    },

    menuLabel: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
  }),
);
