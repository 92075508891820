import { SVGProps } from 'react';

interface WifiProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const Wifi = ({ size = 18, ...props }: WifiProps) => {
  return (
    <svg
      width="23"
      height="16"
      viewBox="0 0 23 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.29492 5.30078C1.96289 5.59961 1.46484 5.56641 1.19922 5.23438C0.900391 4.90234 0.933594 4.4043 1.26562 4.10547C4.02148 1.68164 7.64062 0.1875 11.625 0.1875C15.5762 0.1875 19.1953 1.68164 21.9512 4.10547C22.2832 4.4043 22.3164 4.90234 22.0508 5.23438C21.752 5.56641 21.2539 5.59961 20.9219 5.30078C18.4316 3.10938 15.1777 1.78125 11.625 1.78125C8.03906 1.78125 4.78516 3.10938 2.29492 5.30078ZM11.625 7.625C9.4668 7.625 7.50781 8.45508 6.04688 9.81641C5.71484 10.1152 5.2168 10.082 4.91797 9.75C4.61914 9.41797 4.65234 8.91992 4.95117 8.62109C6.71094 7.02734 9.03516 6.03125 11.625 6.03125C14.1816 6.03125 16.5059 7.02734 18.2656 8.62109C18.5645 8.91992 18.5977 9.41797 18.2988 9.75C18 10.082 17.502 10.1152 17.1699 9.81641C15.709 8.45508 13.75 7.625 11.625 7.625ZM13.4844 13.2031C13.4844 13.9004 13.1191 14.498 12.5547 14.8301C11.957 15.1621 11.2598 15.1621 10.6953 14.8301C10.0977 14.498 9.76562 13.9004 9.76562 13.2031C9.76562 12.5391 10.0977 11.9414 10.6953 11.6094C11.2598 11.2773 11.957 11.2773 12.5547 11.6094C13.1191 11.9414 13.4844 12.5391 13.4844 13.2031Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Wifi;
