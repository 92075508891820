import Card from '@raydiant/raydial/components/Card';
import Page from '../../components/Page';
import PageLayout from '../../components/PageLayout/PageLayout';
import PageTitle from '../../components/PageLayout/PageTitle';
import TextField from '@raydiant/raydial/components/TextField';
import Button from '@raydiant/raydial/components/Button';
import history from '../../history';
import * as paths from '../../routes/paths';
import { useState } from 'react';
import { Footer, Header, LocationGuidTextFields } from './components';
import { useToastState } from '@raydiant/raydial/components/Toast/ToastProvider';
import LoadingModal from '@raydiant/raydial/components/LoadingModal';
import Text from '@raydiant/raydial/components/Text';
import useUpdateConnection from '../../hooks/useUpdateConnection';
import useDeleteConnection from '../../hooks/useDeleteConnection';
import Modal from '@raydiant/raydial/components/Modal';
import Dialog, {
  DialogHeader,
  DialogHeading,
} from '@raydiant/raydial/components/Dialog';
import { Connection } from '@raydiant/api-client-js';

type ProductHubEditToastIntegrationProps = {
  connection: Connection;
};

const ProductHubEditToastIntegrationPage = (
  props: ProductHubEditToastIntegrationProps,
) => {
  const state = useToastState();
  const { connection } = props;
  const settings = connection.settings as { restaurantGuids: string[] };
  const [formData, setFormData] = useState({
    name: connection.name,
    locationGuids: settings.restaurantGuids as string[],
  });
  const [
    showDeleteIntegrationConfirmationModal,
    setShowDeleteIntegrationConfirmationModal,
  ] = useState<1 | 2 | undefined>();
  const [showEditConfirmationModal, setShowEditConfirmationModal] =
    useState(false);
  const [deleteConfirmationInput, setDeleteConfirmationInput] = useState('');
  const updateConnectionQuery = useUpdateConnection();
  const deleteConnectionQuery = useDeleteConnection();
  const { mutateAsync: updateConnection } = updateConnectionQuery;
  const { mutateAsync: deleteConnection } = deleteConnectionQuery;
  const isValid = formData.name.length > 0;
  const isDeleteConfirmationValid =
    deleteConfirmationInput.toUpperCase() === 'DELETE';

  if (updateConnectionQuery.isLoading || deleteConnectionQuery.isLoading) {
    return (
      <Page>
        <LoadingModal isOpen={true} />
      </Page>
    );
  }

  const editConnection = async () => {
    await updateConnection({
      id: connection.id,
      params: {
        name: formData.name,
        settings: {
          restaurantGuids: formData.locationGuids,
        },
      },
    });

    state.add({
      title: 'Integration Updated',
      description: 'Integration settings have been updated successfully',
    });
    history.push(paths.productHubIntegrations());
  };

  const handleSubmitConfirmationModal = async (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();
    editConnection();
  };

  // const handleDeleteIntegration = async () => {
  //   setWarningModalStep(1);
  // };

  const handleOnNameChange = (name: string) => {
    setFormData({ ...formData, name });
  };

  const handleOnLocationGuidsChange = (locationGuids: Array<string>) => {
    setFormData({ ...formData, locationGuids });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const guidRemoved = !settings.restaurantGuids.every((g) =>
      formData.locationGuids.includes(g),
    );

    if (guidRemoved) {
      setShowEditConfirmationModal(true);
      return;
    }

    editConnection();
  };

  const renderEditConfirmationModal = () => {
    const onCloseConfirmationModal = () => {
      setShowEditConfirmationModal(false);
    };

    return (
      <Modal
        isOpen={showEditConfirmationModal}
        onOpenChange={onCloseConfirmationModal}
      >
        <Dialog showCloseButton>
          <form onSubmit={handleSubmitConfirmationModal}>
            <DialogHeader>
              <DialogHeading>Confirm changes</DialogHeading>
            </DialogHeader>

            <div className="px-4 mb-8 flex mt-4 flex-col">
              <Text className="mb-4">
                Removing location GUIDs may affect your presentations and
                content playing on your devices and kiosks.
              </Text>
              <Text>Are you sure you want to continue?</Text>
            </div>

            <footer className="flex flex-wrap gap-6 pb-4 px-4">
              <Button
                size="large"
                variant="secondary"
                className="grow"
                onClick={onCloseConfirmationModal}
              >
                Cancel
              </Button>
              <Button
                size="large"
                variant="primary"
                className="grow"
                type="submit"
              >
                Confirm
              </Button>
            </footer>
          </form>
        </Dialog>
      </Modal>
    );
  };

  const renderDeleteIntegrationConfirmationModal = () => {
    const onSubmitStep1WarningModal = async () => {
      setShowDeleteIntegrationConfirmationModal(2);
    };

    const onSubmitStep2WarningModal = async () => {
      await deleteConnection(connection.id);
      setShowDeleteIntegrationConfirmationModal(undefined);
      state.add({
        title: 'Integration Deleted',
        description: 'Toast integration deleted successfully',
      });
      history.push(paths.productHubCatalogs());
    };

    const onCloseWarningModal = () => {
      setShowDeleteIntegrationConfirmationModal(undefined);
    };

    const showWarningModal =
      showDeleteIntegrationConfirmationModal &&
      showDeleteIntegrationConfirmationModal > 0;

    return (
      <Modal isOpen={showWarningModal} onOpenChange={onCloseWarningModal}>
        <Dialog showCloseButton>
          {showDeleteIntegrationConfirmationModal === 1 && (
            <form onSubmit={onSubmitStep1WarningModal}>
              <DialogHeader>
                <DialogHeading>Confirm delete integration</DialogHeading>
              </DialogHeader>

              <div className="px-4 mb-8 flex mt-4 flex-col">
                <Text className="mb-4">
                  Deleting the ‘{connection.name}’ integration may affect your
                  presentations and content playing on your devices and kiosks.
                </Text>
                <Text>
                  Are you sure you want to delete this integration? This cannot
                  be undone.
                </Text>
              </div>

              <footer className="flex flex-wrap gap-6 pb-4 px-4">
                <Button
                  size="large"
                  variant="secondary"
                  className="grow"
                  onClick={onCloseWarningModal}
                >
                  Cancel
                </Button>
                <Button
                  size="large"
                  variant="primary"
                  className="grow"
                  onClick={onSubmitStep1WarningModal}
                  type="submit"
                >
                  Next
                </Button>
              </footer>
            </form>
          )}

          {showDeleteIntegrationConfirmationModal === 2 && (
            <form onSubmit={onSubmitStep2WarningModal}>
              <DialogHeader>
                <DialogHeading>Confirm delete integration</DialogHeading>
              </DialogHeader>

              <div className="px-4 mb-8 flex mt-4 flex-col">
                <Text as="p" className="mb-4">
                  To proceed, please type DELETE below:
                </Text>
                <TextField
                  placeholder="Enter DELETE"
                  aria-label="confirm delete"
                  value={deleteConfirmationInput}
                  onChange={setDeleteConfirmationInput}
                />
              </div>

              <footer className="flex flex-wrap gap-6 pb-4 px-4">
                <Button
                  size="large"
                  variant="secondary"
                  className="grow"
                  onClick={onCloseWarningModal}
                >
                  Cancel
                </Button>
                <Button
                  size="large"
                  variant="primary"
                  className="grow"
                  onClick={onSubmitStep1WarningModal}
                  type="submit"
                  isDisabled={!isDeleteConfirmationValid}
                >
                  Delete Integration
                </Button>
              </footer>
            </form>
          )}
        </Dialog>
      </Modal>
    );
  };

  return (
    <Page>
      <PageLayout title={<PageTitle title="Integrations" />}>
        {renderDeleteIntegrationConfirmationModal()}
        {renderEditConfirmationModal()}
        <Card className="text-black max-w-[628px]">
          <Header
            heading="Edit Integration Settings"
            subheading="Manage Toast integration location GUIDs"
          />
          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <TextField
                label="Integration Name"
                placeholder="Enter integration name"
                description="This could be your restaurant or brand, eg: The Burger Barn"
                onChange={handleOnNameChange}
                value={formData.name}
                aria-label="integration name"
                autoFocus
              />
            </div>

            <Text className="mx-4 mb-4" as="p">
              Add or delete location GUIDs. Updates will be pushed to your
              catalogs and kiosks.
            </Text>

            <LocationGuidTextFields
              locationGuids={formData.locationGuids}
              onChange={handleOnLocationGuidsChange}
              disabledGuids={settings.restaurantGuids}
            />

            <Footer>
              {/* TODO: Implement delete functionality later */}
              {/* <Button
                size="large"
                variant="caution"
                className="flex-1"
                onPress={handleDeleteIntegration}
              >
                Delete Integration
              </Button> */}
              <Button
                size="large"
                variant="primary"
                className="flex-1"
                type="submit"
                isDisabled={!isValid}
              >
                Save
              </Button>
            </Footer>
          </form>
        </Card>
      </PageLayout>
    </Page>
  );
};

export default ProductHubEditToastIntegrationPage;
