import Heading5 from 'raydiant-elements/typography/Heading5';
import Column from 'raydiant-elements/layout/Column';
import Text from 'raydiant-elements/core/Text';
import Button from 'raydiant-elements/core/Button';
import Link from 'raydiant-elements/core/Link';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import config from '../../config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: '420px',

      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },

    button: {
      width: '250px',

      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },

    buttonText: {
      color: '#003670;',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  }),
);

const MemberAccountsV2 = () => {
  const classes = useStyles();

  return (
    <>
      <Heading5 overline>Member Accounts</Heading5>
      <Column>
        <Text large bold>
          Your members list has moved to the Account Console.
        </Text>
        <Text small className={classes.content}>
          You can now invite, view and manage all your users from the new Users
          tab in the Account Console!
        </Text>
        <br />
        <Button className={classes.button} color="progress" onClick={() => {}}>
          <Link
            className={classes.buttonText}
            href={`${config.accountManagementUrl}/users`}
            target="_blank"
          >
            Go to new Users page
          </Link>
        </Button>
      </Column>
    </>
  );
};

export default MemberAccountsV2;
