import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import Button from 'raydiant-elements/core/Button';
import Heading from 'raydiant-elements/core/Heading';
import Spacer from 'raydiant-elements/layout/Spacer';
import Text from 'raydiant-elements/typography/Text';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      color: theme.palette.primary.main,
      fontSize: theme.spacing(3),
      fontWeight: 500,
    },
    button: {
      minWidth: '8rem',
    },
  }),
);

interface MarektplaceDialogProps {
  content?: string;
  heading?: string;
  open: boolean;
  onClickButtonText?: string;
  onCloseButtonText?: string;
  handleClick: () => void;
  handleClose?: () => void;
}

const MarketplaceModal = ({
  content,
  heading,
  open,
  onClickButtonText = 'Okay',
  onCloseButtonText = 'Cancel',
  handleClick,
  handleClose,
}: MarektplaceDialogProps) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogContent>
        <Heading className={classes.heading}>{heading}</Heading>
        <DialogContentText id="alert-dialog-description">
          <Text>{content}</Text>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {handleClose && (
          <Button
            className={classes.button}
            onClick={() => handleClose()}
            color="default"
          >
            {onCloseButtonText}
          </Button>
        )}
        <Spacer />
        <Button
          className={classes.button}
          onClick={() => handleClick()}
          color="primary"
        >
          {onClickButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MarketplaceModal;
