import { FC, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Application, PurchaseProductRequest } from '@raydiant/api-client-js';
import Paper from 'raydiant-elements/core/Paper';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import apiClient from '../../clients/miraClient';
import {
  selectUserProfile,
  selectHasAccessToInvoices,
  selectIsEnterpriseUser,
} from '../../selectors/user';
import useMarketPlaceApplications from '../../hooks/useMarketPlaceApplications';
import useOutstandingBalance from '../../hooks/useOutstandingBalance';
import Page from '../../components/Page';
import Grid from '../../components/Grid';
import MarketplaceLayout from '../../components/MarketplaceLayout';
import MarketplaceAppDialog from '../../components/MarketplaceAppDialog';
import * as applicationsPageActions from '../ApplicationsPage/actions';
import {
  sendFreetrialRequest,
  getApplicationName,
} from './MarketplacePageUtils';
import { MarketPlaceInquiry } from './MarketplacePage.interface';
import FreetrialModal from './components/FreetrialModal';
import OutstandingInvoicesDialog from './components/OutstandingInvoicesDialog';
import ApplicationsLayout from './components/ApplicationsLayout';
import PurchaseProductModal from './components/PurchaseProductModal';
import ManualSetUpModal from './components/ManualSetUpModal';
import PurchaseSuccessModal from './components/PurchaseSuccessModal';
import useStyles from './MarketplacePage.styles';
import { LoadingStatus } from '../../components/LoadingButton/LoadingButton';
import { useSnackbar } from 'notistack';
import CustomPricingModal from './components/CustomPricingModal';
import ReachOutToSalesModal from './components/ReachOutToSalesModal';

export interface MarketplaceApplication extends Application {
  checked?: boolean;
}

const MarketplaceV2Page: FC = () => {
  const [selectedModal, setSelectedModal] = useState<string | null>(null);
  const [selectedApplication, setSelectedApplication] =
    useState<Application | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [submitStatus, setSubmitStatus] = useState<LoadingStatus>('idle');

  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useSelector(selectUserProfile);
  const hasAccessToInvoices = useSelector(selectHasAccessToInvoices);
  const isEnterpriseUser = useSelector(selectIsEnterpriseUser);

  const { data: applicationsData, isLoading: isApplicationsLoading } =
    useMarketPlaceApplications();
  const {
    data: outstandingBalanceData,
    isLoading: isOutstandingBalanceDataLoading,
  } = useOutstandingBalance();

  const isLoading = isApplicationsLoading || isOutstandingBalanceDataLoading;
  const isTileModalSelected = [
    selectedModal === 'dx',
    selectedModal === 'ex',
    selectedModal === 'cx',
    selectedModal === 'ai',
    selectedModal === 'tiktok',
  ].includes(true);
  const isShowReachOutToSalesModal = !!selectedModal?.split('-')[1];
  const tileModalName = selectedModal?.split('-')[0] || '';

  const loadApplications = useCallback(() => {
    dispatch(applicationsPageActions.loadApplicationsPage());
  }, [dispatch]);

  useEffect(() => {
    loadApplications();
  }, [loadApplications]);

  useEffect(() => {
    const handleSegmentMarketplaceLoad = () => {
      analytics.track('Marketplace Page Loaded', {
        user_id: currentUser?.id,
        user_name: currentUser?.name,
      });
    };

    handleSegmentMarketplaceLoad();
  }, [currentUser]);

  if (!currentUser) return null;

  const isRaydiantEmployee = currentUser.email.includes('@raydiant.com');
  const isOutstandingBalanceUser =
    outstandingBalanceData && outstandingBalanceData.hasOutstandingInvoices;
  const isFreeTrialUser = currentUser.isFreetrialUser && !isRaydiantEmployee;
  const determineUserStatus = () => {
    if (isOutstandingBalanceUser) {
      return 'outstandingInvoices';
    } else if (isFreeTrialUser) {
      return 'freeTrial';
    } else if (isEnterpriseUser) {
      return 'enterpriseUser';
    } else {
      return 'regular';
    }
  };
  const userStatus = determineUserStatus();
  const cta =
    selectedApplication?.currentAppVersion?.name || selectedApplication?.name;

  const sendMarketPlaceInquiry = async (params: MarketPlaceInquiry) => {
    try {
      await apiClient.sendMarketPlaceInquiry(params);
    } catch (err) {
      console.error(err);
      setError('Something went wrong. Please try again.');
    }
  };

  const handleSegmentMarketplaceAppGetPressed = (selected: string) =>
    analytics.track('Marketplace App Get Button Pressed', {
      user_id: currentUser.id,
      user_name: currentUser.name,
      app_name: selected,
    });

  const handleSegmentMarketplaceTileDetailPressed = (selected: string) =>
    analytics.track('Marketplace Tile Details Loaded', {
      user_id: currentUser.id,
      user_name: currentUser.name,
      tile_name: selected,
    });
  const handleSegmentMarketplaceTileGetPressed = (selected: string) =>
    analytics.track('Marketplace Tile Get Button Pressed', {
      user_id: currentUser.id,
      user_name: currentUser.name,
      tile_name: selected,
    });

  const handleSegmentMarketplaceGetConfirmed = (selected: string) =>
    analytics.track('Marketplace Get Confirmed ', {
      user_id: currentUser.id,
      app_name: selected,
    });

  const handleSegmentMarketplaceAppPurchased = (selected: string) =>
    analytics.track('Marketplace App Purchased ', {
      user_id: currentUser.id,
      app_name: selected,
    });

  const handleDialogClick = (selected: string) => {
    handleSegmentMarketplaceGetConfirmed(selected);
    sendMarketPlaceInquiry({ app: selected });
  };

  const handleFreetrialDialogClick = () => {
    if (selectedApplication) {
      handleSegmentMarketplaceAppGetPressed(
        getApplicationName(selectedApplication),
      );
    }
    if (sendFreetrialRequest()) {
      sendMarketPlaceInquiry({ app: 'Permission Update' });
    }
  };

  const handleTileModalInitialClick = (tileName: string) => {
    handleSegmentMarketplaceTileDetailPressed(tileName);
    setSelectedModal(tileName);
  };

  const handleTileModalReachOutToSalesClick = () => {
    if (selectedModal) {
      handleSegmentMarketplaceTileGetPressed(selectedModal);
      setSelectedModal(`${selectedModal}-open`);
    }
  };

  const handleAppCardBuyButtonClick = (application: Application) => {
    handleSegmentMarketplaceAppGetPressed(getApplicationName(application));
    setSelectedApplication(application);
    setSelectedModal('purchaseProduct');
  };

  const handleClose = () => {
    setError(null);
    setSelectedModal(null);
    setSelectedApplication(null);
  };

  const handleOutstandingInvoicesClick = () => {
    if (hasAccessToInvoices) {
      window.open(`${process.env.REACT_APP_ACCOUNT_MANAGEMENT_URL}/invoices`);
    }
    handleClose();
  };

  const handleCustomPricing = () => {
    sendMarketPlaceInquiry({ app: `Tailor Pricing Needed. app: ${cta}` });
    handleClose();
  };

  const openCustomPricingModal = () => {
    setSelectedModal('customPricing');
  };

  const handleReachOutToSalesClick = () => {
    handleFreetrialDialogClick();
    handleClose();
  };

  const handlePurchaseProductModalClick = async (
    orderDetail: PurchaseProductRequest,
    manualEnablementRequired: boolean,
  ) => {
    try {
      setSubmitStatus('loading');

      const { response } = await apiClient.purchaseProduct(orderDetail);
      setSubmitStatus('success');

      if (manualEnablementRequired) {
        setSelectedModal('manualSetUp');
        sendMarketPlaceInquiry({
          app: `Manual Setup Needed. quoteId: ${response.quoteId}`,
        });
      } else {
        setSelectedModal('purchaseSuccess');
      }

      selectedApplication &&
        handleSegmentMarketplaceAppPurchased(
          selectedApplication.currentAppVersion.name ||
            selectedApplication.name,
        );
      enqueueSnackbar('Successfully purchased an application', {
        variant: 'success',
      });
    } catch (error) {
      console.log('ERROR PURCHASING MARKETPLACE APPLICATION', error);

      enqueueSnackbar('There was an error purchasing an application', {
        variant: 'error',
      });

      setSubmitStatus('idle');
    }
  };

  return (
    <Page>
      <Paper color="light" className={classes.paper}>
        <Scrollable>
          <Grid>
            <MarketplaceLayout
              open={isTileModalSelected}
              selected={selectedModal}
              onTileClick={handleTileModalInitialClick}
              onClick={handleTileModalReachOutToSalesClick}
              onClose={handleClose}
            />
            <ApplicationsLayout
              applications={applicationsData}
              isLoading={isLoading}
              onClick={handleAppCardBuyButtonClick}
            />
            {selectedApplication && (
              <>
                {userStatus === 'freeTrial' && (
                  <FreetrialModal
                    open={userStatus === 'freeTrial'}
                    onClick={handleFreetrialDialogClick}
                    onClose={handleClose}
                  />
                )}

                {userStatus === 'enterpriseUser' && (
                  <ReachOutToSalesModal
                    open={userStatus === 'enterpriseUser'}
                    onClick={handleReachOutToSalesClick}
                  />
                )}

                {userStatus === 'outstandingInvoices' && (
                  <OutstandingInvoicesDialog
                    open={userStatus === 'outstandingInvoices'}
                    hasPermission={hasAccessToInvoices}
                    onClick={handleOutstandingInvoicesClick}
                    onCancel={handleClose}
                  />
                )}

                {userStatus === 'regular' && (
                  <>
                    <PurchaseProductModal
                      open={selectedModal === 'purchaseProduct'}
                      selectedApplication={selectedApplication}
                      submitStatus={submitStatus}
                      onClick={handlePurchaseProductModalClick}
                      onCustomPricing={openCustomPricingModal}
                      onClose={handleClose}
                    />
                    <ManualSetUpModal
                      open={selectedModal === 'manualSetUp'}
                      onClick={handleClose}
                    />
                    <PurchaseSuccessModal
                      open={selectedModal === 'purchaseSuccess'}
                      onClick={handleClose}
                    />
                    {cta && (
                      <CustomPricingModal
                        open={selectedModal === 'customPricing'}
                        appName={cta}
                        onClick={handleCustomPricing}
                        onClose={handleClose}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </Grid>
          <MarketplaceAppDialog
            open={isShowReachOutToSalesModal}
            handleClick={handleDialogClick}
            handleClose={handleClose}
            name={tileModalName}
            error={error}
          />
        </Scrollable>
      </Paper>
    </Page>
  );
};

export default MarketplaceV2Page;
