import Heading from 'raydiant-elements/core/Heading';
import Column from 'raydiant-elements/layout/Column';
import { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Device, Tag } from '@raydiant/api-client-js';
import * as deviceActions from '../../actions/devices';
import { hasValidTags } from '../../utilities/tagUtils';
import TagManager from '../../components/TagManager';

interface DeviceTagsProps {
  device: Device;
}

const DeviceTags = ({ device }: DeviceTagsProps) => {
  const dispatch = useDispatch();

  const [tags, setTags] = useState(device.resource.r.tags);

  // Callbacks

  const handleUpdateDeviceTags = useCallback(
    (tags: Tag[]) => {
      setTags(tags);

      // Update the device tags if they are valid.
      if (hasValidTags(tags)) {
        dispatch(
          deviceActions.updateDevice(
            {
              // Unfortunately the updateDevice action expects the full device object so it
              // can update the redux state. It would be nicer if it accepted a partial device
              // but since we are moving away from redux (replaced by react-query) I'm reluctant
              // to invest a bunch of time refactoring it.
              ...device,
              resource: {
                ...device.resource,
                r: {
                  ...device.resource.r,
                  tags,
                },
              },
            },
            {},
          ),
        );
      }
    },
    [dispatch, device],
  );

  // Effects

  // Update state when device changes.
  useEffect(() => {
    setTags(device.resource.r.tags);
  }, [device]);

  // Render

  return (
    <Column>
      <Heading size={5} overline gutterBottom>
        Tags
      </Heading>
      <TagManager
        tags={tags}
        hideSchedulingTags={true}
        onChange={handleUpdateDeviceTags}
      />
    </Column>
  );
};

export default DeviceTags;
