import {
  selectIsEnterpriseUser,
  selectUserProfile,
} from '../../selectors/user';
import { useSelector } from 'react-redux';
import useCurrentUserRoles from '../../hooks/useCurrentUserRoles';
import { useTranslation } from 'react-i18next';
import { useOutstandingBalance } from '../../hooks';

const OutstandingInvoiceBanner = () => {
  const { data, isLoading } = useOutstandingBalance();
  const { t } = useTranslation();

  const isEnterpriseUser = useSelector(selectIsEnterpriseUser);
  const roles = useCurrentUserRoles();
  const currentUser = useSelector(selectUserProfile);

  const hideViewButton = !roles.exAdmin && isEnterpriseUser;

  const isCurrentUserBillToContact =
    data?.billToContactEmail === currentUser?.email;

  const showInvoiceBanner = !isLoading && data?.hasOutstandingInvoices;

  const renderBannerText = () => {
    if (hideViewButton) {
      return isCurrentUserBillToContact
        ? t('banner.billToContact')
        : t('banner.outstandingInvoiceNoPermission');
    } else {
      return t('banner.outstandingInvoice');
    }
  };

  if (!showInvoiceBanner) return null;
  return (
    <>
      <div className="bg-orange-200 p-2 flex items-center justify-center gap-1">
        <span className="text-lg font-semibold text-white px-2.5">
          {renderBannerText()}
        </span>
        <button
          className="bg-white rounded-3xl flex justify-center items-center h-6 w-11"
          onClick={() =>
            window.open(
              `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_URL}/invoices`,
            )
          }
        >
          <span className="text-sm font-bold text-orange-600 px-2 py-1">
            {t('banner.view')}
          </span>
        </button>
      </div>
    </>
  );
};

export default OutstandingInvoiceBanner;
