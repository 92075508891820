import { useTranslation } from 'react-i18next';
import ScreensFilterControl from '../../ScreensFilterControl';
import ScreensFilterFooterAction from '../../ScreensFilterFooterAction';
import { Playlist } from '@raydiant/api-client-js';
import ScreensFilterListItem from '../../ScreensFilterListItem';
import { useSelector } from 'react-redux';
import { selectSearchedLibraryByOwner } from '../../../../../selectors/v2/folders';
import { useCallback } from 'react';

type PlaylistSelectorProps = {
  onChange: (value: Playlist['id']) => void;
  onShowPlaylistLibrary: () => void;
  onHidePlaylistLibrary: () => void;
  value: Playlist['id'];
  playlist?: Playlist;
};

const PlaylistSelector = ({
  onChange,
  onShowPlaylistLibrary,
  onHidePlaylistLibrary,
  playlist,
  value,
}: PlaylistSelectorProps) => {
  const { t } = useTranslation(['devices']);

  const libraryByOwner = useSelector(selectSearchedLibraryByOwner);

  const getPlaylist = useCallback(() => {
    if (!value) return null;
    const hasPlaylist = Object.values(libraryByOwner).find((library) =>
      library.playlists.some((playlist) => playlist.id === value),
    );
    if (!hasPlaylist) return null;

    return hasPlaylist.playlists.find((playlist) => playlist.id === value);
  }, [libraryByOwner, value]);

  return (
    <ScreensFilterControl
      title={<p className="font-[500] text-[16px]">{t('screens.playlists')}</p>}
      footer={
        value && (
          <ul className="flex items-center divide-x divide-black text-sm text-primary">
            <li className="pr-2">
              <ScreensFilterFooterAction
                label={t('screens.clearPlaylist')}
                onClick={() => {
                  onChange('');
                  onHidePlaylistLibrary();
                }}
              />
            </li>
          </ul>
        )
      }
    >
      {
        <button
          onClick={() => onShowPlaylistLibrary()}
          className="flex items-center p-4 text-[#707070] rounded-2xl justify-between bg-[#f3f4f6] h-[40px]"
        >
          {t('screens.selectPlaylist')}
          <svg
            className="w-4 h-4"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M32 48c0 8.8 7.2 16 16 16H64V192H32c-8.8 0-16 7.2-16 16s7.2 16 16 16h96c8.8 0 16-7.2 16-16s-7.2-16-16-16H96V48c0-8.8-7.2-16-16-16H48c-8.8 0-16 7.2-16 16zM192 96c0 8.8 7.2 16 16 16H496c8.8 0 16-7.2 16-16s-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm0 160c0 8.8 7.2 16 16 16H496c8.8 0 16-7.2 16-16s-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm0 160c0 8.8 7.2 16 16 16H496c8.8 0 16-7.2 16-16s-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zM58.7 323.9c11.1-11.1 29.2-10.5 39.6 1.3c9.2 10.5 8.9 26.2-.6 36.3L12.3 453.1c-4.3 4.7-5.5 11.5-3 17.3s8.3 9.6 14.7 9.6H128c8.8 0 16-7.2 16-16s-7.2-16-16-16H60.8l60.3-64.6c20.7-22.2 21.2-56.4 1.3-79.3c-22.5-25.7-62.1-27.1-86.3-2.9L20.7 316.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l15.4-15.4z" />
          </svg>
        </button>
      }
      {value && (
        <ul>
          <ScreensFilterListItem
            key={value}
            label={
              <div className="flex justify-between w-full">
                <p>{playlist?.name ?? getPlaylist()?.name ?? ''}</p>
              </div>
            }
            onClear={() => onChange('')}
          />
        </ul>
      )}
    </ScreensFilterControl>
  );
};

export default PlaylistSelector;
