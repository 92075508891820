import { Grid } from '@material-ui/core';
import cn from 'classnames';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import Heading from 'raydiant-elements/core/Heading';
import Text from 'raydiant-elements/core/Text';

import ImageContentCard from './components/ImageContentCard';
import ContentCard from './components/ContentCard';
import ColorCard from './components/ColorCard';
import { Hidden } from 'raydiant-elements/layout/Hidden/Hidden';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      paddingBottom: theme.spacing(2),
      color: '#000000',
    },
    box: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      overflow: 'hidden',
    },
    case: {
      color: '#28d972',
    },
    lastItem: {
      paddingBottom: 'unset',
    },
    whiteText: {
      color: '#ffffff',
    },
    centerText: {
      textAlign: 'center',
    },
    testimonal: {
      [theme.breakpoints.up('sm')]: {
        paddingBottom: theme.spacing(4),
      },
    },
    list: {
      padding: '1rem',
      marginBlockStart: 'unset',
      marginBlockEnd: 'unset',
    },
  }),
);

const CXModalContent = () => {
  const classes = useStyles();

  const renderDesktopContactSales = () => {
    return (
      <Grid item>
        Rules & Tags are available as part of the Raydiant Enterprise plan.
        <br />
        Reach out to Sales today to see how it can help your business lower
        <br />
        costs and increase efficiency.
      </Grid>
    );
  };

  const renderMobileContactSales = () => {
    return (
      <Grid item>
        Rules & Tags are available as part of the Raydiant Enterprise plan.
        Reach out to Sales today to see how it can help your business lower
        costs and increase efficiency.
      </Grid>
    );
  };

  return (
    <Grid>
      <ColorCard type="cx">
        <ContentCard>
          <Heading
            size={2}
            weight={600}
            className={cn(classes.heading, classes.whiteText)}
          >
            “Game-changing! Rules & Tags redefined the way we manage and deploy
            content to Raydiant screens.”
          </Heading>
          <Heading
            size={2}
            weight={500}
            className={cn(classes.heading, classes.whiteText)}
          >
            - Theresa Anthony, Vice President of Sales & Marketing, Rockford
            Fosgate
          </Heading>
        </ContentCard>
      </ColorCard>

      <ContentCard>
        <Text>
          In 1973, Jim Fosgate developed the PR-7000 amplifier, the first
          consumer product which contained a Frequency Energizer circuit that
          compensated for acoustical anomalies commonly found in automotive
          environments. Since then, Rockford Fosgate has expanded its product
          line to include marine, motorsport, and motorcycle audio equipment,
          establishing a brand synonymous with quality.
        </Text>
      </ContentCard>

      <ImageContentCard
        className={classes.img}
        imageSrc="https://assets.raydiant.com/marketplace/cx-modal-1.jpeg"
        imageAlt="cx-modal-1"
        imageDirectionOnDesktop="left"
        imageDirectionOnMobile="top"
      >
        <ContentCard>
          <Text>
            From its inception, technology was key to Rockford’s success. This
            would again prove to be the case for a successful digital signage
            rollout. With over six hundred dealerships – each catering to
            different vehicles – standard content management and deployment
            technologies just weren’t going to cut it.
          </Text>
        </ContentCard>
      </ImageContentCard>

      <ImageContentCard
        className={classes.img}
        imageSrc="https://assets.raydiant.com/marketplace/cx-modal-2.png"
        imageAlt="cx-modal-2"
        imageDirectionOnDesktop="right"
        imageDirectionOnMobile="top"
      >
        <ContentCard>
          <Text>
            Rules & Tags changed the game for Rockford, enabling them to operate
            Raydiant screens within a tight budget and decrease deployment
            issues resulting from human error.
          </Text>
        </ContentCard>
      </ImageContentCard>

      <ColorCard type="cx">
        <ContentCard className={classes.box}>
          <Heading
            size={2}
            weight={600}
            className={cn(classes.heading, classes.whiteText)}
          >
            How does this work?
          </Heading>
          <Heading
            size={2}
            weight={300}
            className={cn(
              classes.heading,
              classes.whiteText,
              classes.centerText,
            )}
          >
            Rules & Tags let you efficiently deploy content that varies across
            <br />
            locations, projects, campaigns, etc, by introducing context aware
            <br />
            playback, significantly reducing the number of playlists you need to
            <br />
            manage and deploy.
          </Heading>
        </ContentCard>
      </ColorCard>

      <ContentCard>
        <Grid container spacing={2}>
          <Grid item xs={6} md={7}>
            <Text>
              In Rockford’s case, this meant replacing fifteen playlists, each
              tailored to a distinct combination of product lines, with one.
              After a brisk one time setup, where:
            </Text>
            <Text>
              <Grid item>
                <ul className={classes.list}>
                  <li>
                    Each screen and content were tagged with the product lines
                    they represent
                  </li>
                  <li>
                    rules were added to let screens know which content to play
                    ie. play Marine content in dealerships that deal in Marine
                    audio equipment.
                  </li>
                </ul>
              </Grid>
            </Text>
          </Grid>
          <Grid item container xs={6} md={5} justifyContent="flex-end">
            <Heading size={1} weight={700} className={classes.case}>
              …one playlist
              <br />
              was deployed
              <br /> to all of
              <br /> Rockford’s six
              <br /> hundred <br />
              locations.
            </Heading>
          </Grid>
        </Grid>
      </ContentCard>

      <ColorCard type="cx">
        <ContentCard>
          <Heading
            size={2}
            weight={600}
            className={cn(classes.heading, classes.whiteText)}
          >
            “Rules & Tags allow us to automate our content distribution. We just
            tag content and the system does the rest."
          </Heading>
          <Heading
            size={2}
            weight={500}
            className={cn(classes.heading, classes.whiteText)}
          >
            - Theresa Anthony, Vice President of Sales & Marketing, Rockford
            Fosgate
          </Heading>
        </ContentCard>
      </ColorCard>

      <ContentCard className={classes.lastItem}>
        <Text>
          <Hidden xsDown>{renderDesktopContactSales()}</Hidden>
          <Hidden smUp>{renderMobileContactSales()}</Hidden>
        </Text>
      </ContentCard>
    </Grid>
  );
};

export default CXModalContent;
