import { SVGProps } from 'react';

interface SearchProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const Search = ({ size = 18, ...props }: SearchProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.1827 7.08021C13.1827 5.17989 12.1492 3.44627 10.5156 2.47944C8.84866 1.51262 6.81498 1.51262 5.18138 2.47944C3.51443 3.44627 2.51427 5.17989 2.51427 7.08021C2.51427 9.01387 3.51443 10.7475 5.18138 11.7143C6.81498 12.6811 8.84866 12.6811 10.5156 11.7143C12.1492 10.7475 13.1827 9.01387 13.1827 7.08021ZM12.1492 12.5478C10.949 13.4813 9.44876 14.0147 7.84849 14.0147C4.01452 14.0147 0.914001 10.9142 0.914001 7.08021C0.914001 3.27958 4.01452 0.145722 7.84849 0.145722C11.6491 0.145722 14.783 3.27958 14.783 7.08021C14.783 8.71382 14.2162 10.2141 13.2827 11.4143L17.7501 15.8483C18.0502 16.1817 18.0502 16.6818 17.7501 16.9819C17.4167 17.3152 16.9167 17.3152 16.6166 16.9819L12.1492 12.5478Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Search;
