import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import { textTruncate, buttonReset } from 'raydiant-elements/mixins';

export default makeStyles((theme: Theme) => {
  return createStyles({
    popover: {
      '& $popoverPaper': {
        padding: theme.spacing(2),
        maxWidth: 300,
      },
    },

    popoverPaper: {},

    acl: {
      alignItems: 'center',
    },

    aclName: {
      ...textTruncate(),
      flex: 1,
      fontSize: theme.fontSizes.lg,
      letterSpacing: 0.15,
    },

    aclRole: {
      flex: 1,
      fontSize: theme.fontSizes.md,
      color: 'rgba(0, 0, 0, 0.45)',
      fontWeight: 500,
      letterSpacing: 0.1,
    },

    remove: {
      ...buttonReset(),
      display: 'flex',
      color: theme.palette.text.secondary,
      opacity: 0.6,

      '&:hover': {
        opacity: 1,
      },

      '&:disabled': {
        opacity: 0,
        cursor: 'default',
      },
    },

    actions: {
      marginBottom: theme.spacing(1),
    },
  });
});
