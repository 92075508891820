import { useMemo } from 'react';
import useConnections from './useConnections';

export default function useConnection(id: string) {
  const query = useConnections();

  return {
    ...query,
    data: useMemo(() => {
      if (!query.data) return;
      return query.data.data.find((c) => c.id === id);
    }, [query.data, id]),
  };
}
