import { useState, useRef } from 'react';
import { Location, Device } from '@raydiant/api-client-js';
import Dialog from '@raydiant/raydial/components/Dialog';
import Button from '@raydiant/raydial/components/Button';
import Popover from '@raydiant/raydial/components/Popover';
import LocationButtonGroupItem from './LocationButtonGroupItem';
import { CirclePlus, Ellipsis, Edit, Users, Trash } from '../../Icons';
import {
  selectIsEnterpriseUser,
  selectUserProfile,
} from '../../../../selectors/user';
import { useSelector } from 'react-redux';
import { LocationsPageModalType } from '../../LocationsPage';
import { hasRoleOrHigher } from '../../../../utilities';

interface LocationButtonGroupProps {
  devices: Device[];
  location: Location;
  onButtonGroupClick: (locationId: string) => void;
  onButtonGroupItemClick: (type: LocationsPageModalType) => void;
  onButtonGroupClose: () => void;
}

const LocationButtonGroup = ({
  devices,
  location,
  onButtonGroupClick,
  onButtonGroupItemClick,
  onButtonGroupClose,
}: LocationButtonGroupProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef(null);

  const isEnterpriseUser = useSelector(selectIsEnterpriseUser);
  const currentUser = useSelector(selectUserProfile);

  const handleIconClick = () => {
    setIsOpen(true);
    onButtonGroupClick(location.id);
  };

  const handleItemClick = (type: LocationsPageModalType) => {
    setIsOpen(false);
    onButtonGroupItemClick(type);
  };

  if (!currentUser) return null;

  // Enterprise customers validation
  const isUserDomainAdmin = hasRoleOrHigher('admin', currentUser);
  const isUserDomainSuperAdmin = hasRoleOrHigher('superadmin', currentUser);

  const renderSMBCustomerOptions = () => {
    return (
      <>
        <Button
          variant="unstyled"
          className="relative mt-2 ml-2"
          onClick={() => handleIconClick()}
        >
          <span ref={triggerRef}>
            <Ellipsis />
          </span>
        </Button>
        <Popover
          triggerRef={triggerRef}
          isOpen={isOpen}
          onOpenChange={setIsOpen}
          className="w-[191px]"
          placement="bottom end"
        >
          <Dialog className="px-0 py-2">
            <div className="overflow-hidden">
              <LocationButtonGroupItem
                icon={<CirclePlus />}
                label="Add a screen"
                onClick={() => handleItemClick('screen')}
              />
              <LocationButtonGroupItem
                icon={<Edit />}
                label="Edit Location"
                onClick={() => handleItemClick('patch')}
              />
              <LocationButtonGroupItem
                className="text-red-700"
                icon={<Trash />}
                label="Delete Location"
                onClick={() => handleItemClick('delete')}
              />
            </div>
          </Dialog>
        </Popover>
      </>
    );
  };

  const renderEnterpriseCustomerOptions = () => {
    return (
      <>
        <Button
          variant="unstyled"
          className="relative mt-2 ml-2"
          onClick={() => handleIconClick()}
        >
          <span ref={triggerRef}>
            <Ellipsis />
          </span>
        </Button>
        <Popover
          triggerRef={triggerRef}
          isOpen={isOpen}
          onOpenChange={setIsOpen}
          className="w-[191px]"
          placement="bottom end"
        >
          <Dialog className="px-0 py-2">
            <div className="overflow-hidden">
              <LocationButtonGroupItem
                icon={<CirclePlus />}
                label="Add a screen"
                onClick={() => handleItemClick('screen')}
              />
              {isUserDomainAdmin && (
                <LocationButtonGroupItem
                  icon={<Users />}
                  label="Manage Access"
                  onClick={() => handleItemClick('manage')}
                />
              )}
              {isUserDomainAdmin && (
                <LocationButtonGroupItem
                  icon={<Edit />}
                  label="Edit Location"
                  onClick={() => handleItemClick('patch')}
                />
              )}
              {isUserDomainSuperAdmin && (
                <LocationButtonGroupItem
                  className="text-red-700"
                  icon={<Trash />}
                  label="Delete Location"
                  onClick={() => handleItemClick('delete')}
                />
              )}
            </div>
          </Dialog>
        </Popover>
      </>
    );
  };

  return (
    <>
      {isEnterpriseUser
        ? renderEnterpriseCustomerOptions()
        : renderSMBCustomerOptions()}
    </>
  );
};

export default LocationButtonGroup;
