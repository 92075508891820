import VIOnlyIllustration from './assets/vi-only-illustration.png';
import VIOnlyIllustrationMobile from './assets/vi-only-illustration-mobile.png';
import CallToActionCarousel from '../../AnalyticsCallToAction/CallToActionCarousel';

const carouselItems = [
  {
    label: 'Verify the content playing on screen',
  },
  {
    label: 'See which content best converts',
  },
  {
    label: 'Identify best target demographics',
  },
];

const VisitorInsightOnlyCard = () => {
  return (
    <CallToActionCarousel
      header="Integrate digital signage with Visitor InSight"
      content={carouselItems}
      backgroundColor="linear-gradient(108deg, #7ad1f4 -16%, #4c76e2 125%)"
      desktopBackgroundImage={VIOnlyIllustration}
      mobileBackgroundImage={VIOnlyIllustrationMobile}
      href="https://www.raydiant.com/solutions/digital-signage"
    />
  );
};

export default VisitorInsightOnlyCard;
