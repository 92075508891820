import { Popover } from '@headlessui/react';

type PopoverButtonProps = {
  icon: React.ReactNode;
};

const PopoverButton = ({ icon }: PopoverButtonProps) => {
  return <Popover.Button className="popover-btn">{icon}</Popover.Button>;
};

export default PopoverButton;
