import { useTranslation } from 'react-i18next';
import { ContentEngagementByClick } from '@raydiant/api-client-js';
import { Popover } from '@headlessui/react';
import { useToastState } from '@raydiant/raydial/components/Toast/ToastProvider';
import { useLocation } from 'react-router-dom';
import Spinner from '@raydiant/raydial/components/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Export } from '@raydiant/raydial/components/Icons';
import cn from 'classnames';

import * as analyticsActions from '../../actions/analytics';
import DataTable from '../../components/DataTable/DataTable';
import ChartCard from './ChartCard/ChartCard';
import { toPercent } from './ShopperEngagementInsights/utils';
import DataTableHeader, {
  TableSort,
} from '../../components/DataTable/DataTableHeader';
import DataTableCell from '../../components/DataTable/DataTableCell';
import { PaginationProps } from '../../components/Pagination/Pagination';
import QuestionMarkIcon from '../../components/QuestionMarkIcon';
import { ExportAnalyticsEnum } from '../../types/analytics';
import { useAnalyticsExport } from '../../hooks';
import { getApiQueryParams } from './AnalyticsFilter/utils';
import { selectExportType } from '../../selectors/analytics';

type TopClicksDataTableCardProps = PaginationProps & {
  data: ContentEngagementByClick[];
  sort: TableSort<ContentEngagementByClick>;
  onSort: (sort: TableSort<ContentEngagementByClick>) => void;
  showPagination?: boolean;
};

const TopClicksDataTableCard = ({
  currentPage,
  data,
  onPageChange,
  onSort,
  pageCount,
  sort,
  showPagination = true,
}: TopClicksDataTableCardProps) => {
  const { t } = useTranslation();
  const state = useToastState();
  const { search } = useLocation();
  const query = getApiQueryParams(search);
  const dispatch = useDispatch();

  const exportType = useSelector(selectExportType);

  const { isLoading: isExportLoading } = useAnalyticsExport(query, exportType);

  const handleExport = () => {
    dispatch(
      analyticsActions.setExportType(ExportAnalyticsEnum.ContentTopClicks),
    );
    state.add({
      title: t('export.exportingToasts.loadingTopClicksTitle'),
      description: t('export.exportingToasts.loadingDescription'),
      variant: 'info',
    });
  };

  return (
    <ChartCard
      subTitle={''}
      title={
        <div className="flex items-center justify-between">
          <div className="flex gap-3 items-center">
            <h2 className="text-black text-xl font-bold">
              {t('analytics.topClicks')!}
            </h2>
            <Popover className="relative">
              <Popover.Button>
                <QuestionMarkIcon />
              </Popover.Button>
              <Popover.Panel className="absolute z-10 card popover">
                <div className="overflow-hidden">
                  <p className="mb-3">{t('analytics.topClicksTooltip')}</p>
                  <a
                    href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                    rel="noreferrer"
                    target="_blank"
                  >
                    {t('analytics.learnmore')}
                  </a>
                </div>
              </Popover.Panel>
            </Popover>
          </div>
          <div className="flex items-center">
            <button
              className={cn(
                'min-w-[156px] flex items-center py-3 px-6 rounded-2xl ml-5 hover:bg-blue-50 mr-3 disabled:opacity-100',
                isExportLoading ? 'bg-blue-50' : 'bg-white',
              )}
              onClick={handleExport}
              disabled={isExportLoading}
            >
              {isExportLoading ? <Spinner /> : <Export />}
              <div className="text-nowrap text-lg text-blue-600 font-semibold pl-2 leading-4">
                {isExportLoading ? t('export.exporting') : t('export.title')}
              </div>
            </button>
            <Popover className="relative">
              <Popover.Button>
                <QuestionMarkIcon />
              </Popover.Button>
              <Popover.Panel className="absolute z-10 right-0 top-9 card popover drop-shadow-xl">
                <div className="overflow-hidden">
                  <p className="mb-3">{t('export.exportTopClicksTooltip')}</p>
                  <a
                    href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics#FAQs"
                    rel="noreferrer"
                    target="_blank"
                  >
                    {t('analytics.learnmore')}
                  </a>
                </div>
              </Popover.Panel>
            </Popover>
          </div>
        </div>
      }
      showTimeInterval={false}
    >
      <DataTable<ContentEngagementByClick>
        currentPage={currentPage}
        data={data}
        showPagination={showPagination}
        headers={
          <tr>
            <DataTableHeader<ContentEngagementByClick>
              className="w-10 sticky left-0 bg-white"
              onSort={() => {}}
              sortDirection={undefined}
              sortKey={undefined}
              showSort={false}
            >
              {t('analytics.rank')}
            </DataTableHeader>
            <DataTableHeader<ContentEngagementByClick>
              className="w-10 sticky left-[5.5rem] bg-white"
              onSort={onSort}
              sortDirection={
                sort.key === 'interactionSource' ? sort.direction : undefined
              }
              sortKey={'interactionSource'}
              showSort={sort.key === 'interactionSource'}
            >
              {t('analytics.linkName')}
            </DataTableHeader>
            <DataTableHeader<ContentEngagementByClick>
              showSort={sort.key === 'presentationName'}
              sortDirection={
                sort.key === 'presentationName' ? sort.direction : undefined
              }
              sortKey={'presentationName'}
              onSort={onSort}
            >
              {t('analytics.presentation')}
            </DataTableHeader>
            <DataTableHeader<ContentEngagementByClick>
              showSort={sort.key === 'pageType'}
              sortDirection={
                sort.key === 'pageType' ? sort.direction : undefined
              }
              sortKey={'pageType'}
              onSort={onSort}
            >
              {t('analytics.pageType')}
            </DataTableHeader>
            <DataTableHeader<ContentEngagementByClick>
              showSort={sort.key === 'pageTitle'}
              sortDirection={
                sort.key === 'pageTitle' ? sort.direction : undefined
              }
              sortKey={'pageTitle'}
              onSort={onSort}
            >
              {t('analytics.page')}
            </DataTableHeader>
            <DataTableHeader<ContentEngagementByClick>
              showSort={sort.key === 'clicks'}
              sortDirection={sort.key === 'clicks' ? sort.direction : undefined}
              sortKey={'clicks'}
              onSort={onSort}
            >
              {t('analytics.clicks')}
            </DataTableHeader>
            <DataTableHeader<ContentEngagementByClick>
              showSort={sort.key === 'percentClicksOnPage'}
              sortDirection={
                sort.key === 'percentClicksOnPage' ? sort.direction : undefined
              }
              sortKey={'percentClicksOnPage'}
              onSort={onSort}
            >
              {t('analytics.clicksOnPage')}
            </DataTableHeader>
          </tr>
        }
        onPageChange={onPageChange}
        pageCount={pageCount}
        renderRow={(item, index) => (
          <tr key={index}>
            <DataTableCell hasSort className="sticky left-0 bg-white">
              {index + 1}
            </DataTableCell>
            <DataTableCell hasSort className="sticky left-[5.5rem] bg-white">
              {item.interactionSource}
            </DataTableCell>
            <DataTableCell hasSort>{item.presentationName}</DataTableCell>
            <DataTableCell hasSort>{item.pageType}</DataTableCell>
            <DataTableCell hasSort>{item.pageTitle}</DataTableCell>
            <DataTableCell hasSort>{item.clicks}</DataTableCell>
            <DataTableCell hasSort>
              {toPercent(item.percentClicksOnPage)}%
            </DataTableCell>
          </tr>
        )}
      />
    </ChartCard>
  );
};

export default TopClicksDataTableCard;
