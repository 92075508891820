import { useState, useEffect, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import Center from 'raydiant-elements/layout/Center';
import Paper from 'raydiant-elements/core/Paper';
import Title from 'raydiant-elements/typography/Title';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import PaperModal from 'raydiant-elements/core/PaperModal';
import * as paths from '../../routes/paths';
import * as D from '../../clients/mira/types/Device';
import { canEditResource } from '../../utilities';
import * as actions from '../../actions/devices';
import Page from '../../components/Page';
import { selectUserProfile } from '../../selectors/user';
import { selectDevicesById } from '../../selectors/v2/devices';
import IntegrationCard from './components/IntegrationCard';
import IntegrationMainActionBar from './IntegrationMainActionBar';
import { selectLastLoadedDate } from '../DevicesPage/selectors';
import IntegrationSettings from './IntegrationSettings';
import useStyles from './integrationPage.styles';
import AddIntegrationPage from './AddIntegrationPage';
import { mockIntegrations } from './IntegrationPageUtils';

const IntegrationPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const settingsMatch = useRouteMatch<{ integrationId?: string }>({
    path: paths.integrationSettings.pattern,
  });
  const createMatch = useRouteMatch({
    path: paths.createIntegration.pattern,
  });

  const [integrations, setIntegrations] = useState(mockIntegrations);

  // Selectors
  const devicesById = useSelector(selectDevicesById);
  const currentUser = useSelector(selectUserProfile);
  const lastLoadedDate = useSelector(selectLastLoadedDate);

  // will be removed. Just added for the testing purpose
  const addIntegration = (integration: any) => {
    setIntegrations([integration, ...integrations]);
    history.push(paths.integration());
  };

  const editIntegration = (integrationId: string) => {
    history.push(paths.integrationSettings(integrationId));
  };

  const closeIntegrationSettings = () => {
    history.push(paths.integration());
  };

  const createIntegration = useCallback(() => {
    history.push(paths.createIntegration());
  }, [history]);

  useEffect(() => {
    dispatch(actions.fetchDevices());
  }, [dispatch]);

  // Render
  if (!lastLoadedDate || !currentUser) {
    return (
      <Page title="Integrations">
        <Center>
          <CircularProgress size={30} />
        </Center>
      </Page>
    );
  }

  const selectedIntegrationId = settingsMatch?.params?.integrationId ?? '';
  const selectedIntegration = integrations.find(
    (integration) => integration.id === selectedIntegrationId,
  );
  const allDevices = Object.values(devicesById) as D.Device[];
  const selectableDevices = allDevices.filter((device) =>
    canEditResource(currentUser, device.resource),
  );

  const deleteIntegration = (integrationId: string) => {
    const updatedIntegrations = integrations.filter(
      (integration) => integration.id !== integrationId,
    );
    setIntegrations(updatedIntegrations);
  };

  const hasIntegrations = integrations.length > 0;

  if (createMatch)
    return (
      <AddIntegrationPage
        integrations={integrations}
        onApplyClick={addIntegration}
      />
    );

  return (
    <Page title="Integration">
      <Paper color="light" className={classes.integration}>
        <div className={classes.integrationMain}>
          <div className={classes.header}>
            <Title>Analytics Integration</Title>
            <IntegrationMainActionBar onCreate={createIntegration} />
            <br />
            <Scrollable>
              {hasIntegrations ? (
                integrations.map((integration) => (
                  <IntegrationCard
                    integration={integration}
                    handleIconClick={editIntegration}
                  />
                ))
              ) : (
                <IntegrationCard empty={true} />
              )}
            </Scrollable>
          </div>
        </div>

        {selectedIntegration && (
          <PaperModal
            color="lightGrey"
            className={classes.modal}
            open={!!selectedIntegration}
            onClose={closeIntegrationSettings}
          >
            <IntegrationSettings
              devices={selectableDevices}
              integration={selectedIntegration}
              onDeleteIntegration={deleteIntegration}
            />
          </PaperModal>
        )}
      </Paper>
    </Page>
  );
};

export default IntegrationPage;
