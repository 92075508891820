import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from '../../clients/mira/types/Resource';
import * as libraryActions from './actions';
import { selectRootLibraryByOwner } from '../../selectors/v2/folders';
import { SortFolderOptions } from '../../utilities';
import LibraryTree from '../../components/LibraryTree/LibraryTree';

interface LibrarySideBarProps {
  selectedProfile: R.ResourceProfile;
  selectMode: 'playlist' | 'multiple';
  sortOptions: SortFolderOptions;
  onOpenPresentation?: (presentationId: string) => void;
  onOpenPlaylist?: (playlistId: string) => void;
}

const LibrarySideBar: FC<LibrarySideBarProps> = ({
  selectedProfile,
  selectMode,
  sortOptions,
  onOpenPresentation,
  onOpenPlaylist,
}) => {
  const dispatch = useDispatch();

  const libraryByOwner = useSelector(selectRootLibraryByOwner);

  useEffect(() => {
    dispatch(
      libraryActions.loadRootLibraryPage({ profileId: selectedProfile.id }),
    );
  }, [dispatch, selectedProfile.id]);

  return (
    <LibraryTree
      selectMode={selectMode}
      selectedProfile={selectedProfile}
      sortOptions={sortOptions}
      libraryByOwner={libraryByOwner}
      onOpenPlaylist={onOpenPlaylist}
      onOpenPresentation={onOpenPresentation}
    />
  );
};

export default LibrarySideBar;
