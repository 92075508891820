import { useTranslation } from 'react-i18next';
import PageLayout from '../../../components/PageLayout/PageLayout';
import PageTitle from '../../../components/PageLayout/PageTitle';
import { useCallback } from 'react';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useUpdateAnalytics from '../../../hooks/useUpdateAnalytics';

const AnalyticsCallToAction = () => {
  const { t } = useTranslation();
  const { data } = useCurrentUser();
  const { mutateAsync: updateAnalyticsFlag, isLoading } = useUpdateAnalytics();

  const handleGetStarted = useCallback(async () => {
    updateAnalyticsFlag({
      profile: {
        ...data!,
        analyticsOnboardingEnabled: true,
      },
    });
  }, [data, updateAnalyticsFlag]);

  return (
    <PageLayout
      title={
        <PageTitle
          title={t('analytics.analytics')}
          subTitle={t('analytics.pageSubTitle')!}
        />
      }
    >
      <div className="card rounded-3xl p-0 overflow-auto">
        <div className="flex flex-col md:flex-row md:h-[518px]">
          <div className="basis-3/3 md:basis-1/3 flex items-center justify-center p-12">
            <div>
              <p className="text-3xl font-medium mb-6 text-black">
                {t('analytics.welcome')}
              </p>
              <p>{t('analytics.welcomeText')}</p>
            </div>
          </div>
          <div className="basis-3/3 md:basis-2/3 flex flex-col justify-end cta-bg p-11">
            <div className="flex flex-col md:flex-row gap-3 justify-center">
              <button
                className="btn lg w-full bg-white border-0"
                onClick={() =>
                  window.open(
                    'https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics',
                  )
                }
              >
                {t('analytics.learnmore')}
              </button>
              <button
                disabled={isLoading}
                className="btn lg text-white bg-teal-300 hover:bg-teal-500 border-0 w-full"
                onClick={() => handleGetStarted()}
              >
                {t('analytics.getStarted')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default AnalyticsCallToAction;
