import {
  CreateLocationRequest,
  Location,
  ApiClientError,
} from '@raydiant/api-client-js';
import { useQueryClient } from 'react-query';
import raydiant from '../clients/raydiant';
import { keys } from '../queryClient';
import useProtectedMutation from './useProtectedMutation';

export default function useUpdateLocation() {
  const queryClient = useQueryClient();

  const mutation = useProtectedMutation<
    Location,
    ApiClientError,
    { locationId: string; params: CreateLocationRequest },
    Location[]
  >(({ locationId, params }) => raydiant.editLocation(locationId, params), {
    onSuccess: (data) => {
      const previousData =
        queryClient.getQueryData<Location[]>(keys.locations()) || [];

      const selectedLocationIndex = previousData.findIndex(
        (location) => location.id === data.id,
      );
      const updatedLocations = [...previousData];
      const updatedLocation = {
        ...updatedLocations[selectedLocationIndex],
        ...data,
      };
      updatedLocations[selectedLocationIndex] = updatedLocation;

      queryClient.setQueryData(keys.locations(), updatedLocations);
    },
  });

  return mutation;
}
