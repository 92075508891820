import Input from 'raydiant-elements/core/Input';
import { RuleConstantInputPropsType } from './RuleConstantInput';

export type NumberRuleConstantInputProps = RuleConstantInputPropsType<{
  type: 'number';
  value: number;
  onChange: (value: number) => void;
}>;

const NumberRuleConstantInput = ({
  onChange,
  value,
}: NumberRuleConstantInputProps) => {
  return (
    <Input
      type="number"
      value={value ? value.toString() : ''}
      onChange={(value) => onChange(parseInt(value, 10))}
      min={0}
    />
  );
};

export default NumberRuleConstantInput;
