import { useState } from 'react';
import Button from '@raydiant/raydial/components/Button';
import Modal from '@raydiant/raydial/components/Modal';
import TextField from '@raydiant/raydial/components/TextField';
import TextArea from '@raydiant/raydial/components/TextArea';
import Dialog, {
  DialogHeader,
  DialogHeading,
  DialogTrigger,
} from '@raydiant/raydial/components/Dialog';
import Popover from '@raydiant/raydial/components/Popover';
import useCreateLocation from '../../../../hooks/useCreateLocation';
import InfoCircle from '../../Icons/InfoCircle';
import Link from '@raydiant/raydial/components/Link';

interface AddLocationDialogProps {
  isOpen: boolean;
  onSubmit?: () => void;
  onClose?: () => void;
  type?: 'first' | 'default';
}

const AddLocationDialog = ({
  isOpen = false,
  type = 'default',
  onSubmit,
  onClose,
}: AddLocationDialogProps) => {
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const createLocation = useCreateLocation();
  const isFirstLocation = type === 'first';

  const handleCreateLocation = async () => {
    try {
      await createLocation.mutate({
        params: {
          name,
          description,
        },
      });
      setName('');
      setDescription('');
    } catch (err) {
    } finally {
      onSubmit && onSubmit();
    }
  };

  const title = isFirstLocation
    ? 'Tell us which location you want to setup first?'
    : 'Add a Location';

  const defaultButtonGroup = () => {
    return (
      <>
        {onClose && (
          <Button
            onClick={() => onClose()}
            variant="secondary"
            size="large"
            className="grow"
            isDisabled={createLocation.isLoading}
          >
            Cancel
          </Button>
        )}
        <Button
          onClick={() => handleCreateLocation()}
          variant="primary"
          size="large"
          className="grow"
          isDisabled={createLocation.isLoading || !name}
          loading={createLocation.isLoading}
        >
          Add Location
        </Button>
      </>
    );
  };

  const firstTimeButtonGroup = () => {
    return (
      <Button
        onClick={() => handleCreateLocation()}
        variant="primary"
        size="large"
        isDisabled={createLocation.isLoading || !name}
        loading={createLocation.isLoading}
        fullWidth
        className="grow"
      >
        Add Location
      </Button>
    );
  };

  return (
    <Modal isOpen={isOpen} className="overflow-auto">
      <Dialog
        showCloseButton={!isFirstLocation && true}
        onClose={!isFirstLocation ? onClose : undefined}
      >
        <DialogHeader>
          <DialogHeading>
            <span className="text-black">{title}</span>
          </DialogHeading>
        </DialogHeader>

        <div className="p-4">
          <div className="text-black text-[18px] font-semibold">
            Add Location Information
          </div>
          <div className="flex items-center">
            <div>Enter the details for your new location</div>

            <DialogTrigger>
              <Button aria-label="Help" variant="unstyled">
                <div className="flex items-center ml-2 mt-1">
                  <InfoCircle />
                </div>
              </Button>
              <Popover className="max-w-[250px]" placement="bottom start">
                <Dialog>
                  <div className="overflow-hidden">
                    <p>
                      Create a new location to group the screens that reside in
                      the same store/building to keep your screens organized and
                      to allow you to quickly give users access to manage only
                      the screens for this location!
                    </p>
                    <Link
                      className="text-sm mt-2"
                      href="https://support.raydiant.com/s/article/Your-Screens-page-1660092388713"
                    >
                      Learn more
                    </Link>
                  </div>
                </Dialog>
              </Popover>
            </DialogTrigger>
          </div>
        </div>
        <TextField
          value={name || ''}
          onChange={setName}
          placeholder="Location Name"
          description="eg: Austin, TX, Financial District, City Centre Mall, etc."
        />

        <div className="mt-4">
          <TextArea
            value={description || ''}
            onChange={setDescription}
            placeholder="Location Details - Optional"
            description="Add any helpful notes for this location"
            rows={14}
          />
        </div>

        <div className="flex flex-col-reverse justify-between mt-8 mb-4 gap-3 px-0 md:gap-6 md:flex-row md:px-4">
          {type === 'default' && defaultButtonGroup()}
          {type === 'first' && firstTimeButtonGroup()}
        </div>
      </Dialog>
    </Modal>
  );
};

export default AddLocationDialog;
