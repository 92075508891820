import {
  ApiClientError,
  Catalog,
  CreateCatalog,
} from '@raydiant/api-client-js';
import { useQueryClient } from 'react-query';
import raydiant from '../clients/raydiant';
import { keys } from '../queryClient';
import useProtectedMutation from './useProtectedMutation';

export default function useCreateCatalog() {
  const queryClient = useQueryClient();

  const mutation = useProtectedMutation<Catalog, ApiClientError, CreateCatalog>(
    (data) => raydiant.createCatalog(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(keys.catalogs());
      },
    },
  );

  return mutation;
}
