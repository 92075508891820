import { SVGProps } from 'react';

interface FilterProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const Filter = ({ size = 18, ...props }: FilterProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.574707 1.58203C0.574707 0.818359 1.17236 0.1875 1.93604 0.1875H16.1802C16.9438 0.1875 17.5747 0.818359 17.5747 1.58203C17.5747 1.91406 17.4419 2.21289 17.2427 2.44531L11.731 9.25195V14C11.731 14.5977 11.2329 15.0625 10.6353 15.0625C10.4028 15.0625 10.1704 14.9961 9.97119 14.8301L6.9165 12.4062C6.58447 12.1406 6.41846 11.7754 6.41846 11.377V9.25195L0.873535 2.44531C0.674316 2.21289 0.574707 1.91406 0.574707 1.58203ZM2.40088 1.78125L7.81299 8.45508C7.9458 8.62109 8.01221 8.78711 8.01221 8.95312V11.2441L10.1372 12.9375V8.95312C10.1372 8.78711 10.1704 8.62109 10.3032 8.45508L15.7485 1.78125H2.40088Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Filter;
