import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Presentation } from '@raydiant/api-client-js';
import * as objectPath from 'object-path';
import {
  selectLocalUploads,
  selectUploadsByPresentationId,
} from '../selectors/fileUploads';
import { isFileUploading } from '../utilities/fileUploadUtils';
import { PresentationUpload } from '../types';

export default function usePresentationUploads(
  presentation?: Presentation,
): PresentationUpload[] {
  const localUploads = useSelector(selectLocalUploads);
  const uploadsByPresentationId = useSelector(selectUploadsByPresentationId);

  const presentationUploads = useMemo(() => {
    let uploads: PresentationUpload[] = [];

    if (presentation && uploadsByPresentationId[presentation.id]) {
      const presentationUploads = uploadsByPresentationId[presentation.id];

      Object.entries(presentationUploads).forEach(([_, { path }]) => {
        const appVarFile = presentation && objectPath.get(presentation, path);

        // If app var was deleted or invalid, don't replace with local upload.
        if (!appVarFile || !appVarFile.file_upload_id) return;

        // If a local upload doesn't exist for the file upload id, it's no longer uploading.
        const localUpload = localUploads[appVarFile.file_upload_id];
        if (!localUpload) return;

        // Find the file upload for the file upload id that's stored with the file input value.
        const fileUpload = presentation.fileUploads?.find(
          (fu) => fu.id === appVarFile.file_upload_id,
        );

        if (fileUpload) {
          // If the file upload is still uploading, use the local upload URL.
          const isUploading = isFileUploading(fileUpload, localUploads);
          if (isUploading) {
            uploads.push({ path, localUrl: localUpload.localUrl });
          }
        }
      });
    }

    return uploads;
  }, [presentation, uploadsByPresentationId, localUploads]);

  return presentationUploads;
}
