import { Profile } from '@raydiant/api-client-js';

const getFirstName = (user: Pick<Profile, 'name'>) =>
  user?.name?.split(' ')[0] || '';
const getLastName = (user: Pick<Profile, 'name'>) =>
  user?.name?.split(' ')[1] || '';
const getInitials = (user: Pick<Profile, 'name'>) =>
  (getFirstName(user).charAt(0) + getLastName(user).charAt(0)).toUpperCase();

export { getFirstName, getInitials, getLastName };
