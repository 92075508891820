import { BarChart } from 'echarts/charts';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { useTranslation } from 'react-i18next';
import Hidden from 'raydiant-elements/layout/Hidden';
import { TrafficSummary } from '@raydiant/api-client-js';
import { Popover } from '@headlessui/react';
import { renderToStaticMarkup } from 'react-dom/server';

import ChartCard from '../ChartCard/ChartCard';
import { CHART_DEFAULT_AXIS_OPTIONS, CHART_DEFAULT_OPTIONS } from '../utils';
import {
  dwellTime,
  getDwellTimePercentageArrData,
  dwellTimeData,
} from './utils';
import QuestionMarkIcon from '../../../components/QuestionMarkIcon';
import PopoverButton from '../../../components/Popover/PopoverButton';
import ChartToolTip, { EChartsToolTipParams } from '../ChartToolTip';

echarts.use([BarChart]);

interface DwellHistogramChartProps {
  data: TrafficSummary;
}

const dwellTimeColors: Record<string, string> = {
  [dwellTime[0]]: '#00529A',
  [dwellTime[1]]: '#005CBF',
  [dwellTime[2]]: '#0093CE',
  [dwellTime[3]]: '#0B99FF',
  [dwellTime[4]]: '#00B6FF',
  [dwellTime[5]]: '#7AD1F4',
  [dwellTime[6]]: '#B9EBFF',
};

const DwellHistogramChart = ({ data }: DwellHistogramChartProps) => {
  const { t } = useTranslation();

  const { dwellTimePercentageArr } = getDwellTimePercentageArrData(data);
  const dwellTimeDistribution: { [key: string]: number } = dwellTimeData(data);

  const options = {
    ...CHART_DEFAULT_OPTIONS,
    toolbox: {
      feature: {
        saveAsImage: { show: true },
      },
    },
    title: {
      text: '',
    },
    tooltip: {
      ...CHART_DEFAULT_OPTIONS.tooltip,
      formatter: (params: EChartsToolTipParams[]) => {
        const modifiedParams: EChartsToolTipParams[] = params.flatMap(
          (item) => {
            const value = dwellTimeDistribution[item.name];
            return [
              {
                ...item,
                seriesName: t(
                  'analytics.trafficAwarenessPage.tableHeaders.ots',
                ),
                value: value,
              },
              {
                ...item,
                seriesName: t(
                  'analytics.trafficAwarenessPage.tableHeaders.percentage',
                ),
                value: `${Number(item.value).toFixed(2)}%`,
              },
            ];
          },
        );
        return renderToStaticMarkup(
          <ChartToolTip
            header={
              <p className="font-bold text-sm px-2 py-1 text-black">
                {params[0].axisValueLabel
                  ?.replace('sec', 'seconds')
                  ?.replace('min', 'minutes')}
              </p>
            }
            values={modifiedParams}
          />,
        );
      },
    },
    xAxis: {
      ...CHART_DEFAULT_AXIS_OPTIONS.xAxis,
      data: dwellTime,
      type: 'category',
      name: t(
        'analytics.trafficAwarenessPage.overview.dwellTime.title',
      ).toUpperCase(),
      axisLabel: {
        ...CHART_DEFAULT_AXIS_OPTIONS.xAxis.axisLabel,
      },
    },
    yAxis: {
      ...CHART_DEFAULT_AXIS_OPTIONS.yAxis,
      name: t(
        'analytics.trafficAwarenessPage.tableHeaders.percentage',
      ).toUpperCase(),
      type: 'value',
      axisLabel: {
        ...CHART_DEFAULT_AXIS_OPTIONS.yAxis.axisLabel,
      },
      alignTicks: true,
    },
    series: [
      {
        type: 'bar',
        emphasis: {
          focus: 'series',
        },
        data: dwellTimePercentageArr,
        itemStyle: {
          color: function (param: { name: string }) {
            return dwellTimeColors[param.name] || '#0882B2';
          },
        },
      },
    ],
  };

  const mobileOptions = {
    ...options,
    legend: {
      ...CHART_DEFAULT_OPTIONS.legend,
      type: 'scroll',
    },
  };

  return (
    <ChartCard
      subTitle={''}
      title={
        <div className="flex gap-3 items-center">
          <h2 className="text-black text-xl font-bold">
            {t('analytics.trafficAwarenessPage.dwellTimeChart.title')!}
          </h2>
          <Popover className="relative">
            <PopoverButton icon={<QuestionMarkIcon />} />
            <Popover.Panel className="absolute z-10 card popover">
              <div className="overflow-hidden">
                <p className="mb-3">
                  {t('analytics.trafficAwarenessPage.dwellTimeChart.tooltip')}
                </p>
                <a
                  href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('analytics.learnmore')}
                </a>
              </div>
            </Popover.Panel>
          </Popover>
        </div>
      }
      showTimeInterval={false}
    >
      <div className="min-w-full min-h-full">
        <Hidden xsDown>
          <ReactECharts
            style={{ height: '500px' }}
            option={options}
            onChartReady={(chart) => chart.resize()}
          />
        </Hidden>
        <Hidden smUp>
          <ReactECharts
            style={{ height: '500px' }}
            option={mobileOptions}
            onChartReady={(chart) => chart.resize()}
          />
        </Hidden>
      </div>
    </ChartCard>
  );
};

export default DwellHistogramChart;
