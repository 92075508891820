import { makeStyles, createStyles } from 'raydiant-elements/styles';

export default makeStyles(() => {
  return createStyles({
    menuItem: {
      display: 'flex',
      justifyContent: 'space-between',
      minWidth: '200px',
    },
  });
});
