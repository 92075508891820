import { SVGProps } from 'react';

interface CircleCheckProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const Plus = ({ size = 13, ...props }: CircleCheckProps) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="path-1-outside-1_1043_35974"
        maskUnits="userSpaceOnUse"
        x="0.5"
        y="0.5"
        width="13"
        height="13"
        fill="black"
      >
        <rect fill="white" x="0.5" y="0.5" width="13" height="13" />
        <path d="M7.9 1.8625V6.025H12.0625C12.5539 6.025 12.9875 6.45859 12.9875 6.95C12.9875 7.47031 12.5539 7.875 12.0625 7.875H7.9V12.0375C7.9 12.5578 7.46641 12.9625 6.975 12.9625C6.45469 12.9625 6.05 12.5578 6.05 12.0375V7.875H1.8875C1.36719 7.875 0.9625 7.47031 0.9625 6.95C0.9625 6.45859 1.36719 6.025 1.8875 6.025H6.05V1.8625C6.05 1.3711 6.45469 0.937501 6.975 0.937501C7.46641 0.937501 7.9 1.3711 7.9 1.8625Z" />
      </mask>
      <path
        d="M7.9 1.8625V6.025H12.0625C12.5539 6.025 12.9875 6.45859 12.9875 6.95C12.9875 7.47031 12.5539 7.875 12.0625 7.875H7.9V12.0375C7.9 12.5578 7.46641 12.9625 6.975 12.9625C6.45469 12.9625 6.05 12.5578 6.05 12.0375V7.875H1.8875C1.36719 7.875 0.9625 7.47031 0.9625 6.95C0.9625 6.45859 1.36719 6.025 1.8875 6.025H6.05V1.8625C6.05 1.3711 6.45469 0.937501 6.975 0.937501C7.46641 0.937501 7.9 1.3711 7.9 1.8625Z"
        fill="currentColor"
      />
      <path
        d="M7.9 6.025H7.6V6.325H7.9V6.025ZM7.9 7.875V7.575H7.6V7.875H7.9ZM6.05 7.875H6.35V7.575H6.05V7.875ZM6.05 6.025V6.325H6.35V6.025H6.05ZM7.6 1.8625V6.025H8.2V1.8625H7.6ZM7.9 6.325H12.0625V5.725H7.9V6.325ZM12.0625 6.325C12.3882 6.325 12.6875 6.62428 12.6875 6.95H13.2875C13.2875 6.29291 12.7196 5.725 12.0625 5.725V6.325ZM12.6875 6.95C12.6875 7.29858 12.3943 7.575 12.0625 7.575V8.175C12.7135 8.175 13.2875 7.64205 13.2875 6.95H12.6875ZM12.0625 7.575H7.9V8.175H12.0625V7.575ZM7.6 7.875V12.0375H8.2V7.875H7.6ZM7.6 12.0375C7.6 12.3861 7.30683 12.6625 6.975 12.6625V13.2625C7.62598 13.2625 8.2 12.7295 8.2 12.0375H7.6ZM6.975 12.6625C6.62037 12.6625 6.35 12.3921 6.35 12.0375H5.75C5.75 12.7235 6.289 13.2625 6.975 13.2625V12.6625ZM6.35 12.0375V7.875H5.75V12.0375H6.35ZM6.05 7.575H1.8875V8.175H6.05V7.575ZM1.8875 7.575C1.53287 7.575 1.2625 7.30463 1.2625 6.95H0.6625C0.6625 7.636 1.2015 8.175 1.8875 8.175V7.575ZM1.2625 6.95C1.2625 6.61816 1.53892 6.325 1.8875 6.325V5.725C1.19545 5.725 0.6625 6.29902 0.6625 6.95H1.2625ZM1.8875 6.325H6.05V5.725H1.8875V6.325ZM6.35 6.025V1.8625H5.75V6.025H6.35ZM6.35 1.8625C6.35 1.53067 6.62642 1.2375 6.975 1.2375V0.637501C6.28295 0.637501 5.75 1.21152 5.75 1.8625H6.35ZM6.975 1.2375C7.30072 1.2375 7.6 1.53678 7.6 1.8625H8.2C8.2 1.20541 7.63209 0.637501 6.975 0.637501V1.2375Z"
        fill="currentColor"
        mask="url(#path-1-outside-1_1043_35974)"
      />
    </svg>
  );
};
export default Plus;
