import { FC, useState, useEffect, useRef } from 'react';
import Text from 'raydiant-elements/core/Text';
import TagIcon from '../TagIcon';
import useStyles from './TagInput.styles';

export interface TagInputTextTypeProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  showIcon?: boolean;
}

const TagInputTextType: FC<TagInputTextTypeProps> = ({
  label,
  value: initialValue,
  onChange,
  showIcon = true,
}) => {
  const classes = useStyles();

  // Refs

  const inputRef = useRef<HTMLInputElement | null>(null);

  // State

  const [value, setValue] = useState(initialValue);

  // Effects

  // Update state if initialValue changes.
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  // Auto focus input on mount.
  useEffect(() => {
    // Ensure DOM has updated before focusing and selecting text.
    const timeout = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    });

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  // Render

  return (
    <div className="border-2 border-[#f5f5f5] rounded-2xl w-full text-neutral-400 px-4 h-[40px]">
      <div className="flex items-center w-full gap-3 h-full">
        {showIcon && <TagIcon className={classes.icon} />}
        <div className={classes.tag}>
          <span>{label}:</span>
          &nbsp;
          <Text
            ref={inputRef}
            editable
            value={value}
            className={classes.tagValueInput}
            onChange={setValue}
            onBlur={() => onChange(value)}
          />
        </div>
      </div>
    </div>
  );
};

export default TagInputTextType;
