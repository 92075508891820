import { BatchActionStatus } from '@raydiant/api-client-js';
import Text from 'raydiant-elements/core/Text';
import useStyles from './DeviceBatchStatus.styles';
import Tooltip from '@material-ui/core/Tooltip';
import { clientFacingBatchActions } from '../deviceBatchSettingsUtils';

interface DeviceBatchStatusProps {
  batchActions: BatchActionStatus[];
}

const DeviceBatchStatus = ({ batchActions = [] }: DeviceBatchStatusProps) => {
  const classes = useStyles();
  const appliedBatchOperation = batchActions.filter(
    (action) => action.status === 'completed',
  );
  const errorBatchOperation = batchActions.filter(
    (action) => action.status === 'error',
  );

  const appliedText = appliedBatchOperation.length > 1 ? 'changes' : 'change';
  const errorText = errorBatchOperation.length > 1 ? 'errors' : 'error';

  const renderErrorTooltipContent = () => (
    <div>
      <Text xsmall bold className={classes.tooltipTitle}>
        Errors
      </Text>
      {errorBatchOperation.map((batchAction) => (
        <div>
          <Text xsmall>- {clientFacingBatchActions(batchAction.action)}</Text>
        </div>
      ))}
    </div>
  );

  return (
    <div className={classes.root}>
      <div>
        <Text xsmall className={classes.success}>
          {appliedBatchOperation.length} {appliedText} applied
        </Text>
        <Text xsmall className={classes.divider}>
          |
        </Text>
      </div>

      <Tooltip
        classes={{
          tooltip: classes.errorTooltip,
        }}
        placement="bottom-end"
        title={renderErrorTooltipContent()}
      >
        <div>
          <Text xsmall className={classes.error}>
            {errorBatchOperation.length} {errorText}
          </Text>
        </div>
      </Tooltip>
    </div>
  );
};

export default DeviceBatchStatus;
