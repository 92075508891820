import { useTranslation } from 'react-i18next';
import DropDownPlaceholder from '../../../../components/DropDown/DropDownPlaceholder';
import DeviceResolution, { DeviceResolutionQuery } from './DeviceResolution';
import DeviceScreenOrientation, {
  DeviceScreenOrientationQuery,
} from './DeviceScreenOrientation';
import DeviceTimezones, {
  DeviceTimezonesQuery,
} from './DeviceTimezones/DeviceTimezones';
import { Tag } from '@raydiant/api-client-js';
import TagManager from './TagManager/TagManager';
import { Popover } from '@headlessui/react';
import classNames from 'classnames';
import { useState } from 'react';

type DeviceSelectDropDownProps = {
  className?: string;
  onChange: (value: DeviceQuery) => void;
  value: DeviceQuery;
  isVIOnly?: boolean;
};

export type DeviceQuery = {
  resolution: DeviceResolutionQuery;
  screenOrientation: DeviceScreenOrientationQuery;
  timezone: DeviceTimezonesQuery;
  tags: Tag[];
};

const DeviceSelectDropDown = ({
  className,
  onChange,
  value,
  isVIOnly = false,
}: DeviceSelectDropDownProps) => {
  const { t } = useTranslation(['devices']);
  const resolutionValuesCount = value?.resolution
    ? Object.values(value?.resolution).filter(Boolean).length
    : 0;
  const screenOrientationValuesCount = value?.screenOrientation
    ? Object.values(value?.screenOrientation).filter(Boolean).length
    : 0;
  const timezoneCount = value?.timezone.length;
  const tagsCount = Object.values(value?.tags ?? [])?.length;

  const selectedCount =
    resolutionValuesCount +
    screenOrientationValuesCount +
    tagsCount +
    timezoneCount;

  const [open, setOpen] = useState(false);

  return (
    <>
      <Popover className={classNames('relative', className)}>
        <Popover.Button
          className="input no-label text-left w-full"
          onClick={() => setOpen(true)}
        >
          <DropDownPlaceholder className="md:w-full min-w-[100px]">
            <div className="flex items-center justify-between w-full">
              <p>{t('screens.device')}</p>
              {selectedCount > 0 && (
                <span className="mr-1 text-primary">({selectedCount})</span>
              )}
            </div>
          </DropDownPlaceholder>
        </Popover.Button>
        {open && (
          <>
            <div
              className="inset-0 fixed z-[8]"
              onClick={() => setOpen(false)}
            />
            <Popover.Panel
              className="absolute z-[8] card menu dropdown p-0 min-w-min w-full md:!min-w-[380px] !max-h-max left-0 !overflow-visible"
              static
              focus
            >
              <div className="p-6 text-black flex flex-col gap-2">
                {!isVIOnly && (
                  <>
                    <DeviceResolution
                      onChange={(resolution) =>
                        onChange({
                          ...value,
                          resolution,
                        })
                      }
                      value={value?.resolution}
                    />
                    <DeviceScreenOrientation
                      onChange={(screenOrientation) =>
                        onChange({
                          ...value,
                          screenOrientation,
                        })
                      }
                      value={value.screenOrientation}
                    />
                  </>
                )}

                <DeviceTimezones
                  onChange={(timezone) =>
                    onChange({
                      ...value,
                      timezone,
                    })
                  }
                  value={value.timezone ?? ''}
                />
                <TagManager
                  showLabel={false}
                  tags={value?.tags ?? []}
                  onChange={(tags) =>
                    onChange({
                      ...value,
                      tags,
                    })
                  }
                />
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </>
  );
};

export default DeviceSelectDropDown;
