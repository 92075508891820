import Avatar from 'raydiant-elements/core/Avatar';
import Link from 'raydiant-elements/core/Link';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import Row from 'raydiant-elements/layout/Row';
import Heading from 'raydiant-elements/core/Heading';
import Text from 'raydiant-elements/core/Text';
import Column from 'raydiant-elements/layout/Column';
import { FC, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUserProfile,
  selectIsEnterpriseUser,
} from '../../selectors/user';
import { selectDomainForCurrentUser } from '../../selectors/v2/domains';
import * as userActions from '../../actions/user';
import auth0Client from '../../clients/auth0Client';
import { domainRoleLabels } from '../../utilities';
import Page from '../../components/Page';
import logger from '../../logger';

interface AccountDetailsProps {}

const AccountDetails: FC<AccountDetailsProps> = () => {
  const dispatch = useDispatch();

  // Selectors

  const profile = useSelector(selectUserProfile);
  const domain = useSelector(selectDomainForCurrentUser);
  const isEnterpriseUser = useSelector(selectIsEnterpriseUser);

  // State

  const [updatedProfileName, setProfileName] = useState<string | null>(null);
  const [resetPasswordStatus, setResetPasswordStatus] = useState<
    '' | 'pending' | 'success' | 'error'
  >('');

  // Callbacks

  const updateProfileName = useCallback(() => {
    if (!profile) return;
    if (updatedProfileName === null) return;

    dispatch(
      userActions.updateProfile({
        ...profile,
        name: updatedProfileName,
      }),
    );
  }, [dispatch, updatedProfileName, profile]);

  const resetPassword = useCallback(async () => {
    if (!profile) return;

    setResetPasswordStatus('pending');
    try {
      await auth0Client.sendChangePasswordEmail(profile.email);
      setResetPasswordStatus('success');
    } catch (err: any) {
      logger.error(err);
      setResetPasswordStatus('error');
    }
  }, [profile]);

  // Render

  if (!profile) {
    return <Page title="Account" />;
  }

  const supportLink = (
    <Link href="mailto:support@raydiant.com">support@raydiant.com</Link>
  );

  return (
    <Column doubleMargin>
      <Heading size={5} overline>
        Account Settings
      </Heading>

      <Heading>
        <Row center halfMargin>
          <Avatar
            thumbnailUrl={profile.thumbnailUrl ?? undefined}
            name={profile.name}
          />
          <Text
            editable
            value={updatedProfileName ?? profile.name}
            onChange={setProfileName}
            onBlur={updateProfileName}
          />
        </Row>
      </Heading>

      <Column>
        {isEnterpriseUser && (
          <>
            <div>
              <Text bold>Enterprise domain</Text>
              <br />
              <Text large>{domain?.name}</Text>
            </div>
            <div>
              <Text bold>Account role</Text>
              <br />
              <Text large>{domainRoleLabels[profile.domainRole]}</Text>
            </div>
          </>
        )}

        <div>
          <Text bold>Account email</Text>
          <br />
          <Text large>{profile.email}</Text>
          <InputHelperText>
            Contact {supportLink} to change your email
          </InputHelperText>
        </div>
      </Column>

      <div>
        {['', 'pending'].includes(resetPasswordStatus) && (
          <Link
            underline
            onClick={resetPassword}
            disabled={resetPasswordStatus === 'pending'}
          >
            Reset your password
          </Link>
        )}

        {resetPasswordStatus === 'success' && (
          <>
            <Text muted>Link sent, please check your email. </Text>
            <Link onClick={resetPassword}>Send again.</Link>
          </>
        )}
        {resetPasswordStatus === 'error' && (
          <>
            <Text muted>
              Oops! Something went wrong, please contact {supportLink}.
            </Text>
          </>
        )}
      </div>
    </Column>
  );
};

export default AccountDetails;
