import { Playlist, Device, Presentation } from '@raydiant/api-client-js';
import { State, UpdatePlaylistWithId } from './playlistPageTypes';
import raydiant from '../../clients/raydiant';

export interface SavePlaylistOptions {
  rootPlaylistId: string;
  savedPlaylistsById: State['savedPlaylistsById'];
  savedPresentationsById: State['savedPresentationsById'];
  updatedPlaylistsById: State['updatedPlaylistsById'];
  updatedPresentationsById: State['updatedPresentationsById'];
  newPlaylistsById: State['newPlaylistsById'];
  assignToDeviceId?: string;
  addToFolderId?: string;
}

export interface SavePlaylistResult {
  playlistsById: Record<string, Playlist>;
  presentationsById: Record<string, Presentation>;
  device?: Device;
}

type FullOrPartialPlaylist = Playlist | UpdatePlaylistWithId;

// Create or update playlists. Playlists that have new nested playlists
// can't update their playlist items until the new playlists have been created
// and the playlist item references the new playlist id.

// We can't save everything in playlistsById because the user may have
// opened the playlist builder multiple time during the same builder session,
// ie. Playlist A -> MZ -> Playlist B. When saving playlist B, we don't want to save
// Playlist A. Use the rootPlaylistId to traverse and save nested playlists instead
// of saving everything in playlistsById.

// TODO: Add tests for this.
export default async function savePlaylist({
  rootPlaylistId,
  savedPlaylistsById,
  savedPresentationsById,
  updatedPlaylistsById,
  updatedPresentationsById,
  newPlaylistsById,
  assignToDeviceId,
  addToFolderId,
}: SavePlaylistOptions) {
  const result: SavePlaylistResult = {
    playlistsById: {},
    presentationsById: {},
  };

  const getPlaylist = (playlistId: string) => {
    const savedPlaylist = savedPlaylistsById[playlistId];
    const unsavedPlaylist = updatedPlaylistsById[playlistId];
    const newPlaylist = newPlaylistsById[playlistId];

    if (newPlaylist) {
      return newPlaylist;
    }

    if (unsavedPlaylist) {
      // Merge unsaved playlists with saved playlists. This fixes an issue where only updating
      // the schedule of a nested playlist could cause the playlist not to be saved because there
      // are no items to traverse. This ensure there are always playlist items.
      return { ...savedPlaylist, ...unsavedPlaylist };
    }

    return savedPlaylist;
  };

  const isNewPlaylist = (
    playlist: FullOrPartialPlaylist,
  ): playlist is Playlist => {
    return playlist.id in newPlaylistsById;
  };

  const isUpdatedPlaylist = (
    playlist: FullOrPartialPlaylist,
  ): playlist is UpdatePlaylistWithId => {
    return playlist.id in updatedPlaylistsById;
  };

  // Traverse playlist items starting from root playlist and save any nested playlists
  // before saving the parent playlist items with the new playlist ids.
  const traverse = async (
    playlist: FullOrPartialPlaylist,
  ): Promise<FullOrPartialPlaylist> => {
    const playlistWithSavedItems: FullOrPartialPlaylist = {
      ...playlist,
    };

    // If the playlist doesn't have items then we don't need to traverse any further.
    if (playlist.items) {
      // Reset the playlist items, which are added back while saving any nested playlists.
      // It's important that this happens here (and not outside the if statement to prevent
      // removing all items when saving a nested playlist that was renamed without expanding.
      playlistWithSavedItems.items = [];

      for (const item of playlist.items) {
        if (item.playlistId) {
          const nestedPlaylist = getPlaylist(item.playlistId);

          if (nestedPlaylist) {
            // If the nested playlist exists in playlistsById then it needs to be saved before
            // we can save the parent playlist.
            const savedNestedPlaylist = await traverse(nestedPlaylist);

            if (playlistWithSavedItems.items) {
              playlistWithSavedItems.items.push({
                ...item,
                playlistId: savedNestedPlaylist.id,
              });
            }
          } else if (playlistWithSavedItems.items) {
            // Nested playlist doesn't need to be saved.
            playlistWithSavedItems.items.push(item);
          }
        } else if (playlistWithSavedItems.items) {
          playlistWithSavedItems.items.push(item);
        }
      }
    }

    // If the playlist id already exists in results that means we've already updated it and
    // don't need to update it again. This prevents multiple duplicate update requests if the
    // same nested playlist exists in multiple times.
    if (result.playlistsById[playlist.id]) {
      return playlist;
    }

    // If the playlist doesn't need to be saved, don't create or update. We need to
    // do this because playlistsById contain all the playlists inside the root playlist
    // in order to traverse.
    let savedPlaylist: Playlist | undefined;
    if (isNewPlaylist(playlistWithSavedItems)) {
      savedPlaylist = await raydiant.createPlaylist(playlistWithSavedItems);

      if (addToFolderId) {
        // Move new playlist to folder.
        await raydiant.movePlaylistToFolder(savedPlaylist.id, addToFolderId);
      }
    } else if (isUpdatedPlaylist(playlistWithSavedItems)) {
      // The API doesn't not handle updating only the endDatetime as it checks to ensure
      // and startDatetime exists if updating endDatetime when validating the paylod. The API
      // should allow setting only endDatetime if startDatetime is already set in the db but
      // this doesn't work well with the current validation framework and need more time to
      // think about it. Intead use the startDatetime from the saved version of the playlist
      // client side if not set when updating.
      if (
        !playlistWithSavedItems.startDatetime &&
        playlistWithSavedItems.endDatetime
      ) {
        playlistWithSavedItems.startDatetime =
          savedPlaylistsById[playlist.id]?.startDatetime;
      }

      // Same issue as metioned above, we can't only update startDatetime without setting endDatetime.
      // Use the endDatetime from the saved version of the playlist client side if not set when updating.
      if (
        !playlistWithSavedItems.endDatetime &&
        playlistWithSavedItems.startDatetime
      ) {
        playlistWithSavedItems.endDatetime =
          savedPlaylistsById[playlist.id]?.endDatetime;
      }

      savedPlaylist = await raydiant.updatePlaylist(
        playlist.id,
        playlistWithSavedItems,
      );
    }

    // Playlist was saved, return in result.
    if (savedPlaylist) {
      result.playlistsById[playlist.id] = savedPlaylist;
      return savedPlaylist;
    }

    // Playlist wasn't saved, don't return in result.
    return playlist;
  };

  const savedRootPlaylist = await traverse(getPlaylist(rootPlaylistId));
  const isNewRootPlaylist = rootPlaylistId in newPlaylistsById;

  // If we created a new playlist, assign the playlist to the device.
  if (isNewRootPlaylist && assignToDeviceId) {
    // We need to fetch the device first because the update endpoint doesn't
    // allow only updating the playlist id.

    const device = await raydiant.getDevice(assignToDeviceId);
    // TODO: Support setting just the playlist id when updating a device.
    const updatedDevice = await raydiant.updateDevice(assignToDeviceId, {
      ...device,
      // Use the savedRootPlaylistId instead of rootPlaylistId because the id will change
      // if we are creating a new playlist.
      playlistId: savedRootPlaylist.id,
    });

    result.device = updatedDevice;
  }

  // Update presentations
  for (const updatedPresentation of Object.values(updatedPresentationsById)) {
    // We want to add the required properties based on how the current apis is setup
    // Ideally we should only be sending parameters that need to be updated i.e tags
    const [savedPresentation] = await raydiant.updatePresentation(
      updatedPresentation.id,
      {
        ...savedPresentationsById[updatedPresentation.id],
        ...updatedPresentation,
      },
    );

    result.presentationsById[updatedPresentation.id] = savedPresentation;
  }

  return result;
}
