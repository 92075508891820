import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelCircleIcon from '@material-ui/icons/CancelOutlined';
import Column from 'raydiant-elements/layout/Column';
import Heading from 'raydiant-elements/core/Heading';
import PaperModal from 'raydiant-elements/core/PaperModal';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import Button from 'raydiant-elements/core/Button';
import * as paths from '../../routes/paths';
import * as D from '../../clients/mira/types/Device';
import PropertiesTable from '../../components/PropertiesTable';
import LocationCard from './components/LocationCard';
import DeleteConfirmationModal from './components/DeleteConfirmationModal';
import useStyles from './IntegrationSettings.styles';
import { capitalize } from '../../utilities/stringUtils';
import AddLocationInputManager from './components/AddLocationInputManager';
import {
  defaultLocationInfo,
  validateLocationId,
} from './IntegrationPageUtils';

export interface IntegrationSettingsProps {
  devices: D.Device[];
  integration: any;
  onDeleteIntegration: (integrationId: string) => void;
}

const IntegrationSettings = ({
  devices,
  integration,
  onDeleteIntegration,
}: IntegrationSettingsProps) => {
  const classes = useStyles();
  const history = useHistory();

  const [locations, setLocations] = useState<any>([]);
  const [addedLocationIds, setAddedLocationIds] = useState<string[]>([]);
  const [newLocationId, setNewLocationId] = useState('');
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    useState<boolean>(false);
  const [error, setError] = useState('');

  const handleAddLocationId = (locationId: string) => {
    const existingLocationIds = locations.map(
      (location: any) => location.restaurant_guid,
    );

    const allLocationIds = [...existingLocationIds, ...addedLocationIds];

    if (validateLocationId(allLocationIds, locationId)) {
      setError('GUID already exists');
      return;
    }

    const updatedLocationIds = [locationId, ...addedLocationIds];
    setAddedLocationIds(updatedLocationIds);
    setNewLocationId('');
    setError('');
  };

  const handleDeleteLocationId = (selectedLocationId: string) => {
    const updatedLocationIds = addedLocationIds.filter(
      (locationId) => locationId !== selectedLocationId,
    );
    setAddedLocationIds(updatedLocationIds);
  };

  // this will be later updated with proper async call
  const handleApplyAddedLocationIds = () => {
    const addedLocations = addedLocationIds.map((locationId) => ({
      ...defaultLocationInfo,
      restaurant_guid: locationId,
    }));
    const updatedLocations = [...addedLocations, ...locations];
    setAddedLocationIds([] as string[]);
    setLocations(updatedLocations);
  };

  const handleDeleteLocation = (locationId: string) => {
    const updatedLocations = locations.filter(
      (location: any) => location.restaurant_guid !== locationId,
    );
    setLocations(updatedLocations);
  };

  const openDeleteConfirmationModal = () => {
    setDeleteConfirmationOpen(true);
  };

  const closeDeleteConfirmationModal = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleDeleteConfirmationModalClick = () => {
    onDeleteIntegration(integration.id);
    closeDeleteConfirmationModal();
  };

  useEffect(() => {
    setDeleteConfirmationOpen(false);
    setLocations(integration.locations);
    setNewLocationId('');
    setError('');
  }, [integration.locations]);

  return (
    <>
      <PaperModal.Body>
        <Column>
          <div>
            <Heading>{capitalize(integration.name)}</Heading>
          </div>
        </Column>
      </PaperModal.Body>
      <Scrollable>
        <PaperModal.Body>
          <div className={classes.section}>
            <Column doubleMargin>
              <div>
                <Heading size={5} overline gutterBottom>
                  Connection Settings
                </Heading>
                <PropertiesTable>
                  <tbody>
                    <>
                      <tr>
                        <th>Connection Status</th>
                        <td>
                          <div className={classes.iconTextWrapper}>
                            <div>connected</div>
                            <div className={classes.checkmark}>
                              <CheckCircleIcon className={classes.checkIcon} />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </>
                    <tr>
                      <th>Integration</th>
                      <td>POS System</td>
                    </tr>
                    <tr>
                      <th>POS Type</th>
                      <td>{capitalize(integration.POS_integration_type)}</td>
                    </tr>
                  </tbody>
                </PropertiesTable>
              </div>
            </Column>
          </div>

          <div className={classes.section}>
            <Heading size={5} overline gutterBottom>
              Add a Location
            </Heading>
            <Grid>
              <AddLocationInputManager
                locationIds={addedLocationIds}
                value={newLocationId}
                onChange={setNewLocationId}
                onAddIconClick={handleAddLocationId}
                onDeleteIconClick={handleDeleteLocationId}
                onApplyClick={handleApplyAddedLocationIds}
                error={!!error}
                errorHelperText={error}
              />
            </Grid>
            {locations.map((location: any) => (
              <LocationCard
                devices={devices}
                location={location}
                onIconClick={handleDeleteLocation}
              />
            ))}
          </div>
        </PaperModal.Body>
      </Scrollable>

      <PaperModal.Body>
        <Grid item container justifyContent="space-between">
          <Grid item xs={5} md={4}>
            <Button
              fullWidth
              label="Cancel"
              onClick={() => history.push(paths.integration())}
            />
          </Grid>
          <Grid item xs={5} md={4}>
            <Button
              fullWidth
              color="destructive"
              icon={<CancelCircleIcon />}
              iconAlignment="end"
              label="Delete"
              onClick={() => openDeleteConfirmationModal()}
            />
          </Grid>
        </Grid>
      </PaperModal.Body>

      <DeleteConfirmationModal
        open={deleteConfirmationOpen}
        handleClose={closeDeleteConfirmationModal}
        handleClick={handleDeleteConfirmationModalClick}
      />
    </>
  );
};

export default IntegrationSettings;
