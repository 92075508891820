import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { PlaylistItem } from '@raydiant/api-client-js';
import { SortFolderOptions, NodeType } from '../../utilities';

export type LoadAddActionPageParams = {
  profileId?: string;
  search?: string;
};

export const loadAddContentPage = createStandardAction(
  '@ADD_CONTENT_PAGE/LOAD',
)<LoadAddActionPageParams>();

export const unloadAddContentPage = createStandardAction(
  '@ADD_CONTENT_PAGE/UNLOAD',
)();

export const loadAddContentPageAsync = createAsyncAction(
  '@ADD_CONTENT_PAGE/LOAD_REQUEST',
  '@ADD_CONTENT_PAGE/LOAD_SUCCESS',
  '@ADD_CONTENT_PAGE/LOAD_FAILURE',
)<void, void, Error>();

export const setSortOptions = createStandardAction(
  '@ADD_CONTENT_PAGE/SET_SORT_OPTIONS',
)<SortFolderOptions>();

export const setSearchQuery = createStandardAction(
  '@ADD_CONTENT_PAGE/SET_SEARCH_QUERY',
)<string>();

export const clearSelectedNodeIds = createStandardAction(
  '@ADD_CONTENT_PAGE/CLEAR_SELECTED_NODE_IDS',
)();

export interface SelectItem {
  selectionId: string;
  item: { id: string; type: NodeType };
}

export const selectItem = createStandardAction(
  '@ADD_CONTENT_PAGE/SELECT_ITEM',
)<SelectItem>();

export const unselectItem = createStandardAction(
  '@ADD_CONTENT_PAGE/UNSELECT_Item',
)<SelectItem>();

export const setSelectedItems = createStandardAction(
  '@ADD_CONTENT_PAGE/SET_SELECTED_ITEMS',
)<{ selectionId: string; items: Array<{ id: string; type: NodeType }> }>();

export interface ClearSelectedItems {
  selectionId: string;
}

export const clearSelectedItems = createStandardAction(
  '@ADD_CONTENT_PAGE/CLEAR_SELECTED_ITEMS',
)<ClearSelectedItems>();

export interface SetItemsToAdd {
  selectionId: string;
  items: PlaylistItem[];
}

export const setItemsToAdd = createStandardAction(
  '@ADD_CONTENT_PAGE/SET_ITEMS_TO_ADD',
)<SetItemsToAdd>();

export interface ClearItemsToAdd {
  selectionId: string;
}
export const clearItemsToAdd = createStandardAction(
  '@ADD_CONTENT_PAGE/CLEAR_ITEMS_TO_ADD',
)<ClearItemsToAdd>();
