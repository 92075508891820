import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import { Device, Location, RecentDeviceError } from '@raydiant/api-client-js';
import * as deviceActions from '../../actions/devices';
import * as folderActions from '../../actions/folders';

export type DeviceActions = ActionType<typeof deviceActions>;
export type FolderActions = ActionType<typeof folderActions>;

export type DevicesState = Readonly<{
  byId: {
    [deviceId: string]: Device;
  };
  statusById: {
    [deviceId: string]:
      | 'fetching'
      | 'updating'
      | 'publishing'
      | 'success'
      | 'error';
  };
  recentErrorsById: {
    [deviceId: string]: RecentDeviceError[];
  };
}>;

const initialDevicesState: DevicesState = {
  byId: {},
  statusById: {},
  recentErrorsById: {},
};

const mergeDevicesToState = (devices: Device[], state: DevicesState) => {
  devices.forEach((device) => {
    const oldDevice = state.byId[device.id];
    state.byId[device.id] = device;

    // Use the old devices publishedAt if it's greater. This fixes a race
    // condition where the user publishes before GET /devices returns.
    if ((oldDevice?.publishedAt ?? '') > (device.publishedAt ?? '')) {
      state.byId[device.id].publishedAt = oldDevice.publishedAt;
      state.byId[device.id].publishAck = oldDevice.publishAck;
      state.byId[device.id].publishPlaybackSuccess =
        oldDevice.publishPlaybackSuccess;
      state.byId[device.id].publishPlaybackError =
        oldDevice.publishPlaybackError;
    }
  });
};

export default function devicesReducer(
  state = initialDevicesState,
  action: DeviceActions | FolderActions,
): DevicesState {
  switch (action.type) {
    // fetchDevices
    case getType(deviceActions.fetchDevicesAsync.success): {
      const devices = action.payload;
      return produce(state, (draftState) => {
        mergeDevicesToState(devices, draftState);
      });
    }

    // fetchFolderAsync and fetchVirtualFolderAsync
    case getType(folderActions.fetchFolderAsync.success):
    case getType(folderActions.fetchVirtualFolderAsync.success): {
      const { devices } = action.payload;
      if (devices.length === 0) return state;

      return produce(state, (draftState) => {
        mergeDevicesToState(devices, draftState);
      });
    }

    // updateDeviceAsync
    case getType(deviceActions.updateDeviceAsync.request): {
      return produce(state, (draftState) => {
        draftState.byId[action.payload.id] = {
          ...action.payload,
          resource: {
            ...action.payload.resource,
            updatedAt: new Date().toISOString(),
          },
        };
      });
    }
    case getType(deviceActions.updateDeviceAsync.success): {
      return produce(state, (draftState) => {
        const nextDevice = {
          ...action.payload,
          location: state.byId[action.payload.id].location,
        };
        draftState.byId[action.payload.id] = nextDevice;
      });
    }

    // publishDeviceAsync
    case getType(deviceActions.publishDeviceAsync.request): {
      return produce(state, (draftState) => {
        draftState.statusById[action.payload] = 'publishing';
      });
    }
    case getType(deviceActions.publishDeviceAsync.success): {
      return produce(state, (draftState) => {
        const nextDevice = {
          ...action.payload,
          location: { ...state.byId[action.payload.id].location } as Location,
        };
        draftState.statusById[action.payload.id] = 'success';
        draftState.byId[action.payload.id] = nextDevice;
      });
    }
    case getType(deviceActions.publishDeviceAsync.failure): {
      return produce(state, (draftState) => {
        draftState.statusById[action.payload.id] = 'error';
      });
    }

    // registerDeviceAsync
    case getType(deviceActions.registerDeviceAsync.success): {
      return produce(state, (draftState) => {
        draftState.byId[action.payload.id] = action.payload;
      });
    }

    // addDeviceResourceACL
    case getType(deviceActions.addDeviceResourceACL): {
      return produce(state, (draftState) => {
        const { deviceId, resourceACL } = action.payload;
        const device = draftState.byId[deviceId];
        if (!device) return;
        device.resource.r.resourceACLs.push(resourceACL);
      });
    }

    // removeDeviceResourceACL
    case getType(deviceActions.removeDeviceResourceACL): {
      return produce(state, (draftState) => {
        const { deviceId, aclId } = action.payload;
        const device = draftState.byId[deviceId];
        if (!device) return;

        device.resource.r.resourceACLs = device.resource.r.resourceACLs.filter(
          (acl) => acl.id !== aclId,
        );
      });
    }

    // removeDevice
    case getType(deviceActions.removeDevice): {
      return produce(state, (draftState) => {
        const { deviceId } = action.payload;
        delete draftState.byId[deviceId];
      });
    }

    // fetchRecentDeviceErrorsAsync
    case getType(deviceActions.fetchRecentDeviceErrorsAsync.success): {
      return produce(state, (draftState) => {
        const recentErrorsById: {
          [deviceId: string]: RecentDeviceError[];
        } = {};

        action.payload.forEach((deviceError) => {
          if (!recentErrorsById[deviceError.deviceId]) {
            recentErrorsById[deviceError.deviceId] = [];
          }
          recentErrorsById[deviceError.deviceId].push(deviceError);
        });

        draftState.recentErrorsById = recentErrorsById;
      });
    }

    // Move Devices
    case getType(deviceActions.moveDevicesToAnotherLocation):
      const movingDevices = action.payload.deviceIds;
      const selecttedLocation = action.payload.location;

      const updatedDevices = { ...state.byId };
      movingDevices.forEach((deviceId) => {
        const updatedDevice = {
          ...state.byId[deviceId],
          location: selecttedLocation,
        };
        updatedDevices[deviceId] = updatedDevice;
      });

      return produce(state, (draftState) => {
        draftState.byId = updatedDevices;
      });

    default: {
      return state;
    }
  }
}
