import MarketplaceModal from './MarketplaceModal';

interface PurchaseSuccessModalProps {
  open: boolean;
  onClick: () => void;
}

const PurchaseSuccessModal = ({ open, onClick }: PurchaseSuccessModalProps) => {
  return (
    <MarketplaceModal
      heading="Thank you for your purchase"
      content="You can now go to your library and create a new presentation using this app."
      open={open}
      handleClick={onClick}
    />
  );
};

export default PurchaseSuccessModal;
