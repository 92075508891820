import ScreensFilterControl from '../ScreensFilterControl';
import ScreensFilterFooterAction from '../ScreensFilterFooterAction';
import Select from '../../../../components/Select/Select';
import classNames from 'classnames';
import SelectOption from '../../../../components/Select/SelectOption';
import { Location } from '@raydiant/api-client-js';
import ScreensFilterListItem from '../ScreensFilterListItem';
import { useMemo, useState } from 'react';
import DropDownHeader from '../../../../components/DropDown/DropDownHeader';
import DropDownEmpty from '../../../../components/DropDown/DropDownEmpty';
import useLocations from '../../../../hooks/useLocations';
import { sortLocations } from '../../utils';

type LocationsSelectProps = {
  className?: string;
  onChange: (value: Location) => void;
  onClear: () => void;
  onRemoveLocation?: (id: Location['id']) => void;
  value: Location['id'][];
};

const getLocation = (locations: Location[], locationId: Location['id']) =>
  locations.find((location) => location.id === locationId);

const LocationsSelect = ({
  className,
  onChange,
  onClear,
  onRemoveLocation,
  value,
}: LocationsSelectProps) => {
  const { data: locations } = useLocations();
  const sortedLocations = sortLocations(locations || []);

  const [term, setTerm] = useState('');
  const [open, setOpen] = useState(false);

  const filteredLocations = useMemo(() => {
    if (term === '') return sortedLocations;

    return sortedLocations?.filter((location) =>
      location.name.toLocaleLowerCase().includes(term.toLocaleLowerCase()),
    );
  }, [sortedLocations, term]);

  const showEmpty = filteredLocations?.length === 0 && term.length > 0;

  return (
    <ScreensFilterControl
      title={<p className="font-[500] text-lg">Select location</p>}
      footer={
        value?.length > 0 && (
          <ul className="flex items-center divide-x divide-black text-sm text-primary">
            <li className="pr-2">
              <ScreensFilterFooterAction
                label="Clear locations"
                onClick={onClear}
              />
            </li>
          </ul>
        )
      }
    >
      <Select
        className={className}
        disableMobile
        header={
          <DropDownHeader className="hover:bg-transparent!">
            <input
              type="text"
              className="input no-label"
              onChange={(e) => setTerm(e.target.value)}
            />
          </DropDownHeader>
        }
        menuClassname="max-h-[300px] overflow-y-auto"
        onChange={() => {}} //we dont want to trigger on change when user is typing
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        panelClassname="p-0 min-w-min w-full left-0 !overflow-visible"
        wrapperClassName={classNames('no-label')}
        renderAnchorElement={() => <>Select location</>}
        popover
      >
        {filteredLocations
          ?.filter((location) => !value.includes(location.id))
          .map((location, key) => (
            <SelectOption
              disableMobile
              key={key}
              onClick={() => {
                onChange(locations?.find((value) => location.id === value.id)!);
                setTerm('');
                setOpen(false);
              }}
              value={location.id}
            >
              {location.name}
            </SelectOption>
          ))}
        {showEmpty && <DropDownEmpty />}
      </Select>
      {value && (
        <ul>
          {value?.map((locationId) => {
            const location = getLocation(locations!, locationId);
            return (
              <ScreensFilterListItem
                key={locationId}
                label={
                  <div className="flex justify-between items-center w-full">
                    <p className="capitalize text-ellipsis overflow-hidden max-w-[100px]">
                      {location?.name}
                    </p>
                  </div>
                }
                onClear={() =>
                  onRemoveLocation ? onRemoveLocation(locationId) : undefined
                }
              />
            );
          })}
        </ul>
      )}
    </ScreensFilterControl>
  );
};

export default LocationsSelect;
