import classNames from 'classnames';
import React from 'react';

type ScreensFilterListItemProps = {
  label: React.ReactNode;
  onClear?: () => void;
};

const ScreensFilterListItem = ({
  label,
  onClear,
}: ScreensFilterListItemProps) => {
  return (
    <li
      className={classNames(
        'flex items-center justify-between p-3 text-base text-zinc-500',
        onClear ? 'gap-4' : '',
      )}
    >
      {label}
      {onClear && (
        <button className="text-sm mini" onClick={onClear}>
          <svg
            className="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 512 512"
            fill="currentColor"
          >
            <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" />
          </svg>
        </button>
      )}
    </li>
  );
};

export default ScreensFilterListItem;
