import { Device, RecentDeviceError } from '@raydiant/api-client-js';
import Icon from 'raydiant-elements/core/Icon';
import SVGIcon from 'raydiant-elements/core/SVGIcon';
import EthernetAbsentIcon from 'raydiant-elements/icons/EthernetAbsent';
import { useMemo } from 'react';
import cn from 'classnames';
import { getDeviceConnectionStatus } from '../../utilities';
import { ContentWarning } from './getDeviceContentWarning';
import DeviceStatusPopover from './DeviceStatusPopover';
import * as errorTypes from './deviceErrorTypes';
import { useSelector } from 'react-redux';
import {
  selectIsPerchSettingsEnabled,
  selectIsUserAIEnabled,
} from '../../selectors/user';
import HttpsIcon from '@material-ui/icons/Https';
import { stopPropagation } from 'raydiant-elements/helpers';
import Gear from './Icons/Gear';
import Text from '@raydiant/raydial/components/Text';
import Wifi from './Icons/Wifi';
import WifiSlash from './Icons/WifiSlash';
import LTE from './Icons/LTE';
import Ethernet from './Icons/Ethernet';
import CircleCheck from './Icons/CircleCheck';
import CircleExclamation from './Icons/CircleExclamation';
import Dialog, { DialogTrigger } from '@raydiant/raydial/components/Dialog';
import Button from '@raydiant/raydial/components/Button';
import VideoGear from './Icons/VideoGear';
import Popover from '@raydiant/raydial/components/Popover';
import SidebarMenuTooltip from '../../components/Sidebar/SidebarMenuTooltip';

interface DeviceStatusProps {
  device: Device;
  isEditable?: boolean;
  lastLoadedDate: string;
  muted?: boolean;
  contentWarning: ContentWarning | null;
  deviceErrors: RecentDeviceError[];
  onAiSettingsClick?: () => void;
  onPerchSettingsClick?: () => void;
  onSettingsClick?: () => void;
  showAiDevice?: boolean;
  showPerchDevice?: boolean;
}

enum ConnectionTypes {
  ETHERNET = 'ethernet',
  LTE = 'lte',
  WIFI = 'wifi',
}

const DeviceStatus = ({
  device,
  lastLoadedDate,
  muted,
  contentWarning,
  isEditable,
  deviceErrors,
  onAiSettingsClick,
  onPerchSettingsClick,
  onSettingsClick,
  showAiDevice = false,
  showPerchDevice = false,
}: DeviceStatusProps) => {
  const serviceCloudChatButton = document.querySelector(
    '#helpButtonSpan > span.message',
  ) as HTMLElement;

  const openChat = () => serviceCloudChatButton?.click();

  const isUserAIEnabled = useSelector(selectIsUserAIEnabled);
  const isUserPerchSettingsEnabled = useSelector(selectIsPerchSettingsEnabled);

  // Memoizers

  const deviceError = useMemo(() => {
    // A window_end_error takes precendence over a preload error.
    const windowEndError = deviceErrors.find(
      (e) => e.event === errorTypes.windowEndError,
    );
    if (windowEndError) return windowEndError;
    const windowPreloadError = deviceErrors.find(
      (e) => e.event === errorTypes.windowPreloadError,
    );
    if (windowPreloadError) return windowPreloadError;
    return null;
  }, [deviceErrors]);

  // Render
  const {
    isOnline,
    signalStrength = 0,
    lastHeartbeatAt,
  } = getDeviceConnectionStatus(device, lastLoadedDate);

  const connectionType = device.metrics?.connectionType;
  const isEthernet = device.metrics?.connectionType === 'ethernet';
  const isLTE = device.metrics?.connectionType === 'lte';
  const isSocDevice = device.deviceType === 'SoC';

  const isSignalStrengthWeak =
    (!device.isLite || isSocDevice) && !isEthernet && signalStrength < 60;

  const isPreBelenaDevice = !device.resinUuid && !device.isLite;
  if (isPreBelenaDevice) return <>&nbsp;</>;

  const isPerchDevice = device?.services?.includes('perchview');
  const isAiDevice = device?.services?.includes('deepsight');

  const renderSignalStrength = () => {
    if (device.isLite && !isSocDevice) {
      return null;
    }

    if (!isOnline) {
      return renderOfflineStatusIcon();
    }

    switch (connectionType) {
      case ConnectionTypes.ETHERNET:
        return renderSignalStrengthEthernet();

      case ConnectionTypes.WIFI:
        return renderSignalStrengthWifi(signalStrength);

      case ConnectionTypes.LTE:
        return renderSignalStrengthLTE(signalStrength);

      default:
        // TODO We should return no active interface found icon or smth, as wifiNone icon is not very self explanatory
        return null;
    }
  };

  const renderOfflineStatusIcon = (): JSX.Element | null => {
    switch (connectionType) {
      case ConnectionTypes.ETHERNET:
        return <EthernetAbsentIcon />;

      case ConnectionTypes.WIFI:
        return <WifiSlash />;

      case ConnectionTypes.LTE:
        return <LTE />;
      default:
        // TODO We should return no active interface found icon or smth, as wifiNone icon is not very self explanatory
        return null;
    }
  };
  const renderSignalStrengthEthernet = (): JSX.Element => {
    return <Ethernet />;
  };
  const renderSignalStrengthWifi = (signalStrength: number): JSX.Element => {
    if (signalStrength > 80) {
      return <Wifi />;
    } else if (signalStrength > 50) {
      return <SVGIcon icon="wifiHigh" />;
    } else if (signalStrength > 20) {
      return <SVGIcon icon="wifiMedium" />;
    } else if (signalStrength > 0) {
      return <SVGIcon icon="wifiLow" />;
    } else {
      return <SVGIcon icon="wifiNone" />;
    }
  };
  const renderSignalStrengthLTE = (signalStrength: number): JSX.Element => {
    if (signalStrength > 80) {
      return <Icon icon="lteFull" />;
    } else if (signalStrength > 40) {
      return <Icon icon="lteMedium" />;
    } else if (signalStrength > 0) {
      return <Icon icon="lteLow" />;
    } else {
      return <Icon icon="lteNone" />;
    }
  };

  const renderStatusIcon = () => {
    if (
      !isOnline ||
      deviceError?.event === errorTypes.windowEndError ||
      contentWarning?.severity === 'error'
    ) {
      return <CircleExclamation className="bg-red-600 rounded-lg" />;
    } else if (
      isSignalStrengthWeak ||
      deviceError?.event === errorTypes.windowPreloadError ||
      contentWarning?.severity === 'warning'
    ) {
      return <CircleExclamation className="bg-red-600 rounded-lg" />;
    } else {
      return <CircleCheck className="bg-green-600 rounded-lg" />;
    }
  };

  const renderStatusText = () => {
    if (!isOnline) {
      return (
        <Text variant="caption" className="font-medium">
          Offline{' '}
          <button
            onClick={openChat}
            className={cn('live-support', 'decoration-inherit ml-1')}
          >
            Need Help?
          </button>
        </Text>
      );
    } else if (isSignalStrengthWeak) {
      return (
        <Text variant="caption" className="font-medium">
          Online, but weak {isLTE ? 'LTE' : isEthernet ? '' : 'wifi'} connection
        </Text>
      );
    } else {
      return (
        <Text variant="caption" className="font-medium">
          Online
        </Text>
      );
    }
  };

  const showVISettingsOption = showAiDevice && isAiDevice && isUserAIEnabled;
  const showRXSettingsOption =
    showPerchDevice && isUserPerchSettingsEnabled && isPerchDevice;
  const hasValidServices = showVISettingsOption || showRXSettingsOption;

  const renderDeviceServices = () => {
    return (
      <DialogTrigger>
        <Button
          aria-label="DeviceServices"
          variant="unstyled"
          className="mr-2 mt-0.5"
        >
          <VideoGear fontSize="inherit" />
        </Button>
        <Popover className="w-[124px]" placement="bottom end">
          <Dialog className="px-0 py-2 flex flex-col text-lg">
            {showVISettingsOption && (
              <Button
                variant="unstyled"
                className="px-5 py-2 text-left hover:bg-gray-200"
                onClick={onAiSettingsClick}
              >
                VI Settings
              </Button>
            )}
            {showRXSettingsOption && (
              <Button
                variant="unstyled"
                className="px-5 py-2 text-left hover:bg-gray-200"
                onClick={onPerchSettingsClick}
              >
                RX Settings
              </Button>
            )}
          </Dialog>
        </Popover>
      </DialogTrigger>
    );
  };

  const renderStatusWithPopover = () => {
    return (
      <DialogTrigger>
        <Button aria-label="Help" variant="unstyled">
          {renderStatusIcon()}
        </Button>
        <DeviceStatusPopover
          device={device}
          isOnline={isOnline}
          lastHeartbeatAt={lastHeartbeatAt}
          contentWarning={contentWarning}
          deviceError={deviceError}
        />
      </DialogTrigger>
    );
  };

  return (
    <div className="flex text-white">
      <div className={cn('flex flex-1 items-center gap-2 decoration-inherit')}>
        {renderSignalStrength()}
        {renderStatusWithPopover()}
        {renderStatusText()}
      </div>
      {hasValidServices && renderDeviceServices()}
      {isEditable ? (
        <SidebarMenuTooltip placement="bottom" title="Device Settings" arrow>
          <button
            onClick={stopPropagation(onSettingsClick)}
            disabled={!isEditable}
          >
            <Gear fontSize="inherit" />
          </button>
        </SidebarMenuTooltip>
      ) : (
        <button className="text-white" disabled>
          <HttpsIcon />
        </button>
      )}
    </div>
  );
};

export default DeviceStatus;
