import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Playlist } from '@raydiant/api-client-js';
import * as devicePageActions from '../actions';
import PlaylistSelector from '../../../components/PlaylistSelector';
import { BatchDevice } from '../deviceBatchSettings.interface';

interface DeviceBatchPlaylistSelectorProps {
  selectedProfileId: string;
  onSelectProfile: (profileId: string) => void;
  onClose: () => void;
  onNewPlaylist: () => void;
}

const DeviceBatchPlaylistSelector: FC<DeviceBatchPlaylistSelectorProps> = ({
  selectedProfileId,
  onSelectProfile,
  onClose,
  onNewPlaylist,
}) => {
  const dispatch = useDispatch();

  // Callbacks

  const updateDevice = useCallback(
    (updatedDevice: Partial<BatchDevice>) => {
      dispatch(devicePageActions.updateBatchDeviceSettings(updatedDevice));
      onClose();
    },
    [dispatch, onClose],
  );

  const updateDevicePlaylist = useCallback(
    (playlist: Playlist) => {
      updateDevice({
        playlistId: playlist.id,
        playlist,
      });
    },
    [updateDevice],
  );

  // Render

  return (
    <PlaylistSelector
      batch={true}
      selectedProfileId={selectedProfileId}
      onSelectProfile={onSelectProfile}
      onSelectBatchPlaylist={updateDevicePlaylist}
      onNewPlaylist={onNewPlaylist}
      onNewPresentation={() => {}}
    />
  );
};

export default DeviceBatchPlaylistSelector;
