import { FC, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import useStyles from './TagInput.styles';
import TagIcon from '../TagIcon';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Popover from '@material-ui/core/Popover';
import moment from 'moment';
import Spacer from 'raydiant-elements/layout/Spacer';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ActionBar from 'raydiant-elements/core/ActionBar/v2';
import classNames from 'classnames';

export interface TagInputDateTypeProps {
  dateFormat?: string;
  label: string;
  maxDate?: string | Date;
  minDate?: string | Date;
  value: string;
  onChange: (value: string | null) => void;
}

const valueToMoment = (value: string | null) =>
  value === null ? moment() : moment(value);

const TagInputDateType: FC<TagInputDateTypeProps> = ({
  dateFormat = 'MM/DD/YYYY',
  label,
  minDate,
  maxDate,
  value: initialValue,
  onChange,
}) => {
  const classes = useStyles();

  const [date, setDate] = useState<moment.Moment | null>(() =>
    valueToMoment(value),
  );
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(initialValue);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleClose = useCallback(() => {
    setDate(valueToMoment(value));
    setOpen(false);
  }, [value]);

  const handleDone = useCallback(() => {
    onChange(date ? date.format(dateFormat) : null);
    setOpen(false);
  }, [onChange, date, dateFormat]);

  useEffect(() => {
    setValue(initialValue);
    setDate(valueToMoment(value));
  }, [initialValue, value]);

  const isDirty = useMemo(() => {
    return date ? !date.isSame(value) : null;
  }, [date, value]);

  const inputValue = () => {
    if (value === null || date === null) {
      return '';
    }

    return date.format(dateFormat);
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className="border-2 border-[#f5f5f5] rounded-2xl w-full text-neutral-400 px-4 h-[40px]">
        <div className="flex items-center w-full gap-3 h-full">
          <TagIcon />
          <div className={classes.tag}>
            <span>{label}:</span>
            &nbsp;
            <input
              ref={inputRef}
              className={classes.tagTimeInput}
              onChange={(e) => setValue(e.target.value)}
              onClick={() => setOpen(true)}
              value={inputValue()}
            />
            <Popover
              open={open}
              anchorEl={inputRef.current}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <DatePicker
                variant="static"
                value={date}
                onChange={setDate}
                format="MM/DD/YYYY"
                minDate={minDate}
                maxDate={maxDate}
              />
              <div className={classes.tagInputActionsContainer}>
                <div
                  className={classNames(
                    classes.tagInputActions,
                    'flex items-center w-full',
                  )}
                >
                  <ActionBar.Action
                    icon={<CancelIcon />}
                    color="error"
                    label="Cancel"
                    onClick={handleClose}
                  />
                  <Spacer />
                  <ActionBar.Action
                    icon={<CheckCircleIcon />}
                    color="success"
                    label="Done"
                    disabled={!isDirty}
                    onClick={handleDone}
                  />
                </div>
              </div>
            </Popover>
          </div>
        </div>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default TagInputDateType;
