import Select from '../../../../../components/Select/Select';
import SelectOption from '../../../../../components/Select/SelectOption';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { selectDevicesById } from '../../../../../selectors/v2/devices';
import * as D from '../../../../../clients/mira/types/Device';
import { getTagDropDownItems } from '../../utils';
import { Tag } from '@raydiant/api-client-js';
import DropDownHeader from '../../../../../components/DropDown/DropDownHeader';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DropDownEmpty from '../../../../../components/DropDown/DropDownEmpty';

export interface TagSelectProps {
  className?: string;
  onChange: (tag: Tag) => void;
  value: Tag[];
}

const renderLabel = (tag: Tag) => `${tag.label}: ${tag.value}`;

const TagSelect = ({ className, onChange, value }: TagSelectProps) => {
  const devicesById = useSelector(selectDevicesById);
  const allDevices = Object.values(devicesById) as D.Device[];

  const [term, setTerm] = useState('');
  const [open, setOpen] = useState(false);

  const tags = useMemo(() => {
    const items = getTagDropDownItems(allDevices);

    if (term === '') return items;

    return items?.filter((tag) =>
      renderLabel(tag).toLocaleLowerCase().includes(term.toLocaleLowerCase()),
    );
  }, [allDevices, term]);

  const showEmpty = tags?.length === 0 && term.length > 0;
  const { t } = useTranslation();
  return (
    <Select
      className={className}
      disableMobile
      header={
        <DropDownHeader className="hover:bg-transparent!">
          <input
            type="text"
            className="input no-label"
            onChange={(e) => setTerm(e.target.value)}
          />
        </DropDownHeader>
      }
      menuClassname="max-h-[300px] overflow-y-auto"
      onChange={() => {}} //we dont want to trigger on change when user is typing
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      panelClassname="p-0 min-w-min w-full left-0 !overflow-visible"
      wrapperClassName={classNames('no-label')}
      renderAnchorElement={() => <p>{t('chooseTag')}</p>}
      popover
    >
      {tags
        .filter(
          ({ key: key1, value: value1 }) =>
            !value.some(
              ({ key: key2, value: value2 }) =>
                key1 === key2 && value1 === value2,
            ),
        )
        .map((tag) => (
          <SelectOption
            disableMobile
            key={`${tag.key}|${tag.value}`}
            onClick={() => {
              onChange(tag);
              setTerm('');
              setOpen(false);
            }}
            value={`${tag.key}|${tag.value}`}
          >
            {renderLabel(tag)}
          </SelectOption>
        ))}
      {showEmpty && <DropDownEmpty />}
    </Select>
  );
};

export default TagSelect;
