import Page from '../../components/Page';
import PageLayout from '../../components/PageLayout/PageLayout';
import PageTitle from '../../components/PageLayout/PageTitle';
import { useTranslation } from 'react-i18next';
import useAnalytics from '../../hooks/useAnalytics';
import EngagementByDayChart from './EngagementByDayChart';
import AnalyticsFilter from './AnalyticsFilter/AnalyticsFilter';
import { useLocation } from 'react-router';
import AnalyticsPageSkeleton from './AnalyticsPageSkeleton';
import AnalyticsCallToAction from './AnalyticsCallToAction/AnalyticsCallToAction';
import ShopperEngagementInsights from './ShopperEngagementInsights/ShopperEngagementInsights';
import Center from 'raydiant-elements/layout/Center';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import { getApiQueryParams } from './AnalyticsFilter/utils';
import TopProductsChart from './TopProductsChart';
import TopLocationsChart from './TopLocationsChart';
import TopProductsDataTableCard from './TopProductsDataTableCard';
import TopLocationsDataTableCard from './TopLocationsDataTableCard';
import {
  EngagementByLocation,
  EngagementByProduct,
  GetAnalytics,
} from '@raydiant/api-client-js';
import { useEffect, useMemo, useState } from 'react';
import { TableSort } from '../../components/DataTable/DataTableHeader';
import { paginate, sort } from './utils';
import Empty from '../../components/Empty/Empty';
import Error from '../../components/Error/Error';
import VIOnlyLayout from './VIOnlyLayout';
import {
  selectIsKioskEnabled,
  selectIsRXEnabled,
  selectUserProfile,
} from '../../selectors/user';
import { useSelector } from 'react-redux';
import CallToAction from './CallToAction';

const defaultRXLocationSort: TableSort<EngagementByLocation> = {
  direction: 'asc',
  key: 'rank',
};

const defaultKioskSort: TableSort<EngagementByLocation | EngagementByProduct> =
  {
    direction: 'desc',
    key: 'clicks',
  };

const defaultRXProductsSort: TableSort<EngagementByProduct> = {
  direction: 'desc',
  key: 'pickups',
};

const ProductEngagementPage = () => {
  const { t } = useTranslation();

  const { search } = useLocation();
  const query = getApiQueryParams(search);
  const { data, isError, isLoading } = useAnalytics(query);

  const currentUser = useSelector(selectUserProfile);
  const showLandingPage = !currentUser?.analyticsOnboardingEnabled;
  const hasQueryParams = Object.values(query).length > 0;
  const isKioskEnabled = useSelector(selectIsKioskEnabled);
  const isRXEnabled = useSelector(selectIsRXEnabled);
  const showPageContent = isRXEnabled || isKioskEnabled;
  const isAllTime = search.includes('dateRangeKey=allTime');
  const defaultProductsTableSort = isKioskEnabled
    ? defaultKioskSort
    : defaultRXProductsSort;
  const defaultLocationsTableSort = isKioskEnabled
    ? defaultKioskSort
    : defaultRXLocationSort;

  const [topProductsTableSort, setTopProductsTableSort] = useState<
    TableSort<EngagementByProduct>
  >(defaultProductsTableSort);
  const [topProductsCurrentPage, setTopProductsCurrentPage] = useState(0);
  const [topLocationsTableSort, setTopLocationsTableSort] = useState<
    TableSort<EngagementByLocation>
  >(defaultLocationsTableSort);
  const [topLocationsCurrentPage, setTopLocationsCurrentPage] = useState(0);

  const topTenProducts = sort<EngagementByProduct>(
    data?.engagementByProduct!,
    defaultProductsTableSort.key,
    defaultProductsTableSort.direction,
  )?.slice(0, 10);

  const topTenLocations = sort<EngagementByLocation>(
    data?.engagementByLocation!,
    defaultLocationsTableSort.key,
    defaultLocationsTableSort.direction,
  )?.slice(0, 10);

  const topLocationsData = useMemo(() => {
    const sortedItems = sort<EngagementByLocation>(
      data?.engagementByLocation!,
      topLocationsTableSort.key,
      topLocationsTableSort.direction,
    );
    const paginatedData = paginate<EngagementByLocation>(sortedItems);

    return {
      data: paginatedData[topLocationsCurrentPage]?.map((item, index) => ({
        rank: index + 1,
        ...item,
      })),
      pageCount: paginatedData?.length,
    };
  }, [
    data?.engagementByLocation,
    topLocationsTableSort,
    topLocationsCurrentPage,
  ]);

  const topProductsData = useMemo(() => {
    const sortedItems = sort<EngagementByProduct>(
      data?.engagementByProduct!,
      topProductsTableSort.key,
      topProductsTableSort.direction,
    );
    const paginatedData = paginate<EngagementByProduct>(sortedItems!, 10);

    return {
      data: paginatedData[topProductsCurrentPage]?.map((item, index) => ({
        rank: index + 1,
        ...item,
      })),
      pageCount: paginatedData?.length,
    };
  }, [data?.engagementByProduct, topProductsTableSort, topProductsCurrentPage]);

  useEffect(() => {
    setTopProductsCurrentPage(0);
    setTopLocationsCurrentPage(0);
  }, [isLoading]);

  const hasData =
    data &&
    Object.keys(data)
      .filter(
        (key) =>
          !['engagementByDay', 'engagementByDayPreviousPeriod'].includes(key),
      )
      ?.some((key) => data[key as keyof GetAnalytics].length > 0) &&
    data?.engagementByDay
      ?.map((value) => value.clicks + value.pickups + value.sessions)
      .reduce((current, next) => {
        return current + next;
      }) > 0;
  const showEmptyState = !isLoading && !hasData;
  const showErrorState = !isLoading && isError;
  const showCharts = !isLoading && !showEmptyState && !showErrorState;
  const showSkeleton = isLoading && !hasQueryParams;
  const showFilter = hasQueryParams;
  const showLoader = isLoading && hasQueryParams && !isError;
  const showShopperInsights = !isLoading && !showErrorState;

  if (!currentUser) {
    return (
      <Page>
        <PageLayout
          sectionClassName="min-h-full"
          title={
            <PageTitle
              title={t('analytics.overview')}
              subTitle={t('analytics.pageSubTitle')!}
            />
          }
        >
          <AnalyticsFilter className="mb-8" />
          <Center className="h-[500px]">
            <CircularProgress size={30}></CircularProgress>
          </Center>
        </PageLayout>
      </Page>
    );
  }

  return (
    <Page>
      {showLandingPage && <AnalyticsCallToAction />}
      {!showPageContent && <VIOnlyLayout />}
      {showPageContent && (
        <>
          {!showLandingPage && (
            <PageLayout
              sectionClassName="min-h-full"
              title={
                <PageTitle
                  title={t('analytics.overview')}
                  subTitle={t('analytics.pageSubTitle')!}
                />
              }
              isExportNeeded
            >
              {showSkeleton && <AnalyticsPageSkeleton />}
              {showFilter && <AnalyticsFilter className="mb-8" />}
              {showLoader && (
                <Center className="h-[500px]">
                  <CircularProgress size={30}></CircularProgress>
                </Center>
              )}
              {showShopperInsights && (
                <ShopperEngagementInsights
                  data={data!}
                  showEmpty={showEmptyState || showErrorState}
                  isRXEnabled={isRXEnabled}
                  isAllTime={isAllTime}
                />
              )}
              {showEmptyState && (
                <div className="card bg-[#fafafa] w-full h-full items-center justify-center">
                  <Empty>
                    <a
                      href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                      rel="noreferrer"
                      target="_blank"
                    >
                      {t('analytics.learnmore')}
                    </a>
                  </Empty>
                </div>
              )}
              {showErrorState && (
                <div className="card bg-[#fafafa] w-full h-full items-center justify-center">
                  <Error>
                    <a
                      href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                      rel="noreferrer"
                      target="_blank"
                    >
                      {t('analytics.learnmore')}
                    </a>
                  </Error>
                </div>
              )}
              {showCharts && (
                <div className="grid grid-cols-1 gap-8">
                  {!isRXEnabled && <CallToAction />}
                  {data?.engagementByDay && !isError && (
                    <EngagementByDayChart
                      data={data?.engagementByDay}
                      showClicks
                      showPickups
                      showSessions
                      isRXEnabled={isRXEnabled}
                    />
                  )}
                  {data?.engagementByProduct && !isError && (
                    <>
                      <TopProductsChart
                        data={topTenProducts}
                        showClicks
                        showPickups
                        showEngagedShoppers
                        showPickupsToScreen
                        isRXEnabled={isRXEnabled}
                      />
                      <TopProductsDataTableCard
                        currentPage={topProductsCurrentPage}
                        data={topProductsData?.data}
                        onPageChange={(page) => {
                          setTopProductsCurrentPage(page);
                        }}
                        onSort={(sort) => {
                          setTopProductsTableSort({
                            ...topProductsTableSort,
                            ...sort,
                          });
                          setTopProductsCurrentPage(0);
                        }}
                        pageCount={topProductsData.pageCount}
                        sort={topProductsTableSort}
                        isRXEnabled={isRXEnabled}
                        showPagination={data?.engagementByProduct.length > 10}
                      />
                    </>
                  )}
                  {data?.engagementByProduct && !isError && (
                    <>
                      <TopLocationsChart
                        data={topTenLocations}
                        showClicks
                        showPickups
                        showPickupsToScreen
                        showEngagedShoppers
                        isRXEnabled={isRXEnabled}
                      />
                      <TopLocationsDataTableCard
                        currentPage={topLocationsCurrentPage}
                        data={topLocationsData?.data}
                        onPageChange={(page) => {
                          setTopLocationsCurrentPage(page);
                        }}
                        onSort={(sort) => {
                          setTopLocationsTableSort({
                            ...topLocationsTableSort,
                            ...sort,
                          });
                          setTopLocationsCurrentPage(0);
                        }}
                        pageCount={topLocationsData?.pageCount}
                        sort={topLocationsTableSort}
                        isRXEnabled={isRXEnabled}
                        showPagination={data?.engagementByLocation.length > 10}
                      />
                    </>
                  )}
                </div>
              )}
            </PageLayout>
          )}
        </>
      )}
    </Page>
  );
};

export default ProductEngagementPage;
