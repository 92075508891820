import { useTranslation } from 'react-i18next';
import defaultIcon from '../../assets/empty.svg';
import Text from '@raydiant/raydial/components/Text';

type EmptyProps = {
  children?: React.ReactNode;
  message?: string;
  title?: string;
};

const Empty = ({ children, message, title }: EmptyProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col md:flex-row gap-4 md:gap-14 items-center md:max-w-xl h-full justify-center mx-auto">
      <div className="w-full flex items-center justify-center md:justify-start">
        <img className="w-1/2 md:w-full" src={defaultIcon} alt={title} />
      </div>
      <div className="flex flex-col gap-4">
        <Text className="text-black" variant="subheader">
          {title ?? t('empty.oops')}
        </Text>
        <p className="text-lg text-black font-regular">
          {message ?? t('empty.questions')}
        </p>
        {children && <div>{children}</div>}
      </div>
    </div>
  );
};

export default Empty;
