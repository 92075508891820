import classNames from 'classnames';

type DataTableCellProps = React.InputHTMLAttributes<HTMLTableCellElement> & {
  hasSort?: boolean;
  wrapperClassName?: string;
};

const DataTableCell = ({
  className,
  children,
  hasSort,
  wrapperClassName,
  ...others
}: DataTableCellProps) => {
  return (
    <td className={classNames(className)} {...others}>
      <div className={classNames(wrapperClassName, hasSort ? 'mr-7' : '')}>
        {children}
      </div>
    </td>
  );
};

export default DataTableCell;
