import { useTranslation } from 'react-i18next';
import ScreensFilterControl from '../ScreensFilterControl';
import ScreensFilterDropDownActions from '../ScreensFilterDropDownActions';

type DeviceResolutionProps = {
  className?: string;
  onChange: (value: DeviceResolutionQuery) => void;
  value: DeviceResolutionQuery;
};

const DEVICE_RESOLUTION_QUERY_KEYS = ['720p', '1080p', '4K', '8K'] as const;

export type DeviceResolutionType = typeof DEVICE_RESOLUTION_QUERY_KEYS[number];

export const deviceResolutionMap: {
  [key in DeviceResolutionType]: string;
} = {
  '720p': '1280x720',
  '1080p': '1920x1080',
  '4K': '3840x2160',
  '8K': '7680x4320',
};

export const deviceScreensDimensionsMap = Object.fromEntries(
  Object.entries(deviceResolutionMap).map(([key, value]) => [value, key]),
);

export type DeviceResolutionQuery = {
  [key in typeof DEVICE_RESOLUTION_QUERY_KEYS[number]]: boolean;
};

const DeviceResolution = ({ onChange, value }: DeviceResolutionProps) => {
  const { t } = useTranslation(['devices']);

  const hasValue = Object.values(value).some(Boolean);
  return (
    <ScreensFilterControl
      title={
        <p className="font-[500] text-[16px]">{t('screens.resolution')}</p>
      }
      footer={
        <ScreensFilterDropDownActions
          onSelectAll={() =>
            onChange({
              '720p': true,
              '1080p': true,
              '4K': true,
              '8K': true,
            })
          }
          onClearAll={
            hasValue
              ? () =>
                  onChange({
                    '720p': false,
                    '1080p': false,
                    '4K': false,
                    '8K': false,
                  })
              : undefined
          }
        />
      }
    >
      <form>
        <ul className="flex flex-col gap-2">
          {DEVICE_RESOLUTION_QUERY_KEYS.filter((key) => key !== '8K').map(
            (key) => (
              <li className="flex items-center" key={key}>
                <input
                  checked={value[key] ?? false}
                  name={'deviceResolution'}
                  onChange={(e) => {
                    onChange({ ...value, [key]: e.target.checked });
                  }}
                  type="checkbox"
                  value={key}
                />
                <label className="text-base text-[#79797f]">
                  {t(`screens.${key}`)}
                </label>
              </li>
            ),
          )}
        </ul>
      </form>
    </ScreensFilterControl>
  );
};

export default DeviceResolution;
