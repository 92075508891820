import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

export default makeStyles((theme: Theme) => {
  return createStyles({
    addIcon: {
      backgroundColor: theme.palette.primary.main,
      color: '#ffffff',
    },
    buttonRow: {
      marginRight: theme.spacing(3.8),
    },
  });
});
