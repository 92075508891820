import classNames from 'classnames';

type TrendArrowProps = {
  change: string;
  label: string;
};

const TrendArrow = ({ change, label }: TrendArrowProps) => {
  const hasNoChange = change === '0%';
  const isNegative = change.includes('-');
  const isPositive = !isNegative && !hasNoChange;

  return (
    <p className="flex items-center">
      <span
        className={classNames(
          'flex items-center mr-1 text-xs font-bold',
          isPositive && 'text-green-500',
          isNegative && 'text-red-400',
        )}
      >
        {!hasNoChange && (
          <svg
            className={classNames(
              'text-inherit w-3 h-3',
              isNegative && 'rotate-180',
            )}
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
          >
            <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
          </svg>
        )}
        {change}
      </span>
      <span className="text-[0.7rem]">{label}</span>
    </p>
  );
};

export default TrendArrow;
