import VisitorInsightIllust from './assets/visitor-insight-default.png';
import VisitorInsightIllustMobile from './assets/vi-mobile.png';
import cn from 'classnames';
import Hidden from 'raydiant-elements/layout/Hidden';

const NonVisitorInsightCard = () => {
  const renderDesktopContent = () => {
    return (
      <div
        className={cn('rounded-3xl vi-card w-full justify-between')}
        style={{
          backgroundImage: `url(${VisitorInsightIllust}), linear-gradient(108deg, #7ad1f4 -16%, #4c76e2 125%)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom right',
        }}
      >
        <div className="flex flex-col justify-between h-full">
          <div className="vi-card-header">Add Visitor Insight</div>
          <ul className="vi-card-ul">
            <li key="no-vi-1" className="vi-card-item">
              See real-time traffic metrics at your locations
            </li>
            <li key="no-vi-2" className="vi-card-item">
              Identify your best and worst converting content
            </li>
            <li key="no-vi-3" className="vi-card-item">
              Understand which content resonates by demographic
            </li>
            <li key="no-vi-4" className="vi-card-item">
              Personalize content by demographic to drive more sales
            </li>
          </ul>
          <div className="vi-button">
            <a
              className="vi-button"
              href="https://www.raydiant.com/solutions/visitor-insight-ai"
              target="_blank"
              rel="noreferrer"
            >
              Get it now!
            </a>
          </div>
        </div>
      </div>
    );
  };

  const renderMobileContent = () => {
    return (
      <div
        className={cn('rounded-3xl vi-card w-full')}
        style={{
          backgroundImage: `url(${VisitorInsightIllustMobile}), linear-gradient(108deg, #7ad1f4 -16%, #4c76e2 125%)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom right',
        }}
      >
        <div className="flex flex-col justify-between h-full">
          <div className="vi-card-header mobile">Add Visitor Insight</div>
          <div className="vi-button mobile">
            <a
              className="vi-button"
              href="https://www.raydiant.com/solutions/customer-analytics"
              target="_blank"
              rel="noreferrer"
            >
              Get it now!
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Hidden xsDown>{renderDesktopContent()}</Hidden>
      <Hidden smUp>{renderMobileContent()}</Hidden>
    </>
  );
};

export default NonVisitorInsightCard;
