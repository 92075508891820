import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Pagination, { PaginationProps } from '../Pagination/Pagination';

//This component should be moved to raydiant-elements v3 when the time comes

type DataTableProps<T> = PaginationProps & {
  data: T[];
  headers?: React.ReactNode;
  renderRow?: (item: T, index: number) => React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  showPagination?: boolean;
};

const DataTable = <T,>({
  children,
  className,
  currentPage,
  data = [],
  headers,
  onPageChange,
  pageCount,
  renderRow,
  showPagination = true,
}: DataTableProps<T>) => {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-1 gap-3">
      <div className="overflow-y-auto">
        <table className={classNames(className, 'table w-full')}>
          {headers && <thead>{headers}</thead>}
          <tbody>
            {children && children}
            {renderRow &&
              data?.map((item, index) => <>{renderRow(item, index)}</>)}
          </tbody>
        </table>
        {data?.length === 0 && (
          <div className="flex justify-center">{t('empty.oops')}</div>
        )}
      </div>
      {showPagination && (
        <div className="w-full flex justify-center">
          <Pagination
            className="mx-auto"
            onPageChange={onPageChange}
            pageCount={pageCount}
            currentPage={currentPage}
          />
        </div>
      )}
    </div>
  );
};

export default DataTable;
