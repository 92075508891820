import { Menu as HeadlessMenu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { ElementType, Fragment } from 'react';

//Note that this component should move to raydiant-elements v3 and should be the defacto dropdown component

type DropDownProps = {
  anchorElement: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  itemsClassName?: string;
  itemsClassNameAs?: ElementType;
  onChange?: () => void;
};

const DropDown = ({
  anchorElement,
  children,
  className,
  itemsClassName,
  itemsClassNameAs = 'ul',
  onChange,
}: DropDownProps) => {
  return (
    <HeadlessMenu
      as="div"
      className={classNames('relative menu dropdown-wrapper', className)}
      onChange={() => onChange && onChange()}
    >
      <HeadlessMenu.Button className="input no-label text-left w-full">
        {anchorElement}
      </HeadlessMenu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <HeadlessMenu.Items
          as={itemsClassNameAs}
          className={classNames(
            'origin-top-right dropdown w-full z-[8] !rounded-2xl max-h-[250px] !overflow-auto',
            itemsClassName,
          )}
        >
          {children}
        </HeadlessMenu.Items>
      </Transition>
    </HeadlessMenu>
  );
};

export default DropDown;
