import { Device } from '@raydiant/api-client-js';
import { Theme } from 'raydiant-elements/theme';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { buttonReset } from 'raydiant-elements/mixins';
import AlertIcon from 'raydiant-elements/core/AlertIcon';
import SidebarMenuTooltip from '../../components/Sidebar/SidebarMenuTooltip';
interface DeviceAIStatusProps {
  device: Device;
  onEditSettings?: () => void;
}

const DeviceAIStatus = ({ device, onEditSettings }: DeviceAIStatusProps) => {
  const cameraDisconnectedError = false;
  const licenseKeyWarning = false;

  const showError = [cameraDisconnectedError].some((error) => error === true);
  const showWarning = [licenseKeyWarning].some((warning) => warning === true);
  const classes = useStyles();

  return (
    <SidebarMenuTooltip title="VI settings" placement="bottom" arrow>
      <button className={classes.button} onClick={onEditSettings}>
        {showError && <AlertIcon className={classes.error} color="error" />}
        <VideocamOutlinedIcon fontSize="inherit" />
        {showWarning && (
          <AlertIcon className={classes.warning} color="warning" />
        )}
      </button>
    </SidebarMenuTooltip>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    error: {
      fontSize: 13,
      marginBottom: -13,
      zIndex: 1,
    },
    button: {
      ...buttonReset(),
      display: 'flex',
      flexDirection: 'column',
      fontSize: 24,
      alignItems: 'center',
      justifyContent: 'center',
      opacity: 0.6,
      padding: theme.spacing(0),
      '&:hover': {
        opacity: 0.8,
      },
    },
    warning: {
      fontSize: 13,
      marginTop: -13,
      zIndex: 1,
    },
  });
});

export default DeviceAIStatus;
