import { SVGProps } from 'react';

interface VideoGearProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const VideoGear = ({ size = 19, ...props }: VideoGearProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.100146 8.3001L0.700146 7.3001C0.900146 7.0001 1.20015 6.9001 1.50015 7.1001H1.70015C1.80015 7.3001 2.00015 7.3001 2.10015 7.2001C2.20015 7.2001 2.30015 7.0001 2.30015 6.9001V6.7001C2.30015 6.4001 2.60015 6.1001 2.90015 6.1001H4.10015C4.40015 6.1001 4.70015 6.4001 4.70015 6.7001V6.9001C4.70015 7.0001 4.70015 7.2001 4.90015 7.2001C5.00015 7.2001 5.20015 7.2001 5.30015 7.2001H5.50015C5.80015 6.9001 6.20015 7.0001 6.30015 7.2001L6.90015 8.2001C7.10014 8.5001 6.90015 8.8001 6.70015 9.0001H6.50015C6.40015 9.2001 6.30015 9.3001 6.30015 9.4001C6.30015 9.5001 6.30015 9.7001 6.50015 9.7001H6.70015C7.00015 10.0001 7.10014 10.3001 6.90015 10.6001L6.30015 11.6001C6.10015 11.9001 5.80015 12.0001 5.50015 11.8001H5.30015C5.20015 11.6001 5.00015 11.6001 4.90015 11.7001C4.80015 11.8001 4.70015 11.9001 4.70015 12.0001V12.3001C4.70015 12.6001 4.40015 12.9001 4.10015 12.9001H2.90015C2.60015 12.9001 2.30015 12.6001 2.30015 12.3001V12.1001C2.30015 12.0001 2.30015 11.8001 2.10015 11.8001C2.00015 11.8001 1.80015 11.8001 1.70015 11.8001H1.50015C1.20015 12.1001 0.800146 12.0001 0.700146 11.8001L0.100146 10.8001C-0.0998544 10.5001 0.100146 10.2001 0.300146 10.0001H0.500146C0.600146 9.8001 0.700146 9.7001 0.700146 9.5001C0.700146 9.3001 0.700146 9.2001 0.500146 9.2001H0.300146C0.000145614 8.9001 -0.0998544 8.6001 0.100146 8.3001ZM2.50015 9.0001C2.30015 9.4001 2.30015 9.8001 2.50015 10.2001C2.70015 10.6001 3.10015 10.8001 3.50015 10.8001C3.90015 10.8001 4.30015 10.6001 4.50015 10.2001C4.70015 9.8001 4.70015 9.4001 4.50015 9.0001C4.30015 8.6001 3.90015 8.4001 3.50015 8.4001C3.10015 8.4001 2.70015 8.6001 2.50015 9.0001Z"
        fill="currentColor"
      />
      <path
        d="M11.4004 0.900049H3.80039C2.80039 0.900049 1.90039 1.70005 1.90039 2.80005V5.20005C2.20039 5.00005 2.50039 4.90005 2.90039 4.90005H4.10039C4.70039 4.90005 5.20039 5.30005 5.50039 5.70005C5.50039 5.70005 5.50039 5.80005 5.50039 5.90005C6.10039 5.90005 6.70039 6.10005 7.10039 6.70005L7.70039 7.70005C8.00039 8.30005 8.00039 9.00005 7.60039 9.50005C7.60039 9.50005 7.60039 9.60005 7.70039 9.70005C8.00039 10.2 8.00039 10.8 7.70039 11.3L7.20039 12.2H11.4004C12.4004 12.2 13.3004 11.4 13.3004 10.3V2.70005C13.3004 1.70005 12.5004 0.800049 11.4004 0.800049V0.900049Z"
        fill="currentColor"
      />
      <path
        d="M18.5008 1.99999C18.2008 1.79999 17.8008 1.89999 17.5008 1.99999L14.7008 3.89999L14.3008 4.19999V8.99999L14.7008 9.29999L17.5008 11.2C17.8008 11.4 18.2008 11.4 18.5008 11.2C18.8008 11 19.0008 10.7 19.0008 10.4V2.79999C19.0008 2.49999 18.8008 2.09999 18.5008 1.99999Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default VideoGear;
