import PlaybackTimeDistributionIllustration from './assets/playback-time-distribution-illustration.svg';
import BestPerformingContentIllustration from './assets/best-performing-content-illustration.svg';
import ContentEffectivenessIllustration from './assets/content-effectiveness-illustration.svg';
import TagsIllustration from './assets/content-engagement-tags-illustration.svg';
import CallToAction from './CallToAction';
import Hidden from 'raydiant-elements/layout/Hidden';

const VIOnlyLayout = () => {
  const renderDesktopLayout = () => {
    return (
      <div className="p-6">
        <CallToAction />
        <div className="p-6">
          <div className="flex rounded-3xl justify-center gap-6">
            <div>
              <img
                src={PlaybackTimeDistributionIllustration}
                alt="playback time distribution"
              />
            </div>
            <div>
              <img
                src={BestPerformingContentIllustration}
                alt="best performing content"
              />
            </div>
          </div>
          <div className="flex rounded-3xl justify-center gap-6 mt-3">
            <div>
              <img
                src={ContentEffectivenessIllustration}
                alt="content effectiveness"
              />
            </div>
            <div>
              <img src={TagsIllustration} alt="tags" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderMobileLayout = () => {
    return (
      <div className="p-1">
        <CallToAction />
        <div>
          <div className="flex rounded-3xl flex-col">
            <div className="flex justify-center p-4 mt-3">
              <img
                src={PlaybackTimeDistributionIllustration}
                alt="playback time distribution"
              />
            </div>
            <div>
              <img
                src={BestPerformingContentIllustration}
                alt="best performing content"
              />
            </div>
          </div>
          <div className="flex rounded-3xl flex-col">
            <div>
              <img
                src={ContentEffectivenessIllustration}
                alt="content effectiveness"
              />
            </div>
            <div className="flex justify-center p-4">
              <img src={TagsIllustration} alt="tags" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="card">
      <Hidden xsDown>{renderDesktopLayout()}</Hidden>
      <Hidden smUp>{renderMobileLayout()}</Hidden>
    </div>
  );
};

export default VIOnlyLayout;
