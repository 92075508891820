import { useState } from 'react';
import Dialog, {
  DialogHeader,
  DialogHeading,
} from '@raydiant/raydial/components/Dialog';
import Modal from '@raydiant/raydial/components/Modal';
import Button from '@raydiant/raydial/components/Button';
import Text from '@raydiant/raydial/components/Text';
import Link from '@raydiant/raydial/components/Link';
import CollaborateWithOthersImg from './collaborate-with-others.svg';
import ManageScreensImg from './manage-screens.svg';
import OrganizeContentImg from './organize-content.svg';
import OrganizeScreensUsingLocationsImg from './organize-screens-using-locations.svg';
import LocationsPageExampleImg from './locations-page-example.svg';
import useUpdateOnboardingStatus from '../../../../hooks/useUpdateOnboardingStatus';

export interface LocationOnboardingDialogProps {
  isOpen: boolean;
}

const LocationOnboardingDialog = ({
  isOpen,
}: LocationOnboardingDialogProps) => {
  const [onboardingStep, setOnboardingStep] = useState<1 | 2 | 3>(1);

  const updateOnboardingStatus = useUpdateOnboardingStatus();

  const handleUpdateOnboardingStatus = async () => {
    try {
      await updateOnboardingStatus.mutate({ name: 'locations' });
    } catch (error) {
      console.error(error);
    }
  };

  const renderGetStartedContent = () => {
    return (
      <div className="text-black">
        <DialogHeader>
          <DialogHeading>Let's Get Started</DialogHeading>
        </DialogHeader>
        <div className="flex flex-col pt-4 gap-8 md:pl-8 md:pr-32 ">
          <div className="flex items-center">
            <img src={ManageScreensImg} alt="manage access" />
            <div className="flex flex-col flex-1 ml-6">
              <Text variant="smallSubheader">Manage Screens</Text>
              <Text className="text-gray-600" variant="body">
                Manage screens for each of your locations
              </Text>
            </div>
          </div>
          <div className="flex items-center">
            <img src={OrganizeContentImg} alt="organize content" />
            <div className="flex flex-col flex-1 ml-6">
              <Text variant="smallSubheader">Organize Content</Text>
              <Text className="text-gray-600" variant="body">
                Manage content across all of your locations
              </Text>
            </div>
          </div>
          <div className="flex items-center">
            <img src={CollaborateWithOthersImg} alt="collaborate with others" />
            <div className="flex flex-col flex-1 ml-6">
              <Text variant="smallSubheader">Collaborate with others</Text>
              <Text className="text-gray-600" variant="body">
                Collaborate on screen and content management with your team
              </Text>
            </div>
          </div>
        </div>
        <footer className="flex flex-wrap gap-6 pb-4 px-4 mt-8">
          <Button
            size="large"
            variant="primary"
            className="grow"
            onClick={() => setOnboardingStep(2)}
          >
            Let's Go!
          </Button>
        </footer>
      </div>
    );
  };

  const renderOrganizeLocationsContent = () => {
    return (
      <div className="text-black">
        <DialogHeader>
          <DialogHeading>Organize screens using locations</DialogHeading>
        </DialogHeader>
        <div className="flex justify-center items-center pt-4 pb-8">
          <img
            src={OrganizeScreensUsingLocationsImg}
            alt="organize screens using locations"
          />
        </div>
        <div className="flex flex-col gap-8 px-4">
          <Text variant="body">
            Locations are a helpful way of organizing your screens so that you
            can easily find/manage screens for a specific location (ie: store or
            building) and be able to give a user access to manage screens at a
            specific location.
          </Text>
          <Text variant="body">
            For each physical location where you are managing Raydiant, we
            recommend creating a new location for those location specific
            screens. You can choose any name for your location based on how your
            organization references an individual store/building.
          </Text>
        </div>

        <footer className="flex flex-wrap gap-6 pb-4 px-4 mt-8">
          <Button
            size="large"
            variant="primary"
            className="grow"
            onClick={() => setOnboardingStep(3)}
          >
            Next
          </Button>
        </footer>
      </div>
    );
  };

  const renderLocationsPageExampleContent = () => {
    return (
      <div className="text-black">
        <DialogHeader>
          <DialogHeading>Organize screens using locations</DialogHeading>
        </DialogHeader>
        <div className="p-4">
          <div className="flex flex-col pb-6">
            <Text variant="smallSubheader">Example: Restaurant Chain</Text>
            <Text className="text-gray-600" variant="body">
              Organize your locations by how you identify them
            </Text>
          </div>
          <div>
            <Text variant="body">
              In this example, we have a fast food chain that is managing
              Raydiant at the following three restaurants:
            </Text>
            <ul className="list-disc pl-6 text-base font-regular py-3">
              <li key="store-a">Store A — Boardwalk Shopping Centre</li>
              <li key="store-b">Store B — City Centre Mall</li>
              <li key="store-c">Store C — University Plaza</li>
            </ul>
            <Text variant="body">
              This is how we recommend that you create your locations and manage
              screens at those locations in this scenario:
            </Text>
          </div>
          <div className="flex justify-center items-center py-6">
            <img src={LocationsPageExampleImg} alt="locations page example" />
          </div>
          <div className="inline-flex">
            <Link
              href="https://support.raydiant.com/s/article/Your-Screens-page-1660092388713"
              target="_blank"
              rel="noreferrer"
            >
              Learn more about Locations
            </Link>
          </div>
        </div>
        <footer className="flex flex-wrap gap-6 pb-4 px-4 pt-8">
          <Button
            size="large"
            variant="secondary"
            className="grow"
            onClick={() => setOnboardingStep(2)}
          >
            Back
          </Button>
          <Button
            size="large"
            variant="primary"
            className="grow"
            onClick={() => handleUpdateOnboardingStatus()}
          >
            Next
          </Button>
        </footer>
      </div>
    );
  };

  const renderLocationOnboardingDialogContent = (step: number) => {
    switch (step) {
      case 1:
        return renderGetStartedContent();
      case 2:
        return renderOrganizeLocationsContent();
      case 3:
        return renderLocationsPageExampleContent();
      default:
        return renderGetStartedContent();
    }
  };

  return (
    <Modal isOpen={isOpen} className="overflow-auto">
      <Dialog className="grid gap-4 relative">
        {renderLocationOnboardingDialogContent(onboardingStep)}
      </Dialog>
    </Modal>
  );
};

export default LocationOnboardingDialog;
