import cn from 'classnames';
import Text from '@raydiant/raydial/components/Text';
import Button from '@raydiant/raydial/components/Button';

interface EmptyLocationCardItemProps {
  classNames?: string;
  content: string;
  buttonText: string;
  onClick: () => void;
}

const EmptyLocationCardItem = ({
  classNames,
  content,
  buttonText,
  onClick,
}: EmptyLocationCardItemProps) => {
  return (
    <div
      className={cn(
        'flex flex-col flex-1 justify-center items-center',
        classNames,
      )}
    >
      <Text className="p-2.5 text-center whitespace-pre-line" variant="body">
        {content}
      </Text>
      <Button className="mt-4 min-w-[132px] px-0" onClick={() => onClick()}>
        {buttonText}
      </Button>
    </div>
  );
};

export default EmptyLocationCardItem;
