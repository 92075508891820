import ChartCard from '../ChartCard/ChartCard';
import { useTranslation } from 'react-i18next';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { FunnelChart } from 'echarts/charts';
import { CHART_DEFAULT_OPTIONS } from '../utils';
import Hidden from 'raydiant-elements/layout/Hidden';
import { Popover } from '@headlessui/react';
import QuestionMarkIcon from '../../../components/QuestionMarkIcon';
import { TrafficSummary } from '@raydiant/api-client-js';
import { abbreviateNumber } from 'js-abbreviation-number';

echarts.use([FunnelChart]);

interface TrafficFunnelChartProps {
  data: TrafficSummary;
  sessions: number;
  showSessions?: boolean;
}

const colors = ['#FDDA5F', '#009DDB', '#7AD1F4', '#CCEBF8'];

const TrafficFunnelChart = ({
  data,
  sessions,
  showSessions = false,
}: TrafficFunnelChartProps) => {
  const { t } = useTranslation();

  const dataSet = [
    { value: data.ots, name: 'Traffic/OTS' },
    { value: data.impressions, name: 'Unique Impressions' },
    { value: data.verifiedViews, name: 'Verified Viewers' },
    { value: data.engagedViews, name: 'Engaged Viewers' },
  ];

  const options = {
    ...CHART_DEFAULT_OPTIONS,
    title: {
      text: '',
    },
    color: showSessions ? [...colors, '#E5F5FB'] : colors,
    tooltip: {
      trigger: 'item',
      show: false,
    },
    legend: {
      data: [],
    },
    series: [
      {
        name: '',
        type: 'funnel',
        left: '4%',
        top: 30,
        bottom: 90,
        width: 400,
        min: 0,
        max: Math.max(
          ...[
            data.ots,
            data.impressions,
            data.verifiedViews,
            data.engagedViews,
            sessions,
          ],
        ),
        minSize: '30%',
        maxSize: '100%',
        sort: 'none',
        gap: 2,
        label: {
          show: true,
          position: 'inside',
          // formatter: [
          //   '{a|Style "a" is applied to this fragment}',
          //   '{b|Style "b" is applied to this fragment}This fragment use default style{x|use style "x"}'
          // ].join('\n'),
          formatter: function (params: any) {
            return `{a|${params.name}} \n {b|${abbreviateNumber(
              params.value,
            )}}`;
          },
          rich: {
            a: {
              color: '#000',
              fontSize: 14,
              fontWeight: 700,
            },
            b: {
              color: '#000',
              fontSize: 12,
              fontWeight: 400,
            },
          },
        },
        labelLine: {
          length: 30,
          lineStyle: {
            width: 4,
            type: 'solid',
            color: '#D9D9D9',
          },
        },
        emphasis: {
          label: {
            position: 'inside',
          },
        },
        itemStyle: {
          borderColor: '#fff',
          borderWidth: 1,
        },
        data: showSessions
          ? [...dataSet, { value: sessions, name: 'Engaged Shoppers' }]
          : dataSet,
      },
    ],
  };

  const mobileOptions = {
    ...options,
    series: [
      {
        ...options.series[0],
        top: 0,
        left: 0,
        bottom: 20,
        width: 315,
        label: {
          show: true,
          position: 'inside',
        },
        labelLine: {
          show: false,
        },
      },
    ],
  };

  return (
    <ChartCard
      subTitle={''}
      title={
        <div className="flex gap-3 items-center">
          <h2 className="text-black text-xl font-bold">
            {t('analytics.trafficAwarenessPage.trafficFunnel.title')}
          </h2>
          <Popover className="relative">
            <Popover.Button>
              <QuestionMarkIcon />
            </Popover.Button>
            <Popover.Panel className="absolute z-10 card popover">
              <div className="overflow-hidden">
                <p className="mb-3">
                  {t('analytics.trafficAwarenessPage.trafficFunnel.tooltip')}
                </p>
                <a
                  href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('analytics.learnmore')}
                </a>
              </div>
            </Popover.Panel>
          </Popover>
        </div>
      }
      showTimeInterval={false}
    >
      <div className="min-w-full min-h-full">
        <Hidden xsDown>
          <ReactECharts
            style={{ height: '410px' }}
            option={options}
            onChartReady={(chart) => chart.resize()}
          />
        </Hidden>
        <Hidden smUp>
          <ReactECharts
            style={{ height: '310px' }}
            option={mobileOptions}
            onChartReady={(chart) => chart.resize()}
          />
        </Hidden>
      </div>
    </ChartCard>
  );
};

export default TrafficFunnelChart;
