import FilterListIcon from '@material-ui/icons/FilterList';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import SwapVertReverseIcon from 'raydiant-elements/icons/SwapVertReverse';
import ActionBar from 'raydiant-elements/core/ActionBar/v2';
import Hidden from 'raydiant-elements/layout/Hidden';
import Spacer from 'raydiant-elements/layout/Spacer';
import { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from '../../clients/mira/types/Resource';
import { selectUserProfile } from '../../selectors/user';
import { SortFolderOptions, canCreateFolderItem } from '../../utilities';
import { selectSortOptions } from './selectors';
import * as actions from './actions';
import * as libraryActions from '../../pages/LibraryPage/actions';
import { useDebounce } from 'use-debounce';
interface PlaylistSelectorActionBarProps {
  selectedProfile?: R.ResourceProfile;
  sortDisabled?: boolean;
  onNewPlaylist?: () => void;
  sortOnly?: boolean;
}

const SEARCH_DEBOUNCE_TIMEOUT = 1000; //in ms

const PlaylistSelectorActionBar = ({
  selectedProfile,
  sortDisabled,
  onNewPlaylist,
  sortOnly = false,
}: PlaylistSelectorActionBarProps) => {
  const dispatch = useDispatch();

  // Selectors

  const sortOptions = useSelector(selectSortOptions);
  const currentUser = useSelector(selectUserProfile);

  // State

  const [isSortMode, setIsSortMode] = useState(false);
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const [debouncedSearchTerm] = useDebounce(
    searchTerm,
    SEARCH_DEBOUNCE_TIMEOUT,
  );
  // Callbacks

  const toggleSort = useCallback(
    (property: SortFolderOptions['property']) => {
      let direction: SortFolderOptions['direction'] =
        property === 'updatedAt' ? 'desc' : 'asc';

      if (property === sortOptions.property) {
        direction = sortOptions.direction === 'asc' ? 'desc' : 'asc';
      }

      dispatch(actions.setSortOptions({ property, direction }));

      setIsSortMode(false);
    },
    [dispatch, setIsSortMode, sortOptions],
  );

  useEffect(() => {
    let params: libraryActions.LoadLibraryPageParams = {
      profileId: selectedProfile?.id,
    };

    if (debouncedSearchTerm.length) params.search = debouncedSearchTerm;

    dispatch(libraryActions.loadLibraryPage(params));
  }, [dispatch, selectedProfile?.id, debouncedSearchTerm]);

  // Side-effects
  // Reset search query when exiting search mode.
  useEffect(() => {
    if (!isSearchMode) setSearchTerm('');
  }, [isSearchMode]);

  // Render

  let sortLabel = 'Sort';
  if (sortOptions.property === 'name') {
    sortLabel = `${sortLabel}: Name`;
  } else if (sortOptions.property === 'updatedAt') {
    sortLabel = `${sortLabel}: Date`;
  }

  const isSortByName = sortOptions.property === 'name';
  const isSortByDate = sortOptions.property === 'updatedAt';
  const isReverseSort = sortOptions.direction === 'desc';

  const sortActionOptions = (
    <>
      <ActionBar.SelectOption
        icon={
          isSortByName && isReverseSort ? (
            <SwapVertReverseIcon />
          ) : (
            <SwapVertIcon />
          )
        }
        label="Name"
        selected={isSortByName}
        onClick={() => toggleSort('name')}
      />
      <ActionBar.SelectOption
        icon={
          isSortByDate && isReverseSort ? (
            <SwapVertReverseIcon />
          ) : (
            <SwapVertIcon />
          )
        }
        label="Date"
        selected={isSortByDate}
        onClick={() => toggleSort('updatedAt')}
      />
    </>
  );

  const sortAction = (
    <>
      <Hidden mdDown>
        <ActionBar.Select
          icon={<FilterListIcon />}
          label={sortLabel}
          open={isSortMode}
          onOpen={setIsSortMode}
          disabled={sortDisabled}
        >
          {sortActionOptions}
        </ActionBar.Select>
      </Hidden>
      <Hidden lgUp>
        <ActionBar.Select
          icon={<FilterListIcon />}
          open={isSortMode}
          onOpen={setIsSortMode}
          disabled={sortDisabled}
        >
          {sortActionOptions}
        </ActionBar.Select>
      </Hidden>
    </>
  );

  const newPlaylistDisabled =
    currentUser &&
    selectedProfile &&
    !canCreateFolderItem(currentUser, selectedProfile);

  const newPlaylist = (
    <>
      <Hidden mdDown>
        <ActionBar.Action
          icon={<PlaylistAddIcon />}
          disabled={newPlaylistDisabled}
          onClick={onNewPlaylist}
        />
      </Hidden>
      <Hidden lgUp>
        <ActionBar.Action
          icon={<PlaylistAddIcon />}
          disabled={newPlaylistDisabled}
          onClick={onNewPlaylist}
        />
      </Hidden>
    </>
  );

  const searchAction = isSearchMode ? (
    <ActionBar.Input
      autoFocus
      label="Search"
      maxWidth={145}
      icon={
        <ActionBar.Action
          icon={<CloseIcon />}
          onClick={() => setIsSearchMode(false)}
        />
      }
      onChange={setSearchTerm}
      value={searchTerm}
    />
  ) : (
    <ActionBar.Action
      icon={<SearchIcon />}
      onClick={() => setIsSearchMode(true)}
    />
  );

  return (
    <ActionBar>
      <Hidden smDown>
        {sortAction}
        {!sortOnly && onNewPlaylist && newPlaylist}
        <Spacer />
        {searchAction}
      </Hidden>

      <Hidden mdUp>
        {sortAction}
        {!isSearchMode && !sortOnly && onNewPlaylist && newPlaylist}
        {searchAction}
      </Hidden>
    </ActionBar>
  );
};

export default PlaylistSelectorActionBar;
