import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

export default makeStyles((theme: Theme) => {
  return createStyles({
    aiSettingList: {
      listStyleType: 'none',
      padding: 0,
    },
    aiSetting: {
      marginBottom: '10px',
    },

    licenseKey: {
      marginBottom: theme.spacing(1),
    },

    title: {
      marginBottom: theme.spacing(0.5),
    },

    type: {
      margin: 0,
    },

    section: {
      marginBottom: theme.spacing(5),

      '&:last-child': {
        marginBottom: 0,
      },
    },

    video: {
      padding: theme.spacing(3),
      borderRadius: theme.borderRadius.lg,
    },

    headingWithHelperText: {
      marginBottom: 0,
      paddingBottom: 0,
    },
  });
});
