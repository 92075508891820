const AnalyticsPageSkeleton = () => {
  return (
    <div className="grid grid-cols-1 gap-6">
      <div className="grid grid-cols-1 gap-3">
        <div className="bg-gray-100 animate-pulse h-5 w-40 rounded-lg ml-3"></div>
        <div className="bg-gray-100 animate-pulse h-3 w-80 rounded-lg ml-3"></div>
        <div className="bg-gray-100 animate-pulse h-32 w-full rounded-xl"></div>
      </div>
      <div className="grid gap-3">
        <div className="bg-gray-100 animate-pulse h-5 w-40 rounded-lg ml-3"></div>
        <div className="gap-4 flex flex-nowrap overflow-hidden">
          <div className="bg-gray-100 animate-pulse h-32 insights-card rounded-xl"></div>
          <div className="bg-gray-100 animate-pulse h-32 insights-card rounded-xl"></div>
          <div className="bg-gray-100 animate-pulse h-32 insights-card rounded-xl"></div>
          <div className="bg-gray-100 animate-pulse h-32 insights-card rounded-xl"></div>
          <div className="bg-gray-100 animate-pulse h-32 insights-card rounded-xl"></div>
          <div className="bg-gray-100 animate-pulse h-32 insights-card rounded-xl"></div>
        </div>
      </div>
      <div className="bg-gray-100 animate-pulse h-80 w-full rounded-xl"></div>
    </div>
  );
};

export default AnalyticsPageSkeleton;
