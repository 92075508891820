import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { SortDeviceOptions, SortProfileOptions } from '../../utilities';
import { BatchDevice } from './deviceBatchSettings.interface';

export const loadDevicesPage = createStandardAction('@DEVICES_PAGE/LOAD')<{
  query?: string;
}>();

export const loadDevicesPageAsync = createAsyncAction(
  '@DEVICES_PAGE/LOAD_REQUEST',
  '@DEVICES_PAGE/LOAD_SUCCESS',
  '@DEVICES_PAGE/LOAD_FAILURE',
)<void, void, Error>();

export const setSortOptions = createStandardAction(
  '@DEVICES_PAGE/SET_SORT_OPTIONS',
)<SortDeviceOptions>();

export const setSearchQuery = createStandardAction(
  '@DEVICES_PAGE/SET_SEARCH_QUERY',
)<string>();

export const setSharingSortOptions = createStandardAction(
  '@DEVICES_PAGE/SET_SHARING_SORT_OPTIONS',
)<SortProfileOptions>();

export const setSharingSearchQuery = createStandardAction(
  '@DEVICES_PAGE/SET_SHARING_SEARCH_QUERY',
)<string>();

export const setOpenContentWarningOnLoad = createStandardAction(
  '@DEVICES_PAGE/SET_OPEN_CONTENT_WARNING_ON_LOAD',
)<string>();

export const clearOpenStatusPopoverOnLoad = createStandardAction(
  '@DEVICES_PAGE/CLEAR_OPEN_STATUS_POPOVER_ON_LOAD',
)();

export const toggleBatchMode = createStandardAction(
  '@DEVICES_PAGE/TOGGLE_BATCH_MODE',
)();

export const toggleMoveScreenMode = createStandardAction(
  '@DEVICES_PAGE/TOGGLE_MOVE_SCREEN_MODE',
)();

export const updateBatchDeviceSettings = createStandardAction(
  '@DEVICES_PAGE/UPDATE_BATCH_DEVICE_SETTINGS',
)<Partial<BatchDevice>>();

export const updateSelectedDeviceIds = createStandardAction(
  '@DEVICES_PAGE/UPDATE_SELECTED_DEVICE_IDS',
)<string[]>();
