import { createSelector } from 'reselect';
import { RootState } from '../../reducers';
import * as R from '../../clients/mira/types/Resource';
import {
  sortDevices,
  sortProfiles,
  searchDevices,
  searchProfiles,
} from '../../utilities';
import { selectDevicesByOwner, BatchDevice } from '../../selectors/v2/devices';
import { selectPlaylistsById } from '../../selectors/v2/playlists';
import { selectOtherDomainProfiles } from '../../selectors/v2/domains';
import { selectDevicesFolder } from '../../selectors/v2/folders';

export const selectSortOptions = (state: RootState) =>
  state.devicesPage.sortOptions;

export const selectSearchQuery = (state: RootState) =>
  state.devicesPage.searchQuery;

export const selectSharingSortOptions = (state: RootState) =>
  state.devicesPage.sharingSortOptions;

export const selectSharingSearchQuery = (state: RootState) =>
  state.devicesPage.sharingSearchQuery;

export const selectLastLoadedDate = (state: RootState) =>
  state.devicesPage.lastLoadedDate;

export const selectStatusPopoverDeviceId = (state: RootState) =>
  state.devicesPage.statusPopoverDeviceId;

export const selectBatchMode = (state: RootState) =>
  state.devicesPage.isBatchMode;

export const selectMoveScreenMode = (state: RootState) =>
  state.devicesPage.isMoveScreenMode;

export const selectBatchDeviceSettings = (state: RootState) =>
  state.devicesPage.batchDeviceSettings;

export const selectSelectedDeviceIds = (state: RootState) =>
  state.devicesPage.selectedDeviceIds;

export const selectDeviceResults = createSelector(
  [
    selectDevicesByOwner,
    selectDevicesFolder,
    selectSortOptions,
    selectSearchQuery,
    selectLastLoadedDate,
    selectPlaylistsById,
  ],
  (devicesByOwner, devicesFolder, sortOptions, searchQuery, lastLoadedDate) => {
    const devicesByOwnerResults: {
      [profileId: string]: {
        profile: R.ResourceProfile;
        devices: BatchDevice[];
      };
    } = {};

    const data = Object.entries(devicesByOwner);

    data
      .filter(([_, { devices }]) =>
        devices.some((device) =>
          devicesFolder?.devices
            ?.map((device) => device.id)
            .includes(device.id),
        ),
      )
      .forEach(([profileId, { profile, devices }]) => {
        const searchedDevices = searchDevices(devices, searchQuery);
        devicesByOwnerResults[profileId] = {
          profile,
          devices: sortDevices(
            searchedDevices,
            sortOptions,
            lastLoadedDate,
          ).filter((device) =>
            devicesFolder?.devices
              ?.map((device) => device.id)
              .includes(device.id),
          ),
        };
      });

    return devicesByOwnerResults;
  },
);

export const selectLocationsPageDeviceResults = createSelector(
  [
    selectDevicesByOwner,
    selectDevicesFolder,
    selectSortOptions,
    selectSearchQuery,
    selectLastLoadedDate,
    selectPlaylistsById,
  ],
  (devicesByOwner, devicesFolder, sortOptions, lastLoadedDate) => {
    const devicesByOwnerResults: {
      [profileId: string]: {
        profile: R.ResourceProfile;
        devices: BatchDevice[];
      };
    } = {};

    const data = Object.entries(devicesByOwner);

    data
      .filter(([_, { devices }]) =>
        devices.some((device) =>
          devicesFolder?.devices
            ?.map((device) => device.id)
            .includes(device.id),
        ),
      )
      .forEach(([profileId, { profile, devices }]) => {
        devicesByOwnerResults[profileId] = {
          profile,
          devices: sortDevices(devices, sortOptions, lastLoadedDate).filter(
            (device) =>
              devicesFolder?.devices
                ?.map((device) => device.id)
                .includes(device.id),
          ),
        };
      });

    return devicesByOwnerResults;
  },
);

export const selectSharingProfileResults = createSelector(
  [
    selectOtherDomainProfiles,
    selectSharingSortOptions,
    selectSharingSearchQuery,
  ],
  (profiles, sortOptions, searchQuery) => {
    const searchedProfiles = searchProfiles(profiles, searchQuery);
    return sortProfiles(searchedProfiles, sortOptions);
  },
);

export const selectIsLoadingScreens = (state: RootState) =>
  state.devicesPage.isLoading;

export const selectScreensPageHasError = (state: RootState) =>
  state.devicesPage.error?.length > 0;
