import VisitorInsightIllust from './assets/visitor-insight-default.png';
import VisitorInsightIllustMobile from './assets/vi-mobile.png';
import cn from 'classnames';
import Hidden from 'raydiant-elements/layout/Hidden';

const VisitorInsightCard = () => {
  const renderDesktopContent = () => {
    return (
      <div
        className={cn('rounded-3xl vi-card w-full justify-between vi-only')}
        style={{
          backgroundImage: `url(${VisitorInsightIllust}), linear-gradient(108deg, #7ad1f4 -16%, #4c76e2 125%)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom right',
        }}
      >
        <div className="flex flex-col justify-between h-full">
          <div className="vi-card-header">Visitor Insight</div>
          <div key="no-vi-1" className="vi-card-item vi-full">
            Use Visitor InSight&#8482; to unlock game-changing location, screen,
            and content intelligence.
          </div>
          <div className="vi-button">
            <a
              className="vi-button"
              href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
              target="_blank"
              rel="noreferrer"
            >
              Learn More
            </a>
          </div>
        </div>
      </div>
    );
  };

  const renderMobileContent = () => {
    return (
      <div
        className={cn('rounded-3xl vi-card w-full')}
        style={{
          backgroundImage: `url(${VisitorInsightIllustMobile}), linear-gradient(108deg, #7ad1f4 -16%, #4c76e2 125%)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom right',
        }}
      >
        <div className="flex flex-col justify-between h-full">
          <div className="vi-card-header mobile">Visitor Insight</div>
          <div className="vi-button mobile">
            <a
              className="vi-button"
              href="https://support.raydiant.com/s/article/How-do-I-use-Raydiant-Analytics"
              target="_blank"
              rel="noreferrer"
            >
              Learn More
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Hidden xsDown>{renderDesktopContent()}</Hidden>
      <Hidden smUp>{renderMobileContent()}</Hidden>
    </>
  );
};

export default VisitorInsightCard;
