import { Location, Device } from '@raydiant/api-client-js';
import Dialog, { DialogTrigger } from '@raydiant/raydial/components/Dialog';
import Button from '@raydiant/raydial/components/Button';
import Popover from '@raydiant/raydial/components/Popover';
import Text from '@raydiant/raydial/components/Text';
import { getLimitedAccessUsersWithScreens } from '../../utils';
import Divider from '@raydiant/raydial/components/Divider';
import { User } from '../../Icons';

interface LocationShareStatusProps {
  devices: Device[];
  location: Location;
}

const LocationShareStatus = ({
  devices,
  location,
}: LocationShareStatusProps) => {
  const wholeAccessUsers = location.acl?.map((user) => user.grantProfile) || [];

  const wholeUserAccessProfileIds = wholeAccessUsers.map((user) => user.id);

  const limitedAccessUsers = getLimitedAccessUsersWithScreens(devices).filter(
    (item) => !wholeUserAccessProfileIds.includes(item.profile.id),
  );

  return (
    <DialogTrigger>
      <Button aria-label="share" variant="unstyled" className="ml-2">
        <div className="relative mt-3">
          <div className="flex">
            <User />
            <div className="ml-1 w-9 bg-gray-200 text-gray-600 rounded-3xl text-base font-medium">
              {wholeAccessUsers.length + limitedAccessUsers.length}
            </div>
          </div>
        </div>
      </Button>
      <Popover className="w-[247px] text-black" placement="bottom end">
        <Dialog>
          <div className="overflow-hidden">
            <p className="mb-3">
              <Text variant="smallSubtitle">User Access</Text>
              <div className="mb-3" />
              <Text variant="body" className="font-semibold mb-2">
                Location Members, All Screens
              </Text>
              {wholeAccessUsers.length > 0 && (
                <ul className="!list-disc mt-2 ml-4">
                  {wholeAccessUsers.map(
                    (user) =>
                      user.name && (
                        <li key={user.id}>
                          <Text variant="body" className="capitalize">
                            {user.name}
                          </Text>
                        </li>
                      ),
                  )}
                </ul>
              )}
              <Divider className="my-2.5" />
              <Text variant="body" className="font-semibold">
                Limited Access, Specific screens
              </Text>
              {limitedAccessUsers.length > 0 && (
                <ul className="!list-disc mt-2 ml-4">
                  {limitedAccessUsers.map(
                    ({ profile, devices }) =>
                      profile.name && (
                        <li key={profile.id}>
                          <Text variant="body" className="capitalize">{`${
                            profile.name
                          } - ${devices[0].name} ${
                            devices.length > 1 ? `+${devices.length - 1}` : ''
                          }`}</Text>
                        </li>
                      ),
                  )}
                </ul>
              )}
            </p>
          </div>
        </Dialog>
      </Popover>
    </DialogTrigger>
  );
};

export default LocationShareStatus;
