import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import { buttonReset, textTruncate } from 'raydiant-elements/mixins';

export default makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      width: theme.spacing(18),

      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(24),
      },
    },

    checkmark: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      zIndex: 2,
      color: '#28d972',
      position: 'relative',
      bottom: '-0.75rem',
      left: '0.75rem',
    },

    auto: {
      width: '100%',
      maxWidth: theme.spacing(24),
    },

    thumbnail: {
      ...buttonReset(),
      position: 'relative',
      width: '100%',
      paddingTop: `${100 / (16 / 9)}%`, // Preserve a 16:9 aspect ratio
      boxShadow: theme.shadows[3],
    },

    image: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: '#000',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundClip: 'padding-box',
    },

    cta: {
      marginTop: theme.spacing(1),
      fontSize: theme.fontSizes.sm,
      textAlign: 'center',
      ...textTruncate(),
    },

    overlay: {
      ...buttonReset(),
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },

    buyButton: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      fontSize: theme.fontSizes.sm,
      backgroundColor: '#0047d4',
      color: '#fff',
      letterSpacing: '0.11px',
      fontWeight: 520,
      padding: `${theme.spacing(0.4)}px ${theme.spacing(2.3)}px`,
      margin: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
      borderRadius: theme.spacing(0.7),
    },

    checkIcon: {
      backgroundColor: '#ffffff',
      borderRadius: '1.5rem',
    },
  });
});
