import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import { Tag } from '@raydiant/api-client-js';
import Column from 'raydiant-elements/layout/Column';
import Row from 'raydiant-elements/layout/Row';
import TagInput from '../../../components/TagInput';
import {
  tagData,
  tagOptionGroups,
  TagKey,
  TagOption,
  TagOptionGroup,
} from '../../../components/TagManager/tagManagerData';
import Select from 'raydiant-elements/core/Select';
import InputLabel from 'raydiant-elements/core/InputLabel';
import { MenuItem } from '@material-ui/core';
import { Hidden } from 'raydiant-elements/layout/Hidden/Hidden';
import NestedMenuItem from '../../../components/NestedMenuItem';
import { BatchTagKey } from '../deviceBatchSettings.interface';
import useStyles from './DeviceBatchTagManager.styles';
import { isTagAdded } from '../deviceBatchSettingsUtils';

interface DeviceBatchTagManagerProps {
  tags: Tag[];
  action: BatchTagKey;
  selectedKey: string | null;
  open: boolean;
  selectedAction: BatchTagKey | null;
  hideSchedulingTags: boolean;
  labelOnly?: boolean;
  onEdit?: (action: BatchTagKey | null, tagKey: string | null) => void;
  onChange?: (action: BatchTagKey, tag: Tag, value: Tag['value']) => void;
  onMenuItemClick: (tagKey: TagKey) => void;
  onToggle: (action: BatchTagKey) => void;
  onDelete: (action: BatchTagKey, tag: Tag) => void;
}

const renderTagLabel = (action: BatchTagKey) => {
  switch (action) {
    case 'removeKeyTags':
      return 'Clear tag';
    case 'removeValueTags':
      return 'Remove tag values';
    case 'overwriteTags':
      return 'Overwrite a tag';
    case 'addTags':
      return 'Add a tag';
    default:
      return 'Add a tag';
  }
};

const DeviceBatchTagManager = ({
  tags,
  action,
  selectedKey,
  open,
  selectedAction,
  hideSchedulingTags,
  labelOnly = false,
  onEdit,
  onMenuItemClick,
  onToggle,
  onChange,
  onDelete,
}: DeviceBatchTagManagerProps) => {
  const classes = useStyles();

  const showAllRootMenuItems = (group: TagOptionGroup) => {
    if (hideSchedulingTags) {
      return group.name !== 'Schedule';
    }

    return true;
  };

  const renderMenuItem = (
    option: TagOption | TagOptionGroup,
    index: number,
  ) => {
    if ('groups' in option) {
      return (
        <NestedMenuItem
          className={classes.menuItem}
          disabled={option.groups.every((group) =>
            group.options.every((tagKey) => isTagAdded(tags, tagKey)),
          )}
          key={index}
          label={option.name}
          parentMenuOpen={open}
        >
          {option.groups.map(renderMenuItem)}
        </NestedMenuItem>
      );
    } else {
      return (
        <NestedMenuItem
          className={classes.menuItem}
          disabled={option.options.every((tagKey) => isTagAdded(tags, tagKey))}
          key={index}
          label={option.name}
          parentMenuOpen={open}
        >
          {option.options.map((tagKey, index) => (
            <MenuItem
              disabled={isTagAdded(tags, tagKey)}
              key={index}
              value={tagKey}
              selected={tagKey === selectedKey}
              onClick={() => {
                onMenuItemClick(tagKey);
                onToggle(action);
              }}
            >
              {tagData[tagKey].label}
            </MenuItem>
          ))}
        </NestedMenuItem>
      );
    }
  };

  const renderDesktopTagMenu = () => (
    <div>
      <InputLabel>{renderTagLabel(action)}</InputLabel>
      <Select
        native={false}
        open={open}
        onChange={() => onToggle(action)}
        onClose={() => onToggle(action)}
        onOpen={() => onToggle(action)}
        value={selectedKey ?? ''}
      >
        {tagOptionGroups.filter(showAllRootMenuItems).map(renderMenuItem)}
      </Select>
    </div>
  );

  const renderMobileMenuItem = (
    option: TagOption | TagOptionGroup,
    index: number,
  ): any => {
    if ('groups' in option) {
      return option.groups.map(renderMobileMenuItem);
    } else {
      return option.options.map((tagKey) => (
        <option disabled={isTagAdded(tags, tagKey)} key={index} value={tagKey}>
          {tagData[tagKey].label}
        </option>
      ));
    }
  };

  const renderMobileTagMenu = () => (
    <div>
      <InputLabel>{renderTagLabel(action)}</InputLabel>
      <Select
        native={true}
        onChange={(event) => onMenuItemClick(event as TagKey)}
        value=""
      >
        <option value=""></option>
        {tagOptionGroups.filter(showAllRootMenuItems).map(renderMobileMenuItem)}
      </Select>
    </div>
  );

  return (
    <>
      <>
        <Hidden xsDown>{renderDesktopTagMenu()}</Hidden>
        <Hidden smUp>{renderMobileTagMenu()}</Hidden>
      </>
      {tags.map((tag) => (
        <Column>
          <Row key={`${action}-${tag.key}`} halfMargin center>
            <TagInput
              {...tag}
              editing={
                !labelOnly &&
                selectedAction === action &&
                selectedKey === tag.key
              }
              onEdit={(editing) =>
                onEdit?.(editing ? action : null, editing ? tag.key : null)
              }
              onChange={(value: Tag['value']) => onChange?.(action, tag, value)}
            />
            <div>
              <IconButton
                size="small"
                edge="end"
                onClick={() => onDelete(action, tag)}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </div>
          </Row>
        </Column>
      ))}
    </>
  );
};

export default DeviceBatchTagManager;
