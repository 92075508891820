import {
  FC,
  MouseEventHandler,
  forwardRef,
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react';
import cn from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import { Playlist } from '@raydiant/api-client-js';
import AlertIcon from 'raydiant-elements/core/AlertIcon';
import Text from 'raydiant-elements/core/Text';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import Row from 'raydiant-elements/layout/Row';
import Spacer from 'raydiant-elements/layout/Spacer';
import { playlistItemIndentWidth } from './PlaylistPage.styles';
import { ItemIDPath, PlaylistIDPath } from './playlistPageTypes';
import PlaylistItemList from './PlaylistItemList';
import PlaylistItemDropIndicator from './PlaylistItemDropIndicator';
import usePlaylistPagePlaylist from './usePlaylistPagePlaylist';
import PlaylistItemPlaylistActions from './PlaylistItemPlaylistActions';
import usePlaylistPageContext from './usePlaylistPageContext';

interface PlaylistItemPlaylistProps {
  className?: string;
  playlist: Playlist;
  indent?: number;
  expanded?: boolean;
  cyclic?: boolean;
  selected?: boolean;
  editable?: boolean;
  actionsOpen?: boolean;
  disabled?: boolean;
  dropPosition?: 'top' | 'bottom' | 'center';
  itemIdPath: ItemIDPath;
  playlistIdPath: PlaylistIDPath;
  hideActions?: boolean;
  onClick?: MouseEventHandler;
  onToggleExpandClick?: MouseEventHandler<HTMLButtonElement>;
  onMoreClick?: MouseEventHandler<HTMLButtonElement>;
  onScheduleClick?: MouseEventHandler<HTMLButtonElement>;
  onRulesClick?: MouseEventHandler<HTMLButtonElement>;
  onTagsClick?: MouseEventHandler<HTMLButtonElement>;
}

const PlaylistItemPlaylist: FC<PlaylistItemPlaylistProps> = (
  {
    playlist,
    className,
    indent = 0,
    expanded = false,
    cyclic = false,
    selected = false,
    actionsOpen = false,
    editable = false,
    disabled = false,
    dropPosition,
    itemIdPath,
    playlistIdPath,
    hideActions = false,
    onClick,
    onToggleExpandClick,
    onMoreClick,
    onScheduleClick,
    onRulesClick,
    onTagsClick,
  },
  ref,
) => {
  const { classes } = usePlaylistPageContext();

  const {
    updatePlaylist,
    resetEditItemName,
    getItemError,
    getPlaylistNameError,
    getPlaylistStartDatetimeError,
    getPlaylistEndDatetimeError,
  } = usePlaylistPageContext();

  // Fetch playlist items when expanded (if not already fetched).
  const { status, data: pagePlaylist } = usePlaylistPagePlaylist(playlist.id, {
    enabled: expanded,
  });

  // State

  const [name, setName] = useState(playlist.name);

  // Refs

  const nameRef = useRef<HTMLInputElement | null>(null);

  // Callbacks

  const handleNameBlur = useCallback(() => {
    updatePlaylist(playlist.id, { name });
    resetEditItemName();
  }, [updatePlaylist, resetEditItemName, playlist.id, name]);

  // Effects

  // Update state when playlist changes.
  useEffect(() => {
    setName(playlist.name);
  }, [playlist.name]);

  // Select text when editable changes from false to true.
  useEffect(() => {
    if (editable) {
      setTimeout(() => {
        if (!nameRef.current) return;
        nameRef.current.focus();
        nameRef.current.select();
      });
    }
  }, [editable]);

  // Render

  const showError =
    getItemError(itemIdPath) ||
    getPlaylistNameError(playlist.id) ||
    getPlaylistStartDatetimeError(playlist.id) ||
    getPlaylistEndDatetimeError(playlist.id);

  const shouldExpand = expanded && status !== 'loading';

  return (
    <div>
      <div
        ref={ref}
        className={cn(classes.playlistItemOuter, className)}
        style={{ marginLeft: indent * playlistItemIndentWidth }}
      >
        {dropPosition && dropPosition !== 'center' && (
          <PlaylistItemDropIndicator position={dropPosition} />
        )}
        <div
          className={cn(
            classes.playlistItem,
            classes.playlistItemPlaylist,
            shouldExpand && classes.playlistItemPlaylistExpanded,
            selected && classes.playlistItemSelected,
            disabled && classes.playlistItemDisabled,
            dropPosition === 'center' && classes.playlistItemPlaylistOver,
          )}
          onClick={onClick}
        >
          {showError && <AlertIcon className={classes.playlistItemErrorIcon} />}

          <Row halfMargin center className={classes.playlistItemInfo}>
            <div className={classes.playlistItemIcon}>
              {status === 'loading' ? (
                <CircularProgress size={16} />
              ) : (
                <PlaylistPlayIcon color="inherit" />
              )}
            </div>
            <div className={classes.playlistName}>
              <Text
                ref={nameRef}
                editable={editable}
                value={name}
                onChange={setName}
                onBlur={handleNameBlur}
              />
            </div>
          </Row>

          <Spacer />

          {!hideActions && (
            <Row className={classes.playlistItemActionsContainer} inline center>
              <PlaylistItemPlaylistActions
                itemIdPath={itemIdPath}
                expanded={shouldExpand}
                expandedDisabled={cyclic}
                moreActionsOpen={actionsOpen}
                onToggleExpandClick={onToggleExpandClick}
                onMoreClick={onMoreClick}
                onScheduleClick={onScheduleClick}
                onRulesClick={onRulesClick}
                onTagsClick={onTagsClick}
              />
            </Row>
          )}
        </div>
      </div>

      {!cyclic && (
        <Collapse in={shouldExpand} timeout={150}>
          {pagePlaylist && (
            <PlaylistItemList
              playlist={pagePlaylist}
              itemIdPath={itemIdPath}
              playlistIdPath={playlistIdPath}
            />
          )}
        </Collapse>
      )}
    </div>
  );
};

// @ts-ignore
export default forwardRef(PlaylistItemPlaylist);
